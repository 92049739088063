import {useSelector} from "react-redux";

export default function DeviceService() {
    const dispositivos = useSelector(state => state.devices);
    const data = [
        /* {
             id: '106',
             name: 'B2M',
             price: 1294,
             image: '/assets/phones/B2m.svg',
             details: [
                 {
                     title: 'Pantalla',
                     value: '5.0"'
                 },
                 {
                     title: 'Memoria',
                     value: '8GB + 1GB'
                 },
                 {
                     title: 'Cámara',
                     value: '5.0MP + 2.0MP'
                 },
                 {
                     title: 'Batería',
                     value: '2000 mAh'
                 },
                 {
                     title: 'Procesador',
                     value: 'SC9832E'
                 },
                 {
                     title: 'O.S.',
                     value: 'Android 9.0 (Go)'
                 },
             ]
         },{
             id: '105',
             name: 'K26',
             price:  732,
             image: '/assets/phones/K26.svg',
             details: [
                 {
                     title: 'Pantalla',
                     value: '2.8"'
                 },
                 {
                     title: 'Memoria',
                     value: '4GB + 512MB'
                 },
                 {
                     title: 'Cámara',
                     value: '0.3 MP'
                 },
                 {
                     title: 'Batería',
                     value: '1,350 mAh'
                 },
                 {
                     title: 'Procesador',
                     value: 'SC9820E'
                 },
                 {
                     title: 'O.S.',
                     value: 'KaiOS'
                 },
             ]
         },*/
        {
            id: '107',
            name: 'Hisense E20',
            price: 1973,
            image: '/assets/phones/HISENSE.svg',
            details: [
                {
                    title: 'Pantalla',
                    value: '5.0"'
                },
                {
                    title: 'Memoria',
                    value: '16GB + 2GB '
                },
                {
                    title: 'Cámara',
                    value: '13MP + 5MP'
                },
                {
                    title: 'Batería',
                    value: '2450 mAh'
                },
                {
                    title: 'Procesador',
                    value: 'QuadCore 1.4'
                },
                {
                    title: 'O.S.',
                    value: 'Android 10'
                },
            ]
        },
        {
            id: '108',
            name: 'Moto E6Play',
            price: 2758,
            image: '/assets/phones/MOTOPLAY.svg',
            details: [
                {
                    title: 'Pantalla',
                    value: '5.45"'
                },
                {
                    title: 'Memoria',
                    value: '32GB + 2GB'
                },
                {
                    title: 'Cámara',
                    value: '13MP + 5.0MP'
                },
                {
                    title: 'Batería',
                    value: '3000 mAh'
                },
                {
                    title: 'Procesador',
                    value: 'MT6739'
                },
                {
                    title: 'O.S.',
                    value: 'Android 9.0'
                },
            ]
        },
        {
            id: '111',
            name: 'Moto E6S',
            price: 3371,
            image: '/assets/phones/MOTOF6S.svg',
            details: [
                {
                    title: 'Pantalla',
                    value: '6.01"'
                },
                {
                    title: 'Memoria',
                    value: '32GB + 2GB'
                },
                {
                    title: 'Cámara',
                    value: '16MP + 2.0MP'
                },
                {
                    title: 'Batería',
                    value: '3000 mAh'
                },
                {
                    title: 'Procesador',
                    value: 'Helio P22'
                },
                {
                    title: 'O.S.',
                    value: 'Android 9.0'
                },
            ]
        }
    ]

    const getCel = id => new Promise((resolve, reject) => {
        if (dispositivos) {
            for (let i = 0; i < dispositivos.length; i++) {
                if (parseInt(id) === parseInt(dispositivos[i].id) && (dispositivos[i].seccion && dispositivos[i].seccion.toString().toLowerCase() === "celulares")) {
                    resolve(dispositivos[i]);
                    break;
                }
            }
            return
        }
        reject('ID de producto no existe')
    });

    const getThree = new Promise((resolve, reject) => {
        if (!dispositivos)
            reject('No hay productos')
        let cels = [];

        let all = dispositivos.filter(dev => dev.seccion && (dev.seccion.toString().toLowerCase() === "celulares"));
        let limit = all.length >= 3 ? 3 : all.length;
        for (let i = 0; i < limit; i++) {
            cels.push(all[i]);
        }

       
        resolve(cels);
        reject('No hay productos')
    });

    const getAll = new Promise((resolve, reject) => {
        if (!dispositivos)
            reject('No hay productos')
        else {
            resolve(dispositivos.filter(dev => dev.seccion && (dev.seccion.toString().toLowerCase() === "celulares")));
        }
    });

    const getDevice = id => new Promise((resolve, reject) => {
        if (dispositivos) {
            for (let i = 0; i < dispositivos.length; i++) {
                if (id === dispositivos[i].id) {
                    resolve(dispositivos[i]);
                    break;
                }
            }

        }
        reject('ID de producto no existe')
    });


    return {getCel, getThree, getAll, getDevice};
}
