export const SETBANNERS = (banners) => {
    return {
        type: "SETBANNERS",
        payload: banners
    }
}

export const SETHOMEBANNER = (banners) => {
    return {
        type: "SETHOMEBANNER",
        payload: banners
    }
}
export const SETALLIANCEBANNER = (banners) => {
    return {
        type: "SETALLIANCEBANNER",
        payload: banners
    }
}
export const SETENTERPRISEBANNER = (banners) => {
    return {
        type: "SETENTERPRISEBANNER",
        payload: banners
    }
}
export const SETPLANS = (planes) => {
    return {
        type: "SETPLANS",
        payload: planes
    }
}

//Iniciar sesión
export const LOG_IN = (contact) => {
    return {
        type: "SIGN_IN",
        payload: contact
    };
};

//Cerrar sesión
export const LOG_OUT = () => {
    return {
        type: "LOGOUT"
    };
};

export const SET_PLAN_NAME = (data) => {
    return {
        type: "SET_PLAN_NAME",
        payload: data
    };
};

export const SET_PRODUCT_RESUME_DATA = (data) => {
    return {
        type: "SET_PRODUCT_RESUME_DATA",
        payload: data
    };
};

export const ADD_TO_CART = (data) => {
    return {
        type: 'ADD_TO_CART',
        payload: data
    }
}
//Height del NavBar
export const SET_HEIGHT_NAVBAR = (height) => {
    return {
        type: 'SET_HEIGHT_NAVBAR',
        payload: height
    }
}

export const INITIALIZE_OPENPAY = (obj) => {
    return {
        type: 'INITIALIZE_OPENPAY',
        obj
    }
}

export const CLOSE_OPENPAY = () => {
    return {
        type: 'CLOSE_OPENPAY'
    }
}

export const BUY_PROCESS_LOADING = () => {
    return {
        type: 'BUY_PROCESS_LOADING'
    }
}

export const BUY_PROCESS_END = () => {
    return {
        type: 'BUY_PROCESS_END'
    }
}

export const ENTER_CODE = (secret) => {
    return {
        type: 'ENTER_CODE',
        payload: secret,
    }
}
export const LOADING_STATE = () => {
    return {
        type: 'LOADING_STATE',
        payload: true
    }
}

export const LOADING_FINISHED = () => {
    return {
        type: 'LOADING_FINISHED'
    }
}

export const PLAN_SELECTED = () => {
    return {
        type: 'PLAN_SELECTED'
    }
}
export const PLAN_UNSELECTED = () => {
    return {
        type: 'PLAN_UNSELECTED'
    }
}

export const SET_DATA = (data) => {
    return {
        type: 'SET_DATA',
        payload: data
    }
}
export const UNSET_DATA = () => {
    return {
        type: 'UNSET_DATA'
    }
}

export const WITH_SEND = () => {
    return {
        type: 'WITH_SEND',
    }
}
export const WITHOUT_SEND = () => {
    return {
        type: 'WITHOUT_SEND',
    }
}
export const SETPACKS = (xelpacks) => {
    return {
        type: 'SETPACKS',
        payload: xelpacks
    }
}
export const SETDEVICES = (devices) => {
    return {
        type: 'SETDEVICES',
        payload: devices
    }
}
