import React from 'react'
import CoberturaHBB from '../../ui/CoberturaHBB'
import NavBar from '../../ui/NavBar'
import Footer from '../../ui/Footer'

export default () => (
    <div>
	<NavBar />
	<CoberturaHBB />
	<Footer />
    </div>
)

