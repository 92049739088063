import React, {useEffect, useState} from 'react';
import NavBar from "../../ui/NavBar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {Grid, useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {APIHOST, XEL_COLORS, XEL_FONT} from "../../../constant";
import Typography from "@material-ui/core/Typography";
import Footer from '../../ui/Footer';
import {Route} from "react-router-dom";
import DinamicBuy from "./DinamicBuy";
import {Switch, useHistory} from "react-router-dom";
import Prepago from "./Prepago";
import Titulo from "../Inicio/PlansSection/Titulo";
import Axios from "../../../instances/axios";
import {useDispatch, useSelector} from "react-redux";
import axio from "axios";
import * as Actions from "../../../actions";


const useSubNavStyles = makeStyles({
    text: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.bold_weight,
        fontSize: 23,
        color: '#FEFEFE'
    },
    subcontainer: {
        height: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    high_desc: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.bold_weight,
        color: XEL_COLORS.primary,
    },
    low_desc: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        color: XEL_COLORS.primary,
        textAlign: "right",
        padding: 20
    }
});
const SubNav = ({select}) => {
    const styles = useSubNavStyles();
    const history = useHistory();
    const [selected, setSelected] = useState(select);
    useEffect(() => {

    })

    const goPlan = () => {
        setSelected(true);
        history.push('/personas/plan');
    }
    const goPlanEquipo = () => {
        setSelected(false);
        history.push('/personas/plan/equipo');
    }
    return <Grid container>
        <Grid item
              xs={6}
              classes={{root: styles.subcontainer}}
              style={{backgroundColor: select ? XEL_COLORS.primary : XEL_COLORS.primary_light}}
              onClick={goPlan}
        >
            <Typography classes={{root: styles.text}} align={"center"}>
                Comprar solo CHIP
            </Typography>
        </Grid>
        <Grid item
              xs={6}
              classes={{root: styles.subcontainer}}
              style={{backgroundColor: !select ? XEL_COLORS.primary : XEL_COLORS.primary_light}}
              onClick={goPlanEquipo}
        >
            <Typography classes={{root: styles.text}} align={"center"}>
                Comprar celular con CHIP
            </Typography>
        </Grid>
    </Grid>
}
export default function Personas({option}) {
    const noCel = useMediaQuery('(min-width:426px)');
    const axios = Axios();
    const bannersAll = useSelector(state => state.banners);
    const bannersHome = bannersAll.home;
    const dispatch = useDispatch();
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 1
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 1
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 1
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Xel planes";
    }, []);
    useEffect(() => {
        const source = axio.CancelToken.source();
        if (bannersHome.length === 0)
            axios.get("/website/banners/home", {
                cancelToken: source.token
            }).then(res => {

                let {data} = res;

                let {desktop, mobile} = data;

                let deskBanners = [];
                let mobileBanners = [];
                for (let i = 0; i < desktop.length; i++) {
                    deskBanners.push(desktop[i].bannerId);
                }
                for (let i = 0; i < mobile.length; i++) {
                    mobileBanners.push(mobile[i].bannerId);
                }
                let banners = [];

                for (let i = 0; i < deskBanners.length; i++) {
                    banners.push({
                        id: deskBanners[i],
                        file: {
                            type: 'image',
                            files: {
                                desktop: `${APIHOST}/website/banners/home/desktop/${deskBanners[i]}`,
                                mobile: `${APIHOST}/website/banners/home/mobile/${mobileBanners[i]}`
                            }
                        }
                    })
                }
                //  setBanners(banners);
                dispatch(Actions.SETHOMEBANNER(banners))
            }).catch(err => {
                console.error(err);
            });
        return () => {
            source.cancel("Cancelado por desmontar");
        }
    }, []);
    return <>
        <NavBar/>
        <Carousel autoPlaySpeed={4000} responsive={responsive} autoPlay={true} infinite={bannersHome.length > 0}>
            {
                bannersHome.map(item => <div style={{height: '100%', width: '100%'}} key={item.id}>
                    <img style={{height: '100%', width: '100%'}}
                         src={noCel ? item.file.files.desktop : item.file.files.mobile} alt={`banners-${item.id}`}/>
                </div>)
            }
        </Carousel>;
        {
            //    <Carousel data={items} arrows customindex={customIndex} setIndex={setCustomIndex}/>
        }
        <div id={'plan-section-id'} style={{}}/>
        <Grid style={{backgroundColor: '#F6F6F6'}}>
            <Titulo style={{backgroundColor: '#F6F6F6'}}/>
            <DinamicBuy option={option}/>
        </Grid>
        <Footer/>
    </>
}
