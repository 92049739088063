import React, {useEffect, useState} from "react";
import {Container, Grid, withStyles} from "@material-ui/core";
import {PromosPlan, XEL_COLORS, XEL_FONT} from "../../../../constant";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useResponsiveValues} from "../../../utils/hooks";
import CelService from "../../../utils/services/celulares";
import PacksService from "../../../utils/services/packs";
import {useHistory} from 'react-router-dom';
import NumberFormat from "react-number-format";
import Carousel from "react-multi-carousel";
import './styles.css';
import {useSelector} from "react-redux";
import DeviceService from "../../../utils/services/dispositivos";

const useStylesC = makeStyles({
    container: {
        paddingTop: '2%',
        height: '100%',
        width: '100%',
        display: 'flex'
        //   display:"flow"
    },
    item: {
        height: '100%',
        width: '100%',
        display: "flex",
        justifyContent: 'center'
    },
    cont: {
        display: "flex"
    }
});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 2
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 2
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};
const useStyles = makeStyles({
    container: {
        marginTop: 40
    },
    sub_container: {
        marginTop: 20,
        backgroundColor: '#F6F6F6',
        paddingTop: '1%',
        paddingBottom: '1%'
    },
    name_cell: {
        color: '#000000',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 35
    },
    model_cell: {
        color: '#4b4b4b',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 25
    },
    price_cell: {
        color: XEL_COLORS.secondary,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 45
    },
    image_container: {
        display: "flex",
        justifyContent: "center"
    },
    button: {
        color: '#4b4b4b',
        borderRadius: 20,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        marginTop: 30,
        fontSize: 20
    },

});

const usePackStyle = makeStyles({
    head: {
        padding: '2% 0'
    },
    img: {
        maxHeight: 280,
        width: '98%',
        height: '98%'
    },
    title: {

        fontWeight: XEL_FONT.bold_weight,
        padding: '1% 0'
    },
    btn_container: {
        display: "flex", justifyContent: "center"
    },
    btn: {
        padding: '0%',
        borderRadius: 14,
        backgroundColor: '#FEFEFE',
        border: `1px solid ${XEL_COLORS.primary}`,
        color: XEL_COLORS.primary,
        overflow: "hidden",

    },
    container: {
        backgroundColor: '#FEFEFE',
        paddingBottom: '2%',
        boxShadow: '0px 0px 30px #00000029',
        cursor: "pointer"
    },
    image_container: {
        display: "flex",
        justifyContent: "center",
        padding: '3%'
    },
    divider: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        height: 30,
        paddingBottom: '3%',
        paddingTop: '1%',
        '& span': {
            position: 'absolute',
            backgroundColor: '#FEFEFE',
            textShadow: '0px 2px 4px #000000',
            fontSize: 25,
            //    color: 'transparent'
            opacity: .5,
            color: XEL_COLORS.primary,
            fontWeight: XEL_FONT.semi_bold_weight
            //border: '1px solid #5A3671'
        },
        '& .divider': {
            width: '100%',
            height: 1,
            padding: 0,
            boxShadow: '0px 5px 4px #000000',
            backgroundColor: 'transparent',
            opacity: .5
        }
    }
});
const BuyButton = withStyles({
    root: {
        borderRadius: 14,

        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 25,
        border: `1px solid ${XEL_COLORS.primary}`,
        '&:hover': {
            backgroundColor: XEL_COLORS.secondary,
            color: '#FEFEFE',
            border: `1px solid #FEFEFE`,
        },

    }
})(Button);
const Divider = () => {
    const style = usePackStyle();
    return <Grid container alignItems={'center'}>
        <Grid item xs={12} className={style.divider}>
            {
                // <span>+</span>
            }
            <div className={'divider'}/>
        </Grid>
    </Grid>
}
const Pack = ({data}) => {
    const style = usePackStyle();
    const sizeTitle = useResponsiveValues(25, 20, 15);
    const buttonSizeText = useResponsiveValues(20, 15, 12);
    const monthsTextSize = useResponsiveValues(15, 12, 10)
//const promoTitleSize = useResponsiveValues()
    const history = useHistory();
    const [isHover, setIsHover] = useState(false);
    const [dataCel, setDataCel] = useState({});
    const CellService = DeviceService();
    const promosPlan = PromosPlan();
    const {
        id,
        name,
        id_dev,
        product,
        months
    } = data;
    const cuantityPlan = data.cuantity_plan ? data.cuantity_plan : 1;
    const cuantityDev = data.cuantity_dev ? data.cuantity_dev : 1;
    let planes = useSelector(state => state.planes);
    planes = planes.normal.filter(seccion => seccion.name === "Planes")[0].planes.filter(subseccion => subseccion.name.toLowerCase() === "telefonía móvil")[0].planes;
    let plan = planes.filter(plan => plan.id === product);
    plan = plan[0];
    let TotalPlan = (parseInt(plan.price) * months) * cuantityPlan;
    TotalPlan = TotalPlan - (parseInt(plan.price) * data.months_disc);
    const [devPrice, setDevPrice] = useState(0)
    const TotalPlanWithDiscont = TotalPlan;

    useEffect(() => {

        CellService.getCel(id_dev).then(res => {
            setDataCel(res);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (data.resp_dev_price) {
                setDevPrice(parseInt(data.resp_dev_price) * cuantityDev);
            } else {
                setDevPrice(parseInt(res.price) * cuantityDev);
            }
            //alert(devPrice)
        });
    }, [])
    const mouseEnter = () => {
        setIsHover(true);
    }
    const mouseLeave = () => {
        setIsHover(false);
    }
    const handleClick = () => {
        // eslint-disable-next-line no-undef
        gtag("click_lo_quiero", {
            "tipo": "XEL-PACK",
            "pack": name
        });
        history.push(`/detalle/pack/${id}`);
    }
    return <Grid container style={{padding: '2%', height: '100%',}}>
        <Grid item xs={12} className={style.container} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}
              onClick={handleClick}>
            <Grid container style={{height: '100%',}}>
                <Grid
                    item xs={12}
                    className={style.head}
                    style={{backgroundColor: isHover ? XEL_COLORS.secondary : XEL_COLORS.lila, marginBottom: '1%'}}>
                    <Typography
                        align={'center'}
                        className={style.title}
                        style={{fontSize: sizeTitle, color: isHover ? '#FEFEFE' : XEL_COLORS.primary}}>
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={12}
                      className={style.image_container}>
                    <img
                        src={dataCel.image}
                        alt="Celular"
                        className={style.img}/>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Typography align={'center'} color={'secondary'}
                                style={{fontWeight: XEL_FONT.bold_weight, fontSize: buttonSizeText}}>

                        {dataCel.name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Typography align={'center'}
                                color={'primary'}
                                style={{fontWeight: XEL_FONT.bold_weight, fontSize: buttonSizeText}}>
                        INCLUYE
                    </Typography>
                    <Typography align={'center'}
                                style={{fontWeight: XEL_FONT.bold_weight, fontSize: monthsTextSize}}
                    >
                        {months} meses
                    </Typography>
                    <Typography align={'center'}
                                color={'secondary'}
                                style={{fontWeight: XEL_FONT.bold_weight, fontSize: monthsTextSize}}>
                        de Plan {promosPlan.getName(product)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify={'center'}>
                        <Grid item xs={12}>
                            <Typography color={'primary'} align={'center'}
                                        style={{fontSize: buttonSizeText, paddingBottom: '1%'}}>
                                Precio de Contado:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color={'primary'} align={'center'} style={{
                                fontWeight: XEL_FONT.bold_weight,
                                fontSize: sizeTitle,
                                paddingBottom: '2%'
                            }}>
                                <NumberFormat thousandSeparator={true} prefix={'$'} displayType={'text'}
                                              value={TotalPlanWithDiscont + devPrice}/>**
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={style.btn_container}>
                            <BuyButton style={isHover ? {
                                backgroundColor: XEL_COLORS.secondary,
                                color: '#FEFEFE',
                                border: `1px solid #FEFEFE`,
                                fontSize: buttonSizeText
                            } : {fontSize: buttonSizeText}}>
                                ¡Lo quiero!
                            </BuyButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{padding: '3%'}}>
                    <Typography style={{fontSize: 10, marginTop: '3%'}} align={'center'}>
                        **Precio no incluye costo de envío, este se definirá dependiendo zona de contratación.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

export default function PacksSection({All}) {
    const packService = PacksService();
    const stylesC = useStylesC();
    const [data, setData] = useState([]);
    const history = useHistory();
    const style = useStyles();
    const text = useResponsiveValues(20, 15, 12);
    const button = useResponsiveValues(20, 15, 12);
    const title = useResponsiveValues(25, 20, 15);
    useEffect(() => {
        if (All) {
            packService.getAll.then(res => {
                setData(res);
            })
        } else {
            packService.getTwo.then(res => {
                setData(res);
            })
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [])

    return data.length > 0 ? <>
        <Grid container classes={{root: style.container}}>
            <Grid container>
                <Container>
                    <Grid item xs={11} md={12}>
                        <Grid container justify={'center'} alignItems={'center'}>
                            <Grid item xs={10}>
                                <Typography color={'primary'} variant={"h4"} style={{
                                    fontWeight: XEL_FONT.bold_weight,
                                    //    fontSize: title
                                }}>
                                    Xel Pack
                                </Typography>
                                <Typography color={'primary'} variant={"body1"}
                                    //            style={{fontSize: text}}
                                >
                                    Tu equipo + 12 meses de servicio inXel. ¡Encuentra tu Pack perfecto!.
                                </Typography>
                            </Grid>
                            {
                                !All ? <Grid item xs={2}>
                                    <BuyButton
                                        style={{fontSize: button}}
                                        onClick={() => {
                                            history.push('/packs');
                                        }}>
                                        Ver todos
                                    </BuyButton>
                                </Grid> : ''
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid container classes={{root: style.sub_container}}>

                <Grid container justify={'center'}>
                    <Grid item xs={12}>
                        <Container>
                            {
                                !All ? <Carousel
                                    className={stylesC.container}
                                    itemClass={stylesC.item}
                                    //     containerClass={stylesC.cont}
                                    responsive={responsive}

                                >
                                    {
                                        data.map((pack, index) => <Grid key={index} item xs={11}
                                                                        style={{height: '100%', position: "relative"}}>
                                            <Pack data={pack}/>
                                        </Grid>)
                                    }
                                </Carousel> : <Grid container justify={'center'}>
                                    {
                                        data.map((pack, index) => <Grid key={index} item xs={11} md={6}
                                                                        style={{height: '100%', position: "relative"}}>
                                            <Pack data={pack}/>
                                        </Grid>)
                                    }
                                </Grid>
                            }
                        </Container>
                    </Grid>
                </Grid>


            </Grid>
        </Grid>
    </> : <></>
}
