import React, {createRef, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {createMuiTheme, makeStyles, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import {green} from '@material-ui/core/colors';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone';
import PersonIcon from '@material-ui/icons/Person';
import ContactsIcon from '@material-ui/icons/Contacts';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import PaymentIcon from '@material-ui/icons/Payment';
import Container from "@material-ui/core/Container";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Skeleton from '@material-ui/lab/Skeleton';
//import {HOST} from "../../utils/Constants";
import {
    useCapitalizeText,
    useFileValidator,
    usePhoneFormater,
    useCRMFields,
    useResponsiveValues
} from "../../../utils/hooks";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StepContent from "@material-ui/core/StepContent";
import Paper from "@material-ui/core/Paper";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import {withMobileDialog, Zoom} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from "@material-ui/core/FormLabel";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import DoneIcon from '@material-ui/icons/Done';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../../../actions';
import HomeIcon from '@material-ui/icons/Home';
import HelpIcon from '@material-ui/icons/Help';
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Popover from "@material-ui/core/Popover";
import PayCard from "../../PayCard";
import {XEL_COLORS, XEL_FONT} from "../../../../constant";
import Checkbox from "@material-ui/core/Checkbox";
import OpenPayLogoColor from '../../../../assets/logos/OpenPay/openpay_color.png';
import VisaLogo from '../../../../assets/logos/OpenPay/visa.png';
import MasterCardLogo from '../../../../assets/logos/OpenPay/masterCard.png';
import {ThemeProvider} from "@material-ui/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Amex from '../../../../assets/logos/OpenPay/americanExpress.png';
import IneFr from '../../../../assets/images/id/INEfrente.jpg';
import IneVu from '../../../../assets/images/id/INEvuelta.jpg';
import LiceFr from '../../../../assets/images/id/licenciaF.jpg';
import LiceVu from '../../../../assets/images/id/licenciav.jpg';

import CeduFr from '../../../../assets/images/id/cédulaf.jpg';
import CeduVu from '../../../../assets/images/id/cédulav.jpg';
import Passport from '../../../../assets/images/id/pasaporte.jpg';
import Snackbar from "@material-ui/core/Snackbar";

import {useHistory, useParams} from 'react-router-dom';
import Logo from "../../../../assets/images/proceso_pago/inxel.svg";
import {CheckImei} from "../index";
import {not} from "rxjs/internal-compatibility";
import Grow from "@material-ui/core/Grow";
import OpenPayLocalServices from "../../../utils/services/openpay";


const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});

const useStylesProgress = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: '#F28A30',
        position: 'absolute',
        top: -15.5,
        left: -15.6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));
const globalStyles = makeStyles({
    skeleton_dad: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px'
    },
    skeleton_container: {
        width: '300px'
    },
    text_capitalize: {
        textTransform: 'capitalize'
    },
    d_flex: {
        display: 'flex'
    },
    justify_center: {
        justifyContent: 'center'
    },
    justify_start: {
        justifyContent: 'flex-start'
    },
    justify_end: {
        justifyContent: 'flex-end'
    },
    form: {
        marginTop: 10
    },
    input: {
        marginTop: 5,
        padding: 5,
        '& input': {
            width: '100%'
        }
    },
    options_container: {
        marginBottom: 15
    },
    snack_info: {
        backgroundColor: '#FEFEFE',
        marginBottom: 15,
        display: "flex",
        justifyContent: "center"
    },
    porta_container: {
        marginTop: 15
    },
    label_match: {
        fontFamily: XEL_FONT.name, fontWeight: XEL_FONT.regular_weight, fontSize: 16, color: XEL_COLORS.primary
    },
    label_nomatch: {
        fontFamily: XEL_FONT.name, fontWeight: XEL_FONT.regular_weight, fontSize: 14, color: XEL_COLORS.primary
    },
    label_active:
        {
            fontWeight: XEL_FONT.semi_bold_weight
        }
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active, completed} = props;
    useEffect(() => {
        console.log(props);
    }, [])
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({

    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( #ff7600 0%,#d35400 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( #ff7600 0%,#d35400 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: '#593571',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: '#593571'
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;
    const progrestyle = useStylesProgress();
    const loading = useSelector(state => state.buyloading);
    useEffect(() => {
        console.log(props, 'lofofo');
    }, [])
    const icons = {
        1: <PermDeviceInformationIcon/>,
        2: <PersonIcon/>,
        3: <HomeIcon/>,
        4: <PaymentIcon/>,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {
                active ?
                    loading ?
                        <div className={progrestyle.root}>
                            <div className={progrestyle.wrapper}>
                                {icons[String(props.icon)]}
                                <CircularProgress size={57} className={progrestyle.fabProgress}/>
                            </div>
                        </div>
                        :
                        <>{icons[String(props.icon)]}</>
                    :
                    completed ?
                        <DoneIcon/>
                        :
                        <>{icons[String(props.icon)]}</>
            }
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
    /**
     * Verifica si esta cargando
     */
    loading: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#fefefe',

        paddingBottom: '1%'

    },
    button: {
        marginRight: theme.spacing(1),
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    input: {
        display: 'none'
    }
}));

function getSteps() {
    return ['Compatibilidad', 'Identificación', 'Dirección', 'Pago'];
}

const CGRSwitch = withStyles({
    switchBase: {
        color: '#593571',
        '&$checked': {
            color: '#593571',
        },
        '&$checked + $track': {
            backgroundColor: '#593571',
        },
    },
    checked: {},
    track: {},
})(Switch);

const CgrRadio = withStyles({
    root: {
        '&$checked': {
            color: '#593571',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const usePayStyle = makeStyles({
    label: {
        '*': {
            fontFamily: XEL_FONT.name,
            fontWeight: XEL_FONT.regular_weight,
        }
    },
    input: {
        '&*': {
            fontFamily: XEL_FONT.name,
            fontWeight: XEL_FONT.regular_weight,
            textTransform: 'capitalize'
        }
    }
});

const PayTheme = createMuiTheme({
    root: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
    }
})
const Payment = ({infoPago, setInfoPago, infoPagoErrors, setInfoPagoErrors}) => {

    const loading = useSelector(state => state.buyloading);
    const sizeTitle = useResponsiveValues(20, 15, 10);

    const styles = usePayStyle();
    // const onlyLettersReg = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
    const onlyNumber = /^[0-9]+$/
    // const Months = [, , , , , , , , 'Semptiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const Mont = [{
        id: '01',
        name: 'Enero'
    },
        {
            id: '02',
            name: 'Febrero'
        },
        {
            id: '03',
            name: 'Marzo'
        },
        {
            id: '04',
            name: 'Abril'
        },
        {
            id: '05',
            name: 'Mayo'
        },
        {
            id: '06',
            name: 'Junio'
        },
        {
            id: '07',
            name: 'Julio'
        },
        {
            id: '08',
            name: 'Agosto'
        },
        {
            id: '09',
            name: 'Septiembre'
        },
        {
            id: '10',
            name: 'Octubre'
        },
        {
            id: '11',
            name: 'Noviembre'
        },
        {
            id: '12',
            name: 'Diciembre'
        },
    ]
    const Years = () => {
        const date = new Date();
        let year = date.getFullYear();
        let years = [];
        for (let i = 0; i < 13; i++)
            years.push(year++);
        return years;
    }
    const [cardData, setCardData] = useState({
        name_card: "",
        number: "",
        month: "01",
        year: Years()[0],
        cvv: "",
    });

    const handleChange = e => {
        let namerr_check_reset = `err_${[e.target.name]}`;
        let namerr_msg_reset = `msg_${[e.target.name]}`;
        setInfoPagoErrors({
            ...infoPagoErrors,
            [namerr_check_reset]: false,
            [namerr_msg_reset]: ''
        });

        switch (e.target.name) {
            case 'name_card':
                if (e.target.value.trim().split('').length === 0) {
                    setInfoPago({
                        ...infoPago,
                        name_card: ''
                    })
                }
                if (e.target.value.trim().split('').length > 0) {
                    setInfoPago({
                        ...infoPago,
                        name_card: e.target.value
                    })
                }
                break;
            case 'card':
                if (e.target.value.split('').length === 0)
                    setInfoPago({
                        ...infoPago,
                        card: ''
                    })
                else if (e.target.value.split('').length < 17)
                    if (onlyNumber.test(e.target.value)) {
                        setInfoPago({
                            ...infoPago,
                            card: e.target.value
                        })
                    }
                break;
            case 'month':
                setInfoPago({
                    ...infoPago,
                    month: e.target.value
                })
                break;
            case 'year':

                setInfoPago({
                    ...infoPago,
                    year: e.target.value
                });

                break;
            case 'cvv':
                if (e.target.value.split('').length === 0)
                    setInfoPago({
                        ...infoPago,
                        cvv: ''
                    })
                else if (e.target.value.split('').length < 5)
                    if (onlyNumber.test(e.target.value)) {
                        setInfoPago({
                            ...infoPago,
                            cvv: e.target.value
                        })
                    }
                break;
        }


    }
    return <Container component={'form'} id={'frm_getcard'}>
        <Grid container>
            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                <img src={OpenPayLogoColor} alt="OpenPay" style={{maxHeight: 60, height: '100%'}}/>
            </Grid>
            <Grid item xs={12}>
                <Grid container style={{marginBottom: 20}}>
                    <Grid xs={12} md={12}>
                        <FormControl fullWidth error={infoPagoErrors.err_name_card} disabled={loading}>
                            <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Nombre completo</InputLabel>
                            <Input
                                id="name_card" name={'name_card'}
                                onChange={handleChange}
                                value={infoPago.name_card}
                                aria-describedby="component-helper-text"
                                type='text'
                                style={{fontSize: sizeTitle}}
                            />
                            {
                                infoPagoErrors.err_name_card ? <FormHelperText
                                    id="component-helper-text">{infoPagoErrors.msg_name_card}</FormHelperText> : ''
                            }
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container style={{marginBottom: 20}} justify={'center'} spacing={0}>
                    <Grid xs={12} md={8} style={{paddingBottom: '2%'}}>
                        <FormControl fullWidth error={infoPagoErrors.err_card} disabled={loading}>
                            <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Número de tarjeta</InputLabel>
                            <Input
                                id="card-number" name={'card'}
                                onChange={handleChange}
                                value={infoPago.card}
                                aria-describedby="component-helper-text"
                                type='text'
                                style={{fontSize: sizeTitle}}
                            />
                            {
                                infoPagoErrors.err_card ? <FormHelperText
                                    id="component-helper-text">{infoPagoErrors.msg_card}</FormHelperText> : ''
                            }
                        </FormControl>
                    </Grid>
                    <Grid
                        xs={6} md={4} style={{display: "flex", justifyContent: "center"}}>
                        <img src={MasterCardLogo} alt="MasterCard"
                             style={{maxHeight: 43, marginRight: '1%', height: '100%'}}/>
                        <img src={VisaLogo} alt="Visa" style={{maxHeight: 43, marginLeft: '1%', height: '100%'}}/>
                        <img src={Amex} alt="AMEX" style={{maxHeight: 43, marginLeft: '1%', height: '100%'}}/>
                    </Grid>
                </Grid>
                <Grid container style={{marginBottom: 20}}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="month" style={{fontSize: sizeTitle}}>Mes</InputLabel>
                            <Select
                                labelId="month"
                                id="month"
                                name={'month'}
                                value={infoPago.month}
                                onChange={handleChange}
                                style={{fontSize: sizeTitle}}
                            >
                                {
                                    Mont.map(month => <MenuItem value={month.id} key={month.id}
                                                                style={{fontSize: sizeTitle}}>{month.id}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="year" style={{fontSize: sizeTitle}}>Año</InputLabel>
                            <Select
                                labelId="year"
                                id="year"
                                name={'year'}
                                value={infoPago.year}
                                onChange={handleChange}
                                style={{fontSize: sizeTitle}}
                            >
                                {
                                    Years().map(year => <MenuItem value={year} key={year}
                                                                  style={{fontSize: sizeTitle}}>{year}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <FormControl fullWidth error={infoPagoErrors.err_cvv} disabled={loading}>
                            <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>CVV</InputLabel>
                            <Input
                                id="cvv" name={'cvv'}
                                onChange={handleChange}
                                value={infoPago.cvv}
                                aria-describedby="component-helper-text"
                                type={'password'}
                                style={{fontSize: sizeTitle}}
                            />
                            {
                                infoPagoErrors.err_cvv ? <FormHelperText
                                    id="component-helper-text">{infoPagoErrors.msg_cvv}</FormHelperText> : ''
                            }
                        </FormControl>
                    </Grid>
                </Grid>
                {
                    infoPagoErrors.status_pay ? <>
                        <Grid item xs={12}>
                            <Alert severity="error" onClose={() => {
                                setInfoPagoErrors({
                                    ...infoPagoErrors,
                                    status_pay: false,
                                })
                            }}>¡{infoPagoErrors.msg_pay}!</Alert>
                        </Grid>
                    </> : ''
                }

            </Grid>
        </Grid>
    </Container>
}

const usePersonaStyle = makeStyles({
    title_c_porta: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 13
    },
    c_porta: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 11
    },
    texto_pop: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        padding: 5,
        fontSize: 13
    },
    input: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,

    }
    ,
    kind_buy: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 20,
        color: XEL_COLORS.primary
    },
    desc_buy: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 18,
        color: XEL_COLORS.primary
    }
})

const PortaGrid = withStyles({
    root: {
        borderRadius: 15,
        border: `1px solid ${XEL_COLORS.primary}`,
        padding: 15,
        margin: 1,
        minHeight: 50,
        opacity: .4,
        cursor: "pointer"
    }
})(Grid);

const PersonalData = ({personalData, setPersonalData, carriers, setPersonalDataErrors, personalDataErrors}) => {

    const styles = globalStyles();
    const pstyle = usePersonaStyle();

    const loading = useSelector(state => state.buyloading);
    const session = useSelector(state => state.login);
    const [portaPhone, setPortaPhone] = usePhoneFormater(personalData.tel_porta);
    const [phone, setPhone] = usePhoneFormater(personalData.tel);
    const [anchorEl, setAnchorEl] = useState(null);
    const sizeTitle = useResponsiveValues(20, 15, 10);
    useEffect(() => {
        setPersonalData({
            ...personalData,
            mail: session.email
        });
        console.log(session, 'Sesion')
    }, [session])

    useEffect(() => {
        setPersonalData({
            ...personalData,
            tel: phone,
        });
    }, [phone]);
    useEffect(() => {
        setPersonalData({
            ...personalData,
            tel_porta: portaPhone,
        })
    }, [portaPhone]);
    const handleOpenPop = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePop = () => {
        setAnchorEl(null);
    };

    const handleChange = e => {

        if (e.target.name === 'nip') {
            if (isNaN(e.target.value))
                return;
            if (e.target.value.split('').length > 4)
                return;
        }
        let namerr_check_reset = `err_${[e.target.name]}`
        let namerr_msg_reset = `msg_${[e.target.name]}`
        setPersonalData({
            ...personalData,
            [e.target.name]: e.target.value
        });
        setPersonalDataErrors({
            ...personalDataErrors,
            [namerr_check_reset]: false,
            [namerr_msg_reset]: ''
        });
        //  console.log(personalData, 'Datooooo')
    }

    const handlePortaPhone = e => {
        setPortaPhone(e.target.value);
        setPersonalData({
            ...personalData,
            tel_porta: portaPhone,
        })
        setPersonalDataErrors({
            ...personalDataErrors,
            err_tel_porta: false,
            msg_tel_porta: ''
        })
    }
    const handlePhone = e => {
        setPhone(e.target.value);
        setPersonalDataErrors({
            ...personalDataErrors,
            err_tel: false,
            msg_tel: ''
        })

    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const MessagePorta = () => {
        return <Container>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={'body1'} className={pstyle.title_c_porta}>
                        Antes de solicitar una portabilidad debes cumplir con lo siguiente:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <List style={{paddingTop: 5}}>
                        <ListItem style={{padding: 5}}>
                            <Typography variant={'body2'} className={pstyle.c_porta}>
                                1. Debes tener almenos cuatro meses en tu compañía actual.
                            </Typography>
                        </ListItem>
                        <ListItem style={{padding: 5}}>
                            <Typography variant={'body2'} className={pstyle.c_porta}>
                                2. El número a portar no deberá presentar adeudos en tu compañía actual.
                            </Typography>
                        </ListItem>
                        <ListItem style={{padding: 5}}>
                            <Typography variant={'body2'} className={pstyle.c_porta}>
                                3. El número a portar no deberá ser el mismo que el de contacto.
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Container>
    }


    const PortaForm = (<>
        <Divider style={{marginTop: 25}}/>
        <Grid container className={styles.porta_container}>
            <Grid item xs={12}>
                <SnackbarContent className={styles.snack_info}
                                 style={{backgroundColor: XEL_COLORS.primary}}
                                 message={<MessagePorta/>}
                />
            </Grid>
            <Grid item xs={12} md={4} className={styles.input}>

                <FormControl fullWidth error={personalDataErrors.err_tel_porta} disabled={loading}>
                    <InputLabel htmlFor="tel_porta" style={{fontSize: sizeTitle}}>Número a portar</InputLabel>
                    <Input
                        id="tel_porta"
                        name={'tel_porta'}
                        value={personalData.tel_porta}
                        onChange={handlePortaPhone}
                        aria-describedby="component-helper-text"
                        type={'text'}
                        style={{fontSize: sizeTitle}}
                    />
                    {
                        personalDataErrors.err_tel_porta ? <FormHelperText
                            id="component-helper-text">{personalDataErrors.msg_tel_porta}</FormHelperText> : ''
                    }

                </FormControl>
            </Grid>
            <Grid item xs={12} md={4} className={styles.input}>
                <FormControl fullWidth disabled={loading}
                             error={personalDataErrors.err_nip}

                >
                    <InputLabel id="nip" htmlFor={'nip_porta'} style={{fontSize: sizeTitle}}>NIP</InputLabel>
                    <Input type='text' id='nip_porta' classes={{root: pstyle.input}}
                           style={{fontSize: sizeTitle}}
                           maxLength='4'
                           name={'nip'}

                           onChange={handleChange}
                           value={personalData.nip}
                           endAdornment={<InputAdornment position="end">
                               <IconButton
                                   aria-label="toggle password visibility"
                                   onClick={handleOpenPop}
                                   onMouseDown={handleClosePop}
                               >
                                   <HelpIcon/>
                               </IconButton>
                           </InputAdornment>}/>
                    <Popover
                        id={'help_porta'}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClosePop}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Typography className={pstyle.texto_pop}>Para obtener tu NIP marca *051.</Typography>
                    </Popover>
                    {
                        personalDataErrors.err_tel_porta ?
                            <FormHelperText id="nip-helper">{personalDataErrors.msg_nip}</FormHelperText> : ''
                    }
                </FormControl>
            </Grid>
            <Grid item xs={10} md={4} className={styles.input}>
                <FormControl fullWidth disabled={loading} error={personalDataErrors.err_carrier}
                >
                    <InputLabel id="carrier" classes={{root: pstyle.input}} style={{fontSize: sizeTitle}}>Compañia de
                        origen</InputLabel>
                    <Select
                        labelId="carrier"
                        id="porta_carrier"
                        value={personalData.carrier}
                        onChange={handleChange}
                        name='carrier'
                        classes={{root: pstyle.input}}
                        style={{fontSize: sizeTitle}}
                    >
                        <MenuItem value={'0000'} style={{fontSize: sizeTitle}}>Selecciona...</MenuItem>
                        {
                            carriers.map(carrier => <MenuItem key={carrier.ID} value={carrier.ID}
                                                              style={{fontSize: sizeTitle}}>
                                {
                                    carrier.VALUE
                                }
                            </MenuItem>)
                        }
                    </Select>
                    {
                        personalDataErrors.err_carrier ?
                            <FormHelperText id="nip-helper">{personalDataErrors.msg_carrier}</FormHelperText> : ''
                    }
                </FormControl>
            </Grid>
        </Grid>
    </>);


    return <Zoom in={true}>

        <Container component={'form'} className={styles.form}>

            <Grid container justify="space-around">
                <PortaGrid item xs={10} md={5} style={{opacity: personalData.is_porta ? 1 : .4}}
                           onClick={() => {
                               setPersonalData({...personalData, is_porta: true});
                           }}
                >
                    <Typography classes={{root: pstyle.kind_buy}} style={{fontSize: sizeTitle}} align={'center'}>
                        Portarme a inXel
                    </Typography>
                    <Typography classes={{root: pstyle.desc_buy}} align={'center'} style={{fontSize: sizeTitle}}>
                        Cámbiate a inXel manteniendo tu número de teléfono actual.
                    </Typography>

                </PortaGrid>
                <PortaGrid item xs={10} md={5} style={{opacity: !personalData.is_porta ? 1 : .4}}
                           onClick={() => {
                               setPersonalData({...personalData, is_porta: false});
                           }}
                >
                    <Typography classes={{root: pstyle.kind_buy}} align={'center'} style={{fontSize: sizeTitle}}>
                        Línea nueva
                    </Typography>
                    <Typography classes={{root: pstyle.desc_buy}} align={'center'} style={{fontSize: sizeTitle}}>
                        Obtén un nuevo número con inXel.
                    </Typography>
                </PortaGrid>
            </Grid>

            <Grid container wrap='wrap' justify={'center'}>
                <Grid item xs={12} md={6} className={styles.input}>


                    <FormControl fullWidth error={personalDataErrors.err_nombre} disabled={loading}>
                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Nombre</InputLabel>
                        <Input
                            id="name"
                            name={'nombre'}
                            value={personalData.nombre}
                            onChange={handleChange}
                            aria-describedby="component-helper-text"
                            type={'text'}
                            style={{fontSize: sizeTitle}}
                        />
                        {
                            personalDataErrors.err_nombre ? <FormHelperText
                                id="component-helper-text">{personalDataErrors.msg_nombre}</FormHelperText> : ''
                        }

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className={styles.input}>
                    <FormControl fullWidth error={personalDataErrors.err_apellidos} disabled={loading}>
                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Apellidos</InputLabel>
                        <Input
                            id="apps"
                            name={'apellidos'}
                            value={personalData.apellidos}
                            onChange={handleChange}
                            aria-describedby="component-helper-text"
                            type={'text'}
                            style={{fontSize: sizeTitle}}
                        />
                        {
                            personalDataErrors.err_apellidos ? <FormHelperText
                                id="component-helper-text">{personalDataErrors.msg_apellidos}</FormHelperText> : ''
                        }

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className={styles.input}>
                    <FormControl fullWidth error={personalDataErrors.err_tel} disabled={loading}>
                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Telefono de contacto</InputLabel>
                        <Input
                            type='text'
                            id="tel"
                            name={'tel'}

                            onChange={handlePhone}
                            value={phone}
                            aria-describedby="component-helper-text"

                            style={{fontSize: sizeTitle}}
                        />
                        <FormHelperText
                            id="component-helper-text">{!personalDataErrors.err_tel ? 'Se usará para contactarte' : personalDataErrors.msg_tel}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className={styles.input}>
                    <FormControl fullWidth error={personalDataErrors.err_mail} disabled={loading}>
                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Correo electrónico</InputLabel>
                        <Input
                            id="email"
                            name={'mail'}
                            // onChange={handleChange}
                            value={session.email}
                            aria-describedby="component-helper-text"
                            type='mail'
                            InputProps={{
                                readOnly: true,
                            }}
                            style={{fontSize: sizeTitle}}
                        />
                        {
                            personalDataErrors.err_mail ? <FormHelperText
                                id="component-helper-text">{personalDataErrors.msg_mail}</FormHelperText> : ''
                        }

                    </FormControl>
                </Grid>
            </Grid>
            {
                personalData.is_porta ? PortaForm : ''
            }
        </Container>
    </Zoom>

}

const IdData = ({alertData, setAlertData, idData, setIdData,}) => {
    const classes = useStyles();
    const styles = globalStyles();
    const theme = useTheme();
    const matchesupsm = useMediaQuery(theme.breakpoints.up('sm'));
    const [idSelected, setIdSelected] = useState('0000');
    const dataSend = useSelector(state => state.datasend);
    const session = useSelector(state => state.login);
    const dispatch = useDispatch();
    const [ineFront, validIneFront, setIneFront, resetIneFront] = useFileValidator(null, ['jpg', 'jpeg', 'png']);
    const [inePost, validInePost, setInePost, resetInePost] = useFileValidator(null, ['jpg', 'jpeg', 'png']);

    const [liceFront, validLiceFront, setLiceFront, resetLiceFront] = useFileValidator(null, ['jpg', 'jpeg', 'png']);
    const [licePost, validLicePost, setLicePost, resetLicePost] = useFileValidator(null, ['jpg', 'jpeg', 'png']);

    const [ceduFront, validCeduFront, setCeduFront, resetCeduFront] = useFileValidator(null, ['jpg', 'jpeg', 'png']);
    const [ceduPost, validCeduPost, setCeduPost, resetCeduPost] = useFileValidator(null, ['jpg', 'jpeg', 'png']);

    const [passport, validPassport, setPassport, resetPassport] = useFileValidator(null, ['jpg', 'jpeg', 'png']);
    const [flagToAlert, setFlagToAlert] = useState(false);
    const [saved, setSaved] = useState({
        saved: false,
        verified: false,
    });
    useEffect(() => {
        dispatch(Actions.SET_DATA({
            ...dataSend,
            identificacion_tipo_id: idSelected,
            identificacion_image_one: null,
            identificacion_image_two: null,
        }));
        resetInePost();
        resetIneFront();

        resetLicePost();
        resetLiceFront();

        resetCeduPost();
        resetCeduFront();

        resetPassport();

    }, [idSelected]);

    useEffect(() => {
        fetch('https://inxel.mx/api/xel/v1/ecommerce.customer.backup.get.json?xel_token=' + session.token, {
            method: 'GET'
        }).then(response => response.json())
            .then(response => {
                console.log(response, 'saved');

                if (response.status === 'OK') {
                    console.log(response.result.step);

                    if (parseInt(response.result.step) > 0) {
                        setSaved({
                            saved: true,
                            verified: true,
                        });
                        setIdSelected(response.result.identificacion_tipo_id);
                    } else {
                        setSaved({
                            saved: false,
                            verified: true,
                        })
                    }
                } else {
                    console.log('NOt')
                    setSaved({
                        saved: false,
                        verified: true,
                    })
                }

            }).catch(error => {
            console.log(error)
        });
    }, []);

    useEffect(() => {
        if (validPassport)
            if (passport !== null || typeof passport !== "undefined" || passport !== '')
                dispatch(Actions.SET_DATA({
                    ...dataSend,
                    identificacion_image_one: passport.split(',')[1],
                    identificacion_image_one_src: passport
                }));
        console.log('Valido : ', validPassport);
    }, [passport, validPassport]);
    useEffect(() => {
        if (validInePost)
            if (inePost !== null || typeof inePost !== "undefined" || inePost !== '')
                dispatch(Actions.SET_DATA({
                    ...dataSend,
                    identificacion_image_two: inePost.split(',')[1],
                    identificacion_image_two_src: inePost

                }));
    }, [validInePost, inePost]);

    useEffect(() => {
        if (validIneFront)
            if (ineFront !== null || typeof ineFront !== "undefined" || ineFront !== '')
                dispatch(Actions.SET_DATA({
                    ...dataSend,
                    identificacion_image_one: ineFront.split(',')[1],
                    identificacion_image_one_src: ineFront
                }));
    }, [validIneFront, ineFront]);

    useEffect(() => {
        if (validLicePost)
            if (licePost !== null || typeof licePost !== "undefined" || licePost !== '')
                dispatch(Actions.SET_DATA({
                    ...dataSend,
                    identificacion_image_two: licePost.split(',')[1],
                    identificacion_image_two_src: licePost
                }));
    }, [validLicePost, licePost]);
    useEffect(() => {
        if (validLiceFront)
            if (liceFront != null || typeof liceFront !== "undefined" || liceFront !== '')
                dispatch(Actions.SET_DATA({
                    ...dataSend,
                    identificacion_image_one: liceFront.split(',')[1],
                    identificacion_image_one_src: liceFront
                }));
    }, [validLiceFront, liceFront]);

    useEffect(() => {
        if (validCeduPost)
            if (ceduPost != null || typeof ceduPost !== "undefined" || ceduPost !== '')
                dispatch(Actions.SET_DATA({
                    ...dataSend,
                    identificacion_image_two: ceduPost.split(',')[1],
                    identificacion_image_two_src: ceduPost
                }));
    }, [validCeduPost, ceduPost]);
    useEffect(() => {
        if (validCeduFront)
            if (ceduFront != null || typeof ceduFront !== "undefined" || ceduFront !== '')
                dispatch(Actions.SET_DATA({
                    ...dataSend,
                    identificacion_image_one: ceduFront.split(',')[1],
                    identificacion_image_one_src: ceduFront
                }));
    }, [validCeduFront, ceduFront]);


    /*
        useEffect(() => {
            setAlertData({
                show: false,
                type: 'success',
                title: '',
                text: ''
            });
            if (!validInePost && !inePost) {
                setAlertData({
                    show: true,
                    type: 'error',
                    title: 'Archivo no válido',
                    text: 'El archivo de INE posterior no es válida'
                });
                console.log('Hay pedos')
            }

        }, [validInePost, inePost])*/
    const handleIdSelected = e => {
        setIdSelected(e.target.value);
    }
    const handleIneFront = e => {
        setAlertData({
            show: false,
            type: 'error',
            title: '',
            text: ''
        });
        setIneFront(e);
    }
    const handleInePost = e => {
        setAlertData({
            show: false,
            type: 'error',
            title: '',
            text: ''
        });
        //3896078
        setInePost(e);
    }
    const handleCeduFront = e => {
        setAlertData({
            show: false,
            type: 'error',
            title: '',
            text: ''
        });
        setCeduFront(e);
    }
    const handleCeduPost = e => {
        setAlertData({
            show: false,
            type: 'error',
            title: '',
            text: ''
        });
        //3896078
        setCeduPost(e);
    }
    const handleLiceFront = e => {
        setAlertData({
            show: false,
            type: 'error',
            title: '',
            text: ''
        });
        setLiceFront(e);
    }
    const handleLicePost = e => {
        setAlertData({
            show: false,
            type: 'error',
            title: '',
            text: ''
        });
        //3896078
        setLicePost(e);
    }
    const handlePassport = e => {
        setAlertData({
            show: false,
            type: 'error',
            title: '',
            text: ''
        });
        //3896078
        setPassport(e);
    }
    const ineCase = () => {
        return <Grid container justify={'center'}>
            <Grid style={{display: 'flex', justifyContent: 'flex-end'}} item xs={12} md={4}>
                <FormControl>
                    <div className={classes.root}>
                        <Grid container justify={'center'}>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <Typography variant={"overline"} align={'center'}>
                                    Parte frontal
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.d_flex} justify={'center'} style={{marginBottom: 15}}>
                                {
                                    validIneFront && ineFront ?
                                        <img style={{
                                            maxWidth: '160px',
                                            width: '100%',
                                            maxHeight: '190px',
                                            height: '100%'
                                        }}
                                             src={ineFront}
                                             alt="INE Frontal"/> :
                                        <img
                                            src={saved.saved ? dataSend.identificacion_image_one_src !== '' ? dataSend.identificacion_image_one_src : IneFr : IneFr}
                                            alt="Ine"
                                            style={{
                                                maxWidth: '160px',
                                                width: '100%',
                                                maxHeight: '190px',
                                                height: '100%'
                                            }}/>
                                }
                            </Grid>
                        </Grid>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            onChange={handleIneFront}
                            type="file"
                        />
                        <Grid container>
                            <Grid item xs={12} justify={'center'} className={styles.d_flex}>
                                <label htmlFor="contained-button-file" style={{width: 'fit-content'}}>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        style={
                                            validIneFront && ineFront ? {
                                                backgroundColor: 'green',
                                                color: '#fefefe',
                                                marginRight: '0'
                                            } : {
                                                marginRight: '0'
                                            }
                                        }
                                        className={classes.button}
                                        startIcon={<PhotoCamera/>}
                                        component={'span'}
                                    >
                                        {
                                            validIneFront && ineFront ? 'Actualizar' : 'Subir'
                                        }
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </div>
                </FormControl>
            </Grid>
            <Grid item xs={11} md={4}>
                <FormControl>
                    <div className={classes.root}>
                        <Grid container justify={'center'}>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <Typography variant={"overline"} align={'center'}>
                                    Parte posterior
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.d_flex} justify={'center'} style={{marginBottom: 15}}>
                                {
                                    validInePost && inePost ?
                                        <img style={{
                                            maxWidth: '160px',
                                            width: '100%',
                                            maxHeight: '190px',
                                            height: '100%'
                                        }} src={inePost}
                                             alt="INE Frontal"/> :
                                        <img
                                            src={saved.saved ? dataSend.identificacion_image_two_src !== '' ? dataSend.identificacion_image_two_src : IneVu : IneVu}
                                            alt="Ine"
                                            style={{
                                                maxWidth: '160px',
                                                width: '100%',
                                                maxHeight: '190px',
                                                height: '100%'
                                            }}/>
                                    // <InsertPhotoIcon style={{fontSize: matchesupsm ? 120 : 80}}/>
                                }
                            </Grid>
                        </Grid>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file-2"
                            onChange={handleInePost}
                            type="file"
                        />
                        <Grid container>
                            <Grid item xs={12} justify={'center'} className={styles.d_flex}>
                                <label htmlFor="contained-button-file-2" style={{width: 'fit-content'}}>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        style={
                                            validInePost && inePost ? {
                                                backgroundColor: 'green',
                                                color: '#fefefe',
                                                marginRight: '0'
                                            } : {
                                                marginRight: '0'
                                            }
                                        }
                                        className={classes.button}
                                        startIcon={<PhotoCamera/>}
                                        component={'span'}
                                    >
                                        {
                                            validInePost && inePost ? 'Actualizar' : 'Subir'
                                        }
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </div>
                </FormControl>
            </Grid>
        </Grid>
    }

    const liceCase = () => {
        return <Grid container justify={'center'}>
            <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12} md={4}>
                <FormControl>
                    <div className={classes.root}>
                        <Grid container justify={'center'}>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <Typography variant={"overline"} align={'center'}>
                                    Parte frontal
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.d_flex} justify={'center'} style={{marginBottom: 15}}>
                                {
                                    validLiceFront && liceFront ?
                                        <img style={{
                                            maxWidth: '160px',
                                            width: '100%',
                                            maxHeight: '190px',
                                            height: '100%'
                                        }} src={liceFront}
                                             alt="INE Frontal"/> :
                                        <img
                                            src={saved.saved ? dataSend.identificacion_image_one_src !== '' ? dataSend.identificacion_image_one_src : LiceFr : LiceFr}
                                            alt="Ine" style={{
                                            maxWidth: '160px',
                                            width: '100%',
                                            maxHeight: '190px',
                                            height: '100%'
                                        }}/>
                                }
                            </Grid>
                        </Grid>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            onChange={handleLiceFront}
                            type="file"
                        />
                        <Grid container>
                            <Grid item xs={12} justify={'center'} className={styles.d_flex}>
                                <label htmlFor="contained-button-file" style={{width: 'fit-content'}}>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        style={
                                            validLiceFront && liceFront ? {
                                                backgroundColor: 'green',
                                                color: '#fefefe',
                                                marginRight: '0'
                                            } : {
                                                marginRight: '0'
                                            }
                                        }
                                        className={classes.button}
                                        startIcon={<PhotoCamera/>}
                                        component={'span'}
                                    >
                                        {
                                            validLiceFront && liceFront ? 'Actualizar' : 'Subir'
                                        }
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </div>
                </FormControl>
            </Grid>
            <Grid item xs={11} md={4} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl>
                    <div className={classes.root}>
                        <Grid container justify={'center'}>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <Typography variant={"overline"} align={'center'}>
                                    Parte posterior
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.d_flex} justify={'center'} style={{marginBottom: 15}}>
                                {
                                    validLicePost && licePost ?
                                        <img style={{
                                            maxWidth: '160px',
                                            width: '100%',
                                            maxHeight: '190px',
                                            height: '100%'
                                        }} src={licePost}
                                             alt="INE Frontal"/> :
                                        <img
                                            src={saved.saved ? dataSend.identificacion_image_two_src !== '' ? dataSend.identificacion_image_two_src : LiceVu : LiceVu}
                                            alt="Ine" style={{
                                            maxWidth: '160px',
                                            width: '100%',
                                            maxHeight: '190px',
                                            height: '100%'
                                        }}/>
                                    // <InsertPhotoIcon style={{fontSize: matchesupsm ? 120 : 80}}/>
                                }
                            </Grid>
                        </Grid>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file-2"
                            onChange={handleLicePost}
                            type="file"
                        />
                        <Grid container>
                            <Grid item xs={12} justify={'center'} className={styles.d_flex}>
                                <label htmlFor="contained-button-file-2" style={{width: 'fit-content'}}>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        style={
                                            validLicePost && licePost ? {
                                                backgroundColor: 'green',
                                                color: '#fefefe',
                                                marginRight: '0'
                                            } : {
                                                marginRight: '0'
                                            }
                                        }
                                        className={classes.button}
                                        startIcon={<PhotoCamera/>}
                                        component={'span'}
                                    >
                                        {
                                            validLicePost && licePost ? 'Actualizar' : 'Subir'
                                        }
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </div>
                </FormControl>
            </Grid>
        </Grid>
    }

    const ceduCase = () => {
        return <Grid container justify={'center'}>
            <Grid style={{display: 'flex', justifyContent: 'flex-end'}} item xs={12} md={4}>
                <FormControl>
                    <div className={classes.root}>
                        <Grid container justify={'center'}>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <Typography variant={"overline"} align={'center'}>
                                    Parte frontal
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.d_flex} justify={'center'} style={{marginBottom: 15}}>
                                {
                                    validCeduFront && ceduFront ?
                                        <img style={{
                                            maxWidth: '160px',
                                            width: '100%',
                                            maxHeight: '190px',
                                            height: '100%'
                                        }} src={ceduFront}
                                             alt="Cedula Frontal"/> :
                                        <img
                                            src={saved.saved ? dataSend.identificacion_image_one_src !== '' ? dataSend.identificacion_image_one_src : CeduFr : CeduFr}
                                            alt="Ine" style={{
                                            maxWidth: '160px',
                                            width: '100%',
                                            maxHeight: '190px',
                                            height: '100%'
                                        }}/>
                                }
                            </Grid>
                        </Grid>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            onChange={handleCeduFront}
                            type="file"
                        />
                        <Grid container>
                            <Grid item xs={12} justify={'center'} className={styles.d_flex}>
                                <label htmlFor="contained-button-file" style={{width: 'fit-content'}}>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        style={
                                            validCeduFront && ceduFront ? {
                                                backgroundColor: 'green',
                                                color: '#fefefe',
                                                marginRight: '0'
                                            } : {
                                                marginRight: '0'
                                            }
                                        }
                                        className={classes.button}
                                        startIcon={<PhotoCamera/>}
                                        component={'span'}
                                    >
                                        {
                                            validCeduFront && ceduFront ? 'Actualizar' : 'Subir'
                                        }
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </div>
                </FormControl>
            </Grid>
            <Grid item xs={11} md={4}>
                <FormControl>
                    <div className={classes.root}>
                        <Grid container justify={'center'}>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <Typography variant={"overline"} align={'center'}>
                                    Parte posterior
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={styles.d_flex} justify={'center'} style={{marginBottom: 15}}>
                                {
                                    validCeduPost && ceduPost ?
                                        <img style={{
                                            maxWidth: '160px',
                                            width: '100%',
                                            maxHeight: '190px',
                                            height: '100%'
                                        }} src={ceduPost}
                                             alt="Cedula Frontal"/> :
                                        <img
                                            src={saved.saved ? dataSend.identificacion_image_two_src !== '' ? dataSend.identificacion_image_two_src : CeduVu : CeduVu}
                                            alt="Ine" style={{
                                            maxWidth: '160px',
                                            width: '100%',
                                            maxHeight: '190px',
                                            height: '100%'
                                        }}/>
                                    // <InsertPhotoIcon style={{fontSize: matchesupsm ? 120 : 80}}/>
                                }
                            </Grid>
                        </Grid>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file-2"
                            onChange={handleCeduPost}
                            type="file"
                        />
                        <Grid container>
                            <Grid item xs={12} justify={'center'} className={styles.d_flex}>
                                <label htmlFor="contained-button-file-2" style={{width: 'fit-content'}}>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        style={
                                            validCeduPost && ceduPost ? {
                                                backgroundColor: 'green',
                                                color: '#fefefe',
                                                marginRight: '0'
                                            } : {
                                                marginRight: '0'
                                            }
                                        }
                                        className={classes.button}
                                        startIcon={<PhotoCamera/>}
                                        component={'span'}
                                    >
                                        {
                                            validCeduPost && ceduPost ? 'Actualizar' : 'Subir'
                                        }
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </div>
                </FormControl>
            </Grid>
        </Grid>
    }
    const pasportCase = () => {

        return <Grid container justify={'center'} style={{marginTop: '2.5%'}}>
            <Grid item xs={11} md={6} component={'form'} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl style={{width: 'fit-content'}}>
                    <div className={classes.root}
                         style={{
                             width: 'fit-content',
                             display: 'flex',
                             justifyContent: 'center',
                             flexDirection: 'column',
                             alignItems: 'center'
                         }}>
                        <Grid container justify={'center'}>
                            <Grid item xs={12} className={styles.d_flex} justify={'center'} style={{marginBottom: 4}}>
                                {
                                    validPassport ?
                                        <img style={{
                                            maxWidth: '180px',
                                            width: '100%',
                                            maxHeight: '190px',
                                            height: '100%'
                                        }} src={passport}
                                             alt="Pasaporte"/> :
                                        <img
                                            src={saved.saved ? dataSend.identificacion_image_one_src !== '' ? dataSend.identificacion_image_one_src : Passport : Passport}
                                            alt="Pasaporte" style={{
                                            maxWidth: '180px',
                                            width: '100%',
                                            maxHeight: '190px',
                                            height: '100%'
                                        }}/>
                                }
                            </Grid>
                        </Grid>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            onChange={handlePassport}
                            type="file"
                        />
                        <label htmlFor="contained-button-file">
                            <Button
                                variant="contained"
                                color="default"

                                style={
                                    validPassport ? {
                                        backgroundColor: 'green',
                                        color: '#fefefe',
                                        marginRight: '0'
                                    } : {
                                        marginRight: '0'
                                    }
                                }
                                className={classes.button}
                                startIcon={<PhotoCamera/>}
                                component={'span'}
                            >
                                {
                                    validPassport ? 'Actualizar' : 'Subir'
                                }
                            </Button>
                        </label>
                    </div>
                </FormControl>
            </Grid>
        </Grid>
    }


    return <>
        {
            saved.verified ? <Zoom in={true}>
                <Container>
                    <Grid container justify={'center'}>
                        <Grid item xs={12} md={11}>
                            <Grid container justify={'center'}>
                                <Grid item xs={12} md={7}>
                                    <Typography variant={'body1'}
                                                style={{
                                                    marginRight: '10px',
                                                    alignItems: 'center',
                                                    paddingTop: '5px',
                                                    paddingBottom: 15
                                                }}>
                                        ¿Que identificación otorgarás?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <FormControl style={{width: '100%'}}>
                                        <Select
                                            //                                value={carrierSelected}
                                            //                              onChange={handleChangeCarrier}
                                            //displayEmpty
                                            //className={classes.selectEmpty}
                                            value={idSelected}
                                            onChange={handleIdSelected}
                                            style={{width: '100%'}}
                                            inputProps={{'aria-label': 'Without label'}}
                                        >
                                            <MenuItem value={`0000`}>INE</MenuItem>
                                            <MenuItem value={`1111`}>Permiso de conducir</MenuItem>
                                            <MenuItem value={`2222`}>Pasaporte</MenuItem>
                                            <MenuItem value={`3333`}>Cédula profesional</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        idSelected === '0000' ? ineCase() : ''
                    }
                    {
                        idSelected === '1111' ? liceCase() : ''
                    }
                    {
                        idSelected === '2222' ? pasportCase() : ''
                    }
                    {
                        idSelected === '3333' ? ceduCase() : ''
                    }
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant={'body1'} align={'center'} style={{fontSize: 12, marginTop: 25}}>
                                {
                                    //                                Puedes omitir este paso si lo deseas, si ese es el caso un asesor te contactara despues
                                    //                                 de finalizar tu compra.
                                }
                                Si quieres omitir el paso de proporcionar identificación solo debes dar clic en
                                siguiente y un asesor te contactará despues
                                de finalizar tu compra.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Zoom> : <Grid container style={{

                display: "flex",
                justifyContent: "center",
                alignItems: 'center'
            }}>
                <Grid item xs={12}
                      style={{height: '65vh', display: 'flex', justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress color="secondary"/> <Typography variant={'body1'}>
                    Cargando...
                </Typography>
                </Grid>
            </Grid>
        }
    </>

}

const DirData = ({dirData, setDirData, dirDataErrors, setDirDataErrors, sameDir, setSameDir, matches}) => {
    const styles = globalStyles();
    const pstyle = usePersonaStyle();
    const titleSize = useResponsiveValues(30, 15, 12);
    const sizeTitle = useResponsiveValues(20, 15, 10);
    const loading = useSelector(state => state.buyloading);
    //const [sameDir, setSameDir] = useState('SI');
    const [portaPhone, setPortaPhone] = usePhoneFormater('');
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const [useIneAddr, setUseIneAddr] = useState(false);
    const handleOpenPop = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [cpInfo, setCpInfo] = useState([]);
    const [cpInfo2, setCpInfo2] = useState([]);
    const [cdmxPostals, setCdmxPostals] = useState([]);
    const handleClosePop = () => {
        setAnchorEl(null);
    };
    const totales = useSelector(state => state.carrito);

    useEffect(() => {
        fetch('https://api-sepomex.hckdrk.mx/query/get_cp_por_estado/Ciudad%20de%20Mexico', {
            method: 'GET'
        }).then(response => response.json())
            .then(response => {
                console.log('Codigos', response)
                if (!response.error) {
                    setCdmxPostals(response.response.cp);
                }
            }).catch(error => {
            console.log(error);
        });
    }, [matches])

    useEffect(() => {
        if (dirData.cod_pos !== '')
            if (sameDir === 'SI') {
                if (cpInfo.length === 0)
                    if (isNaN(dirData.cod_pos))
                        return
                    else if (dirData.cod_pos.split('').length > 5)
                        return
                    else if (dirData.cod_pos.split('').length < 6)
                        fetch(`https://api-sepomex.hckdrk.mx/query/info_cp/${dirData.cod_pos}`, {method: 'GET'}).then(response => response.json())
                            .then(response => {
                                if (response.length > 0) {
                                    if (!response[0].error) {
                                        setCpInfo(response);
                                        setDirData({
                                            ...dirData,
                                            'cod_pos': response[0].response.cp,
                                            'colonia': response[0].response.asentamiento,
                                            'municipio': response[0].response.municipio,
                                            'ciudad': response[0].response.ciudad.split('').length === 0 ? response[0].response.municipio : response[0].response.ciudad,
                                            'estado': response[0].response.estado
                                        });
                                    }
                                }
                            })
            } else {
                if (cpInfo2.length === 0)
                    if (isNaN(dirData.cod_pos))
                        return
                    else if (dirData.cod_pos.split('').length > 5)
                        return
                    else if (dirData.cod_pos.split('').length < 6)
                        fetch(`https://api-sepomex.hckdrk.mx/query/info_cp/${dirData.cod_pos}`, {method: 'GET'}).then(response => response.json())
                            .then(response => {
                                if (response.length > 0) {
                                    if (!response[0].error) {
                                        setCpInfo(response);
                                        setDirData({
                                            ...dirData,
                                            'cod_pos': response[0].response.cp,
                                            'colonia': response[0].response.asentamiento,
                                            'municipio': response[0].response.municipio,
                                            'ciudad': response[0].response.ciudad.split('').length === 0 ? response[0].response.municipio : response[0].response.ciudad,
                                            'estado': response[0].response.estado
                                        });
                                    }
                                }
                            })
            }
    }, [dirData.cod_pos]);
    useEffect(() => {
        if (dirData.cod_pos || dirData.cod_pos_e) {
            if (dirData.cod_pos.split('').length > 0 || dirData.cod_pos_e.split('').length > 0) {
                if (cdmxPostals.length !== 0) {
                    let flag = false;
                    for (let i = 0; i < cdmxPostals.length; i++) {
                        let postal = cdmxPostals[i];
                        console.log()
                        //  console.log(dirData.cod_pos,postal);
                        if (sameDir === 'SI') {
                            if (cpInfo.length !== 0) {
                                if (dirData.cod_pos === postal) {

                                    flag = true;
                                    break;
                                    // i = cdmxPostals.length;
                                }
                            }
                        } else {
                            if (cpInfo2.length !== 0)
                                if (dirData.cod_pos_e === postal) {
                                    flag = true;
                                    break
                                    //i = cdmxPostals.length;
                                }
                        }
                    }
                    if (sameDir === 'SI') {
                        let nothing = false;
                        if (dirData.cod_pos) {
                            if (dirData.cod_pos.split('').length === 0) {
                                nothing = true;
                            }
                        }
                        if (cpInfo.length === 0 && nothing)
                            return
                    } else {
                        let nothing = false;
                        if (dirData.cod_pos_e) {
                            if (dirData.cod_pos_e.split('').length === 0) {
                                nothing = true;
                            }
                        }
                        if (cpInfo2.length === 0 && nothing)
                            return
                    }
                    if (!flag) {
                        //  alert('4.1');
                        dispatch(Actions.WITH_SEND());

                        dispatch(Actions.ADD_TO_CART({
                            ...totales,
                            envio :{
                                title: 'Costo de envío',
                                price: 150,
                                cuantity: 1,
                                price_original: 150,
                                id: 102
                            }
                        }))
                    } else {
                        //  alert('4.2');
                        dispatch(Actions.WITHOUT_SEND());
                        dispatch(Actions.ADD_TO_CART({
                            ...totales,
                            envio: {
                                title: 'Costo de envío',
                                price: 60,
                                cuantity: 1,
                                price_original: 60,
                                id: 100
                            }
                        }))
                    }
                }
            }
        }

    }, [dirData, cdmxPostals, sameDir, cpInfo, cpInfo2, matches]);
    const handleChange = e => {
        let namerr_check_reset = `err_${[e.target.name]}`
        let namerr_msg_reset = `msg_${[e.target.name]}`
        switch (e.target.name) {
            case 'cod_pos':
                if (isNaN(e.target.value))
                    return
                else if (e.target.value.split('').length > 5)
                    return
                else if (e.target.value.split('').length < 6)
                    fetch(`https://api-sepomex.hckdrk.mx/query/info_cp/${e.target.value}`, {method: 'GET'}).then(response => response.json())
                        .then(response => {
                            if (response.length > 0) {
                                if (!response[0].error) {
                                    setCpInfo(response);
                                    setDirData({
                                        ...dirData,
                                        'cod_pos': response[0].response.cp,
                                        'colonia': response[0].response.asentamiento,
                                        'municipio': response[0].response.municipio,
                                        'ciudad': response[0].response.ciudad.split('').length === 0 ? response[0].response.municipio : response[0].response.ciudad,
                                        'estado': response[0].response.estado
                                    });
                                } else {
                                    setDirDataErrors({
                                        ...dirDataErrors,
                                        [namerr_check_reset]: true,
                                        [namerr_msg_reset]: 'Código postal no válido'
                                    });
                                    return;
                                }
                            }
                        })
                break;
            case 'cod_pos_e':
                if (isNaN(e.target.value))
                    return
                else if (e.target.value.split('').length > 5)
                    return
                else if (e.target.value.split('').length < 6)
                    fetch(`https://api-sepomex.hckdrk.mx/query/info_cp/${e.target.value}`, {method: 'GET'}).then(response => response.json())
                        .then(response => {
                            if (response.length > 0) {
                                if (!response[0].error) {
                                    setCpInfo2(response);
                                    setDirData({
                                        ...dirData,
                                        'cod_pos_e': response[0].response.cp,
                                        'colonia_e': response[0].response.asentamiento,
                                        'municipio_e': response[0].response.municipio,
                                        'ciudad_e': response[0].response.ciudad.split('').length === 0 ? response[0].response.municipio : response[0].response.ciudad,
                                        'estado_e': response[0].response.estado
                                    });
                                } else {
                                    setDirDataErrors({
                                        ...dirDataErrors,
                                        [namerr_check_reset]: true,
                                        [namerr_msg_reset]: 'Código postal no válido'
                                    });
                                    return;
                                }
                            }
                        })
                break;
            default:
                break;
        }
        setDirData({
            ...dirData,
            [e.target.name]: e.target.value
        });
        setDirDataErrors({
            ...dirDataErrors,
            [namerr_check_reset]: false,
            [namerr_msg_reset]: ''
        });
    }

    const handlePortaPhone = e => {
        setPortaPhone(e.target.value);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const MessageDir = () => {
        return <Container>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={'h3'} color={'primary'} align={'center'}
                                style={{
                                    boxShadow: '1px 1px 1px solid #00000',
                                    fontWeight: XEL_FONT.regular_weight,
                                    fontSize: titleSize
                                }}>
                        Registra tu dirección
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    }

    return <Zoom in={true}>
        <Container component={'form'} className={styles.form}>
            <Grid container wrap='wrap' justify={'center'}>
                <Grid item xs={12}>
                    <SnackbarContent className={styles.snack_info}
                                     message={<MessageDir/>}
                    />
                </Grid>
                <Grid item xs={12} md={4} className={styles.input}>
                    <FormControl fullWidth error={dirDataErrors.err_cod_pos} disabled={loading}>
                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Código Postal</InputLabel>
                        <Input
                            id="cod_pos"
                            name={'cod_pos'}
                            onChange={handleChange}
                            value={dirData.cod_pos}
                            aria-describedby="component-helper-text"
                            type='text'
                            style={{fontSize: sizeTitle}}
                        />
                        {
                            dirDataErrors.err_cod_pos ? <FormHelperText
                                id="component-helper-text">{dirDataErrors.msg_cod_pos}</FormHelperText> : ''
                        }

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} className={styles.input}>

                    {
                        cpInfo.length > 1 ? <>

                                <FormControl fullWidth disabled={loading} error={dirDataErrors.err_colonia}
                                >
                                    <InputLabel id="coloy" style={{fontSize: sizeTitle}}>Colonia</InputLabel>
                                    <Select
                                        labelId="coloy"
                                        value={dirData.colonia}
                                        onChange={handleChange}
                                        id="colonia" name={'colonia'}

                                        style={{fontSize: sizeTitle}}
                                    >
                                        {
                                            cpInfo.map(cp => <MenuItem
                                                value={cp.response.asentamiento}
                                                style={{fontSize: sizeTitle}}>{cp.response.asentamiento}</MenuItem>)
                                        }
                                    </Select>
                                    {
                                        dirDataErrors.err_colonia ?
                                            <FormHelperText
                                                id="nip-helper">{dirDataErrors.msg_colonia}</FormHelperText> : ''
                                    }
                                </FormControl>

                            </> :
                            <FormControl fullWidth error={dirDataErrors.err_colonia} disabled={loading}>
                                <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Colonia</InputLabel>
                                <Input
                                    id="colonia" name={'colonia'}
                                    onChange={handleChange}
                                    value={dirData.colonia}
                                    aria-describedby="component-helper-text"
                                    type='text'
                                    style={{fontSize: sizeTitle}}
                                />
                                {
                                    dirDataErrors.err_colonia ? <FormHelperText
                                        id="component-helper-text">{dirDataErrors.msg_colonia}</FormHelperText> : ''
                                }

                            </FormControl>
                    }
                </Grid>
                <Grid item xs={12} md={4} className={styles.input}>
                    <FormControl fullWidth error={dirDataErrors.err_municipio} disabled={loading}>
                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Municipio</InputLabel>
                        <Input
                            id="municipio" name={'municipio'}
                            onChange={handleChange}
                            value={dirData.municipio}
                            aria-describedby="component-helper-text"
                            type='text'
                            style={{fontSize: sizeTitle}}
                        />
                        {
                            dirDataErrors.err_municipio ? <FormHelperText
                                id="component-helper-text">{dirDataErrors.msg_municipio}</FormHelperText> : ''
                        }

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className={styles.input}>
                    <FormControl fullWidth error={dirDataErrors.err_ciudad} disabled={loading}>
                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Ciudad</InputLabel>
                        <Input
                            id="ciudad" name={'ciudad'}
                            onChange={handleChange}
                            value={dirData.ciudad}
                            aria-describedby="component-helper-text"
                            type='text'
                            style={{fontSize: sizeTitle}}
                        />
                        {
                            dirDataErrors.err_ciudad ? <FormHelperText
                                id="component-helper-text">{dirDataErrors.msg_ciudad}</FormHelperText> : ''
                        }

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className={styles.input}>
                    <FormControl fullWidth error={dirDataErrors.err_estado} disabled={loading}>
                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Estado</InputLabel>
                        <Input
                            id="estado" name={'estado'}
                            onChange={handleChange}
                            value={dirData.estado}
                            aria-describedby="component-helper-text"
                            type='text'
                            style={{fontSize: sizeTitle}}
                        />
                        {
                            dirDataErrors.err_estado ? <FormHelperText
                                id="component-helper-text">{dirDataErrors.msg_estado}</FormHelperText> : ''
                        }

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className={styles.input}>
                    <FormControl fullWidth error={dirDataErrors.err_calle} disabled={loading}>
                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Calle</InputLabel>
                        <Input
                            id="calle" name={'calle'}
                            onChange={handleChange}
                            value={dirData.calle}
                            aria-describedby="component-helper-text"
                            type='text'
                            style={{fontSize: sizeTitle}}
                        />
                        {
                            dirDataErrors.err_estado ? <FormHelperText
                                id="component-helper-text">{dirDataErrors.msg_calle}</FormHelperText> : ''
                        }

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} className={styles.input}>
                    <FormControl fullWidth error={dirDataErrors.err_num_ex} disabled={loading}>
                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Núm. Exterior </InputLabel>
                        <Input
                            id="num_ex" name={'num_ex'}
                            onChange={handleChange}
                            value={dirData.num_ex}
                            aria-describedby="component-helper-text"
                            type='text'
                            style={{fontSize: sizeTitle}}
                        />
                        {
                            dirDataErrors.err_num_ex ? <FormHelperText
                                id="component-helper-text">{dirDataErrors.msg_num_ex}</FormHelperText> : ''
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} className={styles.input}>
                    <FormControl fullWidth error={dirDataErrors.err_num_in} disabled={loading}>
                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Núm. Interior </InputLabel>
                        <Input
                            id="num_in" name={'num_in'}
                            onChange={handleChange}
                            value={dirData.num_in}
                            aria-describedby="component-helper-text"
                            type='text'
                            style={{fontSize: sizeTitle}}
                        />
                        {
                            dirDataErrors.err_num_in ? <FormHelperText
                                id="component-helper-text">{dirDataErrors.msg_num_in}</FormHelperText> : ''
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12} className={styles.input}>
                    <FormControl fullWidth error={dirDataErrors.err_rfc} disabled={loading}>
                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Referencias</InputLabel>
                        <Input
                            id="referencias" name={'referencias'}
                            onChange={handleChange}
                            value={dirData.referencias}
                            aria-describedby="component-helper-text"
                            type='text'
                            style={{fontSize: sizeTitle}}
                        />
                        {
                            dirDataErrors.err_rfc ? <FormHelperText
                                id="component-helper-text">{dirDataErrors.msg_rfc}</FormHelperText> : ''
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h3'} color={'primary'}
                                style={{fontWeight: XEL_FONT.regular_weight, marginTop: '2%', fontSize: titleSize}}
                                align={'center'}>
                        ¿Deseas que enviemos tu chip a la dirección que registraste?
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{display: "flex", justifyContent: "center", marginTop: '2%'}}>
                    <RadioGroup row aria-label="position" name="position" defaultValue="top">
                        <FormControlLabel
                            value="SI"
                            checked={sameDir === 'SI' ? true : false}
                            control={<Radio color="primary"/>}
                            onClick={() => {
                                setSameDir('SI');
                            }}
                            label="Si"
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            value="NO"
                            checked={sameDir === 'NO' ? true : false}
                            onClick={() => {
                                setSameDir('NO');
                            }}
                            control={<Radio color="primary"/>}
                            label="No"
                            labelPlacement="top"
                        />
                    </RadioGroup>
                </Grid>

                {
                    sameDir === 'NO' ? <>

                        <Grid item xs={12} md={4} className={styles.input}>
                            <FormControl fullWidth error={dirDataErrors.err_cod_pos_e} disabled={loading}>
                                <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Código Postal</InputLabel>
                                <Input
                                    id="cod_pos_e"
                                    name={'cod_pos_e'}
                                    onChange={handleChange}
                                    value={dirData.cod_pos_e}
                                    aria-describedby="component-helper-text"
                                    type='text'
                                    style={{fontSize: sizeTitle}}
                                />
                                {
                                    dirDataErrors.err_cod_pos_e ? <FormHelperText
                                        id="component-helper-text">{dirDataErrors.msg_cod_pos_e}</FormHelperText> : ''
                                }

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} className={styles.input}>

                            {
                                cpInfo2.length > 1 ? <>

                                        <FormControl fullWidth disabled={loading} error={dirDataErrors.err_colonia_e}
                                        >
                                            <InputLabel id="coloy" style={{fontSize: sizeTitle}}>Colonia</InputLabel>
                                            <Select
                                                labelId="coloy"
                                                value={dirData.colonia_e}
                                                onChange={handleChange}
                                                id="colonia_e" name={'colonia_e'}

                                                style={{fontSize: sizeTitle}}
                                            >
                                                {
                                                    cpInfo2.map(cp => <MenuItem
                                                        value={cp.response.asentamiento}
                                                        style={{fontSize: sizeTitle}}>{cp.response.asentamiento}</MenuItem>)
                                                }
                                            </Select>
                                            {
                                                dirDataErrors.err_colonia_e ?
                                                    <FormHelperText
                                                        id="nip-helper">{dirDataErrors.msg_colonia_e}</FormHelperText> : ''
                                            }
                                        </FormControl>

                                    </> :
                                    <FormControl fullWidth error={dirDataErrors.err_colonia_e} disabled={loading}>
                                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Colonia</InputLabel>
                                        <Input
                                            id="colonia_e" name={'colonia_e'}
                                            onChange={handleChange}
                                            value={dirData.colonia_e}
                                            aria-describedby="component-helper-text"
                                            type='text'
                                            style={{fontSize: sizeTitle}}
                                        />
                                        {
                                            dirDataErrors.err_colonia_e ? <FormHelperText
                                                id="component-helper-text">{dirDataErrors.msg_colonia_e}</FormHelperText> : ''
                                        }

                                    </FormControl>
                            }
                        </Grid>
                        <Grid item xs={12} md={4} className={styles.input}>
                            <FormControl fullWidth error={dirDataErrors.err_municipio_e} disabled={loading}>
                                <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Municipio</InputLabel>
                                <Input
                                    id="municipio_e" name={'municipio_e'}
                                    onChange={handleChange}
                                    value={dirData.municipio_e}
                                    aria-describedby="component-helper-text"
                                    type='text'
                                    style={{fontSize: sizeTitle}}
                                />
                                {
                                    dirDataErrors.err_municipio_e ? <FormHelperText
                                        id="component-helper-text">{dirDataErrors.msg_municipio_e}</FormHelperText> : ''
                                }

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} className={styles.input}>
                            <FormControl fullWidth error={dirDataErrors.err_ciudad_e} disabled={loading}>
                                <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Ciudad</InputLabel>
                                <Input
                                    id="ciudad_e" name={'ciudad_e'}
                                    onChange={handleChange}
                                    value={dirData.ciudad_e}
                                    aria-describedby="component-helper-text"
                                    type='text'
                                    style={{fontSize: sizeTitle}}
                                />
                                {
                                    dirDataErrors.err_ciudad_e ? <FormHelperText
                                        id="component-helper-text">{dirDataErrors.msg_ciudad_e}</FormHelperText> : ''
                                }

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} className={styles.input}>
                            <FormControl fullWidth error={dirDataErrors.err_estado_e} disabled={loading}>
                                <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Estado</InputLabel>
                                <Input
                                    id="estado_e" name={'estado_e'}
                                    onChange={handleChange}
                                    value={dirData.estado_e}
                                    aria-describedby="component-helper-text"
                                    type='text'
                                    style={{fontSize: sizeTitle}}
                                />
                                {
                                    dirDataErrors.err_estado_e ? <FormHelperText
                                        id="component-helper-text">{dirDataErrors.msg_estado_e}</FormHelperText> : ''
                                }

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} className={styles.input}>
                            <FormControl fullWidth error={dirDataErrors.err_calle_e} disabled={loading}>
                                <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Calle</InputLabel>
                                <Input
                                    id="calle_e" name={'calle_e'}
                                    onChange={handleChange}
                                    value={dirData.calle_e}
                                    aria-describedby="component-helper-text"
                                    type='text'
                                    style={{fontSize: sizeTitle}}
                                />
                                {
                                    dirDataErrors.err_calle_e ? <FormHelperText
                                        id="component-helper-text">{dirDataErrors.msg_calle_e}</FormHelperText> : ''
                                }

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} className={styles.input}>
                            <FormControl fullWidth error={dirDataErrors.err_num_ex_e} disabled={loading}>
                                <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Núm. Exterior </InputLabel>
                                <Input
                                    id="num_ex_e" name={'num_ex_e'}
                                    onChange={handleChange}
                                    value={dirData.num_ex_e}
                                    aria-describedby="component-helper-text"
                                    type='text'
                                    style={{fontSize: sizeTitle}}
                                />
                                {
                                    dirDataErrors.err_num_ex_e ? <FormHelperText
                                        id="component-helper-text">{dirDataErrors.msg_num_ex_e}</FormHelperText> : ''
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} className={styles.input}>
                            <FormControl fullWidth error={dirDataErrors.err_num_in_e} disabled={loading}>
                                <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Núm. Interior </InputLabel>
                                <Input
                                    id="num_in_e" name={'num_in_e'}
                                    onChange={handleChange}
                                    value={dirData.num_in_e}
                                    aria-describedby="component-helper-text"
                                    type='text'
                                    style={{fontSize: sizeTitle}}
                                />
                                {
                                    dirDataErrors.err_num_in_e ? <FormHelperText
                                        id="component-helper-text">{dirDataErrors.msg_num_in_e}</FormHelperText> : ''
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className={styles.input}>
                            <FormControl fullWidth error={dirDataErrors.err_rfc_e} disabled={loading}>
                                <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Referencias</InputLabel>
                                <Input
                                    id="referencias_e" name={'referencias_e'}
                                    onChange={handleChange}
                                    value={dirData.referencias_e}
                                    aria-describedby="component-helper-text"
                                    type='text'
                                    style={{fontSize: sizeTitle}}
                                />
                                {
                                    dirDataErrors.err_rfc_e ? <FormHelperText
                                        id="component-helper-text">{dirDataErrors.msg_rfc_e}</FormHelperText> : ''
                                }
                            </FormControl>
                        </Grid>


                        {
                            /* <Grid item xs={12} md={4} className={styles.input}>
                            <TextField

                                disabled={loading || useIneAddr} type='text' id="cod_pos" name={'cod_pos_e'}
                                label="Código Postal"
                                onChange={handleChange}
                                value={dirData.cod_pos_e}
                                error={dirDataErrors.err_cod_pos_e}
                                helperText={dirDataErrors.msg_cod_pos_e}
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={4} className={styles.input}>

                            {
                                cpInfo2.length > 1 ? <>
                                    <TextField

                                        disabled={loading || useIneAddr} id="colonia" name={'colonia_e'}
                                        label="Colonia"
                                        onChange={handleChange}
                                        value={dirData.colonia_e}
                                        error={dirDataErrors.err_colonia_e}
                                        helperText={dirDataErrors.msg_colonia_e}
                                        fullWidth
                                        select>

                                        {
                                            cpInfo2.map(cp => <MenuItem
                                                value={cp.response.asentamiento}>{cp.response.asentamiento}</MenuItem>)
                                        }

                                    </TextField>
                                </> : <TextField

                                    disabled={loading || useIneAddr} type='text' id="colonia"
                                    name={'colonia_e'}
                                    label="Colonia"
                                    onChange={handleChange}
                                    value={dirData.colonia_e}
                                    error={dirDataErrors.err_colonia_e}
                                    helperText={dirDataErrors.msg_colonia_e}
                                    fullWidth/>
                            }
                        </Grid>
                        <Grid item xs={12} md={4} className={styles.input}>
                            <TextField

                                disabled={loading || useIneAddr} type='text' id="municipio" name={'municipio_e'}
                                label="Municipio"
                                onChange={handleChange}
                                value={dirData.municipio_e}
                                error={dirDataErrors.err_municipio_e}
                                helperText={dirDataErrors.msg_municipio_e}
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={6} className={styles.input}>
                            <TextField

                                disabled={loading || useIneAddr} type='text' id="ciudad" name={'ciudad_e'}
                                label="Ciudad"
                                onChange={handleChange}
                                value={dirData.ciudad_e}
                                error={dirDataErrors.err_ciudad_e}
                                helperText={dirDataErrors.msg_ciudad_e}
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={6} className={styles.input}>
                            <TextField

                                disabled={loading || useIneAddr} type='text' id="estado" name={'estado_e'}
                                label="Estado"
                                onChange={handleChange}
                                value={dirData.estado_e}
                                error={dirDataErrors.err_estado_e}
                                helperText={dirDataErrors.msg_estado_e}
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={6} className={styles.input}>
                            <TextField

                                disabled={loading || useIneAddr} type='text' id="calle" name={'calle_e'}
                                label="Calle"
                                onChange={handleChange}
                                value={dirData.calle_e}
                                error={dirDataErrors.err_calle_e}
                                helperText={dirDataErrors.msg_calle_e}
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={3} className={styles.input}>
                            <TextField

                                disabled={loading || useIneAddr} type='text' id="num_ex_e" name={'num_ex_e'}
                                label="Núm. Exterior"
                                onChange={handleChange}
                                value={dirData.num_ex_e}
                                error={dirDataErrors.err_num_ex_e}
                                helperText={dirDataErrors.msg_num_ex_e}
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={3} className={styles.input}>
                            <TextField

                                disabled={loading || useIneAddr} type='text' id="num_in_e" name={'num_in_e'}
                                label="Núm. Interior"
                                onChange={handleChange}
                                value={dirData.num_in_e}
                                error={dirDataErrors.err_num_in_e}
                                helperText={dirDataErrors.msg_num_in_e}
                                fullWidth/>
                        </Grid>
                        <Grid item xs={12} className={styles.input}>
                            <TextField


                                disabled={loading || useIneAddr} type='text' id="referencias_e"
                                name={'referencias_e'}
                                label="Referencias"
                                onChange={handleChange}
                                value={dirData.referencias_e}
                                error={dirDataErrors.err_rfc_e}
                                helperText={dirDataErrors.msg_rfc_e}
                                fullWidth/>
                        </Grid>*/
                        }
                    </> : ''
                }
            </Grid>
        </Container>
    </Zoom>

}

const useStylesComp = makeStyles(theme => ({
    step: {
        backgroundColor: XEL_COLORS.primary,
    },
    text: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight
    },
    container_steps: {
        minHeight: '70vh',
        paddingTop: 10,
        display: "flex",
        alignItems: "flex-start"
    },
    title_resume: {
        color: XEL_COLORS.primary,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.bold_weight,
        fontSize: 17,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: XEL_COLORS.primary,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const Compatibilidad = ({option, next}) => {
    const styles = useStylesComp();
    const dispatch = useDispatch();
    const dataSend = useSelector(state => state.dataSend);
    const session = useSelector(state => state.login);
    const [promotion, setPromotion] = useState('');
    const history = useHistory();
    const [imei, setImei] = useState({
        imei: '',
        valid: false
    });
    const [imeirequest, setImeiRequest] = useState({
        status: false,
        valid: 0,
        verified: false,
    });
    const [free, setFree] = useState({
        status: false,
        value: 'NO',
        cheking: false,
    });

    const handleProm = e => {
        setPromotion(e.target.value);
    }
    useEffect(() => {
        fetch('https://inxel.mx/api/xel/v1/ecommerce.customer.leads.json?xel_token=' + session.token, {
            method: 'GET'
        }).then(response => response.json())
            .then(response => {
                console.log(response, 'leads');
                /*   if (response.status !== 'EMPTY') {
                       setFree({...free, cheking: true});
                       if (response.free !== null && response.free !== '0') {
                           fetch('https://inxel.mx/api/xel/v1/ecommerce.customer.lead.json?id=' + response.free,
                               {
                                   method: 'GET'
                               }).then(response => response.json())
                               .then(response => {
                                   if (response.result.UF_CRM_1596403646379)
                                       if (response.result.UF_CRM_1596403646379 === '1') {
                                           setFree({
                                               ...free,
                                               status: true,
                                               value: 'SI'
                                           });
                                       } else {
                                           setFree({
                                               ...free,
                                               status: true,
                                               value: 'NO'
                                           });
                                       }
                               }).catch(error => {
                               console.log(error)
                           })
                       } else {
                           setFree({
                               ...free,
                               status: false,
                               value: 'NO'
                           });
                       }
                       if (response.imei !== null && response.imei !== '0') {
                           fetch('https://inxel.mx/api/xel/v1/ecommerce.customer.lead.json?id=' + response.imei,
                               {
                                   method: 'GET'
                               }).then(response => response.json())
                               .then(response => {
                                   if (response.result.UF_CRM_1596403646379 === '1') {
                                       setImei({
                                           imei: response.result.UF_CRM_1596318010001,
                                           valid: true
                                       });
                                       setImeiRequest({
                                           status: true,
                                           valid: 0,
                                           verified: true
                                       });
                                       console.log('verificado')
                                   } else {
                                       setImeiRequest({
                                           status: true,
                                           valid: 2,
                                           verified: true
                                       });
                                       console.log('verificado')
                                   }
                                   setFree({
                                       ...free,
                                       status: true,
                                       value: 'SI'
                                   });
                                   // setLoaded(true);
                               }).catch(error => {
                               setFree({
                                   ...free,
                                   status: true,
                                   value: 'SI'
                               });

                           })
                       } else {
                           //setLoaded(true);
                           setImeiRequest({
                               status: false,
                               valid: 0,
                               verified: true
                           });
                       }
                   } else {*/
                setImeiRequest({
                    status: false,
                    valid: 0,
                    verified: true
                });
                setFree({
                    ...free,
                    status: false,
                    value: 'NO'
                });
                //    setLoaded(true);
                // }

            }).catch(error => {
            console.log(error)
        })
    }, [])

    useEffect(() => {
        dispatch(Actions.SET_DATA({
            ...dataSend,
            imei: imei.imei,
            promotion: promotion
        }));
        if (imei.valid) {
            next();
        }
    }, [imei, promotion]);

    const sendToHome = () => {
        history.push('/');
        window.location.reload();
    }
    const NotFreeMessage = (
        <Grid container justify={'center'} alignItems={'center'} style={{height: '100%'}}>
            <Grid item xs={12} md={10} lg={7}
                  style={{display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: "column"}}>
                <Typography>
                    Estimad@ cliente, agradecemos su registro con inXel , detectamos que su equipo NO ESTA LIBERADO para
                    ser ocupado con nuestra red, en el transcurso máximo de 5 días hábiles, un asesor inXeL lo
                    contactará para ofrecerle algunas alternativas, reiterando que estamos a sus órdenes, se despide su
                    equipo de asesores InXel.
                </Typography>
                <Button color={'primary'} variant={'contained'} style={{width: 250, marginTop: 9}} onClick={sendToHome}>
                    Entiendo, volver al inicio
                </Button>
            </Grid>

        </Grid>
    )
    const checkPorta = (
        <Grid container justify={'center'} alignItems={'center'} style={{paddingTop: useResponsiveValues(130, 70, 20)}}>
            <Grid item xs={12} md={10}>
                <Grid container justify={'center'} spacing={1}>
                    <Grid item md={4}>
                        <TextField variant={'outlined'} type={'text'} value={promotion} label={'Aplicar cupón inXel'}
                                   onChange={handleProm} fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color={'primary'} style={{fontSize: 12, marginBottom: 10}} align={'center'}>
                            *En caso de <span style={{fontWeight: XEL_FONT.semi_bold_weight}}>no</span> contar con algún
                            cupón, <span style={{fontWeight: XEL_FONT.semi_bold_weight}}>hacer caso omiso</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={10} style={{display: "flex", justifyContent: "center"}}>
                <img src={Logo} style={{height: useResponsiveValues(60, 40, 30)}} alt="inXel"/>
            </Grid>
            <Grid item xs={12} md={10}>
                <Typography variant={'body1'} align={'center'} style={{paddingBottom: 10, marginTop: 10}}>
                    ¿Tu equipo celular esta liberado?
                </Typography>
            </Grid>
            <Grid item xs={12} md={10} style={{display: "flex", justifyContent: "center", marginTop: 9}}>
                <RadioGroup row aria-label="position" name="position" defaultValue="top"
                            style={{display: "flex", justifyContent: "center"}}>
                    <FormControlLabel
                        disabled={free.cheking}
                        value="true"
                        control={<Radio color="primary" checked={free.value === 'SI'} onChange={() => {
                            setFree({status: false, value: 'SI'})
                        }}/>}
                        label="Si"
                        labelPlacement="top"

                    />
                    <FormControlLabel
                        disabled={free.cheking}
                        value="false"
                        control={<Radio color="primary" checked={free.value === 'NO'} onChange={() => {
                            setFree({status: false, value: 'NO'})
                        }}/>}
                        label="No"
                        labelPlacement="top"
                    />
                    <FormControlLabel
                        disabled={free.cheking}
                        value="nose"
                        control={<Radio color="primary" checked={free.value === 'NOSE'} onChange={() => {
                            setFree({status: false, value: 'NOSE'})
                        }}/>}
                        label="No lo se"
                        labelPlacement="top"
                    />
                </RadioGroup>
            </Grid>
            <Grid item xs={12} md={10} style={{display: "flex", justifyContent: 'center', marginTop: 9}}>
                <div className={styles.wrapper}>
                    <Button
                        variant="contained"
                        color="primary"
                        //  className={buttonClassname}
                        disabled={free.cheking}
                        onClick={() => {
                            setFree({...free, cheking: true});
                            if (free.value === 'NO' || free.value === 'NOSE') {
                                let data = {
                                    xel_token: session.token,
                                    promotion: dataSend.promotion ? dataSend.promotion : ''
                                }
                                fetch('https://inxel.mx/api/xel/v1/ecommerce.lead.not.free.json', {
                                    method: 'POST',
                                    body: JSON.stringify(data)
                                }).then(response => response.json())
                                    .then(response => {
                                        console.log(response);
                                        if (response.status === 'OK') {
                                            setFree({...free, status: true});
                                        }
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    })
                            } else {
                                setFree({...free, status: true});
                            }
                        }}
                    >
                        {!free.cheking ? 'Continuar' : 'Verificando'}
                    </Button>
                    {free.cheking && <CircularProgress size={24} className={styles.buttonProgress}/>}
                </div>
            </Grid>
        </Grid>
    )
    return <Container>
        <Grid container>
            <Grid item xs={12} style={{minHeight: '50vh'}}>
                {
                    option === 1 ? imei.valid ? '' :
                        free.status ? free.value === 'SI' ?
                            <Grow in={true} timeout={1000}>
                                <CheckImei imeiReq={imeirequest} setImei={setImei}
                                           imeiData={imei}/>
                            </Grow>
                            : NotFreeMessage : checkPorta : option === 2 ?
                        '' : ''
                }
            </Grid>
        </Grid>
    </Container>
}

export default function PasosCompra({setPayed, option}) {

    const styles = globalStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const classes = useStyles();
    const dispatch = useDispatch();
    const steps = getSteps();
    const params = useParams();
    const plan = params.idp;
    //const crmfield = useCRMFields();
    //const onlyLettersReg = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
    const mailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const loading = useSelector(state => state.buyloading);
    // eslint-disable-next-line no-undef
    const OpenPayInstance = OpenPay;
    const dataSend = useSelector(state => state.datasend);
    const session = useSelector(state => state.login);
    const [activeStep, setActiveStep] = useState(0);
    const actualYear = new Date().getFullYear();
    const [carriers, setCarriers] = useState([]);
    const [carrierSelected, setCarrierSelected] = useState('0000');//
    const [keepNumber, setKeepNumber] = useState(true);//
    const [principalPhone, setPrincipalPhone] = usePhoneFormater('');
    const [contactPhone, setContactPhone] = usePhoneFormater('');
    const [loaded, setLoaded] = useState(false);
    const [name, setName] = useCapitalizeText('');
    const [surName, setSurName] = useCapitalizeText('');
    const [lastName, setLastName] = useCapitalizeText('');
    const [hourSel, setHourSel] = useState(true);
    const crmfield = useCRMFields();
    /**********Seccion datos personales*************/
        //Datos
    const [personalData, setPersonalData] = useState({
            nombre: '',
            apellidos: '',
            tel: '',
            mail: session.email,
            nip: '',
            carrier: '0000',
            tel_porta: '',
            is_porta: false
        });

    const [personalDataErrors, setPersonalDataErrors] = useState({
        err_nombre: false,
        msg_nombre: '',
        err_apellidos: false,
        msg_apellidos: '',
        err_tel: false,
        msg_tel: '',
        err_mail: false,
        msg_mail: '',
        err_nip: false,
        msg_nip: '',
        err_carrier: false,
        msg_carrier: '',
        err_tel_porta: false,
        msg_tel_porta: '',
    });

    /**********Fin Datos personales*************/
    /**********Seccion direcciones*************/
    const [dirData, setDirData] = useState({
        cod_pos: '',
        colonia: '',
        municipio: '',
        ciudad: '',
        estado: '',
        calle: '',
        num_ex: '',
        num_in: '',
        referencias: '',

        cod_pos_e: '',
        colonia_e: '',
        municipio_e: '',
        ciudad_e: '',
        estado_e: '',
        calle_e: '',
        num_ex_e: '',
        num_in_e: '',
        referencias_e: '',
    });

    const [dirDataErrors, setDirDataErrors] = useState({
        err_cod_pos: false,
        msg_cod_pos: '',
        err_colonia: false,
        msg_colonia: '',
        err_municipio: false,
        msg_municipio: '',
        err_ciudad: false,
        msg_ciudad: '',
        err_estado: false,
        msg_estado: '',
        err_calle: false,
        msg_calle: '',
        err_num_ex: false,
        msg_num_ex: '',
        err_num_in: false,
        msg_num_in: '',
        err_rfc: false,
        msg_rfc: '',

        err_cod_pos_e: false,
        msg_cod_pos_e: '',
        err_colonia_e: false,
        msg_colonia_e: '',
        err_municipio_e: false,
        msg_municipio_e: '',
        err_ciudad_e: false,
        msg_ciudad_e: '',
        err_estado_e: false,
        msg_estado_e: '',
        err_calle_e: false,
        msg_calle_e: '',
        err_num_ex_e: false,
        msg_num_ex_e: '',
        err_num_in_e: false,
        msg_num_in_e: '',
        err_rfc_e: false,
        msg_rfc_e: ''
    });

    const [sameDir, setSameDir] = useState('SI');
    /**********Fin Seccion Direcciones*************/

    /**********Seccion Pago*************/
    const planPrice = useSelector(state => state.planprice);
    const [infoPago, setInfoPago] = useState({
        name_card: '',
        card: '',
        month: '01',
        year: '' + actualYear,
        cvv: ''
    });
    const [infoPagoErrors, setInfoPagoErrors] = useState({
        err_name_card: false,
        mg_name_card: '',
        err_card: false,
        mg_card: '',
        err_month: false,
        mg_month: '',
        err_year: false,
        mg_year: '',
        err_cvv: false,
        mg_cvv: '',
        status_pay: false,
        msg_pay: ''
    });
    /**********Fin Seccion Pago*************/
    const [alertData, setAlertData] = useState({
        show: false,
        type: 'success',
        title: 'Lo que sea',
        text: 'Hubo un error'
    });

    const [idData, setIdData] = useState({
        selected: '0000',
        isError: true,
        img_one: null,
        img_two: null
    });
    //Errores
    useEffect(() => {
        if (loaded) {
            dispatch(Actions.SET_DATA({
                ...dataSend,
                personal_nombre: personalData.nombre,
                personal_apellidos: personalData.apellidos,
                personal_tel: personalData.tel,
                personal_email: session.email,
                personal_tel_porta: personalData.tel_porta,
                personal_nip: personalData.nip,
                personal_portabilidad: personalData.is_porta,
                personal_compania: personalData.carrier,
            }))
        }
    }, [personalData, loaded]);
    useEffect(() => {
        if (loaded) {
            dispatch(Actions.SET_DATA({
                ...dataSend,
                dir_postal_code: dirData.cod_pos,
                dir_colonia: dirData.colonia,
                dir_municipio: dirData.municipio,
                dir_ciudad: dirData.ciudad,
                dir_estado: dirData.estado,
                dir_calle: dirData.calle,
                dir_numex: dirData.num_ex,
                dir_numin: dirData.num_in,
                dir_referencias: dirData.referencias
            }));

        }
    }, [dirData, loaded]);
    useEffect(() => {
        // console.log(dataSend, 'dasdasdasdasd')
    }, [dataSend])
    useEffect(() => {

        crmfield.then(response => {
            console.log(response);
            var cm = [];
            for (
                let i = 0;
                i < response.result.UF_CRM_1594426767.items.length;
                i++
            ) {
                const element = response.result.UF_CRM_1594426767.items[i];
                cm.push(element);
            }
            setCarriers(cm);
            fetch('https://inxel.mx/api/xel/v1/ecommerce.customer.backup.get.json?xel_token=' + session.token, {
                method: 'GET'
            }).then(response => response.json())
                .then(response => {
                    console.log(response, 'saved');

                    if (response.status === 'OK') {
                        dispatch(Actions.SET_DATA(
                            response.result
                        ));
                        setActiveStep(parseInt(response.result.step));
                        setPersonalData({
                            nombre: response.result.personal_nombre,
                            apellidos: response.result.personal_apellidos,
                            tel: response.result.personal_tel,
                            mail: response.result.email,
                            nip: response.result.personal_nip ? response.result.personal_nip !== null || response.result.personal_nip.split('').length !== 0 ? response.result.personal_nip : '' : '',
                            carrier: response.result.personal_compania ? response.result.personal_compania !== null || response.result.personal_compania.split('').length !== 0 ? response.result.personal_compania : '' : '',
                            tel_porta: response.result.personal_tel_porta ? response.result.personal_tel_porta !== null || response.result.personal_tel_porta.split('').length !== 0 ? response.result.personal_tel_porta : '' : '',
                            is_porta: response.result.personal_portabilidad ? response.result.personal_portabilidad !== null || response.result.personal_portabilidad.split('').length !== 0 ? response.result.personal_portabilidad === 'true' ? true : false : false : false,
                        });
                        if (parseInt(response.result.step) > 1) {
                            setDirData({
                                ...dirData,
                                cod_pos: response.result.dir_postal_code,
                                colonia: response.result.dir_colonia,
                                municipio: response.result.dir_municipio,
                                ciudad: response.result.dir_ciudad,
                                estado: response.result.dir_estado,
                                calle: response.result.dir_calle,
                                num_ex: response.result.dir_numex,
                                num_in: response.result.dir_numin,
                                referencias: response.result.dir_referencias,
                            });
                        }
                    }
                    setLoaded(true);
                }).catch(error => {
                console.log(error)
            });

        }).catch(error => {
            console.error(error);
        });

    }, []);
    useEffect(() => {
        dispatch(Actions.SET_DATA(
            {
                ...dataSend,
                dir_postal_code_e: '',
                dir_colonia_e: '',
                dir_municipio_e: '',
                dir_ciudad_e: '',
                dir_estado_e: '',
                dir_calle_e: '',
                dir_numex_e: '',
                dir_numin_e: '',
                dir_referencias_e: '',
            }
        ))
    }, [sameDir])
    const handleChangeCarrier = e => {
        setCarrierSelected(e.target.value);
    }
    const handleKeepNumber = e => {
        setKeepNumber(e.target.checked);
    }
    const handlePrincipalPhone = e => {

        setPrincipalPhone(e.target.value);
    }
    const handleContactPhone = e => {
        setContactPhone(e.target.value);
    }
    const handleName = e => {
        setName(e.target.value);
    }
    const handleSurName = e => {
        setSurName(e.target.value);
    }
    const handleLastName = e => {
        setLastName(e.target.value);
    }
    const handleNext = () => {
            switch (activeStep) {
                case 0 :
                    dispatch(Actions.BUY_PROCESS_LOADING());


                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    dispatch(Actions.BUY_PROCESS_END());
                    break;
                case 1:
                    let {
                        nombre,
                        apellidos,
                        tel,
                        mail,
                        nip,
                        carrier,
                        tel_porta,
                        is_porta
                    } = personalData;


                    let arrayErrs = [];

                    if (nombre.split('').length === 0) {
                        setPersonalDataErrors({
                            ...personalDataErrors,
                            err_nombre: true,
                            msg_nombre: 'Inserta nombre'
                        });
                        console.log('Error nombres vacio');
                        let err = {
                            err_name: 'err_nombre',
                            err_msg_name: 'msg_nombre',
                            err_msg: 'Inserta nombre'
                        }

                        arrayErrs.push(err);

                    }

                    if (apellidos.split('').length === 0) {
                        console.log('Error nombres vacio');
                        let err = {
                            err_name: 'err_apellidos',
                            err_msg_name: 'msg_apellidos',
                            err_msg: 'Inserta apellidos'
                        }
                        arrayErrs.push(err);
                    }
                    console.log(tel.split(''))
                    if (tel.split('').length === 0) {
                        let err = {
                            err_name: 'err_tel',
                            err_msg_name: 'msg_tel',
                            err_msg: 'Inserta teléfono'
                        }
                        arrayErrs.push(err);
                    } else if (tel.split('').length < 12) {
                        let err = {
                            err_name: 'err_tel',
                            err_msg_name: 'msg_tel',
                            err_msg: 'Teléfono a 10 digitos'
                        }
                        arrayErrs.push(err);
                    }
                    if (mail.split('').length === 0) {
                        let err = {
                            err_name: 'err_mail',
                            err_msg_name: 'msg_mail',
                            err_msg: 'Inserta correo electrónico'
                        }
                        arrayErrs.push(err);
                    } else if (!mailReg.test(mail)) {
                        let err = {
                            err_name: 'err_mail',
                            err_msg_name: 'msg_mail',
                            err_msg: 'Correo electrónico inválido'
                        }
                        arrayErrs.push(err);
                    }

                    if (is_porta) {
                        const tel_porta_a = tel_porta.split('');
                        if (tel_porta_a.length === 0) {
                            let err = {
                                err_name: 'err_tel_porta',
                                err_msg_name: 'msg_tel_porta',
                                err_msg: 'Inserta teléfono'
                            }
                            arrayErrs.push(err);
                        } else if (tel_porta_a.length < 12) {
                            let err = {
                                err_name: 'err_tel_porta',
                                err_msg_name: 'msg_tel_porta',
                                err_msg: 'Teléfono a 10 digitos'
                            }
                            arrayErrs.push(err);
                        } else if (tel_porta === tel) {
                            let err = {
                                err_name: 'err_tel',
                                err_msg_name: 'msg_tel',
                                err_msg: 'No se puede usar el número a portar'
                            }
                            arrayErrs.push(err);
                        }

                        const nip_a = nip.split('');
                        if (nip_a.length === 0) {
                            let err = {
                                err_name: 'err_nip',
                                err_msg_name: 'msg_nip',
                                err_msg: 'Inserta NIP'
                            }
                            arrayErrs.push(err);
                        } else if (nip_a.length < 4 || nip_a.length > 4) {
                            let err = {
                                err_name: 'err_nip',
                                err_msg_name: 'msg_nip',
                                err_msg: 'NIP a 4 digitos'
                            }
                            arrayErrs.push(err);
                        }

                        if (carrier === '0000') {
                            let err = {
                                err_name: 'err_carrier',
                                err_msg_name: 'msg_carrier',
                                err_msg: 'Selecciona compañia'
                            }
                            arrayErrs.push(err);
                        }
                    }
                    let errorExist = arrayErrs.length > 0;
                    console.log(arrayErrs);
                    let newErrVals = personalDataErrors;
                    for (let i = 0; i < arrayErrs.length; i++) {
                        newErrVals = {
                            ...newErrVals,
                            [arrayErrs[i].err_name]: true,
                            [arrayErrs[i].err_msg_name]: [arrayErrs[i].err_msg],
                        }
                    }
                    setPersonalDataErrors(newErrVals)
                    if (errorExist) {
                        handleAncla();
                        return;
                    }

                    let data = {
                        ...dataSend,
                        xel_token: session.token,
                        step: activeStep,
                        identificacion_tipo_id: dataSend.identificacion_tipo_id ? dataSend.identificacion_tipo_id : '',
                        identificacion_image_one: '',
                        identificacion_image_one_src: dataSend.identificacion_image_one_src ? dataSend.identificacion_image_one_src : '',
                        identificacion_image_two: '',
                        identificacion_image_two_src: dataSend.identificacion_image_two_src ? dataSend.identificacion_image_two_src : '',

                        dir_postal_code: dataSend.dir_postal_code ? dataSend.dir_postal_code : '',
                        dir_colonia: dataSend.dir_colonia ? dataSend.dir_colonia : '',
                        dir_municipio: dataSend.dir_municipio ? dataSend.dir_municipio : '',
                        dir_ciudad: dataSend.dir_ciudad ? dataSend.dir_ciudad : '',
                        dir_estado: dataSend.dir_estado ? dataSend.dir_estado : '',
                        dir_calle: dataSend.dir_calle ? dataSend.dir_calle : '',
                        dir_numex: dataSend.dir_numex ? dataSend.dir_numex : '',
                        dir_numin: dataSend.dir_numin ? dataSend.dir_numin : '',
                        dir_referencias: dataSend.dir_referencias ? dataSend.dir_referencias : '',
                        dir_postal_code_e: dataSend.dir_postal_code_e ? dataSend.dir_postal_code_e : '',
                        dir_colonia_e: dataSend.dir_colonia_e ? dataSend.dir_colonia_e : '',
                        dir_municipio_e: dataSend.dir_municipio_e ? dataSend.dir_municipio_e : '',
                        dir_ciudad_e: dataSend.dir_ciudad_e ? dataSend.dir_ciudad_e : '',
                        dir_estado_e: dataSend.dir_estado_e ? dataSend.dir_estado_e : '',
                        dir_calle_e: dataSend.dir_calle_e ? dataSend.dir_calle_e : '',
                        dir_numex_e: dataSend.dir_numex_e ? dataSend.dir_numex_e : '',
                        dir_numin_e: dataSend.dir_numin_e ? dataSend.dir_numin_e : '',
                        dir_referencias_e: dataSend.dir_referencias_e ? dataSend.dir_referencias_e : '',
                    }
                    console.log(data);
                    dispatch(Actions.BUY_PROCESS_LOADING());
                    fetch('https://inxel.mx/api/xel/v1/ecommerce.customer.backup.json', {
                        method: 'POST',
                        body: JSON.stringify(data)
                    }).then(response => response.json())
                        .then(response => {
                            console.log(response, 'back');
                            //  return;
                            if (response.status === 'OK') {
                                dispatch(Actions.BUY_PROCESS_END());
                                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            }
                        }).catch(error => {
                        console.log(error)
                    });

                    break;
                /*  case 1:
                      setAlertData({
                          show: false,
                          type: 'warning',
                          title: 'Identificación requerida',
                          text: 'Se requieren las imagenes de la identificación'
                      });

                      if (dataSend.identificacion_tipo_id !== '2222') {
                          if (dataSend.identificacion_image_one === null || dataSend.identificacion_image_one === '' || typeof dataSend.identificacion_image_one === "undefined"
                              || dataSend.identificacion_image_two === null || dataSend.identificacion_image_two === '' || typeof dataSend.identificacion_image_two === "undefined"
                          ) {

                              setAlertData({
                                  show: true,
                                  type: 'warning',
                                  title: 'Identificación requerida',
                                  text: 'Se requieren las imagenes de la identificación'
                              });
                              dispatch(Actions.BUY_PROCESS_END());
                              return;
                          }
                      } else {
                          if (dataSend.identificacion_image_one === null || dataSend.identificacion_image_one === '' || typeof dataSend.identificacion_image_one === "undefined"
                          ) {
                              setAlertData({
                                  show: true,
                                  type: 'warning',
                                  title: 'Identificación requerida',
                                  text: 'Se requieren la imagen de la identificación'
                              });
                              dispatch(Actions.BUY_PROCESS_END());
                              return;
                          }
                      }

                      let datas = {
                          ...dataSend,
                          xel_token: session.token,
                          step: activeStep,
                          identificacion_tipo_id: dataSend.identificacion_tipo_id ? dataSend.identificacion_tipo_id : '',
                          identificacion_image_one: dataSend.identificacion_image_one ? dataSend.identificacion_image_one : '',
                          identificacion_image_one_src: dataSend.identificacion_image_one_src ? dataSend.identificacion_image_one_src : '',
                          identificacion_image_two: dataSend.identificacion_image_two ? dataSend.identificacion_image_two : '',
                          identificacion_image_two_src: dataSend.identificacion_image_two_src ? dataSend.identificacion_image_two_src : '',
                      }
                      console.log(datas);
                      dispatch(Actions.BUY_PROCESS_LOADING());
                      fetch('https://inxel.mx/api/xel/v1/ecommerce.customer.backup.json', {
                          method: 'POST',
                          body: JSON.stringify(datas)
                      }).then(response => response.json())
                          .then(response => {
                              console.log(response);
                              if (response.status === 'OK') {
                                  dispatch(Actions.BUY_PROCESS_END());
                                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                              }
                          }).catch(error => {
                          console.log(error)
                      });
                      break;*/
                case 2:
                    const {
                        cod_pos,
                        colonia,
                        municipio,
                        ciudad,
                        estado,
                        calle,
                        num_ex,
                        num_in,
                        referencias,
                        cod_pos_e,
                        colonia_e,
                        municipio_e,
                        ciudad_e,
                        estado_e,
                        calle_e,
                        num_ex_e,
                        num_in_e,
                        referencias_e,
                    } = dirData;
                    console.log('Direcciondata', dirData);
                    let arrayErrs2 = [];

                    if (cod_pos.split('').length === 0) {
                        let err = {
                            err_name: 'err_cod_pos',
                            err_msg_name: 'msg_cod_pos',
                            err_msg: 'Inserta código postal'
                        }
                        arrayErrs2.push(err);
                    } else if (cod_pos.split('').length < 5) {
                        let err = {
                            err_name: 'err_cod_pos',
                            err_msg_name: 'msg_cod_pos',
                            err_msg: 'Codigo inválido'
                        }
                        arrayErrs2.push(err);
                    }
                    if (colonia.split('').length === 0) {
                        let err = {
                            err_name: 'err_colonia',
                            err_msg_name: 'msg_colonia',
                            err_msg: 'Inserta colonia'
                        }
                        arrayErrs2.push(err);
                    }
                    if (municipio.split('').length === 0) {
                        let err = {
                            err_name: 'err_municipio',
                            err_msg_name: 'msg_municipio',
                            err_msg: 'Inserta municipio'
                        }
                        arrayErrs2.push(err);
                    }
                    if (ciudad.split('').length === 0) {
                        let err = {
                            err_name: 'err_ciudad',
                            err_msg_name: 'msg_ciudad',
                            err_msg: 'Inserta ciudad'
                        }
                        arrayErrs2.push(err);
                    }
                    if (estado.split('').length === 0) {
                        let err = {
                            err_name: 'err_estado',
                            err_msg_name: 'msg_estado',
                            err_msg: 'Inserta estado'
                        }
                        arrayErrs2.push(err);
                    }
                    if (calle.split('').length === 0) {
                        let err = {
                            err_name: 'err_calle',
                            err_msg_name: 'msg_calle',
                            err_msg: 'Inserta calle'
                        }
                        arrayErrs2.push(err);
                    }
                    if (num_ex.split('').length === 0) {
                        let err = {
                            err_name: 'err_num_ex',
                            err_msg_name: 'msg_num_ex',
                            err_msg: 'Inserta valor'
                        }
                        arrayErrs2.push(err);
                    }
                    if (num_in.split('').length === 0) {
                        let err = {
                            err_name: 'err_num_in',
                            err_msg_name: 'msg_num_in',
                            err_msg: 'Inserta valor'
                        }
                        arrayErrs2.push(err);
                    }
                    if (referencias.split('').length === 0) {
                        let err = {
                            err_name: 'err_rfc',
                            err_msg_name: 'msg_rfc',
                            err_msg: 'Inserta valor'
                        }
                        arrayErrs2.push(err);
                    }

                    if (sameDir === 'NO') {
                        if (cod_pos_e.split('').length === 0) {
                            let err = {
                                err_name: 'err_cod_pos_e',
                                err_msg_name: 'msg_cod_pos_e',
                                err_msg: 'Inserta código postal'
                            }
                            arrayErrs2.push(err);
                        } else if (cod_pos_e.split('').length < 5) {
                            let err = {
                                err_name: 'err_cod_pos_e',
                                err_msg_name: 'msg_cod_pos_e',
                                err_msg: 'Codigo inválido'
                            }
                            arrayErrs2.push(err);
                        }
                        if (colonia_e.split('').length === 0) {
                            let err = {
                                err_name: 'err_colonia_e',
                                err_msg_name: 'msg_colonia_e',
                                err_msg: 'Inserta colonia'
                            }
                            arrayErrs2.push(err);
                        }
                        if (municipio_e.split('').length === 0) {
                            let err = {
                                err_name: 'err_municipio_e',
                                err_msg_name: 'msg_municipio_e',
                                err_msg: 'Inserta municipio'
                            }
                            arrayErrs2.push(err);
                        }
                        if (ciudad_e.split('').length === 0) {
                            let err = {
                                err_name: 'err_ciudad_e',
                                err_msg_name: 'msg_ciudad_e',
                                err_msg: 'Inserta ciudad'
                            }
                            arrayErrs2.push(err);
                        }
                        if (estado_e.split('').length === 0) {
                            let err = {
                                err_name: 'err_estado_e',
                                err_msg_name: 'msg_estado_e',
                                err_msg: 'Inserta estado'
                            }
                            arrayErrs2.push(err);
                        }
                        if (calle_e.split('').length === 0) {
                            let err = {
                                err_name: 'err_calle_e',
                                err_msg_name: 'msg_calle_e',
                                err_msg: 'Inserta calle'
                            }
                            arrayErrs2.push(err);
                        }
                        if (num_ex_e.split('').length === 0) {
                            let err = {
                                err_name: 'err_num_ex_e',
                                err_msg_name: 'msg_num_ex_e',
                                err_msg: 'Inserta valor'
                            }
                            arrayErrs2.push(err);
                        }
                        if (num_in_e.split('').length === 0) {
                            let err = {
                                err_name: 'err_num_in_e',
                                err_msg_name: 'msg_num_in_e',
                                err_msg: 'Inserta valor'
                            }
                            arrayErrs2.push(err);
                        }
                        if (referencias_e.split('').length === 0) {
                            let err = {
                                err_name: 'err_rfc_e',
                                err_msg_name: 'msg_rfc_e',
                                err_msg: 'Inserta valor'
                            }
                            arrayErrs2.push(err);
                        }
                    }

                    let newErrVals2 = infoPagoErrors;
                    for (let i = 0; i < arrayErrs2.length; i++) {
                        newErrVals2 = {
                            ...newErrVals2,
                            [arrayErrs2[i].err_name]: true,
                            [arrayErrs2[i].err_msg_name]: [arrayErrs2[i].err_msg],
                        }
                    }
                    if (arrayErrs2.length > 0) {
                        setDirDataErrors(newErrVals2)
                        handleAncla();
                        return;
                    }

                    dispatch(Actions.BUY_PROCESS_LOADING());
                    let datass = {
                        ...dataSend,
                        xel_token: session.token,
                        step: activeStep,
                        identificacion_tipo_id: dataSend.identificacion_tipo_id ? dataSend.identificacion_tipo_id : '',
                        identificacion_image_one: '',
                        identificacion_image_one_src: '',
                        identificacion_image_two: '',
                        identificacion_image_two_src: '',
                    }
                    console.log(datass);
                    dispatch(Actions.BUY_PROCESS_LOADING());
                    fetch('https://inxel.mx/api/xel/v1/ecommerce.customer.backup.json', {
                        method: 'POST',
                        body: JSON.stringify(datass)
                    }).then(response => response.json())
                        .then(response => {
                            console.log(response);
                            if (response.status === 'OK') {
                                dispatch(Actions.BUY_PROCESS_END());
                                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            }
                        }).catch(error => {
                        console.log(error)
                    });
                    break;

                case 3:
                    let {
                        name_card,
                        card,
                        month,
                        year,
                        cvv,
                    } = infoPago;

                    let arrayErrs3 = [];
                    // eslint-disable-next-line no-undef
                    //console.log(OpenPayInstance.token.create, 's')

                    if (name_card.split('').length === 0) {
                        let err = {
                            err_name: 'err_name_card',
                            err_msg_name: 'msg_name_card',
                            err_msg: 'Inserta nombre completo'
                        }
                        arrayErrs3.push(err);
                    }
                    if (card.split('').length === 0) {
                        let err = {
                            err_name: 'err_card',
                            err_msg_name: 'msg_card',
                            err_msg: 'Inserta tarjeta'
                        }
                        arrayErrs3.push(err);
                    }
                    if (cvv.split('').length === 0) {
                        let err = {
                            err_name: 'err_cvv',
                            err_msg_name: 'msg_cvv',
                            err_msg: 'Inserta CVV'
                        }
                        arrayErrs3.push(err);
                    }

                    let newErrVals3 = infoPagoErrors;
                    for (let i = 0; i < arrayErrs3.length; i++) {
                        newErrVals3 = {
                            ...newErrVals3,
                            [arrayErrs3[i].err_name]: true,
                            [arrayErrs3[i].err_msg_name]: [arrayErrs3[i].err_msg],
                        }
                    }
                    setInfoPagoErrors(newErrVals3);

                    if (arrayErrs3.length > 0) {
                        handleAncla();
                        return;
                    }

                    dispatch(Actions.BUY_PROCESS_LOADING());
                    const form = new FormData();


                    //  if (OpenPayInstance.card.validateCardNumber(infoPago.card)) {
                    form.append('name', personalData.nombre);
                    form.append('apps', personalData.apellidos);
                    form.append('mail', personalData.mail);
                    form.append('phone', personalData.tel);
                    // eslint-disable-next-line no-undef
                    // form.append('id_dev', deviceSessionId);


                    let arrYear = (infoPago.year + "").split('');
                    console.log(arrYear)
                    let yr = arrYear[2] + arrYear[3];
                    OpenPayInstance.token.create({
                        "card_number": infoPago.card,
                        "holder_name": infoPago.name_card,
                        "expiration_year": yr,
                        "expiration_month": infoPago.month,
                        "cvv2": infoPago.cvv,
                        "address": {
                            "city": dirData.ciudad,
                            "line3": dirData.municipio,
                            "postal_code": dirData.cod_pos,
                            "line1": "No requerido",
                            "line2": "No requerido",
                            "state": dirData.estado,
                            "country_code": "MX"
                        }
                    }, (data) => {
                        //form.append('token_card', data.card.id)
                        console.log(data)

                        var deviceDataId = OpenPayInstance.deviceData.setup("frm_getcard");
                        console.log(
                            'd', deviceDataId
                        );
                        form.append('id_dev', deviceDataId);
                        form.append('token_card', data.data.id);
                        form.append('monto', planPrice);
                        console.log(data.data.id);
                        console.log(form);
                        const ffff = {
                            id: data.data.id,
                            id_dev: deviceDataId,
                            name: personalData.nombre,
                            'apps': personalData.apellidos,
                            'mail': personalData.mail,
                            'phone': personalData.tel,
                        }
                        fetch('https://inxel.mx/api/xel/v1/ecommerce.customer.charge_activation.json', // Production
                            // fetch('https://inxel.mx/api/xel/v1/ecommerce.customer.charge_activation.sandbox.json', //Sandbox
                            {
                                method: 'POST',
                                body: form
                            }
                        ).then(response => response.json())
                            .then(response => {
                                console.log(response, 'Respuesta compra');
                                if (response.result.error) {
                                    let openpayservice = new OpenPayLocalServices();
                                    let data = {
                                        xel_token: session.token,
                                        reason: openpayservice.getError(response.result.error_code).system
                                    }
                                    fetch('https://inxel.mx/api/xel/v1/ecommerce.lead.failed.buy.json', {
                                        method: 'POST',
                                        body: JSON.stringify(data)
                                    }).then(response => response.json())
                                        .then(response => {
                                            console.log(response);
                                        }).catch(error => {
                                        console.log(error);
                                    });
                                    setInfoPagoErrors({
                                        status_pay: true,
                                        msg_pay: openpayservice.getError(response.result.error_code).user
                                    });
                                    dispatch(Actions.BUY_PROCESS_END());

                                } else if (response.result.success) {
                                    dispatch(Actions.SET_DATA({
                                        ...dataSend,
                                        pay_order_id: response.result.order,
                                    }));
                                    const data = {
                                        ...dataSend,
                                        pay_order_id: response.result.order,
                                        plan: plan,
                                        dir_postal_code_e: sameDir === 'SI' ? dataSend.dir_postal_code : dirData.cod_pos_e,
                                        dir_colonia_e: sameDir === 'SI' ? dataSend.dir_colonia : dirData.colonia_e,
                                        dir_municipio_e: sameDir === 'SI' ? dataSend.dir_municipio : dirData.municipio_e,
                                        dir_ciudad_e: sameDir === 'SI' ? dataSend.dir_ciudad : dirData.ciudad_e,
                                        dir_estado_e: sameDir === 'SI' ? dataSend.dir_estado : dirData.estado_e,
                                        dir_calle_e: sameDir === 'SI' ? dataSend.dir_calle : dirData.calle_e,
                                        dir_numex_e: sameDir === 'SI' ? dataSend.dir_numex : dirData.num_ex_e,
                                        dir_numin_e: sameDir === 'SI' ? dataSend.dir_numin : dirData.num_in_e,
                                        dir_referencias_e: sameDir === 'SI' ? dataSend.dir_referencias : dirData.referencias_e,
                                        identificacion_image_one_src: '',
                                        identificacion_image_two_src: ''
                                    }
                                    console.log(data)
                                    fetch('https://inxel.mx/api/xel/v1/ecommerce.first.buy.json', {
                                        method: 'POST',
                                        body: JSON.stringify(data)
                                    }).then(response => response.text())
                                        .then(responses => {
                                            console.log(responses, 'nego')
                                            //  return;
                                            //  return;
                                            let data = {
                                                xel_token: session.token
                                            }
                                            fetch('https://inxel.mx/api/xel/v1/ecommerce.clean.backup.json', {
                                                method: 'POST',
                                                body: JSON.stringify(data)
                                            }).then(responses => responses.json())
                                                .then(responses => {
                                                    console.log(responses);
                                                    dispatch(Actions.BUY_PROCESS_END());
                                                    setPayed({
                                                        completed: true,
                                                        data: response.result,
                                                        card: {
                                                            infoPago,
                                                            dirData,
                                                            personalData
                                                        }
                                                    });
                                                }).catch(error => {
                                                console.log('Error al limpiar ')
                                                console.log(error);
                                            });
                                        }).catch(error => {
                                        console.log(error);
                                        console.log('Error al enviar ');
                                    })
                                }
                                console.log(response);
                                /**/
                            }).catch(error => {
                            alert('Hubo un Error2');

                            console.log('Error');
                            console.log(error);
                            if (error.error_code === 2005) {

                                setInfoPagoErrors({
                                    status_pay: true,
                                    msg_pay: 'Tarjeta vencida'
                                });

                            }

                            dispatch(Actions.BUY_PROCESS_END());
                        });
                    }, (error) => {
                        //alert('Hubo un Error');
                        if (error.data.error_code === 1018) {
                            setInfoPagoErrors({
                                status_pay: true,
                                msg_pay: 'Error desconocido'
                            });
                        } else if (error.data.error_code === 2005) {

                            setInfoPagoErrors({
                                status_pay: true,
                                msg_pay: 'Tarjeta vencida'
                            });

                        } else {
                            setInfoPagoErrors({
                                status_pay: true,
                                msg_pay: 'Error de conexión, intente de nuevo.'
                            });
                        }
                        dispatch(Actions.BUY_PROCESS_END());
                        console.log(error.data, 'Error tokens')
                        //  dispatch(Actions.BUY_PROCESS_END());
                    });
                    /* } else {
                         setInfoPagoErrors({
                             ...infoPagoErrors,
                             err_card: true,
                             msg_card: 'Tarjeta invalida'
                         })
                     }*/

                    break;
                default:

                    break;
            }
        }
    ;

    const handleAncla = () => {
        const el = document.getElementById('steps-to-buy');
        const y = el.getBoundingClientRect().top;
        window.scrollTo(0, y + 80);

        /*  setAlertData({
            type: 'error',
            title: 'Error',
            text: 'Error en datos',
            show: true
        })
*/
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const getProps = (num) => {
        switch (num) {
            case  0:
                return {
                    personalData,
                    setPersonalData,
                    carriers,
                    setPersonalDataErrors,
                    personalDataErrors,
                };
            case 1 :
                return {
                    alertData,
                    setAlertData,
                    idData,
                    setIdData,
                }
            case 2:
                return {
                    dirData,
                    setDirData,
                    dirDataErrors,
                    setDirDataErrors,
                    sameDir,
                    setSameDir,
                    matches
                }
            default:
                return {
                    infoPago,
                    setInfoPago,
                    infoPagoErrors,
                    setInfoPagoErrors,
                }
        }
    }
    const getProperties = () => {
        switch (activeStep) {
            case  0:
                return {
                    personalData,
                    setPersonalData,
                    carriers,
                    setPersonalDataErrors,
                    personalDataErrors,
                    alertData,
                    setAlertData,
                    idData,
                    setIdData,
                };
            case 1:
                return {
                    dirData,
                    setDirData,
                    dirDataErrors,
                    setDirDataErrors
                }
            default:
                return {
                    infoPago,
                    setInfoPago,
                    infoPagoErrors,
                    setInfoPagoErrors,
                }
        }
    }

    return (
        <>
            {
                alertData.show ? <div style={{position: 'fixed', bottom: '5px', zIndex: '3'}}>
                    <Alert variant="outlined" style={{backgroundColor: '#fefefe'}} severity={alertData.type}
                           onClose={() => {
                               setAlertData({
                                   ...alertData,
                                   show: false
                               })
                           }}>
                        <AlertTitle> {alertData.title} </AlertTitle>
                        {alertData.text}
                    </Alert>
                </div> : ''
            }
            {
                matches ? <div className={classes.root} style={{border: '.5px solid rgba(0,0,0,0.2)', borderRadius: 5}}
                               id={'steps-to-buy'}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector/>}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel classes={{label: styles.label_match, active: styles.label_active}}
                                               StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {activeStep === steps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>
                                        Pasos completados
                                    </Typography>
                                    <Button onClick={handleReset} className={classes.button}>
                                        Reset
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    {loaded ? <>
                                            {
                                                activeStep === 0 ? <Compatibilidad option={option} next={handleNext}/> : ''
                                            }
                                            {

                                                activeStep === 1 ? <>
                                                    <PersonalData {...getProps(0)}/>
                                                    <br/>
                                                    <IdData {...getProps(1)}/>
                                                </> : ''
                                            }
                                            {
                                                activeStep === 2 ? <DirData {...getProps(2)}/> : ''
                                            }
                                            {
                                                activeStep === 3 ? <Payment {...getProperties()}/> : ''
                                            }

                                            {
                                                activeStep !== 0 ? <Grid container justify={'center'}>
                                                    <Grid item xs={12} md={6} style={{
                                                        marginTop: '35px',
                                                        marginBottom: '30px',
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>
                                                        {
                                                            loading ? <>
                                                                <Button
                                                                    disabled={activeStep === 0 || loading}
                                                                    // onClick={handleBack}
                                                                    className={classes.button}
                                                                >
                                                                    Volver
                                                                </Button>
                                                                <Button
                                                                    disabled={loading}
                                                                    variant="contained"
                                                                    //color={''}
                                                                    startIcon={<CircularProgress
                                                                        color={'secondary'}/>}
                                                                    //  onClick={handleNext}
                                                                    className={classes.button}
                                                                >
                                                                    {activeStep === steps.length - 1 ? 'Procesando' : 'Siguiente'}
                                                                </Button>
                                                            </> : <>
                                                                <Button
                                                                    // disabled={activeStep === 0 || loading}
                                                                    onClick={handleBack}
                                                                    className={classes.button}

                                                                >
                                                                    Volver
                                                                </Button>
                                                                <Button
                                                                    //  disabled={loading}
                                                                    variant="contained"
                                                                    style={{
                                                                        backgroundColor: '#ff7600',
                                                                        color: 'white'
                                                                    }}
                                                                    //color={''}
                                                                    onClick={handleNext}
                                                                    className={classes.button}
                                                                >
                                                                    {activeStep === steps.length - 1 ? 'Comprar' : 'Siguiente'}
                                                                </Button>
                                                            </>
                                                        }
                                                    </Grid>
                                                </Grid> : ''
                                            }
                                        </>
                                        :
                                        <div className={styles.skeleton_dad}>
                                            <div className={styles.skeleton_container}>
                                                <Skeleton/>
                                                <Skeleton animation={false}/>
                                                <Skeleton animation="wave"/>
                                                <Skeleton animation={false}/>
                                                <Skeleton animation="wave"/>
                                            </div>
                                        </div>}
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    <div className={classes.root} id={'steps-to-buy'}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel classes={{label: styles.label_nomatch, active: styles.label_active}}
                                               StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    <StepContent>
                                        {
                                            loaded ? <>
                                                    {
                                                        activeStep === 0 ?
                                                            <Compatibilidad option={option} next={handleNext}/> : ''
                                                    }
                                                    {

                                                        activeStep === 1 ? <>
                                                            <PersonalData {...getProps(0)}/>
                                                            <br/>
                                                            <IdData {...getProps(1)}/>
                                                        </> : ''
                                                    }

                                                    {
                                                        activeStep === 2 ? <DirData {...getProps(2)}/> : ''
                                                    }
                                                    {
                                                        activeStep === 3 ? <Payment {...getProperties()}/> : ''
                                                    }

                                                    {activeStep === 0 ? '' :
                                                        <div style={{marginTop: '20px'}}>
                                                            <div>

                                                                {
                                                                    loading ? <>
                                                                        <Button
                                                                            disabled={activeStep === 0 || loading}
                                                                            // onClick={handleBack}
                                                                            className={classes.button}
                                                                        >
                                                                            Volver
                                                                        </Button>
                                                                        <Button
                                                                            disabled={loading}
                                                                            variant="contained"
                                                                            //color={''}
                                                                            startIcon={<CircularProgress
                                                                                color={'secondary'}/>}
                                                                            //  onClick={handleNext}
                                                                            className={classes.button}
                                                                        >
                                                                            {activeStep === steps.length - 1 ? 'Procesando' : 'Siguiente'}
                                                                        </Button>
                                                                    </> : <>
                                                                        <Button
                                                                            // disabled={activeStep === 0 || loading}
                                                                            onClick={handleBack}
                                                                            className={classes.button}
                                                                        >
                                                                            Volver
                                                                        </Button>
                                                                        <Button
                                                                            //  disabled={loading}
                                                                            variant="contained"
                                                                            style={{
                                                                                backgroundColor: '#ff7600',
                                                                                color: 'white'
                                                                            }}
                                                                            //color={''}
                                                                            onClick={handleNext}
                                                                            className={classes.button}
                                                                        >
                                                                            {activeStep === steps.length - 1 ? 'Comprar' : 'Siguiente'}
                                                                        </Button>
                                                                    </>
                                                                }

                                                            </div>
                                                        </div>
                                                    }

                                                </>
                                                :
                                                <div className={styles.skeleton_dad}>
                                                    <div className={styles.skeleton_container}>
                                                        <Skeleton/>
                                                        <Skeleton animation={false}/>
                                                        <Skeleton animation="wave"/>
                                                        <Skeleton animation={false}/>
                                                        <Skeleton animation="wave"/>
                                                    </div>
                                                </div>
                                        }
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} className={classes.resetContainer}>
                                <Typography>Completado</Typography>
                                <Button onClick={handleReset} className={classes.button}
                                        style={{backgroundColor: '#ff7600', color: 'white'}}>
                                    Restaurar
                                </Button>
                            </Paper>
                        )}
                    </div>
            }
        </>
    );
}
