import React, {useEffect, useState} from "react";
import {Container, Grid, Hidden, PropTypes, Typography, useMediaQuery, withStyles} from "@material-ui/core";
import {useParams, useLocation, useHistory} from 'react-router-dom';
import NavBar from "../../ui/NavBar";
import Footer from "../../ui/Footer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Samsung from '../../../assets/images/phones/Sasmsuns_a01.png';
import {makeStyles} from "@material-ui/core/styles";
import {PromosPlan, XEL_COLORS, XEL_FONT} from "../../../constant";
import {useResponsiveValues} from "../../utils/hooks";
import SmartPhone from './assets/smartphone.svg';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import GifCarro from "../Inicio/GifCar/Car";
import Button from "@material-ui/core/Button";
import CelService from "../../utils/services/celulares";
import NumberFormat from "react-number-format";
import DeviceService from "../../utils/services/dispositivos";

const useStyles = makeStyles({
    img: {
        width: '100%',
        maxWidth: 200,
        height: '70%'
    },
    name: {
        fontWeight: XEL_FONT.bold_weight
    },
    subtitle: {
        fontWeight: XEL_FONT.semi_bold_weight,
        color: '#FEFEFE',
    },
    price_container: {
        borderRadius: 12,
        overflow: "hidden",

    },
    price_head: {
        backgroundColor: '#F3F2F5',
        padding: '.7% 0'
    }
});

const BuyButton = withStyles({
    root: {
        borderRadius: 14,

        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,

        border: `1px solid ${XEL_COLORS.primary}`,
        '&:hover': {
            backgroundColor: XEL_COLORS.secondary,
            color: '#FEFEFE',
            border: `1px solid #FEFEFE`,
        },

    }
})(Button);

const Loading = () => {
    return <Grid container style={{minHeight: '70vh'}} justify={'center'} alignItems={'center'}>
        <Grid item xs={12} style={{display: "flex", justifyContent: "center", height: '100%', alignItems: "center"}}>
            <CircularProgress color={'primary'}/>
        </Grid>
    </Grid>
}


const Error = ({msg}) => {
    return <Grid container style={{minHeight: '70vh'}} justify={'center'} alignItems={'center'}>
        <Grid item xs={12} style={{
            display: "flex",
            justifyContent: "center",
            height: '100%',
            alignItems: "center",
            flexDirection: "column"
        }}>
            <Typography>
                {msg}
            </Typography>
            <Typography>
                <a href="/">Volver a inicio</a>
            </Typography>
        </Grid>
    </Grid>
}


const Detalle = ({data, isPack}) => {
    const styles = useStyles();
    const superTitleSize = useResponsiveValues(30, 25, 20);
    const nameSize = useResponsiveValues(25, 20, 15);
    const subSize = useResponsiveValues(20, 15, 12);
    const normalSize = useResponsiveValues(15, 13, 12)
    const [plan, setPlan] = useState('XEL5');
    const promos = PromosPlan();
    const [priceP, setPriceP] = useState(promos.XEL5p.price);

    const {id, name, price, image, details} = data;
    const history = useHistory();
    useEffect(() => {
        if (plan === 'XEL5')
            setPriceP(promos.XEL5p.price)
        else if (plan === 'XELIL')
            setPriceP(promos.XEL40.price)
    }, [plan]);

    const handleChangePlan = e => {
        setPlan(e.target.value);
    }

    const handleClick = () => {
        // eslint-disable-next-line no-undef
        gtag("click_lo_quiero", {
            "tipo": "Celular + Plan",
            "celular": name,
            "plan": plan,
        });
        if (plan === 'XEL5')
            history.push('/comprar/equipo-plan/' + promos.XEL5p.id_original + '/' + id);
        else if (plan === 'XELIL')
            history.push('/comprar/equipo-plan/' + promos.XEL40.id_original + '/' + id);

    }
    return <Grid container>
        <Grid item xs={12} style={{padding: '3% 0'}}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} style={{display: "flex", justifyContent: "center", alignItems: 'center'}}>
                    <img src={image} alt={name} className={styles.img}/>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className={styles.name} color={'primary'} style={{fontSize: nameSize}}>
                                {name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}
                              style={{backgroundColor: XEL_COLORS.primary, padding: '2%', marginBottom: '3%'}}>
                            <Grid container>
                                <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                                    <img src={SmartPhone} alt="icono"
                                         style={{maxWidth: 30, width: '100%', height: '100%'}}/>
                                    <Typography className={styles.subtitle} style={{paddingLeft: 5, fontSize: subSize}}>
                                        Equipo + Plan Xel
                                    </Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container style={{padding: '2%'}}>
                                        <Grid item xs={12} className={styles.price_container}>
                                            <Grid container>
                                                <Grid item xs={12} className={styles.price_head}>
                                                    <Typography color={'primary'} align={'center'}>
                                                        Precio Total del Equipo
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}
                                                      style={{backgroundColor: '#FEFEFE', padding: '.5% 0'}}>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item xs={5}
                                                              style={{
                                                                  display: "flex",
                                                                  justifyContent: "space-around",
                                                                  alignItems: "center",
                                                                  paddingRight: '5%'
                                                              }}>
                                                            <Typography align={'center'}
                                                                        style={{
                                                                            fontSize: superTitleSize,
                                                                            fontWeight: XEL_FONT.bold_weight
                                                                        }}>
                                                                <NumberFormat thousandSeparator={true}
                                                                              prefix={'$'}
                                                                              displayType={'text'}
                                                                              value={price}/>

                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    fontSize: useResponsiveValues(30, 25, 20),
                                                                    color: XEL_COLORS.primary
                                                                }}>
                                                                +
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <FormControl fullWidth>
                                                                <InputLabel
                                                                    id="planselector"
                                                                    style={{fontSize: normalSize}}>Plan</InputLabel>
                                                                <Select

                                                                    labelId="planselector"
                                                                    id="demo-customized-select"
                                                                    value={plan}
                                                                    onChange={handleChangePlan}
                                                                    style={{fontSize: normalSize}}
                                                                    //   input={<BootstrapInput />}
                                                                >
                                                                    <MenuItem value={'XEL5'}
                                                                              style={{fontSize: normalSize}}>Xel
                                                                        5 +</MenuItem>
                                                                    <MenuItem value={'XELIL'}
                                                                              style={{fontSize: normalSize}}>Xel
                                                                        40</MenuItem>
                                                                </Select>
                                                                <FormHelperText
                                                                    style={{fontSize: normalSize}}>{`$ ${priceP}.00`}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={3}
                                                              style={{display: "flex", justifyContent: "center"}}>
                                                            <BuyButton onClick={handleClick}
                                                                       style={{fontSize: subSize}}>¡Lo
                                                                quiero!</BuyButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography color={'primary'} style={{
                                        fontSize: subSize,
                                        fontWeight: XEL_FONT.bold_weight
                                    }}>
                                        Detalles técnicos
                                    </Typography>
                                </Grid>
                                {
                                    //Detalles
                                }
                                <Grid item xs={12}>
                                    <Grid container>

                                        {
                                            details.map(detail => <Grid item xs={6} style={{marginTop: '2%'}}>
                                                <Typography
                                                    style={{
                                                        fontWeight: XEL_FONT.semi_bold_weight,
                                                        fontSize: normalSize
                                                    }}>
                                                    {detail.title}
                                                </Typography>
                                                <Typography style={{fontSize: normalSize}}>
                                                    {detail.value}
                                                </Typography>
                                            </Grid>)
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: 10, marginTop: '3%'}}>
                                *Precio no incluye costo de envío, este se definirá dependiendo zona de contratación.
                            </Typography>
                        </Grid>
                        {
                            /* <Grid item xs={12}>
                                 <Typography style={{fontSize: 10, marginTop: '3%'}}>
                                     En la compra de equipo + plan, recibiras el descuento mostrado en la renta mensual
                                     del
                                     plan que selecciones durante 12 meses.
                                 </Typography>
                             </Grid> : ''*/
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}


export default function DetalleCelular() {
    const params = useParams();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({
        isError: false,
        msg: ''
    });
    const [data, setData] = useState('');
    const history = useHistory();
    const celularService = DeviceService();
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Detalles equipo";
        const id = params.idcel;
        if (id) {
            celularService.getCel(id).then(res => {
                setData(res);
                setLoaded(true);
            }).catch(error => {
                setError({
                    isError: true,
                    msg: error
                });
            })
        }
    }, []);

    return <div style={{scrollBehavior: 'smooth',}}>
        <NavBar/>
        <Grid container style={{margin: '4% 0', backgroundColor: "#F6F6F6"}}>
            <Container>
                <Grid item xs={12}>
                    {
                        error.isError ? <Error msg={error.msg}/> : loaded ?
                            <Detalle data={data}/> : <Loading/>
                    }
                </Grid>
            </Container>
        </Grid>
        <Footer/>
    </div>;
}
