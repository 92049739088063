import React, {useEffect, useState} from "react";
import NavBar from "../../ui/NavBar";
import {Container, Grid} from "@material-ui/core";
import Footer from "../../ui/Footer";
import PacksSection from "../Inicio/PacksSection";

export default function Packs() {
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - XelPacks";
    }, [])
    return <div style={{scrollBehavior: 'smooth',}}>
        <NavBar/>
        <Grid container style={{margin: '4% 0', backgroundColor: "#F6F6F6"}}>
            <Container>
                <Grid item xs={12}>
                    <PacksSection All/>
                </Grid>
            </Container>
        </Grid>
        <Footer/>
    </div>;
}
