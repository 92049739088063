import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../../actions';
import {usePhoneFormater} from "../../utils/hooks";
import Cookie from 'js-cookie';
import {useHistory} from 'react-router-dom';
import {XEL_COLORS, XEL_FONT} from "../../../constant";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import Axios from "../../../instances/axios";

const { primary2, primary_shiny, primary_dark } = XEL_COLORS

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '60vh'
    },
    msg_nop: {
        fontSize: 13,
        color: '#000000',
        fontWeight: XEL_FONT.semi_bold_weight
    }
    ,
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function LoginForm({register}) {
    const classes = useStyles();
    const mailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const dispatch = useDispatch();
    const session = useSelector(state => state.login);
    const [login, setLogin] = useState(true);
    const [onLoad, setOnLoad] = useState(false);
    const [formatPhone, setFormatPhone] = usePhoneFormater('');
    const [loaded, setLoaded] = useState(false);

    const defaultData = {
        name: '',
        apps: '',
        email: '',
        phone: '',
        password: '',
        repassword: '',
        code: ''
    };
    const [data, setData] = useState(defaultData);
    const [open, setOpen] = useState({
        open: false,
        msg: '',
        type: 'error'
    })
    const [recoveryMail, setRecoveryMail] = useState(false);
    const [recoveryPassword, setRecoveryPassword] = useState(false);
    const [remember, setRemember] = useState(true);
    const defaultDataErrors = {
        name: false,
        name_m: '',
        apps: false,
        apps_m: '',
        email: false,
        email_m: '',
        phone: false,
        phone_m: '',
        password: false,
        password_m: '',
        repassword: false,
        repassword_m: '',
        code: false,
        code_m: ''
    };
    const [dataErrors, setDataErrors] = useState(defaultDataErrors);
    const [option, setOption] = useState(register ? 'REGISTER' : 'LOGIN');
    const history = useHistory();
    const {name, apps, email, phone, password, repassword, code} = data;
    const [iDist, setIdist] = useState(false);
    const axios = Axios();
    const [socialReady,setSocialReady] = useState({
        facebook:false
    })
    useEffect(() => {
        // eslint-disable-next-line no-undef
        if(FB){
            setSocialReady(prevState => ({...prevState,facebook: true}));
        }
        if (session.logged) {
            history.push('/');
        } else {
            setLoaded(true);
        }
    }, [])
    useEffect(() => {
        setData({
            ...data,
            'phone': formatPhone
        });
        setDataErrors({
            ...dataErrors,
            'phone': false
        });
    }, [formatPhone]);
    const handleChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
        setDataErrors({
            ...dataErrors,
            [e.target.name]: false
        })
    }

    const handlePhone = e => {
        setFormatPhone(e.target.value);
    }

    const handleFacebookLogin = () => {
        setOnLoad(true);
        // eslint-disable-next-line no-undef
        FB.login(function (response) {
            console.log('Respuesta de Login', response);
            const {status, authResponse} = response;
            if (status === 'connected') {
                const {userId, accessToken} = authResponse;
                axios('/clients/oauth/socials/facebook', {
                    params: {
                        accessToken
                    }
                }).then(res => {
                    const {data:{token}} = res;
                    sessionStorage.setItem("xel_token", token);
                    Cookie.set('xel_token', token, {expires: 365});
                    window.location.reload();
                }).catch(err => {
                    setOpen({
                        open: true,
                        msg: "Hubo un problema para completar tu autorizacion.",
                        type: 'error'
                    });
                });

            } else {
                setOpen({
                    open: true,
                    msg: "No pudo obtenerse la autorización.",
                    type: 'error'
                });
            }
            // handle the response
        }, {scope: 'public_profile,email'});

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let errors = dataErrors;

        if (email.trim().split('').length === 0) {
            errors.email = true;
            errors.email_m = 'Introduce correo';
        } else if (!mailReg.test(email.trim().toLowerCase())) {
            errors.email = true;
            errors.email_m = 'Correo inválido';
        }
        if (option === 'LOGIN' || option === 'REGISTER' || option === 'RECOVERY_PASSWORD')
            if (password.split('').length === 0) {
                errors.password = true;
                errors.password_m = 'Introduce contraseña';
            }

        if (option === 'RECOVERY_PASSWORD')
            if (code.split('').length === 0) {
                errors.code = true;
                errors.code_m = 'Inserta tu código';
            }
        if (option === 'RECOVERY_PASSWORD') {
            if (repassword.split('').length === 0) {
                errors.repassword = true;
                errors.repassword_m = 'Repite contraseña';
            } else if (password !== repassword) {
                errors.repassword = true;
                errors.repassword_m = 'Las contraseñas no coinciden';
            }
        }
        if (option === 'REGISTER') {
            if (name.split('').length === 0) {
                errors.name = true;
                errors.name_m = 'Introduce tu nombre';
            }
            if (apps.split('').length === 0) {
                errors.apps = true;
                errors.apps_m = 'Introduce tus apellidos';
            }
            if (phone.split('').length === 0) {
                errors.phone = true;
                errors.phone_m = 'Introduce teléfono';
            } else if (phone.trim().split('').length > 12 || phone.trim().split('').length < 12) {
                errors.phone = true;
                errors.phone_m = 'Número inválido';
            }

            if (repassword.split('').length === 0) {
                errors.repassword = true;
                errors.repassword_m = 'Repite contraseña';
            } else if (password !== repassword) {
                errors.repassword = true;
                errors.repassword_m = 'Las contraseñas no coinciden';
            }
        }
        if (errors.password || errors.email || errors.code || errors.repassword) {
            setDataErrors({...errors});
            return;
        }
        setOnLoad(true);
        if (option === 'LOGIN') {
            let form = new FormData();
            form.append('email', data.email.trim().toLowerCase());
            form.append('password', data.password);

            if (iDist) {
                axios.post('/distributor/login', {
                    email: data.email.trim().toLowerCase(),
                    password: data.password
                }).then(res => {
                    alert('Seras redireccionado');
		    window.location.href = 'https://distribuidor.inxel.mx/?auth=' + res.data.token;
                }).catch(err => {
                    setOnLoad(false);
                    setOpen({
                        open: true,
                        type: 'error',
                        msg: 'Correo o contraseña incorrectas'
                    })
                });

            } else {
                axios.post("/clients/login/v2", {email: data.email, password: data.password}).then(res => {

                    let data = res.data;
                    if (data.error === "") {
                        setOnLoad(false);
                        setOpen({
                            open: true,
                            type: 'warning',
                            msg: data.error_description
                        });
                        return;
                    }
                    let token = res.data.token;
                    sessionStorage.setItem("xel_token", token);
                    Cookie.set('xel_token', token, {expires: 365});
                    window.location.reload();
                }).catch(err => {
                    setOnLoad(false);
                    console.error(err);
                    setOpen({
                        open: true,
                        type: 'error',
                        msg: 'Correo o contraseña incorrectas'
                    })
                });
            }


        } else if (option === 'REGISTER') {
            axios.post("/clients/register/v2", {
                origin: "site",
                name,
                apps,
                mail: data.email.trim().toLowerCase(),
                password: data.password,
                phone: data.phone.split(" ").join('')
            }).then(res => {
                let response = res.data;
                if (response.success) {
                    let token = response.token;
                    sessionStorage.setItem("xel_token", token);
                    Cookie.set('xel_token', token, {expires: 1});
                    window.location.reload();
                    return;
                }
                setOnLoad(false);
                setOpen({
                    open: true,
                    type: 'error',
                    msg: response.error_description
                });


            }).catch(err => {
                console.error(err);
                setOpen({
                    open: true,
                    type: 'error',
                    msg: 'Error de conexión'
                });
                setOnLoad(false);
            })
        } else if (option === 'RECOVERY') {
            sendEmailRecovery();
        } else if (option === 'RECOVERY_PASSWORD') {
            sendPasswordRecovery();
        }
    }
    const sendEmailRecovery = () => {
        setOnLoad(true);
        axios.post("/clients/email.recovery", {
            email: data.email
        }).then(res => {
            let response = res.data;
            if (response.error === '') {
                setOpen({
                    open: true,
                    msg: response.error_description,
                    type: 'error'
                });
                // dispatch(Actions.SET_MESSAGE(, 'error'));
                setOnLoad(false);
                return;
            }
            setOption('RECOVERY_PASSWORD');
            setOnLoad(false);
        }).catch(err => {
            console.error(err);
            setOpen({
                open: true,
                msg: 'Error, intenta de nuevo',
                type: 'error'
            });
            setOnLoad(false);
        });
        // dispatch(Actions.SET_MESSAGE('Inserta correo válido', 'error'));
    }
    const sendPasswordRecovery = () => {
        setOnLoad(true);
        axios.post("/clients/password.recovery", {
            email,
            code,
            password
        }).then(res => {
            let response = res.data;
            if (response.error === '') {
                setOpen({
                    open: true,
                    msg: response.description,
                    type: 'error'
                });
                setOnLoad(false);
                return;
            }

            setOpen({
                open: true,
                msg: 'Contraseña actualizada',
                type: 'success'
            });
            setOnLoad(false);
            setOption('LOGIN');
        }).catch(err => {
            console.error(err);
            setOpen({
                open: true,
                msg: 'Error, intenta de nuevo',
                type: 'error'
            });
            setOnLoad(false);
        });
    }
    const handleToggle = () => {
        if (option === 'LOGIN')
            setOption('REGISTER');
        else
            setOption('LOGIN');
        setData(defaultData);
        setDataErrors(defaultDataErrors)
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen({
            ...open,
            open: false
        });
    };
    return (
        <>
            {
                loaded ? <Container component="main" maxWidth="xs">
                    {
                        <Snackbar open={open.open} autoHideDuration={3500} onClose={handleClose}>
                            <Alert onClose={handleClose} severity={open.type}>
                                {open.msg}
                            </Alert>
                        </Snackbar>
                    }
                    <CssBaseline/>
                    <div className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                <Avatar className={classes.avatar}>
                                    <LockOutlinedIcon/>
                                </Avatar>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="h1" variant="h5" align={'center'}>
                                    {
                                        option === 'LOGIN' ? 'Iniciar sesión' : ''
                                    }
                                    {
                                        option === 'REGISTER' ? 'Registrarse' : ''
                                    }
                                    {
                                        option === 'RECOVERY' || option === 'RECOVERY_PASSWORD' ? 'Recuperar contraseña' : ''
                                    }
                                </Typography>
                            </Grid>

                            {
                                (option === 'LOGIN' || option === 'REGISTER' &&socialReady.facebook ) && <Grid item xs={12}>
                                    <div onClick={handleFacebookLogin}
                                         style={{backgroundColor: "#1877f2", borderRadius: 10, cursor: "pointer"}}>
                                        <Typography style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            padding: 5,
                                            color: "#FEFEFE",
                                            alignItems: "center"
                                        }}>
                                            <img style={{margin: 5, height: 24, width: 24}}
                                                 src={'https://static.xx.fbcdn.net/rsrc.php/v3/y3/r/U7MAWJlE6hZ.png'}
                                                 alt={'Facebook'}/> Continuar con Facebook
                                        </Typography>
                                    </div>
                                </Grid>
                            }
                            {
                                (option === 'LOGIN' || option === 'REGISTER'  &&socialReady.facebook) && <Grid item xs={12}>
                                    <Typography align={'center'}>
                                        - O -
                                    </Typography>
                                </Grid>
                            }
                        </Grid>


                        {
                            option === 'RECOVERY' ? <Grid item xs={10}>
                                <Typography className={classes.msg_nop} align={'center'}>
                                    Inserta tu correo electrónico
                                </Typography>
                            </Grid> : ""
                        }
                        {
                            option === 'RECOVERY_PASSWORD' ? <Grid item xs={10}>
                                <Typography className={classes.msg_nop} align={'center'}>
                                    Inserta el código que se envío a tu correo electrónico e ingresa tu nueva
                                    contraseña
                                </Typography>
                            </Grid> : ""
                        }
                        <form className={classes.form} noValidate onSubmit={handleSubmit}>
                            {
                                option === 'REGISTER' ? <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Nombre"
                                    name="name"
                                    autoComplete="name"
                                    autoFocus
                                    value={name}
                                    onChange={handleChange}
                                    error={dataErrors.name}
                                    helperText={dataErrors.name ? dataErrors.name_m : ''}
                                    disabled={onLoad}
                                /> : ''
                            }
                            {
                                option === 'REGISTER' ? <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="apps"
                                    label="Apellidos"
                                    name="apps"
                                    autoComplete="lastname"
                                    autoFocus
                                    value={apps}
                                    onChange={handleChange}
                                    error={dataErrors.apps}
                                    helperText={dataErrors.apps ? dataErrors.apps_m : ''}
                                    disabled={onLoad}
                                /> : ''
                            }
                            {
                                option === 'RECOVERY' || option === 'LOGIN' || option === 'REGISTER' ? <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo electrónico"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={email}
                                    onChange={handleChange}
                                    error={dataErrors.email}
                                    helperText={dataErrors.email ? dataErrors.email_m : ''}
                                    disabled={onLoad}
                                /> : ''
                            }
                            {
                                option === 'REGISTER' ?
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="phone"
                                        label="Teléfono de contacto"
                                        type="text"
                                        id="phone"
                                        value={formatPhone}
                                        onChange={handlePhone}
                                        error={dataErrors.phone}
                                        disabled={onLoad}
                                        helperText={dataErrors.phone ? dataErrors.phone_m : ''}
                                    /> : ''}

                            {
                                option === 'RECOVERY_PASSWORD' ?
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="code"
                                        label="Código de verificacíon"
                                        type="text"
                                        id="code"
                                        value={code}
                                        onChange={handleChange}
                                        error={dataErrors.code}
                                        disabled={onLoad}
                                        helperText={dataErrors.code ? dataErrors.code_m : ''}
                                    /> : ''}
                            {
                                option === 'LOGIN' || option === 'RECOVERY_PASSWORD' || option === 'REGISTER' ?
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Contraseña"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        value={password}
                                        onChange={handleChange}
                                        error={dataErrors.password}
                                        helperText={dataErrors.password ? dataErrors.password_m : ''}
                                        disabled={onLoad}
                                    /> : ''
                            }
                            {
                                option === 'RECOVERY_PASSWORD' || option === 'REGISTER' ? <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="repassword"
                                    label="Repetir contraseña"
                                    type="password"
                                    id="repassword"
                                    value={repassword}
                                    onChange={handleChange}
                                    error={dataErrors.repassword}
                                    disabled={onLoad}
                                    helperText={dataErrors.repassword ? dataErrors.repassword_m : ''}
                                /> : ''
                            }
                            {
                                option === 'LOGIN' || option === 'REGISTER' ? <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" checked={remember}
                                                       onChange={() => {
                                                           setRemember(!remember)
                                                       }}/>}
                                    label="Recuérdame"
                                /> : ''
                            }
                            {
                                option === "REGISTER" ? <Grid style={{marginTop: 5}} container>
                                    <Grid item xs={12}>
                                        Al enviar tus datos, estas aceptando nuestras <a target="_blank"
                                                                                         href="/aviso-privacidad">Políticas
                                        de privacidad</a> y nuestros <a target="_blank" href="/terminos-y-condiciones">Términos
                                        y
                                        Condiciones</a>
                                    </Grid>
                                </Grid> : ""
                            }
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
				style={{
				    background: 
					`linear-gradient(${primary2}, ${ primary_shiny })`,
				    color: 'white',
				}}
                                className={classes.submit}
                                disabled={onLoad}
                            >
                                {option === 'LOGIN' ? 'Iniciar' : 'Enviar'}
                            </Button>

                            <Grid container>


                                {login ? <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                    <Link href="#" variant="body2" disabled={onLoad} onClick={() => {
                                        if (option === 'RECOVERY')
                                            setOption('LOGIN');
                                        else
                                            setOption('RECOVERY')
                                    }}>
                                        {
                                            option === 'RECOVERY' || option === 'RECOVERY_PASSWORD' ? 'Iniciar sesion' : 'Olvidé mi contraseña'
                                        }
                                    </Link>
                                </Grid> : ''}

                                {
                                    option !== 'RECOVERY' && option !== 'RECOVERY_PASSWORD' ?
                                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                            <Link href="#" variant="body2" onClick={handleToggle} disabled={onLoad}>
                                                {option === 'LOGIN' ? '¿No tienes cuenta? Regístrate' : 'Ya tienes una cuenta, Inicia sesión'}
                                            </Link>
                                        </Grid> : ''
                                }
                            </Grid>
                        </form>
                    </div>
                </Container> : ''
            }
        </>
    );
}
