import React from "react";

export const XelIconAvatar = (props) => {
    return <svg viewBox="0 0 25.78 30.91" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M12.7,14.89A7.24,7.24,0,0,0,18,12.71a7.2,7.2,0,0,0,2.18-5.27A7.2,7.2,0,0,0,18,2.18,7.2,7.2,0,0,0,12.7,0,7.2,7.2,0,0,0,7.43,2.18,7.24,7.24,0,0,0,5.25,7.44a7.24,7.24,0,0,0,2.18,5.27,7.24,7.24,0,0,0,5.27,2.18Zm-4-11.43a5.41,5.41,0,0,1,4-1.65,5.38,5.38,0,0,1,4,1.65,5.38,5.38,0,0,1,1.65,4,5.4,5.4,0,0,1-1.65,4,5.38,5.38,0,0,1-4,1.65,5.41,5.41,0,0,1-4-1.65,5.4,5.4,0,0,1-1.65-4,5.38,5.38,0,0,1,1.65-4Z"/>
        <path className="cls-1"
              d="M25.72,23.76a16.81,16.81,0,0,0-.25-2,14.79,14.79,0,0,0-.48-2A9.28,9.28,0,0,0,24.18,18,6.54,6.54,0,0,0,23,16.43a5.37,5.37,0,0,0-1.75-1.1,6,6,0,0,0-2.23-.4,2.27,2.27,0,0,0-1.21.51l-1.26.81a7.43,7.43,0,0,1-1.63.72,6.4,6.4,0,0,1-2,.33,6.29,6.29,0,0,1-2-.33,7,7,0,0,1-1.63-.72L8,15.44a2.3,2.3,0,0,0-1.21-.51,6,6,0,0,0-2.24.4,5.33,5.33,0,0,0-1.74,1.1A6.83,6.83,0,0,0,1.6,18a9.28,9.28,0,0,0-.81,1.83,16.54,16.54,0,0,0-.48,2,18.79,18.79,0,0,0-.25,2C0,24.36,0,25,0,25.59a5.13,5.13,0,0,0,1.52,3.88,5.5,5.5,0,0,0,3.93,1.44H20.33a5.5,5.5,0,0,0,3.93-1.44,5.13,5.13,0,0,0,1.52-3.88c0-.62,0-1.24-.06-1.83ZM23,28.16a3.69,3.69,0,0,1-2.68.93H5.45a3.69,3.69,0,0,1-2.68-.93,3.39,3.39,0,0,1-1-2.57c0-.58,0-1.15.06-1.7s.11-1.13.22-1.76a15.08,15.08,0,0,1,.43-1.73,8.11,8.11,0,0,1,.65-1.49,5.48,5.48,0,0,1,.9-1.17A3.46,3.46,0,0,1,5.23,17a4.33,4.33,0,0,1,1.43-.28L7,17l1.27.82a9.41,9.41,0,0,0,2.05.92,8.13,8.13,0,0,0,5.1,0,9,9,0,0,0,2-.92L18.77,17l.35-.22a4.28,4.28,0,0,1,1.43.28,3.46,3.46,0,0,1,1.16.72,4.91,4.91,0,0,1,.9,1.17,8.91,8.91,0,0,1,.66,1.49,13.28,13.28,0,0,1,.42,1.73c.11.63.19,1.22.23,1.76h0c0,.55,0,1.12,0,1.7a3.35,3.35,0,0,1-1,2.57Z"/>
    </svg>
}
export const XelIconCard = (props) => {
    return <svg viewBox="0 0 45.24 30.16" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M40.53,30.16H4.71A4.72,4.72,0,0,1,0,25.45V4.71A4.72,4.72,0,0,1,4.71,0H40.53a4.72,4.72,0,0,1,4.71,4.71V25.45A4.71,4.71,0,0,1,40.53,30.16ZM4.71,1.89A2.82,2.82,0,0,0,1.89,4.71V25.45a2.83,2.83,0,0,0,2.82,2.83H40.53a2.83,2.83,0,0,0,2.83-2.83V4.71a2.83,2.83,0,0,0-2.83-2.82Z"/>
        <path className="cls-1"
              d="M44.3,13.2H.94A1,1,0,0,1,0,12.25V6.6a.94.94,0,0,1,.94-.94H44.3a.94.94,0,0,1,.94.94v5.65A1,1,0,0,1,44.3,13.2ZM1.89,11.31H43.36V7.54H1.89Z"/>
        <path className="cls-1" d="M17.91,20.74H6.6a.95.95,0,0,1,0-1.89H17.91a.95.95,0,0,1,0,1.89Z"/>
        <path className="cls-1" d="M17.91,24.51H6.6a.95.95,0,0,1,0-1.89H17.91a.95.95,0,0,1,0,1.89Z"/>
        <path className="cls-1"
              d="M36.76,24.51H34.87a2.83,2.83,0,0,1-2.82-2.83V19.79A2.82,2.82,0,0,1,34.87,17h1.89a2.83,2.83,0,0,1,2.83,2.82v1.89A2.83,2.83,0,0,1,36.76,24.51Zm-1.89-5.66a.94.94,0,0,0-.94.94v1.89a.94.94,0,0,0,.94.94h1.89a.94.94,0,0,0,.94-.94V19.79a.94.94,0,0,0-.94-.94Z"/>
    </svg>
}