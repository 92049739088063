import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Card, Container, Grid, Typography, useMediaQuery, withStyles} from "@material-ui/core";
import {XEL_COLORS, XEL_FONT} from "../../../../constant";

import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {useResponsiveValues} from "../../../utils/hooks";
import Titulo from "./Titulo";
import Particles from "react-particles";
import {useSelector} from "react-redux";
import { loadFull } from "tsparticles";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import "./styles.css";
import DeviceService from "../../../utils/services/dispositivos";
import PlanCard from "../../../ui/Plan/v2";

const useStyles = makeStyles({
    title_primary: {
        backgroundColor: XEL_COLORS.primary,
        marginTop: 0
    }

});

const useCarouStyle = makeStyles({
    button: {
        margin: "0 10px",
        position: "relative",
        backgroundColor: "transparent",
        top: "30px",
        color: XEL_COLORS.primary,
        fontSize: "10px",
        transition: "200ms",
        cursor: "pointer",
        height: 10,
        width: 10,
        '&:hover': {
            opacity: "0.6 !important"
        },
        '&:active': {
            backgroundColor: XEL_COLORS.secondary_light
        }
    },
    next: {
        right: -13
    },
    prev: {
        left: -13
    },
    active: {
        backgroundColor: XEL_COLORS.secondary_light
    },
    back: {
        backgroundColor: "transparent"
    },
    btnSel: {
        backgroundColor: "#FEFEFE",
        borderRadius: 10,
        border: '1px solid ' + XEL_COLORS.primary,
        margin: '1%',
        color: XEL_COLORS.primary,

        '&:hover': {
            border: '1px solid ' + XEL_COLORS.secondary,
            backgroundColor: XEL_COLORS.secondary,
            color: '#FEFEFE',
        }
    },
    btnSelAct: {
        border: '1px solid ' + XEL_COLORS.secondary,
        backgroundColor: XEL_COLORS.secondary,
        color: '#FEFEFE',
    }
});

const useStylesCar = makeStyles({
    container: {
        // paddingTop: '2%',
        height: '100%',
        width: '100%',
    },
    c2: {
        paddingTop: 5,
        paddingLeft: 0,
        paddingRight: '2%'
    },
    item: {
        height: '100%',
        display: "flex",
        justifyContent: "center"
    }
});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 4
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 4
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};
const Carouplan = ({planes}) => {
    const styles = useStylesCar();

    const containerRef = useRef();
    const responsiveCar = useMemo(() => {
        if (planes.length > 4) {
            return responsive;
        }
        return {
            ...responsive,
            superLargeDesktop: {
                ...responsive.superLargeDesktop,
                items: planes.length
            },
            desktop: {
                ...responsive.desktop,
                items: planes.length
            },
        }

    }, [planes]);

    useEffect(() => {
        if (containerRef.current) {

            console.log('f', containerRef.current.getBoundingClientRect())
        }

    }, [containerRef])
    return <Grid container>
        <Grid item xs={12}>
            <Container ref={containerRef}>
                <Carousel
                    className={styles.container}
                    containerClass={styles.c2}
                    itemClass={styles.item}
                    responsive={responsiveCar}>
                    {
                        planes.map(plan => <PlanCard key={plan.id} type={"plan"} plan={plan}/>)
                    }
                </Carousel>
            </Container>
        </Grid>
    </Grid>
}
export default function PlansSection({showPreplan}) {
    const styles = useStyles();
    const carouStyle = useCarouStyle();
    const matches2 = useMediaQuery('(min-width:769px)');
    const matches3 = useMediaQuery('(min-width:426px)');
    const tamBtn = useResponsiveValues(25, 20, 15);
    const [selection, setSelection] = useState('telefonía móvil');
    const [plansSelected, setPlansSelected] = useState([]);
    const txs = useResponsiveValues(25, 22, 20);

    const [preplan, setPreplan] = useState([]);
    const planes = useSelector(state => state.planes);
    const [dev, setDev] = useState(null);
    const [idDev, setIdDev] = useState(null);
    const devices = DeviceService();
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);
    useEffect(() => {
        const selPlans = planes.normal.filter(seccion => seccion.name === "Planes")[0].planes.filter(subseccion => subseccion.name.toLowerCase() === selection.toLowerCase())[0].planes;
        setPlansSelected(selPlans);
        if (selPlans.length > 0)
            if (selPlans[0].req_devs.length > 0) {
                devices.getDevice(selPlans[0].req_devs[0].toString()).then(res => {
                    setDev(res);
                }).catch(err => {

                })
            }
        const normalPlanes = planes.normal.filter(seccion => seccion.name === "Planes")[0].planes.filter(subseccion => subseccion.name.toLowerCase() === selection.toLowerCase())[0].planes;
        let preplanes = planes.normal.filter(seccion => seccion.name === "Preplan")[0].planes.filter(subseccion => subseccion.name.toLowerCase() === selection.toLowerCase());
        if (preplanes.length > 0) {
            preplanes = preplanes[0].planes;
            let pre = [];

            for (let i = 0; i < preplanes.length; i++) {
                const {id, original} = preplanes[i];
                let search = pre.filter(pr => pr.original === original && pr.id !== id);
                if (search.length > 0) {

                    let plan = search[0];
                    let preplan = preplanes[i];
                    let detpre = plan.detpre;
                    detpre.push({
                        months: preplan.months_service,
                        price: preplan.price,
                        id: preplan.id,
                        noPrice: (parseInt(normalPlanes.filter(p => p.id === original)[0].price) + 1) * preplan.months_service
                    });
                    search[0] = plan;
                } else {
                    let plan = preplanes[i];
                    plan.name = `${plan.name.split(" ")[0]} ${plan.name.split(" ")[1]}`;
                    plan.detpre = [
                        {
                            months: plan.months_service,
                            price: plan.price,
                            id: plan.id,
                            noPrice: (parseInt(normalPlanes.filter(p => p.id === original)[0].price) + 1) * plan.months_service
                        }
                    ]
                    // delete plan.price;
                    pre.push(plan);
                }
            }
            setPreplan(pre);
        } else
            setPreplan([]);
        // eslint-disable-next-line no-undef
        gtag("click_plan", {
            tipo: selection
        });
    }, [selection]);

    const changeSelection = () => {
        setSelection(selection === 'MBB' ? 'NOMBB' : 'MBB');
    }
    const onChange = (value = "") => {
        // eslint-disable-next-line no-undef
        gtag("click_plan", {
            tipo: selection
        });
        setSelection(value.toLowerCase());
    }

    return <>
        <div id={'plan-section-id'}
             style={{backgroundColor: '#F6F6F6', position: "relative", overflow: "hidden"}}>
            <div style={{position: 'absolute', height: '100%', width: '100%', zIndex: 0}}>
                <Particles
                    options={{
                        particles: {
                            "number": {
                                "value":20
                            },
                            "size": {
                                "value": 3
                            },
                            color: XEL_COLORS.primary,
                            links: {
                                enable: true,
                                distance: 150,
                                color: XEL_COLORS.secondary,
                                opacity: 0.6,
                                width: 1,
                                shadow: {
                                    enable: false,
                                    blur: 5,
                                    color: "lime"
                                }
                            },
                        },
                        interactivity: {
                            events: {
                                onhover: {
                                    enable: true,
                                    mode: "repulse"
                                }
                            }
                        }
                    }
                    }
                    init={particlesInit}
                    style={{height: '100%'}}
                />
            </div>
            <Titulo/>
            <Container style={{position: "relative", backgroundColor: "transparent"}}>
                <Grid container>
                    <Grid item xs={12} style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>

                        {
                            planes.normal.filter((seccion, id) => seccion.name === "Planes")[0].planes.map((subseccion, i) =>
                                <Button
                                    key={i}
                                    onClick={() => onChange(subseccion.name)}
                                    className={`${carouStyle.btnSel} ${selection === subseccion.name.toLowerCase() ? carouStyle.btnSelAct : ""}`}
                                    style={{
                                        //fontSize: tamBtn
                                    }}>
                                    <Typography variant={"h5"}>
                                        {subseccion.name === "WiFi Portátil" ? "Internet Móvil" : subseccion.name}
                                    </Typography>
                                </Button>)
                        }
                    </Grid>
                </Grid>
                {
                    plansSelected.length > 0 && <Carouplan planes={plansSelected}/>
                }
                {
                    selection === 'telefonía móvil' ? <> <Grid container style={{paddingBottom: '1%'}} spacing={1}>
                        <Grid item xs={12} style={{justifyContent: "center", display: "flex"}}>
                            <Typography variant={'caption'} align={'center'}
                                //            style={{fontSize: 11}}
                            >
                                * Política de uso justo: Superando los primeros 20GB, cuentas con 20GB adicionales para
                                seguir
                                navegando con velocidad limitada a 512 Kbps. Todos los precios publicados incluyen
                                I.V.A.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{justifyContent: "center", display: "flex"}}>
                            <Typography variant={'caption'} align={'center'}
                                //            style={{fontSize: 11}}
                            >
                                ** Política de uso justo: Superando los primeros 50GB, cuentas con 50GB adicionales para
                                seguir
                                navegando con velocidad limitada a 1 Mbps. Todos los precios publicados incluyen I.V.A.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{justifyContent: "center", display: "flex"}}>
                            <Typography variant={'caption'} align={'center'}
                                        color={"primary"}
                                //            style={{fontSize: 11}}
                            >
                                Para planes con política de uso justo: Al consumir los GB adicionales, el usuario no
                                podrá
                                navegar a menos que contrate
                                una bolsa adicional de datos (recarga).
                            </Typography>
                        </Grid>
                    </Grid></> : ""
                }
                {
                    selection === "wifi portátil" ? <Grid container style={{paddingBottom: '1%'}} spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={'caption'} align={'center'} style={{marginBottom: '2%'}}>
                                *Los planes Xel-Fi no incluyen Router (Modem), el costo será incluido al solicitar la
                                compra y es un
                                pago único. El servicio será garantizado por parte de Inxel siempre y cuando usted
                                adquiera el Router sugerido.
                            </Typography>
                        </Grid>
                    </Grid> : ""
                }
                {
                    selection === "internet fijo" ? <Grid container style={{paddingBottom: '1%'}} spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={'caption'} align={'center'}
                            >
                                * Política de uso justo: De tus Gigas totales 60GB son para navegar con velocidad
                                limitada . Todos los precios publicados incluyen I.V.A.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'caption'} align={'center'}
                            >
                                *Los planes Xel Home no incluyen Router (Modem), el costo será incluido al solicitar la
                                compra y es un
                                pago único. La compra de tu Plan solo será posible si adquieres tu Router (Modem).
                            </Typography>
                        </Grid>
                    </Grid> : ""
                }
            </Container>
        </div>
        {
            showPreplan && preplan.length > 0 && <Container style={{marginTop: "5%"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={"h4"} color={"primary"} style={{fontWeight: XEL_FONT.bold_weight}}>
                            Preplan
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body1"} color={"primary"} style={{fontWeight: XEL_FONT.regular_weight}}>
                            Nueva modalidad Prepago que te brinda grandes beneficios, adquiere ya el plan ideal para ti
                            y
                            disfruta de tu línea inXel al maxímo.
                        </Typography>
                    </Grid>
                    <Container style={{justifyContent: "center", position: "relative"}}>
                        <Carouplan
                            planes={preplan}/>
                    </Container>
                </Grid>
            </Container>
        }
        {
            (selection === "wifi portátil" || selection === "internet fijo") && dev ?
                <Grid container
                      style={{backgroundColor: "rgba(90,54,113,0.06)", paddingTop: "2%", paddingBottom: "2%"}}>
                    <Container>
                        <Grid container style={{marginTop: "2%"}} justify={"center"}>
                            <Grid item xs={12}>
                                <Typography variant={"h4"} color={"primary"} style={{fontWeight: XEL_FONT.bold_weight}}>
                                    {selection === "wifi portátil" ? "Router Portatil" : "Router fijo"}
                                </Typography>
                                <Typography variant={"body1"} color={"primary"}>
                                    {selection === "wifi portátil" ? "Dispositivo portátil con el cual podrás disfrutar de tu internet inXel dónde sea y cuando sea." : "Dispositivo fijo, ideal para tu hogar, disfruta de la conexión ideal en todos tus dispositivos."}
                                </Typography>
                            </Grid>
                            <Grid item xs={11} md={6}>
                                <Card elevation={1} style={{marginTop: "2%"}}>
                                    <Grid container>
                                        <Grid item xs={12} style={{backgroundColor: XEL_COLORS.lila}}>
                                            <Typography align={"center"} variant={"body1"}
                                                        style={{fontWeight: XEL_FONT.bold_weight}} color={"primary"}>
                                                {dev.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{padding: "1%"}}>
                                            <Grid container alignItems={"center"}>
                                                <Grid item xs={11} md={4}
                                                      style={{justifyContent: "center", display: "flex"}}>
                                                    <img style={{padding: "1%", width: "80%", height: "80%"}}
                                                         src={dev.image} alt={dev.name}/>
                                                </Grid>
                                                <Grid item xs={12} md style={{padding: "4%"}}>
                                                    <Card elevation={2} style={{padding: "3%"}}>
                                                        <Grid container spacing={2}>
                                                            {
                                                                dev.details.map((det, i) => <Grid key={i * 4} item
                                                                                                  xs={12}>
                                                                    <Typography variant={"body2"} color={"primary"}
                                                                                style={{fontWeight: XEL_FONT.bold_weight}}>
                                                                        {det.title}: <span
                                                                        style={{fontWeight: XEL_FONT.regular_weight}}>{det.value}</span>
                                                                    </Typography>
                                                                </Grid>)
                                                            }
                                                            <Grid item xs={12}>
                                                                <Typography variant={"h4"} color={"primary"}
                                                                            align={"center"}>
                                                                    ${dev.price}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid> </Container>
                </Grid> : ""
        }
    </>
}
