import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer, Font
} from "@react-pdf/renderer";
import React from "react";
import LogoInxel from "../../../../assets/logos/inxel/logo-inxel-color.png";
import LogoPaynet from "../../../../assets/logos/OpenPay/paynet.png";
import {XEL_FONT} from "../../../../constant";

const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        //flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        height: 80,
        alignItems: 'center'
    },
    inxel: {
        width: 98,
        height: 58
    },
    paynet: {
        width: 140,
        height: 42
    },
    paynet_container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        fontFamily: 'Monserrat',
        fontWeight:400
    }
});

export default function Logos() {
    return <View style={styles.section}>
        <Image src={LogoInxel} style={styles.inxel}/>
        <View style={styles.paynet_container}>
            <Text style={styles.text}>Servicio a pagar</Text>
            <Image src={LogoPaynet} style={styles.paynet}/>
        </View>
    </View>
}