import React, {useEffect, useState} from "react";
import {Button, Card, Grid, Typography, useMediaQuery, withStyles} from "@material-ui/core";
import {PromosPlan, XEL_COLORS, XEL_FONT} from "../../../constant";
import Container from "@material-ui/core/Container";
import {useHistory} from 'react-router-dom';
import FacebookSVG, {
    XelIconFacebookMain, XelIconInstagramMain,
    XelIconMessengerMain, XelIconTikTokMain,
    XelIconTwitterMain, XelIconYouTubeMain
} from '../../../assets/logos/home/svg/index';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../../actions';
import {makeStyles} from "@material-ui/core/styles";
import {useResponsiveValues} from "../../utils/hooks";
import LogoPromo from '../../../assets/images/navidad.png';
import './style.css';
import InterLogo from '../../../assets/images/me-gusta.svg';
import {XELCross} from "../../../assets/logos/svg";

const PlanContainer = withStyles({
    root: {
        display: 'flex',
        justifyContent: "center",
        maxWidth: 400,
        backgroundColor: "transparent",
        width: '100%',
        position: "absolute",
        height: '100%'
    }
})(Grid);


const BuyButton = withStyles({
    root: {
        borderRadius: 15,
        width: 200,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 25,
        '&:hover': {
            backgroundColor: XEL_COLORS.secondary
        },

    }
})(Button);


const usePlanStyles = makeStyles({
    head: {
        height: 100,
        backgroundColor: XEL_COLORS.lila,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        display: "flex",
        alignItems: "center",
        paddingLeft: 0,
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
    },
    hotstop: {
        width: 96,
        height: 96,
        backgroundColor: XEL_COLORS.primary,
        color: '#FEFEFE',
        borderRadius: '50%',
        position: "absolute",
        top: -20,
        right: -15,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: 'all .3s ease-in-out'
    },
    hotstopActive: {
        width: 96,
        height: 96,
        backgroundColor: XEL_COLORS.secondary,
        color: '#FEFEFE',
        borderRadius: '50%',
        position: "absolute",
        top: -20,
        right: -15,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: 'all .3s ease-in-out'
    }
});

export default function Planv2({selector, setId, id, array, data, disabled, types, titleDiscount}) {

    let type = types ? types : data.type;
    const noSocials = type !== "plan";
    const styles = usePlanStyles();
    const history = useHistory();
    const [isHover, setIsHover] = useState(false);
    const [selected, setSelected] = useState(false);
    const [cardSelected, setCardSelected] = useState(null)
    const dispatch = useDispatch();
    const btnFSize = useResponsiveValues(25, 20, 16);
    const polSize = useResponsiveValues(12, 11, 10);
    const iconHeight = useResponsiveValues(32.5, 25, 25);
    const hotspotSize = {
        dimension: useResponsiveValues(96, 65, 65),
        top: useResponsiveValues(-20, -5, 0),
        right: useResponsiveValues(-15, -5, 0),
        font: useResponsiveValues(15, 12.5, 10)
    };
    const sizePromoPrice = useResponsiveValues(30, 25, 20)
    const promoPlan = PromosPlan();
    const session = useSelector(state => state.login);
    useEffect(() => {
        if (array)
            array.push(setSelected);
    }, [array]);
    const reboot = () => {
        if (array)
            for (let i = 0; i < array.length; i++)
                array[i](false);
    }

    const goTobuy = () => {
        // eslint-disable-next-line no-undef
        gtag("click_lo_quiero", {
            "tipo": "Plan",
            "tipo_plan": type,
            "plan": data.name,
        });
        history.push(`/comprar/${type}/` + data.id);
        //if (!session.logged)
        //             window.location.reload();
    }
    const colorSelected = (option) => {
        if (option === 1)
            if (selector)
                return selected || isHover ? XEL_COLORS.secondary : XEL_COLORS.primary
            else
                return isHover ? XEL_COLORS.secondary : XEL_COLORS.primary
        if (option === 2)
            if (selector)
                return !selected ? XEL_COLORS.secondary : XEL_COLORS.primary
            else
                return !isHover ? XEL_COLORS.secondary : XEL_COLORS.primary
    }
    const colorMainSelected = (option) => {
        if (option === 1)
            if (selector)
                return selected || isHover ? 'secondary' : 'primary'
            else
                return isHover ? 'secondary' : 'primary'
        if (option === 2)
            if (selector)
                return selected || isHover ? 'secondary' : 'primary'
            else
                return isHover ? 'secondary' : 'primary'
    }
    const variantMainSelected = (option) => {
        if (option === 1)
            if (selector)
                return selected || isHover ? 'contained' : 'outlined'
            else
                return isHover ? 'contained' : 'outlined'
        if (option === 2)
            if (selector)
                return selected || isHover ? 'contained' : 'outlined'
            else
                return isHover ? 'contained' : 'outlined'
    }
    const colorTextSelected = (option) => {
        if (option === 1)
            if (selector)
                return selected || isHover ? '#fefefe' : XEL_COLORS.primary
            else
                return isHover ? '#fefefe' : XEL_COLORS.primary
        if (option === 2)
            if (selector)
                return selected || isHover ? '#fefefe' : XEL_COLORS.primary
            else
                return isHover ? '#fefefe' : XEL_COLORS.primary
    }

    return <PlanContainer
        onMouseOver={() => {
            setIsHover(true);
        }} onMouseLeave={() => {
        setIsHover(false);
    }}
        onClick={() => {

            if (disabled || data.type === "preplan")
                return;

            dispatch(Actions.PLAN_SELECTED());
            if (selector) {
                if (selected) {
                    setSelected(false);
                    setId(null);
                } else {
                    reboot();
                    setSelected(true);
                    setId('Xel_5');
                }
            } else {
                goTobuy();
            }
        }}
        item xs={11} sm={10} md={4}
        style={{
            padding: useResponsiveValues(25, 18, 10),
            marginBottom: 22,
            height: '100%',
            position: 'relative',
            display: 'flex',
            cursor: data.type !== "preplan" ? "pointer" : "default"
        }}>
        {
            data.hotspot && (data.type === "plan" || data.type === "preplan") ?
                <div className={isHover ? styles.hotstop : styles.hotstopActive}
                     style={{
                         width: hotspotSize.dimension,
                         height: hotspotSize.dimension,
                         top: hotspotSize.top,
                         right: hotspotSize.right
                     }}>
                    <Typography variant={'body2'} style={{
                        color: '#FEFEFE',
                        fontSize: hotspotSize.font
                    }}
                                align={'center'}>
                        {
                            data.hotspotext ? data.hotspotext : 'Incluye HotSpot'
                        }
                    </Typography>
                </div> : ''
        }
        <Grid container style={{
            height: '100%',
            backgroundColor: '#FEFEFE',
            boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.75)',
            borderRadius: 5,
        }}>
            <Grid item xs={12} className={styles.head} style={{
                transition: 'all .3s ease-in-out',
                backgroundColor: isHover ? XEL_COLORS.secondary : XEL_COLORS.lila,
                height: useResponsiveValues(100, 70, 70)
            }}>
                <Typography mt={1} variant={'h4'} align={'center'} style={{
                    width: '100%',
                    color: isHover ? '#FEFEFE' : XEL_COLORS.primary,
                    //        fontSize: useResponsiveValues(30, 25, 20)
                }}>
                    {data.name}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                paddingTop: useResponsiveValues(30, 20, 10),
                minHeight: '100%'
            }}>
                <Grid container style={{height: '100%'}}>
                    <Grid item xs={12} style={{
                        marginBottom: useResponsiveValues(10, 10, 7),
                        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.26)',
                        paddingBottom: 20
                    }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container style={{}}>
                                    <Grid item xs={12}>
                                        <Typography mt={3} variant={'body1'}

                                                    style={{
                                                        fontWeight: XEL_FONT.bold_weight,
                                                        //   fontSize: useResponsiveValues(16, 14, 10),
                                                        paddingLeft: '10%',
                                                        paddingRight: '7%',
                                                    }}>
                                            {data.primera_desc}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={'body2'} style={{
                                            //   height: useResponsiveValues(10, 7, 7),
                                            fontSize: 10,
                                            paddingLeft: '10%',
                                            paddingRight: '7%'
                                        }}>
                                            {
                                                data.primera_real_desc ? data.primera_real_desc : ''
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{
                        marginBottom: useResponsiveValues(10, 10, 7),
                        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.26)',
                        paddingTop: 15,
                        paddingBottom: 15
                    }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant={'body1'}
                                                    style={{
                                                        fontWeight: XEL_FONT.bold_weight,
                                                        //     fontSize: useResponsiveValues(15, 14, 10),
                                                        paddingLeft: '10%',
                                                        paddingRight: '7%'
                                                    }}>
                                            {data.segunda_desc}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={'body2'} style={{

                                            // fontSize: 10,
                                            paddingLeft: '10%',
                                            paddingRight: '7%'

                                        }}>
                                            {data.segunda_real_desc}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{
                        marginBottom: 0,
                        paddingTop: 15,
                        paddingBottom: 10,
                        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.26)',
                    }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant={'body1'}
                                                    style={{
                                                        fontWeight: XEL_FONT.bold_weight,
                                                        //    fontSize: useResponsiveValues(15, 14, 10),
                                                        paddingLeft: '10%',
                                                        paddingRight: '7%'
                                                    }}>
                                            {data.tercera_desc}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={'body2'} style={{

                                            //fontSize: 10,
                                            paddingLeft: '10%',
                                            paddingRight: '7%'
                                        }}>
                                            {data.tercera_real_desc}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        data.services_desc && data.services_desc.map((s, i) => {
                            const {value} = s;
                            return <Grid item xs={12} style={{
                                marginBottom: 0,
                                paddingTop: 15,
                                paddingBottom: 10,
                                boxShadow: data.services_desc.length === i + 1 ? "none" : "'0px 3px 5px -1px rgba(0,0,0,0.26)'",
                            }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant={'body1'}
                                                            style={{
                                                                fontWeight: XEL_FONT.bold_weight,
                                                                //    fontSize: useResponsiveValues(15, 14, 10),
                                                                paddingLeft: '10%',
                                                                paddingRight: '7%'
                                                            }}>
                                                    {value.split(":")[0]}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant={'body2'} style={{

                                                    //fontSize: 10,
                                                    paddingLeft: '10%',
                                                    paddingRight: '7%'
                                                }}>
                                                    {value.split(":")[1]}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        })
                    }
                    {
                        titleDiscount && <Grid item xs={12} style={{marginTop: "2%"}}>
                            <Typography style={{
                                marginBottom: "2%",
                                color: isHover ? XEL_COLORS.secondary : XEL_COLORS.primary,
                                fontWeight: XEL_FONT.bold_weight,
                            }} align={"center"}>
                                {titleDiscount}
                            </Typography></Grid>
                    }
                    <Grid item xs={12}
                          style={{
                              marginBottom: 1,
                              marginTop: useResponsiveValues('1%', "2%", "3%"),
                              position: "relative",
                              height: useResponsiveValues(73, 73, 53),
                              display: data.type === "preplan" ? "none" : "initial"
                          }}>

                        <Typography variant={'h3'} align={'center'} style={{
                            position: "absolute",
                            top: 4,
                            width: '100%',
                            overflow: 'hidden',
                            //    fontSize: useResponsiveValues(40, 33, 27),
                            color: XEL_COLORS.primary,
                            display: "flex",
                            justifyContent: "center"
                        }}>
                            {
                                data.promos && promoPlan.withPromo() ? <div className={'line1'}/> : ""
                            }
                            ${data.promo ? <>{data.promo.price}.00 {promoPlan.withPromo() ?
                                <img src={LogoPromo} alt="Logo"
                                     style={{width: 30, height: 30}}/> : ''} </>
                            : <>{data.type !== "preplan" && data.price}.00
                            </>}
                        </Typography>
                        <Typography variant={'body2'} align={'right'} style={{
                            fontWeight: XEL_FONT.bold_weight,
                            position: "absolute",
                            top: useResponsiveValues(45, 45, 30),
                            right: useResponsiveValues(53, 7, 7),
                            // fontSize: useResponsiveValues(15, 13, 10),
                            paddingRight: useResponsiveValues('17%', '20%', '30%')
                        }}>
                            {data.period}
                        </Typography>
                    </Grid>

                    {
                        data.promos && promoPlan.withPromo() ? <>
                            <Grid item xs={12} style={{padding: '1%', marginBottom: '1%'}}>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Typography align={'center'} variant={'body2'} style={{fontSize: 10}}>
                                            Portabilidad
                                        </Typography>
                                        <Typography align={'center'} style={{
                                            fontSize: sizePromoPrice,
                                            color: XEL_COLORS.primary,
                                            fontWeight: XEL_FONT.bold_weight
                                        }}>
                                            ${data.promos.porta}.00
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} style={{display: "flex", justifyContent: "center"}}>
                                        <img src={InterLogo} alt="Amo inXel"
                                             style={{height: '100%', maxWidth: 26, width: '100%'}}/>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography align={'center'} variant={'body2'} style={{fontSize: 10}}>
                                            Línea nueva
                                        </Typography>
                                        <Typography align={'center'} style={{
                                            fontSize: sizePromoPrice,
                                            color: XEL_COLORS.primary,
                                            fontWeight: XEL_FONT.bold_weight
                                        }}>
                                            ${data.promos.nueva}.00
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align={'center'} style={{
                                            fontSize: 10,
                                            fontWeight: XEL_FONT.bold_weight,
                                            color: "#000000"
                                        }}>
                                            Los primeros {data.promos.months} meses
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </> : ""
                    }
                    {
                        noSocials || data.noSocial ? "" : <Grid item xs={12} style={{marginBottom: 3}}>
                            <Container>
                                <Grid container
                                      style={{
                                          //    borderTop: `1px solid ${colorSelected(1)}`,
                                          marginTop: '1%',
                                          paddingTop: 5,
                                          marginBottom: 20,
                                          display: "flex",
                                          justifyContent: "space-around",
                                          height: '100%',
                                          alignItems: "center"
                                      }}>
                                    <XelIconFacebookMain fill={XEL_COLORS.primary} style={{height: iconHeight}}/>
                                    <XelIconTwitterMain fill={XEL_COLORS.primary} style={{height: iconHeight}}/>
                                    <XelIconMessengerMain fill={XEL_COLORS.primary} style={{height: iconHeight}}/>
                                    <XelIconInstagramMain fill={XEL_COLORS.primary} style={{height: iconHeight}}/>
                                    <XelIconYouTubeMain fill={XEL_COLORS.primary} style={{height: iconHeight}}/>
                                    <XelIconTikTokMain fill={XEL_COLORS.primary} style={{height: iconHeight}}/>
                                </Grid>
                            </Container>
                        </Grid>

                    }
                    {
                        data.original_price && <Grid item xs={12} style={{
                            display: "flex",
                            justifyContent: "space-around",
                            flexDirection: "column",
                            marginBottom: "2%"
                        }}>
                            <Typography variant={"body2"}
                                        style={{
                                            fontWeight: XEL_FONT.semi_bold_weight,
                                            color: "gray",
                                            paddingLeft: "5%"
                                        }}>
                                Precio público:
                            </Typography>
                            <Typography variant={"h4"} align={"center"} color={"primary"}
                                        style={{
                                            fontWeight: XEL_FONT.bold_weight,
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "center",

                                        }}>
                                ${(+data.original_price).toFixed(2)}
                                <XELCross style={{position: "absolute", zIndex: 10,}}
                                          fill={XEL_COLORS.secondary}
                                          width={"100%"}
                                          height={"100%"}/>
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12} style={{
                        display: "flex", justifyContent: "center",
                        //    marginBottom: 20
                    }}>
                        {
                            data.type === "preplan" ? <Grid container spacing={2} style={{width: "100%"}}>
                                {
                                    data.detpre.map(det => <Grid key={det.id}
                                                                 onMouseEnter={() => setCardSelected(det.id)}
                                                                 onMouseLeave={() => setCardSelected(null)}

                                                                 onClick={() => {
                                                                     history.push(`/comprar/${data.type}/` + det.id);
                                                                 }} item xs style={{
                                        boxShadow: "-1px 2px 7px 1px rgba(0,0,0,0.42)",
                                        //  backgroundColor: "#FEFEFE",
                                        margin: "1%",
                                        cursor: "pointer",
                                        marginTop: "7%",
                                        backgroundColor: cardSelected === det.id ? XEL_COLORS.secondary : "#FEFEFE"
                                    }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}
                                                  style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center"
                                                  }}>
                                                <Typography variant={"h4"}
                                                    //   color={"secondary"}
                                                            style={{
                                                                fontWeight: XEL_FONT.bold_weight, marginRight: 7,
                                                                color: cardSelected === det.id ? "#FEFEFE" : XEL_COLORS.secondary,
                                                                marginBottom: "2%"
                                                            }}>
                                                    {det.months}
                                                </Typography>
                                                <Typography variant={"h5"}
                                                    // color={"primary"}
                                                            style={{
                                                                fontWeight: XEL_FONT.bold_weight,
                                                                color: cardSelected === det.id ? "#FEFEFE" : XEL_COLORS.primary
                                                            }}>
                                                    Meses
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant={"body2"}
                                                            style={{fontWeight: XEL_FONT.semi_bold_weight}}>
                                                    Precio real:
                                                </Typography>
                                                <Typography variant={"h5"} align={"center"} color={"primary"}
                                                            style={{
                                                                fontWeight: XEL_FONT.bold_weight,
                                                                position: "relative",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                color: cardSelected === det.id ? "#FEFEFE" : XEL_COLORS.primary
                                                            }}>
                                                    ${det.noPrice}
                                                    <XELCross style={{position: "absolute", zIndex: 10,}}
                                                              fill={cardSelected === det.id && "#FEFEFE"}
                                                              width={"100%"}
                                                              height={"100%"}/>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{}}>

                                                <Typography variant={"h4"} align={"center"}
                                                    //color={"primary"}
                                                            style={{
                                                                fontWeight: XEL_FONT.bold_weight,
                                                                backgroundColor: cardSelected === det.id ? "#FEFEFE" : XEL_COLORS.lila,
                                                                borderRadius: 10,
                                                                padding: "1%",
                                                                marginTop: "4%",
                                                                color: cardSelected === det.id ? XEL_COLORS.secondary : XEL_COLORS.primary
                                                            }}>
                                                    <Typography style={{color: "#00000"}}>
                                                        Paga solo:
                                                    </Typography>
                                                    ${det.price}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>)
                                }
                            </Grid> : <BuyButton disabled={!!disabled} variant={variantMainSelected(1)}
                                                 color={colorMainSelected(1)}
                                                 style={{
                                                     color: colorTextSelected(1),
                                                     fontSize: btnFSize,
                                                     width: 'fit-content',
                                                     marginTop: "3%"
                                                 }}
                                                 onMouseEnter={() => {
                                                     setIsHover(true);
                                                 }}

                                                 onMouseLeave={() => {
                                                     setIsHover(false);
                                                 }}
                            >
                                {
                                    disabled ? 'Proximamente' : selector ? !selected ? 'Seleccionar' : '¡OK!' : '¡Lo quiero!'
                                }
                            </BuyButton>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {
                data.politicas ?
                    <Grid item xs={12} style={{marginTop: 3, marginBottom: 3, height: 25}}>
                        <Typography variant={'body2'} align={'center'}
                                    style={{fontSize: polSize, color: '#000000'}}>
                            Aplica política de uso justo
                        </Typography>
                    </Grid> : <Grid item xs={12} style={{marginTop: 3, marginBottom: 3, height: 25}}>

                    </Grid>
            }
            <Grid item xs={12}>
                <Typography align={'center'} style={{fontSize: 12, marginBottom: 3}} color={'secondary'}>
                    Folio: {data.folio}
                </Typography>
            </Grid>
        </Grid>
    </PlanContainer>
}
