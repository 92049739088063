import {useEffect, useState} from 'react'
//import {HOST} from "./Constants";
import {useLocation} from 'react-router-dom';
import {useMediaQuery} from "@material-ui/core";

const HOST = 'https://www.inxel.mx/';

export const useCRMFields = () => {
    return new Promise((resolve, reject) => {
        resolve({})
    });
}

export const usePhoneFormater = (initialState) => {
    const [phone, setPhone] = useState(initialState);
    const checkPhone = phone => {
        const values = phone.split(" ");
        var value = "";
        for (let j = 0; j < values.length; j++) {
            value += values[j];
        }
        if (!isNaN(value)) {
            if (!(value.split("").length > 10)) {
                const f = phone.split("");
                var ws = "";
                if (f[f.length - 1] === " ") {
                    for (let u = 0; u < f.length - 1; u++) {
                        ws += f[u];
                    }
                    setPhone(ws);
                } else {
                    Format(value);
                }
            }
        }
    }
    const Format = phone => {
        const numbers = phone.trim().split("");
        var formated = "";
        for (let i = 0; i < numbers.length; i++) {
            const n = numbers[i];
            if (i === 1 || i === 5) {
                formated += n + " ";
            } else {
                formated += n;
            }
        }
        setPhone(formated)
    }
    return [phone, checkPhone];
}

export const useCapitalizeText = initialState => {
    const [text, setText] = useState(initialState);
    const prettyString = str => {
        let nextCapital = true;
        let newString = "";
        for (let i = 0; i < str.length; i++) {
            const cur = str.charAt(i);
            if (nextCapital) {
                newString += cur.toUpperCase();
            } else {
                newString += cur.toLowerCase();
            }
            nextCapital = cur === " ";
        }
        setText(newString);
    };

    return [text, prettyString];
}

export const useFileValidator = (initialFile, formatsToValidate) => {
    const [file, setFile] = useState(initialFile);
    const [valid, setValid] = useState(false);
    let flag = false;
    const checkFile = (inputElement) => {


        const ext = inputElement.target.value.split(".")[inputElement.target.value.split(".").length - 1];
        for (let i = 0; i < formatsToValidate.length; i++) {
            if (ext === formatsToValidate[i]) {
                flag = true;
            }
        }

        if (flag) {

            let file = inputElement.target.files[0];

            let reader = new FileReader();

            reader.onloadend = function () {
                setFile(reader.result);
                setValid(true);
            }
            if (file) {
                reader.readAsDataURL(file);
            } else {
                setValid(false);
            }
        } else {
            setFile(null);
        }
        setValid(false);
    }

    function toDataURL(src, resize, callback) {
        var image = new Image(); // create Image object
        image.crossOrigin = 'Anonymous';
        image.onload = function () {
            var canvas = document.createElement('canvas'); //initialize canvas
            var context = canvas.getContext('2d');
            let height = this.naturalHeight;
            let width = this.naturalWidth;
            let max_width = this.naturalWidth / 100 * resize;
            let max_height = this.naturalHeight / 100 * resize;
            console.log("To rezise", resize);
            if (width > height) {
                if (width > max_width) {
                    //height *= max_width / width;
                    height = Math.round(height *= max_width / width);
                    width = max_width;
                }
            } else {
                if (height > max_height) {
                    //width *= max_height / height;
                    width = Math.round(width *= max_height / height);
                    height = max_height;
                }
            }

            canvas.height = height;// this.naturalHeight;
            canvas.width = width;// this.naturalWidth;
            context.drawImage(this, 0, 0, width, height);
            var dataURL = canvas.toDataURL('image/jpeg');
            callback(dataURL);
        };
        image.src = src;
    }

    const reset = () => {
        setFile(initialFile);
        setValid(false);
    }
    return [file, valid, checkFile, reset];
}

export function useResponsiveValues(desktop, tablet, cellphone) {
    const noCel = useMediaQuery('(min-width:426px)');
    const noTablet = useMediaQuery('(min-width:769px)');

    return !noCel ? cellphone : !noTablet ? tablet : desktop

}

export function useResponsiveValuesV2(desktoplg, desktopmd, tablet, cellphone) {
    const noCel = useMediaQuery('(min-width:426px)');
    const noTablet = useMediaQuery('(min-width:769px)');
    const noDesktopmd = useMediaQuery('(min-width:1024px)');
    return !noCel ? cellphone : !noTablet ? tablet : !noDesktopmd ? desktopmd : desktoplg

}

export function useResponsiveValuesV3(xl, lg, md, sm, xs) {
    const noXs = useMediaQuery('(min-width:576px)');
    const noSm = useMediaQuery('(min-width:768px)');
    const noMd = useMediaQuery('(min-width:992px)');
    const noLg = useMediaQuery('(min-width:1200px)');
    return !noXs ? xs : !noSm ? sm : !noMd ? md : !noLg ? lg : xl;
}


export function useQuery() {
    return new URLSearchParams(useLocation().search);
}
