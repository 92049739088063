import { createSvgIcon } from "@material-ui/core"
export const Messages = createSvgIcon(<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
<path d="M19.2412 3.5C19.2412 2.5375 18.4625 1.75 17.5 1.75H3.5C2.5375 1.75 1.75 2.5375 1.75 3.5V14C1.75 14.9625 2.5375 15.75 3.5 15.75H15.75L19.25 19.25L19.2412 3.5Z" fill="url(#paint0_linear_2_705)"/>
<defs>
  <linearGradient id="paint0_linear_2_705" x1="1.75" y1="13.2885" x2="19.25" y2="13.2885" gradientUnits="userSpaceOnUse">
    <stop stop-color="#B627FF"/>
    <stop offset="0.463542" stop-color="#8A0EC9"/>
    <stop offset="1" stop-color="#412C5F"/>
  </linearGradient>
</defs>
</svg>, 'Messages')