import React, {useEffect, useState} from "react";
import {Container, Grid} from "@material-ui/core";
import {CheckImei} from "../../ui/BuyDialog";

export default function ConsultaImei() {
    const [imei, setImei] = useState({
        imei: '',
        valid: false
    });
    const [imeirequest, setImeiRequest] = useState({
        status: false,
        valid: 0,
        verified: true,
    });

    useEffect(() => {
        if (imei.valid)
            window.location.reload();
    }, [imei]);
    return <Grid container style={{minHeight: '80vh'}} alignItems={'center'}>
        <Grid item xs={12} justify={'center'}>
            <Container>
                <CheckImei withBackButton={true} imeiData={imei} imeiReq={imeirequest} setImei={setImei}
                           justCheck={true}/>
            </Container>
        </Grid>
    </Grid>
}
