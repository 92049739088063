import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import NavBar from "../../ui/NavBar";
import Footer from '../../ui/Footer';
import {useResponsiveValues} from "../../utils/hooks";
import Logo from '../../../assets/logos/inxel/logo-inxel-color.png';
import {makeStyles} from "@material-ui/core/styles";
import {XEL_FONT} from "../../../constant";

const useStyles = makeStyles({
   title:{
       fontWeight:XEL_FONT.bold_weight,
       fontSize:25
   },
    body:{
       fontSize:20,
        fontWeight:XEL_FONT.regular_weight,
        marginTop:10
    }
});
export default function Nosotros() {
    const style = useStyles();
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Nosotros";
    }, [])
    return <Grid container alignItems={'center'}   >
        <NavBar/>
        <Grid container style={{minHeight:'80vh'}} alignItems={'center'} >
            <Container   >
                <Grid item xs={12} style={{display:"flex",justifyContent:"center"}}>
                    <img src={Logo} alt="inXel" style={{height:120}}/>
                </Grid>
                <Grid item xs={12} style={{marginTop:20}}>
                    <Typography align={'left'} variant={'h2'} style={{}} className={style.title}>
                        Nosotros
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="justify" variant={'h3'} className={style.body} >
                        Absoluteteck SA de CV con la marca inXel, es una empresa dedicada al desarrollo de servicios innovadores
                        en telecomunicaciones y telefonía celular. Nos especializamos en brindar una experiencia única en
                        asesoría y atención a nuestros clientes, así como la creación de canales de ventas innovadores, creando
                        alianzas con grandes mercados y empresas para generar un canal de comunicación masivo y eficaz. Nuestros
                        principios y valores se centran en brindar una atención deexcelencia y calidad en todo momento, buscando
                        la mejor solución en todos nuestros servicios, facilitando el acceso de laspersonas, empresas y
                        corporativos a planes y equipos de telefonía celular, redes, internet, servicios de nube, voz y
                        datos;nuestra filosofía es ser el mejor aliado de nuestros clientes.
                    </Typography>
                </Grid>
            </Container>
        </Grid>
        <Footer/>
    </Grid>

}
