import React, {useEffect, useState} from "react";
import {Container, Hidden, useMediaQuery} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {APIHOST, XEL_COLORS, XEL_FONT} from "../../../constant";
import NavBar from "../../ui/NavBar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from '../../ui/Footer';
import Typography from "@material-ui/core/Typography";
import {usePhoneFormater, useResponsiveValues} from "../../utils/hooks";
import Titulo from "./Titulo";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Unitys from "./Unitys";
import Axios from "../../../instances/axios";
import axio from "axios";
import * as Actions from "../../../actions";
import {useDispatch, useSelector} from "react-redux";
import CTLOGO from '../../../assets/alianza/logos/CT.jpg';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles({
    button: {
        borderRadius: 0,
        padding: '2%',
        color: '#fefefe',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 20

    },
    input: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 18
    }, high_desc: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.bold_weight,
        color: XEL_COLORS.primary,
    },
    low_desc: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        color: XEL_COLORS.primary,
        textAlign: "left",
        padding: 20
    },
    desc_boton: {
        backgroundColor: XEL_COLORS.secondary,
        color: '#FEFEFE',
        display: "flex", justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Alliance = ({src, name, url}) => {
    const history = useHistory();
    const [hover, setHover] = useState(false);
    return <div onClick={() => history.push(`/alianza/${url}`)}
                style={{height: "50%", cursor: "pointer", padding: 5, margin: "1%"}}
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <img style={{filter: !hover ? " grayscale(100%)" : "none"}} src={src} alt={name}/>
    </div>
}
export default function Alianzas({hideNav}) {

    const style = useStyles();
    const noCel = useMediaQuery('(min-width:426px)');
    const descriptiosInitals = {
        one: true,
        two: false,
        three: false
    }
    const axios = Axios();

    const [data, setData] = useState({
        nombre: '',
        empresa: '',
        cargo: '',
        email: '',
        phone: ''
    });
    const bannersAll = useSelector(state => state.banners);

    const bannersAlliance = bannersAll.alliance;
    const dispatch = useDispatch();
    const [dataErrors, setDataErrors] = useState({
        nombre_err: false,
        empresa_err: false,
        cargo_err: false,
        email_err: false,
        phone_err: false,
        nombre_err_msg: '',
        empresa_err_msg: '',
        cargo_err_msg: '',
        email_err_msg: '',
        phone_err_msg: ''
    });
    const [open, setOpen] = React.useState(false);
    const [dataMsg, setDataMsg] = useState({
        severity: 'success',
        msg: 'Datos enviados'
    })
    const textbutton = useResponsiveValues(15, 12.5, 10);
    const [phone, setPhone] = usePhoneFormater('');
    const [loading, setLoading] = useState(false);
    const mailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Alianzas";
        setData({
            ...data,
            phone: phone
        });
    }, [phone]);
    const handleChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
        let name = e.target.name + '_err';
        let msg = e.target.name + '_err_msg';

        setDataErrors({
            ...dataErrors,
            [name]: false,
            [msg]: ''
        });
        console.log(dataErrors);
    }

    const handleChangePhone = e => {
        setPhone(e.target.value);
        setDataErrors({
            ...dataErrors,
            phone_err: false,
            phone_err_msg: ''
        });
    }
    const handleSend = () => {
        const {
            nombre,
            empresa,
            cargo,
            email,
            phone
        } = data;

        let forErrors = {};
        let error = false;


        if (nombre.trim().split('').length === 0) {
            forErrors.nombre_err = true;
            forErrors.nombre_err_msg = 'Inserta nombre';
            error = true;
        }
        if (empresa.trim().split('').length === 0) {
            forErrors.empresa_err = true;
            forErrors.empresa_err_msg = 'Inserta empresa';
            error = true;
        }
        if (cargo.trim().split('').length === 0) {
            forErrors.cargo_err = true;
            forErrors.cargo_err_msg = 'Inserta cargo';
            error = true;
        }
        if (email.trim().split('').length === 0) {
            forErrors.email_err = true;
            forErrors.email_err_msg = 'Inserta correo';
            error = true;
        } else if (!mailReg.test(email)) {
            forErrors.email_err = true;
            forErrors.email_err_msg = 'Correo no válido';
            error = true;
        }

        if (phone.trim().split('').length === 0) {
            forErrors.phone_err = true;
            forErrors.phone_err_msg = 'Inserta teléfono';
            error = true;
        } else if (phone.split('').length !== 12) {
            forErrors.phone_err = true;
            forErrors.phone_err_msg = 'Email no válido';
            error = true;
        }

        if (error) {
            setDataErrors({
                ...dataErrors,
                ...forErrors
            })
            return;
        }
        setLoading(true);
        axios.post("/website/save.alianza", data).then(res => {
            console.log(res.data);
            setLoading(false);
            setData({
                nombre: '',
                empresa: '',
                cargo: '',
                email: '',
                phone: ''
            });
            setPhone('');
            setDataMsg({
                severity: 'success',
                msg: 'Datos enviados.'
            });
            setOpen(true);
        }).catch(err => {
            setLoading(false);
            console.log("Error save alianza ->", err);
            setDataMsg({
                severity: 'error',
                msg: 'Hubo un error, intenta de nuevo.'
            });
            setOpen(true);
        })
        /* fetch('https://inxel.mx/api/xel/v1/ecommerce.save.alianza.json', {
             method: 'POST',
             body: JSON.stringify(data)
         }).then(response => response.text()).then(response => {
             console.log(response);
             setLoading(false);
             setData({
                 nombre: '',
                 empresa: '',
                 cargo: '',
                 email: '',
                 phone: ''
             });
             setPhone('');
             setDataMsg({
                 severity: 'success',
                 msg: 'Datos enviados.'
             });
             setOpen(true);
         }).catch(error => {
             console.log(error);
             setDataMsg({
                 severity: 'error',
                 msg: 'Hubo un error, intenta de nuevo.'
             });
             setOpen(true);
         })*/
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 1
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 1
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 1
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    const alliances = [
    ]
    useEffect(() => {
        const source = axio.CancelToken.source();
        if (bannersAlliance.length === 0)
            axios.get("/website/banners/alliances", {
                cancelToken: source.token
            }).then(res => {
                let {data} = res;
                let {desktop, mobile} = data;
                let deskBanners = [];
                let mobileBanners = [];
                for (let i = 0; i < desktop.length; i++) {
                    deskBanners.push(desktop[i].bannerId);
                }
                for (let i = 0; i < mobile.length; i++) {
                    mobileBanners.push(mobile[i].bannerId);
                }
                let banners = [];

                for (let i = 0; i < deskBanners.length; i++) {
                    banners.push({
                        id: deskBanners[i],
                        file: {
                            type: 'image',
                            files: {
                                desktop: `${axios.defaults.baseURL}website/banners/alliances/desktop/${deskBanners[i]}`,
                                mobile: `${axios.defaults.baseURL}website/banners/alliances/mobile/${mobileBanners[i]}`
                            }
                        }
                    })
                }
                //  setBanners(banners);
                dispatch(Actions.SETALLIANCEBANNER(banners))
            }).catch(err => {
                console.error(err);
            });
        return () => {
            source.cancel("Cancelado por desmontar");
        }
    }, [])
    return <>
        <NavBar hide={hideNav}/>
        <Carousel autoPlaySpeed={4000} responsive={responsive} autoPlay={true}
                  infinite={bannersAlliance.length > 1}>
            {
                bannersAlliance.map(item => <div key={item.id}>
                    <img style={{height: "100%", width: "100%"}}
                         src={noCel ? item.file.files.desktop : item.file.files.mobile} alt={`banner-${item.id}`}/>
                </div>)
            }
        </Carousel>
        {
            //  <Carousel arrows data={items} customindex={customIndex} setIndex={setCustomIndex}/>
        }
        <Titulo/>
        <Unitys/>
        <Grid item xs={12} style={{marginTop: "2%", marginBottom: "2%"}}>
            <Container>
                <Grid item xs={12} style={{marginTop: "2%", marginBottom: "2%"}}>
                    <Typography align={"center"} variant={"h4"} color={"primary"}
                                style={{fontWeight: XEL_FONT.bold_weight}}>
                        Alianzas
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                    {
                        alliances.map((item, i) => <Alliance key={i}
                                                             src={`${APIHOST}/website/assets/alliance/brands/${item}.png`}
                                                             name={item.toUpperCase()} url={item}/>)
                    }
                </Grid>
            </Container>
        </Grid>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={dataMsg.severity}>
                {dataMsg.msg}
            </Alert>
        </Snackbar>
        <Grid container style={{backgroundColor: '#F6F6F6'}}>
            <Container style={{marginTop: 100}}>
                <Grid container justify={'center'}>
                    <Grid item xs={12} style={{marginBottom: 20}}>
                        <Typography color={'primary'} style={{fontSize: useResponsiveValues(30, 25, 15)}}>
                            <strong> ¡Contáctanos!</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant={'body2'} style={{fontSize: useResponsiveValues(15, 12.5, 10)}}>
                            Estamos convencidos de que somos su mejor opción para incorporar un beneficio sin que le
                            represente
                            costos adicionales, si desea más información por favor llene el siguiente formulario y
                            un
                            Asesor
                            inXel se pondrá en contacto con usted dentro de las próximas 24 horas.
                        </Typography>
                    </Grid>
                    <Grid xs={12} sm={6} component={'form'}
                          style={{display: "flex", justifyContent: "center", marginTop: 0}}>
                        <Grid container justify={'center'}>
                            <Grid item xs={12}
                                  style={{display: "flex", justifyContent: "center", margin: '10px 0'}}>
                                <TextField classes={{root: style.input}}
                                           variant={"outlined"}
                                           fullWidth
                                           disabled={loading}
                                           error={dataErrors.nombre_err}
                                           helperText={dataErrors.nombre_err_msg}
                                    //id="standard-basic"
                                           value={data.nombre}
                                           name={'nombre'}
                                           onChange={handleChange}
                                           label="Nombre completo"
                                           size={'small'}
                                           inputProps={
                                               {
                                                   style: {fontSize: useResponsiveValues(15, 12, 10)}
                                               }
                                           }
                                           InputLabelProps={{
                                               style: {fontSize: useResponsiveValues(15, 12, 10)}
                                           }}
                                />
                            </Grid>
                            <Grid item xs={12}
                                  style={{display: "flex", justifyContent: "center", margin: '10px 0'}}>
                                <TextField

                                    value={data.empresa}
                                    name={'empresa'}
                                    onChange={handleChange}
                                    classes={{root: style.input}} variant={"outlined"} fullWidth id="standard-basic"
                                    disabled={loading}
                                    error={dataErrors.empresa_err}
                                    helperText={dataErrors.empresa_err_msg}
                                    label="Empresa"
                                    size={'small'}
                                    inputProps={
                                        {
                                            style: {fontSize: useResponsiveValues(15, 12, 10)}
                                        }
                                    }
                                    InputLabelProps={{
                                        style: {fontSize: useResponsiveValues(15, 12, 10)}
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}
                                  style={{display: "flex", justifyContent: "center", margin: '10px 0'}}>
                                <TextField

                                    value={data.cargo}
                                    name={'cargo'}
                                    onChange={handleChange}
                                    classes={{root: style.input}} variant={"outlined"} fullWidth id="standard-basic"
                                    disabled={loading}
                                    error={dataErrors.cargo_err}
                                    helperText={dataErrors.cargo_err_msg}
                                    label="Cargo"
                                    size={'small'}
                                    inputProps={
                                        {
                                            style: {fontSize: useResponsiveValues(15, 12, 10)}
                                        }
                                    }
                                    InputLabelProps={{
                                        style: {fontSize: useResponsiveValues(15, 12, 10)}
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}
                                  style={{display: "flex", justifyContent: "center", margin: '10px 0'}}>
                                <TextField

                                    value={data.email}
                                    name={'email'}
                                    onChange={handleChange}
                                    classes={{root: style.input}} variant={"outlined"} fullWidth id="standard-basic"
                                    disabled={loading}
                                    error={dataErrors.email_err}
                                    helperText={dataErrors.email_err_msg}
                                    label="Correo electronico"
                                    size={'small'}
                                    inputProps={
                                        {
                                            style: {fontSize: useResponsiveValues(15, 12, 10)}
                                        }
                                    }
                                    InputLabelProps={{
                                        style: {fontSize: useResponsiveValues(15, 12, 10)}
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}
                                  style={{display: "flex", justifyContent: "center", margin: '10px 0'}}>
                                <TextField classes={{root: style.input}} variant={"outlined"} fullWidth
                                           id="standard-basic"
                                           onChange={handleChangePhone}
                                           value={phone}
                                           disabled={loading}
                                           error={dataErrors.phone_err}
                                           helperText={dataErrors.phone_err_msg}
                                           label="Numero telefonico a 10 digitos"
                                           size={'small'}
                                           inputProps={
                                               {
                                                   style: {fontSize: useResponsiveValues(15, 12, 10)}
                                               }
                                           }
                                           InputLabelProps={{
                                               style: {fontSize: useResponsiveValues(15, 12, 10)}
                                           }}
                                />
                            </Grid>
                            <Grid item xs={12}
                                  style={{display: "flex", justifyContent: "flex-end", margin: '10px 0'}}>

                                {
                                    loading ?
                                        <Button variant="contained" color={'secondary'}
                                                classes={{root: style.button}}
                                                startIcon={<CircularProgress color={'primary'}
                                                                             style={{fontSize: textbutton}}/>}
                                                disabled={loading}
                                        >
                                            Enviar
                                        </Button> :
                                        <Button variant="contained" color={'secondary'}
                                                classes={{root: style.button}}
                                                onClick={handleSend}
                                                style={{fontSize: textbutton}}
                                                disabled={loading}
                                        >
                                            Enviar
                                        </Button>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>

        <Footer/>
    </>
}
