import React, {useCallback, useEffect, useState} from "react";
import {Container, Grid, useMediaQuery} from "@material-ui/core";

import {makeStyles} from "@material-ui/core/styles";
import { XEL_COLORS, XEL_FONT} from "../../../../constant";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {XelIconPhoneHand} from "../../../../assets/logos/svg";

import Particles from "react-particles";
import {loadFull} from "tsparticles";
import {useSelector} from "react-redux";
import PlanCard from "../../../ui/Plan/v2";

const useStyle = makeStyles({
    title_imei: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 30
    },

});

export const CheckImei = () => {
    const style = useStyle();
    return <Container style={{marginBottom: 70}}>
        <Grid container>
            <Grid item xs={12}>
                <Typography classes={{root: style.title_imei}} align={"center"}>
                    Conoce tu <span
                    style={{fontWeight: XEL_FONT.bold_weight, color: XEL_COLORS.secondary}}>IMEI </span> marcando
                    *#06#
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify={'center'}>
                    <Grid item xs={4} md={3} style={{display: "flex", justifyContent: "flex-end"}}>
                        <XelIconPhoneHand style={{fill: 'none', stroke: XEL_COLORS.primary, height: 150}}/>
                    </Grid>
                    <Grid item xs={8} md={4} style={{display: "flex", alignItems: "center"}}>
                        <Grid container>
                            <Grid item xs={12}> <TextField fullWidth id="imei-text" label="Ingresa tu IMEI"/>
                            </Grid>
                            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                <Button variant={"contained"} color={"primary"}
                                        style={{borderRadius: 10, marginTop: 10}}>
                                    Enviar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Container>
}
const FirstOption = () => {

    const matches2 = useMediaQuery('(min-width:426px)');
    const planes = useSelector(state => state.planes);
    const [plans, setPlans] = useState([]);
    useEffect(() => {
        try {
            let ps = planes.normal.filter(seccion => seccion.name === "Planes")[0].planes.filter(subseccion => subseccion.name.toLowerCase() === "wifi portátil")[0].planes;

            setPlans(ps);
        } catch (e) {
            console.log("eee",e)
        }
    }, [])
    return <>
        <Container>
            <Grid container justify={!matches2 ? 'center' : "space-around"}>
                {
                    plans.map(plan => <PlanCard key={plan.id}  noSocials={true} margin={.5} plan={plan}/>)
                }
                <Grid item xs={12}>
                    <Typography variant={'body2'} align={'center'} style={{fontSize: 11, marginBottom: '2%'}}>
                        *Los planes Xel-Fi no incluyen router portátil, el costo del router es de $929 y es un pago
                        único Todos los precios publicados incluyen I.V.A. Aplican restricciones.
                    </Typography>
                </Grid>

            </Grid>
        </Container>
    </>
}

const useCatalogStyles = makeStyles({
    title_filter: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 25,
        color: XEL_COLORS.primary
    },
    subtitle_filter: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 20,
        color: XEL_COLORS.primary
    }
});
const useCellStyles = makeStyles({
    container: {
        marginTop: 40
    },
    sub_container: {
        marginTop: 20
    },
    name_cell: {
        color: '#000000',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 35
    },
    model_cell: {
        color: '#4b4b4b',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 25
    },
    price_cell: {
        color: XEL_COLORS.secondary,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 45
    },
    image_container: {
        display: "flex",
        justifyContent: "center"
    },
    button: {
        color: '#4b4b4b',
        borderRadius: 20,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        marginTop: 30,
        fontSize: 20
    }, cell_container: {
        position: "relative",
        cursor: "pointer",
        padding: '10px auto'
    },
    cell_selector: {
        position: "absolute",
        top: 10,
        right: 10
    }
});

export default function DinamicBuy({option, setSel}) {

    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    return <Container style={{paddingTop: 70, paddingBottom: 30, position: "relative"}}>
        <div style={{position: 'absolute', backgrounColor: 'gray', height: '100%', width: '100%', zIndex: -1}}>
            <Particles
                options={{
                    particles: {
                        "number": {
                            "value":20
                        },
                        "size": {
                            "value": 3
                        },
                        color: XEL_COLORS.primary,
                        links: {
                            enable: true,
                            distance: 150,
                            color: XEL_COLORS.secondary,
                            opacity: 0.6,
                            width: 1,
                            shadow: {
                                enable: false,
                                blur: 5,
                                color: "lime"
                            }
                        },
                    },
                    interactivity: {
                        events: {
                            onhover: {
                                enable: true,
                                mode: "repulse"
                            }
                        }
                    }
                }
                }
                init={particlesInit}
            />
        </div>
        <FirstOption/>

    </Container>
}
