export default function OpenPayLocalServices() {

    const getError = (code) => {
        let system = 'Error desconocido';
        let user = 'Error de conexión';

        switch (parseInt(code)) {
            case 1000:
                system = 'Ocurrió un error interno en el servidor de Openpay';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1001:
                system = 'El formato de la petición no es JSON, los campos no tienen el formato correcto, o la petición no tiene campos que son requeridos.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1002:
                system = 'La llamada no esta autenticada o la autenticación es incorrecta.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1003:
                system = 'La operación no se pudo completar por que el valor de uno o más de los parámetros no es correcto.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1004:
                system = 'Un servicio necesario para el procesamiento de la transacción no se encuentra disponible.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1005:
                system = 'Uno de los recursos requeridos no existe.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1006:
                system = 'Ya existe una transacción con el mismo ID de orden.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1007:
                system = 'La transferencia de fondos entre una cuenta de banco o tarjeta y la cuenta de Openpay no fue aceptada.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1008:
                system = 'Una de las cuentas requeridas en la petición se encuentra desactivada.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1009:
                system = 'El cuerpo de la petición es demasiado grande.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1010:
                system = 'Se esta utilizando la llave pública para hacer una llamada que requiere la llave privada, o bien, se esta usando la llave privada desde JavaScript.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1011:
                system = 'Se solicita un recurso que esta marcado como eliminado.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1012:
                system = 'El monto transacción esta fuera de los limites permitidos.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1013:
                system = 'La operación no esta permitida para el recurso.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1014:
                system = 'La cuenta esta inactiva.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1015:
                system = 'No se ha obtenido respuesta de la solicitud realizada al servicio';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1016:
                system = 'El mail del comercio ya ha sido procesada.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1017:
                system = 'El gateway no se encuentra disponible en ese momento.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1018:
                system = 'El número de intentos de cargo es mayor al permitido.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 1020:
                system = 'El número de dígitos decimales es inválido para esta moneda';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 2001:
                system = 'La cuenta de banco con esta CLABE ya se encuentra registrada en el cliente.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 2003:
                system = 'El cliente con este identificador externo (External ID) ya existe.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 2004:
                system = 'El número de tarjeta es invalido.';
                user = 'Estimado cliente inXel, el número de tarjeta es invalido.';
                break;
            case 2005:
                system = 'La fecha de expiración de la tarjeta es anterior a la fecha actual.';
                user = 'Estimado cliente inXel, la fecha de expiración de la tarjeta es anterior a la fecha actual.';
                break;
            case 2006:
                system = 'El código de seguridad de la tarjeta (CVV2) no fue proporcionado.';
                user = 'Estimado cliente inXel, introduce el código de seguridad.';
                break;
            case 2007:
                system = 'El número de tarjeta es de prueba, solamente puede usarse en Sandbox.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 2008:
                system = 'La tarjeta no es valida para pago con puntos.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 2009:
                system = 'El código de seguridad de la tarjeta (CVV2) es inválido.';
                user = 'Estimado cliente inXel, el código de seguridad es inválido.';
                break;
            case 2010:
                system = 'Autenticación 3D Secure fallida.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 2011:
                system = 'Tipo de tarjeta no soportada.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 3001:
                system = 'La tarjeta fue declinada por el banco.';
                user = 'Estimado cliente inXel, su tarjeta fue declinada por su banco.';
                break;
            case 3002:
                system = 'La tarjeta ha expirado.';
                user = 'Estimado cliente inXel, la tarjeta ha expirado.';
                break;
            case 3003:
                system = 'La tarjeta no tiene fondos suficientes.';
                user = 'Estimado cliente inXel, la tarjeta no cuenta con fondos suficientes.';
                break;
            case 3004:
                system = 'La tarjeta ha sido identificada como una tarjeta robada.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 3005:
                system = 'La tarjeta ha sido rechazada por el sistema antifraude o Rechazada por coincidir con registros en lista negra.';
                user = 'Estimado cliente inXel, su compra no fue exitosa, por favor inténtelo con otra tarjeta y con otro correo electrónico. Para brindarle asistencia escribanos por el chat, gracias.';
                break;
            case 3006:
                system = 'La operación no esta permitida para este cliente o esta transacción.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 3009:
                system = 'La tarjeta fue reportada como perdida.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 3010:
                system = 'El banco ha restringido la tarjeta.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 3011:
                system = 'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.';
                user = 'Estimado cliente inXel, su banco ha solicitado que la tarjeta sea retenida. Contacte a su banco.';
                break;
            case 3012:
                system = 'Se requiere solicitar al banco autorización para realizar este pago.';
                user = 'Estimado cliente inXel, se requiere solicitar al banco autorización para realizar este pago.';
                break;
            case 3201:
                system = 'Comercio no autorizado para procesar pago a meses sin intereses.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 3203:
                system = 'Promoción no valida para este tipo de tarjetas.';
                user = 'Estimado cliente inXel, esta tarjeta no es válida para esta compra';
                break;
            case 3204:
                system = 'El monto de la transacción es menor al mínimo permitido para la promoción.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            case 3205:
                system = 'Promoción no permitida.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
            default:
                system = 'Error desconocido.';
                user = 'Estimado cliente inXel, ocurrió un error con nuestro sistema de pago, contáctanos para atender esta situación.';
                break;
        }
        return {
            system,
            user
        };

    }

    const getStoreCharge = id => {
        return new Promise((resolve, reject) => {
            // Llamamos a resolve(...) cuando lo que estabamos haciendo finaliza con éxito, y reject(...) cuando falla.
            // En este ejemplo, usamos setTimeout(...) para simular código asíncrono.
            // En la vida real, probablemente uses algo como XHR o una API HTML5.
            setTimeout(function () {
                if (id === 'andres') {
                    resolve('success');
                } else {
                    reject('error');
                }
            }, 2500);
        });
    }

    return {getError, getStoreCharge}
}