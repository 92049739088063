import React, {useEffect, useState} from "react";
import {Backdrop, Button, Card, CircularProgress, Divider, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Axios from "../../../../../../instances/axios";
import CardMethod from "./CardMethod";
import {Alert} from "@material-ui/lab";
import {XEL_COLORS, XEL_FONT} from "../../../../../../constant";
import Banca from "./Banca";
import Tienda from "./Tienda";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {GetApp, Security} from "@material-ui/icons";
import PinRequest from "./PinRequest";
import Exito from "../../../../../../assets/improve_pays/exito.png";
import Fallo from "../../../../../../assets/improve_pays/fallo.png";
import cookie from "js-cookie";

const useStyles = makeStyles({
    supcontainer: {
        padding: "2%"
    },
    container: {
        // backgroundColor: "#FEFEFE",
        boxShadow: "-3px 3px 9px -3px rgba(0,0,0,0.6)",
        height: "fit-content"
    },
    url: {
        cursor: "pointer",
        color: "#00A8E8",
        textDecoration: "none",
        "&:hover": {
            // textDecoration: "underline",
        }
    },
    titleContainer: {
        backgroundColor: XEL_COLORS.primary,
        padding: "3%",
        color: "#FEFEFE"
    },
    payButton: {
        color: "#FEFEFE",
        backgroundColor: "green",
        "&:hover": {
            backgroundColor: "green",
        },
        width: "100%",
        marginTop: "4%"
    }
});


const MethodContainer = ({payParams, method, rollBack, onCompleted}) => {
    const styles = useStyles();
    const [total, setTotal] = useState(0);
    const history = useHistory();
    const axios = Axios();
    const [selectedCard, setSelectedCard] = useState(null);
    const [internFunctions, setInternFunctions] = useState({});
    const [sessionId, setSessionId] = useState(null);
    const session = useSelector(state => state.login);
    const cardDefaults = {
        clientName: "",// "Andres",
        clientLastName: "",// "Perez",
        cardNumber: "",// "4111111111111111",
        cardName: "",// "Andres",
        cardExpirationMonth: "",//"10",
        cardExpirationYear: "",//"26",
        cardCvv: "",//"325",
        device_session_id: "",// "",
        //    saveCard: false,
        clientEmail: "",//"ejemplo@gmail.com"
    }
    const [card, setCard] = useState(cardDefaults);
    const [addNewCard, setAddNewCard] = useState(!session.logged);
    const [status, setStatus] = useState({
        loading: false,
        message: "Cargando..."
    });
    const [error, setError] = useState({
        error: false,
        message: "Hola mundo"
    });

    const [details, setDetails] = useState([]);

    const [showDetails, setShowDetails] = useState(false);

    const [processed, setProcessed] = useState(false);

    const [urlWindow, setUrlWindow] = useState();

    /*Orden de pago*/
    const [orderId, setOrderId] = useState(null);
    /*Pago mediante tienda*/
    const [storeParams, setStoreParams] = useState({
        created: false,
        reference: null,
        expire: null,
        amount: 0,
        url: ""
    });
    /*Activar 3d secure*/
    const [secure3d, setSecure3d] = useState(false);
    const [url3d, setUrl3d] = useState({
        loading: false,
        url: null
    });
    /*Fin pago mediante tienda*/

    /*Pin de autorizacion*/
    const [authPin, setAuthPin] = useState({
        open: false,
        pin: ""
    });

    const [payError, setPayError] = useState({
        error: false,
        message: "Hubo un error al procesar tarjeta",
        code: 3001
    });

    const [blocked, setBlocked] = useState({
        blocked: false,
        message: "",
        code: 3001
    });

    const [privateRelayMessageClosed, setPrivateRelayMessageClosed] = useState(false);


    /*Pagar*/
    const [enablePay, setEnablePay] = useState(false);
    /*Pago mediante spei*/
    const [bancaOption, setBancaOption] = useState(1);
    const [speiParams, setSpeiParams] = useState({
        created: false,
        bbva: {
            cie: "agreement",
            reference: "name",
        },
        other: {
            concept: "name",
            clabe: "clabe",
            reference: "agreement"
        },
        url: "",
        dueDate: null,
    });
    /*Fin pago mediante spei*/

    // eslint-disable-next-line no-undef
    const Openpay = OpenPay


    const [intervalFunction, setIntervalFunction] = useState(0);


    useEffect(() => {
        if (intervalFunction === 0 && url3d.loading) {
            let interval = setInterval(() => {
                let value = cookie.get(`xel-pay-confirmed-${url3d.order_id}`);
                if (value === '1') {
                    if (onCompleted) onCompleted();
                    setProcessed(true);
                    setError(prevState => ({...prevState, error: false}));
                } else {
                    let error = cookie.get(`xel-pay-failed-${url3d.order_id}`);
                    if (error === '1') {
                        setError({
                            error: true,
                            message: "Hubo un problema al procesar el pago. Intenta nuevamente."
                        });
                        setUrl3d({
                            loading: false,
                            url: null
                        });
                        if (urlWindow)
                            urlWindow.close();
                        setUrlWindow(null);
                    }
                }
            }, 1000);
            setIntervalFunction(interval);
        }
        return () => {
            clearInterval(intervalFunction);
        }
    }, [url3d]);


    /*Verificar que se pueda realizar el pago*/
    useEffect(() => {
        let enable = false
        switch (method) {
            case 1:
                if (!blocked.blocked) {
                    if (session.logged)
                        enable = (selectedCard) && !status.loading;
                    else
                        enable = !status.loading;
                } else {
                    enable = false;
                }
                break;
            case 2:
                enable = !status.loading;
                break;
            case 3:
                enable = !status.loading;
                break;
            default:
                break;
        }

        if (!status.loading) {
            setEnablePay(enable)
        }

        if (status.loading || (!session.logged && !privateRelayMessageClosed))
            setEnablePay(false)

    }, [speiParams, selectedCard, card, bancaOption, addNewCard, status, privateRelayMessageClosed])

    /*Detalles y total a pagar*/
    useEffect(() => {
        if (payParams) {
            let tot = 0;
            for (let i = 0; i < payParams.length; i++) {
                const {msisdn, params: {postpago, prepago}} = payParams[i];
                let conta = 0;

                let detail = {
                    msisdn,
                }

                let pays = [];

                if (postpago) {
                    pays.push(`Mensualidad $${postpago}.00`);
                    tot += postpago;
                    conta++;
                }

                if (prepago) {
                    pays.push(`Recarga ${prepago.name} $${prepago.price}.00`);
                    conta++;
                    tot += prepago.price;
                }

                detail.pays = pays;

                if (conta > 0) {
                    setDetails(prevState => [...prevState, detail])
                }
            }
            setTotal(tot);
        }
    }, [payParams])


    useEffect(() => {
        let device_session_id = Openpay.deviceData.setup("pay-container");
        setSessionId(device_session_id);
    }, []);

    useEffect(() => {
        lastPays();
    }, []);
    const lastPays = () => {
        axios.get("/clients/last").then(res => {
            console.log("last", res.data);
            const {data} = res;
            const {status} = data;
            if (status === 403) {
                setBlocked({
                    blocked: true,
                    message: data.lasta.errorDescription,
                    code: data.lasta.errorCode
                })
            }
        }).catch(err => {
            console.error(err)
        });
    }

    const createToken = () => new Promise((resolve, reject) => {

        let validateCard = Openpay.card.validateCardNumber(card.cardNumber);
        if (!validateCard) {
            setError({
                error: true,
                message: "Número de tarjeta no válido"
            });
            reject({});
            return;
        }

        let validateCVV = Openpay.card.validateCVC(card.cardCvv, card.cardNumber);
        if (!validateCVV) {
            setError({
                error: true,
                message: "Código de seguridad no válido"
            });
            reject({});
            return;
        }

        let validateExpiry = Openpay.card.validateExpiry(card.cardExpirationMonth, card.cardExpirationYear);

        if (!validateExpiry) {
            setError({
                error: true,
                message: "Fecha de expiración no válida"
            });
            reject({});
            return;
        }

        Openpay.token.create({
            "card_number": card.cardNumber,
            "holder_name": card.cardName,
            "expiration_year": card.cardExpirationYear,
            "expiration_month": card.cardExpirationMonth,
            "cvv2": card.cardCvv,
        }, data => {
            let token = data.data.id;
            resolve(token);
        }, error => {
            const {
                category,
                description,
                http_code,
                error_code,
                request_id,
                fraud_rules
            } = error.data;

            setError({
                error: true,
                message: "Hubo un problema al procesar tarjeta, código: " + error_code
            });
            reject(error);
        });
    });

    const reset = () => {
        setAuthPin({
            open: false,
            pin: ""
        });
        setStatus({
            loading: false,
            message: ""
        })
    }
    const closeAuthPin = () => {
        setAuthPin({
            open: false,
            pin: ""
        });
        setStatus({
            loading: false,
            message: "Iniciando..."
        });
    }
    const processPay = () => new Promise(async resolve => {

        let params = {
            pay_params: payParams,
            order_id: orderId,
            device_session_id: sessionId,

        }

        if (!authPin.open) {
            if (status.loading) {
                return;
            }
        }

        setStatus({
            loading: true,
            message: "Iniciando..."
        });

        if (method === 1) {
            if (session.logged) {
                /*if (!selectedCard) {
                    if (!card.check_accept) {
                        setError({
                            error: true,
                            message: "Por favor marque la casilla de aceptación para guardar la tarjeta",
                            severity: "info"
                        });
                        setStatus({
                            loading: false,
                            message: "Procesando tarjeta..."
                        });
                        return;
                    }
                }*/
                if (!authPin.open) {
                    setAuthPin(prevState => ({
                        ...prevState,
                        open: true
                    }));
                    return;
                }
                setAuthPin(prevState => ({
                    ...prevState,
                    open: false
                }));
            } else {
                params.customer = {
                    name: card.clientName,
                    last_name: card.clientLastName,
                    email: card.clientEmail,
                }
            }


            params.card = selectedCard;
            if (session.logged) {
                params.cvConfirm = authPin.pin;
            }


            if (!selectedCard) {

                let token = null;

                setStatus({
                    loading: true,
                    message: "Procesando tarjeta..."
                });

                try {
                    token = await createToken();
                } catch (e) {
                    reset();
                    return;
                }


                //   if (card.saveCard) {
                if (session.logged) {
                    setStatus({
                        loading: true,
                        message: "Guardando tarjeta..."
                    });
                    try {
                        let res = await axios.post("/clients/card", {
                            token,
                            device_session_id: sessionId
                        });
                        let data = res.data;

                        if (data.error) {
                            setError({
                                error: true,
                                message: data.error_descripcion,
                                severity: "error"
                            });
                            reset();
                        } else {
                            params.card = data.id;
                        }
                    } catch (e) {
                        setError({
                            error: true,
                            message: "Al comunicarse con el servidor, intente más tarde",
                            severity: "error"
                        });
                        reset();
                        resolve();
                        return;
                    }
                } else {
                    params.card = token;
                }
            }
        }

        let form = "card";

        if (session.logged) {
            if (method === 1)
                form = "card";
            else if (method === 2)
                form = "bank";
            else if (method === 3)
                form = "cash";
        }

        setStatus({
            loading: true,
            message: "Procesando pago..."
        })

        axios.post(`/clients/${session.logged ? 'pay' : "pay.guest"}/${form}${secure3d ? "?secure=true" : ""}`, params).then(res => {
                let data = res.data;
                // console.log('data-charge', JSON.stringify(data));
		// return
                setStatus({
                    loading: false,
                    message: "Procesando pago..."
                });
                if (data.error) {
                    if (data.error_code === 3005) {
                        setError({
                            error: true,
                            message: "El pago no pudo ser procesado, tu proximo intento sera procesado mediante 3d Secure",
                            severity: "warning"
                        });
                        setSecure3d(true);

                    } else
                        setError({
                            error: true,
                            message: data.error_description,
                            severity: "error",
                        });
                    return;
                }
                if (method === 1) {
                    if (data.payment_method) {
                        if (data.payment_method.url) {
                            setUrl3d({
                                loading: false,
                                url: data.payment_method.url,
                                order_id: data.order_id
                            });
                            setError({
                                error: true,
                                severity: "warning",
                                message: "Seras redireccionado para completar tu pago."
                            });
                        }
                    } else {
                        if (onCompleted) onCompleted();
                        setProcessed(true);
                    }
                } else if (method === 2) {
                    setSpeiParams({
                        created: true,
                        bbva: {
                            cie: data.payment_method.agreement,
                            reference: data.payment_method.name,
                        },
                        other: {
                            concept: data.payment_method.name,
                            clabe: data.payment_method.clabe,
                            reference: data.payment_method.agreement
                        },
                        url: data.url,
                        dueDate: data.due_date,
                    })
                } else if (method === 3) {
                    setStoreParams({
                        created: true,
                        reference: data.payment_method.reference,
                        url: data.url,
                        expire: data.due_date,
                        amount: data.amount
                    })
                }
            }
        ).catch(err => {
            console.log(err);
            reset();
            setStatus({
                loading: false,
                message: "Procesando pago..."
            })
        });
        resolve();
    });
    const handlePay = () => {
        //:TODO manejar pago global de metodo tarjeta

        if (processed) {
//            history.push("/recarga")
            window.location.reload();
            return;
        } else if ((method === 2 && speiParams.created) || (method === 3 && storeParams.created)) {
            setProcessed(true);
        }

        setStatus({
            loading: true,
            message: "Iniciando..."
        });
        processPay();
    }
    const addCard = async () => {
        let token;
        try {
            token = await createToken();
        } catch (e) {
            reset();
            return;
        }
        try {
            let res = await axios.post("/clients/card", {
                token,
                device_session_id: sessionId
            });
            let data = res.data;

            if (data.error) {
                setError({
                    error: true,
                    message: data.error_descripcion,
                    severity: "error"
                });
                reset();
            } else {
                let id = data.id;
                //params.card = data.id;
            }
        } catch (e) {
            setError({
                error: true,
                message: "Al comunicarse con el servidor, intente más tarde",
                severity: "error"
            });
            reset();
        }
    }


    const handlCardUpdate = (name, value) => {
        setCard(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    return <Grid id={"pay-container"} container justify={"space-around"} style={{
        //backgroundColor: "#EEEEEE",
        //boxShadow: "-3px 3px 9px -3px rgba(0,0,0,0.6)",
        padding: 3,
        position: "relative"
    }}>
        {
            authPin.open &&
            <PinRequest close={closeAuthPin} processPay={processPay} setPin={pin => setAuthPin({open: true, pin})}/>
        }
        <Backdrop open={error.error} style={{zIndex: 100}}>
            <Grid container justify={"center"}>
                <Grid item xs={11} md={6}>
                    <Card>
                        <Grid container>
                            <Grid item xs={12}
                                  style={{
                                      padding: "2%",
                                      backgroundColor: error.severity === "error" ? "#ef5350" : "#03a9f4"
                                  }}>
                                <Typography style={{color: "#FEFEFE", fontWeight: XEL_FONT.bold_weight}}>
                                    {
                                        error.severity === "error" ?
                                            "Error" :
                                            "Información"
                                    }
                                </Typography>
                            </Grid>

                            <Grid item xs={12} style={{padding: "4%",}}>
                                <Typography align={"center"}>
                                    {
                                        error.message
                                    }
                                </Typography>
                            </Grid>
                            {
                                payError.error && <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant={"h3"} style={{color: "#CF0606"}} align={"center"}>
                                                Pago no procesado
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                            <img src={Fallo} style={{width: "30%", height: "100%"}} alt={"fallo"}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"} align={"center"}>
                                                El pago no pudo ser procesado debido
                                                a... <strong>{payError.message}</strong>
                                            </Typography>
                                        </Grid>
                                        {
                                            !session.logged && <Grid item xs={12} style={{padding: "2%"}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography align={"center"}>
                                                            Estimado cliente, su pago no pudo ser procesado, inicie
                                                            sesión para
                                                            utilizar un método de pago alternativo.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider variant={"fullWidth"}
                                                                 style={{backgroundColor: XEL_COLORS.secondary}}/>
                                                    </Grid>
                                                    <Grid item xs={12}
                                                          style={{display: "flex", justifyContent: "center"}}>
                                                        <Button
                                                            variant={"contained"}
                                                            color={"secondary"}
                                                            style={{color: "#FEFEFE"}}
                                                            onClick={() => {
                                                                history.push("/login");
                                                            }}>
                                                            Iniciar sesión
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={12}
                                  style={{display: "flex", justifyContent: "center", paddingBottom: "2%"}}>
                                <Button disabled={url3d.loading} color={"primary"} variant={"contained"}
                                        onClick={() => {
                                            if (url3d.url) {
                                                setUrl3d(prevState => ({...prevState, loading: true}));
                                                let winUrl = window.open(url3d.url);
                                                setUrlWindow(winUrl);
                                            } else
                                                setError({error: false})
                                        }}>
                                    {
                                        url3d ? "Continuar" : "Cerrar"
                                    }
                                    {
                                        url3d.loading && <CircularProgress size={25} color={'primary'}/>
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Backdrop>
        {
            processed && <Grid item xs={11} md={8} style={{padding: "3%"}}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={"h3"} style={{color: "#1EB20D"}} align={"center"}>
                            {
                                method === 1 ? "Pago exitoso" : "Datos generados"
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                        <img src={Exito} style={{width: "30%", height: "100%"}} alt={"exito"}/>
                    </Grid>
                    <Grid item xs={12}
                          style={{margin: "1%", borderRadius: 10, backgroundColor: "#ffe1c7", padding: "1%"}}>
                        <Typography variant={"body1"} align={"center"} color={"primary"}
                                    style={{fontWeight: XEL_FONT.bold_weight}}>
                            En breve recibirás un correo con la confirmación de tu pago
                        </Typography>
                        <Divider variant={"fullWidth"} style={{backgroundColor: XEL_COLORS.secondary}}/>
                        <Typography variant={"body2"} align={"center"}>
                            (En caso de no encontrar el correo en tu bandeja principal verifica
                            tu bandeja de correo no deseado)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                        <Button
                            color={"primary"}
                            variant={"contained"}
                            onClick={() => {
                                window.location.reload()
                            }}
                        >
                            Volver al inicio
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        }
        {
            (blocked.blocked && method === 1) &&
            <Grid item xs={12} style={{marginBottom: 10}}>
                <Alert severity={"warning"} style={{display: "flex", flexDirection: "column"}}>
                    <Typography variant={"body2"}>
                        Sus últimos tres pagos con tarjeta fueron rechazados, por esta razón no podrás realizar más
                        intentos. Por favor intenta otra forma de pago o comunícate a nuestro centro de atención a
                        clientes.
                    </Typography>
                    <Typography variant={"caption"}>Código de error: {blocked.code}</Typography>
                </Alert>
            </Grid>
        }
        {
            !processed && <Grid item xs={11} md={8} className={styles.container}>
                {
                    method === 1 &&
                    <CardMethod
                        block={blocked}
                        error={null}
                        status={status}
                        setFunctions={setInternFunctions}
                        add={addNewCard}
                        setAdd={() => {
                            setAddNewCard(!addNewCard);
                            setSelectedCard(null);
                        }}
                        card={card}
                        updateCard={handlCardUpdate}
                        rollBack={rollBack}
                        selectCard={id => setSelectedCard(id)}
                        selected={selectedCard}
                        pin={authPin}
                        setPin={setAuthPin}
                        addCard={addCard}

                        functions={{
                            closePrivateRelay: () => setPrivateRelayMessageClosed(true),
                        }}
                    />
                }
                {
                    method === 2 &&
                    <Banca rollBack={rollBack} total={total} setSpeiParams={setSpeiParams} payParams={payParams}
                           speiParams={speiParams}
                           setOption={setBancaOption} option={bancaOption}/>
                }
                {
                    method === 3 &&
                    <Tienda rollBack={rollBack} storeParams={storeParams} setStoreParams={setStoreParams}
                            total={total}/>
                }
            </Grid>
        }
        {
            !processed && !speiParams.created && !storeParams.created &&
            <Grid item xs={11} md={3} className={styles.container}>
                <Grid container>
                    <Grid item xs={12} className={styles.titleContainer}>
                        <Typography variant={"overline"}>
                            Detalles del pago
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{padding: "4%"}}>
                        <Grid container>
                            <Grid item xs={9}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} style={{display: "flex", justifyContent: "start"}}>
                                        <Typography variant={"body1"}>
                                            Total a pagar
                                        </Typography>
                                        <Typography onClick={() => setShowDetails(!showDetails)} variant={"caption"}
                                                    className={styles.url} style={{marginLeft: "3%"}}>
                                            Ver detalle
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={"caption"}>
                                            IVA incluido - Pesos mexicanos
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography align={"center"}>
                                    ${total}.00
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {
                                        showDetails && details.map((detail, i) => {
                                            return <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography variant={"caption"}
                                                                    style={{fontWeight: XEL_FONT.bold_weight}}>
                                                            {detail.msisdn}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}
                                                          style={{display: "flex", flexDirection: "column"}}>
                                                        {
                                                            detail.pays.map(pay =>
                                                                <Typography variant={"caption"}>
                                                                    - {pay}
                                                                </Typography>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disabled={!enablePay
                                        //|| error.error || status.loading || (method === 1 && !selectedCard)
                                    }
                                    onClick={handlePay}
                                    variant={"contained"}
                                    className={styles.payButton}>
                                    {
                                        !processed && method === 1 && "Pagar"
                                    }
                                    {
                                        !processed && ((method === 2 && !speiParams.created) || (method === 3 && !storeParams.created)) && "Generar informacion de pago"
                                    }
                                    {
                                        !processed && ((method === 2 && speiParams.created) || (method === 3 && storeParams.created)) && "Finalizar"
                                    }
                                    {
                                        processed && "Volver a pagos"
                                    }
                                </Button>
                                {
                                    method === 1 && secure3d && <><Typography variant={"caption"} align={"center"}
                                                                              style={{
                                                                                  color: "blue",
                                                                                  marginTop: "4%",
                                                                                  cursor: "pointer",
                                                                                  textDecoration: "underline",
                                                                                  display: "flex",
                                                                                  justifyContent: "center"
                                                                              }}>
                                        3D Secure Activo
                                        <Security fontSize={"small"} color={"primary"}/>
                                    </Typography> </>
                                }
                                {
                                    status.loading &&
                                    <Grid item xs={12} style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        marginTop: "2%"
                                    }}>
                                        <CircularProgress size={15}/>
                                        <Typography variant={"caption"} style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "2%",
                                        }}>
                                            {
                                                status.message
                                            }
                                        </Typography>
                                    </Grid>
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        }
    </Grid>
}


export default MethodContainer;
