import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Axios from "../../../../../../../instances/axios";
import CardItem from "./CardItem";
import NewCard from "./NewCard";
import {useSelector} from "react-redux";
import {Alert} from "@material-ui/lab";
import { XEL_COLORS } from "../../../../../../../constant"
const { secondary, secondary_dark, primary2, primary_shiny } = XEL_COLORS

const useStyles = makeStyles({
    supcontainer: {
        padding: "2%"
    },
    container: {
        backgroundColor: "#FEFEFE",
        boxShadow: "-3px 3px 9px -3px rgba(0,0,0,0.6)",
        //margin:"1%"
    },
    url: {
        cursor: "pointer",
        color: "#00A8E8",
        textDecoration: "none",
        "&:hover": {
            // textDecoration: "underline",
        }
    },
    titleContainer: {},
    payButton: {
        color: "#FEFEFE",
        backgroundColor: "green",
        "&:hover": {
            backgroundColor: "green",
        },
        width: "100%",
        marginTop: "4%"
    }
});


const CardMethod = ({
                        selectCard,
                        selected,
                        rollBack,
                        setAdd,
                        add,
                        card,
                        updateCard,
                        setFunctions,
                        status,
                        error,
                        addCard,
                        block,
                        functions
                    }) => {
    const styles = useStyles();
    const session = useSelector(state => state.login);
    const axios = Axios();
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingCards, setLoadingCards] = useState(false);
    const [windowCheck, setWindowCheck] = useState({
        checked: false,
        isWindows: false,
        accept: false,
    });
    useEffect(() => {
        loadCards();
    }, [add]);
    useEffect(() => {
        setWindowCheck({
            checked: true,
            isWindows: window.navigator.appVersion.indexOf("Win") === -1,
            accept: false
        });
    }, []);

    useEffect(() => {
        (windowCheck.isWindows || windowCheck.accept) && (functions && functions.closePrivateRelay());
    }, [windowCheck]);
    const loadCards = () => {
        if (session.logged && !add) {
            setLoadingCards(true);
            axios.get("/clients/card").then(res => {
                const {status, message, cards: all} = res.data;
                setLoadingCards(false);
                if (status !== 200) {
                    return
                }
                let cards = [];

                for (let i = 0; i < all.length; i++) {
                    let card = all[i];

                    const {
                        id, card_number, bank_name, type, brand, source
                    } = card;
                    if (source === "openpay")
                        cards.push({
                            id, number: card_number, type, brand, bank: bank_name
                        });
                }
                setCards(cards);
            }).catch(err => {
                setLoadingCards(false);
                console.log("ERROR", err);
            })
        }
    }

    return <Grid container>
        <Grid item xs={12}>
            <Grid container>
                {
                    session.logged && <Grid item xs={12}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                backgroundColor: "#F9F5FB",
                                                padding: "3%"
                                            }}>
                        <Typography variant={"overline"}>
                            Tarjeta de crédito/débito
                        </Typography>
                        <Typography variant={"overline"} className={styles.url} onClick={() => {
                            if (rollBack) {
                                rollBack()
                            }
                        }}>
                            {
                                session.logged ? "Cambiar medio de pago" : "Volver"
                            }
                        </Typography>
                    </Grid>
                }
                {
                    error && <Grid item xs={12}>
                        <Alert severity="error">{error}</Alert>
                    </Grid>
                }
                <Grid item xs={12} style={{padding: "2%"}}>
                    <Grid container spacing={1}>
                        {
                            loadingCards && <Grid item xs={12}>
                                <CircularProgress color={"primary"} size={30}/>
                            </Grid>
                        }
                        {
                            !add && <>
                                {
                                    cards.map(card => <Grid key={card.id} item xs={12}>
                                        <CardItem
                                            {
                                                ...card
                                            }
                                            selectCard={() => selectCard(card.id)}
                                            selected={selected === card.id}/>
                                    </Grid>)
                                }

                            </>
                        }

                        {
                            add && <Grid item xs={12}>


                                <NewCard setWindowsCheck={setWindowCheck} windowsCheck={windowCheck} status={status}
                                         setFunctions={setFunctions} card={card}
                                         updateCard={updateCard}/>
                            </Grid>
                        }

                        {
                            session.logged && <Grid item xs={12}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: 'flex-end',
                                                        marginTop: "3%"
                                                    }}>
                                {
                                    !add && <Button variant={"contained"} color={"secondary"} style={{color: "#FEFEFE", background: `linear-gradient(${ secondary }, ${ secondary_dark })`}}
                                                    onClick={() => {
                                                        if (status.loading) {
                                                            return;
                                                        }
                                                        setAdd();
                                                    }}>
                                        <Typography variant={"caption"}>Agregar tarjeta</Typography>
                                    </Button>
                                }
                                {
                                    (add && (windowCheck.isWindows || windowCheck.accept)) && <>
                                        <Button style={{margin: 3, background: `linear-gradient(${ primary2 }, ${ primary_shiny })`}}
                                                disabled={loading}
                                                onClick={async () => {
                                                    setLoading(true);
                                                    await addCard();
                                                    setLoading(false);
                                                    setAdd();
                                                }} variant={"contained"} color={"primary"}>
                                            Aceptar y Guardar
                                        </Button>
                                        <Button style={{margin: 3, color: "#FEFEFE", background: `linear-gradient(${ secondary }, ${ secondary_dark })`}} disabled={loading}
                                                variant={"contained"} color={"secondary"}
                                                onClick={() => {
                                                    if (status.loading) {
                                                        return;
                                                    }
                                                    setAdd();
                                                }}>
                                            Cancelar
                                        </Button>
                                    </>
                                }
                            </Grid>
                        }
                        {
                            !session.logged && <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end"}}>
                                <Button variant={"contained"} color={"secondary"} style={{color: "#FEFEFE"}}
                                        onClick={() => {
                                            if (rollBack) {
                                                rollBack()
                                            }
                                        }}>
                                    Volver
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    </Grid>
}


export default CardMethod;
