import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import carouselArrow from '../../../assets/images/carouselArrow.svg'
import { useResponsiveValues } from '../../utils/hooks'

const Arrow = ({ left, onClick }) => {
    const separation = useResponsiveValues('5%', 6, 6)
    const width = useResponsiveValues(80, 57, 45)

    return (
        <img
            onClick={onClick}
            style={{
                transform: `rotate( ${left ? '0deg' : '180deg'} )`,
                width,
                position: 'absolute',
                left: left ? separation : 'unset',
                right: !left ? separation : 'unset',
                cursor: 'pointer',
            }}
            src={carouselArrow}
        />
    )
}

// ridiculous solution but nothing else works
const CarouselOf3 = ({ children }) => (
    <Carousel
        {...{
            infinite: false,
            autoPlaySpeed: 2000,
            responsive: {
                desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3,
                },
                tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 1,
                },
                mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                },
            },
            customLeftArrow: <Arrow left />,
            customRightArrow: <Arrow right />,
        }}>
        {children}
    </Carousel>
)

export default ({ children, selectedTypeIndex }) => {
    const [itemAmount, setItemAmount] = useState(children.length)
    useEffect(() => {
        setItemAmount(children.length)
    }, [selectedTypeIndex])

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
            <div style={{ width: '100%', maxWidth: 1300, flex: 1 }}>
                {itemAmount < 4 ? (
                    <CarouselOf3 { ...{ children } } />
                ) : (
                    <Carousel
                        {...{
                            infinite: false,
                            autoPlaySpeed: 2000,
                            responsive: {
                                desktop: {
                                    breakpoint: { max: 3000, min: 1024 },
                                    items: 4,
                                },
                                tablet: {
                                    breakpoint: { max: 1024, min: 464 },
                                    items: 1,
                                },
                                mobile: {
                                    breakpoint: { max: 464, min: 0 },
                                    items: 1,
                                },
                            },
                            customLeftArrow: <Arrow left />,
                            customRightArrow: <Arrow right />,
                        }}>
                        {children}
                    </Carousel>
                )}
            </div>
        </div>
    )
}
