import React from 'react';
import NavBar from "../../ui/NavBar";
import Logo from '../../../logo-inxel-color.png';
import Footer from '../../ui/Footer';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

export default function Prox() {
    return <>
        <NavBar/>
        <Container style={{height:'70vh',display:"flex",flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <img src={Logo} alt="inXel"/>
            <Typography variant="overline">
                Proximamente
            </Typography>
        </Container>

        <Footer/>
    </>
}