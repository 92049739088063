import React, {useEffect, useState} from "react";
import {Hidden, Paper} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackIos, ArrowForwardIos} from "@material-ui/icons";
import Slider from "react-slick";
import Grid from "@material-ui/core/Grid";
import Flecha from "../../../../../assets/svg/Flecha_Carrusel.svg";
import {makeStyles} from "@material-ui/core/styles";
import Fondo from "../../../../../assets/animaciones/carr/fondo.svg";
import {XEL_COLORS} from "../../../../../constant";
import Logo1 from "../../../../../assets/GIFCARRUSEL/LOGOSVG/MESA01.svg";
import Logo2 from "../../../../../assets/GIFCARRUSEL/LOGOSVG/MESA02.svg";
import Logo3 from "../../../../../assets/GIFCARRUSEL/LOGOSVG/MESA03.svg";
import Logo4 from "../../../../../assets/GIFCARRUSEL/LOGOSVG/Recurso 4.svg";
import {useResponsiveValues} from "../../../../utils/hooks";
import Gif1 from '../../../../../assets/GIFCARRUSEL/carrusel_1_final-min.gif';
import Gif2 from '../../../../../assets/GIFCARRUSEL/carrusel_2_final-min.gif';
import Gif3 from '../../../../../assets/GIFCARRUSEL/carrusel_3_final-min.gif';
import Gif4 from '../../../../../assets/GIFCARRUSEL/carrusel_4_final-min.gif';
import {useHistory} from 'react-router-dom';
import {withRouter} from "react-router";

const useStyles = makeStyles({
    contendedor: {
        backgroundImage: `url(${Fondo})`,
        //height: '26vmax',
        width: '100%',
        position: "relative",
        backgroundSize: 'cover',
        display: "flex",
        alignItems: "center",
        '& img': {
            height: '100%',
            width: '100%'
        }
    },
    img: {
        height: '100%',
        width: '100%',
        position: "absolute",
        zIndex: 1
    },
    logo_cont: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
        height: '100%'
    },
    controller: {
        width: '8vmin',
        height: '8vmin',
        position: "absolute",

        borderRadius: '50%',
        zIndex: 3,
        backgroundColor: 'rgba(255,255,255,0.5)',

        cursor: "pointer",
        '&:hover': {
            backgroundColor: XEL_COLORS.secondary,

        },
        display: "flex",
        alignItems: "center",
        '& img': {
            height: '30%',
            paddingLeft: 3
        }
    },
    controller_left: {
        left: '-4vmin',
        justifyContent: "flex-end",
        '& img': {
            marginRight: '1%',
            transform: 'rotate(180deg)',

        }
    },
    controller_right: {
        right: '-4vmin',
        justifyContent: "flex-start",
    }


})

const LogoLoad = () => {
    const styles = useStyles();
    const [logo, setLogo] = useState({
        logo: Logo1,
        pos: 1
    });
    useEffect(() => {
        setTimeout(() => {
            let l = logo.logo;
            let posi = logo.pos;
            if (logo.pos === 1) {

                l = Logo2;
                posi = 2;
            }
            if (logo.pos === 2) {
                l = Logo3;
                posi = 3;
            }
            if (logo.pos === 3) {
                l = Logo4;
                posi = 4;
            }
            if (logo.pos === 4) {
                l = Logo1;
                posi = 1;
            }
            setLogo({
                logo: l,
                pos: posi
            })
        }, 300);
    }, [logo]);

    return <div className={styles.logo_cont}>
        <img src={logo.logo} style={{height: 70, width: 70}} alt="inXel"/>
    </div>
}


const Item = ({item, action}) => {
    const styles = useStyles();
    const size = useResponsiveValues('28vmax', '23vmax', '15vmax')
    return (
        <>
            <div className={styles.contendedor} style={{cursor: action ? 'cursor' : 'initial', height: size}}
                 onClick={() => {
                     if (action)
                         action();
                 }}>
                <img src={item} alt=""/>
            </div>
        </>
    )
}


const BackButton = (props) => {
    const styles = useStyles();
    return <span {...props} className={`${styles.controller} ${styles.controller_left}`}>
                    <img src={Flecha} alt="Anterior"/>
                </span>
}
const NextButton = (props) => {
    const styles = useStyles();
    return <span {...props} className={`${styles.controller} ${styles.controller_right}`}>
                    <img src={Flecha} alt="Siguiente"/>
                </span>
}

class GifCarro extends React.Component {
    state = {
        slideIndex: 0,
        updateCount: 0,
        onHover: false
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        this.slider.slickGoTo(this.props.customindex);
    }

    render() {
        const {match, location, history} = this.props;
        const settings = {
            dots: false,
            infinite: true,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 8100,
            autoplay: true,
            afterChange: () =>
                this.setState(state => ({updateCount: state.updateCount + 1})),
            beforeChange: (current, next) => {
                this.setState({slideIndex: next});
                if (this.props.setIndex) {
                    this.props.setIndex(next);
                }
            }
        };
        const handlePlanXel = () => {
            history.push('/comprar/plan/69');
        }
        const handlePlanXeli = () => {
            history.push('/comprar/plan/69');
        }
        const data = [
            {
                item: Gif1,
                action: null
            },
            {
                item: Gif2,
                action: handlePlanXel
            },
            {
                item: Gif3,
                action: null
            },
            {
                item: Gif4,
                action: handlePlanXeli
            }
        ];
        console.log(this.state.slideIndex === 0 ? 8097 : this.state.slideIndex === 1 ? 7120 : this.state.slideIndex === 2 ? 5120 : 7120, 'Indice');
        return (
            <div onMouseLeave={() => {
                this.setState({
                    ...this.state,
                    onHover: false
                })
            }} onMouseEnter={() => {
                this.setState({
                    ...this.state,
                    onHover: true
                })
            }} style={{position: 'relative', overflow: 'hidden', width: '100%',}}>
                {
                    //Controles
                    this.state.onHover ? <div style={{
                        position: "absolute",
                        height: '100%',
                        width: '100%',
                        display: "flex",
                        alignItems: "center",

                    }}>
                        <BackButton onClick={() => {
                            this.slider.slickGoTo(this.state.slideIndex + -1);
                        }}/>
                        <NextButton onClick={() => {
                            this.slider.slickGoTo(this.state.slideIndex + 1);
                        }}/>
                    </div> : ''
                }
                <Slider ref={slider => (this.slider = slider)} {...settings} >
                    {
                        data.map(item => <Item item={item.item} action={item.action}/>)
                    }
                </Slider>

            </div>
        );
    }
}

export default withRouter(GifCarro);