import React, { useEffect, useState } from 'react'
import {
    Grid,
    Card,
    Typography,
    Button,
    Table,
    TableHead,
    styled,
} from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import { XEL_COLORS } from '../../../../constant'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Axios from '../../../../instances/axios'
import Pagar from './Pagarv2'
import Container from '@material-ui/core/Container'
import { useSelector } from 'react-redux'
import Cards from './Pagarv2/Cards'
import Frecuents from './Pagarv2/Frecuents'
import PropTypes from 'prop-types'

const { secondary, secondary_dark } = XEL_COLORS

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

const OptionCard = ({ title, children }) => {
    return (
        <Card variant={'elevation'} elevation={2} style={{ height: '100%' }}>
            <Typography
                align={'center'}
                style={{
                    backgroundColor: XEL_COLORS.primary,
                    color: '#FEFEFE',
                    padding: '2%',
                }}>
                {title}
            </Typography>
            {children}
        </Card>
    )
}

const Pestana = ({ selected, onClick }) => {
    return <Typography></Typography>
}

Pestana.propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func,
}

const Metodos = () => {
    let axios = Axios()
    const [pays, setPays] = useState({
        all: [],
    })
    const session = useSelector(state => state.login)
    const [option, setOption] = useState(session.logged ? 0 : 1)
    const [activeTab, setActiveTab] = useState(1)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        loadPays()
    }, [])

    const loadPays = () => {
        if (session.logged) {
            setLoading(true)
            axios
                .get('/clients/pays')
                .then(res => {
                    setLoading(false)
                    let data = res.data;
                    if (data.status !== 200) {
                        return;
                    }
                    setPays(data.data)
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                })
        }
    }
    const handleBackToPagos = () => {
        setOption(0)
    }
    const cancelId = id => {
        setLoading(true)
        axios
            .delete('/clients/pay/' + id)
            .then(res => {
                setLoading(false)
                let { data } = res
                if (data.status !== 200) {
                    return window.alert(data.message)
                }
                loadPays()
            })
            .catch(err => {
                setLoading(false)
            })
    }
    return (
        <Container>
            <Grid
                container
                spacing={1}
                justify={'center'}
                style={{
                    margin: '2%',
                    //    paddingBottom: "2%"
                }}>
                {session.logged && option === 0 && (
                    <Grid item xs={12}>
                        <Typography variant={'h4'} color={'primary'}>
                            Sección de Pago
                        </Typography>
                        <Typography>
                            Desde aquí podrás pagar recargas y renovaciones y
                            consultar el historial de pagos
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {option === 1 && <Pagar handleBack={handleBackToPagos} />}
                    {option === 3 && <Cards handleBack={handleBackToPagos} />}
                    {option === 4 && (
                        <Frecuents handleBack={handleBackToPagos} />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {option === 0 && (
                            <Grid item xs={12}>
                                <Card variant="outlined">
                                    <Grid
                                        container
                                        justify={'center'}
                                        style={{
                                            padding: '4%',
                                            height: '100%',
                                        }}
                                        spacing={2}>
                                        <Grid item xs={10} md={4}>
                                            <OptionCard title={'Pagar'}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            padding: '2%',
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                        }}>
                                                        <Button
                                                            variant={
                                                                'contained'
                                                            }
                                                            onClick={() =>
                                                                setOption(1)
                                                            }
                                                            color={'secondary'}
                                                            style={{
                                                                color: '#FEFEFE',
                                                                background: `linear-gradient(${secondary}, ${secondary_dark})`,
                                                            }}>
                                                            Ir a pagar
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </OptionCard>
                                        </Grid>
                                        <Grid item xs={10} md={4}>
                                            <OptionCard
                                                title={'Mi cuenta inXel'}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            padding: '2%',
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                        }}>
                                                        <Button
                                                            variant={
                                                                'contained'
                                                            }
                                                            onClick={() =>
                                                                setOption(3)
                                                            }
                                                            color={'secondary'}
                                                            style={{
                                                                color: '#FEFEFE',
                                                                background: `linear-gradient(${secondary}, ${secondary_dark})`,
                                                                marginBottom:
                                                                    '2%',
                                                            }}>
                                                            Tarjetas
                                                        </Button>
                                                        <Button
                                                            variant={
                                                                'contained'
                                                            }
                                                            onClick={() =>
                                                                setOption(4)
                                                            }
                                                            color={'secondary'}
                                                            style={{
                                                                color: '#FEFEFE',
                                                                marginBottom:
                                                                    '2%',
                                                                background: `linear-gradient(${secondary}, ${secondary_dark})`,
                                                            }}>
                                                            Números Frecuentes
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </OptionCard>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )}

                        {option === 0 && (
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} md={3}>
                                                <Typography
                                                    onClick={() =>
                                                        setActiveTab(1)
                                                    }
                                                    align={'center'}
                                                    className={`pay-history-button ${
                                                        activeTab === 1
                                                            ? 'active'
                                                            : ''
                                                    }`}>
                                                    Pagos recientes
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <Typography
                                                    onClick={() =>
                                                        setActiveTab(2)
                                                    }
                                                    align={'center'}
                                                    className={`pay-history-button ${
                                                        activeTab === 2
                                                            ? 'active'
                                                            : ''
                                                    }`}>
                                                    Pagos pendientes{' '}
                                                    {pays.all.filter(
                                                        p =>
                                                            p.status ===
                                                            'Pendiente',
                                                    ).length > 0 && (
                                                        <strong
                                                            className={
                                                                'pay-history-button-counter'
                                                            }>
                                                            {
                                                                pays.all.filter(
                                                                    p =>
                                                                        p.status ===
                                                                        'Pendiente',
                                                                ).length
                                                            }
                                                        </strong>
                                                    )}
                                                </Typography>
                                            </Grid>
                                            {
                                                //<Grid item xs={3}>
                                                //                                             <Typography align={"center"} style={{
                                                //                                                 padding: "1%",
                                                //                                                 borderTop: "1px solid #000000",
                                                //                                                 borderLeft: "1px solid #000000",
                                                //                                                 borderRight: "1px solid #000000",
                                                //                                                 borderTopRightRadius: 10,
                                                //                                                 borderTopLeftRadius: 10
                                                //                                             }}>
                                                //                                                 Facturas
                                                //                                             </Typography>
                                                //                                         </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper}>
                                            <Table
                                                sx={{ minWidth: 700 }}
                                                aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="right">
                                                            <strong>
                                                                Orden/Referencia
                                                            </strong>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <strong>
                                                                Detalles
                                                            </strong>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <strong>
                                                                Monto
                                                            </strong>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <strong>
                                                                Método
                                                            </strong>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <strong>
                                                                Estatus
                                                            </strong>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <strong>
                                                                {activeTab ===
                                                                    1 &&
                                                                    'Fecha'}
                                                                {activeTab ===
                                                                    2 &&
                                                                    'Fecha de vencimiento'}
                                                            </strong>
                                                        </TableCell>
                                                        {activeTab === 2 && (
                                                            <TableCell align="right">
                                                                <strong>
                                                                    Acción
                                                                </strong>
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {pays.all.map((row, i) => {
                                                        if (activeTab === 1) {
                                                            if (
                                                                row.status !==
                                                                'Completado'
                                                            )
                                                                return null
                                                        } else if (
                                                            activeTab === 2
                                                        )
                                                            if (
                                                                row.status !==
                                                                'Pendiente'
                                                            )
                                                                return null
                                                        return (
                                                            <StyledTableRow
                                                                key={row.id}>
                                                                <TableCell align="right">
                                                                    <a
                                                                        href={`/recipient?id=${row.id}`}
                                                                        target={
                                                                            '_blank'
                                                                        }>
                                                                        {
                                                                            row.order_id_reference
                                                                        }
                                                                    </a>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {row.pay_params.map(
                                                                        (
                                                                            p,
                                                                            i,
                                                                        ) => (
                                                                            <Grid
                                                                                container
                                                                                key={
                                                                                    i
                                                                                }>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }>
                                                                                    <Typography>
                                                                                        Número:{' '}
                                                                                        {
                                                                                            p.msisdn
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }>
                                                                                    {p.pays.map(
                                                                                        py => (
                                                                                            <Typography
                                                                                                key={`pay${i}`}
                                                                                                variant={
                                                                                                    'body2'
                                                                                                }>
                                                                                                {
                                                                                                    py
                                                                                                }
                                                                                            </Typography>
                                                                                        ),
                                                                                    )}
                                                                                </Grid>
                                                                            </Grid>
                                                                        ),
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    $
                                                                    {row.amount.toFixed(
                                                                        2,
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {row.method}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {row.status}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {activeTab ===
                                                                        1 &&
                                                                        row.operation_date}
                                                                    {activeTab ===
                                                                        2 &&
                                                                        row.due_date}
                                                                </TableCell>
                                                                {activeTab ===
                                                                    2 && (
                                                                    <TableCell align="center">
                                                                        {row.url && (
                                                                            <Button
                                                                                variant={
                                                                                    'contained'
                                                                                }
                                                                                color={
                                                                                    'primary'
                                                                                }
                                                                                style={{
                                                                                    margin: 3,
                                                                                }}
                                                                                onClick={() =>
                                                                                    window.open(
                                                                                        row.url,
                                                                                        '_blank',
                                                                                    )
                                                                                }>
                                                                                PDF
                                                                            </Button>
                                                                        )}
                                                                        <Button
                                                                            disabled={
                                                                                loading
                                                                            }
                                                                            onClick={() =>
                                                                                cancelId(
                                                                                    row.id,
                                                                                )
                                                                            }
                                                                            variant={
                                                                                'contained'
                                                                            }
                                                                            color={
                                                                                'secondary'
                                                                            }
                                                                            style={{
                                                                                color: '#FEFEFE',
                                                                                margin: 3,
                                                                            }}>
                                                                            Cancelar
                                                                        </Button>
                                                                    </TableCell>
                                                                )}
                                                            </StyledTableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Metodos
