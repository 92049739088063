import {PromosPlan} from "../../../../constant";
import {useSelector} from "react-redux";

export default function PacksService() {
    const promo = PromosPlan();
    let data = [


        /*   {
               id: 'pk-560',
               name: 'B2M + Plan Xel 5 Siempre juntos',
               id_dev: '106',
               product: '112',
               months: 12,
               months_disc:  0,
               cuantity_plan: 2,
               cuantity_dev: 2,
               resp_dev_price: 1000
           },{
               id: 'pk-567',
               name: 'K26 + Plan Xel 5',
               id_dev: '105',
               product: '112',
               months: 12,
               months_disc: promo.validBuenFin() ? 2 : 0,
           //    resp_dev_price: 732,
           },*/

        /* {
             id: 'pk-5345',
             name: 'B2M + Plan Xel 5',
             id_dev: '106',
             product: '112',
             months: 12,
             months_disc: promo.validBuenFin() ? 2 : 0,
          //   resp_dev_price: 1294
         },*/
        {
            id: 'pk-555',
            name: 'Hisense E20 + Plan Xel 40',
            id_dev: '107',
            product: '113',
            months: 12,
            months_disc: promo.validBuenFin() ? 2 : 0,
            // resp_dev_price: 1973
        },
        {
            id: 'pk-675',
            name: 'Moto E6 Play + Plan Xel 40',
            id_dev: '108',
            product: '113',
            months: 12,
            months_disc: promo.validBuenFin() ? 2 : 0,
            //    resp_dev_price: 2758
        },
        {
            id: 'pk-980',
            name: 'Moto E6s + Plan Xel 40',
            id_dev: '111',
            product: '113',
            months: 12,
            months_disc: promo.validBuenFin() ? 2 : 0,
            //   resp_dev_price: 2758
        },


        {
            id: 'pk-5550',
            name: 'Hisense E20 + Plan Xel 40 +',
            id_dev: '107',
            product: '82',
            months: 12,
            months_disc: promo.validBuenFin() ? 2 : 0,
            //   resp_dev_price: 1973
        },
        {
            id: 'pk-6750',
            name: 'Moto E6 Play + Plan Xel 40 +',
            id_dev: '108',
            product: '82',
            months: 12,
            months_disc: promo.validBuenFin() ? 2 : 0,
            //  resp_dev_price: 3131
        },
        {
            id: 'pk-9800',
            name: 'Moto E6s + Plan Xel 40 +',
            id_dev: '111',
            product: '82',
            months: 12,
            months_disc: promo.validBuenFin() ? 2 : 0,
            //   resp_dev_price: 3827
        },
    ]

    data = useSelector(state => state.xelpacks);

    const getPack = id => new Promise((resolve, reject) => {
        for (let i = 0; i < data.length; i++) {
            if (id === data[i].id) {
                resolve(data[i]);
                break;
            }
        }
        reject('Xel Pack no existe')
    });

    const getTwo = new Promise((resolve, reject) => {
        let packs = [];
        if (data.length > 0) {
            if (data.length > 1)
                for (let i = 0; i < 2; i++)
                    packs.push(data[i]);
            else
                packs.push(data[0]);
        }
        resolve(packs);
        //reject('No hay Xel Packs')
    });

    const getAll = new Promise((resolve, reject) => {
        resolve(data);
        reject('No hay Xel Packs')
    });

    return {getPack, getTwo, getAll};
}
