import React, {useEffect, useState} from "react";
import Method from "../MethodContainer";
import {Button, Grid, Typography, Zoom} from "@material-ui/core";
import {XEL_COLORS, XEL_FONT} from "../../../../../../../constant";
import {ZoomIn} from "@material-ui/icons";


const Tienda = ({payParams, storeParams, setStoreParams, total, rollBack}) => {

    const [dueDate, setDueDate] = useState('');
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    useEffect(() => {
        if (storeParams.created) {
            let date = new Date(storeParams.expire);
            let dia = date.getDate();
            let mes = meses[date.getMonth()];
            let anio = date.getFullYear();
            setDueDate(`${dia} de ${mes} de ${anio}`);
        }
        console.log("Params tienda", storeParams);
    }, [storeParams])

    return <Method title={"Pago por tienda de conveniencia"} rollBack={rollBack}>
        <Grid container>
            {
                storeParams.created ? <>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{backgroundColor: XEL_COLORS.primary, color: "#FEFEFE"}}>
                                <Typography align={"center"} variant={"h6"}>
                                    Total a pagar:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{backgroundColor: "#F9F5FB"}}>
                                <Typography variant={"h4"} align={"center"}>
                                    ${total}.00
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography color={"primary"} style={{fontWeight: XEL_FONT.bold_weight}}>
                                            Realiza tu pago en efectivo de forma rápida y segura:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Acude a la tienda afiliada más cercana:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        alignItems: "center"
                                    }}>
                                        <img alt={"Tiendas"}
                                             src={"https://s3.amazonaws.com/images.openpay/Horizontal_1.gif"}/>
                                        <Typography style={{color: "blue", cursor: "pointer"}}
                                                    onClick={() => {
                                                        window.open("https://www.openpay.mx/tiendas", "_blank");
                                                    }}>
                                            Ver más tiendas
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} style={{
                                marginTop: "1%",
                                marginBottom: "1%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <img style={{height: 60}} alt={"Codigo de barras"}
                                     src={`https://api.openpay.mx/barcode/${storeParams.reference}?width=1&height=45`}/>
                                <Typography variant={"body1"} style={{fontWeight: XEL_FONT.bold_weight}}>
                                    {storeParams.reference}
                                </Typography>
                                <Typography variant={"caption"} align={"center"}>
                                    En caso de que el escáner no sea capaz de leer el código de
                                    barras, escribir la referencia tal como se muestra.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{
                                marginTop: "3%",
                                marginBottom: "3%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <Typography variant={"body1"} style={{fontWeight: XEL_FONT.bold_weight}}>
                                    Fecha limite: {dueDate}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container style={{backgroundColor: "#F9F5FB", padding: "2%"}}>

                                    <Grid item xs={12} md={6}>
                                        <Typography variant={"body2"}
                                                    style={{fontWeight: XEL_FONT.bold_weight, marginBottom: "3%"}}>
                                            Como realizar el pago
                                        </Typography>
                                        {
                                            [
                                                "1. Acude a cualquier tienda afiliada",
                                                "2. Entrega al cajero el código de barras y menciona que realizarás un pago de servicio Paynet",
                                                `3. Realizar el pago en efectivo por $ ${total}.00 MXN`,
                                                "4. Conserva el ticket para cualquier aclaración"
                                            ].map((p, i) =>
                                                <Typography key={`f${i}`} variant={"body2"}
                                                            style={{marginBottom: "2%"}}>
                                                    {p}
                                                </Typography>)
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant={"body2"}
                                                    style={{fontWeight: XEL_FONT.bold_weight, marginBottom: "3%"}}>
                                            Instrucciones para el cajero
                                        </Typography>
                                        {
                                            [
                                                "1. Ingresar al menú de Pago de Servicios",
                                                "2. Seleccionar Paynet",
                                                "3. Escanear el código de barras o ingresar el núm. de referencia",
                                                "4. Ingresa la cantidad total a pagar",
                                                "5. Cobrar al cliente el monto total más la comisión",
                                                "6. Confirmar la transacción y entregar el ticket al cliente"
                                            ].map((p, i) =>
                                                <Typography key={`t${i}`} variant={"body2"}
                                                            style={{marginBottom: "2%"}}>
                                                    {p}
                                                </Typography>)
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                <Button style={{marginRight: 10}} onClick={() => {
                                    window.open(storeParams.url, '_blank');
                                }}
                                        variant={"contained"}
                                        color={"primary"}
                                >
                                    <Typography variant={"caption"}> Descargar PDF</Typography>
                                    {
                                        //<XelIconFacebook height={30} width={30}/>
                                    }
                                </Button>
                                <Button onClick={() => {
                                    window.location.reload();
                                }}
                                        variant={"contained"}
                                        color={"secondary"}
                                        style={{color: "#FEFEFE"}}
                                >
                                    <Typography variant={"caption"}> Volver al inicio</Typography>

                                </Button>

                            </Grid>
                        </Grid>
                    </Grid>
                </> : <Grid container>
                    <Grid item xs={12}>
                        <Typography>
                            Para continuar, se deben generar los datos de pago. Por favor presiona el botón Generar
                            información de pago
                        </Typography>
                    </Grid>
                </Grid>
            }
        </Grid>
    </Method>
}

export default Tienda;
