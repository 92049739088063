import React, {useMemo, useState} from "react";
import {Button, Card, CardActions, CardContent, CardHeader, Grid, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {XEL_COLORS, XEL_FONT} from "../../../constant";
import {styled} from "@material-ui/styles";
import {useHistory} from "react-router-dom";

const usePlanCardStyles = makeStyles(props => {
    const {shadows, transitions} = props;
    const button = {
        backgroundColor: "#FEFEFE",
        color: XEL_COLORS.primary,
        "&:hover": {
            backgroundColor: XEL_COLORS.primary,
            color: "#FEFEFE",
        },
        fontSize: '1rem',
        fontWeight: XEL_FONT.bold_weight,
        borderRadius: 25,
        lineHeight: '1rem'
    }
    return {
        cardRoot: {
            padding: 0
        },
        cardHeader: {
            paddingRight: 0
        },
        cardContent: {
            lineHeight: 0,
            padding: 0
        },
        cardContainer: {
            //   cursor: "pointer",
            // minHeight: 550,
            //  maxHeight: 600,
            //minWidth: 300,
            width: 216,
            backgroundImage: 'linear-gradient(#5E1E93 30%, #FF7900  90%)',
	    boxShadow: shadows[2],
            userSelect: "none",
            display: "flex",
            alignItems: "space-between",
            justifyContent: "space-between",
            flexDirection: "column",
            borderRadius:15
        },
        cardContainerEffect: {
            "&:hover": {
                transition: transitions.easeIn,
                backgroundImage: 'linear-gradient(#F9BE89  , #FF7900)',
            },

        },
        gbText: {
            //    fontSize: '10rem',
            fontWeight: XEL_FONT.semi_bold_weight,
            letterSpacing: -3,
            lineHeight: 1,

        },
        gbText2: {
            display: "flex",
            justifyContent: "center",
            // alignItems:"center",
            flexDirection: "column",
            "*": {
                fontWeight: XEL_FONT.bold_weight,
            },
            paddingLeft: '1rem',
            lineHeight: 1,
        },
        smsMinText: {

            backgroundColor: XEL_COLORS.primary,
            color: "#FEFEFE",
            "*": {
                fontWeight: XEL_FONT.bold_weight,
            }
        },
        button: {
            ...button

        },
        pre_container: {
            backgroundColor: "#FEFEFE",
            "&:hover": {
                backgroundcolor: XEL_COLORS.secondary
            }

        },
        prebutton: {
            ...button,
            borderRadius:10,
            width: "100%",
            color: XEL_COLORS.primary,
            "&:hover": {
                transition: transitions.easeIn,
                backgroundImage: 'radial-gradient(#F9BE89  , #FF7900)',
            }
        }


    }
});


const PlanCard = ({plan, titleDiscount, margin, types}) => {

    const {type, name, price, original_price, gb, maxspeed, folio, detpre, min, sms, hotspot, services_desc} = plan;
    const styles = usePlanCardStyles({item: {ds: ""}});

    const history = useHistory();

    const [onHover, setOnHover] = useState(false);

    const [onPreBtnHover, setOnPreBtnHover] = useState(0);
    const Title = ({title}) => {
        return <Grid container justify={'flex-end'}>
            <Grid item xs={10} style={{backgroundColor: "#FEFEFE"}}>
                <Typography autoCapitalize={'on'} color={'primary'} align={'center'} variant={'h5'}>
                    {title}
                </Typography>
            </Grid>
        </Grid>
    }

    const textColor = useMemo(() => {
        return onHover ? XEL_COLORS.primary : "#FEFEFE";
    }, [onHover]);

    const onMouseEnter = () => {
        if (type !== 'preplan')
            setOnHover(true);

    }
    const goTobuy = () => {
        // eslint-disable-next-line no-undef
        gtag("click_lo_quiero", {
            "tipo": "Plan",
            "tipo_plan": type,
            "plan": plan.name,
        });
        history.push(`/comprar/${types ? types : type}/` + plan.id);
        //if (!session.logged)
        //             window.location.reload();
    }

    return <Card
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setOnHover(false)}
        className={[styles.cardContainer, type !== 'preplan' ? styles.cardContainerEffect : ""].join(' ')}
    style={{
	margin: margin ? margin * 10 : 'auto',
	backgroundImage: plan.id === '280143' && !onHover ? 'linear-gradient(#FF409F 30%, #FF7900  90%)' :
	plan.id === '280143' && onHover ? 'linear-gradient(#ff70b8 30%, #FF7900  90%)' :
	plan.id !== '280143' && !onHover ? 'linear-gradient(#5E1E93  30%, #FF7900  90%)' :
	'linear-gradient(#F9BE89 30%, #FF7900  90%)'

    }}
	>

        <CardHeader className={styles.cardHeader} title={<Title title={name}/>}/>
        <CardContent className={styles.cardContent}>
            <Grid container justify={'center'}>
                <Grid item xs={12}>
                    <Typography align={'center'}
                                style={{
                                    fontWeight: XEL_FONT.bold_weight,
                                    color: onHover ? XEL_COLORS.primary : "#FEFEFE"
                                }}>
		    {
			plan.id === '280143' ? 
			    "2 chips que incluyen servicio por 12 meses" : 
			    "Tu chip inXel incluye"
		    }
                        
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify={'center'}>
                        <Grid item xs={12}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Typography className={styles.gbText}
                                            style={{
                                                color: textColor,
                                                fontSize: '5rem'
                                            }}>
                                    {gb}
                                </Typography>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    paddingBottom: 15
                                }}>
                                    <Typography variant={"h2"} style={{color: textColor}}>
                                        GB
                                    </Typography>
                                    {
                                        type === 'preplan' && <Typography variant={'h5'} style={{color: textColor}}>
                                            Al mes
                                        </Typography>
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align={'center'} variant={'h6'}
                                        style={{color: textColor, fontWeight: XEL_FONT.bold_weight, fontSize: ".9rem"}}>
                                {
                                    hotspot ? "Navega y comparte" : "Navega y Redes Sociales"
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {
                    type === "mifi" || type === 'hbb' ? <Grid item xs={12} style={{marginTop: 10}}>
                        <Grid container>
                            <Grid item xs={12}
                                  className={styles.smsMinText}>
                                <Typography style={{fontWeight: XEL_FONT.bold_weight,fontSize: ".9rem"}} align={'center'}>Conecta hasta 15
                                    dispositivos</Typography>
                            </Grid>
                        </Grid>
                    </Grid> : <Grid item xs={12} style={{marginTop: 10}}>
                        <Grid container>
                            <Grid item xs={6}
                                  className={styles.smsMinText}
                                  style={{borderRight: "1px solid #FEFEFE"}}>
                                <Typography style={{fontWeight: XEL_FONT.bold_weight, fontSize: ".8rem"}}
                                            align={'center'}>{min} Minutos</Typography>
                            </Grid>
                            <Grid item xs={6}
                                  className={styles.smsMinText}
                                  style={{borderLeft: "1px solid #FEFEFE"}}>
                                <Typography style={{fontWeight: XEL_FONT.bold_weight, fontSize: ".8rem"}}
                                            align={'center'}>{sms} Mensajes</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    type === 'hbb' &&
                    <Grid item xs={12} style={{marginTop: 5}}>
                        <Grid container>
                            <Grid item xs={12}
                                  className={styles.smsMinText}
                                  style={{backgroundColor: onHover ? XEL_COLORS.primary : XEL_COLORS.secondary}}
                            >
                                <Typography style={{fontWeight: XEL_FONT,fontSize:".8rem"}}
                                            align={'center'}>Velocidad {maxspeed} Mbps</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                     {
                    services_desc && services_desc.length > 0 && services_desc.map((service, index) => {
                        return <Grid item xs={12} key={`extras-` + index} style={{marginTop: 10, padding: 4}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography align={'center'} style={{
                                        fontWeight: XEL_FONT.bold_weight,
                                        color: onHover ? XEL_COLORS.primary : "#FEFEFE",
                                        fontSize:".8rem"
                                    }}>
                                        {service.value.toString().split(":")[0]}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    })

                }
                {
                    titleDiscount && <Grid item xs={12} style={{marginTop: 10}}>
                        <Typography
                            style={{fontWeight: XEL_FONT.bold_weight, color: onHover ? XEL_COLORS.primary : "#FEFEFE",fontSize:'.8rem'}}
                            align={'center'}>{titleDiscount}</Typography>
                    </Grid>
                }
                {
                    type !== 'preplan' && <Grid item xs={12}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginTop: 15
                                                }}>
		    {
			plan.id === '280143' ? (
			    <>
				<Typography variant={'h6'} style={{color: "#FEFEFE", whiteSpace: 'nowrap'}}>
				    Paga solo
				</Typography>
				<Typography variant={'h3'} style={{color: textColor, fontWeight: XEL_FONT.bold_weight}}>
				    ${price}
				</Typography>
			    </>
			) : (
			    <>
				<Typography variant={'h3'} style={{color: textColor, fontWeight: XEL_FONT.bold_weight}}>
				    ${price}
				</Typography>
				<Typography variant={'h6'} style={{color: "#FEFEFE"}}>
				    Al mes
				</Typography>
			    </>
			)
		    }
                    </Grid>
                }
                {
                    (original_price && (+original_price !== +price) && type !== 'preplan') &&
                    <Grid item xs={9} style={{marginTop: 5}}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography style={{color: "#FEFEFE"}}>Precio público:</Typography>
                            </Grid>
                            <Grid item xs={12}
                                  style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Typography variant={'h5'} style={{
                                    color: "#FEFEFE",
                                    fontWeight: XEL_FONT.bold_weight,
                                    textDecoration: "line-through",
                                    textDecorationColor: XEL_COLORS.primary
                                }}>
                                    ${original_price}
                                </Typography>
                                <Typography style={{
                                    color: "#FEFEFE",
                                    marginLeft: 10,
                                    fontSize: ".5rem",
                                    fontWeight: XEL_FONT.bold_weight
                                }}>
                                    Al mes
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </CardContent>
        <CardActions>
            <Grid container>
                <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                    {
                        type === 'preplan' ? <Grid container spacing={1}>
                            {
                                detpre.map((d, i) => <Grid item xs={6} key={'pre-btn' + i}>
                                    <Button className={styles.prebutton}
                                            onMouseEnter={() => setOnPreBtnHover(i + 1)}
                                            onMouseLeave={() => setOnPreBtnHover(0)}
                                            onClick={() => {
                                                history.push(`/comprar/${type}/` + d.id);
                                            }}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant={'h5'} align={'center'}
                                                            style={{
                                                                fontSize:"1rem",
                                                                fontWeight: XEL_FONT.bold_weight,
                                                                color: onPreBtnHover === (i + 1) ? "#FEFEFE" : XEL_COLORS.primary
                                                            }}>
                                                    {d.months} Meses
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{marginTop: 7}}>
                                                <Typography align={'center'} variant={'h6'}
                                                            style={{
                                                                fontSize:".9rem",
                                                                textDecoration: "line-through",
                                                                color: onPreBtnHover === (i + 1) ? 'gray' : XEL_COLORS.secondary
                                                            }}>
                                                    ${d.noPrice}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{marginTop: 4}}>
                                                <Typography variant={'h5'} align={'center'}
                                                            style={{fontSize: "1rem"}}>
                                                    Paga solo
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography variant={'h3'} align={'center'}
                                                            style={{
                                                                fontSize: '1.4rem',
                                                                color: onPreBtnHover === (i + 1) ? XEL_COLORS.primary : XEL_COLORS.secondary
                                                            }}>
                                                    ${d.price}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Button>
                                </Grid>)
                            }
                        </Grid> : <Button className={styles.button} onClick={goTobuy}>
                            Comprar
                        </Button>
                    }
                </Grid>
                <Grid item xs={12} style={{display: "flex", justifyContent: "center", marginTop: 10, color: "#FEFEFE"}}>
                    <Typography variant={'caption'}>
                        Folio: {folio}
                    </Typography>
                </Grid>
            </Grid>
        </CardActions>
    </Card>
}


const usePreBtnStyles = makeStyles(() => {

    return {}
});


export default PlanCard;
