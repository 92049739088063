import NavBar from "../../../ui/NavBar";
import {Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Footer from "../../../ui/Footer";
import React, {useEffect} from "react";
import {useLegalStyle} from "../Privacidad";


const Title = ({children}) => {
    useEffect(() => {
        //  console.log(props);
    });
    const style = useLegalStyle();
    return <Grid item xs={12}>
        <Typography className={style.title} align={'center'}>
            {children}
        </Typography>
    </Grid>
}

const Section = ({title, body}) => {
    const style = useLegalStyle();
    return <Grid item xs={12}>
        <Typography className={style.subtitle}>
            {title}
        </Typography>
        <Typography className={style.body} variant="body2">
            {body}
        </Typography>
    </Grid>
}

export const NSection = ({title, children}) => {
    const style = useLegalStyle();
    return <Grid item xs={12}>
        <Typography className={style.subtitle}>
            {title}
        </Typography>
        <Typography className={style.body} variant="body2">
            {children}
        </Typography>
    </Grid>
}
export const Salto = () => {
    return <><br/><br/></>
}
export default function Derechos() {
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Derechos del usuario";
    }, [])
    return <>
        <NavBar/>
        <Container>
            <Grid container justify={'center'}>

                <Title>
                    CARTA DE DERECHOS DE LOS USUARIOS INXEL
                </Title>
                <Grid item xs={12}>
                    <Section
                        title={'COMO USUARIO DE LOS SERVICIOS DE TELECOMUNICACIONES1  ESTOS SON TUS PRINCIPALESDERECHOS:'}/>
                    <Section title={'I. LIBERTAD DE ELEGIR. '}/>
                </Grid>
                <Section title={'1. Libre elección.'}
                         body={'TÚ TIENES DERECHO a elegir libremente el servicio o servicios que quieras contratar, el proveedor que te loproporcionará, el equipo o dispositivo que utilizarás (ejemplo celular, televisión, tableta, etc.), el plan y mediode pago que tendrás.2La información, promoción y/o publicidad deben ser oportunas, completas, claras, comprobables, veraces, yexentas de textos, diálogos, sonidos, imágenes, marcas, denominaciones de origen y otras descripciones quete induzcan o puedan inducirte a error o confusión por engañosas o abusivas, de manera que siempre puedaselegir con pleno conocimiento. En todo caso, el proveedor está obligado a cumplir lo que haya ofrecido através de cualquier tipo de información, promoción o publicidad emitida.'}/>
                <Section title={'2. Servicios adicionales.'}
                         body={'TÚ TIENES DERECHO a elegir el o los servicios que desees contratar, sin que el proveedor te condicione o teobligue a contratar servicios o productos adicionales al principal o en paquete.4Tienes derecho a que el proveedor te informe los servicios o productos adicionales, especiales o conexos alservicio básico y a elegir libremente respecto de la contratación de los mismos. Asimismo, puedes dar porterminada la prestación de dichos servicios o productos adicionales, sin que ello implique la suspensión ocancelación de la prestación del servicio básico.'}/>
                <Section title={'II. DERECHO A LA PORTABILIDAD Y EL DESBLOQUEO. '}/>
                <Section title={'3. Portabilidad.'} body={'' +
                'TÚ TIENES DERECHO a cambiarte gratuitamente de compañía conservando tu mismo número telefónico,tantas veces como lo decidas, acudiendo con la nueva compañía con la que deseas contratar, quien deberárealizar la portabilidad durante las 24 horas siguientes a tu solicitud, o si así lo deseas, tienes derecho adecidir sobre la fecha en que quieres que se ejecute la portabilidad, siempre y cuando ésta no exceda de 5días hábiles a partir de que presentes tu solicitud.' +
                'TÚ TIENES DERECHO a partir de la fecha en que se ejecute la portabilidad y sin la exigencia de requisitosadicionales, a finiquitar de manera automática la relación contractual con el proveedor que antes te prestabael servicio, únicamente de aquellos servicios cuya prestación requiera de los números telefónicos a serportados, por lo que los servicios que no requieran de los números telefónicos portados podrán continuaractivos en los términos establecidos en los contratos.' +
                'TÚ   TIENES   DERECHO   a   que   el   proveedor   con   el   que   decidas   cambiarte,   teproporcione la información necesaria para acceder al Sistema de Información en internet que te permita saberel estado que guarda tu solicitud de portabilidad.' +
                'Una vez que has iniciado el proceso de portabilidad, si así lo decides, tienes derecho a cancelarlo de maneragratuita.' +
                'TÚ TIENES DERECHO a recibir el pago de las penas convencionales previstas en el contrato, en caso de queno se cumplan los plazos de portabilidad correspondientes' +
                'TÚ TIENES DERECHO a realizar la portabilidad de tu número telefónico pese a tener adeudos pendientescon el proveedor que te prestaba los servicios de telecomunicaciones, sin que ello te exente de pagar losadeudos pendientes y, en su caso, las penalizaciones por terminación anticipada, así como devolver losequipos que no sean de tu propiedad.' +
                ''}/>
                <Section title={'4. Desbloqueo de equipo o dispositivo.'} body={'' +
                'TÚ TIENES DERECHO, cuando adquieras un equipo, a que el proveedor te informe por escrito si ese equipose encuentra bloqueado para que sólo pueda ser utilizado en su red y en tal caso, debe informarte cómopodrá ser desbloqueado.' +
                'TÚ TIENES DERECHO al desbloqueo del equipo móvil cuando concluya el plazo del plan contratado, o bien,cuando hayas liquidado el precio total del equipo. Cuando lo hayas pagado de contado en un esquema depre-pago, el proveedor o su distribuidor te deberán proporcionar la clave de desbloqueo de tu equipo almomento de entregártelo.' +
                ''}/>
                <Section title={'III. DERECHO A CONDICIONES DE CONTRATACIÓN CLARAS, JUSTAS Y EQUITATIVAS.'}/>
                <Section title={'5. Información sobre términos y condiciones de contratación.'} body={'' +
                'TÚ TIENES DERECHO a conocer, antes de contratar, todos los términos y condiciones establecidos en elcontrato de adhesión, de manera clara, suficiente, veraz y precisa. Dicho contrato deberá estar disponible enla página de Internet del proveedor.\n' +
                'Al momento de contratar, el proveedor deberá entregarte por escrito el contrato de adhesión, el cual debeestar escrito en idioma español y con caracteres legibles a simple vista.\n' +
                'El contrato de adhesión deberá contener, cuando menos, la descripción del servicio que contratarás, lasobligaciones de las partes, los términos y condiciones bajo los cuales se prestarán los servicios, las penasconvencionales   y   los   supuestos   de   terminación   y   modificación.   Asimismo,   deberá   contener   lasespecificaciones técnicas y comerciales, incluyendo la calidad y, en su caso, velocidad del servicio detelecomunicaciones a contratar, entre otras.\n' +
                'Asimismo, el contrato deberá indicar los medios disponibles de atención a clientes y, en su caso, horarios deatención de los mismos.\n' +
                'Tienes derecho a exigir al proveedor que cumpla con las condiciones ofrecidas o implícitas en el contrato deadhesión y en la publicidad, así como en cualquier información y desplegados que difunda u ofrezca.\n' +
                ''}/>

                <NSection title={'6. Contrato registrado ante Profeco y el Instituto Federal de Telecomunicaciones.'}>
                    TÚ TIENES DERECHO a que el contrato de adhesión a través del cual contrates unservicio, esté
                    autorizado y registrado por la Profeco y ante el Instituto Federal de Telecomunicaciones.
                    <Salto/>
                    El contrato de adhesión que te entregue el proveedor deberá también estar disponible en su portal de
                    internety deberá coincidir con el autorizado y registrado en Profeco y el Instituto Federal de
                    Telecomunicaciones.
                    <Salto/>
                    Consulta el contrato autorizado y registrado en el portal de internet www.profeco.gob.mx y en
                    www.ift.org.mx
                    <Salto/>
                </NSection>
                <NSection title={'7. Modificaciones sólo con tu consentimiento.'}>
                    TÚ TIENES DERECHO a que el contrato celebrado con el proveedor, únicamente pueda ser modificado
                    contu consentimiento expreso.
                    <Salto/>
                    Tienes derecho a que cualquier modificación al contrato, esté previamente autorizada y registrada
                    anteProfeco y el Instituto Federal de Telecomunicaciones.
                    <Salto/>
                    Si no estás de acuerdo con las modificaciones o no diste tu consentimiento, puedes exigir el
                    cumplimientoforzoso o dar por terminado el contrato sin penalización alguna para ti, aunque el plazo
                    forzoso no hayavencido.
                    <Salto/>
                    En todo caso, si el servicio no se presta conforme al contrato por causas imputables al proveedor,
                    éste deberáhacerte la bonificación, sin perjuicio de la indemnización que en su caso corresponda por
                    daños y perjuicios.
                    <Salto/>
                </NSection>
                <NSection title={'8. Conceptos de pago desglosados.'}>
                    TÚ TIENES DERECHO a que en el contrato, facturas y comprobantes de pago, se desglose por separado
                    laparte que corresponda a las tarifas por los servicios contratados; la compra o renta de los
                    equipos, y otroscargos de instalación u otros accesorios, como seguros, fianzas, membresías,
                    etcétera, así como laperiodicidad de estos cargos.
                </NSection>
                <NSection title={'9. Terminación del Contrato. '}>
                    TÚ TIENES DERECHO a terminar el contrato y cambiar de paquete o plan de forma anticipada y, en su
                    caso,sólo pagar el remanente del equipo (ejemplo: celular, televisión, tablet, etc.) y, en su caso,
                    la penaconvencional pactada.
                    <Salto/>
                    Asimismo, si el plazo de tu contrato concluye y no quieres continuar con tu proveedor, puedes
                    cancelarlo y elproveedor ya no te deberá cobrar el periodo subsecuente, excepto cuando se renueve el
                    contrato porcontinuar usando y pagando los servicios de telecomunicaciones contratados
                    originalmente.
                    <Salto/>
                    La terminación del contrato, la podrás realizar a través de los mismos medios por los que
                    contrataste, asícomo a través de mecanismos ágiles disponibles puestos a disposición por el
                    proveedor, incluyendo medioselectrónicos.
                    <Salto/>
                    Una vez que solicites la terminación del contrato o la baja de determinados servicios, el proveedor
                    deberáinformarte a partir de cuándo se encuentra obligado a realizar la cancelación o baja de dichos
                    servicios. Sitranscurrido dicho plazo, el proveedor no ha realizado la cancelación o baja, tienes
                    derecho a que no se tecobren los cargos que se llegaren a generar por dicho concepto.
                    <Salto/>
                </NSection>
                <NSection title={'10. Penas convencionales proporcionales y recíprocas.'}>
                    TÚ TIENES DERECHO a que las penas convencionales por incumplimiento del contrato por alguna de
                    laspartes, sean recíprocas, razonables y equitativas.
                    <Salto/>
                    Profeco verificará que en los contratos de adhesión se establezcan penas convencionales recíprocas
                    yrazonables.
                    <Salto/>
                    En ningún caso, el proveedor podrá exigirte doble penalización por el mismo incumplimiento, ni
                    tampocopodrá exigirte penas superiores al monto que resta por pagar conforme al contrato.
                    <Salto/>
                </NSection>
                <NSection title={'11. Renovación sin pago del equipo. '}>
                    TÚ TIENES DERECHO a que en caso de que decidas renovar tu contrato de servicios móviles (tiempo
                    aire,mensajes cortos, datos, servicios de valor agregado) y no adquieras un nuevo equipo o
                    dispositivo, el pagoesté integrado exclusivamente por las tarifas registradas ante el Instituto
                    Federal de Telecomunicacionesaplicables a los servicios, sin monto alguno por el equipo.
                    <Salto/>
                </NSection>
                <NSection
                    title={'12. Suspensión del servicio por falta de pago. TÚ TIENES DERECHO a que previamente a la suspensión delservicio por falta de pago, el proveedor te avise de dicha suspensión.'}>
                    TÚ TIENES DERECHO a que el proveedor te suspenda sólo aquellos servicios cuyo pago no realizaste.
                    <Salto/>
                </NSection>
                <NSection title={'IV. CARGOS POR SERVICIO.'}/>
                <NSection title={'13. Larga Distancia. '}>
                    TÚ TIENES DERECHO a que el proveedor no te cobre cargos de larga distancia por llamadas que
                    realices,dentro del país, a cualquier destino nacional (a números fijos o móviles).
                    <Salto/>
                </NSection>
                <NSection title={'14. Cobro por segundo. '}>
                    TÚ TIENES DERECHO a que en los servicios medidos por duración de las comunicaciones, el proveedor
                    teofrezca la opción de cobro por segundo, sin perjuicio de que también te ofrezca la opción de cobro
                    por minuto,por evento, por capacidad o cualquier otra modalidad.
                    <Salto/>
                </NSection>
                <NSection title={'15. Consulta gratis tu saldo.'}>
                    TÚ TIENES DERECHO a consultar tu saldo sin costo y sin que te condicionen a comprar saldo adicional,
                    asícomo al abono de tu saldo no consumido a la fecha de expiración, acumulándolo a las recargas que
                    realicesdentro del año siguiente a la fecha de expiración del mismo.
                    <Salto/>
                </NSection>
                <NSection title={'16. Estados de cuenta, comprobantes de pago y/o facturas claras y desglosadas.'}>
                    TÚ TIENES DERECHO a que los estados de cuenta, comprobantes de pago y/o facturas que expida
                    tuproveedor de servicios, sean claros, legibles y contengan de manera desglosada los conceptos por
                    el costode los servicios, los equipos, servicios adicionales, cargos de instalación u otros
                    accesorios como seguros,fianzas, membresías y demás cargos realizados.
                    <Salto/>
                    En ningún caso, el proveedor podrá cobrarte servicios que no hayas aceptado expresamente.
                    <Salto/>
                    Si eres usuario de servicios de telecomunicaciones en modalidad de prepago, igualmente TÚ
                    TIENESDERECHO a solicitar y obtener comprobantes de pago e información desglosada sobre tu consumo y
                    cargos
                    cobrados. El proveedor deberá informarte de qué manera puedes obtener dichainformación.
                    <Salto/>
                </NSection>
                <NSection title={'V. DERECHO A LA CALIDAD EN EL SERVICIO Y EN EL EQUIPO TERMINAL. '}/>
                <NSection title={'17. Derecho a exigir calidad en el servicio.'}>
                    TÚ TIENES DERECHO a exigir la calidad que el proveedor se haya obligado a cumplir, a través de
                    supublicidad o contrato de adhesión. Recuerda que existen indicadores de calidad para el servicio
                    móvil, comointentos de llamadas fallidas, llamadas interrumpidas, tiempo de establecimiento de
                    llamadas, integridad demensajes, sesiones de internet interrumpidas, velocidad de datos promedio de
                    descarga, entre otros, loscuales son revisados y publicados periódicamente por el Instituto Federal
                    de Telecomunicaciones.
                    <Salto/>
                    TÚ TIENES DERECHO a que, en la prestación de los servicios, el proveedor cumpla con los estándares
                    decalidad determinados por el Instituto Federal de Telecomunicaciones.
                    <Salto/>
                </NSection>
                <NSection title={'18. Bonificación o descuento.'}>
                    TÚ TIENES DERECHO a que se te bonifique si el servicio no se presta conforme a la calidad y
                    condicionesconvenidas en el contrato, o por fallas o cargos indebidos por causas imputables al
                    proveedor, con al menosel 20% del monto del periodo de afectación y del precio del servicio. Lo
                    anterior, sin que sea necesario quepresentes una reclamación y sin perjuicio de ejercer las acciones
                    legales respectivas para reclamar laindemnización que en su caso corresponda por daños y perjuicios.
                    <Salto/>
                </NSection>
                <NSection title={'19. Garantía mínima y póliza de garantía.'}>
                    TÚ TIENES DERECHO a que el proveedor te ofrezca una garantía del equipo, de cuando menos sesenta
                    díasnaturales a partir de la entrega del mismo. Para tal efecto, el proveedor deberá entregarte con
                    tu equipo, unapóliza por escrito y en español que deberá contener de manera clara su alcance,
                    duración, condiciones,mecanismos para hacerla efectiva, domicilio para reclamaciones y
                    establecimiento o lugar parareparaciones.
                    <Salto/>
                    En caso de no otorgar garantía, el proveedor debe informártelo por escrito, así como las
                    consecuencias eimplicaciones por no otorgarla.
                    <Salto/>
                </NSection>
                <NSection title={'20. Reparaciones.'}>
                    TÚ TIENES DERECHO a que el tiempo que duren las reparaciones efectuadas a tu equipo por concepto
                    degarantía, no se descuente del plazo de garantía.
                    <Salto/>
                    Cuando el equipo haya sido reparado o repuesto, el proveedor deberá entregar una nueva garantía para
                    laspiezas repuestas.
                    <Salto/>
                    El proveedor debe asegurar que se te proporcione la infraestructura, capacidad técnica, mano de
                    obra, asícomo refacciones y accesorios necesarios para proporcionar los servicios establecidos en la
                    garantía delequipo terminal de telecomunicaciones, cuando ésta se ofrezca.
                    <Salto/>
                </NSection>
                <NSection title={'21. Restitución de equipos defectuosos, rescisión y devolución. '}>
                    Cuando el equipo presente defectos o no sirva para el uso que está destinado, TÚ TIENES DERECHO a
                    optarpor la restitución del equipo, rescindir el contrato y la devolución del precio pagado o pedir
                    la reducción del
                    precio. Además, en cualquiera de los casos anteriores, tienes derecho a labonificación respectiva la
                    cual no podrá ser menor del 20% del precio pagado.
                    <Salto/>
                </NSection>
                <NSection title={'VI. DERECHO A LA INFORMACIÓN. '}></NSection>
                <NSection title={'22. Información clara y oportuna.'}>
                    TÚ TIENES DERECHO a que el proveedor te informe antes de la contratación sobre los precios,
                    tarifas,garantías, penalidades, compensaciones, cantidades, calidad, medidas, intereses, cargos,
                    términos, plazos,fechas, modalidades, reservaciones y demás condiciones de la prestación del
                    servicio, conforme a las cualesse hubiera ofrecido, obligado o convenido y, bajo ninguna
                    circunstancia podrá negarte dicho servicio.
                    <Salto/>
                    TÚ TIENES DERECHO a conocer la cobertura del proveedor, la cual podrás consultar en los centros
                    deatención y/o distribución, en la página de internet del proveedor y deberá proporcionártela en
                    caso de que lasolicites.
                    <Salto/>
                </NSection>
                <NSection title={'23. Acceso a la información para la defensa de tus derechos.'}>
                    TÚ TIENES DERECHO a recibir una constancia de las reclamaciones, solicitudes y, en general, de todas
                    lasgestiones con incidencias en tu servicio que realices ante el proveedor. Si el medio de atención
                    del proveedorfue telefónico, el proveedor está obligado a informarte que tienes derecho a recibir un
                    documento que acreditela presentación y contenido de la reclamación, incidencia o gestión mediante
                    cualquier soporte que permita talacreditación.
                    <Salto/>
                    TÚ TIENES DERECHO a ser informado del estado de los trámites que has realizado ante el proveedor, en
                    elmomento en el que lo solicites.
                    <Salto/>
                </NSection>
                <NSection title={'24. Sistema de atención telefónica para quejas y consultas. '}>
                    TÚ TIENES DERECHO a acceder gratuitamente, las 24 horas del día, los trescientos sesenta y cinco
                    días delaño, a un sistema de atención telefónica o electrónico del proveedor, en el que podrás
                    realizar cualquier tipode consulta o presentar una reclamación sobre el servicio que se te
                    proporciona.
                    <Salto/>
                    En el caso de telefonía móvil, el sistema de atención deberá ser telefónico, para lo cual una vez
                    que hayasaccedido al mismo y elijas hablar con un representante autorizado, el tiempo de espera para
                    que te atiendanno deberá exceder de sesenta segundos.
                    <Salto/>
                </NSection>
                <NSection title={'25. Tarifas registradas ante el Instituto Federal de Telecomunicaciones. '}>
                    TÚ TIENES DERECHO a que te respeten la tarifa registrada ante el Instituto Federal de
                    Telecomunicaciones,que ofrecen los proveedores de cualquier servicio de telecomunicaciones.
                    <Salto/>
                    En caso de que las tarifas que te apliquen no se encuentren registradas ante el Instituto, o si lo
                    están, no se terespetan, puedes presentar tu queja o denuncia. VII. DERECHO A LA PRIVACIDAD Y A LA
                    PROTECCIÓNDE DATOS PERSONALES.
                    <Salto/>
                </NSection>
                <NSection title={'26. Protección de tus datos personales.'}>
                    TÚ TIENES DERECHO a que el proveedor resguarde y proteja tu información personal, como tu
                    nombre,domicilio, correo electrónico, número telefónico y otros.
                    <Salto/>
                    Asimismo, tiene la obligación de informarte qué datos recaba de ti y con qué fines los utilizará a
                    través de unaviso de privacidad. Tienes derecho a que los datos que proporcionaste sean usados
                    únicamente para los
                    fines que autorizaste y en todo momento, tienes derecho a la seguridad del resguardode los mismos.
                    <Salto/>
                    En cualquier caso, puedes Acceder, Rectificar, Cancelar tu información y Oponerte a su uso, lo cual
                    seconoce comúnmente como “Derechos ARCO”.
                    <Salto/>
                    En caso de pérdida o daño causado a tus datos personales, el proveedor debe notificarte.
                    <Salto/>
                </NSection>
                <NSection title={'27. Protección de tus comunicaciones y los datos que las identifiquen.'}>
                    TÚ TIENES DERECHO a que el proveedor resguarde y proteja tus comunicaciones, así como los datos
                    queidentifiquen las mismas, tales como fecha, hora y duración de las llamadas, mensajes o datos que
                    identifiquenel origen y destino de éstos, entre otros, garantizando su confidencialidad y
                    privacidad.
                    <Salto/>
                </NSection>
                <NSection title={'28. A no recibir llamadas o mensajes de promociones comerciales no autorizadas.'}>
                    TÚ TIENES DERECHO a no recibir llamadas de tu proveedor o de cualquier otro, para promover o
                    venderservicios o paquetes, a menos que expresamente manifiestes tu consentimiento.
                    <Salto/>
                </NSection>
                <NSection title={'VIII. DERECHOS DE ACCESIBILIDAD DE LOS USUARIOS CON DISCAPACIDAD.'}/>
                <NSection title={'29. Equipos o dispositivos accesibles para personas con discapacidad.'}>
                    TÚ TIENES DERECHO a contar, previa solicitud de tu parte, con equipos o dispositivos, que
                    tenganfuncionalidades, programas o aplicaciones, que permitan la accesibilidad a personas con
                    discapacidad motriz,visual y auditiva.
                    <Salto/>
                    Además, tienes derecho a acceder a un número telefónico para servicios de emergencia que
                    contemplemecanismos que permitan identificarte y ubicarte geográficamente.
                    <Salto/>
                </NSection>
                <NSection
                    title={'30. Las instalaciones o centros de atención contarán con adaptaciones y personal capacitado paraatender a personas con discapacidad.'}>
                    TÚ TIENES DERECHO a que las instalaciones o centros de atención al público de tu proveedor, cuenten
                    conadaptaciones, modificaciones o mecanismos de accesibilidad, así como con personal capacitado para
                    que túrecibas una atención adecuada.
                    <Salto/>
                </NSection>
                <NSection title={'31. Páginas de Internet con funcionalidades de accesibilidad. '}>
                    TÚ TIENES DERECHO a que las páginas o portales de Internet, o números telefónicos de atención al
                    públicodel proveedor, cuenten con funcionalidades de accesibilidad, y a recibir atención de su parte
                    a través depersonal capacitado.
                    <Salto/>
                </NSection>
                <NSection title={'IX. DERECHO A LA NO DISCRIMINACIÓN. '}/>
                <NSection
                    title={'32. Derecho a no ser discriminado en el acceso a la contratación y prestación de los servicios detelecomunicaciones.'}>
                    TÚ TIENES DERECHO a acceder, contratar o que se te presten los servicios de telecomunicaciones, sin
                    queel proveedor te pueda discriminar por origen étnico o nacionalidad, género, edad, discapacidades,
                    condición
                    social, salud, religión, opiniones, preferencias sexuales, estado civil o cualquier otroque
                    menoscabe o anule tus derechos y libertades como persona.
                    <Salto/>
                </NSection>
                <NSection title={'33. Derecho a la neutralidad de la red.'}>
                    TÚ TIENES DERECHO a acceder a cualquier contenido, aplicación o servicio ofrecido por el
                    proveedor,dentro del marco legal aplicable, sin limitaciones, degradaciones, restricciones o
                    discriminaciones.
                    <Salto/>
                    TÚ TIENES DERECHO a conocer, a través del portal de internet del proveedor, las características
                    delservicio, velocidad (como bits por segundo de subida y descarga de información, así como los
                    factores quepueden disminuir la velocidad del servicio) y calidad autorizados por el Instituto
                    Federal deTelecomunicaciones.
                    <Salto/>
                    TÚ TIENES DERECHO a que el proveedor preserve tu privacidad y la seguridad de la red, a que tu
                    proveedorno obstruya, interfiera, inspeccione, filtre o discrimine contenidos, aplicaciones o
                    servicios. También tienesderecho a recibir la capacidad, velocidad y calidad que contrataste con
                    independencia del contenido, origen,destino, aplicación o servicio que se te provean a través de
                    internet.
                    <Salto/>
                </NSection>
                <NSection title={'X. DERECHO DE ACCESO GRATUITO A UN NÚMERO TELEFÓNICO DE SERVICIOS DE EMERGENCIA.'}/>
                <NSection title={'34. Servicios de emergencia.'}>
                    TÚ TIENES DERECHO a que el proveedor te informe el número único de emergencias y a acceder demanera
                    gratuita a dicho número, el cual permitirá identificar y ubicar geográficamente tu llamada y, en
                    sucaso, los mensajes de texto que se envíen.
                    <Salto/>
                </NSection>
                <NSection title={'XI. DERECHO A LA SUSPENSIÓN DEL SERVICIO POR REPORTE DE ROBO O EXTRAVÍO. '}/>
                <NSection title={'35. Reporte de robo o extravío de equipos. '}>
                    TÚ TIENES DERECHO a reportar a tu operador a través de procedimientos expeditos, el robo o extravío
                    de tuequipo móvil, para que éste suspenda inmediatamente el servicio de los equipos o dispositivos
                    móviles.
                    <Salto/>
                    Una vez que reportaste tu equipo como robado o extraviado, el proveedor no podrá seguirte cobrando
                    elservicio.
                    <Salto/>
                </NSection>
                <NSection title={'36. Bloqueo de aplicaciones o servicios.'}>
                    TÚ TIENES DERECHO a que los proveedores bloqueen los contenidos, aplicaciones o servicios que
                    solicites.Podrás realizar esta petición por los mismos medios disponibles para realizar la
                    contratación.
                    <Salto/>

                </NSection>
                <NSection title={'37. Control parental. '}>
                    TÚ TIENES DERECHO a que los proveedores te proporcionen un servicio de control parental previa
                    solicitudde tu parte, y que publiquen de manera clara las características operativas de este
                    servicio y las instruccionespara que puedas operar las aplicaciones necesarias para su correcto
                    funcionamiento.
                    <Salto/>
                </NSection>
                <NSection title={'XII. DERECHO A LA PROTECCIÓN. '}/>
                <NSection title={'38. Derecho al acceso a los órganos administrativos.'}>
                    TÚ TIENES DERECHO a la protección y representación de Profeco para asegurarque tus derechos sean
                    respetados, con vistas a la prevención de daños patrimoniales y morales individuales ycolectivos,
                    garantizando la protección jurídica, económica administrativa y técnica.
                    <Salto/>
                </NSection>
                <NSection title={'39. Interposición de quejas y denuncias por incumplimientos del proveedor.'}>
                    TÚ TIENES DERECHO a presentar quejas y denuncias ante el Instituto Federal de Telecomunicaciones y
                    laProcuraduría Federal del Consumidor, ante cualquier incumplimiento del proveedor. Al iniciar
                    unprocedimiento conciliatorio derivado de una queja ante Profeco, tienes derecho a que, mientras
                    dure elmismo, no te suspendan los servicios de telecomunicaciones por falta de pago.
                    <Salto/>
                    Tienes derecho a presentar una queja ante el Instituto Federal de Telecomunicaciones por las fallas
                    de losservicios o incumplimientos de los operadores a sus obligaciones, a efecto de que se hagan las
                    verificacionescorrespondientes y, en su caso, se inicie un procedimiento de sanción.
                    <Salto/>
                </NSection>
                <NSection title={'40. Representación colectiva.'}>
                    TÚ TIENES DERECHO a ser representado en una acción colectiva ante el incumplimiento de un
                    proveedor,para buscar que se declare que el proveedor incurrió en conductas que dañaron tus
                    intereses y tu patrimonioy que lo condenen a la indemnización de daños y perjuicios. El
                    representante de tal colectividad o grupopuede ser Profeco, una asociación de consumidores
                    registrada o bien un grupo de por lo menos 30consumidores afectados que firmen la demanda.
                    <Salto/>
                    Estos son tus principales derechos como usuario de servicios de telecomunicaciones, existen otros
                    que seencuentran en las leyes, reglamentos y disposiciones administrativas vigentes y aplicables que
                    el proveedorde servicios tiene la obligación de respetar.
                    <Salto/>
                    Si quieres conocer más sobre tus derechos, visita la página de Internet del Instituto Federal
                    deTelecomunicaciones www.ift.org.mx o la página de la Procuraduría Federal del
                    Consumidorwww.profeco.gob.mx, y recuerda TÚ TIENES DERECHO.
                    <Salto/>
                </NSection>
            </Grid>
        </Container>
        <Footer/>
    </>
}
