import React, {useContext} from 'react'
import Typography from '@material-ui/core/Typography'
import { useResponsiveValues } from '../../../utils/hooks'
import { useSelector } from 'react-redux'

export default () => {
    const flexDirection = useResponsiveValues(
        'row',
        'column-reverse',
        'column-reverse'
    )
    const flexDirection2 = useResponsiveValues('row', 'column', 'column')
    const spaceing = useResponsiveValues('50px', '30px', '10px')
    const textAlign = useResponsiveValues('left', 'center', 'center')

    var data = useSelector((state) => state.productResumeData)
    const benefits = []

    switch (data.type) {
        case 'preplan':
        case 'plan':
            benefits[0] = {
                title: data?.mb_title.toLowerCase().split('mb')[0].trim() + ' MB',
                sub: `${data.primera_desc.split(' ').slice(1).join(' ')} (${
                    data.gb
                } GB en roaming internacional En Estados Unidos y Canadá)`,
            }
            benefits[1] = {
                title: `${data.segunda_desc.split(' ')[0]} Min`,
                sub: `(${data.segunda_real_desc.split(' ')[0]} min en Roaming
			    Estados Unidos y Canadá)`,
            }
            benefits[2] = {
                title: `${data.sms} SMS`,
                sub: `(${
                    data.tercera_real_desc.split(' ')[0]
                } min en Roaming Estados Unidos y Canadá)`,
            }
            break
        case 'mifi':
            benefits[0] = {
                title: data.gb + 'GB',
                sub: data.primera_desc.split(' ').slice(2).join(' '),
            }
            benefits[1] = { title: 'Conexión Portátil', sub: data.segunda_desc }
            benefits[2] = {
                title: `${data.segunda_real_desc.split(' ')[3]} Dispositivos`,
                sub: data.segunda_real_desc,
            }
            break
        case 'hbb':
            benefits[0] = {
                title: data.gb + 'GB',
                sub: data.primera_desc.split(' ').slice(2).join(' '),
            }
            benefits[1] = {
                title: data.segunda_desc,
                sub: data.segunda_real_desc,
            }
            benefits[2] = {
                title: data.tercera_desc.split(' ').slice(2).join(' '),
                sub: data.tercera_desc + ' ' + data.tercera_real_desc,
            }
    }

    if (data.stepperTexts) {
        data.toptext = data.stepperTexts.value.split('-')[0]
        data.bottomtext = data.stepperTexts.value.split('-')[1]
        data.pricetext = data.stepperTexts.value.split('-')[2]
    }

    return (
        <div
            style={{
                width: '100%',
                boxShadow: '2px 6px 13px -4px rgba(0,0,0,0.49)',
                padding: spaceing,
                margin: spaceing,
                marginTop: 0,
                display: 'flex',
                flexDirection,
            }}
        >
            <div
                style={{
                    flex: 1,
                    textAlign,
                }}
            >
                <Typography variant={'h5'} color={'secondary'}>
                    {data.toptext}
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: flexDirection2,
                        margin: 7,
                    }}
                >
                    <Typography variant={'h4'} color={'primary'}>
                        {benefits[0].title}
                    </Typography>
                    <Typography
                        variant={'body'}
                        color={'primary'}
                        style={{
                            paddingTop: 10,
                            marginLeft: 20,
                            fontSize: '14px',
                        }}
                    >
                        {benefits[0].sub}
                    </Typography>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: flexDirection2,
                        margin: 7,
                    }}
                >
                    <Typography
                        variant={'h4'}
                        color={'primary'}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {benefits[1].title}
                    </Typography>
                    <Typography
                        variant={'body'}
                        color={'primary'}
                        style={{
                            paddingTop: 10,
                            marginLeft: 20,
                            fontSize: '14px',
                        }}
                    >
                        {benefits[1].sub}
                    </Typography>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: flexDirection2,
                        margin: 7,
                    }}
                >
                    <Typography
                        variant={'h4'}
                        color={'primary'}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {benefits[2].title}
                    </Typography>
                    <Typography
                        variant={'body'}
                        color={'primary'}
                        style={{
                            paddingTop: 10,
                            marginLeft: 20,
                            fontSize: '14px',
                        }}
                    >
                        {benefits[2].sub}
                    </Typography>
                </div>
                <Typography
                    variant={'h5'}
                    color={'primary'}
                    style={{ textAlign: 'center' }}
                >
                    {data.bottomtext}
                </Typography>
            </div>

            <div
                style={{
                    padding: '20px 40px',
                    margin: 10,
                    borderLeft: '2px solid orange',
                    paddingTop: 63,
                }}
            >
                <Typography
                    color={'primary'}
                    variant={'h1'}
                    style={{ textAlign: 'center' }}
                >
                    ${data.price}
                </Typography>
                <Typography
                    variant={'body'}
                    color={'primary'}
                    style={{
                        textAlign: 'center',
                        display: 'inline-block',
                        width: '100%',
                        font: 'bolder',
			maxWidth: 240
                    }}
                >
                    {data.pricetext ? data.pricetext : data.period}
                </Typography>
            </div>
        </div>
    )
}
