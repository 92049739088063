import { createSvgIcon } from "@material-ui/core";

export const Telegram = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M8.04278 16.4154C12.4374 16.4154 16 12.882 16 8.52337C16 4.16473 12.4374 0.631348 8.04278 0.631348C3.64813 0.631348 0.0855713 4.16473 0.0855713 8.52337C0.0855713 12.882 3.64813 16.4154 8.04278 16.4154Z"
        fill="url(#paint0_linear_505_5308)"
      />
      <path
        d="M5.4729 9.09625L6.41598 11.6918C6.41598 11.6918 6.53387 11.9374 6.66355 11.9374C6.79322 11.9374 8.66758 9.99652 8.66758 9.99652L10.7541 5.98621L5.50827 8.42981L5.4729 9.09625Z"
        fill="#C2DBEB"
      />
      <path
        d="M6.72251 9.7627L6.54568 11.6685C6.54568 11.6685 6.47494 12.2531 7.06436 11.6685C7.65378 11.0839 8.21964 10.6279 8.21964 10.6279L6.72251 9.7627Z"
        fill="#9EC7D9"
      />
      <path
        d="M5.48471 9.18981L3.53961 8.55844C3.53961 8.55844 3.30384 8.46491 3.38636 8.25445C3.39814 8.20769 3.4335 8.17261 3.52781 8.11415C3.96398 7.82186 11.4968 5.13272 11.4968 5.13272C11.4968 5.13272 11.709 5.06257 11.8387 5.10934C11.8976 5.13272 11.933 5.15611 11.9684 5.24964C11.9801 5.28472 11.9919 5.35487 11.9801 5.42502C11.9801 5.47179 11.9684 5.51855 11.9684 5.58871C11.9212 6.32529 10.5537 11.8088 10.5537 11.8088C10.5537 11.8088 10.4712 12.1245 10.1765 12.1362C10.0704 12.1362 9.94074 12.1128 9.78749 11.9842C9.20986 11.4931 7.2176 10.1602 6.76964 9.86794C6.74606 9.85624 6.73428 9.83286 6.73428 9.80948C6.72249 9.7744 6.75785 9.73932 6.75785 9.73932C6.75785 9.73932 10.2355 6.67605 10.3298 6.34868C10.3415 6.32529 10.3062 6.3136 10.2708 6.32529C10.035 6.40714 6.03875 8.92089 5.59079 9.18981C5.55542 9.2015 5.48471 9.18981 5.48471 9.18981Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_505_5308"
          x1="8.03985"
          y1="16.4137"
          x2="8.03985"
          y2="0.629699"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0094D6" />
          <stop offset="1" stopColor="#00ACE0" />
        </linearGradient>
      </defs>
    </svg>,
    'Telegram'
  );
  