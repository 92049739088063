import React, {useEffect, useState} from "react";
import {Card, Checkbox, FormControlLabel, FormGroup, Grid, Typography} from "@material-ui/core";
import Item from "./Item";
import {XEL_FONT} from "../../../../../../../constant";
import Button from "@material-ui/core/Button";


const Products = ({dnData, planes, params, handlePostpago, handlePrepago, setHasHotspot}) => {
    const [loading, setLoading] = useState(true);

    const [option, setOption] = useState("NORMAL");
    useEffect(() => {
        if (dnData.planes) {
            setLoading(false);
            if (!dnData.planes.length)
                setOption('PREPLAN');
        }
    }, [dnData]);

    const handleChange = ({target: {id, name}}) => {
        setOption(name.toUpperCase());
    }
    return <Grid container spacing={1} style={{margin: 5}}>
        <Grid item xs={12}>
            {
                !loading && dnData.postpago && dnData.saldo > 0 &&
                <Typography variant={"body1"} style={{marginBottom: "2%", fontWeight: XEL_FONT.bold_weight}}
                            align={"center"}>
                    Pagar mensualidad
                </Typography>
            }
            {
                !loading && dnData.postpago && dnData.saldo > 0 &&
                <Item selected={params.postpago > 0}
		      setHasHotspot={setHasHotspot}
                      onClick={handlePostpago}
                      data={{
                          mensual: true,
                          name: "Mensualidad",
                          price: dnData.saldo,
                          price_original: dnData.saldo,
                          lim: dnData.fecha_limite,
                      }}
                />
            }
            {
                !loading && (dnData.planes.length && dnData.preplanes?.length) ? <>
                    <div style={{display: "flex", justifyContent: "space-around"}}>
                        <Button color={'primary'} variant={option === 'NORMAL' ? "contained" : "outlined"}
                                onClick={() => setOption('NORMAL')} name={'normal'}>
                            Normales
                        </Button>
                        <Button color={'primary'} variant={option === 'PREPLAN' ? "contained" : "outlined"}
                                onClick={() => setOption('PREPLAN')} name={'preplan'}>
                            Preplanes
                        </Button>
                    </div>
                </> : <></>
            }
            {
                (!loading && dnData.planes.length > 0 && option === 'NORMAL') &&
                <Typography variant={"body1"}
                            style={{marginBottom: "2%", marginTop: "2%", fontWeight: XEL_FONT.bold_weight}}
                            align={"center"}>
                    Comprar recarga
                </Typography>
            }
            {
                (!loading && dnData.planes.length > 0 && option === 'NORMAL') && planes.map(plan => {
                    let filter = dnData.planes.filter(id => id === plan.id_product);
                    if (filter.length > 0) {
                        return <Item
			    setHasHotspot={setHasHotspot}
                            key={plan.id_product}
                            data={plan}
                            selected={params.prepago.id === plan.id_product}
                            onClick={() => {
                                handlePrepago(plan.id_product, plan.price, plan.name)
                            }} onContinue={() => {
                        }}/>
                    }
                    return null;
                })
            }
            {
                (dnData.preplanes?.length > 0 && option === 'PREPLAN') && <>
                    <Typography variant={"body1"}
                                style={{marginBottom: "2%", marginTop: "2%", fontWeight: XEL_FONT.bold_weight}}
                                align={"center"}>
                        Preplanes
                    </Typography>
                    {
                        !dnData.preplan && <Typography variant={'caption'} align={'center'}>
                            No te quedes incomunicado, si lo prefieres, realiza un solo pago por 6 o 12 meses en el plan de tu
                            preferencia.
                        </Typography>
                    }
                    {
                        planes.map(plan => {
                            let filter = dnData.preplanes.filter(id => id === plan.id_product);
                            if (filter.length > 0) {
                                return <Item
				    setHasHotspot={setHasHotspot}
                                    key={plan.id_product}
                                    data={plan}
                                    selected={params.prepago.id === plan.id_product}
                                    onClick={() => {
                                        handlePrepago(plan.id_product, plan.price, plan.name)
                                    }} onContinue={() => {
                                }}/>
                            }
                            return null;
                        })
                    }
                </>
            }
        </Grid>
    </Grid>
}

export default Products;
