import {
    Backdrop,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    Paper,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../../constant";
import FormLabel from "@material-ui/core/FormLabel";
import {Alert, AlertTitle} from "@material-ui/lab";
import Axios from "../../../instances/axios";
import {OrangeMap} from "../../../assets/logos/home/svg";

const useStyles = makeStyles({
    header: {
        backgroundColor: XEL_COLORS.secondary,
        borderRadius: 5,
        padding: "1%"
    },
    cardContainer: {
        borderRadius: 5,
        backgroundColor: "#FEFEFE",
        boxShadow: "-1px 2px 6px 0px rgba(0,0,0,0.75)"
    },
    inputcontainer: {
        marginBottom: "1%"
    },
    label: {
        fontSize: 13
    }
});

const CoberturaHBB = ({
                          setStatus,
                          planDetails
                      }) => {
    let styles = useStyles();
    const axios = Axios();
    const [type, setType] = useState("address");
    const [error, setError] = useState({
        error: false,
        msg: ""
    })
    const defaultValues = {
        inputs: {
            cp: "",
            calle: "",
            numex: "",
            lat: "",
            long: ""
        },
        helpers: {
            cp: {
                error: false,
                msg: "Ejemplo: 09546"
            },
            calle: {
                error: false,
                msg: ""
            },
            numex: {
                error: false,
                msg: ""
            },
            lat: {
                error: false,
                msg: "Ejemplo: 19.1111111"
            },
            long: {
                error: false,
                msg: "Ejemplo: -99.2222222"
            }
        }
    };
    const [values, setValues] = useState(defaultValues.inputs);
    const [helpers, setHelpers] = useState(defaultValues.helpers);
    const [loading, setLoading] = useState(false);
    const [dirs, setDirs] = useState([]);
    const [selectedDir, setSelectedDir] = useState({});
    const [selectedUbication, setSelectedUbication] = useState("dir-0");
    const [continueRequired, setContinueRequired] = useState(false);
    const [extraMessage, setExtraMessage] = useState(null);
    const [continueHbb, setContinueHbb] = useState(false);
    const [currentPosition, setCurrentPosition] = useState({

        latitude: "",
        longitude: ""
    });
    const [showCurrentModal, setShowCurrentModal] = useState(false);
    const [coordinates, setCoordinates] = useState({
        lat: "",
        long: ""
    })
    const [result, setResult] = useState({
        loaded: false,
        message: "",
        available_service: false,
        velocity: 0
    });
    const [dirLabel, setdirLabel] = useState({
        verified: false,
        label: ""
    });
    useEffect(() => {
        // handle success case
        function onSuccess(position) {
            const {
                latitude,
                longitude
            } = position.coords;
            setLoading(true);
            axios.get("/utils/cp/query/info_cp_geocoding_reverse", {
                params: {
                    lat: latitude,
                    lng: longitude
                }
            }).then(res => {
                let data = res.data;
                if (!data.error) {

                    let g = {
                        "label": "Calle Jesús Morales, Barrio El Arenal, 43203 Zacualtipán de Ángeles, Hgo, México",
                        "cp": "43203",
                        "calle": "Calle Jesús Morales",
                        "numero": null,
                        "asentamiento": "Barrio El Arenal",
                        "municipio": "Zacualtipán de Ángeles",
                        "estado": "Hidalgo",
                        "pais": "México",
                        "lat": 20.65624,
                        "lng": -98.65922,
                        "asentamientos_cp": [
                            "10 de Mayo",
                            "11 de Abril",
                            "Ampliación Santa Cecilia",
                            "El Arenal",
                            "La Campesina",
                            "Los Pinos",
                            "Santa Cecilia",
                            "Tenantipa",
                            "Tepeyac",
                            "Zaragoza"
                        ]
                    }
                    console.log('Geocodign', data);
                    const r = data.response;
                    setdirLabel(data.response.label);
                    setCurrentPosition({
                        latitude: data.response.lat,
                        longitude: data.response.lng
                    });
                    setValues(prevState => ({...prevState, cp: r.cp, calle: r.calle, numex: r.numero || 'S/N'}));
                    setCoordinates({
                        lat: data.response.lat,
                        long: data.response.lng
                    });
                    setShowCurrentModal(true);
                    setLoading(false);
                }


            }).catch(err => {
                console.log(err);
            })


        }

        // handle error case
        function onError() {
            console.error("Error, al solicitar direccion");
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(onSuccess, onError);
        }
    }, [])
    const onChange = e => {
        let value = e.target.value;
        switch (e.target.name) {
            case "cp":
                if (isNaN(value))
                    return
                break;
            default:
                break;
        }
        setError({
            error: false,
            msg: ""
        })
        setValues({
            ...values,
            [e.target.name]: value
        });
    }


    const getCoordinates = (cp, calle, numex) => new Promise(resolve => {

        axios.get(`/utils/cp/query/info_cp_geocoding/${cp}`, {
            params: {
                type: "simplified",
                calle,
                numero: numex
            }
        }).then(res => {
            let data = res.data;

            if (data.error) {
                resolve({
                    error: true,
                    message: data.error_message
                });
            } else if (!data.response.geocoding) {
                resolve({
                    error: true,
                    message: "No se encontraron resultados"
                })
            } else {
                resolve({
                    error: false,
                    data: res.data.response.geocoding
                })
            }
        }).catch(err => {
            resolve({
                error: true,
                message: `Error al intentar conectarse a nuestros servicios.`
            });
        })
    });
    const getCoverage = (latitude, longitude) => new Promise(resolve => {
        let lat = latitude;
        let long = longitude;

        let ls = lat.toString().split(".");
        let los = long.toString().split(".");

        let sl = ls[1];
        let slo = los[1];

        let sml = "";
        if (sl.length < 5) {
            let tam = 5 - sl.length;

            for (let i = 0; i < tam; i++)
                sml += "0";

            lat = ls[0] + "." + ls[1] + sml;
        }
        let smlo = "";
        if (slo.length < 5) {
            let tam = 5 - slo.length;
            for (let i = 0; i < tam; i++)
                smlo += "0";
            long = los[0] + "." + los[1] + smlo;
        }
        setCoordinates({
            lat,
            long
        });

        axios.get("/utils/coverage/hbb", {
            params: {
                address: `${lat},${long}`
            }
        }).then(res => {

            resolve({
                ...res.data
            });
        }).catch(err => {
            resolve({
                error: true,
                message: `Error al intentar conectarse a nuestros servicios.`
            })
        });
    });
    const onCheckCurrent = async () => {
        setShowCurrentModal(false);
        setLoading(true);
        let results = await getCoverage(coordinates.lat, coordinates.long);
        if (results.error || results.status === "UNKNOW") {
            setResult({
                loaded: true,
                message: "Hubo un error al consultar disponibilidad de servicio, intenta nuevamente o utiliza otra dirección.",
                available_service: false
            })
        } else {
            let hbbMessageClient = checkPlan(results.velocity);
            setResult({
                loaded: true,
                message: results.status === "SERVICE" && hbbMessageClient ? "Felicidades hay cobertura para que disfrutes del servicio." : results.message,
                available_service: results.status === "SERVICE",
                velocity: results.velocity
            });
        }
        setLoading(false);
    }
    const onCheck = async () => {
        setError({
            error: false,
            msg: ""
        });

        if (continueRequired) { //Realizar acciones, aplica unicamente para direccion
            setLoading(true);
            let results = await getCoverage(selectedDir.lat, selectedDir.long);
            setLoading(false);
            if (results.error || results.status === "UNKNOW") {
                setResult({
                    loaded: true,
                    message: "Hubo un error al consultar disponibilidad de servicio, intenta nuevamente verificando tus datos o utiliza otra dirección.",
                    available_service: false,
                    velocity: 0
                })
            } else {
                let hbbMessageClient = checkPlan(results.velocity);
                setResult({
                    loaded: true,
                    message: results.status === "SERVICE" && hbbMessageClient ? "Felicidades hay cobertura para que disfrutes del servicio." : results.message,
                    available_service: results.status === "SERVICE",
                    velocity: results.velocity
                });
            }
        } else if (type === "address") {
            let {cp, calle, numex} = values;
            cp = cp.trim();
            if (cp.length < 5 || cp.length > 5) {
                setError({
                    error: true,
                    msg: "Codigo postal no válido."
                });
                return;
            } else if (calle.trim() === "") {
                setError({
                    error: true,
                    msg: "Nombre de calle requerido."
                });
                return;
            } else if (numex === "") {
                setError({
                    error: true,
                    msg: "Número exterior no válido."
                });
                return;
            }
            setLoading(true);
            let coords = await getCoordinates(cp, calle, numex);

            if (coords.error) {
                setLoading(false);
                setResult({
                    loaded: true,
                    message: "No se encontro dirección",
                    available_service: false,
                    velocity: 0
                })
            } else if (coords.data.length === 1) {
                let results = await getCoverage(coords.data[0].lat, coords.data[0].long);
                setLoading(false);
                if (results.error) {
                    setResult({
                        loaded: true,
                        message: "Hubo un error al consultar disponibilidad de servicio, intenta nuevamente verificando tus datos o utiliza otra dirección.",
                        available_service: false,
                        velocity: 0
                    })
                } else {
                    let hbbMessageClient = checkPlan(results.velocity);
                    setResult({
                        loaded: true,
                        message: results.status === "SERVICE" && hbbMessageClient ? "Felicidades hay cobertura para que disfrutes del servicio." : results.message,
                        available_service: results.status === "SERVICE",
                        velocity: results.velocity
                    })
                }
            } else if (coords.data.length > 1) {
                setDirs(coords.data);
                setLoading(false);
                setContinueRequired(true);
                setSelectedDir(coords.data[1]);
            } else {
                setLoading(false);
                setResult({
                    loaded: true,
                    message: "No se encontraron coordenadas",
                    velocity: 0,
                    available_service: false
                })
            }
        } else {
            const latRegex = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,15}/g;
            const lngRegex = /^-?(([-+]?)([\d]{1,3})((\.)(\d+))?)/g;
            const reg = new RegExp("^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}");
            if (!latRegex.test(values.lat) || !lngRegex.test(values.long)) {
                setError({
                    error: true,
                    msg: "Latitud y/o longitud no válidos"
                })
            }
            setLoading(true);
            let results = await getCoverage(values.lat, values.long);
            if (results.error || results.status === "UNKNOW") {
                setResult({
                    loaded: true,
                    message: "Hubo un error al consultar disponibilidad de servicio, intenta nuevamente o utiliza otra dirección.",
                    available_service: false
                })
            } else {
                let hbbMessageClient = checkPlan(results.velocity);
                setResult({
                    loaded: true,
                    message: results.status === "SERVICE" && hbbMessageClient ? "Felicidades hay cobertura para que disfrutes del servicio." : results.message,
                    available_service: results.status === "SERVICE",
                    velocity: results.velocity
                });
            }
            setLoading(false);
        }
    }
    const checkPlan = (velocity) => {
        if (planDetails && velocity) {
            let max = +planDetails.maxspeed;
            if (velocity < max) {
                setExtraMessage(`El plan seleccionado ofrece ${max}Mbps y la zona de tu dirección solo otorga ${velocity}Mbps de velocidad.`);
            } else {
                setContinueHbb(true);
                return true;
            }
        }
        return null;
    }
    const onClose = () => {
        let inBuyProcess = false;
        if (result.loaded && result.available_service) {
            if (setStatus) {
                if (continueHbb) {
                    inBuyProcess = true;
                    setStatus({
                        ...coordinates,
                        dir: {
                            cp: values.cp,
                            calle: values.calle,
                            numex: values.numex,
                        },
                    });
                }
            }
        }
        if (!inBuyProcess)
            setResult({message: "", loaded: false, available_service: false, velocity: 0});
    }


    return <Grid container style={{position: "relative", padding: 0, margin: 0}}>
        <Grid item xs={12}>
            <Grid container className={styles.cardContainer} spacing={2}>
                <Grid item xs={12} className={styles.header}>
                    <Typography variant={"h6"} color={"initial"} align={"center"} style={{color: "#FEFEFE"}}>
                        Consulta la cobertura en tu domicilio para internet fijo
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align={"center"} variant={"body2"}>
                        Antes de realizar la compra de tu servicio de internet fijo, es necesario verificar
                        que en la localidad donde vives existe cobertura disponible.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container style={{padding: "1%"}} spacing={2} alignItems={"center"}>
                        <Grid item xs={12} md={6}
                              style={{paddingLeft: "1%", paddingRight: "1%", justifyContent: "center"}}>
                            {
                                <FormControl component="fieldset" disabled={loading || continueRequired}>
                                    <FormLabel component="legend">Buscar por:</FormLabel>
                                    <RadioGroup
                                        value={type}
                                        onChange={e => {
                                            setType(e.target.value);
                                            setError({
                                                error: false,
                                                msg: ""
                                            });
                                        }}
                                        row
                                        aria-label="gender"
                                        name="row-radio-buttons-group">
                                        <FormControlLabel value="address" control={<Radio/>} label="Dirección"/>
                                        <FormControlLabel value="coordinates" control={<Radio/>} label="Coordenadas"/>
                                    </RadioGroup>
                                </FormControl>
                            }
                            {
                                type === "address" ? <> <FormControl disabled={loading || continueRequired} fullWidth
                                                                     className={styles.inputcontainer}>
                                    <InputLabel htmlFor="my-input">Código postal</InputLabel>
                                    <Input onChange={onChange} value={values.cp} name={"cp"} id="cp-ipt"
                                           aria-describedby="Código postal"/>
                                    <FormHelperText error={helpers.cp.error}
                                                    id="cp-helper">{helpers.cp.msg}</FormHelperText>
                                </FormControl>
                                    <FormControl disabled={loading || continueRequired} fullWidth
                                                 className={styles.inputcontainer}>
                                        <InputLabel htmlFor="calle">Calle</InputLabel>
                                        <Input onChange={onChange} value={values.calle} name={"calle"} id="calle"
                                               aria-describedby="Calle"/>
                                        <FormHelperText error={helpers.calle.error}
                                                        id="calle-helper">{helpers.calle.msg}</FormHelperText>
                                    </FormControl>
                                    <FormControl disabled={loading || continueRequired} fullWidth
                                                 className={styles.inputcontainer}>
                                        <InputLabel htmlFor="numex">Número exterior</InputLabel>
                                        <Input onChange={onChange} value={values.numex} name={"numex"} id="numex-ipt"
                                               aria-describedby="Num. Ext"/>
                                        <FormHelperText error={helpers.numex.error}
                                                        id="numex-helper">{helpers.numex.msg}</FormHelperText>
                                    </FormControl>
                                    {
                                        dirs.length > 0 &&
                                        <FormControl disabled={loading} style={{marginTop: "1.5%"}} component="fieldset"
                                                     className={styles.inputcontainer}>
                                            <FormLabel component="legend" style={{color: "#000000", fontSize: 14}}>Selecciona
                                                ubicacion y da clic en
                                                continuar:</FormLabel>
                                            <RadioGroup value={selectedUbication}
                                                        onChange={e => {
                                                            setSelectedUbication(e.target.value)
                                                        }}
                                                        aria-label="ubications"
                                                        name="ubicatios">
                                                {
                                                    dirs.map((dir, i) => <FormControlLabel
                                                        onClick={() => {
                                                            setSelectedDir(dir);
                                                        }}
                                                        classes={{
                                                            label: styles.label
                                                        }} key={i} value={`dir-${i}`}
                                                        control={<Radio/>}
                                                        label={dir.label}/>)
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    }
                                </> : <>
                                    <FormControl disabled={loading || continueRequired} fullWidth
                                                 className={styles.inputcontainer}>
                                        <InputLabel htmlFor="my-input">Latitud</InputLabel>
                                        <Input onChange={onChange} value={values.lat} name={"lat"} id="lat-ipt"
                                               aria-describedby="Latitud"/>
                                        <FormHelperText error={helpers.lat.error}
                                                        id="cp-helper">{helpers.lat.msg}</FormHelperText>
                                    </FormControl>
                                    <FormControl disabled={loading || continueRequired} fullWidth
                                                 className={styles.inputcontainer}>
                                        <InputLabel htmlFor="long">Longitud</InputLabel>
                                        <Input onChange={onChange} value={values.long} name={"long"} id="long-ipt"
                                               aria-describedby="longitud"/>
                                        <FormHelperText error={helpers.long.error}
                                                        id="calle-helper">{helpers.long.msg}</FormHelperText>
                                    </FormControl>

                                </>
                            }
                            {

                                error.error && <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {
                                        error.msg
                                    }
                                </Alert>
                            }
                            <FormControl fullWidth className={styles.inputcontainer} style={{marginTop: "1%"}}>
                                <Button disabled={loading} variant={"contained"} color={"primary"} onClick={onCheck}>
                                    {loading ? <CircularProgress/> : !continueRequired ? "Verificar" : "Continuar"}
                                </Button>
                            </FormControl>
                            {
                                type === "address" && <Typography style={{marginTop: "3%"}} variant={"subtitle2"}>
                                    Nota: La búsqueda mediante dirección puede no ser exacta.
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={0} md={6}>
                            <OrangeMap width={"100%"} height={"100%"}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {
            showCurrentModal && <Backdrop sx={{
                color: '#fff',
                //    zIndex: (theme) => theme.zIndex.drawer + 1
            }}
                                          style={{
                                              position: "absolute",
                                              zIndex: 1000,
                                              margin: 0,
                                              height: "100%",
                                              width: "100%"
                                          }} open={true}>
                <Paper elevation={3} style={{
                    padding: "2%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={"body1"} align={"center"}>
                                ¿Deseas que utilicemos tu ubicacion actual?
                            </Typography>
                            <Typography align={"center"} variant={"body2"}>
                                {dirLabel}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{justifyContent: "space-around", display: "flex"}}>
                            <Button variant={"contained"} color={"primary"} onClick={onCheckCurrent}>
                                Si
                            </Button>
                            <Button variant={"contained"} color={"secondary"}
                                    onClick={() => setShowCurrentModal(false)}>
                                No
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Backdrop>
        }
        <Backdrop
            sx={{
                color: '#fff',
                //    zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            style={{position: "absolute", zIndex: 1000, margin: 0, height: "100%", width: "100%"}}
            open={result.loaded}

        >
            <Paper elevation={3} style={{
                padding: "2%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column"
            }}>
                <Typography style={{marginBottom: "2%"}}>
                    Resultado:
                </Typography>
                <Typography style={{marginBottom: "2%", fontWeight: XEL_FONT.bold_weight}}>
                    {
                        result.message
                    }
                </Typography>
                <Typography variant={"body2"}>
                    Coordenadas: {coordinates.lat} | {coordinates.long}
                </Typography>
                {
                    extraMessage && <Typography style={{marginBottom: "2%"}} variant={"subtitle2"} color={"primary"}>
                        Importante: {
                        extraMessage
                    }
                    </Typography>
                }
                <Button style={{marginTop: "3%"}} onClick={onClose} variant={"contained"} color={"primary"}>
                    Aceptar
                </Button>
            </Paper>
        </Backdrop>
    </Grid>
}

export default CoberturaHBB;
