import React, {useEffect, useState} from "react";
import {Container, Grid, Hidden} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {XEL_COLORS, XEL_FONT} from "../../../../constant";
import OpenPay from '../../../../assets/logos/OpenPay/openpay_color.png';
import InXel from '../../../../assets/animaciones/animationhome.gif';
import {useResponsiveValues} from "../../../utils/hooks";
import Zoom from "@material-ui/core/Zoom";

export function Extra() {

    const [msgActive, setMsgActive] = useState(1);

    useEffect(() => {
        setTimeout(() => {
            if (msgActive === 2)
                setMsgActive(1);
            else
                setMsgActive(msgActive + 1);
        }, 3000);
    }, [msgActive])

    const Msg1 = () => <Zoom in={true} timeout={500}>
            <Grid item xs={9} md={6}
                  style={{display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center"}}>
                <Typography variant={'h1'} color={'primary'} align={'center'}
                            style={{marginBottom: 10, fontSize: useResponsiveValues(40, 20, 12)}}>
                    ¿Quieres más por tu dinero?
                </Typography>
                <Hidden only={['xs', "sm"]}>
                    <br/>
                </Hidden>
                <Typography variant={'h3'} color={'primary'}
                            style={{fontWeight: XEL_FONT.regular_weight, fontSize: useResponsiveValues(30, 15, 12)}}
                            align={'center'}>
                    Tenemos un plan que se adapta a ti
                </Typography>
            </Grid>
        </Zoom>
    ;
    const Msg2 = () => <Zoom in={true} timeout={500}>
        <Grid item xs={9} md={6}
              style={{display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center"}}>
            <Typography variant={'h1'} color={'primary'} align={'center'}
                        style={{marginBottom: 10, fontSize: useResponsiveValues(40, 20, 12)}}>
                Envíos a CDMX: $99.00
            </Typography>
            <Hidden only={['xs', "sm"]}>
                <br/>
            </Hidden>
            <Typography variant={'h3'} color={'primary'}
                        style={{fontWeight: XEL_FONT.regular_weight, fontSize: useResponsiveValues(30, 15, 12)}}
                        align={'center'}>
                Envíos al interior de la república: $149.00
            </Typography>
        </Grid>
    </Zoom>;
    return <Container>
        <Grid container style={{backgroundColor: '#FEFEFE', paddingTop: 40, marginBottom: 20}}>
            {
                msgActive === 1 ? <Msg1/> : ''
            }
            {
                msgActive === 2 ? <Msg2/> : ''
            }

            <Grid item xs={3} md={6} style={{display: "flex", justifyContent: "center"}}>
                <img src={InXel} alt="inXel" style={{maxWidth: 240, maxHeight: 213, width: '100%', height: '100%'}}/>
            </Grid>

            <Grid item xs={12} md={6}
                  style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                <Hidden only={['md', "lg", "xl"]}>
                    <br/>
                </Hidden>
                <Typography variant={'body1'}
                            style={{fontWeight: XEL_FONT.regular_weight, fontSize: useResponsiveValues(20, 12, 12)}}
                            align={'center'}>
                    Pagos seguros con
                </Typography>
                <Grid container>
                    <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                        <img src={OpenPay} style={{height: 50}} alt="OpenPay"/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Container>
}
