import React, { useEffect, useState, useContext } from "react";
import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { XEL_COLORS, XEL_FONT } from "../../../../../constant";
import { useResponsiveValues } from "../../../../utils/hooks";
import TextField from "@material-ui/core/TextField";
import * as Actions from '../../../../../actions';
import { useLocation } from 'react-router-dom';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Visa from '../../../../../assets/logos/OpenPay/visa.png';
import Master from '../../../../../assets/logos/OpenPay/masterCard.png';
import Amex from '../../../../../assets/logos/OpenPay/americanExpress.png';
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import NumberFormat from "react-number-format";
import { PayGateContext } from "../context"

export default function Detalles({ option }) {


    const plan = useSelector(state => state.planname);
    const amount = useSelector(state => state.planprice);
    const envio = parseInt(amount);
    const dispatch = useDispatch();
    const dataSend = useSelector(state => state.datasend);
    const { search } = useLocation();
    const textSize = useResponsiveValues(20, 16, 12);
    const detalle = useSelector(state => state.carrito);
    const normalSize = useResponsiveValues(20, 15, 12)
    let { freeShipping, showPortInInput } = useContext(PayGateContext)

    const appState = useSelector(s => s)
    const [tot, setTot] = useState(
        detalle.total_w_send ?
            +detalle.total_w_send :
            parseInt(freeShipping ? 0 : envio) + parseInt(detalle.total)
    )
    useEffect(() => {
        setTot(parseInt(freeShipping ? 0 : envio) + parseInt(detalle.total))
    }, [freeShipping, showPortInInput, appState])


    const [method, setMethod] = useState(dataSend.pay_method ? dataSend.pay_method : 'CONTADO');
    const [cardType, setCardType] = useState(dataSend.cardType ? dataSend.cardType : 'VISA');

    const [months, setMoths] = useState([]);
    const [monSel, setMonSel] = useState(3);
    const [totalMes, setTotalMes] = useState(0);


    useEffect(() => {
        if (method !== 'CONTADO') {
            // eslint-disable-next-line array-callback-return
            months.map(month => {
                if (month.months === monSel)
                    setTotalMes(month.price)
            })
        }
    }, [method, monSel, cardType, months])


    useEffect(() => {
        dispatch(Actions.SET_DATA({
            ...dataSend,
            pay_method: method,
            cardType: cardType
        }));
    }, [method]);

    useEffect(() => {
        dispatch(Actions.SET_DATA({
            ...dataSend,
            cardType: cardType
        }));

        const onePercent = tot / 100;
        let pr1;
        let pr2;
        let pr3;
        let pr4;
        let ms = [];
        if (cardType === 'VISA') {
            let price1 = (onePercent * 7.7) + 2.5 + tot;
            let price2 = (onePercent * 10.7) + 2.5 + tot;
            let price3 = (onePercent * 13.7) + 2.5 + tot;
            let price4 = (onePercent * 16.7) + 2.5 + tot;

            pr1 = {
                months: 3,
                price: price1.toFixed(2)
            }
            pr2 = {
                months: 6,
                price: price2.toFixed(2)
            }
            pr3 = {
                months: 9,
                price: price3.toFixed(2)
            }
            pr4 = {
                months: 12,
                price: price4.toFixed(2)
            }
        } else {
            let price1 = (onePercent * 6.7) + 2.5 + tot;
            let price2 = (onePercent * 8.7) + 2.5 + tot;
            let price3 = (onePercent * 10.7) + 2.5 + tot;
            let price4 = (onePercent * 12.7) + 2.5 + tot;
            pr1 = {
                months: 3,
                price: price1.toFixed(2)
            }
            pr2 = {
                months: 6,
                price: price2.toFixed(2)
            }
            pr3 = {
                months: 9,
                price: price3.toFixed(2)
            }
            pr4 = {
                months: 12,
                price: price4.toFixed(2)
            }
        }
        if (tot >= 400)
            ms.push(pr1);
        if (tot >= 600)
            ms.push(pr2);
        if (tot >= 900)
            ms.push(pr3);
        if (tot >= 1200)
            ms.push(pr4);
        setMoths(ms);
    }, [cardType]);


    useEffect(() => {
        dispatch(Actions.SET_DATA({
            ...dataSend,
            monSel: monSel
        }));
        for (let i = 0; i < months.length; i++) {
            if (monSel === months[i].months) {
                dispatch(Actions.ADD_TO_CART({
                    ...detalle,
                    mensuality: {
                        enabled: true,
                        price: months[i].price,
                        months: months[i].months
                    }
                }))
            }
        }
    }, [monSel]);
    useEffect(() => {
        dispatch(Actions.ADD_TO_CART({
            ...detalle,
            total_w_send: tot
        }));
        const especialCode = new URLSearchParams(search).get('XelCode');
        if (especialCode) {
            dispatch(Actions.SET_DATA({
                ...dataSend,
                promotion: especialCode
            }));
        }
    }, []);

    const handleChange = e => {

        dispatch(Actions.SET_DATA({
            ...dataSend,
            [e.target.name]: e.target.value.toUpperCase()
        }));
    }

    const handleChangeMethod = e => {
        if (e.target.value === 'CONTADO') {
            dispatch(Actions.ADD_TO_CART({
                ...detalle,
                mensuality: {
                    enabled: false,
                }
            }))
        } else {
            for (let i = 0; i < months.length; i++) {
                if (monSel === months[i].months) {
                    dispatch(Actions.ADD_TO_CART({
                        ...detalle,
                        mensuality: {
                            enabled: true,
                            price: months[i].price,
                            months: months[i].months
                        }
                    }))
                }
            }
        }
        setMethod(e.target.value);

    }
    const handleChangeCard = e => {
        setCardType(e.target.value);
    }
    const handleChangeMonth = e => {
        setMonSel(e.target.value);
    }
    return <Grid container justify={'center'} alignItems={'center'} spacing={2}>
        <Grid item xs={12}>
            <Typography align={'center'} color={'primary'} variant={'h5'} style={{
                marginBottom: '1%',
                fontWeight: XEL_FONT.bold_weight,
            }}>
                Resumen de compra:
            </Typography>
            <Divider style={{ width: "100%", backgroundColor: XEL_COLORS.secondary }} />
        </Grid>

        <Grid item xs={12} md={7}>
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant={'h4'} align={'center'} color={'primary'} style={{
                                borderRight: "2px solid " + XEL_COLORS.secondary,
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                {
                                    option === 1 || option === 4 || option === 6 || option === 5 || option === 7 ? `Plan ${plan.name}` : ''
                                }
                                {
                                    option === 2 ? 'Equipo + Plan' : ''
                                }
                                {
                                    option === 3 ? 'Xel Pack' : ''
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8} style={{ padding: '.5%' }}>
                            <Grid container spacing={1} >
                                <Grid item xs={8}>

                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align={'center'} color={'secondary'}
                                        style={{ fontWeight: XEL_FONT.bold_weight }}>
                                        Total
                                    </Typography>
                                </Grid>
                                {
                                    detalle.products.map(product => <>
                                        <Grid item xs={8}>
                                            <Typography align={'left'} style={{ fontWeight: XEL_FONT.bold_weight }} color={'primary'} >
                                                {product.title}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Typography style={{ fontWeight: XEL_FONT.bold_weight }} align={'center'} color={'primary'} >
                                                ${
                                                    parseInt(product.price).toFixed(2)
                                                }
                                            </Typography>
                                        </Grid>
                                    </>
                                    )
                                }
                                {
                                    detalle.descuento ? detalle.descuento.show ? <>
                                        <Grid item xs={8}>
                                            <Typography style={{ fontWeight: XEL_FONT.bold_weight }} align={'left'} color={'primary'} >
                                                {detalle.descuento.title}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Typography align={'center'} color={'primary'}
                                                style={{ fontWeight: XEL_FONT.bold_weight, color: 'red' }}
                                            >
                                                {
                                                    +detalle.descuento.value.toFixed(2)
                                                }
                                            </Typography>
                                        </Grid>
                                    </> : '' : ''
                                }
                                <Grid item xs={8}>
                                    <Typography style={{ fontWeight: XEL_FONT.bold_weight }} align={'left'} color={'primary'} >
                                        Costo de envío
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography style={{ fontWeight: XEL_FONT.bold_weight }} align={'center'} color={'primary'} >
                                        ${
                                            parseInt(detalle.envio.price).toFixed(2)
                                        }
                                    </Typography>
                                </Grid>

                                <Grid item xs={8}>
                                    <Typography style={{ fontWeight: XEL_FONT.bold_weight }} align={'left'} color={'primary'} >
                                        Chip inXel
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography style={{ fontWeight: XEL_FONT.bold_weight }} align={'center'} color={'primary'} >
                                        $0.00
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <Divider variant={"fullWidth"}
                                                style={{ backgroundColor: XEL_COLORS.secondary }} />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography align={'left'} color={'secondary'}>
                                                Total a pagar
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography align={'center'} color={'secondary'}
                                                style={{ fontWeight: XEL_FONT.semi_bold_weight }}>

                                                ${tot.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {
                                    method !== 'CONTADO' ? <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={8}>
                                                <Typography align={'left'} color={'primary'}
                                                >
                                                    Total a mensualidades
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography align={'center'} color={'primary'}
                                                    style={{
                                                        fontWeight: XEL_FONT.semi_bold_weight
                                                    }}>
                                                    <NumberFormat thousandSeparator={true}
                                                        prefix={'$'}
                                                        displayType={'text'}
                                                        value={totalMes} />

                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid> : ''
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>


        <Grid item xs={12} style={{ width: '100%', marginTop: '2%' }}>
            <Grid container justify={'center'}>
                {
                    tot > 50000 ? <Grid item xs={11} md={7}>
                        <FormControl fullWidth>
                            <InputLabel
                                id="planselector"
                                style={{ fontSize: normalSize }}>¿Cómo vas a realizar tu pago?</InputLabel>
                            <Select

                                labelId="planselector"
                                id="demo-customized-select"
                                value={method}
                                onChange={handleChangeMethod}
                                style={{ fontSize: normalSize }}
                            >
                                <MenuItem key={'contado'} value={'CONTADO'}
                                    style={{ fontSize: normalSize }}>Al contado</MenuItem>
                                <MenuItem key={'meses'} value={'MENSUALIDADES'}
                                    style={{ fontSize: normalSize }}>Mensualidades</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> : ''
                }
                <Grid item xs={12}>
                    <Grid container justify={'center'} alignItems={'flex-end'}>
                        {
                            method === 'MENSUALIDADES' ? <>
                                <Grid item xs={11} md={4} style={{ marginTop: '2%' }}>
                                    <FormControl component="fieldset">
                                        <FormLabel style={{ fontSize: normalSize }} component="legend">Tarjeta de
                                            crédito</FormLabel>
                                        <RadioGroup onChange={handleChangeCard} row aria-label="position"
                                            style={{ alignItems: "center", marginTop: '2%' }} name="tipoc"
                                            defaultValue="VISA">
                                            <Grid container spacing={2} justify={'center'}>
                                                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                                                    <FormControlLabel
                                                        value="VISA"
                                                        control={<Radio size={'small'} color="primary" />}
                                                        label=""
                                                        labelPlacement="top"
                                                        style={{ marginRight: 0 }}
                                                    />
                                                    <img src={Visa}
                                                        style={{
                                                            maxWidth: 40,
                                                            width: '100%',
                                                            maxHeight: 30,
                                                            height: '100%'
                                                        }}
                                                        alt={'VISA'} />
                                                    <img src={Master}
                                                        style={{
                                                            maxWidth: 40,
                                                            width: '100%',
                                                            maxHeight: 30,
                                                            height: '100%', marginRight: '4%'
                                                        }}
                                                        alt={'MasterCard'} />
                                                </Grid>
                                                <Grid item xs={12} md={6} style={{ display: "flex" }}>
                                                    <FormControlLabel
                                                        value="AMEX"
                                                        control={<Radio size={'small'} color="primary" />}
                                                        label=""
                                                        labelPlacement="top"
                                                        style={{ marginRight: 0 }}
                                                    />
                                                    <img src={Amex}
                                                        style={{
                                                            maxWidth: 40,
                                                            width: '100%',
                                                            maxHeight: 30,
                                                            height: '100%'
                                                        }}
                                                        alt={'Amex'} />
                                                </Grid>
                                            </Grid>


                                        </RadioGroup>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={11} md={4} style={{ marginTop: '2%' }}>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="planselector"
                                            style={{ fontSize: normalSize }}>Selecciona meses</InputLabel>
                                        <Select

                                            labelId="planselector"
                                            id="demo-customized-select"
                                            value={monSel}
                                            onChange={handleChangeMonth}
                                            style={{ fontSize: normalSize }}
                                        //   input={<BootstrapInput />}
                                        >
                                            {
                                                months.map(month => <MenuItem key={month.months} value={month.months}
                                                    style={{ fontSize: normalSize }}>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography>{month.months} Meses</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            ${month.price}
                                                        </Grid>
                                                    </Grid>
                                                </MenuItem>
                                                )
                                            }

                                        </Select>
                                    </FormControl>
                                </Grid>

                            </>

                                : ''
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}
