import React, {useEffect} from "react";
import Cookies from "js-cookie";
import {Backdrop, Button, Card, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Inxel from "../../../assets/logos/inxel/logo-inxel-blanco.png";
import {XEL_COLORS, XEL_FONT} from "../../../constant";

const useStyles = makeStyles({
    popUp: {
        background: `linear-gradient(${XEL_COLORS.secondary} 0%, ${XEL_COLORS.primary} 100%)`,
        padding: "2%"
    },
    centerObjects: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
});
const PopUp = () => {
    const styles = useStyles();

    const [message, setMessage] = React.useState({
        text: "Te informamos que derivado del ajuste inflacionario que vivimos actualmente las tarifas de nuestros servicios fueron ajustadas desde el 1ro de marzo 2022.",
        type: "",
        title: "Estimado Cliente inXel:",
        //dateStart: "",
        dateEnd: new Date("2022-04-01T00:00:00.000Z").getTime(),
        show: false
    });

    useEffect(() => {
        if (message.dateEnd && !message.show) {
            const date = new Date().getTime();
            if (date < message.dateEnd) {//Verificar validez de la fecha
                let popUpCookies = Cookies.get("@popUp");
                if (!popUpCookies) {//Verificar si ya se ha mostrado el mensaje
                    setMessage({...message, show: true});
                }
            }
        }
    }, [message]);


    const handleClose = () => {
        let date = new Date();
        date.setHours(date.getHours() + 24);
        Cookies.set("@popUp", "Showed PopUp", {expires: date});
        setMessage({
            ...message,
            show: false
        });
    };

    if (!message.show)
        return <></>

    return <Backdrop open={true} style={{zIndex: 10000}}>
        <Grid container justify={"center"} alignItems={"center"}>
            <Grid item xs={11} sm={8} md={5} lg={4}>
                <Card className={styles.popUp}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            <img src={Inxel} style={{width: 100, height: "100%"}} alt={"Logo inXel"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body1"} align={"center"}
                                        style={{fontWeight: XEL_FONT.bold_weight, color: "#FEFEFE"}}>
                                {message.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={"body1"} style={{color: "#FEFEFE"}}
                                        align={"center"}>{message.text}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            <Button variant={"contained"} onClick={handleClose}>
                                <Typography style={{color: XEL_COLORS.primary}}>
                                    Aceptar
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>

                </Card>
            </Grid>
        </Grid>
    </Backdrop>


}

export default PopUp;
