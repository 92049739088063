import React, {useEffect} from "react";
import {Grid, Hidden} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import Footer from "../../ui/Footer";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Mapa from '../../../assets/images/zonaventa/map.svg';
import Caro from '../../../assets/images/zonaventa/caro1.png';
import Titulo from "./Titulo";
export default function ZonaVenta() {
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Zona de venta";
    }, [])
    return <>
        <NavBar/>
        <Grid container alignItems={'center'} style={{marginBottom: '3%',minHeight:'30vh'}}>
            <Hidden only={["sm", "xs"]}>
                <Grid item xs={6} style={{padding: '3%'}}>
                    <Typography variant={'h1'} color={'primary'}>
                        Descubre toda nuestra zona de venta.
                    </Typography>
                    <br/>
                    <br/>
                    <Typography variant={'h3'} color={'primary'}>
                        Si tu ciudad no aparece, no te preocupes. ¡Esperanos!
                    </Typography>
                </Grid>
            </Hidden>
            <Hidden only={["sm", "xs"]}>
                <Grid item xs={6}>
                    <img src={Caro} alt="City" style={{height: '100%', width: '100%'}}/>
                </Grid>
            </Hidden>
            <Hidden smUp={true}>
                <Titulo/>
            </Hidden>
        </Grid>


        <Container style={{marginTop: 20}}>
            <Grid container>
                <Grid item xs={12}>
                    <img src={Mapa} alt="Mapa" style={{maxWidth: 1317, width: '100%', maxHeight: 897, height: '100%'}}/>
                </Grid>
            </Grid>
        </Container>
        <Footer/>
    </>
}
