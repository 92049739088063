import React, {useEffect, useState} from "react";
import {Container, Grid, Hidden} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {XEL_COLORS, XEL_FONT} from "../../../../constant";
import Logo1 from '../../../../assets/logos/companies/svg/sec1.svg';
import Logo2 from '../../../../assets/logos/companies/svg/sec2.svg';
import Logo3 from '../../../../assets/logos/companies/svg/sec3.svg';
import {useResponsiveValues} from "../../../utils/hooks";

const useStyles = makeStyles({
    card: {
        width: '100%',
        padding: '2%',
    },
    container: {
        padding: '1%',
        height: '100%'
    },
    circle: {
        borderRadius: '50%',
        borderColor: XEL_COLORS.primary,
        border: '1px solid',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: '1%',
        '& img': {
            width: '70%',
        },
    },
    icon_c: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:"column"
    },
    title:{
       padding:'1%',
       color:XEL_COLORS.secondary,
       fontWeight:XEL_FONT.bold_weight,
    }


});

const SIcon = ({icon}) => {
    const styles = useStyles();
    const size = useResponsiveValues(100, 75, 50);
    return <div className={styles.circle} style={{height: size, width: size}}>
        <img src={icon} alt="inXel"/>
    </div>
}
export default function Unitys() {
    const styles = useStyles();
    const textSize = useResponsiveValues(19, 15, 10);
    const heig = useResponsiveValues('500px','120px','90px');
    const [he, setHe] = useState('100%');
    useEffect(() => {
        console.log(document.getElementById('box_i').getBoundingClientRect().height);
    },[]);
    return <Container>
        <Grid container id={'box_i'}  >
            <Grid item sm={12} md={4} className={styles.container} >
                <Card className={styles.card} style={{minHeight:heig}} >
                    <Grid container style={{height:'100%'}} >
                        <Grid item xs={3} md={12} className={styles.icon_c}>
                            <SIcon icon={Logo1}/>

                        </Grid>
                        <Grid item xs={9} md={12} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <Typography align={'center'} style={{fontSize: textSize}}>
                                En la unidad de negocios de <strong>inXel Empresas</strong>, contamos con un equipo de
                                profesionales
                                expertos , elaborando esquemas de soluciones de servicios de telefonía celular para las
                                comunidades de las empresas, instituciones, organizaciones, etc., sin que le represente
                                un costo adicional y puedan incorporarlo como un beneficio para sus empleados,
                                afiliados, clientes, etc.
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item sm={12} md={4} className={styles.container} style={{height:he}}>
                <Card className={styles.card} style={{minHeight:heig}}>
                    <Grid container>
                        <Grid item xs={3} md={12} className={styles.icon_c}>
                            <SIcon icon={Logo2}/>

                        </Grid>
                        <Grid item xs={9} md={12} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <Typography align={'center'} style={{fontSize: textSize}}>
                                Tendrán a su disposición todas las facilidades para realizar cualquier tipo de trámite, <strong>adición de líneas, renovación del contrato y apoyo para la solución de problemas</strong> que se presenten, de forma ágil y eficiente.
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

            <Grid item sm={12} md={4} className={styles.container} style={{height:he}}>
                <Card className={styles.card} style={{minHeight:heig}}>
                    <Grid container>
                        <Grid item xs={3} md={12} className={styles.icon_c}>
                            <SIcon icon={Logo3}/>
                        </Grid>
                        <Grid item xs={9} md={12} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <Typography align={'center'} style={{fontSize: textSize}}>
                                Estamos convencidos de que somos su mejor opción, si desea más información por favor llene el siguiente formulario y un <strong>Asesor inXel</strong> se pondrá en contacto con usted dentro de las próximas 24 horas.
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    </Container>
}