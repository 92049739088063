import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Axios from '../../../instances/axios'
import { useSelector } from 'react-redux'

import Alert from '@material-ui/lab/Alert'
import Logo from '../../../assets/logos/inxel/logo-inxel-color.png'
import LogoGray from '../../../assets/logos/inxel/logo-inxel-grayscale.png'

export default ({ setImeiIsCompatible }) => {
    const [isCompatible, setIsCompatible] = useState(null)
    const [loading, setLoading] = useState(false)
    const [errorFeedback, setErrorFeedback] = useState('')
    const [imei, setImei] = useState('')
    const [showContactForm, setShowContactForm] = useState(false)

    const session = useSelector((state) => state.login)
    const axios = Axios()
    const checkImei = () => {
        if (!imei) {
            setErrorFeedback('Introduzca su IMEI antes de enviar el formulario')
            return
        }
        if (imei.match(/[^$,.\d]/)) {
            setErrorFeedback('El IMEI solo debe contener números')
            return
        }
        if (imei.length < 14 || imei.length > 17) {
            setErrorFeedback('El IMEI debe contener entre 14 y 17 caracteres')
            return
        }

        setErrorFeedback('')
        setLoading(true)
        axios({
            url: `/status/imei/${imei}`,
            method: 'GET',
        })
            .then(({ data: { status, message } }) => {
                setLoading(false)
                if (status === 'OK') {
                    setIsCompatible('compatible')
                    setImeiIsCompatible(true)
                } else if (status === 'ERROR') {
                    setImeiIsCompatible(false)
                    setIsCompatible('incompatible')
                    if (session.logged)
                        axios({
                            url: '/clients/leads/add.because.fail',
                            method: 'POST',
                            data: {
                                tipo: 'IMEINOTCOMPATIBLE',
                                extras: {
                                    imei,
                                    status: message,
                                },
                            },
                        })
                }
            })
            .catch((e) => console.log(e))
    }

    const history = useHistory()

    const [prospectName, setProspectName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [prospectFeedback, setProspectFeedBack] = useState(null)

    const postNotLoggedUserProspect = () => {
        if (!prospectName || !lastName || !phone) {
            setProspectFeedBack({
                msg: 'No ha llenado todos los campos requeridos',
                type: 'error',
            })
            return
        }
        if (phone.match(/[^$,.\d]/) || phone.length !== 10) {
            setProspectFeedBack({
                msg: 'Número de teléfono inválido',
                type: 'error',
            })
            return
        }
        if (
            email &&
            !email
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
        ) {
            setProspectFeedBack({
                msg: 'Email inválido',
                type: 'error',
            })
            return
        }

        setProspectFeedBack({ msg: 'Enviando formulario...' })
        axios({
            url: '/clients/not-logged-prospect',
            method: 'POST',
            data: {
                fields: {
                    TITLE: 'Usuario inXel IMEI no compatible',
                    NAME: prospectName,
                    LAST_NAME: lastName,
                    STATUS_ID: 'NEW',
                    OPENED: 'Y',
                    ASSIGNED_BY_ID: 1,
                    PHONE: [
                        {
                            VALUE: phone,
                            VALUE_TYPE: 'WORK',
                        },
                    ],
                    EMAIL: [
                        {
                            VALUE: email,
                            VALUE_TYPE: 'WORK',
                        },
                    ],
                },
            },
        })
            .then((_) => {
                setProspectFeedBack({
                    msg: 'Nuestros asesores se pondrán en contacto pronto!',
                    type: 'success',
                })
                setTimeout(() => history.push('/'), 2500)
            })
            .catch((e) =>
                setProspectFeedBack({
                    msg: 'No se pudo enviar el formulario',
                    type: 'error',
                })
            )
    }

    const optionBtnStyle = {
        backgroundColor: '#5A3571',
        padding: '5px 10px',
        color: 'white',
        border: 'none',
        borderRadius: 6,
        margin: '0px 20px',
        cursor: 'pointer',
    }

    const inputStyle = {
        // border: 'none',
        border: '1px solid #bbb',
        borderRadius: 5,
        padding: 5,
        boxSizing: 'border-box',
        margin: 4,
    }

    return (
        <div style={{ color: '#5A3571' }}>
            {!isCompatible ? (
                <>
                    <ul>
                        <li>Marca desde tu celular *#06# y anota tu IMEI</li>
                    </ul>
                    <div style={{ display: 'flex' }}>
                        <div
                            style={{
                                padding: 20,
                                backgroundColor: '#FF8A21',
                                borderRadius: 15,
                                margin: 'auto',
                                maxWidth: 550,
                            }}
                        >
                            <input
                                onChange={({ target: { value } }) =>
                                    setImei(value)
                                }
                                style={{
                                    border: 'none',
                                    borderBottom: '2px solid white',
                                    color: 'white',
                                    fontSize: 20,
                                    backgroundColor: 'transparent',
                                }}
                                type="text"
                                placeholder="Ingresa tu IMEI"
                            />
                        </div>
                    </div>
                    {errorFeedback ? (
                        <div style={{ display: 'flex' }}>
                            <span style={{ color: '#d54', margin: 'auto' }}>
                                {errorFeedback}
                            </span>
                        </div>
                    ) : null}
                    <div style={{ display: 'flex' }}>
                        <button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                border: 'none',
                                borderRadius: 15,
                                margin: '20px auto',
                                backgroundColor: '#FF8A21',
                                color: 'white',
                                padding: '5px 30px',
                                fontSize: 21,
                                cursor: 'pointer',
                            }}
                            onClick={checkImei}
                        >
                            Enviar
                        </button>
                    </div>
                    {loading ? <>Estamos verificando tu IMEI...</> : null}
                </>
            ) : isCompatible === 'compatible' ? (
                <div style={{ margin: '20px 0px' }}>
                    <div style={{ display: 'flex' }}>
                        <h1 style={{ margin: 'auto' }}>
                            ¡ f e l i c i d a d e s !
                        </h1>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <img style={{ margin: 'auto' }} src={Logo} alt="" />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <span style={{ margin: 'auto', fontSize: 20 }}>
                            Tu equipo{' '}
                            <span style={{ fontWeight: 'bold' }}>SI</span> es
                            compatible con la red
                        </span>
                    </div>
                </div>
            ) : (
                <div style={{ margin: '20px 0px' }}>
                    <div
                        style={{
                            padding: '5px 20px',
                            display: 'flex',
                            borderRadius: 8,
                            backgroundColor: '#FF8A21',
			    marginBottom: 30
                        }}
                    >
                        <span
                            style={{
                                padding: 3,
                                width: '100%',
                                display: 'flex',
                                borderBottom: '2px solid white',
                                color: 'white',
                            }}
                        >
                            <span style={{ margin: 'auto' }}>{imei}</span>
                        </span>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <h1 style={{ margin: 'auto' }}>Lo sentimos!</h1>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <img style={{ margin: 'auto' }} src={LogoGray} alt="" />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <span style={{ margin: 'auto', fontSize: 20 }}>
                            Tu equipo{' '}
                            <span style={{ fontWeight: 'bold' }}>NO</span> es
                            compatible con la red
                        </span>
                    </div>
                    {showContactForm ? (
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <div style={{ maxWidth: 400, margin: '20px auto' }}>
                                <div style={{ display: 'flex' }}>
                                    <input
                                        style={{ ...inputStyle, width: '50%' }}
                                        type="text"
                                        placeholder="Nombre"
                                        onChange={({ target: { value } }) =>
                                            setProspectName(value)
                                        }
                                    />
                                    <input
                                        style={{ ...inputStyle, width: '50%' }}
                                        type="text"
                                        placeholder="Apellido"
                                        onChange={({ target: { value } }) =>
                                            setLastName(value)
                                        }
                                    />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <input
                                        style={{ ...inputStyle, width: '100%' }}
                                        type="text"
                                        placeholder="Correo Electrónico"
                                        onChange={({ target: { value } }) =>
                                            setEmail(value)
                                        }
                                    />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <input
                                        style={{ ...inputStyle, width: '100%' }}
                                        type="text"
                                        placeholder="Número de teléfono"
                                        onChange={({ target: { value } }) =>
                                            setPhone(value)
                                        }
                                    />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <button
                                        style={{
                                            ...optionBtnStyle,
                                            backgroundColor: '#FF8A21',
                                            fontSize: 18,
                                            margin: 'auto',
                                            marginTop: 20,
                                            paddingLeft: 40,
                                            paddingRight: 40,
                                        }}
                                        onClick={postNotLoggedUserProspect}
                                    >
                                        Enviar
                                    </button>
                                </div>
                            </div>
                            {prospectFeedback ? (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        color:
                                            prospectFeedback.type === 'success'
                                                ? '#292'
                                                : prospectFeedback.type ===
                                                  'error'
                                                ? '#d43'
                                                : '#5A3571',
                                    }}
                                >
                                    {prospectFeedback.msg}
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <div
                                style={{
                                    display: 'flex',
                                    maxWidth: 600,
                                    margin: 'auto',
                                }}
                            >
                                {!session.logged ? (
                                    <>
                                        <button
                                            style={optionBtnStyle}
                                            onClick={() =>
                                                setShowContactForm(true)
                                            }
                                        >
                                            Obtener Solución
                                        </button>
                                        <div
                                            style={{ flex: 1, display: 'flex' }}
                                        >
                                            <div style={{ margin: 'auto' }}>
                                                o bien
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                <button
                                    onClick={() => {
                                        setIsCompatible(null)
                                        setShowContactForm(false)
                                        setImeiIsCompatible(true)
                                    }}
                                    style={optionBtnStyle}
                                >
                                    Intentar de nuevo
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
