import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {XEL_FONT} from "../../../../constant";
import NavBar from "../../../ui/NavBar";
import Footer from '../../../ui/Footer';

export const useLegalStyle = makeStyles({
    title: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 20,
        marginTop: 20,
        marginBottom: 15
    },
    subtitle: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 18,
        marginBottom: 5,
        marginTop: 10

    },
    body: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        marginTop: 5,
        textAlign: "justify",
        fontSize: 17
    }
});

export default function Privacidad() {
    const style = useLegalStyle();
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Aviso de privacidad";
    }, [])

    return <>
        <NavBar/>
        <Container>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={style.title} align={'center'}>
                        AVISO DE PRIVACIDAD INTEGRAL DE INXEL
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={style.subtitle}>
                        ABSOLUTETECK S.A. DE C.V.
                    </Typography>
                    <Typography className={style.body} variant="body2">
                        En cumplimiento de la Ley Federal de Protección de Datos Personales en Posesión de los
                        Particulares (laLey) y su Reglamento (reglamento), Absoluteteck S.A de C.V., (en lo subsecuente
                        INXEL), con domicilio paraoír y recibir documentos y notificaciones en calle Milwaukee No. 9,
                        Colonia Ampliación Nápoles, AlcandíaBenito Juárez, Ciudad de México, C.P. 03840, además de
                        ponerse a las órdenes del SUSCRIPTOR en lapágina web www.inxel.mx y en los Centros de Atención a
                        Suscriptores; en virtud de que la empresa esResponsable del Tratamiento de los Datos Personales
                        que recaba, pone a su disposición el presente Aviso dePrivacidad Integral (Aviso de Privacidad).
                        Y se explica que el tratamiento de sus Datos Personales se llevará acabo de conformidad con los
                        siguientes términos:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={style.subtitle}>
                        1. Los Datos Personales que solicita lNXEL.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        INXEL podrá recabar a través de sus vendedores o personal responsable los datos personales
                        siguientes:
                        <ol style={{listStyle: "none"}}>
                            <li>
                                a) Datos para identificar al SUSCRIPTOR: Nombre, firma autógrafa, datos de
                                identificación oficial, registrofederal de contribuyentes (RFC), fecha de nacimiento,
                                edad,
                                nacionalidad, fotografía e imagen.
                            </li>
                            <li>
                                b) Datos Biométricos del SUSCRIPTOR: para autenticar la
                                identidad de nuestros clientes, INXEL podrásolicitar la huella dactilar para identificar
                                plenamente al SUSCRIPTOR y las podrá almacenar en servidorespropiedad de INXEL, y
                                protegidas con
                                herramienta de encriptación.
                            </li>
                            <li>
                                c) Datos de contacto del SUSCRIPTOR: como lo son el comprobante de
                                domicilio, domicilio de entrega,correo electrónico, teléfono fijo y teléfono celular.
                            </li>
                            <li>
                                d) Datos
                                de referencias personales del SUSCRIPTOR: Información de personas conocidas y
                                autorizadaspreviamente por el SUSCRIPTOR.
                            </li>
                            <li>
                                e) Datos de Ubicación: Información que permite
                                identificar la ubicación del dispositivo electrónico a través degeolocalización, WiFi,
                                iBeacon y
                                a través de referencia y triangulación de radiobases.
                            </li>
                            <li>
                                f) Datos de la Red: Información sobre el
                                rendimiento y el uso de la red, y la información sobre la navegaciónpor Internet y
                                Aplicaciones
                                Móviles.
                            </li>
                        </ol>

                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography className={style.subtitle}>
                        2. Datos Financieros Personales del SUSCRIPTOR recabados por INXEL.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Hacemos de su conocimiento que para cumplir con las finalidades previstas en este Aviso de
                        Privacidad,serán recabados y tratados los siguientes Datos Personales Financieros:
                        <ol style={{listStyle: "none"}}>
                            <li>
                                a) Datos de tarjeta de crédito: Nombre del Titular de la tarjeta, número de tarjeta,
                                fecha de vencimiento ydomicilio donde se recibe el estado de cuenta.
                            </li>
                            <li>
                                b) Historial crediticio: Obtenido a través de consulta a Sociedades de Información
                                Crediticia, personas moralescon las que INXEL, celebre contrato de prestación de
                                servicios con cláusulas y medidas de protección deDatos Personales. Su Consentimiento al
                                Aviso de Privacidad INXEL, es importante por lo que INXEL pone atu disposición en sus
                                recepciones de atención al público, así como en su página web www.inxel.mx, elpresente
                                Aviso de Privacidad. Este Aviso de Privacidad rige el uso de nuestros productos y
                                servicios. Aunadoa lo anterior, al momento de contratar los servicios que presta INXEL,
                                y/o al momento de manifestar túvoluntad a través de la autenticación por medios
                                electrónicos o en su caso biométricos, reconoces que:
                                <ol>
                                    <li>
                                        Se te ha dado a conocer el presente Aviso de Privacidad;
                                    </li>
                                    <li>
                                        Entiendes y estás de acuerdo con los términos establecidos en éste Aviso de
                                        Privacidad; y
                                    </li>
                                    <li>
                                        Otorgas tu Consentimiento expreso para el Tratamiento de tus Datos Personales,
                                        DatosPersonales Sensibles, Datos Financieros y Datos Biométricos, conforme al
                                        mismo.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Si INXEL, requiere tratar tus Datos Personales Financieros, recabará tu Consentimiento expreso
                        mediante lacelebración del Contrato de Servicios correspondiente (el “Contrato”), ya sea de
                        forma impresa o por medioselectrónicos.De acuerdo con la Ley, INXEL, no requiere tu
                        Consentimiento para Remitir o Transferir tus Datos Personalesy/o Datos Personales Financieros en
                        los siguientes casos:
                        <ol style={{listStyle: "none"}}>
                            <li>
                                a) Cuando esté previsto en una ley o tratado en los que México sea parte,
                            </li>
                            <li>
                                b) Cuando la Transferencia o Remisión se realice hacia sus sociedades controladoras,
                                subsidiarias o afiliadasde INXEL, que operen bajo procesos y políticas de protección de
                                Datos Personales similares a las de INXEL,
                            </li>
                            <li>
                                c) Cuando sea necesario por virtud del cumplimiento de un contrato celebrado o por
                                celebrar en intereses delTitular por INXEL,
                            </li>
                            <li>
                                d) Cuando sea necesario o legalmente exigido para salvaguardar el interés público, o
                                para la procuración oadministración de justicia,
                            </li>
                            <li>
                                e) Cuando sea preciso para el reconocimiento, ejercicio o defensa de un derecho en un
                                proceso judicial,
                            </li>
                            <li>
                                f) Cuando sea preciso para el mantenimiento o cumplimiento de la relación jurídica entre
                                el Titular y INXEL,
                            </li>
                            <li>
                                g) Cuando los Datos Personales se sometan a un procedimiento previo de disociación,
                            </li>
                            <li>
                                h) Los Datos Personales figuren en fuentes de acceso público,
                            </li>
                            <li>
                                i) Exista una situación de emergencia que potencialmente pueda dañar a un individuo en
                                su persona o en susbienes,
                            </li>
                            <li>
                                j) Sean indispensables para la atención médica, la prevención, diagnóstico, la
                                prestación de asistenciasanitaria, tratamientos médicos o la gestión de servicios
                                sanitarios, mientras el titular no esté en condicionesde otorgar el consentimiento, en
                                los términos que establece la Ley General de Salud y demás disposicionesjurídicas
                                aplicables y que dicho tratamiento de datos se realice por una persona sujeta al secreto
                                profesional uobligación equivalente, o
                            </li>
                            <li>
                                k) Se dicte resolución de autoridad competente.
                            </li>
                        </ol>
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Podrás solicitar el ejercicio de tus Derechos de Acceso, Rectificación, Cancelación y Oposición
                        (“DerechosARCO”), así como la Revocación de tu Consentimiento de conformidad con los términos
                        descritos el formatosolicitud de derechos ARCO, del presente Aviso de Privacidad. Información
                        Adicional Sobre Remisiones yTransferencias tus Datos Personales y/o Datos Personales Financieros
                        podrán remitirse, transferirse oalmacenarse en México o en un país distinto para el Tratamiento
                        para los fines descritos a continuación, locual si éste fuera el caso, se llevará a cabo de
                        conformidad con todas las leyes aplicables.
                    </Typography>
                    <Typography className={style.subtitle}>
                        3. Finalidades de tus Datos Personales y/o Datos Personales Financieros.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Finalidades Primarias: Los Datos Personales y/o Datos Personales Financieros que recaba INXEL,
                        seránutilizados para cumplir con las obligaciones previstas en el Contrato y llevar a cabo la
                        prestación de losservicios correspondientes, específicamente:
                        <ol>
                            <li>
                                Para proporcionarte servicios de Atención al Cliente, ya sea por nuestros canales de
                                ventas o distribuidoresautorizados, incluyendo la facturación, presentación de equipos y
                                planes, registro y gestión de servicios yasean técnicos, de valor agregado y/o
                                contenido.
                            </li>
                            <li>
                                Para informarte sobre cambios en los servicios solicitados, planes comerciales, precios,
                                disponibilidad,facturación y condiciones de pago de los mismos.
                            </li>
                            <li>
                                Para llevar a cabo las actividades, trámites y gestiones necesarios para el
                                cumplimientode las obligaciones derivadas de la prestación de nuestros servicios.
                            </li>
                            <li>
                                Para verificar los datos de la tarjeta de crédito proporcionada para realizar el pago de
                                los serviciossolicitados.
                            </li>
                            <li>
                                Para identificar y autenticar plenamente al Titular que proporciona los Datos
                                Personales. Para mejorarnuestros productos y servicios.
                            </li>
                            <li>
                                Para llevar a cabo investigaciones y análisis para mantener, proteger, desarrollar y
                                mejorar nuestras redesy servicios.
                            </li>
                            <li>
                                Para prevenir el uso indebido de tu línea o equipo, así como para prevenir conductas
                                ilícitas.
                            </li>
                            <li>
                                Para cumplir con las obligaciones que las leyes aplicables imponen a INXEL, y sus
                                compañíascontroladoras, subsidiarias y afiliadas dentro y fuera del país.
                            </li>
                            <li>
                                Para actividades de cobranza.
                            </li>
                        </ol>
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Finalidades Secundarias: Al proporcionar tus Datos Personales y/o Datos Personales Financieros,
                        autorizasa INXEL, a tratarlos directamente o a través de su controladora, filiales,
                        subsidiarias, Encargados o Terceros,para fines mercadotécnicos, estadísticos, promocionales,
                        publicitarios, informativos o de prospección yencuestas de calidad. Adicionalmente, INXEL, podrá
                        tratar los Datos Personales y/o Datos PersonalesFinancieros para las siguientes finalidades:
                        <ol>
                            <li>
                                Para minería de datos.
                            </li>
                            <li>
                                Para estudios demográficos.
                            </li>
                            <li>
                                Para reportes internos y externos de marketing y analítica. Usamos información agregada,
                                anonimizada ydisociada para crear informes externos de marketing y análisis que podemos
                                llegar a vender a otrascompañías para su propio marketing, publicidad u otros usos
                                similares dentro y fuera del país. Estos informespueden ser una combinación de
                                información de ubicaciones inalámbricas y Wi-Fi, registros de llamadas ymensajes de
                                texto, navegación en el sitio web y uso de aplicaciones móviles.
                            </li>
                            <li>
                                Actividades de capacitación y entrenamiento.
                            </li>
                        </ol>
                    </Typography>
                    <Typography className={style.subtitle}>
                        4. Grabaciones e imagen personal en los Centros de atención.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Al ingresar a cualquiera de las instalaciones de INXEL, otorgas tu Consentimiento para ser video
                        grabado pornuestras cámaras de seguridad. Las imágenes que sean captadas por las cámaras serán
                        utilizadas para lassiguientes finalidades:
                        <ol>
                            <li>
                                Monitorear a las personas que nos visitan
                            </li>
                            <li>
                                Salvaguardar los bienes propiedad de INXEL.
                            </li>
                            <li>
                                Proporcionar video y audio a autoridades competentes, previo requerimiento legal fundado
                                y motivado.
                            </li>
                            <li>
                                Proporcionarte servicios de Atención al Cliente.
                            </li>
                        </ol>
                    </Typography>
                    <Typography className={style.subtitle}>
                        5. Proceso para recabar tus Datos Personales y Datos Personales Financieros.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Los Datos Personales y/o Datos Personales Financieros que recaba INXEL, podrán ser obtenidos de
                        manerapersonal, electrónica, a través de Fuentes de Acceso Público, o a través de nuestras
                        empresas controladoras,filiales, subsidiarias y Encargados autorizados, a través de los
                        siguientes mecanismos:
                        <ol>
                            <li>
                                Cuando solicitas nuestros servicios a través de nuestros agentes de ventas, o cuando
                                ellos te los ofrecen.
                            </li>
                            <li>
                                Cuando acudes a nuestros Centros de Atención y Puntos de Venta Autorizados.
                            </li>
                            <li>
                                A través del intercambio de correos electrónicos o llamadas con personal de INXEL.
                            </li>
                            <li>
                                Cuando nos solicitas una actualización o modificación a tus Datos Personales y/o Datos
                                Personales Financieros previamente registrados.
                            </li>
                            <li>
                                Cuando visitas sitios web, y redes sociales de INXEL.
                            </li>
                            <li>
                                Por el registro de control de acceso a nuestras instalaciones.
                            </li>
                            <li>
                                A través de Encargados y Terceros.
                            </li>
                            <li>
                                A través de Clientes que proporcionan tus Datos Personales como información de contacto.
                            </li>
                            <li>
                                Cuando accedes a través de tu dispositivo electrónico a cualquier sitio web o a
                                cualquier aplicación, podemos recabar la identificación única del dispositivo
                                electrónico, la dirección IP, la información del sistema operativo del dispositivo
                                electrónico, el operador de telefonía móvil y los datos de tu ubicación. Así mismo
                                podemos recolectar información de preferencias o intereses de los Usuarios al acceder en
                                nuestros sitios web y Aplicaciones Móviles.
                            </li>
                            <li>
                                A través de la tecnología Cookies, las cuales recopilan información con el fin de
                                personalizar los anuncios que aparecen en nuestros sitios y en sitios propiedad de otras
                                compañías
                            </li>
                            <li>
                                Así mismo, a través de la tecnología denominada Web Beacons, podemos recabar un registro
                                de las páginas y los anuncios que accedes, o manejar el seguimiento de una campaña de
                                mercadeo.
                            </li>
                            <li>
                                Servicios Basados en Localización (LBS) serán utilizados para obtener la localización
                                del dispositivo electrónico, necesario para la operación de las aplicaciones que tú
                                vayas descargando. Por lo tanto, es posible, que otras compañías ajenas a INXEL, puedan
                                localizar tu dispositivo electrónico, como el fabricante del dispositivo electrónico o
                                del sistema operativo.
                            </li>
                            <li>
                                Cuando se solicita una consulta de historial crediticio ante sociedades de información
                                crediticia, y
                            </li>
                            <li>
                                Al momento de realizar una consulta ante cualquier Fuente de Acceso Público.
                            </li>
                        </ol>

                        Te informamos que otras compañías ajenas a INXEL, pueden tener acceso a tu dispositivo, como las
                        Aplicaciones Móviles que descargas, éstas pueden recolectar información y/o localizar tu
                        dispositivo.
                        <br/><br/>
                        Por ello, INXEL, te recomienda que revises los avisos de privacidad y los términos y condiciones
                        de éstas compañías. Asimismo, INXEL, hace de tu conocimiento que conforme al Título Octavo de la
                        Ley Federal de Telecomunicaciones y Radiodifusión y los Lineamientos de Colaboración en materia
                        de Seguridad y Justicia, se encuentra obligada a registrar y conservar información relacionada
                        con el uso de nuestros productos y servicios.
                    </Typography>
                    <Typography className={style.subtitle}>
                        6. Transferencia de tus Datos Personales y Datos Personales Financieros.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Te informamos que tus Datos Personales y/o Datos Personales Financieros podrán ser compartidos
                        dentro y fuera del país, sin tu Consentimiento expreso de acuerdo a la Ley, con las siguientes
                        personas morales, organizaciones o autoridades con las finalidades previamente descritas en este
                        Aviso de Privacidad:
                        <ol>
                            <li>
                                Empresas Controladoras, Filiales y Subsidiarias de INXEL, mismas que operan bajo
                                procesos y políticas de privacidad similares.
                            </li>
                            <li>
                                Encargados, a través de la celebración de contratos con cláusulas de protección de datos
                                y confidencialidad de la información.
                            </li>
                            <li>
                                Terceros, a través de la celebración de contratos con cláusulas de protección de datos y
                                confidencialidad de la información.
                            </li>
                            <li>
                                Autoridades gubernamentales, en caso de ser debidamente solicitados a INXEL, y sus
                                compañías Controladoras, Subsidiarias o Afiliadas, de conformidad con la legislación
                                aplicable.
                            </li>
                        </ol>
                    </Typography>
                    <Typography className={style.subtitle}>
                        7. Derechos ARCO (Acceso, Rectificación, Cancelación, y Oposición).
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Te hacemos conocer tus derechos ARCO. Tienes el derecho a:
                        <ul style={{listStyle: 'none'}}>
                            <li>
                                <strong> Acceso:</strong> Es tu derecho a conocer cuales Datos Personales y/o Datos
                                Personales Financieros obran en nuestras bases de datos y para qué fin son tratados por
                                INXEL, sus empresas Controladoras, Afiliados, y Subsidiarias, Encargados y Terceros.
                            </li>
                            <li>
                                <strong>Rectificación:</strong> Es tu derecho poder solicitar la corrección de tus Datos
                                Personales y/o Datos Personales Financieros, en caso de que estén desactualizados, sean
                                inexactos o incompletos.
                            </li>
                            <li>
                                <strong> Cancelación:</strong> Es tu derecho para que los mismos sean eliminados de
                                nuestros registros o bases de datos cuando consideres que no están siendo utilizados
                                adecuadamente, siempre y cuando no exista una obligación vigente con INXEL.
                            </li>
                            <li>
                                <strong> Oposición:</strong> Puedes oponerte al uso de tus Datos Personales y/o Datos
                                Personales Financieros para fines específicos.
                            </li>
                        </ul>
                    </Typography>
                    <Typography className={style.subtitle}>
                        8. Revocación del Consentimiento.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Tienes el derecho de revocar tu Consentimiento para fines mercadotécnicos, estadísticos,
                        publicidad, promocionales, informativos o de prospección y encuestas de calidad. Toma en cuenta
                        por favor que la Revocación de tu Consentimiento no puede tener efectos retroactivos.
                    </Typography>
                    <Typography className={style.subtitle}>
                        9. Solicitud de derechos ARCO.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Cualquier Titular o su Representante Legal podrán ejercer sus Derechos ARCO (Acceso,
                        Rectificación, Cancelación y Oposición). Así mismo podrás ejercer su derecho a revocar el
                        Consentimiento para el Tratamiento de tus Datos Personales y/o Datos Personales Financieros
                        (siempre y cuando no existan derechos vigentes en favor de INXEL, o una disposición legal que lo
                        impida).
                        <br/>
                        Para ello INXEL, te ofrece tres sencillas opciones:
                        <ol>
                            <li>
                                Acudir a nuestros Centros de Atención a Suscriptores, para ser atendido por uno de
                                nuestros ejecutivos.
                            </li>
                            <li>
                                Llamar a nuestro departamento de Atención a Suscriptores, las 24 horas los 365 días del
                                año, a los siguientes teléfonos:
                                <br/>
                                Desde tu móvil: *935 sin costo.
                                <br/>
                                Interior de la República: 55 8840 9522.
                            </li>
                            <li>
                                Con la Oficina de Protección de Datos de INXEL, para ello deberán cumplir con el
                                siguiente procedimiento: Descarga tu Solicitud de Ejercicio de Derechos ARCO o
                                Revocación del Consentimiento que se encuentra en el portal <a
                                href="https://www.inxel.mx" target={'_blank'}>www.inxel.mx</a>, completarla y
                                enviarla en formato digital con firma autógrafa del Titular vía correo electrónico a la
                                siguiente dirección: <a
                                href="mailto:xel.legal1@inxel.mx">xel.legal1@inxel.mx</a> proporcionando tu número de
                                cuenta en caso de
                                contar con esta, y adjuntando la documentación que acredite tu identidad como Titular de
                                los Datos Personales y/o Datos Personales Financieros (copia simple en formato
                                electrónico de tu credencial de elector, pasaporte vigente, cédula profesional,
                                documento de identificación migratoria en caso de personas extranjeras) o, en su caso,
                                acreditar la representación legal del Titular con copia simple en formato electrónico de
                                la carta poder que deberá contar con firma autógrafa del Titular y/o el Representante
                                Legal, adjuntando copias simples de sus identificaciones oficiales.
                                <br/>
                                El medio por el cual INXEL, te comunicará la respuesta de tu Solicitud de Ejercicio de
                                Derechos ARCO o Revocación de Consentimiento, será la dirección del correo electrónico
                                por medio de la cual enviaste inicialmente tu Solicitud.
                                <br/>
                                Si no cuentas con correo electrónico, favor de enviarnos tu Solicitud vía correo postal,
                                al domicilio ubicado en: Calle Milwaukee 9, Colonia Ampliación Nápoles, Alcaldía Benito
                                Juárez, Ciudad de México, C.P. 03840.
                                <br/>
                                Por favor indícanos el domicilio al que se te deba de enviar la contestación vía correo
                                postal o por servicio de mensajería exprés. Una vez que dicha Solicitud sea recibida por
                                la Oficina de Protección de Datos Personales de INXEL, se te comunicará sobre la
                                procedencia o improcedencia de tu Solicitud en un plazo no mayor a 20 (veinte) días
                                hábiles, contados desde la fecha en que se recibió tu Solicitud de Ejercicio de Derechos
                                ARCO o Revocación del Consentimiento.
                                <br/>
                                En caso de que tu Solicitud resulte procedente, INXEL, tendrá un plazo de 15 (quince)
                                días hábiles adicionales para hacerla efectiva. Cuando así lo justifiquen las
                                circunstancias, los plazos anteriores podrán ser ampliados por una sola ocasión por un
                                período igual. En el caso de que la información proporcionada en la Solicitud sea
                                insuficiente o errónea para ser atendida, INXEL, dentro de los 5 (cinco) días hábiles
                                siguientes a la recepción de la Solicitud, podrá solicitarte que aportes información o
                                documentación adicional para acreditar tu Solicitud y contarás con 10 (diez) días
                                hábiles para atender el requerimiento, contados a partir del día siguiente en que hayas
                                sido notificado.
                                <br/>
                                De no dar respuesta en dicho plazo, la Oficina de Protección de Datos Personales de
                                INXEL, podrá negar al solicitante el ejercicio de cualquiera de los Derechos ARCO o la
                                Revocación del Consentimiento, por no acreditar la titularidad de los Datos Personales,
                                o por cualquier otra circunstancia de Ley.
                                <br/>
                                En caso de que el Titular atienda el requerimiento de información, el plazo para que
                                INXEL, dé respuesta a la Solicitud, empezará a correr al día siguiente hábil a aquel en
                                que el Titular haya atendido el requerimiento.
                                <br/>
                            </li>
                        </ol>
                    </Typography>
                    <Typography className={style.subtitle}>
                        10. Administración de Cookies.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        La mayoría de los navegadores te permiten borrar, desactivar y elegir qué Cookies se pueden
                        colocar en tu dispositivo electrónico. Si deseas más información sobre el control de Cookies,
                        visita el sitio de tu navegador. A continuación, podrás encontrar las ligas electrónicas
                        correspondientes a algunos de los navegadores más utilizados de Internet, en donde podrás
                        administrar y deshabilitar el uso de las tecnologías Cookies:
                        <ol>
                            <li>
                                Chrome: <a href="https://support.google.com/chrome/answear/95647?hl=es=-49"
                                           target="_blank">https://support.google.com/chrome/answear/95647?hl=es=-49</a>
                            </li>
                            <li>
                                Mozilla: <a
                                href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en"
                                target="_blank">https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en</a>
                            </li>
                            <li>
                                Microsoft: <a
                                href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11"
                                target="_blank">http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11</a>
                            </li>
                            <li>
                                Safari: <a href="https://support.apple.com/kb/PH21411?viewlocale=es_MX&locale=en_US"
                                           target="_blank">https://support.apple.com/kb/PH21411?viewlocale=es_MX&locale=en_US</a>
                            </li>
                        </ol>
                    </Typography>
                    <Typography className={style.subtitle}>
                        11. Protección de tus Accesos y Dispositivos Electrónicos.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Al usar nuestros sitios web o aplicaciones tú diseñas tu propio nombre de usuario y/o contraseña
                        o número de identificación personal (PIN o Master PIN), por tanto, serás responsable de tu
                        confidencialidad, pues se trata de información personal e intransferible cuyo resguardo está a
                        tu cargo; por lo que te sugerimos no divulgarla.
                        <br/>Si compartes tu dispositivo electrónico, procura cerrar siempre la sesión antes de salir de
                        un sitio o aplicación a fin de evitar accesos indebidos por parte de terceros.

                    </Typography>
                    <Typography className={style.subtitle}>
                        12. Limitar el Uso de los Datos Personales y/o Datos Personales Financieros.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        A través del Registro Público para Evitar Publicidad de PROFECO (REPEP) Como Titular, tienes
                        derecho a limitar el uso de tus Datos Personales y/o Datos Personales Financieros. Para ello,
                        INXEL, te informa que podrás inscribirte en el Registro Público para Evitar Publicidad (REPEP),
                        que está a cargo de la Procuraduría Federal del Consumidor (PROFECO), con la finalidad de que
                        tus Datos Personales, no sean utilizados para recibir publicidad y marketing.
                        <br/>
                        Para mayor información sobre éste registro, puedes consultar el portal de Internet del REPEP de
                        PROFECO ingresando a <a href="https://www.repep.profeco.gob.mx"
                                                target="_blank">www.repep.profeco.gob.mx</a>o bien acudir personalmente
                        a PROFECO.
                    </Typography>

                    <Typography className={style.subtitle}>
                        13.Contacto a la Oficina de Protección de Datos Personales de INXEL.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        Si tienes alguna pregunta o inquietud acerca de cómo INXEL, le da Tratamiento y protege tus
                        Datos Personales y/o tus Datos Personales Financieros, o si tienes alguna solicitud adicional,
                        ponte en contacto con la Oficina de Protección de Datos Personales de INXEL, a la dirección
                        electrónica <a href="mailto:xel.legal1@inxel.mx">xel.legal1@inxel.mx</a>.
                    </Typography>
                    <Typography className={style.subtitle}>
                        14. Cambios al presente Aviso de Privacidad.
                    </Typography>
                    <Typography className={style.body} variant="body2" align="justify">
                        El presente Aviso de Privacidad puede ser modificado, cambiado o actualizado derivado a nuevos
                        requerimientos legales, o de nuestras propias necesidades por los productos y servicios que te
                        ofrecemos, para cumplir con las mejores prácticas, cambios de tecnología y por cualquier cambio
                        en nuestro modelo de negocio. En este sentido, te notificamos que el procedimiento por el cual
                        INXEL, comunicará cambios en el presente Aviso de Privacidad, será a través de la publicación en
                        su página web <a href="https://www.inxel.mx">www.inxel.mx</a> ingresando para ello a su liga
                        electrónica identificada como “Aviso
                        de Privacidad” por lo que se te recomienda visitar de manera continua dicha página y liga
                        electrónica.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
        <Footer/>
    </>
}
