import React, {useEffect} from "react";
import {Button, Grid, useMediaQuery, withStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {XEL_COLORS, XEL_FONT} from "../../../constant";
import Typography from "@material-ui/core/Typography";
import NavBar from "../../ui/NavBar";
import Footer from '../../ui/Footer';
import CheckIcon from '@material-ui/icons/Check';
import Cell from '../../../assets/images/app/Cell2x.png';
import {useResponsiveValues} from "../../utils/hooks";
import Head from '../../../assets/images/app/head.png';
import Titulo from "./Titulo";
import Banner from '../../../assets/animaciones/Baja la App/Carrusel Site-01.png';
import BannerP from '../../../assets/animaciones/Baja la App/Carrusel Site-01_Pan.png';
import Telefono from '../../../assets/logos/pwa/Animacion.gif';
import inXel from '../../../assets/logos/pwa/inxel.svg';
import Card from "@material-ui/core/Card";

const useStyles = makeStyles({
    text: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        color: XEL_COLORS.primary,
    },
    container: {
        marginTop: 30
    },
    li_container: {
        marginTop: 30
    },
    li: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center'
    },
    text_li: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        color: XEL_COLORS.primary,
    }
});


export default function AppXel() {
    const style = useStyles();
    const noCel = useMediaQuery('(min-width:426px)');
    const noTablet = useMediaQuery('(min-width:769px)');
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - App";
    }, [])
    return <Grid container>
        <NavBar/>
        <Grid container >
            <Grid item xs={12} style={{width: '100%'}}>
                <img src={useResponsiveValues(BannerP,BannerP,Banner)} alt="App" style={{ width: '100%', height: '100%'}}/>
            </Grid>
        </Grid>
        <Titulo/>
        <Container style={{marginTop: useResponsiveValues(90, 60, 30),marginBottom:10}}>
            <Grid container justify={'center'} alignItems={'center'}>
                <Grid item xs={6} md={6}
                      style={{position:"relative",display: 'flex', justifyContent: "center",marginBottom:15}}>
                    <img style={{maxWidth: 330, maxHeight: 615, width: '100%', height: '100%'}} src={Telefono}
                         alt="Phones"/>
                    {
                        /*<div style={{zIndex:2,position:"absolute",height:'100%',width:'100%',display:"flex",justifyContent:"center",alignItems:"center"}}>
                             <img src={inXel} alt="inXel"/>
                         </div>*/
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Card style={{padding:'2%'}}>
                            <Grid item xs={12} className={style.li_container}>
                                <ul>
                                    <li className={style.li}>
                                        <CheckIcon fontSize={'20'} color={'secondary'}/>
                                        <Typography className={style.text_li}
                                                    style={{fontSize: !noCel ? 15 : !noTablet ? 20 : 24}}>
                                            Revisar tu consumo
                                        </Typography>
                                    </li>
                                    <li className={style.li}>
                                        <CheckIcon fontSize={'20'} color={'secondary'}/>
                                        <Typography className={style.text_li}
                                                    style={{fontSize: !noCel ? 15 : !noTablet ? 20 : 24}}>
                                            Promociones activas
                                        </Typography>
                                    </li>
                                    <li className={style.li}>
                                        <CheckIcon fontSize={'20'} color={'secondary'}/>
                                        <Typography className={style.text_li}
                                                    style={{fontSize: !noCel ? 15 : !noTablet ? 20 : 24}}>
                                            Características de tu plan o prepago
                                        </Typography>
                                    </li>
                                    <li className={style.li}>
                                        <CheckIcon fontSize={'20'} color={'secondary'}/>
                                        <Typography className={style.text_li}
                                                    style={{fontSize: !noCel ? 15 : !noTablet ? 20 : 24}}>
                                            Saldo y recargas
                                        </Typography>
                                    </li>
                                    <li className={style.li}>
                                        <CheckIcon fontSize={'20'} color={'secondary'}/>
                                        <Typography className={style.text_li}
                                                    style={{fontSize: !noCel ? 15 : !noTablet ? 20 : 24}}>
                                            Pagar tu cuenta o la de un amigo
                                        </Typography>
                                    </li>
                                    <li className={style.li}>
                                        <CheckIcon fontSize={'20'} color={'secondary'}/>
                                        <Typography className={style.text_li}
                                                    style={{fontSize: !noCel ? 15 : !noTablet ? 20 : 24}}>
                                            Chat con inXel para todas tus dudas
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12} style={{display: "flex", justifyContent: "center", marginTop: '4%'}}>
                                <Button onClick={()=>{
                                    window.open(
                                        "https://cliente.inxel.mx", "_blank");
                                }} color='secondary' variant='contained' style={{color: '#FEFEFE'}}>
                                    Instalar
                                </Button>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>


            </Grid>
        </Container>
        <Footer/>
    </Grid>
}
