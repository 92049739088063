import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider, useSelector} from "react-redux";
import myStore from "./reducers";
import {BrowserRouter as Router} from 'react-router-dom';
import {createMuiTheme, responsiveFontSizes} from "@material-ui/core";
import {XEL_COLORS, XEL_FONT} from "./constant";
import {ThemeProvider} from "@material-ui/styles";
import red from "@material-ui/core/colors/red";
import Loading from "./components/ui/Loading";
import HttpsRedirect from 'react-https-redirect';

let inXelTheme = createMuiTheme({
    palette: {
        primary: {
            main: XEL_COLORS.primary
        },
        secondary: {
            main: XEL_COLORS.secondary
        },
        danger: {
            main: red[700]
        },
    },
    typography: {
        fontFamily: XEL_FONT.name,
        h1: {
            fontWeight: XEL_FONT.bold_weight,
         //   fontSize: 40,
        },
        h2: {
            fontWeight: XEL_FONT.semi_bold_weight,
       //     fontSize: 33
        },
        h3: {
        //    fontWeight: XEL_FONT.semi_bold_weight,
            fontWeight:XEL_FONT.bold_weight,
            fontFamily:XEL_FONT.name
         //   fontSize: 30,

        },
        h4: {
            fontWeight:XEL_FONT.bold_weight,
          //  fontSize: 27
        },
        h5: {
            fontWeight: XEL_FONT.semi_bold_weight,
           // fontSize: 24
        },
        h6: {
            fontWeight: XEL_FONT.semi_bold_weight,
          //  fontSize: 21
        },

        body1: {
            fontWeight: XEL_FONT.regular_weight,
            fontFamily:XEL_FONT.name,
       //     fontSize: 13
        },
        body2: {
            fontWeight: XEL_FONT.regular_weight,
       //     fontSize: 9
        },
        button: {
            fontWeight: XEL_FONT.regular_weight,
            textTransform: 'none',
         //   fontSize: 18,
        }
    }
});
inXelTheme = responsiveFontSizes(inXelTheme, {
    breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    factor:6
});

const Load = () => {
    const loading = useSelector(state => state.loading);
    return <>
        {
            loading ? <Loading/> : ''
        }
    </>
}
ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={inXelTheme}>
            <Provider store={myStore}>
                <Router>
                    <Load/>
                    <App/>
                </Router>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
