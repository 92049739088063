import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {XEL_COLORS, XEL_FONT} from "../../../../../../../../constant";
import Button from "@material-ui/core/Button";
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles({
    iddle_border: {
        border: '1px solid #BBBBBB'
    },
    selected_border: {
        border: '1px solid #FF8A21'
    },
    container: {
        borderRadius: 10,
        padding: '2%',
        position: 'relative'
    },
    border_button: {
        borderRadius: '50%',
        height: 35,
        width: 35,
        padding: '10%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    circle_button: {
        height: '100%',
        width: '100%',
        borderRadius: '50%',
        backgroundColor: '#FEFEFE'
    },
    selected_circle_button: {
        backgroundColor: '#FF8A21'
    }
    ,
    center_c: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    hotspot: {
        position: 'absolute',
        top: '-15%',
        right: '-3%',
        //  bottom: '-5.2%',
        background: XEL_COLORS.secondary,
        padding: 1,
        borderRadius: '50%',
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    hotspot_sel: {
        background: XEL_COLORS.primary
    }
});
export default function Item({selected, data, onClick, onContinue, mensual, setHasHotspot}) {
    const styles = useStyles();
    const isSelected = !selected ? false : selected;
    const history = useHistory();
    const [isHover, setIsHover] = useState(false);
    useEffect(() => {
    }, []);
    return <Grid style={{position: 'relative', cursor: "pointer",marginTop:"2%"}}
                 container
                 onMouseEnter={() => {
                     setIsHover(true)
                 }}
                 onClick={() => {
		     console.log( 'click, data:', data )
		    setHasHotspot(data.hotspot === 1)
                     if (onClick)
                         onClick();
                 }}
                 onMouseLeave={() => {
                     setIsHover(false);
                 }}
                 justify={'center'}
    >

        <Grid item xs={12}
              className={`${isSelected ? styles.selected_border : styles.iddle_border} ${styles.container}`}>

            <Grid container justify={'center'}>
                {
                    data.hotspot === 1 ? <div className={`${styles.hotspot} ${isSelected ? styles.hotspot_sel : ""}`}>
                        <Typography style={{fontSize: 7, color: "#FEFEFE"}} align={'center'}>
                            Hotspot habilitado
                        </Typography>
                    </div> : ""
                }
                <Grid item xs={3}
                      className={styles.center_c}
                    //      className={`${isSelected ? styles.selected_border : styles.iddle_border} ${styles.b}`}
                >
                    <div
                        className={`${isSelected || isHover ? styles.selected_border : styles.iddle_border} ${styles.border_button}`}>
                        <span
                            className={`${isSelected || isHover ? styles.selected_border : styles.iddle_border} ${styles.circle_button} ${isSelected || isHover ? styles.selected_circle_button : ""}`}
                        >
                        </span>
                    </div>
                </Grid>
                <Grid item xs={7}>
                    <Grid container alignItems={'center'} style={{height: '100%'}}>
                        <Grid item xs={12}>
                            <Typography style={{fontWeight: XEL_FONT.bold_weight, fontSize: 12}}>
                                {data.name}

                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontWeight: XEL_FONT.bold_weight, fontSize: 10}}>
                                {data.public_description}
                                {
                                    data.mensual && `Pagar antes del ${data.lim < 10 ? `0${data.lim}` : data.lim} de cada mes`
                                }
                            </Typography>
                        </Grid>
                        {
                            data.local_minutes ? <Grid item xs={12}>
                                <Typography style={{fontWeight: XEL_FONT.bold_weight, fontSize: 10}}>
                                    {data.local_minutes} Minutos
                                </Typography>
                            </Grid> : ""
                        }
                        {
                            data.roaming_minutes ? <Grid item xs={12}>
                                <Typography style={{fontSize: 10}}>
                                    {data.roaming_minutes} Minutos en roaming internacional
                                </Typography>
                            </Grid> : ""
                        }
                        {
                            data.local_sms ? <Grid item xs={12}>
                                <Typography style={{fontWeight: XEL_FONT.bold_weight, fontSize: 10}}>
                                    {data.local_sms} SMS
                                </Typography>
                            </Grid> : ""
                        }
                        {
                            data.roaming_sms ? <Grid item xs={12}>
                                <Typography style={{fontSize: 10}}>
                                    {data.roaming_sms} SMS en roaming internacional
                                </Typography>
                            </Grid> : ""
                        }
                        {
                            data.type === "preplan" && <Typography variant={"caption"} style={{
                                fontSize: 9,
                                fontWeight: XEL_FONT.bold_weight,
                                marginTop: "2%"
                            }}>
                                *El servicio se renueva mensualmente a partir de tu fecha de contratación.
                            </Typography>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={2}
                      className={styles.center_c}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            {
                                parseInt(data.price) !== parseInt(data.price_original) ?
                                    <Typography align={'center'}
                                                style={{
                                                    fontSize: 11,
                                                    fontWeight: XEL_FONT.bold_weight,
                                                    textDecoration: "line-through " + XEL_COLORS.secondary + ' 2px'
                                                }}>
                                        ${data.price_original}
                                    </Typography> : ""
                            }
                            <Typography align={'center'} style={{fontSize: 12, fontWeight: XEL_FONT.bold_weight}}>
                                ${data.price}
                            </Typography>
                            <Typography align={'center'} style={{fontSize: 10}}>
                                {
                                    !data.mensual && (data.type === "preplan" ? `${data.service_months} Meses` : `${data.dias} dias`)
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {
            false && isSelected ? <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                <Button onClick={() => {
                    onContinue();
                }} style={{fontSize: 12, marginTop: '3%'}} variant={'contained'} color={'primary'}>
                    Continuar
                </Button>
            </Grid> : ""
        }
    </Grid>
}
