import React, {useState} from "react";
import {Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography} from "@material-ui/core";
import Alerts from "../../../../../ui/Alert";
import Axios from "../../../../../../instances/axios";
import {useSelector} from "react-redux";
import {Alert} from "@material-ui/lab";
import { XEL_COLORS } from "../../../../../../constant";

const { primary2, primary_shiny } = XEL_COLORS

const AddNumber = ({msisdns, addData, justAdd, addFrecuent, onSelect}) => {
    console.log( 'msisdns:', msisdns )
    const axios = Axios();
    const [error, setError] = useState({
        open: false,
        msg: "",
        severity: "error"
    });
    const session = useSelector(state => state.login);
    const [loading, setLoading] = useState(false);
    const [save, setSave] = useState(false);

    const [params, setParams] = useState({
        number: "",
        confirm: ""
    });
    const passAdd = (tipo) => {
        if (save || justAdd) {
            axios.post("/clients/frecuents/", {
                msisdn: params.number,
                tipo,
            }).then(ress => {
                console.log("ERdf", ress.data);
                if (addFrecuent)
                    addFrecuent({msisdn: params.number, tipo});
                if (!justAdd)
                    onSelect(params.number, tipo);
            }).catch(err => {
                if (!justAdd)
                    onSelect(params.number, tipo);
                console.log(err);
            })
        } else
            onSelect(params.number, tipo);
    }
    const handleChange = ({target: {value, name}}) => {

        let val = value.toString().split(' ').join('');

        if (isNaN(+val))
            return;
        if (val.length > 10)
            return;
        if (error.open)
            setError(prevState => ({
                ...prevState,
                open: false
            }));
        setParams({
            ...params,
            [name]: val
        });
    }

    const add = () => {
        const {number, confirm} = params;

	if (number.length !== 10 || confirm.length !== 10) {
            return setError({
                open: true,
                msg: "El número debe tener 10 dígitos",
                severity: "error"
            });
	}
        if (number !== confirm) {
            setError({
                open: true,
                msg: "Los números no coinciden",
                severity: "error"
            });
            return;
        }

        setLoading(true);
        axios.get(`/clients/pays/${number}?lower=true`).then(res => {
            let data = res.data;
            setLoading(false);
            if (data.error) {
		console.log( 'pays error:', data )
                setError({
                    open: true,
                    msg: data.error_description,
                    severity: "error"
                });
                return;
            }
            setParams({
                number: "",
                confirm: ""
            });

            //console.log(data.tipo);
            console.log(data)

            /* if (!justAdd) {
                 if (data.postpago && data.planes.length > 0 && data.saldo === 0) {
                     setError({
                         open: true,
                         msg: "No tienes deuda pendiente.",
                         severity: "success"
                     });
                     setParams({
                         number: "",
                         confirm: ""
                     });
                     return;
                 }
                 addData(number, data);
             }*/
	    console.log( 'type!!!!!!!!!!!!!!!!!!!!!', data )
            passAdd(data.tipo);

        }).catch(err => {
            console.log(err);
            setLoading(false);
            setError({
                open: true,
                msg: "Hubo un error al intentar conectarse con el servidor",
                severity: "error"
            });
        });

    }
    return <Grid container spacing={1} alignItems={"center"} justify={"center"}
                 style={{backgroundColor: "#FEFEFE", padding: 3}}>
        <Grid item xs={11}>
            <TextField disabled={loading} name={"number"} value={params.number} onChange={handleChange} fullWidth
                       label={"Número inXel"}/>
        </Grid>
        <Grid item xs={11}>
            <TextField disabled={loading} name={"confirm"} value={params.confirm} onChange={handleChange} fullWidth
                       label={"Confirmar número"}/>
        </Grid>
        {
            error.open && <Grid item xs={11}>
                <Alert onClose={() => setError(prevState => {
                    return {...prevState, open: false}
                })} severity={"error"}>
                    <Typography>
                        {
                            error.msg
                        }
                    </Typography>
                </Alert>
            </Grid>
        }
        {
            !justAdd && session.logged && <Grid item xs={11}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox disabled={loading} checked={save} onClick={() => {
                        setSave(!save);
                    }}/>} label="Guardar como número frecuente"/>
                </FormGroup>
            </Grid>
        }
        <Grid item xs={12} md={4} style={{display: "flex", justifyContent: "center"}}>
            <Button disabled={loading} onClick={add} variant={"contained"} color={"primary"}
                   style={{
			background: 
			    `linear-gradient(${primary2}, ${ primary_shiny })`,
			color: 'white',
		    }} >
                Agregar
            </Button>
        </Grid>

        {
            // <Alerts open={error} onClose={() => {
            //             setError({
            //                 ...error,
            //                 open: false
            //             })
            //         }}/>
        }
    </Grid>
}

export default AddNumber;
