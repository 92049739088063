import React, {useEffect} from "react";
import {Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {XEL_FONT} from "../../../constant";
import Typography from "@material-ui/core/Typography";
import NavBar from "../../ui/NavBar";
import Footer from '../../ui/Footer';
import LoginForm from "../../ui/LoginForm";
const useStyles = makeStyles({
    title: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 28
    },
    top_container: {
        marginTop: 35,
        marginBottom:30
    }
})

export default function Login({dir}) {
    const style = useStyles();
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Iniciar sesión";
    }, [])
    return <>
        <NavBar/>
        <Container className={style.top_container}>
            <Grid container >
                <Grid item xs={12}>
                    <LoginForm dir={dir}/>
                </Grid>
            </Grid>
        </Container>
        <Footer/>
    </>
}
