import React from "react";

export const XelIconLink = (props) => {
    return <svg viewBox="0 0 19.52 19.65" version={'1.0'}  {...props} >
        <rect className="cls-1" x="0.61" y="6.48" width="4.15" height="13.17" rx="0.66"/>
        <path className="cls-1" d="M2.68,5.37A2.69,2.69,0,1,1,5.37,2.68,2.68,2.68,0,0,1,2.68,5.37Z"/>
        <path className="cls-1"
              d="M19.52,19a.61.61,0,0,1-.61.61h-3a.61.61,0,0,1-.61-.61V13.49c0-.83.24-3.63-2.17-3.63-1.86,0-2.24,1.92-2.32,2.78V19a.61.61,0,0,1-.61.61H7.24A.61.61,0,0,1,6.63,19V7.09a.61.61,0,0,1,.61-.61h2.93a.61.61,0,0,1,.61.61v1A4.16,4.16,0,0,1,14.7,6.28c4.85,0,4.82,4.54,4.82,7V19Z"/>
    </svg>
}

export const XelIconPhoneAlone = (props) => {
    return <svg viewBox="0 0 11.24 17.99" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M9.56,0H1.68A1.69,1.69,0,0,0,0,1.69V16.31A1.69,1.69,0,0,0,1.68,18H9.56a1.69,1.69,0,0,0,1.68-1.68V1.69A1.69,1.69,0,0,0,9.56,0ZM4.5,1.12H6.75v.57H4.5ZM5.62,16.87A.85.85,0,1,1,6.46,16,.85.85,0,0,1,5.62,16.87Zm4.5-3.94A1.13,1.13,0,0,1,9,14.06H2.25a1.14,1.14,0,0,1-1.13-1.13v-9A1.14,1.14,0,0,1,2.25,2.81H9a1.13,1.13,0,0,1,1.12,1.13Z"/>
    </svg>
}

export const XelIconEMail = (props) => {
    return <svg viewBox="0 0 18.86 11.78" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M17.09,0H1.77A1.78,1.78,0,0,0,0,1.77V10a1.77,1.77,0,0,0,1.77,1.76H17.09A1.77,1.77,0,0,0,18.86,10V1.77A1.78,1.78,0,0,0,17.09,0Zm.28,2.29L9.71,6.41l-.08,0-.05,0-.15,0h0l-.15,0,0,0-.08,0L1.49,2.29a.6.6,0,0,1-.24-.8.58.58,0,0,1,.8-.24l7.38,4,7.38-4a.58.58,0,0,1,.8.24A.6.6,0,0,1,17.37,2.29Z"/>
    </svg>
}
export const XelIconWhats = (props) => {
    return <svg viewBox="0 0 16.74 16.87" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M8,0A8.38,8.38,0,0,0,0,8.39a8.27,8.27,0,0,0,.91,3.78L0,16.48a.32.32,0,0,0,.4.38l4.22-1A8.37,8.37,0,1,0,8,0Zm5,13a6.57,6.57,0,0,1-7.55,1.24l-.59-.3-2.59.62.55-2.65-.29-.57A6.57,6.57,0,0,1,3.74,3.73,6.55,6.55,0,0,1,13,13Z"/>
        <path className="cls-1"
              d="M12.43,10.18l-1.62-.46a.61.61,0,0,0-.6.16l-.4.4a.58.58,0,0,1-.64.13A8.66,8.66,0,0,1,6.38,8a.58.58,0,0,1,0-.65l.34-.45a.59.59,0,0,0,.08-.61L6.17,4.69a.62.62,0,0,0-1-.21,2.69,2.69,0,0,0-1,1.61c-.12,1.13.37,2.56,2.21,4.28,2.13,2,3.83,2.25,4.94,2a2.68,2.68,0,0,0,1.45-1.26.6.6,0,0,0-.34-.91Z"/>
    </svg>
}