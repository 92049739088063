import React, { useEffect, useState, useRef } from 'react';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const currentYear = new Date().getFullYear();
const monthsArr = Array.from({ length: 12 }, (x, i) => {
    const month = i + 1;
    return month <= 9 ? '0' + month : month;
});
const yearsArr = Array.from({ length: 12 }, (_x, i) => currentYear + i);

const XFormControl = withStyles({
   root:{
       marginTop:5
   }
})(FormControl);

export default function CForm({
    cardMonth,
    cardYear,
    onUpdateState,
    cardNumberRef,
    cardHolderRef,
    cardDateRef,
    onCardInputFocus,
    onCardInputBlur,
    cardCvv,
    children,
submit
}) {
    const [cardNumber, setCardNumber] = useState('');

    const handleFormChange = (event) => {
        const { name, value } = event.target;

        onUpdateState(name, value);
    };

    // TODO: We can improve the regex check with a better approach like in the card component.
    const onCardNumberChange = (event) => {
        let { value, name } = event.target;
        let cardNumber = value;
        value = value.replace(/\D/g, '');
        if (/^3[47]\d{0,13}$/.test(value)) {
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
            // diner's club, 14 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^\d{0,16}$/.test(value)) {
            // regular cc number, 16 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
                .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
        }

        setCardNumber(cardNumber.trimRight());
        onUpdateState(name, cardNumber);
    };

    const onCvvFocus = (event) => {
        onUpdateState('isCardFlipped', true);
    };

    const onCvvBlur = (event) => {
        onUpdateState('isCardFlipped', false);
    };

    // NOTE: Currently the cursor on the card number field gets reset if we remove a number, the code bellow was used
    // in class components, need to transform this to work with functional components.
    // getSnapshotBeforeUpdate() {
    //     return this.props.cardNumberRef.current.selectionStart;
    // }

    // const componentDidUpdate = function (prevProps, prevState, cursorIdx) {
    //     const node = cardNumberRef.current;
    //     const { cardNumber: cardNum } = state;
    //     const { cardNumber: prevCardNum } = prevState;
    //     if (
    //         cardNum.length > prevCardNum.length &&
    //         cardNum[cursorIdx - 1] === ' '
    //     ) {
    //         cursorIdx += 1;
    //     } else if (prevCardNum[cursorIdx - 1] === ' ') {
    //         cursorIdx -= 1;
    //     }
    //     node.selectionStart = node.selectionEnd = cursorIdx;
    // };


    /*<Grid item xs={12}>
                {children}
            </Grid>*/


    return  <Container >
        <Grid container >
            <Grid item xs={12}>
                <Typography variant={'h6'} align={'center'}>
                    Se hará un cobro de garantía unicamente de $99.00
                </Typography>
            </Grid>
            <Grid item xs={12} style={{paddingTop:20}}>
                <XFormControl fullWidth>
                    <InputLabel id="card_n" htmlFor={'card_number'}>Número de tarjeta</InputLabel>
                    <Input   type="tel"
                             name="cardNumber"
                             className="card-input__input"
                             autoComplete="off"
                             onChange={onCardNumberChange}
                             maxLength="19"
                             ref={cardNumberRef}
                             onFocus={(e) => onCardInputFocus(e, 'cardNumber')}
                             onBlur={onCardInputBlur}
                             value={cardNumber} />
                </XFormControl>
            </Grid>
            <Grid item xs={12}>
                <XFormControl fullWidth>
                    <InputLabel id="card_name" htmlFor={'card_name'}>Nombre</InputLabel>
                    <Input   type="text"
                             className="card-input__input"
                             autoComplete="off"
                             name="cardHolder"
                             onChange={handleFormChange}
                             ref={cardHolderRef}
                             onFocus={(e) => onCardInputFocus(e, 'cardHolder')}
                             onBlur={onCardInputBlur} />
                </XFormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <XFormControl fullWidth >
                    <InputLabel id="card-mon-lab">Mes</InputLabel>
                    <Select
                        className="card-input__input -select"
                        value={cardMonth}
                        name="cardMonth"
                        onChange={handleFormChange}
                        ref={cardDateRef}
                        onFocus={(e) => onCardInputFocus(e, 'cardDate')}
                        onBlur={onCardInputBlur}
                    >
                        {monthsArr.map((val, index) => (
                            <MenuItem key={index} value={val}>
                                {val}
                            </MenuItem>
                        ))}
                    </Select>
                </XFormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <XFormControl fullWidth >
                    <InputLabel id="card-year-lab">Año</InputLabel>
                    <Select
                        name="cardYear"
                        className="card-input__input -select"
                        value={cardYear}
                        onChange={handleFormChange}
                        onFocus={(e) => onCardInputFocus(e, 'cardDate')}
                        onBlur={onCardInputBlur}
                    >
                        {yearsArr.map((val, index) => (
                            <MenuItem key={index} value={val}>
                                {val}
                            </MenuItem>
                        ))}
                    </Select>
                </XFormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <XFormControl fullWidth>
                    <InputLabel id="card_cvv" htmlFor={'card_cvv'}>CVV</InputLabel>
                    <Input  type="tel"
                            className="card-input__input"
                            maxLength="4"
                            autoComplete="off"
                            name="cardCvv"
                            onChange={handleFormChange}
                            onFocus={onCvvFocus}
                            onBlur={onCvvBlur}
                            ref={cardCvv} />
                </XFormControl>
            </Grid>
        </Grid>
    </Container>
    return (
        <div className="card-form">
            <div className="card-list">{children}</div>
            <div className="card-form__inner">
                <div className="card-input">
                    <label htmlFor="cardNumber" className="card-input__label">
                        Card Number
                    </label>
                    <input
                        type="tel"
                        name="cardNumber"
                        className="card-input__input"
                        autoComplete="off"
                        onChange={onCardNumberChange}
                        maxLength="19"
                        ref={cardNumberRef}
                        onFocus={(e) => onCardInputFocus(e, 'cardNumber')}
                        onBlur={onCardInputBlur}
                        value={cardNumber}
                    />
                </div>

                <div className="card-input">
                    <label htmlFor="cardName" className="card-input__label">
                        Card Holder
                    </label>
                    <input
                        type="text"
                        className="card-input__input"
                        autoComplete="off"
                        name="cardHolder"
                        onChange={handleFormChange}
                        ref={cardHolderRef}
                        onFocus={(e) => onCardInputFocus(e, 'cardHolder')}
                        onBlur={onCardInputBlur}
                    />
                </div>

                <div className="card-form__row">
                    <div className="card-form__col">
                        <div className="card-form__group">
                            <label
                                htmlFor="cardMonth"
                                className="card-input__label"
                            >
                                Expiration Date
                            </label>
                            <select
                                className="card-input__input -select"
                                value={cardMonth}
                                name="cardMonth"
                                onChange={handleFormChange}
                                ref={cardDateRef}
                                onFocus={(e) => onCardInputFocus(e, 'cardDate')}
                                onBlur={onCardInputBlur}
                            >
                                <option value="" disabled>
                                    Month
                                </option>

                                {monthsArr.map((val, index) => (
                                    <option key={index} value={val}>
                                        {val}
                                    </option>
                                ))}
                            </select>
                            <select
                                name="cardYear"
                                className="card-input__input -select"
                                value={cardYear}
                                onChange={handleFormChange}
                                onFocus={(e) => onCardInputFocus(e, 'cardDate')}
                                onBlur={onCardInputBlur}
                            >
                                <option value="" disabled>
                                    Year
                                </option>

                                {yearsArr.map((val, index) => (
                                    <option key={index} value={val}>
                                        {val}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="card-form__col -cvv">
                        <div className="card-input">
                            <label
                                htmlFor="cardCvv"
                                className="card-input__label"
                            >
                                CVV
                            </label>
                            <input
                                type="tel"
                                className="card-input__input"
                                maxLength="4"
                                autoComplete="off"
                                name="cardCvv"
                                onChange={handleFormChange}
                                onFocus={onCvvFocus}
                                onBlur={onCvvBlur}
                                ref={cardCvv}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
