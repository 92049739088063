import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, CardContent, Grid} from "@material-ui/core";
import {XEL_COLORS, XEL_FONT} from "../../../constant";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";
import Footer from "../../ui/Footer";
import {useHistory, useLocation} from "react-router-dom";


const useStyles = makeStyles(() => {
    return {
        linH: {

            lineHeight: 0


        }
    }

})

const Landing = () => {

    const graphicRef = useRef();

    const history = useHistory();

    const location = useLocation();
    useEffect(() => {
        return () => {
        }
    }, []);

    const goBuy = () => {
        history.push('/comprar/alianza/216704' + (location.search || ""));
    }
    return <Grid container>
        <Grid item xs={12}>
            <Grid container style={{padding: ".3%"}}>
                <Grid item xs={12} style={{marginBottom: "1%"}}>
                    <img src={'https://api.inxel.mx/website/assets/alliance/res/ifreedoms/banner.png'}
                         style={{width: "100%", height: "100%"}} alt={'banner_landing'}/>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent style={{
                            backgroundImage: `linear-gradient(#5E1E93, #623284, #7827B9, #8D1CEB)`,
                            //  padding:"1rem"
                        }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                    <Grid container spacing={1} style={{padding: "2%"}}>
                                        <Grid item xs={12}>
                                            <img
                                                onClick={goBuy}
                                                src={'https://api.inxel.mx/website/assets/alliance/res/ifreedoms/cell.png'}
                                                style={{width: "100%", height: "100%",cursor:"pointer"}} alt={'banner_ibriverso'}/>
                                        </Grid>
                                        <Grid item xs={12} style={{
                                            backgroundColor: "#FEFEFE",
                                            borderRadius: 10
                                        }}>
                                            <Typography color={'primary'} variant={'h6'} style={{fontSize: '1rem'}}
                                                        align={'center'}>
                                                <span style={{color: XEL_COLORS.secondary}}>¡Con</span> cobertura <span
                                                style={{color: XEL_COLORS.secondary}}>en toda la</span> Republica
                                                Mexicana<span
                                                style={{color: XEL_COLORS.secondary}}>!</span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={12} md={8}>
                                                    <Grid container spacing={2} style={{paddingLeft: "1%"}}>
                                                        <Grid item xs={12}>
                                                            <Point title={'20GB'}
                                                                   subtitle={'Para navegar y Redes Sociales'}
                                                                   caption={'(Con 5GB en Roaming internacional en Estados Unidos y Canadá)'}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Point title={'1,500 Minutos'} subtitle={''}
                                                                   caption={'(Con 750 minutos en Roaming internacional en Estados Unidos y Canadá)'}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Point title={'500 Mensajes'} subtitle={''}
                                                                   caption={'(Con 250  mensajes en Roaming internacional en Estados Unidos y Canadá)'}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Point title={'Chip inXel'} subtitle={''}
                                                                   caption={'Sin costo adicional'}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Point title={'Membresía ifreedoms'} subtitle={''}
                                                                   caption={'Membresía mensual, para leer, escribir y monetizar tus textos.'}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Point title={'30 días de vigencia'} subtitle={''}
                                                                   caption={''}/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Grid container style={{marginTop: 10}}>
                                                        <Grid item xs={12} ref={graphicRef} style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            position: "relative",
                                                            justifyContent: "center",
                                                        }}>
                                                            <img
                                                                onClick={goBuy}
                                                                style={{width: "80%", height: "100%",cursor:"pointer"}}
                                                                 src="https://api.inxel.mx/website/assets/alliance/res/ifreedoms/fondo.png"
                                                                 alt="fondo"/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant={'h6'}
                                                                        style={{color: "#FEFEFE", marginBottom: 10}}
                                                                        align={'center'}>
                                                                Precio especial en tus primeros 6 Pagos
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}
                                                              style={{display: "flex", justifyContent: "center"}}>
                                                            <Button variant={'contained'} color={'secondary'}
                                                                    onClick={goBuy}>
                                                                <Typography variant={'h3'} style={{color: "#FEFEFE"}}>
                                                                    ¡Lo quiero!
                                                                </Typography>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{display: "flex", justifyContent: "center", marginTop: 10}}>
                                    <Typography variant={'caption'} style={{color: "#FEFEFE", textAlign: "center"}}>
                                        Aplica en portabilidad y solo en nuestro plan Xel Free 5+, los 15 GB adicionales
                                        serán por 6 meses a partir de tu compra . Aplica política de uso justo:
                                        Superando los primeros 10GB cuentas con 10GB adicionales a velocidad reducida de
                                        512 kbps. Aplican términos y condiciones. Válida hasta el 30 de Septiembre 2022
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </Grid>
        <Footer/>
    </Grid>
}


const usePointStyles = makeStyles((props) => {
    return {
        baseColor: {
            color: "#FEFEFE",
            display: "flex"

        },
        secondaryPoint: {
            backgroundColor: XEL_COLORS.secondary,
            width: ".8rem",
            height: ".8rem",
            borderRadius: "50%",
            marginBottom: '.5rem',
            marginRight: '.3rem',
        }
    }

})

const Point = ({title, subtitle, caption}) => {

    const styles = usePointStyles();
    return <Grid container>
        <Grid item xs={12} className={styles.baseColor}>
            <div style={{height: "100%", display: "flex", alignItems: "center"}}>
                <span className={styles.secondaryPoint}></span>
            </div>
            <div>
                <div style={{display: "flex", alignItems: "flex-end", flexWrap: "wrap"}}>
                    <Typography variant={'h3'} style={{display: "flex", fontSize: "1.6rem"}}>
                        <strong>
                            {title}
                        </strong>
                    </Typography>
                    {
                        subtitle &&
                        <Typography variant={'h6'} style={{padding: '.1rem', fontSize: '1.1rem'}}>
                            {subtitle}
                        </Typography>
                    }
                </div>
                {
                    caption &&
                    <div>
                        <Typography style={{fontSize: ".4"}}>
                            {caption}
                        </Typography>
                    </div>
                }
            </div>
        </Grid>
    </Grid>
}
export default Landing;
