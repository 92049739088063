import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import Carousel from '../Carousel'
import { XEL_COLORS } from '../../../../constant'
import PlanCard from '../PlanCard'
import homeBg1 from '../../../../assets/images/homebg1.png'
import XelGradient from '../../../ui/XelGradient'
import { Plan } from '../../../ui/Cards/Plan'
import { Typography } from '@material-ui/core'

const { primary, secondary } = XEL_COLORS

export default () => {
    const monthly = useSelector(s => s.planes.normal[0].planes[1].planes)
    const preplans = useSelector(s => s.planes.normal[1].planes[0].planes)

    const [selectedTypeIndex, setSelectedTypeIndex] = useState(0)
    const plans = [
        { label: 'Mensual', plans: monthly },
        { label: 'Anual', plans: preplans },
    ]

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    color: primary,
                    marginBottom: 20,
                }}>
                <div
                    style={{
                        flex: 1,
                        maxWidth: 1240,
                        padding: '0 20px 0 20px',
                    }}>
                    <h1
                        style={{
                            fontSize: 45,
                            fontWeight: 700,
                            lineHeight: 1,
                            marginBottom: 0,
                        }}>
                        Planes de Telefonía Móvil
                    </h1>
                    <span style={{ fontWeight: 600, fontSize: 20 }}>
                        Encuentra el plan que se adapte a tí y porta tu número!
                    </span>
                </div>
            </div>
            <div
                style={{
                    backgroundImage: `url(${homeBg1})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                        style={{
                            flex: 1,
                            maxWidth: 600,
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                        {plans.map((p, i) => {
                            let nonSelectedStyle = {}
                            if (selectedTypeIndex !== i)
                                nonSelectedStyle = {
                                    color: primary,
                                    border: '2px solid ' + primary,
                                    background: 'none',
                                    backgroundColor: 'white',
                                }
                            return (
                                <XelGradient
                                    secondary
                                    onClick={() => setSelectedTypeIndex(i)}
                                    style={{
                                        color: 'white',
                                        flex: 1,
                                        padding: 12,
                                        margin: 20,
                                        borderRadius: 10,
                                        fontSize: 20,
                                        fontWeight: 800,
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        ...nonSelectedStyle,
                                    }}
                                    key={i}>
                                    {p.label}
                                </XelGradient>
                            )
                        })}
                    </div>
                </div>


                <Carousel {...{ selectedTypeIndex }}>
                    {plans[selectedTypeIndex].plans.map((plan, i) => (
                        <Plan key={`${selectedTypeIndex === 0 ? 'mobile' : 'preplan'}-${i}`} planData={plan} />
                    ))}
                </Carousel>

                {selectedTypeIndex === 1 && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '40px 20px 0px 20px'
                        }}>
                        <div
                            style={{
                                flex: 1,
                                fontWeight: 600,
                                maxWidth: 1200,
                                backgroundColor: secondary,
                                borderRadius: 15,
                                padding: 15,
                                textAlign: 'center',
                                fontSize: 30,
                                color: primary,
                            }}>
                            Los beneficios de tu plan se{' '}
                            <span style={{ color: 'white' }}>renuevan</span>{' '}
                            automáticamente cada{' '}
                            <span style={{ color: 'white' }}>30 días</span>
                        </div>
                    </div>
                )}
            </div>
            <div
                style={{
                    fontSize: 15,
                    textAlign: 'center',
                    color: primary,
                    margin: '30px 0px 30px 0px',
                    padding: '0 20px 0 20px',
                }}>
                (1) Aplica política de Uso Justo. Dependiendo del paquete, contiene MB a velocidad reducida 512 kbps.

                <br />
                (2) Aplica política de Uso Justo, Terminado los MB a velocidad de la Red que incluye el paquete en navegación, las RRSS bajarán a velocidad reducida 512 kbps. Los minutos y SMS son ilimitados, aplica restricciones por mala práctica. Consulta Términos y Condiciones.

            </div>
        </div>
    )
}
