import React, {useEffect} from "react";
import {useLegalStyle} from "../Privacidad";
import NavBar from "../../../ui/NavBar";
import {Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Footer from "../../../ui/Footer";
import {NSection, Salto} from "../Derechos";

const Title = ({children}) => {
    useEffect(() => {
        //  console.log(props);
    });
    const style = useLegalStyle();
    return <Grid item xs={12}>
        <Typography className={style.title} align={'center'}>
            {children}
        </Typography>
    </Grid>
}
const Section = ({title, body}) => {
    const style = useLegalStyle();
    return <Grid item xs={12}>
        <Typography className={style.subtitle}>
            {title}
        </Typography>
        <Typography className={style.body} variant="body2">
            {body}
        </Typography>
    </Grid>
}
export default function Contrato() {

    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Contrato";
    }, [])
    return <>
        <NavBar/>
        <Container>
            <Grid container justify={'center'}>
                <Title>
                    INFORMACIÓN RELEVANTE DEL CONTRATO.
                </Title>
                <NSection title={'1. Plan Tarifario:'}>
                    Pospago abierto: Planes con eventos incluidos en la renta mensual, más la facturación de
                    cargosadicionales.
                    <Salto/>
                    Pospago Control: Planes con eventos incluidos en renta mensual y que tienen la posibilidad de
                    adquirirservicios a través de recargas de saldo.
                    <Salto/>
                    Este plan puede incluir una combinación de servicios de voz, SMS, datos u otros servicios
                    adicionales. Estáregistrado ante el IFT y puedes consultar sus características en
                    www.ucsweb.ift.org.mx/ift_visor o por mediode la página web www.inxel.com.mx.
                    <Salto/>
                </NSection>
                <NSection title={'2. Plazo Forzoso: '}>
                    Contrato con Plazo Forzoso: Aplica cuando adquieres un Equipo INXEL y lo pagas a plazos.
                    <Salto/>
                    Contrato con vigencia indefinida: Aplica cuando tienes tu propio equipo y sólo contratas un plan
                    tarifario.
                    <Salto/>
                </NSection>
                <NSection title={'3. Penas Convencionales:'}>
                    Cuando se dé la terminación anticipada del contrato con Plazo Forzoso, se tendrá que pagar:
                    <Salto/>
                    1. El monto equivalente a 2 cargos mensuales del plan tarifario.
                    <Salto/>
                    2. El 20% del monto total de los meses pendientes de facturar de renta mensual de servicio, relativo
                    al PlazoForzoso descrito en la carátula de tu contrato.
                    <Salto/>
                    INXEL aplicará la pena convencional que tú decidas y la que tenga un menor costo.
                    <Salto/>
                </NSection>
                <NSection title={'4. Póliza de Garantía del Equipo INXEL:'}>
                    Cuando contrates con Plazo Forzoso, se te entregará un Equipo INXEL que cuenta con una Póliza
                    deGarantía de 12 meses contados a partir de la compra.
                    <Salto/>
                    Para hacer válida la garantía debes acudir a cualquier Centro de Atención y Ventas INXEL.
                    <Salto/>
                </NSection>
                <NSection title={'5. Compensación o bonificación y descuento: '}>
                    En los siguientes casos, INXEL te compensará:
                    <Salto/>
                    1. Por fallas en los servicios móviles cuando exista una determinación por parte del IFT respecto
                    delincumplimiento de INXEL a los parámetros de calidad móvil. INXEL te compensará conforme a la
                    fórmula quese acuerde con el IFT.
                    <Salto/>
                    2. Por la interrupción total de la prestación de los servicios móviles por causas imputables a
                    INXEL, salvo encaso de interrupciones por periodos menores a 72 horas en un caso fortuito o de
                    fuerza mayor. En este caso,INXEL te compensará la parte proporcional del cargo mensual del plan
                    tarifario correspondiente al tiempo quehaya durado la interrupción.
                    <Salto/>
                    3. En caso de que se suspenda el servicio por supuesta falta de pago de la factura y que sí lo hayas
                    realizadoen tiempo, INXEL te compensará el monto proporcional al cargo mensual del plan tarifario
                    contratado por elperiodo que se haya suspendido indebidamente el servicio móvil.
                    <Salto/>
                    4. En caso de la Portabilidad, tú podrás exigir a INXEL una vez firmado el contrato, la compensación
                    delmonto proporcional del cargo mensual del plan tarifario, por los días de retraso en tu cambio de
                    compañía.
                    <Salto/>
                    En todos los casos, se te aplicará una bonificación adicional del 20% sobre el monto de
                    compensaciónresultante. Tanto la compensación como la bonificación correspondiente y se verá
                    reflejada en los próximosdos ciclos de facturación.
                    <Salto/>
                </NSection>
                <NSection title={'6. Facturación y pagos: '}>
                    INXEL te enviará mensualmente la factura con el detalle de tu consumo y podrás consultar tu estado
                    decuenta en www.inxel.mx. Debes realizar el pago a través de los medios indicados en tu factura
                    antes de lafecha límite. En caso de no realizar el pago de tu factura dentro de la fecha límite,
                    INXEL realizará un cargopor pago tardío por cada una de tus líneas suspendidas que visualizarás en
                    tu siguiente factura.
                    <Salto/>
                </NSection>
                <NSection title={'7. Garantías de apertura de contrato: '}>
                    Éstas sirven para garantizar a INXEL tus obligaciones en caso de impago que lleve a la cancelación
                    delcontrato. En todos los contratos, aplicará la fianza y si es una contratación con Equipo INXEL en
                    venta aplazos, se te podrá solicitar el depósito en garantía.
                    <Salto/>
                </NSection>
                <NSection title={'8. Principales causas de cancelación del contrato:'}>
                    Entre otros, la falta de pago de facturas de servicio consecutivas a la fecha límite de pago, por
                    utilizarindebidamente la línea o comercializar o revender los servicios sin autorización.
                    <Salto/>
                </NSection>
                <NSection title={'9. Principales causales de suspensión del servicio:'}>
                    No cubrir por lo menos el importe correspondiente al concepto de tu plan tarifario contratado en la
                    fechalímite de pago de la factura inmediata anterior.
                    <Salto/>
                    En caso de solicitar la suspensión por robo o extravío del equipo, hasta en tanto no solicites su
                    reactivación.
                    <Salto/>
                    Proporcionar datos falsos a INXEL.
                    <Salto/>
                    Utilizar el servicio con un equipo no homologado.
                    <Salto/>
                    Por solicitud de autoridad competente con el objetivo de cesar la comisión de delitos
                    <Salto/>
                </NSection>
                <NSection title={'10. Protección de datos personales:'}>
                    INXEL se obliga a tratar tus datos personales cumpliendo la Ley Federal de Protección de Datos
                    Personalesen Posesión de los Particulares. Puedes consultar el aviso de privacidad en www.inxel.mx.
                    <Salto/>
                </NSection>
                <NSection title={'11. Registro del Contrato'}>
                    Ten la seguridad que tu contrato fue aprobado y registrado por la PROFECO en cumplimiento a la
                    NOM-184-SCFI-2018.
                    <Salto/>
                </NSection>
                <a href="https://inxel.mx/docs/Contrato_de_adhesión.pdf" target="_blank">Ver contrato</a>
            </Grid>
        </Container>
        <Footer/>
    </>
}
