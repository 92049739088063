import axios from 'axios'
import { useSelector } from 'react-redux'

//const axios = Axios.create({});

const Axios = () => {
    const login = useSelector((state) => state.login)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return axios.create({
        baseURL: process.env.NODE_ENV === 'development' ? "http://localhost:3000/" : "https://api.inxel.mx/",
        //timeout: 1000,
        headers: { 'auth-token': login.token ? login.token : '' },
    })
}

export default Axios
