import React from 'react'

import NavBar from '../../ui/NavBar'
import Footer from '../../ui/Footer'
import Metodos from './Metodos'
import { XEL_COLORS } from '../../../constant/index'

const { primary } = XEL_COLORS

export default function Recargar({ option }) {
    return (
        <>
            <NavBar />
                <Metodos/>
            <Footer />
        </>
    )
}
