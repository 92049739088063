import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Alerts = ({open,onClose}) => {
    return <Snackbar open={open.open} autoHideDuration={10000} onClose={onClose}>
        <Alert onClose={onClose} severity={open.severity}>
            {open.msg}
        </Alert>
    </Snackbar>
}

export default Alerts;
