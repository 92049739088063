import React, {useEffect, useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@material-ui/core";
import {useParams} from "react-router-dom";
import NavBar from "../../ui/NavBar";
import Footer from "../../ui/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Axios from "../../../instances/axios";
import axio from "axios";
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "../../../actions";
import PlansSection from "../Inicio/PlansSection";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 1
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};
const Alianza = () => {
    const params = useParams();
    const [banners, setBanners] = useState([]);
    const noCel = useMediaQuery('(min-width:426px)');
    const banner = useSelector(state => state.banner);
    const axios = Axios();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const {name} = params;
        const source = axio.CancelToken.source();
        if (name) {
            console.log(name)
            if (banner[name]) {
                setBanners(banner[name]);
                setLoading(false);
            } else {
                axios.get(`/website/alianza/banners/${name}/`, {
                    cancelToken: source.token
                }).then(res => {
                    console.log("Success")
                    let {data} = res;

                    let {desktop, mobile} = data;

                    let banners = [];
                    for (let i = 0; i < desktop.length; i++) {
                        banners.push({
                            id: desktop[i],
                            file: {
                                type: 'image',
                                files: {
                                    desktop: `${axios.defaults.baseURL}website/alianza/banners/${name}/desktop/${desktop[i]}`,
                                    mobile: `${axios.defaults.baseURL}website/alianza/banners/${name}/mobile/${mobile[i]}`
                                }
                            }
                        });
                    }
                    setBanners(banners);
                    dispatch(Actions.SETBANNERS({
                        [name]: banners
                    }));
                    setLoading(false);
                }).catch(err => {
                    console.error(err);
                });
            }
        }
        return () => {
            source.cancel("Cancelado por desmontar");
        }
    }, [])

    return <div>
        <NavBar/>
        <Carousel autoPlaySpeed={4000}
                  responsive={responsive}
                  autoPlay={true}
                  infinite={true}>
            {
                banners.map(item => <div style={{}} key={item.id}>
                    <img style={{height: "100%", width: "100%"}}
                         src={noCel ? item.file.files.desktop : item.file.files.mobile} alt={`banner-${item.id}`}/>
                </div>)
            }
        </Carousel>
        <PlansSection/>
        <Footer/>
    </div>;
};

export default Alianza;
