import React, {useEffect, useState} from "react";
import {Container, Grid, withStyles} from "@material-ui/core";
import {XEL_COLORS, XEL_FONT} from "../../../../constant";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useResponsiveValues} from "../../../utils/hooks";
import {useHistory} from 'react-router-dom';
import NumberFormat from "react-number-format";
import Carousel from "react-multi-carousel";
import './styles.css';
import DeviceService from "../../../utils/services/dispositivos";

const useStylesC = makeStyles({
    container: {
        paddingTop: '2%',
        height: '100%',
        width: '100%',
        display: 'flex'
        //   display:"flow"
    },
    item: {
        height: '100%',
        width: '100%',
        display: "flex",
        justifyContent: 'center'
    },
    cont: {
        display: "flex"
    }
});

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 3
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

const useStyles = makeStyles({
    container: {
        marginTop: 40
    },
    sub_container: {
        marginTop: 20,
        backgroundColor: '#F6F6F6',
        paddingTop: '1%',
        paddingBottom: '1%'
    },
    name_cell: {
        color: '#000000',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 35
    },
    model_cell: {
        color: '#4b4b4b',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 25
    },
    price_cell: {
        color: XEL_COLORS.secondary,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 45
    },
    image_container: {
        display: "flex",
        justifyContent: "center"
    },
    button: {
        color: '#4b4b4b',
        borderRadius: 20,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        marginTop: 30,
        fontSize: 20
    },

});

const useCellStyle = makeStyles({
    head: {
        padding: '2% 0',
        maxHeight: 60,
        height: '100%'
    },
    img: {
        maxHeight: 250,
        width: '100%',
        height: '100%',
        padding: '3%'
    },
    title: {

        fontWeight: XEL_FONT.bold_weight,
        padding: '1% 0'
    },
    btn_container: {
        display: "flex", justifyContent: "center"
    },
    btn: {
        padding: '0%',
        borderRadius: 14,
        backgroundColor: '#FEFEFE',
        border: `1px solid ${XEL_COLORS.primary}`,
        color: XEL_COLORS.primary,
        overflow: "hidden",

    },
    container: {
        backgroundColor: '#FEFEFE',
        paddingBottom: '2%',
        boxShadow: '0px 0px 30px #00000029',
        cursor: "pointer"
    },
    image_container: {
        display: "flex",
        justifyContent: "center"
    }
});
const BuyButton = withStyles({
    root: {
        borderRadius: 14,

        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 25,
        border: `1px solid ${XEL_COLORS.primary}`,
        '&:hover': {
            backgroundColor: XEL_COLORS.secondary,
            color: '#FEFEFE',
            border: `1px solid #FEFEFE`,
        },

    }
})(Button);
const Celular = ({data}) => {
    const style = useCellStyle();
    const sizeTitle = useResponsiveValues(25, 20, 15);
    const buttonSizeText = useResponsiveValues(20, 15, 12);
    const {id, name, price, image, details} = data;
    const [isHover, setIsHover] = useState(false);
    const history = useHistory();
    const mouseEnter = () => {
        setIsHover(true);
    }
    const mouseLeave = () => {
        setIsHover(false);
    }

    const handleClick = () => {
        history.push(`/detalle/celular/${id}`);
    }
    return <Grid container id={data.id} style={{padding: '2%', height: '100%'}}>
        <Grid item xs={12} className={style.container} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}
              onClick={handleClick}>
            <Grid container style={{height: '100%'}}>
                <Grid
                    item xs={12}
                    className={style.head}
                    style={{backgroundColor: isHover ? XEL_COLORS.secondary : XEL_COLORS.lila}}>
                    <Typography
                        align={'center'}
                        className={style.title}
                        variant={"h4"}
                        style={{
                            //fontSize: sizeTitle,
                            color: isHover ? '#FEFEFE' : XEL_COLORS.primary}}>
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems={'center'} justify={'center'}
                          style={{height: useResponsiveValues('98%', '93%', '93%')}}>
                        <Grid item xs={4} md={10}
                              className={style.image_container}
                              style={{height: '70%', display: "flex", alignItems: "center", paddingTop: '1%'}}
                        >
                            <img
                                src={image}
                                alt={name}
                                className={style.img}/>
                        </Grid>
                        <Grid item xs={12} style={{height: '30%', display: "flex", alignItems: "center"}}>
                            <Grid container justify={'center'}>
                                <Grid item xs={12}>
                                    <Typography color={'primary'} align={'center'}
                                                style={{
                                                    //fontSize: buttonSizeText,
                                                    paddingBottom: '1%'}}>
                                        Por solo:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color={'primary'} variant={"h5"} align={'center'} style={{
                                        fontWeight: XEL_FONT.bold_weight,
                                      //  fontSize: sizeTitle,
                                        paddingBottom: '2%'
                                    }}>
                                        <NumberFormat thousandSeparator={true} prefix={'$'} displayType={'text'}
                                                      value={price}/>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className={style.btn_container}>
                                    <BuyButton style={isHover ? {
                                        backgroundColor: XEL_COLORS.secondary,
                                        color: '#FEFEFE',
                                        border: `1px solid #FEFEFE`,
                                        fontSize: buttonSizeText
                                    } : {fontSize: buttonSizeText}}>
                                        ¡Lo quiero!
                                    </BuyButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

export default function CellPhoneSection({All}) {

    const cellService = DeviceService();
    const stylesC = useStylesC();
    const [data, setData] = useState([]);

    const history = useHistory();
    const text = useResponsiveValues(20, 15, 12);
    const button = useResponsiveValues(20, 15, 12);
    const title = useResponsiveValues(25, 20, 15);
    const style = useStyles();
    useEffect(() => {
        if (All) {
            cellService.getAll.then(res => {
                setData(res);
            })
        } else {
            cellService.getThree.then(res => {
                setData(res);
            })
        }

        return () => {
            window.scrollTo(0, 0)
        }
    }, [])

    return <Grid container classes={{root: style.container}}>
        <Grid container>
            <Container>
                <Grid item xs={11} md={12}>
                    <Grid container justify={'center'} alignItems={"center"}>
                        <Grid item xs={10}>
                            <Typography variant={"h4"} color={'primary'} style={{
                                fontWeight: XEL_FONT.bold_weight,
                               // fontSize: title
                            }}>
                                Equipo + Plan
                            </Typography>
                            <Typography variant={"body1"} color={'primary'}
                                        //style={{fontSize: text}}
                            >
                                ¿Ya tienes el plan que más se adapta a ti? ¿Qué esperas? ¡Encuentra el equipo perfecto
                                para ti!
                            </Typography>
                        </Grid>
                        {
                            !All ? <Grid item xs={2}>
                                <BuyButton
                                    style={{fontSize: button}}
                                    onClick={() => {
                                        history.push('/equipos');
                                    }}>
                                    Ver todos
                                </BuyButton>
                            </Grid> : ''
                        }
                    </Grid>
                </Grid>
            </Container>
        </Grid>
        <Grid container classes={{root: style.sub_container}}>

            <Grid container justify={'center'}>
                <Grid item xs={12}>
                    <Container>
                        {
                            !All ? <Carousel
                                className={stylesC.container}
                                itemClass={stylesC.item}
                                //     containerClass={stylesC.cont}
                                responsive={responsive}

                            >
                                {
                                    data.map((res, index) => <Grid key={index} item xs={11}>
                                        <Celular data={res}/>
                                    </Grid>)
                                }
                            </Carousel> : <Grid container justify={'center'}>
                                {
                                    data.map((res, index) => <Grid key={index} item xs={11} md={4}>
                                        <Celular data={res}/>
                                    </Grid>)
                                }
                            </Grid>
                        }
                    </Container>
                </Grid>
            </Grid>

        </Grid>
    </Grid>
}
