import React, {useEffect, useState} from "react";
import {
    Backdrop,
    Button,
    Card,
    CircularProgress,
    FormHelperText,
    Grid,
    Input,
    TextField,
    Typography
} from "@material-ui/core";
import Axios from "../../../../../../../instances/axios";
import {useSelector} from "react-redux";
import Container from "@material-ui/core/Container";
import {XEL_COLORS, XEL_FONT} from "../../../../../../../constant";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import SMS from "../../../../../../../assets/improve_pays/sms.png";

const AuthOption = ({selected, title, handleChange, res}) => {
    const [hover, setHover] = useState(false);
    return <Card
        onClick={handleChange}
        elevation={hover ? 6 : 1}
        style={{
            height: "100%",
            padding: "3%", cursor: "pointer",
            backgroundColor: selected ? XEL_COLORS.secondary : "white",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}

    >
        <Grid container spacing={2} style={{
            height: "100%",
            //  backgroundColor: selected ? XEL_COLORS.secondary : "white",
        }}>
            <Grid item xs={12}>
                <Typography align={"center"} style={{color: selected ? "#FEFEFE" : "#000000"}}>
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                <img src={res} style={{width: "60%", height: "100%"}} alt="Logo"/>
            </Grid>
        </Grid>
    </Card>
}
const PinRequest = ({processPay, setPin, close}) => {
    const axios = Axios();
    const session = useSelector(state => state.login);
    const [option, setOption] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sended, setSended] = useState(false);
    const [enable, setEnable] = useState(false);
    const [code, setCode] = useState("");
    useEffect(() => {
        if (option === 2 && !sended) {
            handleSend();
        }
    }, [option])

    const handleSend = () => {
        setLoading(true);
        axios.get("/clients/pay.pins?mode=sms").then(res => {
            setLoading(false);
            setSended(true);
            console.log(res);
        }).catch(err => {
            setLoading(false);
            setSended(true);
            console.log(err);
        })
    }
    const handleCode = (e) => {
        let value = e.target.value;
        if (isNaN(value))
            return;
        if (value.length > 4)
            return;
        setPin(value);
        setCode(value);
    }
    return <Backdrop open={true} style={{zIndex: 10000}}>
        <Container>
            <Grid container justify={"center"}>
                <Grid item xs={10} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{backgroundColor: XEL_COLORS.secondary, position: "relative"}}>
                            <Typography variant={"h5"} style={{color: "#FEFEFE"}} align={"center"}>
                                Autorización de pago
                            </Typography>

                        </Grid>
                        <Grid item xs={12} style={{
                            backgroundColor: "#FEFEFE",
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5
                        }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography align={"center"}>
                                        Para completar tu pago, debes ingresar el código de seguridad (cvv) de tu
                                        tarjeta.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{padding: "1%", display: "flex", justifyContent: "center"}}>
                                    <TextField name={"cvv"} placeholder={"CVV"} value={code}
                                               onChange={handleCode}
                                               type={"password"}/>
                                </Grid>

                                {
                                    <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={processPay} variant={"contained"}
                                                disabled={code.length > 4 || code.length < 3}
                                                style={{
                                                    backgroundColor: !(code.length > 4 || code.length < 3) ? "green" : "gray",
                                                    color: "#FEFEFE",
                                                    marginRight: 10
                                                }}>
                                            Pagar
                                        </Button>
                                        {
                                            <Button onClick={close} style={{color: "#FEFEFE"}} variant={"contained"}
                                                    color={"secondary"}>
                                                Cancelar
                                            </Button>
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </Backdrop>
}

export default PinRequest;
