import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { XEL_COLORS } from '../../../../constant'
import { useResponsiveValues } from '../../../utils/hooks'
import XelGradient from '../../../ui/XelGradient'
import MinsIcon from '../../../../assets/images/MinsIcon.svg'
import SmsIcon from '../../../../assets/images/SmsIcon.svg'
import MinsIconWhite from '../../../../assets/images/MinsIconWhite.svg'
import SmsIconWhite from '../../../../assets/images/SmsIconWhite.svg'
import WifiIconWhite from '../../../../assets/images/wifi-icon-white.svg'
import WifiIcon from '../../../../assets/images/wifi-icon.svg'

const {
    primary,
    primary2,
    primary_shiny,
    primary_dark,
    secondary,
    secondary_dark,
} = XEL_COLORS

export default ({
    id,
    type,
    name,
    gb,
    primera_desc,
    segunda_desc,
    tercera_desc,
    price,
    period,
    folio,
    modem,
    mobile,
    selected,
    mb_title,
    ...rest
}) => {
    const [nonSelectedStyle, setNonSelectedStyle] = useState(
        modem
            ? {
                  background: 'none',
                  backgroundColor: 'white',
              }
            : {},
    )
    const [buyBtnStyles, setBuyBtnStyles] = useState(
        !modem ? (selected ? { background: 'white', color: primary } : {}) : {},
    )

    useEffect(() => {
        setBuyBtnStyles(
            !modem
                ? selected
                    ? { background: 'white', color: primary }
                    : {}
                : {},
        )
        if (!modem) return
        setNonSelectedStyle(
            !selected
                ? {
                      background: 'none',
                      backgroundColor: 'white',
                  }
                : {},
        )
    }, [selected])

    const history = useHistory()

    return (
        <div {...rest} style={{ display: 'flex', justifyContent: 'center' }}>
            <XelGradient
                y
                secondary={!modem && selected}
                style={{
                    flex: 1,
                    color: modem ? (selected ? 'white' : secondary) : 'white',
                    margin: 10,
                    borderRadius: 10,
                    maxWidth: useResponsiveValues(270, 250, 240),
                    boxShadow: '0px 0px 21px -6px black',
                    ...nonSelectedStyle,
                }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    <div
                        style={{
                            backgroundColor: modem
                                ? selected
                                    ? secondary
                                    : primary
                                : selected
                                ? primary
                                : secondary,
                            color: 'white',
                            padding: '5px 15px',
                            fontSize: 20,
                            fontWeight: 800,
                            margin: '7px 0px',
                        }}>
                        {name.split('*')[0]}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            color: modem
                                ? selected
                                    ? 'white'
                                    : secondary
                                : 'white',
                        }}>
                        <span style={{ fontWeight: 800, fontSize: 50 }}>
                            {mb_title ? mb_title : gb}
                        </span>{' '}
                        <span
                            style={{
                                fontWeight: 800,
                                fontSize: 25,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}>
                            {modem && (
                                <img
                                    style={{
                                        width: 40,
                                        height: 'auto',
                                        marginLeft: 'auto',
                                    }}
                                    src={
                                        selected && modem
                                            ? WifiIconWhite
                                            : WifiIcon
                                    }
                                />
                            )}
                            <span>
                                MB
                                <span style={{ fontSize: 15, top: 0 }}>
                                    {name.split('').filter(c => c === '*')
                                        .length
                                        ? name.split('').filter(c => c === '*')
                                              .length === 1
                                            ? '(1)'
                                            : '(2)'
                                        : null}
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
                {!(mobile && modem) && (
                    <div
                        style={{
                            textAlign: 'center',
                            marginBottom: 15,
                            fontWeight: 600,
			    fontSize: 13,
                            color: modem
                                ? selected
                                    ? 'white'
                                    : primary
                                : 'white',
                        }}>
                        {mobile
                            ? primera_desc.split('(')[0]
                            : `Velocidad ${segunda_desc}`}
                    </div>
                )}
                <div
                    style={{
                        background: modem
                            ? `linear-gradient(180deg, ${secondary} 50%, ${secondary_dark} 100%)`
                            : selected
                            ? `linear-gradient(180deg, ${primary2}, ${primary_shiny}, ${primary_dark})`
                            : 'white',
                        borderRadius: 10,
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            color: modem
                                ? 'white'
                                : selected
                                ? 'white'
                                : secondary,
                            padding: 15,
                            fontSize: 18,
                        }}>
                        {mobile && !modem && (
                            <>
                                <Benefit
                                    selected={selected}
                                    string={segunda_desc}
                                />
                                <div
                                    style={{
                                        width: 2,
                                        backgroundColor: secondary,
                                    }}
                                />
                                <Benefit
                                    selected={selected}
                                    string={tercera_desc}
                                />
                            </>
                        )}
                    </div>
                    <div
                        style={{
                            backgroundColor: modem
                                ? 'white'
                                : selected
                                ? 'white'
                                : secondary,
                            textAlign: 'center',
                            color: modem
                                ? primary
                                : selected
                                ? secondary
                                : 'white',
                        }}>
                        <span style={{ fontSize: 50, fontWeight: 800 }}>
                            ${price}
                        </span>
                        <span style={{ fontWeight: 800 }}>{period}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <XelGradient
                            y
                            reverse
                            onClick={() =>
                                history.push(`comprar/${type}/${id}`)
                            }
                            style={{
                                height: 40,
                                border: 'none',
                                borderRadius: 20,
                                width: 200,
                                color: 'white',
                                fontSize: 20,
                                fontWeight: 800,
                                textAlign: 'center',
                                margin: 13,
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                ...buyBtnStyles,
                            }}>
                            Comprar
                        </XelGradient>
                    </div>
                    <div
                        style={{
                            paddingBottom: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            color: modem
                                ? 'white'
                                : selected
                                ? 'white'
                                : secondary,
			    fontSize: 12
                        }}>
                        <span>FOLIO: {folio}</span>
                    </div>
                </div>
            </XelGradient>
        </div>
    )
}

const Benefit = ({ string, selected }) => (
    <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
        <div
            style={{
                margin: 'auto',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
            }}>
            <div
                style={{
                    width: 40,
                    height: 30,
                    backgroundImage: `url(${
                        string.split(' ')[1] === 'Mensajes'
                            ? selected
                                ? SmsIconWhite
                                : SmsIcon
                            : selected
                            ? MinsIconWhite
                            : MinsIcon
                    })`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                }}
            />
            <span
                style={{
                    fontWeight: 800,
                    display: 'flex',
                    alignItems: 'center',
                }}>
                {string.split(' ')[0]}
            </span>
        </div>
    </div>
)
