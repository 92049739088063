import React, {useEffect, useRef, useState} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useMediaQuery, useTheme} from "@material-ui/core";
import {ASSETS_URL, XEL_COLORS, XEL_FONT} from "../../../constant";
import Button from "@material-ui/core/Button";
import inXel from '../../../assets/logos/inxel/logo-inxel-blanco.png';
import {
    XelIconXelPhone
} from "../../../assets/logos/svg";
import {useHistory} from 'react-router-dom';
import Collapse from "@material-ui/core/Collapse";
import {XelIconEMail, XelIconLink, XelIconPhoneAlone, XelIconWhats} from "../../../assets/logos/footer/svg";
import {XelIconFacebookMain, XelIconInstagramMain, XelIconTwitterMain} from "../../../assets/logos/home/svg";
import {useResponsiveValues} from "../../utils/hooks";
import Axios from "../../../instances/axios";
import XelGradient from '../XelGradient'

const useGlobalStyles = makeStyles((themes) => {

    return ({
        list_container: {
            listStyle: 'none',
            display: 'flex',
            justifyContent: "space-between"
        },
        xel_footer: {
            height: 200,
            backgroundColor: '#2D1540'
        },
        footer_list: {
            listStyle: 'none'
        },
        titles: {
            color: '#2D1540',
            fontSize: 20
        },
        links: {
            color: '#2D1540',
            textDecoration: 'none',
            margin: '35px 0',
            '&:hover': {
                color: '#ff8b22'
            }
        }
    })
});

const useStyle = makeStyles({
    container: {
        marginTop: "2%"
    },
    title: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        color: '#fefefe',
    //    fontSize: 21
    },
    value: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        color: '#fefefe',
     //   fontSize: 15
    },
    socials: {
        stroke: '#fefefe',
        fill: '#fefefe',
        height: 40,
        '&:hover': {
            stroke: XEL_COLORS.secondary,
            fill: XEL_COLORS.secondary,
        },
        cursor: "pointer"
    }
});

const FootButton = withStyles({
    root: {
        backgroundColor: "transparent",
        color: '#fefefe',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: "transparent",
            //   textDecoration: 'underline'
        },
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
     //   fontSize: 15,
        padding: 0,
        textAlign: 'start'
    }
})(Button);

const listStyles = makeStyles({
    lis: {
        '& li': {
            '& :hover': {
                '& *': {
                    color: XEL_COLORS.secondary,
                },
                '& svg': {
                    fill: XEL_COLORS.secondary
                }
            }
        }
    },
    socials: {
        '& :hover': {
            '& svg': {
                fill: XEL_COLORS.secondary
            }
        }
    }

})
export default function () {
    const style = useGlobalStyles();
    const styles = useStyle();
    const listStyle = listStyles();
    const [year, setYear] = useState(0);
    const history = useHistory();
    const matches = useMediaQuery('(min-width:964px)');
    const matches2 = useMediaQuery('(min-width:426px)');
    const [openTerms, setOpenTerms] = useState(false);
    const [openAbout, setOpenAbout] = useState(false);
    const axios = Axios();
    const legalesRef = useRef(null)
    useEffect(() => {
        axios.get("/utils/server.time.json").then(res => {
            let d = res.data;
            const date = new Date(d);
            setYear(date.getFullYear());

        }).catch(err => {
            console.log(err);
            const date = new Date();
            setYear(date.getFullYear());
        });
    }, []);
    useEffect(() => {
        const isLegal = history.location.hash === '#legales'
        setTimeout(
            () => {
                if(legalesRef.current && isLegal){
                    legalesRef.current.scrollIntoView({behavior: "smooth"})
                }
            },
            0
        )
    }, [legalesRef, history])
    const Desktop = (<Grid container>
        <Grid item xs={12} md={6}>
            <ul style={{listStyle: 'none', listStyleType: 'none'}} className={listStyle.lis}>
                <li style={{paddingBottom: 15}}>
                    <Typography classes={{root: styles.title}} style={{paddingLeft: 15}}>
                        Contáctanos
                    </Typography>
                </li>
                <li style={{paddingBottom: 10}}>
                    <Grid container onClick={() => {
                        window.open(
                            "tel:*935", "_blank");
                    }} style={{cursor: "pointer"}}>
                        <Grid item xs={3} style={{display: "flex", justifyContent: "center"}}>
                            <XelIconXelPhone fill={'#FEFEFE'} style={{height: 19}}/>
                        </Grid>
                        <Grid item xs={8} style={{display: "flex", alignItems: "center"}}>
                            <Typography variant={'button'} classes={{root: styles.value}} >
                                <span
                                    style={{fontWeight: XEL_FONT.regular_weight}}> Desde tu número inXel marca al:</span> *xel
                                (*935)
                            </Typography>
                        </Grid>
                    </Grid>
                </li>
                <li style={{paddingBottom: 10}}>
                    <Grid container onClick={() => {
                        window.open(
                            "tel:55 8840 9522", "_blank");
                    }} style={{cursor: "pointer"}}>
                        <Grid item xs={3} style={{display: "flex", justifyContent: "center"}}>
                            <XelIconPhoneAlone fill={'#FEFEFE'} style={{height: 17}}/>
                        </Grid>
                        <Grid item xs={8} style={{display: "flex", alignItems: "center"}}>
                            <Typography variant={'button'}  classes={{root: styles.value}}>
                                <span
                                    style={{fontWeight: XEL_FONT.regular_weight}}>Llámanos al:</span> 55
                                8840 9522
                            </Typography>
                        </Grid>
                    </Grid>
                </li>
                {
                    /*   <li style={{paddingBottom: 10}}>
                    <Grid container onClick={() => {
                        window.open(
                            "https://api.whatsapp.com/send?phone=+525567902404&text=Hola", "_blank");
                    }} style={{cursor: "pointer"}}>
                        <Grid item xs={3} style={{display: "flex", justifyContent: "center"}}>
                            <XelIconWhats fill={'#FEFEFE'} style={{height: 17}}/>
                        </Grid>
                        <Grid item xs={8} style={{display: "flex", alignItems: "center"}}>
                            <Typography classes={{root: styles.value}}>
                                <span style={{fontWeight: XEL_FONT.regular_weight}}>Escríbenos por WhatsApp:</span> 55
                                6790 2404
                            </Typography>
                        </Grid>
                    </Grid>
                </li>*/
                }
                <li style={{paddingBottom: 10}}>
                    <Grid container onClick={() => {
                        window.open(
                            "mailto:asistencia@inxel.mx", "_blank");
                    }} style={{cursor: "pointer"}}>
                        <Grid item xs={3} style={{display: "flex", justifyContent: "center"}}>
                            <XelIconEMail fill={'#FEFEFE'} style={{height: 14}}/>
                        </Grid>
                        <Grid item xs={8} style={{display: "flex", alignItems: "center"}}>
                            <Typography variant={'button'}  classes={{root: styles.value}}>
                                <span
                                    style={{fontWeight: XEL_FONT.regular_weight}}>Mándanos un correo, incluyendo algún número de contacto a:</span> asistencia@inxel.mx
                            </Typography>
                        </Grid>
                    </Grid>
                </li>
                <li>
                    <Grid container style={{marginTop: 10}} justify={'flex-end'}>
                        <Grid item xs={11}>
                            <Typography style={{color: '#FEFEFE', fontWeight: XEL_FONT.bold_weight, fontSize: 12}}>
                                Horario de atención:
                            </Typography>
                            <Typography style={{color: '#FEFEFE', fontSize: 12}}>
                                Lunes a Viernes de 8:00 a.m. a 9:00 p.m. Sábados de 9:00 a.m. a 9:00 p.m. Domingos de
                                10:00 a.m. a 5:00 p.m.
                            </Typography>

                        </Grid>
                    </Grid>
                </li>


            </ul>
        </Grid>


        <Grid item xs={12} md={3}
              style={{
                  display: "flex",
                  flexDirection: "column",
              }}>
            <ul style={{listStyle: 'none'}} className={listStyle.lis}>
                <li>
                    <Typography classes={{root: styles.title}} style={{marginBottom: 10}}>
                        Sobre inXel
                    </Typography>
                </li>
                <li>
                    <FootButton onClick={() => {
                        window.scrollTo(0, 0)
                        history.push('/nosotros');
                    }}>
                        Nosotros
                    </FootButton>
                </li>
                <li>
                    <FootButton onClick={() => {
                        window.scrollTo(0, 0)
                        history.push('/about');
                    }}>
                        Nuestra misión y visión
                    </FootButton>
                </li>
                {
                    /*<li>
                    <FootButton>
                        Trabaja con nosotros
                    </FootButton>
                </li>*/
                }
                <li>
                    <FootButton onClick={() => {
                        window.scrollTo(0, 0)
                        history.push('/preguntas-frecuentes');
                    }}>
                        Preguntas frecuentes
                    </FootButton>
                </li>
                <li>
                    <FootButton onClick={() => {
                        window.scrollTo(0, 0)
                        history.push('/quejas-sugerencias');
                    }}>
                        Quejas y Sugerencias
                    </FootButton>
                </li>
                <li>
                    <FootButton onClick={() => {
                        window.scrollTo(0, 0)
                        history.push('/proveedores');
                    }}>
                        Únete al equipo inXel
                    </FootButton>
                </li>
            </ul>
        </Grid>
        <Grid item xs={12} md={3} id={'legales'} ref={legalesRef}>
            <ul style={{listStyle: 'none'}} className={listStyle.lis}>
                <li>
                    <Typography classes={{root: styles.title}} style={{marginBottom: 10}}>
                        Términos legales
                    </Typography>
                </li>
                <li>
                    <FootButton onClick={() => {
                       window.open(`${ASSETS_URL}/inxel/docs/14052024_AvisoPrivacidadInXel.pdf`, "_blank");
                    }}>
                        Aviso de privacidad
                    </FootButton>
                </li>
                <li>
                    <FootButton onClick={() => {
                          window.open(`${ASSETS_URL}/inxel/docs/14052024_TerminiosYCondicionesPreciosInXel.pdf`, "_blank");
                    }}>
                        Terminos y Condiciones
                    </FootButton>
                </li>
                <li>
                    <FootButton onClick={() => {
                        window.scrollTo(0, 0)
                        window.open(`${ASSETS_URL}/inxel/docs/14052024_CartaDeDerechosInXel.pdf`, "_blank");
                    }}>
                        Carta de derechos de los usuarios
                    </FootButton>
                </li>
                <li>
                    <FootButton
                        onClick={() => {
                            window.open(`${ASSETS_URL}/inxel/docs/14052024_ColaboracionConLaJusticiaInXel.pdf`, "_blank");
                        }}
                    >
                        Colaboración en materia de seguridad y justicia
                    </FootButton>
                </li>
                <li>
                    <FootButton
                        onClick={() => {
                            window.open(`${ASSETS_URL}/inxel/docs/14052024_FormatoSolicitudDeDerechosInXel.pdf`, "_blank");
                        }}
                    >
                        Formato de solicitud de derechos
                    </FootButton>
                </li>
                <li>
                    <FootButton
                        onClick={() => {
                            window.open(`${ASSETS_URL}/inxel/docs/Old_ContratoDeAdhesión.pdf`, "_blank");
                        }}
                    >
                        Contrato de Adhesión
                    </FootButton>
                </li>
                <li>
                    <FootButton
                        onClick={() => {
                            window.open(`${ASSETS_URL}/inxel/docs/14052024_CodigoDePracticasComercialesInXel.pdf`, "_blank");
                        }}
                    >
                        Código de prácticas comerciales
                    </FootButton>
                </li>
                <li>
                    <FootButton
                        onClick={() => {
                            window.open(`${ASSETS_URL}/inxel/docs/Old_AutorizaciónIFT.pdf`, "_blank");
                        }}
                    >
                        Autorización del IFT
                    </FootButton>
                </li>
                <li>
                    <FootButton
                        onClick={() => {
                            window.open('https://inxel.mx/docs/Politica_de_Gestion_de_Trafico_y_Administracion_de_Red.pdf', '_blank');
                        }}
                    >
                        Política de Gestión de Tráfico
                        y Administración de Red
                    </FootButton>
                </li>
                <li>
                    <FootButton onClick={() => {
                        //window.scrollTo(0, 0)
                        window.open("https://tarifas.ift.org.mx/ift_visor/", "_blank");
                    }}>
                        Tarifas inscritas al IFT
                    </FootButton>
                </li>

            </ul>


        </Grid>
        <Grid item xs={12}>
            <Typography style={{
                fontFamily: XEL_FONT.name,
                fontWeight: XEL_FONT.regular_weight,
                color: '#FEFEFE',
                fontSize: 15,
                margin: 30
            }} align={'center'}>
                Copyright {year} inXel | Lago Alberto, Miguel Hidalgo, 11320, CDMX
            </Typography>
        </Grid>
    </Grid>);


    const Movil = (<Grid container style={{padding: 10}}>

        <Grid item xs={12} md={6}>
            <ul style={{listStyle: 'none', listStyleType: 'none', padding: 0}} className={listStyle.lis}>
                <li style={{paddingBottom: 15}}>
                    <Typography classes={{root: styles.title}} align={'left'} style={{fontSize: 10}}>
                        Contáctanos
                    </Typography>
                </li>
                <li style={{paddingBottom: 10}}>
                    <Grid container onClick={() => {
                        window.open(
                            "tel:*935", "_blank");
                    }} style={{cursor: "pointer"}}>
                        <Grid item xs={3} style={{display: "flex", justifyContent: "center"}}>
                            <XelIconXelPhone fill={'#FEFEFE'} style={{height: 19}}/>
                        </Grid>
                        <Grid item xs={8} style={{display: "flex", alignItems: "center"}}>
                            <Typography classes={{root: styles.value}} style={{fontSize: 10}}>
                                <span
                                    style={{fontWeight: XEL_FONT.regular_weight}}> Desde tu número inXel marca al:</span> *xel
                                (*935)
                            </Typography>
                        </Grid>
                    </Grid>
                </li>
                <li style={{paddingBottom: 10}}>
                    <Grid container onClick={() => {
                        window.open(
                            "tel:55 8840 9522", "_blank");
                    }} style={{cursor: "pointer"}}>
                        <Grid item xs={3} style={{display: "flex", justifyContent: "center"}}>
                            <XelIconPhoneAlone fill={'#FEFEFE'} style={{height: 17}}/>
                        </Grid>
                        <Grid item xs={8} style={{display: "flex", alignItems: "center"}}>
                            <Typography classes={{root: styles.value}} style={{fontSize: 10}}>
                                <span
                                    style={{fontWeight: XEL_FONT.regular_weight}}>Llámanos al:</span> 55
                                8840 9522
                            </Typography>
                        </Grid>
                    </Grid>
                </li>
                {
                    /* <li style={{paddingBottom: 10}}>
                    <Grid container onClick={() => {
                        window.open(
                            "https://api.whatsapp.com/send?phone=+525567902404&text=Hola", "_blank");
                    }} style={{cursor: "pointer"}}>
                        <Grid item xs={3} style={{display: "flex", justifyContent: "center"}}>
                            <XelIconWhats fill={'#FEFEFE'} style={{height: 17}}/>
                        </Grid>
                        <Grid item xs={8} style={{display: "flex", alignItems: "center"}}>
                            <Typography classes={{root: styles.value}} style={{fontSize: 10}}>
                                <span style={{fontWeight: XEL_FONT.regular_weight}}>Escríbenos por WhatsApp:</span> 55
                                6790 2404
                            </Typography>
                        </Grid>
                    </Grid>
                </li>*/
                }
                <li style={{paddingBottom: 10}}>
                    <Grid container onClick={() => {
                        window.open(
                            "mailto:asistencia@inxel.mx", "_blank");
                    }} style={{cursor: "pointer"}}>
                        <Grid item xs={3} style={{display: "flex", justifyContent: "center"}}>
                            <XelIconEMail fill={'#FEFEFE'} style={{height: 14}}/>
                        </Grid>
                        <Grid item xs={8} style={{display: "flex", alignItems: "center"}}>
                            <Typography classes={{root: styles.value}} style={{fontSize: 10}}>
                                <span
                                    style={{fontWeight: XEL_FONT.regular_weight}}>Mándanos un correo, incluyendo algún número de contacto a:</span> asistencia@inxel.mx
                            </Typography>
                        </Grid>
                    </Grid>
                </li>
                <li>
                    <Grid container style={{marginTop: 10}} justify={'flex-end'}>
                        <Grid item xs={11}>
                            <Typography style={{color: '#FEFEFE', fontWeight: XEL_FONT.bold_weight, fontSize: 10}}>
                                Horario de atención:
                            </Typography>
                            <Typography style={{color: '#FEFEFE', fontSize: 10}}>
                                Lunes a Viernes de 8:00 a.m. a 9:00 p.m. Sábados de 9:00 a.m. a 9:00 p.m. Domingos de
                                10:00 a.m. a 5:00 p.m.
                            </Typography>
                        </Grid>
                    </Grid>
                </li>


            </ul>
        </Grid>

        <Grid item xs={12} style={{marginTop: 10}}>
            <Container style={{
                // borderTop: '1px solid #FEFEFE',
                //  borderBottom: '1px solid #FEFEFE',
                //   paddingTop: 5,
                paddingBottom: 2
            }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={10}>
                                <Typography style={{
                                    fontFamily: XEL_FONT.name,
                                    fontWeight: XEL_FONT.semi_bold_weight,
                                    color: '#FEFEFE',
                                    fontSize: !matches2 ? 11 : 17
                                }} align={'center'}>
                                    Terminos legales
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button style={{fontSize: 19, color: '#FEFEFE'}} onClick={() => {
                                    setOpenTerms(!openTerms)
                                }}>
                                    {
                                        openTerms ? '-' : '+'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Collapse in={openTerms}>
                    <Grid item xs={12}>
                        <ul style={{listStyle: 'none'}}>
                            <li>
                                <FootButton onClick={() => {
                                   window.open(`${ASSETS_URL}/inxel/docs/14052024_AvisoPrivacidadInXel.pdf`, "_blank");
                                }} style={{fontSize: !matches2 ? 11 : 17}}>
                                    Aviso de privacidad
                                </FootButton>
                            </li>
                            <li>
                                <FootButton onClick={() => {
                               window.open(`${ASSETS_URL}/inxel/docs/14052024_TerminiosYCondicionesPreciosInXel.pdf`, "_blank");
                                }}
                                            style={{fontSize: !matches2 ? 11 : 17}}
                                >
                                    Terminos y Condiciones
                                </FootButton>
                            </li>
                            <li>
                                <FootButton onClick={() => {
                                   window.open(`${ASSETS_URL}/inxel/docs/14052024_CartaDeDerechosInXel.pdf`, "_blank");
                                }}
                                            style={{fontSize: !matches2 ? 11 : 17}}
                                >
                                    Carta de derechos de los usuarios
                                </FootButton>
                            </li>
                            <li>
                                <FootButton
                                    onClick={() => {
                                       window.open(`${ASSETS_URL}/inxel/docs/14052024_ColaboracionConLaJusticiaInXel.pdf`, "_blank");
                                    }}
                                    style={{fontSize: !matches2 ? 11 : 17}}
                                >
                                    Colaboración en materia de seguridad y justicia
                                </FootButton>
                            </li>
                            <li>
                                <FootButton
                                    onClick={() => {
                                      window.open(`${ASSETS_URL}/inxel/docs/14052024_FormatoSolicitudDeDerechosInXel.pdf`, "_blank");
                                    }}
                                    style={{fontSize: !matches2 ? 11 : 17}}
                                >
                                    Formato de solicitud de derechos
                                </FootButton>
                            </li>
                          
                            <li>
                                <FootButton
                                    onClick={() => {
                                        window.open(`${ASSETS_URL}/inxel/docs/Old_ContratoDeAdhesión.pdf`, "_blank");
                                    }}
                                    style={{fontSize: !matches2 ? 11 : 17}}
                                >
                                    Contrato de Adhesión
                                </FootButton>
                            </li>
                            <li>
                                <FootButton
                                    onClick={() => {
                                     window.open(`${ASSETS_URL}/inxel/docs/14052024_CodigoDePracticasComercialesInXel.pdf`, "_blank");
                                    }}
                                    style={{fontSize: !matches2 ? 11 : 17}}
                                >
                                    Código de prácticas comerciales
                                </FootButton>
                            </li>
                            <li>
                                <FootButton
                                    onClick={() => {
                                    window.open(`${ASSETS_URL}/inxel/docs/Old_AutorizaciónIFT.pdf`, "_blank");
                                    }}
                                    style={{fontSize: !matches2 ? 11 : 17}}
                                >
                                    Autorización del IFT
                                </FootButton>
                            </li>
                            <li>
                                <FootButton
                                    onClick={() => {
                                       window.open('https://inxel.mx/docs/Politica_de_Gestion_de_Trafico_y_Administracion_de_Red.pdf', '_blank');
                                    }}
                                    style={{fontSize: !matches2 ? 11 : 17}}
                                >
                                    Política de Gestión de Tráfico
                                    y Administración de Red
                                </FootButton>
                            </li>
                            <li>
                                <FootButton onClick={() => {
                                    window.open("https://tarifas.ift.org.mx/ift_visor/", "_blank");
                                }} style={{fontSize: !matches2 ? 11 : 17}}>
                                    Tarifas inscritas al IFT
                                </FootButton>
                            </li>

                            <li>
                                <FootButton onClick={() => {
                                    window.scrollTo(0, 0)
                                    history.push('/preguntas-frecuentes');
                                }} style={{fontSize: !matches2 ? 11 : 17}}>
                                    Preguntas frecuentes
                                </FootButton>
                            </li>
                            <li>
                                <FootButton onClick={() => {
                                    window.scrollTo(0, 0)
                                    history.push('/quejas-sugerencias');
                                }} style={{fontSize: !matches2 ? 11 : 17}}>
                                    Quejas y Sugerencias
                                </FootButton>
                            </li>
                            <li>
                                <FootButton onClick={() => {
                                    window.scrollTo(0, 0)
                                    history.push('/proveedores');
                                }} style={{fontSize: !matches2 ? 11 : 17}}>
                                    Únete al equipo inXel
                                </FootButton>
                            </li>
                        </ul>
                    </Grid>
                </Collapse>
            </Container>
        </Grid>
        <Grid item xs={12}>
            <Container style={{
                //  borderTop: '1px solid #FEFEFE',
                // borderBottom: '1px solid #FEFEFE',
                //   paddingTop: 5,
                paddingBottom: 2,

            }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container style={{display: "flex", alignItems: "center"}}>
                            <Grid item xs={10}>
                                <Typography style={{
                                    fontFamily: XEL_FONT.name,
                                    fontWeight: XEL_FONT.semi_bold_weight,
                                    color: '#FEFEFE',
                                    fontSize: !matches2 ? 11 : 17
                                }} align={'center'}>
                                    Sobre inXel
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button style={{fontSize: 19, color: '#FEFEFE'}} onClick={() => {
                                    setOpenAbout(!openAbout)
                                }}>
                                    {
                                        openAbout ? '-' : '+'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Collapse in={openAbout}>
                    <Grid item xs={12}>
                        <ul style={{listStyle: 'none'}}>
                            <li>
                                <FootButton onClick={() => {
                                    window.scrollTo(0, 0)
                                    history.push('/nosotros');
                                }}
                                            style={{fontSize: !matches2 ? 11 : 17}}
                                >
                                    Nosotros
                                </FootButton>
                            </li>
                            <li>
                                <FootButton
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        history.push('/about');
                                    }}
                                    style={{fontSize: !matches2 ? 11 : 17}}
                                >
                                    Nuestra misión y visión
                                </FootButton>
                            </li>
                            {
                                /*    <li>
                                <FootButton onClick={() => {
                                }}
                                            style={{fontSize: !matches2 ? 11 : 17}}
                                >
                                    Trabaja con nosotros
                                </FootButton>
                            </li>*/
                            }
                            <li>
                                <FootButton onClick={() => {
                                    window.scrollTo(0, 0)
                                    history.push('/preguntas-frecuentes');
                                }}
                                            style={{fontSize: !matches2 ? 11 : 17}}
                                >
                                    Preguntas frecuentes
                                </FootButton>
                            </li>
                        </ul>
                    </Grid>
                </Collapse>
            </Container>
        </Grid>
        <Grid item xs={12}>
            <Typography style={{
                fontFamily: XEL_FONT.name,
                fontWeight: XEL_FONT.regular_weight,
                color: '#FEFEFE',
                fontSize: useResponsiveValues(15, 10, 8),
                marginTop: 10
            }} align={'center'}>
                Copyright {year} inXel | Lago Alberto, Miguel Hidalgo, 11320, CDMX
            </Typography>
        </Grid>
    </Grid>)
    return (
	<XelGradient reverse>
	<Grid container component={'footer'} classes={{root: styles.container}}>
        <Grid item xs={12}>
	    <Grid container style={{ 
		justifyContent: 'center',
		margin: '20px 0 20px 0',
	    }}>
		<Grid item xs={useResponsiveValues(4, 4, 6)} style={{
		    display: "flex",
		    justifyContent: "center",
		    padding: 20,
		    border: `2px solid ${ XEL_COLORS.secondary }`,
		    borderLeft: 'none',
		    borderRight: 'none',
		    minWidth: 300
		}}
		>
                    <Grid container justify={'center'}>
                        <Grid className={listStyle.socials} item xs={2}
                              style={{display: "flex", justifyContent: "center", cursor: "pointer"}}
                              onClick={() => {
                                  window.open(
                                      "https://www.facebook.com/Inxel_mx-100681561717346/", "_blank");
                              }}>
                            <XelIconFacebookMain fill={'#FEFEFE'} style={{height: useResponsiveValues(30, 22.5, 15)}}/>
                        </Grid>
                        <Grid className={listStyle.socials} item xs={2}
                              style={{display: "flex", justifyContent: "center", cursor: "pointer"}}
                              onClick={() => {
                                  window.open(
                                      "https://twitter.com/inxel_mx", "_blank");
                              }}>
                            <XelIconTwitterMain fill={'#FEFEFE'} style={{height: useResponsiveValues(30, 22.5, 15)}}/>
                        </Grid>

                        <Grid className={listStyle.socials} item xs={2}
                              style={{display: "flex", justifyContent: "center", cursor: "pointer"}}
                              onClick={() => {
                                  window.open(
                                      "https://www.instagram.com/inxel_mx/", "_blank");
                              }}>
                            <XelIconInstagramMain fill={'#FEFEFE'} style={{height: useResponsiveValues(30, 22.5, 15)}}/>
                        </Grid>

                        <Grid className={listStyle.socials} item xs={2}
                              style={{display: "flex", justifyContent: "center", cursor: "pointer"}}
                              onClick={() => {
                                  window.open(
                                      "https://www.linkedin.com/company/inxel-mx", "_blank");
                              }}>
                            <XelIconLink fill={'#FEFEFE'} style={{height: useResponsiveValues(30, 22.5, 15)}}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Container>
            {
                matches ? Desktop : Movil
            }
        </Container>
	</Grid>
	</XelGradient>
	)
}
