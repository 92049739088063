import React, {useEffect, useState} from "react";
import {CircularProgress, Grid} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import axios from "axios";
import {useParams} from "react-router-dom";
import cookie from "js-cookie";
import Cookie from "js-cookie";
import Typography from "@material-ui/core/Typography";
import {useQuery} from "../../../utils/hooks";

const Confirm = () => {

    const [loading, setLoading] = useState(true);

    const [message, setMessage] = useState({
        open: false,
        message: ''
    })

    const queryParams = useQuery();
    useEffect(() => {
        if (!queryParams.get('id')) {
            setMessage({
                open: false,
                message: "Se requiere un ID"
            })
        } else {
            loadCharge();
        }
    }, []);

    const loadCharge = () => {
        axios.get(`https://api.inxel.mx/clients/buys/status/${queryParams.get('id')}`).then(({
                                                                                                 data: {
                                                                                                     error,
                                                                                                     order_id,
                                                                                                     status
                                                                                                 }
                                                                                             }) => {

            console.log('error', error);
            console.log('order_id', order_id);
            console.log('status', status);

            if (!error && status === 'completed') {
                console.log('Completed');
                Cookie.set(`xel-pay-confirmed-${order_id}`, 'completed', {expires: 3});
                setLoading(false);
                return;
            }

            setLoading(false);

            if (status === 'pending') {
                setMessage({
                    open: true,
                    message: "El pago aun no ha sido procesado"
                });
            } else if (status === 'canceled') {
                setMessage({
                    open: true,
                    message: "El pago ha sido cancelado"
                });
            } else if (status === 'failed') {
                setMessage({
                    open: true,
                    message: "El pago no pudo ser procesado."
                });
            } else {
                setMessage({
                    open: true,
                    message: "Hubo un problema al obtener el estatus del pago. Codigo de error: " + error.error_code
                })
            }

        }).catch(() => {
            setLoading(false);
            setMessage({
                open: true,
                message: "Hubo un problema al obtener el estatus del pago. Codigo de error: " + 500
            })
        })

    }


    return <Container>
        <Grid container alignItems={'center'} style={{display: 'flex', minHeight: '100vh'}}>
            {
                !loading ? <Grid item xs={12}>
                    {
                        message.open ? <><Typography align={'center'}>
                            Hubo un problema
                        </Typography>
                            <Typography align={'center'} variant={'body2'}>
                                {message.message}
                            </Typography></> : <Typography variant={'h6'} align={'center'}>
                            Todo listo puedes cerrar esta ventana.
                        </Typography>
                    }
                </Grid> : <Grid item xs={12} style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: '100vh'
                }}>
                    <Typography variant={'h6'}>
                        Cargando... <CircularProgress size={35}/>
                    </Typography>
                </Grid>
            }
        </Grid>
    </Container>
}

export default Confirm;
