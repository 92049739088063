import React, {useEffect, useState} from 'react';
import NavBar from "../../ui/NavBar";
import './style.css';
import Footer from '../../ui/Footer';
import {useMediaQuery} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../../actions';
import Opciones from "./SeccionOpciones";
import {Extra} from "./SeccionExtra";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Axios from "../../../instances/axios";
import axio from "axios";
import MobilePlans from './MobilePlans';
import ModemPlans from './ModemPlans';
import { Plan } from '../../ui/Cards/Plan';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 1
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

export default ({ mobile = false, modem }) => {
    const noCel = useMediaQuery('(min-width:768px)');
    const dispatch = useDispatch();
    const axios = Axios();
    const bannersAll = useSelector(state => state.banners);
    const bannersHome = bannersAll.home;
    const [banners, setBanners] = useState([]);
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel";
        dispatch(Actions.PLAN_UNSELECTED());
    }, []);

    useEffect(() => {
        const source = axio.CancelToken.source();
        if (bannersHome.length === 0)
            axios.get("/website/banners/home", {
                cancelToken: source.token
            }).then(res => {
                let {data} = res;
                let {desktop, mobile, urls} = data;

                urls = urls || [];

                let deskBanners = [];
                let mobileBanners = [];
                for (let i = 0; i < desktop.length; i++) {
                    deskBanners.push(desktop[i].bannerId);
                }
                for (let i = 0; i < mobile.length; i++) {
                    mobileBanners.push(mobile[i].bannerId);
                }
                let banners = [];

                for (let i = 0; i < deskBanners.length; i++) {
                    banners.push({
                        id: deskBanners[i],
                        file: {
                            type: 'image',
                            files: {
                                desktop: `${axios.defaults.baseURL}website/banners/home/desktop/${deskBanners[i]}`,
                                mobile: `${axios.defaults.baseURL}website/banners/home/mobile/${mobileBanners[i]}`
                            },
                            urlClick: urls[i]
                        }
                    })
                }
                setBanners(banners);
                dispatch(Actions.SETHOMEBANNER(banners))
            }).catch(err => {
                console.error(err);
            });
        return () => {
            source.cancel("Cancelado por desmontar");
        }
    }, [])

    return <div style={{scrollBehavior: 'smooth'}}>
        <NavBar/>


        {
            <Carousel autoPlaySpeed={6000} responsive={responsive} autoPlay={true} infinite={bannersHome.length > 1}>
                {
                    bannersHome.map(item => <div style={{
                        cursor: (item.file.urlClick && item.file.urlClick.length > 0) ? "pointer":"initial"
			// cursor: 'pointer'
                    }} key={item.id} onClick={() => {
                        if (item.file.urlClick && item.file.urlClick.length > 0) {
                            window.open(item.file.urlClick, "_blank");
                        }
                    }}>
                        <img style={{height: "100%", width: "100%"}}
                             src={noCel ? item.file.files.desktop : item.file.files.mobile} alt={`banner-${item.id}`}/>
                    </div>)
                }
            </Carousel>
        }
        <Opciones/>

	{ mobile && !modem && <MobilePlans /> }
	{ modem && !mobile && <ModemPlans /> }
	{ mobile && modem && <ModemPlans mobile/> }


        <Extra/>
        <Footer/>
    </div>;
}
