import React from "react";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import {useSelector} from "react-redux";
import {useResponsiveValues} from "../../../../utils/hooks";
import {XEL_COLORS, XEL_FONT} from "../../../../../constant";


export default function PlanName() {

    const plan = useSelector(state => state.planname)
    return <Grid container style={{marginBottom:'1.5%'}}>
        <Grid item xs={12}
              style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
            <Typography color={'primary'}
                        style={{fontWeight: XEL_FONT.semi_bold_weight, fontSize: useResponsiveValues(25, 20, 15)}}>
                Plan seleccionado:
            </Typography>
            <Typography style={{
                padding:'1% 2% ',
                borderRadius: 5,
                border: '1px solid ' + XEL_COLORS.secondary,
                fontSize: useResponsiveValues(20, 16, 12)
            }} color={'primary'}>
	    {
		plan.name === "XEL 5+ X12 MESES*" ? "xel 5+ x12" 
		: plan.name
	    }
            </Typography>
        </Grid>
    </Grid>
}
