import React from "react";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


import Linea from '../../../../assets/logos/home/LineaPlan.png';

const Titulo = () => {
    return <Grid container style={{marginTop:40}}>
        <Grid item xs={12}>
            <Typography variant={'h1'} align={'center'} color={'primary'}>
                Quejas y Sugerencias
            </Typography>
        </Grid>
        <Grid item xs={12} style={{display:"flex",justifyContent:"center",marginTop:10,marginBottom:10}}>
            <img src={Linea} alt="Linea" style={{height:15,width:325}}/>
        </Grid>
    </Grid>
}

export default Titulo;