import { createSvgIcon } from "@material-ui/core";
export const Messenger = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M8.31512 16.4154C12.7098 16.4154 16.2723 12.882 16.2723 8.52337C16.2723 4.16473 12.7098 0.631348 8.31512 0.631348C3.92047 0.631348 0.35791 4.16473 0.35791 8.52337C0.35791 12.882 3.92047 16.4154 8.31512 16.4154Z"
        fill="white"
        stroke="#D8D6D8"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.31509 2.67743C4.9318 2.67743 2.30298 5.13272 2.30298 8.46491C2.30298 10.207 3.02207 11.7036 4.18913 12.7441C4.28344 12.826 4.34238 12.9546 4.35417 13.0832L4.38954 14.1472C4.40132 14.4862 4.75498 14.7084 5.06148 14.5681L6.25211 14.0419C6.35821 13.9952 6.4643 13.9952 6.5704 14.0186C7.12446 14.1706 7.70209 14.2524 8.31509 14.2524C11.6984 14.2524 14.3272 11.7971 14.3272 8.46491C14.3272 5.13272 11.7102 2.67743 8.31509 2.67743Z"
        fill="url(#paint0_radial_505_5290)"
        stroke="#D8D6D8"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.70781 10.1486L6.47608 7.3659C6.759 6.92161 7.36021 6.81638 7.7846 7.13206L9.18743 8.17264C9.3171 8.26617 9.49392 8.26617 9.6236 8.17264L11.5215 6.74623C11.7691 6.55916 12.111 6.85145 11.9341 7.12037L10.1659 9.90304C9.88294 10.3473 9.28173 10.4526 8.85735 10.1369L7.45452 9.0963C7.32485 9.00276 7.14802 9.00276 7.01835 9.0963L5.12041 10.5227C4.87285 10.7215 4.54278 10.4175 4.70781 10.1486Z"
        fill="white"
        stroke="#D8D6D8"
        strokeWidth="0.2"
        strokeMiterlimit="10"
      />
      <defs>
        <radialGradient
          id="paint0_radial_505_5290"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.61484 14.5342) scale(13.0993 12.992)"
        >
          <stop stopColor="#448DCC" />
          <stop offset="0.6098" stopColor="#684A99" />
          <stop offset="0.9348" stopColor="#EA5481" />
          <stop offset="1" stopColor="#ED6F64" />
        </radialGradient>
      </defs>
    </svg>,
    'Messenger'
  );
  