import React, {useEffect} from "react";
import {Grid, Hidden} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import Footer from "../../ui/Footer";
import Typography from "@material-ui/core/Typography";
import {useResponsiveValues} from "../../utils/hooks";
import {XEL_COLORS} from "../../../constant";
import Torre from '../../../assets/images/zonaventa/torre2x.png';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Head from "../../../assets/images/zonaventa/Head.png";
import Zona from '../../../assets/logos/zone/imagen_zonas.png';
import Mapa from '../../../assets/images/zonaventa/map.svg';
import Caro from '../../../assets/images/zonaventa/caro1.png';
import CaroM from '../../../assets/images/zonaventa/CaroS.jpg';
import Titulo from "./Titulo";
import {CheckImeiv2} from "../../ui/BuyDialog";
import {CheckImei} from "../Mifi/DinamicBuy";
import ConsultaImei from "../ConsultaImei";

const useStyles = makeStyles({
    list: {
        listStyle: "none",
    }
});
export default function CheckImeiPage() {
    useEffect(()=>{
        document.getElementsByTagName("title")[0].text = "inXel - Compatibilidad";

    },[])
    return <>
        <NavBar/>
        <Grid container alignItems={'center'} style={{marginBottom: '3%', minHeight: '30vh'}}>
            <Hidden only={["sm", "xs"]}>
                <Grid item xs={12} style={{padding: '3%'}}>
                    <Typography variant={'h1'} color={'primary'} align={'center'}
                                style={{fontSize: useResponsiveValues(20, 15, 12)}}>
                        Consulta la compatibilidad de tu celular

                    </Typography>
                </Grid>
            </Hidden>
            <Grid item xs={12}>
                <ConsultaImei/>
            </Grid>
        </Grid>


        <Footer/>
    </>
}
