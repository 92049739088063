import React from "react";

export const XELCross = (props) =>{
    return <svg {...props} viewBox="0 0 58 44.2">
        <g id="Grupo_52" dataName="Grupo 52" transform="translate(-346.725 -362.78)" opacity="0.62">
            <path id="Trazado_3" dataName="Trazado 3" d="M372.815,378.312q-9.382-7.181-18.782-14.349a7.211,7.211,0,0,0-.845-.567,3.48,3.48,0,0,0-4.909.927,2.086,2.086,0,0,0-.254,1.7,8.4,8.4,0,0,0,.759,1.156q6.048,6.686,12.13,13.353a24.265,24.265,0,0,1,1.78,1.919c1.493,2.065.981,4.037-.805,5.855-2.183,2.222-4.466,4.391-6.683,6.6-2.506,2.49-4.989,4.994-7.476,7.495a2.53,2.53,0,0,0-.863,2.763c.549,1.491,2.883,2.231,4.7,1.462a7.077,7.077,0,0,0,1.291-.722q10.407-7.27,20.791-14.557c2.012-1.414,3.457-3.087,3.812-5.157.388-2.26-.6-4.254-2.426-6.039A24.11,24.11,0,0,0,372.815,378.312Z"
                  fill={props.fill ? props.fill : "#ee6e1d"}
            />
            <path id="Trazado_4" dataName="Trazado 4" d="M517.933,378.637q9.382-7.18,18.782-14.349a7.191,7.191,0,0,1,.845-.567,3.48,3.48,0,0,1,4.909.927,2.085,2.085,0,0,1,.254,1.7,8.4,8.4,0,0,1-.759,1.156q-6.048,6.686-12.13,13.353a24.269,24.269,0,0,0-1.78,1.919c-1.493,2.065-.981,4.037.805,5.855,2.183,2.222,4.466,4.391,6.683,6.6,2.506,2.49,4.989,4.994,7.476,7.495a2.53,2.53,0,0,1,.863,2.763c-.549,1.491-2.883,2.231-4.7,1.462a7.091,7.091,0,0,1-1.291-.722q-10.407-7.27-20.791-14.557c-2.012-1.414-3.456-3.087-3.812-5.157-.388-2.26.6-4.254,2.426-6.039A24.131,24.131,0,0,1,517.933,378.637Z" transform="translate(-139.299 -0.272)"     fill={props.fill ? props.fill : "#ee6e1d"}
            />
        </g>
    </svg>

}
export const XelIconXelPhone= (props) => {
    return <svg  viewBox="0 0 89.98 93.32" version={'1.0'}  {...props} >
        <path className="cls-1"
              d="M30.6,43c4-2.26,4.79-2.76,4.91-2.83l.19-.11a1.09,1.09,0,0,0,.41-1.49L24.67,18.75a1.09,1.09,0,0,0-1.49-.39s-6.82,3.14-11.11,7.07C6.86,30.22,5.39,36.74,6.12,42.9c.93,7.84,11.74,25.58,11.74,25.58s10,18.23,16.28,23c5,3.71,11.35,5.7,18.09,3.58,5.55-1.74,11.68-6.08,11.68-6.08a1.09,1.09,0,0,0,.41-1.49L52.89,67.64a1.08,1.08,0,0,0-1.49-.39,1,1,0,0,0-.17.15s-.95.44-4.93,2.8c-5.07,3-5.2,5.81-5.2,5.81-2.34-1.44-9.93-15.21-9.93-15.21S23,47.35,23,44.6C23,44.6,25.58,45.8,30.6,43Z"
              transform="translate(-4.48 -4.03)"/>
        <path className="cls-1"
              d="M46.67,97.35a22.44,22.44,0,0,1-13.4-4.74C26.76,87.75,17,69.94,16.58,69.18s-11-18-11.92-26.11C3.78,35.61,6.06,29,11.07,24.35c4.4-4,11.2-7.2,11.49-7.33a2.39,2.39,0,0,1,1.16-.28A2.56,2.56,0,0,1,25.94,18L37.38,37.83a2.57,2.57,0,0,1-.95,3.5l-.2.11c-.88.55-2.53,1.5-4.92,2.84a14.4,14.4,0,0,1-6.32,2A137.09,137.09,0,0,0,32.43,60a137.94,137.94,0,0,0,8.18,13.32,14.23,14.23,0,0,1,4.94-4.43c3-1.8,4.34-2.5,4.83-2.75a1.83,1.83,0,0,1,.29-.2,2.63,2.63,0,0,1,3.5.94L65.6,86.71a2.57,2.57,0,0,1-.95,3.5,51.18,51.18,0,0,1-12,6.21A20,20,0,0,1,46.67,97.35ZM23.57,19.8a49,49,0,0,0-10.51,6.72c-4.3,4-6.25,9.71-5.48,16.2.88,7.44,11.43,24.81,11.54,25S29,85.78,35,90.26c5.21,3.89,11.18,5.11,16.77,3.35a48.74,48.74,0,0,0,11.07-5.74l-11-19.13c-.14.07-1.22.61-4.78,2.72-4.17,2.47-4.48,4.65-4.49,4.67a1.53,1.53,0,0,1-.83,1.17,1.47,1.47,0,0,1-1.41,0c-2.48-1.53-8.62-12.44-10.44-15.75s-8.31-14-8.39-16.87a1.47,1.47,0,0,1,2.09-1.38h0s2.24.73,6.3-1.55c1.61-.9,3.74-2.1,4.76-2.74Z"
              transform="translate(-4.48 -4.03)"/>
        <path className="cls-1"
              d="M57.52,60.23A3.87,3.87,0,0,1,57,58,20.42,20.42,0,0,0,45.48,38a3.88,3.88,0,0,1,3.38-7A28.12,28.12,0,0,1,64.74,58.57a3.88,3.88,0,0,1-7.22,1.66Z"
              transform="translate(-4.48 -4.03)"/>
        <path className="cls-1"
              d="M60.88,63.64h-.4a5.35,5.35,0,0,1-4.94-5.72,19,19,0,0,0-10.7-18.54,5.35,5.35,0,0,1-2.48-7.14,5.38,5.38,0,0,1,4.82-3,5.31,5.31,0,0,1,2.32.53A29.58,29.58,0,0,1,66.21,58.68,5.36,5.36,0,0,1,60.88,63.64ZM47.17,32.14a2.41,2.41,0,0,0-1.05,4.57A21.88,21.88,0,0,1,58.48,58.12a2.41,2.41,0,0,0,2.22,2.57h.18a2.41,2.41,0,0,0,2.39-2.23A26.65,26.65,0,0,0,48.21,32.38,2.38,2.38,0,0,0,47.17,32.14Z"
              transform="translate(-4.48 -4.03)"/>
        <path className="cls-1"
              d="M71.56,61.07a3.84,3.84,0,0,1-.51-2.22,34.14,34.14,0,0,0-19.28-33.4,3.87,3.87,0,0,1,3.37-7A41.84,41.84,0,0,1,78.78,59.41a3.88,3.88,0,0,1-7.22,1.65Z"
              transform="translate(-4.48 -4.03)"/>
        <path className="cls-1"
              d="M74.93,64.48h-.4a5.34,5.34,0,0,1-4.94-5.73,32.68,32.68,0,0,0-18.46-32,5.35,5.35,0,0,1-2.49-7.14,5.38,5.38,0,0,1,4.82-3,5.29,5.29,0,0,1,2.32.53A43.29,43.29,0,0,1,80.25,59.52,5.37,5.37,0,0,1,74.93,64.48ZM53.46,19.56a2.4,2.4,0,0,0-1.05,4.57A35.6,35.6,0,0,1,72.52,59a2.36,2.36,0,0,0,.31,1.37,2.41,2.41,0,0,0,1.91,1.2h.18a2.41,2.41,0,0,0,2.39-2.23A40.37,40.37,0,0,0,54.51,19.8,2.39,2.39,0,0,0,53.46,19.56Z"
              transform="translate(-4.48 -4.03)"/>
        <path className="cls-1"
              d="M85.6,61.91a3.86,3.86,0,0,1-.51-2.22,47.84,47.84,0,0,0-27-46.83,3.88,3.88,0,1,1,3.37-7A55.55,55.55,0,0,1,92.83,60.26a3.88,3.88,0,0,1-7.22,1.65Z"
              transform="translate(-4.48 -4.03)"/>
        <path className="cls-1"
              d="M89,65.32h-.4a5.34,5.34,0,0,1-4.94-5.72,46.37,46.37,0,0,0-26.2-45.39,5.35,5.35,0,0,1-2.49-7.14,5.38,5.38,0,0,1,4.82-3,5.3,5.3,0,0,1,2.33.53A57,57,0,0,1,94.3,60.37,5.36,5.36,0,0,1,89,65.32ZM59.75,7a2.4,2.4,0,0,0-1,4.57A49.3,49.3,0,0,1,86.57,59.8a2.39,2.39,0,0,0,.31,1.38,2.42,2.42,0,0,0,1.91,1.19H89a2.41,2.41,0,0,0,2.39-2.23A54.08,54.08,0,0,0,60.8,7.21,2.36,2.36,0,0,0,59.75,7Z"
              transform="translate(-4.48 -4.03)"/>   </svg>
}
export const XelIconLinkedin= (props) => {
    return <svg  viewBox="0 0 136 136" version={'1.0'}  {...props} >
        <path className="st0" d="M104.1,32.5C94.6,23,81.9,17.7,68.5,17.7c-13.4,0-26.1,5.2-35.6,14.7S18.2,54.6,18.2,68
		c0,13.4,5.2,26.1,14.7,35.6c9.5,9.5,22.1,14.7,35.6,14.7c13.4,0,26.1-5.2,35.6-14.7c9.5-9.5,14.7-22.1,14.7-35.6
		C118.8,54.6,113.6,42,104.1,32.5z M101.8,101.4c-8.9,8.9-20.7,13.8-33.3,13.8c-12.6,0-24.4-4.9-33.3-13.8
		C26.3,92.5,21.4,80.6,21.4,68c0-12.6,4.9-24.4,13.8-33.3c8.9-8.9,20.7-13.8,33.3-13.8c12.6,0,24.4,4.9,33.3,13.8
		c8.9,8.9,13.8,20.7,13.8,33.3C115.6,80.6,110.7,92.5,101.8,101.4z"/>
        <path className="st0" d="M82.2,91h9.7c0.9,0,1.6-0.7,1.6-1.6V72.6c0-4.7-1.2-8.4-3.6-11c-2.2-2.4-5.4-3.7-9.2-3.7c-3.3,0-5.5,1-7.2,2.3
		v0c0-0.9-0.7-1.6-1.6-1.6h-9.7c-0.4,0-0.8,0.2-1.1,0.5c-0.3,0.3-0.5,0.7-0.4,1.2c0.1,2.7,0,28.9,0,29.2c0,0.4,0.2,0.8,0.5,1.1
		c0.3,0.3,0.7,0.5,1.1,0.5h9.7c0.9,0,1.6-0.7,1.6-1.6V73.1c0-0.9,0.1-1.4,0.2-1.8c0.3-0.8,1.3-2.6,3.5-2.6c0.9,0,3.4,0,3.4,5.1v15.7
		C80.6,90.3,81.3,91,82.2,91z"/>
        <path className="st0" d="M59,51c-0.1-3.9-3-6.6-7-6.6c-4.1,0-7.1,2.8-7.1,6.6s2.9,6.6,6.9,6.6h0.1C56,57.7,59,54.9,59,51
		C59,51.1,59,51.1,59,51z"/>
        <path className="st0" d="M47.1,58.6c-0.9,0-1.6,0.7-1.6,1.6v29.3c0,0.9,0.7,1.6,1.6,1.6h9.7c0.9,0,1.6-0.7,1.6-1.6V60.1
		c0-0.9-0.7-1.6-1.6-1.6H47.1z"/>     </svg>
}

export const XelIconMail = (props) => {
    return <svg viewBox="0 0 74 49" version={'1.0'}  {...props} >
        <path className="st7" d="M19.94,38.89h32.98c3.41,0,6.18-2.77,6.18-6.18V12.09c0-3.4-2.77-6.18-6.18-6.18H19.94
	c-3.4,0-6.18,2.77-6.18,6.18V32.7C13.75,36.11,16.53,38.89,19.94,38.89z M54.98,31.84l-9.45-9.45l9.45-9.45V31.84z M32.06,27.12
	c1.21,1.21,2.79,1.81,4.37,1.81s3.17-0.6,4.37-1.81l1.81-1.81l9.46,9.46H20.79l9.46-9.46L32.06,27.12z M37.89,24.21
	c-0.8,0.8-2.11,0.8-2.92,0L20.79,10.03h31.28L37.89,24.21z M27.33,22.39l-9.45,9.45v-18.9L27.33,22.39z"/>

    </svg>
}

export const XelIconInstagram = (props) => {
    return <svg  viewBox="0 0 136 136" version={'1.0'}  {...props} >
        <g id="Grupo_29" transform="translate(-50.046 -20.187)">
            <g id="Grupo_27" transform="translate(50.046 20.187)">
                <path id="Trazado_45" className="st0" d="M63.67,20.22c23.82-0.02,43.15,19.27,43.17,43.09c0.02,23.82-19.27,43.15-43.09,43.17
			S20.6,87.21,20.58,63.39c0-0.02,0-0.04,0-0.06C20.59,39.53,39.87,20.24,63.67,20.22 M63.67,18c-25.03,0-45.33,20.29-45.33,45.33
			s20.29,45.33,45.33,45.33S109,88.36,109,63.33C108.97,38.31,88.69,18.03,63.67,18z"/>
            </g>
            <g id="Grupo_28" transform="translate(58.265 28.421)">
                <path id="Trazado_46" className="st1" d="M72.61,31.82H38.33c-3.04-0.37-5.8,1.8-6.17,4.83c-0.06,0.46-0.05,0.93,0.01,1.4v34.22
			c-0.37,2.99,1.76,5.71,4.75,6.08c0.41,0.05,0.83,0.05,1.24,0.01h34.46c3.03,0.37,5.79-1.79,6.16-4.82c0.05-0.44,0.05-0.88,0-1.31
			V37.91c0.33-3.04-1.87-5.77-4.91-6.1C73.45,31.77,73.03,31.77,72.61,31.82z M68.85,37.11c4.61,0,4.61,0,4.61,4.63
			c0,4.51,0,4.51-4.53,4.51c-4.59,0-4.59,0-4.59-4.59C64.34,37.11,64.34,37.11,68.85,37.11z M55.49,46.06
			c4.98,0.03,9,4.09,8.97,9.07c-0.03,4.98-4.09,9-9.07,8.97c-4.98-0.03-9-4.09-8.97-9.07c0-0.01,0-0.01,0-0.02
			C46.57,50.08,50.56,46.14,55.49,46.06z M73.46,70.34c-0.04,2.16-0.6,2.76-2.76,2.76H40.23c-2.12,0-2.74-0.6-2.74-2.76
			c0-5.63,0.02-11.34-0.08-17.02c0-1.61,0.45-1.93,1.93-1.89c2.14,0.16,2.18,0.08,1.93,2.16c-0.85,7.84,4.81,14.88,12.65,15.73
			c7.84,0.85,14.88-4.81,15.73-12.65c0.15-1.4,0.1-2.82-0.17-4.21c-0.16-0.82,0.04-0.95,0.76-0.95c3.21-0.27,3.21-0.31,3.21,3.02
			L73.46,70.34z"/>
            </g>
        </g>
    </svg>
}

export const XelIconTwitter = (props) => {
    return <svg  viewBox="0 0 136 136" version={'1.0'}  {...props} >
        <g id="Grupo_26" transform="translate(-34.341 -25.872) rotate(8)">
            <g id="Grupo_24" transform="translate(42.262 20.187)">
                <path id="Trazado_43" className="st0" d="M67.21,11.07c23.99,0,43.45,19.44,43.45,43.43c0,23.99-19.44,43.45-43.43,43.45
			c-23.99,0-43.45-19.44-43.45-43.43c0-0.01,0-0.01,0-0.02c-0.01-23.98,19.43-43.43,43.41-43.43C67.2,11.07,67.21,11.07,67.21,11.07
			 M67.21,8.83c-25.22,0-45.67,20.45-45.67,45.67c0,25.22,20.45,45.67,45.67,45.67c25.22,0,45.67-20.45,45.67-45.67
			C112.85,29.3,92.42,8.87,67.21,8.83z"/>
            </g>
            <g id="Grupo_25" transform="translate(49.429 28.698)">
                <path id="Trazado_44" className="st1" d="M33.77,63.7c4.08,0.31,8.16-0.39,11.9-2.05c1.71-0.75,3.31-1.72,4.78-2.87
			c-1.72-0.04-3.42-0.47-4.95-1.24c-2.52-1.27-4.46-3.45-5.43-6.1c-0.25-0.6-0.25-0.6,0.41-0.56c1.4,0.23,2.83,0.14,4.19-0.27
			c-0.94-0.23-1.85-0.58-2.7-1.04c-3.65-1.9-6-5.62-6.16-9.74c-0.04-0.5,0.08-0.56,0.56-0.33c1.39,0.72,2.92,1.13,4.48,1.2
			c-0.72-0.64-1.37-1.16-1.93-1.74c-3.18-3.54-3.89-8.66-1.8-12.94c0.29-0.56,0.39-0.56,0.77-0.1c5.07,6,12.16,9.95,19.92,11.11
			c0.81,0.16,1.7,0.16,2.53,0.33c0.56,0.08,0.62-0.16,0.54-0.6c-1.08-6.22,3.09-12.14,9.31-13.22c3.55-0.62,7.18,0.48,9.8,2.95
			c0.34,0.32,0.82,0.44,1.27,0.31c2.12-0.49,4.15-1.29,6.03-2.39c0.18-0.05,0.35-0.15,0.48-0.29c-0.78,2.62-2.49,4.86-4.81,6.3
			c2.12-0.25,4.19-0.79,6.16-1.6c0.04,0.27-0.16,0.44-0.29,0.64c-1.34,1.83-2.93,3.47-4.73,4.85c-0.31,0.19-0.48,0.55-0.44,0.91
			c0.2,8.15-2.6,16.09-7.88,22.31c-4.84,5.77-11.62,9.58-19.07,10.69c-7.85,1.37-15.93-0.2-22.7-4.42L33.77,63.7z"/>
            </g>
        </g>
    </svg>
}

export const XelIconWhatsApp = (props) => {
    return <svg  viewBox="0 0 179 162" version={'1.0'}  {...props} >
        <path className="st0" d="M89.6,16.6c35.4,0,64.1,28.7,64.1,64.1s-28.7,64.1-64.1,64.1s-64.1-28.7-64.1-64.1S54.2,16.6,89.6,16.6
		 M89.6,13.3c-37.1,0-67.4,30.2-67.4,67.4S52.5,148,89.6,148c37.2,0,67.4-30.2,67.4-67.4S126.8,13.3,89.6,13.3L89.6,13.3z"/>
        <path className="st0" d="M127.6,71.5c-4.2-17.7-19.5-29.8-38-30.1c-2.4,0.1-5.1,0.3-7.4,0.8C56.3,47.4,43,75.9,55.5,98.9
		c0.5,0.7,0.5,1.2,0.4,1.9c-1.8,6-3.5,12.1-5.1,18.1c-0.3,1.1-0.3,1.1,0.7,0.8c6.3-1.7,12.6-3.4,18.9-4.9c0.5-0.2,0.9-0.2,1.4,0.1
		c8.1,4.1,16.6,5.3,25.6,3.6C118.7,114.4,132.8,92.6,127.6,71.5z M95.1,112.3c-1.9,0.3-3.6,0.5-4.5,0.4c-6.9,0-12.4-1.6-17.6-4.5
		c-0.7-0.4-1.3-0.6-2-0.3c-3.5,0.8-7,1.8-10.4,2.7c-0.7,0.2-0.8,0.1-0.6-0.6c0.9-3.2,1.8-6.5,2.8-9.9c0.2-0.9,0.2-1.6-0.4-2.3
		c-7.1-11.5-6.8-25.7,1.2-36.3c8.2-11,19.5-15.6,33-12.7c13.2,2.8,21.7,11.4,24.7,24.6C125.5,91.4,113.3,109.3,95.1,112.3z"/>
        <path className="st0" d="M99.8,98.9c-1.4,0.1-2.7-0.1-4.1-0.5c-8.9-2.6-15.7-8.1-21.1-15.5c-2-2.8-3.9-5.7-4.5-9.2
		c-0.8-4,0.5-7.7,3.5-10.4c1-1,4-1.4,5.2-0.8c0.4,0.3,0.6,0.6,0.9,1c1.1,2.8,2.3,5.3,3.4,8c0.2,0.6,0.1,1.1-0.2,1.7
		c-0.6,1.2-1.5,2.2-2.4,3.2c-0.8,0.6-0.8,1.3-0.2,2.1c3.2,5.3,7.5,9.2,13.3,11.4c0.8,0.3,1.5,0.2,2-0.4c1-1.2,2-2.4,2.9-3.7
		c0.6-0.8,1.3-0.9,2.2-0.5c1.9,0.8,3.8,1.7,5.6,2.6c0.5,0.3,0.9,0.4,1.3,0.7c1.9,1,1.9,1.1,1.7,3.1c-0.2,4.1-3.4,6.1-6.9,7.1
		C101.5,99.1,100.7,99.1,99.8,98.9z"/>
    </svg>
}


export const XelIconPromotion = (props) => {
    return <svg viewBox="0 0 81 99" version={'1.0'}  {...props} >
        <path className="st6" d="M36.17,26.13c0.27,0,0.54,0,0.81,0c0.7,0.42,1.41,0.83,2.11,1.26c0.2,0.12,0.38,0.28,0.57,0.43
		c0.45,0.35,0.93,0.44,1.47,0.24c0.44-0.16,0.89-0.32,1.33-0.47c1.72-0.61,2.71-0.29,3.75,1.21c0.29,0.42,0.57,0.83,0.87,1.25
		c0.26,0.37,0.6,0.58,1.07,0.58c0.67-0.01,1.35-0.03,2.02-0.02c1.35,0.03,2.14,0.59,2.6,1.85c0.16,0.44,0.31,0.89,0.41,1.35
		c0.19,0.94,0.72,1.47,1.66,1.66c0.54,0.11,1.07,0.3,1.59,0.5c0.9,0.36,1.47,1.02,1.56,2c0.05,0.62,0.06,1.25,0.02,1.87
		c-0.07,0.89,0.2,1.57,1.01,2.03c0.47,0.27,0.9,0.59,1.33,0.93c0.92,0.72,1.22,1.67,0.9,2.79c-0.13,0.47-0.27,0.95-0.47,1.39
		c-0.44,0.93-0.24,1.68,0.39,2.46c0.54,0.67,0.92,1.47,1.37,2.22c0,0.27,0,0.54,0,0.81c-0.42,0.7-0.83,1.41-1.26,2.11
		c-0.13,0.21-0.3,0.41-0.46,0.61c-0.32,0.42-0.41,0.88-0.23,1.38c0.19,0.52,0.37,1.05,0.54,1.58c0.5,1.54,0.2,2.48-1.1,3.43
		c-0.45,0.33-0.92,0.63-1.37,0.95c-0.4,0.28-0.63,0.65-0.62,1.15c0.01,0.59,0.03,1.18,0.03,1.77c0,1.58-0.59,2.4-2.1,2.89
		c-0.5,0.16-1,0.3-1.5,0.44c-0.61,0.17-0.99,0.54-1.15,1.15c-0.15,0.55-0.31,1.1-0.5,1.64c-0.48,1.37-1.28,1.94-2.73,1.95
		c-0.46,0-0.91-0.01-1.37-0.05c-0.82-0.07-1.44,0.2-1.87,0.92c-0.31,0.52-0.67,1.01-1.05,1.49c-0.68,0.85-1.58,1.12-2.63,0.85
		c-0.49-0.12-0.98-0.27-1.43-0.48c-0.96-0.46-1.74-0.25-2.55,0.41c-0.66,0.53-1.45,0.9-2.18,1.34c-0.27,0-0.54,0-0.81,0
		c-0.69-0.41-1.39-0.81-2.07-1.23c-0.23-0.14-0.43-0.32-0.65-0.49c-0.42-0.32-0.88-0.41-1.38-0.23c-0.48,0.17-0.95,0.34-1.43,0.5
		c-1.68,0.58-2.66,0.26-3.67-1.18c-0.3-0.43-0.59-0.86-0.89-1.29c-0.26-0.37-0.6-0.58-1.07-0.58c-0.61,0.01-1.21,0.02-1.82,0.02
		c-1.58,0-2.4-0.59-2.89-2.09c-0.16-0.5-0.3-1-0.44-1.5c-0.17-0.61-0.54-0.98-1.15-1.16c-0.66-0.19-1.33-0.38-1.97-0.63
		c-0.9-0.35-1.48-1.01-1.56-2c-0.06-0.69-0.05-1.38-0.01-2.07c0.03-0.76-0.22-1.33-0.88-1.74c-0.51-0.32-1.01-0.67-1.49-1.05
		c-0.89-0.7-1.17-1.62-0.88-2.71c0.13-0.47,0.26-0.95,0.47-1.39c0.46-0.96,0.26-1.74-0.4-2.55c-0.53-0.65-0.9-1.45-1.34-2.18
		c0-0.27,0-0.54,0-0.81c0.42-0.7,0.83-1.41,1.26-2.11c0.13-0.21,0.3-0.41,0.45-0.61c0.32-0.42,0.41-0.88,0.23-1.38
		c-0.18-0.51-0.36-1.02-0.53-1.53c-0.53-1.59-0.22-2.54,1.13-3.51c0.4-0.29,0.8-0.56,1.21-0.83c0.53-0.34,0.78-0.82,0.76-1.45
		c-0.02-0.52-0.04-1.05-0.04-1.57c0-1.58,0.6-2.4,2.1-2.89c0.48-0.16,0.97-0.3,1.46-0.43c0.65-0.17,1.04-0.57,1.22-1.22
		c0.18-0.65,0.37-1.3,0.61-1.92c0.35-0.9,1.02-1.48,2-1.56c0.65-0.05,1.31-0.05,1.97-0.01c0.82,0.05,1.44-0.2,1.87-0.93
		c0.29-0.49,0.63-0.96,0.98-1.41c0.72-0.92,1.67-1.22,2.79-0.9c0.47,0.13,0.95,0.27,1.39,0.47c0.93,0.43,1.68,0.24,2.46-0.39
		C34.63,26.96,35.43,26.58,36.17,26.13z M24.01,32.14c0-0.01,0-0.02,0-0.02c-0.29,0-0.57,0-0.86,0c-0.86,0.01-1.1,0.18-1.37,0.98
		c-0.18,0.53-0.33,1.06-0.48,1.6c-0.32,1.07-1.01,1.77-2.08,2.08c-0.53,0.16-1.07,0.31-1.6,0.49c-0.81,0.26-0.98,0.51-0.98,1.37
		c0,0.42,0.01,0.84,0.04,1.26c0.1,1.35-0.37,2.41-1.53,3.14c-0.43,0.27-0.85,0.55-1.25,0.86c-0.59,0.45-0.68,0.74-0.46,1.45
		c0.16,0.51,0.36,1.02,0.54,1.53c0.41,1.14,0.26,2.19-0.51,3.14c-0.29,0.36-0.57,0.71-0.84,1.08c-0.62,0.84-0.62,1.1-0.01,1.94
		c0.29,0.4,0.6,0.78,0.9,1.16c0.69,0.9,0.87,1.88,0.49,2.95c-0.2,0.56-0.41,1.11-0.58,1.67c-0.21,0.68-0.11,0.98,0.45,1.41
		c0.5,0.37,1.03,0.71,1.53,1.07c0.77,0.56,1.23,1.29,1.24,2.26c0.01,0.69-0.03,1.38-0.02,2.07c0.01,0.7,0.21,0.98,0.87,1.2
		c0.54,0.18,1.09,0.34,1.65,0.5c1.14,0.32,1.85,1.05,2.17,2.19c0.16,0.55,0.33,1.1,0.49,1.65c0.18,0.57,0.57,0.86,1.17,0.85
		c0.52-0.01,1.05-0.02,1.57-0.04c1.27-0.06,2.27,0.4,2.96,1.49c0.27,0.43,0.56,0.85,0.86,1.25c0.48,0.65,0.76,0.74,1.52,0.5
		c0.53-0.17,1.05-0.36,1.58-0.55c1.09-0.39,2.09-0.22,3,0.49c0.44,0.34,0.87,0.69,1.33,1.01c0.63,0.44,0.93,0.44,1.56,0
		c0.48-0.34,0.94-0.72,1.41-1.07c0.86-0.64,1.8-0.8,2.82-0.45c0.45,0.15,0.89,0.31,1.34,0.47c1.14,0.4,1.37,0.32,2.07-0.68
		c0.3-0.43,0.58-0.87,0.9-1.29c0.53-0.7,1.22-1.11,2.11-1.13c0.66-0.01,1.32,0.02,1.97,0.02c0.89,0,1.13-0.17,1.41-1.01
		c0.17-0.51,0.32-1.03,0.47-1.55c0.32-1.09,1.02-1.8,2.11-2.12c0.58-0.17,1.16-0.35,1.74-0.53c0.56-0.17,0.82-0.57,0.81-1.14
		c-0.01-0.51-0.02-1.01-0.05-1.52c-0.07-1.31,0.4-2.33,1.52-3.05c0.44-0.28,0.86-0.59,1.29-0.89c0.52-0.36,0.66-0.83,0.45-1.41
		c-0.18-0.51-0.36-1.02-0.54-1.52c-0.43-1.16-0.27-2.22,0.51-3.18c0.31-0.38,0.61-0.77,0.89-1.16c0.52-0.73,0.53-1.01,0.01-1.73
		c-0.33-0.47-0.7-0.91-1.04-1.37c-0.63-0.85-0.78-1.78-0.44-2.78c0.19-0.57,0.41-1.14,0.59-1.72c0.22-0.71,0.13-1-0.47-1.45
		c-0.53-0.4-1.08-0.75-1.61-1.14c-0.7-0.52-1.12-1.22-1.14-2.1c-0.02-0.69,0.02-1.38,0.01-2.07c-0.01-0.79-0.19-1.04-0.94-1.3
		c-0.45-0.15-0.9-0.29-1.36-0.4c-1.3-0.32-2.11-1.13-2.45-2.42c-0.12-0.47-0.26-0.94-0.41-1.41c-0.25-0.73-0.5-0.91-1.26-0.92
		c-0.51-0.01-1.01,0.02-1.52,0.04c-1.25,0.06-2.23-0.41-2.92-1.47c-0.28-0.42-0.56-0.85-0.86-1.25c-0.5-0.67-0.77-0.76-1.56-0.51
		c-0.51,0.16-1.02,0.35-1.53,0.53c-1.11,0.4-2.12,0.23-3.05-0.5c-0.45-0.35-0.91-0.7-1.37-1.04c-0.48-0.36-0.96-0.36-1.44,0
		c-0.5,0.38-0.99,0.75-1.49,1.13c-0.85,0.63-1.77,0.78-2.78,0.45c-0.45-0.15-0.89-0.31-1.34-0.47c-1.16-0.41-1.4-0.33-2.11,0.69
		c-0.28,0.4-0.55,0.81-0.83,1.21c-0.54,0.73-1.26,1.16-2.18,1.19C24.75,32.15,24.38,32.14,24.01,32.14z"/>
        <path className="st6" d="M36.53,32.62c9.44,0.02,17.41,6.58,19.14,15.91c1.09,5.89-0.35,11.21-4.1,15.89
		c-0.36,0.44-0.78,0.53-1.14,0.25c-0.38-0.28-0.41-0.75-0.04-1.2c2.01-2.47,3.37-5.26,3.83-8.42c1.04-7.18-1.29-13.09-7.08-17.43
		c-6.58-4.93-15.4-4.61-21.84,0.53c-0.04,0.03-0.08,0.06-0.12,0.09c-0.46,0.35-0.92,0.34-1.2-0.04c-0.28-0.36-0.21-0.8,0.25-1.13
		c0.9-0.65,1.79-1.34,2.75-1.89C29.92,33.47,33.13,32.66,36.53,32.62z"/>
        <path className="st6" d="M17.12,52.38c0.09-4.97,1.52-9.07,4.44-12.65c0.38-0.47,0.8-0.57,1.17-0.28c0.38,0.3,0.39,0.76,0,1.24
		c-1.88,2.3-3.1,4.92-3.72,7.83c-1.86,8.73,3.45,17.9,11.98,20.54c6.15,1.91,11.79,0.84,16.88-3.11c0.07-0.05,0.13-0.11,0.2-0.15
		c0.4-0.28,0.81-0.25,1.09,0.07c0.29,0.34,0.27,0.82-0.16,1.11c-1.19,0.82-2.35,1.73-3.63,2.38c-11.28,5.69-24.68-0.52-27.7-12.79
		c-0.14-0.59-0.26-1.19-0.33-1.79C17.23,53.87,17.17,52.94,17.12,52.38z"/>
        <path className="st6" d="M35.48,45.63c-0.01,1.17-0.35,2.24-1.09,3.18c-1.64,2.08-4.43,2.17-6.2,0.2c-1.7-1.9-1.66-5.09,0.1-6.95
		c1.72-1.82,4.4-1.74,6,0.19C35.1,43.22,35.48,44.35,35.48,45.63z M28.46,45.52c0,1,0.28,1.86,0.95,2.57
		c1.03,1.09,2.54,1.08,3.58,0.01c1.29-1.33,1.27-3.75-0.03-5.06c-1.03-1.04-2.54-1.03-3.55,0.03
		C28.76,43.77,28.48,44.61,28.46,45.52z"/>
        <path className="st6" d="M46.22,58.66c-0.02,1.3-0.47,2.54-1.48,3.54c-1.6,1.58-3.98,1.57-5.58-0.01c-1.89-1.87-1.97-5.18-0.17-7.13
		c1.74-1.88,4.43-1.81,6.06,0.16C45.84,56.18,46.21,57.3,46.22,58.66z M44.7,58.47c-0.02-0.99-0.35-1.91-1.14-2.62
		c-0.95-0.85-2.26-0.85-3.22-0.01c-1.5,1.32-1.53,4-0.05,5.34c1,0.91,2.4,0.89,3.37-0.06C44.39,60.41,44.69,59.52,44.7,58.47z"/>
        <path className="st6" d="M41.51,42.26c-0.1,0.3-0.15,0.48-0.22,0.65c-2.58,6.17-5.17,12.33-7.77,18.49c-0.11,0.26-0.37,0.54-0.63,0.65
		c-0.18,0.07-0.6-0.07-0.69-0.23c-0.13-0.26-0.19-0.66-0.08-0.93c0.75-1.88,1.54-3.73,2.33-5.6c1.79-4.27,3.58-8.54,5.38-12.81
		c0.08-0.19,0.13-0.44,0.27-0.53c0.23-0.15,0.56-0.32,0.79-0.26C41.13,41.76,41.32,42.08,41.51,42.26z"/>

    </svg>
}


export const XelIconSecureBuyPhone = (props) => {
    return <svg viewBox="0 0 77 99" version={'1.0'}  {...props} >
        <path className="st6" d="M17.75,52.53c0-7.74,0-15.48,0-23.22c0-3.21,1.95-5.18,5.15-5.18c8.59,0,17.18,0,25.77,0
		c3.12,0,5.11,1.98,5.11,5.1c0,8.29,0.01,16.59-0.01,24.88c0,0.5,0.16,0.7,0.62,0.87c2.92,1.04,5.82,2.11,8.72,3.16
		c1.01,0.37,1.11,0.5,1.1,1.54c-0.03,2.45,0.11,4.91-0.13,7.34c-0.53,5.41-3.28,9.49-7.92,12.3c-0.83,0.5-1.71,0.91-2.53,1.41
		c-0.56,0.34-1.07,0.36-1.59-0.02c-0.39-0.29-0.73-0.26-1.19-0.08C50.26,80.85,49.61,81,49,81c-8.81,0.02-17.61,0.02-26.42,0.01
		c-2.77,0-4.82-2.07-4.83-4.84C17.74,68.29,17.75,60.41,17.75,52.53z M42.51,71.52c-0.32-1.48-0.81-2.91-0.91-4.36
		c-0.17-2.54-0.12-5.09-0.15-7.63c-0.01-0.83,0.13-1.02,0.91-1.3c2.98-1.09,5.96-2.19,8.95-3.24c0.46-0.16,0.57-0.38,0.57-0.84
		c-0.02-7.25-0.01-14.49-0.01-21.74c0-0.21,0-0.42,0-0.64c-10.78,0-21.49,0-32.19,0c0,13.28,0,26.52,0,39.77
		C27.35,71.52,34.97,71.52,42.51,71.52z M43.35,63.26C43.35,63.26,43.35,63.26,43.35,63.26c0.01,0.81-0.01,1.62,0.01,2.43
		c0.09,3.29,1.19,6.2,3.26,8.76c1.62,2,3.76,3.28,6.01,4.42c0.13,0.07,0.38,0.01,0.53-0.07c0.8-0.42,1.62-0.83,2.38-1.32
		c3.88-2.48,6.19-5.99,6.64-10.59c0.22-2.21,0.11-4.46,0.11-6.69c0-0.16-0.22-0.4-0.39-0.47c-2.9-1.07-5.81-2.13-8.73-3.17
		c-0.22-0.08-0.51-0.06-0.73,0.02c-2.9,1.03-5.78,2.09-8.68,3.13c-0.35,0.12-0.44,0.3-0.43,0.64
		C43.36,61.32,43.35,62.29,43.35,63.26z M49.1,79.12c-0.19-0.15-0.25-0.21-0.32-0.25c-2.03-1.33-3.67-3.03-4.97-5.08
		c-0.12-0.19-0.46-0.34-0.71-0.34c-7.64-0.02-15.28-0.01-22.92-0.01c-0.17,0-0.34,0.02-0.54,0.03c0,0.83,0,1.62,0,2.4
		c0,2.14,1.11,3.25,3.24,3.25c8.49,0,16.98,0,25.46,0C48.54,79.12,48.74,79.12,49.1,79.12z M19.67,29.78c10.75,0,21.46,0,32.16,0
		c0.39-2.45-1.25-3.84-3.3-3.78c-3.37,0.1-6.75,0.03-10.12,0.03c-5.25,0-10.5,0-15.75,0c-1.09,0-1.95,0.41-2.56,1.32
		C19.6,28.08,19.6,28.9,19.67,29.78z"/>
        <path className="st6" d="M27.23,43.09c0-0.97-0.01-1.86,0-2.75c0.02-2.72,2.08-4.83,4.72-4.83c2.67-0.01,4.74,2.1,4.76,4.86
		c0.01,0.89,0,1.77,0,2.73c0.88,0,1.73,0,2.57,0c0.89,0,1.22,0.32,1.22,1.2c0,3.67,0.01,7.35,0,11.02
		c-0.01,2.77-2.07,4.82-4.84,4.84c-2.47,0.01-4.94,0.01-7.41,0c-2.74-0.02-4.8-2.07-4.81-4.8c-0.01-3.69-0.01-7.39,0-11.08
		c0-0.85,0.33-1.18,1.18-1.18C25.47,43.09,26.31,43.09,27.23,43.09z M25.33,45c0,0.23,0,0.4,0,0.57c0,3.22,0,6.44,0,9.65
		c0,1.85,1.19,3.03,3.04,3.03c2.37,0,4.74,0,7.11,0c1.97,0,3.12-1.16,3.12-3.14c0-3.18,0-6.36,0-9.54c0-0.19-0.02-0.38-0.03-0.56
		c-0.65,0-1.25,0-1.85,0c-0.07,1.43-0.28,1.88-0.94,1.83c-1.23-0.08-0.91-1.1-1-1.84c-1.91,0-3.77,0-5.67,0c0,0.33,0.02,0.63,0,0.92
		c-0.04,0.56-0.44,0.94-0.95,0.93c-0.51-0.01-0.9-0.39-0.93-0.96c-0.02-0.3,0-0.61,0-0.92C26.56,45,25.99,45,25.33,45z M34.81,43.07
		c0-1,0.02-1.94,0-2.88c-0.03-1.48-1.18-2.68-2.62-2.78c-1.46-0.1-2.82,0.89-2.99,2.36c-0.13,1.08-0.03,2.19-0.03,3.3
		C31.04,43.07,32.89,43.07,34.81,43.07z"/>
        <path className="st6" d="M51.87,68.32c1.51-1.51,2.94-2.95,4.38-4.38c0.15-0.15,0.3-0.32,0.48-0.43c0.4-0.24,0.8-0.22,1.14,0.11
		c0.36,0.34,0.4,0.76,0.15,1.18c-0.08,0.13-0.2,0.25-0.31,0.36c-1.66,1.66-3.32,3.32-4.98,4.98c-0.63,0.63-1.07,0.62-1.72-0.01
		c-0.76-0.75-1.51-1.5-2.26-2.26c-0.61-0.61-0.68-1.14-0.22-1.59c0.44-0.43,0.96-0.36,1.54,0.22
		C50.66,67.08,51.24,67.67,51.87,68.32z"/>
        <path className="st6" d="M35.82,75.33c0.87,0,1.74-0.01,2.61,0c0.7,0.01,1.12,0.37,1.12,0.95c0,0.57-0.43,0.94-1.12,0.95
		c-1.78,0.01-3.55,0.01-5.33,0c-0.7,0-1.12-0.37-1.12-0.95c0-0.58,0.42-0.94,1.12-0.95C34,75.32,34.91,75.33,35.82,75.33z"/>
        <path className="st6" d="M35.72,28.87c-0.32,0-0.63,0.02-0.95,0c-0.53-0.04-0.89-0.42-0.91-0.92c-0.01-0.52,0.36-0.94,0.92-0.96
		c0.65-0.03,1.3-0.03,1.95,0c0.53,0.02,0.89,0.42,0.91,0.91c0.01,0.51-0.37,0.92-0.92,0.96C36.4,28.88,36.06,28.87,35.72,28.87z"/>

    </svg>
}

export const XelIconRephone = (props) => {
    return <svg viewBox="0 0 192 222" version={'1.0'}  {...props} >
        <path className="st0" d="M159,101.72c0,24.14,0,48.29,0,72.43c0,9.96-6.11,16.05-16.08,16.05c-26.66,0-53.31,0-79.97,0
			c-9.85,0-15.99-6.13-15.99-15.95c0-48.35,0-96.7,0-145.05c0-9.83,6.14-15.95,15.99-15.95c26.72,0,53.44,0,80.15,0
			c9.73,0,15.9,6.15,15.9,15.86C159,53.31,159,77.51,159,101.72z M67.59,19.14c-1.69,0-3.1,0-4.51,0c-6.8,0-10.23,3.4-10.23,10.15
			c0,48.29,0,96.58,0,144.86c0,6.75,3.43,10.15,10.24,10.15c26.53,0,53.07,0,79.6,0c7.05,0,10.41-3.33,10.41-10.34
			c0-48.16,0-96.33,0-144.49c0-0.61,0.01-1.23-0.01-1.84c-0.17-3.89-2.77-7.35-6.59-8.09c-2.6-0.5-5.32-0.36-8.14-0.52
			c0,3.03,0.01,5.72,0,8.41c-0.03,5.66-3.7,9.4-9.31,9.4c-17.38,0.02-34.76,0.02-52.15,0c-5.53-0.01-9.26-3.74-9.3-9.23
			C67.58,24.85,67.59,22.1,67.59,19.14z M73.49,19.2c0,2.64,0,5.08,0,7.53c0,3.36,0.86,4.21,4.27,4.21c16.81,0,33.63,0,50.44,0
			c0.55,0,1.11,0.05,1.65-0.04c1.43-0.23,2.47-1.09,2.53-2.52c0.13-3.04,0.04-6.09,0.04-9.18C112.72,19.2,93.23,19.2,73.49,19.2z"/>
        <path className="st0" d="M59.4,92.36c0.09-7.54,2.57-13.71,7.52-18.84c3.33-3.44,6.75-6.79,10.15-10.17c1.86-1.84,3.36-1.86,5.23-0.01
			c3.88,3.84,7.75,7.71,11.59,11.59c1.71,1.73,1.7,3.21-0.01,4.95c-3.14,3.19-6.34,6.34-9.49,9.52c-1.9,1.92-1.9,3.31-0.01,5.21
			c6.59,6.61,13.19,13.21,19.8,19.8c1.9,1.9,3.29,1.91,5.21,0.01c3.1-3.06,6.16-6.17,9.25-9.24c2.01-2,3.47-1.99,5.5,0.02
			c3.75,3.72,7.48,7.45,11.21,11.19c2.03,2.04,2.02,3.41-0.03,5.47c-3.25,3.26-6.48,6.54-9.77,9.77
			c-10.62,10.41-27.02,10.38-37.58-0.09c-6.93-6.87-13.82-13.79-20.71-20.71C62.07,105.62,59.5,99.31,59.4,92.36z M73.31,75.56
			c-9.99,8.63-10.83,21.91-1.82,31.16c6.72,6.91,13.6,13.67,20.43,20.47c4.51,4.49,9.96,6.64,16.32,6.22
			c6.21-0.41,11.12-3.33,14.78-7.69c-2.84-2.84-5.55-5.54-8.44-8.42c-0.46,0.53-1.05,1.33-1.77,1.99
			c-3.51,3.22-8.62,3.34-12.01,0.03c-7.2-7.04-14.32-14.16-21.36-21.36c-3.29-3.37-3.16-8.56,0.07-12.01
			c0.7-0.75,1.56-1.35,2.25-1.94C78.78,81.02,76.1,78.35,73.31,75.56z M77.78,70.93c2.88,2.88,5.56,5.55,8.33,8.32
			c0.5-0.55,1.1-1.2,1.55-1.69c-2.75-2.73-5.46-5.43-8.24-8.2C78.96,69.81,78.35,70.39,77.78,70.93z M127.68,120.81
			c0.55-0.48,1.21-1.07,1.7-1.49c-2.78-2.77-5.48-5.48-8.09-8.09c-0.57,0.42-1.28,0.96-1.83,1.37
			C122.36,115.5,125.04,118.17,127.68,120.81z"/>
        <path className="st0" d="M102.97,172.5c10.38,0,20.76,0,31.14,0c0.55,0,1.12-0.05,1.65,0.04c1.59,0.29,2.55,1.27,2.55,2.9
			c0,1.63-0.96,2.6-2.55,2.9c-0.54,0.1-1.1,0.05-1.65,0.05c-20.76,0-41.52,0-62.28,0c-0.55,0-1.12,0.05-1.65-0.05
			c-1.58-0.3-2.55-1.27-2.54-2.9c0-1.63,0.96-2.61,2.55-2.9c0.54-0.1,1.1-0.04,1.65-0.04C82.21,172.5,92.59,172.5,102.97,172.5z"/>
        <path className="st0" d="M116.04,48.65c16.72,0.43,30.95,14.84,31.15,31.55c0.03,2.35-0.98,3.72-2.8,3.81
			c-1.88,0.09-3.02-1.28-3.1-3.72c-0.44-13.89-11.7-25.3-25.4-25.74c-2.78-0.09-4.17-1.16-4.05-3.13
			C111.95,49.49,113.34,48.58,116.04,48.65z"/>
        <path className="st0" d="M135.39,80.38c0.04,2.16-1.02,3.54-2.79,3.62c-1.76,0.09-2.96-1.21-3.1-3.35
			c-0.5-8.08-6.25-13.85-14.26-14.32c-2.18-0.13-3.45-1.26-3.4-3.04c0.05-1.79,1.41-2.88,3.56-2.85
			C126.02,60.59,135.2,69.74,135.39,80.38z"/>
        <path className="st0" d="M114.99,72.23c4.45-0.07,8.65,4.12,8.61,8.58c-0.02,1.75-1.09,3.03-2.67,3.19c-1.57,0.15-2.98-0.91-3.17-2.64
			c-0.23-2.03-1.2-3.05-3.25-3.28c-1.75-0.19-2.81-1.56-2.68-3.14C111.98,73.34,113.24,72.26,114.99,72.23z"/>
        <path className="st0" d="M29.1,135.6c0.36-3.66,1.15-4.87,3.17-4.91c2-0.04,2.8,1.12,3.31,4.82c1.27,0.15,2.58,0.17,3.81,0.48
		c3.49,0.89,5.88,4.19,5.75,7.75c-0.07,1.76-1.19,3.01-2.82,3.13c-1.65,0.12-2.95-0.91-3.32-2.63c-0.49-2.32-0.54-2.36-2.96-2.36
		c-1.97,0-3.94-0.01-5.91,0c-2.98,0.02-4.97,1.79-4.95,4.39c0.02,2.59,2.01,4.29,5.02,4.32c2.02,0.02,4.07-0.14,6.05,0.16
		c5.24,0.78,9.01,5.13,9.13,10.25c0.13,5.28-3.38,9.68-8.64,10.83c-0.4,0.09-0.8,0.19-1.18,0.28c-0.46,3.75-1.24,4.93-3.26,4.91
		c-2.03-0.02-2.86-1.27-3.19-4.97c-0.74,0-1.5,0.02-2.26,0c-4.28-0.12-7.66-3.46-7.83-7.72c-0.08-2.07,1.12-3.59,2.93-3.73
		c1.75-0.13,3.08,1.12,3.26,3.18c0.13,1.48,0.88,2.09,2.34,2.06c2.28-0.05,4.56,0.01,6.84-0.02c2.77-0.04,4.75-1.93,4.77-4.48
		c0.01-2.56-1.97-4.47-4.72-4.53c-2.12-0.05-4.28,0.14-6.37-0.14c-5.24-0.71-9.03-5.09-9.12-10.22c-0.08-5.21,3.47-9.55,8.69-10.6
		C28.1,135.77,28.56,135.7,29.1,135.6z"/>
    </svg>
}

export const XelIconCobertura = (props) => {
    return <svg viewBox="0 0 142 208" version={'1.0'}  {...props} >
        <path className="st0" d="M104.9,18.24c3.21,0.76,6.15,1.97,8.44,4.48c2.81,3.08,3.71,6.77,3.71,10.81
		c-0.01,46.11-0.01,92.22-0.02,138.33c0,1.1-0.06,2.2-0.16,3.3c-0.59,6.38-4.96,11.19-11.22,12.39c-0.26,0.05-0.51,0.18-0.76,0.27
		c-25.84,0-51.68,0-77.52,0c-3.21-0.76-6.15-1.97-8.44-4.48c-2.81-3.08-3.71-6.77-3.71-10.81c0.01-46.11,0.01-92.22,0.02-138.33
		c0-1.1,0.06-2.2,0.16-3.3c0.59-6.37,4.96-11.19,11.22-12.39c0.26-0.05,0.51-0.18,0.76-0.27C53.22,18.24,79.06,18.24,104.9,18.24z
		 M21.03,40.91c0,39.56,0,79.04,0,118.59c30.12,0,60.12,0,90.23,0c0-39.57,0-79.05,0-118.59C81.14,40.91,51.13,40.91,21.03,40.91z
		 M20.91,165.3c0,2.78-0.02,5.42,0.01,8.06c0.01,0.82,0.06,1.66,0.23,2.47c0.81,3.86,4.23,6.36,8.72,6.36
		c24.18,0.01,48.35,0,72.53,0c0.55,0,1.11,0,1.65-0.08c3.22-0.44,5.67-2.12,6.7-5.18c1.27-3.77,0.43-7.71,0.63-11.63
		C81.13,165.3,51.17,165.3,20.91,165.3z M111.48,35.08c-0.15-1.81-0.08-3.54-0.47-5.16c-0.92-3.82-4.19-6.03-8.62-6.03
		c-24.06-0.01-48.13-0.01-72.19,0c-6.61,0-10.26,4.45-9.11,11.19C51.09,35.08,81.09,35.08,111.48,35.08z"/>
        <path className="st0" d="M86.1,74.85c1.85,0,3.56,0,5.39,0c0,18.78,0,37.51,0,56.33c-1.8,0-3.54,0-5.39,0
		C86.1,112.4,86.1,93.63,86.1,74.85z"/>
        <path className="st0" d="M74.75,86.18c1.85,0,3.59,0,5.44,0c0,14.98,0,29.89,0,44.95c-1.78,0-3.55,0-5.44,0
		C74.75,116.2,74.75,101.28,74.75,86.18z"/>
        <path className="st0" d="M63.42,97.44c1.86,0,3.56,0,5.41,0c0,11.25,0,22.46,0,33.75c-1.8,0-3.55,0-5.41,0
		C63.42,119.95,63.42,108.78,63.42,97.44z"/>
        <path className="st0" d="M52.08,108.76c1.83,0,3.57,0,5.44,0c0,7.45,0,14.85,0,22.39c-1.78,0-3.56,0-5.44,0
		C52.08,123.67,52.08,116.26,52.08,108.76z"/>
        <path className="st0" d="M40.77,131.16c0-3.68,0-7.29,0-11.05c1.76,0,3.53,0,5.44,0c0,3.63,0,7.28,0,11.05
		C44.42,131.16,42.64,131.16,40.77,131.16z"/>
        <path className="st0" d="M63.43,170.99c1.8,0,3.55,0,5.42,0c0,1.8,0,3.59,0,5.46c-1.8,0-3.55,0-5.42,0
		C63.43,174.66,63.43,172.87,63.43,170.99z"/>
        <path className="st0" d="M57.56,176.48c-1.88,0-3.57,0-5.42,0c0-1.79,0-3.57,0-5.47c1.78,0,3.56,0,5.42,0
		C57.56,172.83,57.56,174.58,57.56,176.48z"/>
        <path className="st0" d="M80.17,176.49c-1.86,0-3.56,0-5.39,0c0-1.83,0-3.61,0-5.49c1.77,0,3.52,0,5.39,0
		C80.17,172.78,80.17,174.56,80.17,176.49z"/>
    </svg>
}

export const XelIconPhoneHand = (props) => {
    return <svg viewBox="0 0 193 209" version={'1.0'}  {...props} >
        <path className="st0" d="M112.28,175.69c-1.66-2.07-3.3-4.16-4.99-6.2c-0.39-0.47-0.92-0.99-1.48-1.14
		c-11.44-3.17-17.7-10.8-20.11-22.28c-1.72-8.2-0.57-15.58,3.79-22.69c3.64-5.94,6.94-12.08,10.39-18.14c1.32-2.32,1.29-2.93-0.32-5
		c-3.54-4.56-7.13-9.09-10.64-13.68c-5.13-6.71-4.8-15.09,0.78-21.42c2.73-3.1,6.73-3.24,9.6-0.25c3.48,3.61,6.9,7.28,10.32,10.94
		c1.3,1.39,1.41,2.85,0.35,3.89c-1.16,1.14-2.55,0.97-3.97-0.53c-3.26-3.44-6.5-6.9-9.76-10.35c-1.5-1.58-1.93-1.56-3.34,0.15
		c-3.42,4.12-3.61,9.87-0.33,14.2c3.45,4.56,7.02,9.04,10.54,13.55c3.29,4.21,3.47,6.7,0.82,11.35c-3.32,5.82-6.45,11.75-10,17.42
		c-4.03,6.44-4.96,13.09-3.22,20.48c2.24,9.56,7.71,15.6,17.34,17.82c0.78,0.18,1.59,0.73,2.11,1.35c1.54,1.83,2.95,3.78,4.4,5.68
		c1.64,2.15,1.58,3.05-0.33,4.84C113.59,175.69,112.93,175.69,112.28,175.69z"/>
        <path className="st0" d="M102.79,8.24c0.97,0.33,1.96,0.6,2.9,0.98c5.05,2.03,8.24,6.74,8.25,12.4c0.04,14.93,0.02,29.85,0.01,44.78
		c0,2-0.82,3.02-2.38,3.05c-1.61,0.04-2.54-1.06-2.54-3.09c-0.01-14.6,0-29.2-0.01-43.8c0-6.1-3.29-9.41-9.36-9.41
		c-17.6,0-35.19,0-52.79,0c-6.06,0-9.41,3.35-9.42,9.39c0,2.34,0.02,4.68-0.01,7.03c-0.02,1.74-1.05,2.86-2.49,2.82
		c-1.38-0.03-2.42-1.12-2.42-2.76c-0.01-3.27-0.17-6.56,0.19-9.79C33.38,14,37.4,9.84,43.16,8.52c0.26-0.06,0.5-0.19,0.75-0.28
		C63.54,8.24,83.17,8.24,102.79,8.24z"/>
        <path className="st0" d="M167.56,175.69c-2.11-2.4-4.25-4.78-6.33-7.2c-3.68-4.29-7.34-8.61-11-12.93c-1.3-1.53-1.36-3.02-0.19-4.01
		c1.22-1.03,2.58-0.77,3.93,0.81c5.29,6.21,10.58,12.42,15.87,18.64c1.66,1.96,1.48,3.2-0.65,4.69
		C168.65,175.69,168.1,175.69,167.56,175.69z"/>
        <path className="st0" d="M32.42,40.29c0.15-0.34,0.24-0.58,0.36-0.82c0.58-1.18,1.56-1.7,2.82-1.38c1.18,0.29,1.84,1.14,1.84,2.39
		c0.01,3.22,0.01,6.43,0.02,9.65c0,4.69,0.02,9.37-0.02,14.06c-0.01,0.96,0.2,1.44,1.26,1.66c1.19,0.24,2.32,0.81,3.7,1.33
		c0-0.74,0-1.37,0-2c0-14.06,0-28.12,0-42.19c0-3.91,1-4.88,4.96-4.89c3.65,0,7.3-0.01,10.96,0c2.51,0.01,3.71,0.8,4.55,3.16
		c0.38,1.05,0.97,1.42,2.06,1.41c5.94-0.03,11.88-0.04,17.82,0c1.11,0.01,1.67-0.43,2.04-1.45c0.82-2.28,2.03-3.11,4.41-3.12
		c3.65-0.02,7.3-0.02,10.96,0c2.64,0.02,3.94,1.3,3.94,3.91c0.01,11.55,0.01,23.11,0,34.66c0,1.92-0.89,3.04-2.4,3.08
		c-1.57,0.04-2.52-1.1-2.52-3.12c-0.01-10.46,0-20.93,0-31.39c0-0.65,0-1.3,0-2.22c-3.16,0-6.24-0.02-9.31,0.04
		c-0.28,0.01-0.65,0.5-0.82,0.84c-1.29,2.57-3.36,3.7-6.21,3.68c-5.61-0.04-11.23-0.01-16.84-0.01c-4.6,0-5.58-0.57-7.83-4.46
		c-3.54,0-7.07,0-10.8,0c0,0.68,0,1.26,0,1.85c0,14.93,0.02,29.87-0.03,44.8c0,1.28,0.36,2.14,1.4,2.87
		c1.11,0.78,2.13,1.7,3.19,2.56c6.66,5.42,5.9,13.43-1.76,17.62c2.18,2.62,3.37,5.54,2.64,9.01c-0.73,3.47-2.83,5.8-6.1,7.33
		c2.03,2.28,3.18,4.83,2.81,7.81c-0.17,1.37-0.5,2.84-1.19,4.01c-0.78,1.31-0.99,2.58-0.96,4.02c0.05,2.72,0.01,5.44,0.01,8.43
		c0.77,0,1.39,0,2.01,0c8.94,0,17.88,0,26.82,0c0.54,0,1.1-0.03,1.63,0.05c1.23,0.18,1.96,0.92,2.07,2.16
		c0.11,1.24-0.45,2.14-1.63,2.51c-0.61,0.19-1.29,0.2-1.93,0.2c-9.81,0.01-19.62,0.01-29.44,0.01c-3.35,0-4.48-1.14-4.5-4.53
		c-0.01-2.51-0.04-5.01-0.07-7.52c0-0.09-0.1-0.18-0.16-0.28c-1.5,0-3.01,0-4.41,0c0,3.76-0.38,7.49,0.11,11.1
		c0.51,3.76,4.18,6.15,8.36,6.16c10.19,0.02,20.39,0.01,30.58,0.01c0.49,0,0.99-0.03,1.47,0.02c1.39,0.14,2.2,0.92,2.28,2.32
		c0.07,1.42-0.74,2.23-2.06,2.53c-0.47,0.11-0.98,0.05-1.47,0.05c-10.14,0-20.28,0.01-30.42,0c-8.41-0.01-14.06-5.63-14.09-14.02
		c-0.01-3.27,0.05-6.54-0.05-9.81c-0.02-0.69-0.43-1.57-0.96-2.02c-2.47-2.14-5.05-4.15-7.58-6.22c-6.07-4.99-5.35-13.12,1.5-16.92
		c0.18-0.1,0.35-0.21,0.66-0.4c-2.33-2.66-3.34-5.67-2.63-9.09c0.71-3.38,2.81-5.69,6.04-7.2c-2.19-2.63-3.35-5.5-2.69-8.9
		c0.66-3.41,2.71-5.73,5.94-7.42c-0.51-0.53-0.87-0.97-1.3-1.32c-3.16-2.59-6.35-5.15-9.51-7.74c-4.48-3.67-5.59-8.89-2.87-13.34
		C21.82,39.32,26.95,37.93,32.42,40.29z M46.37,88.69c2.27-0.25,4.02-1.45,4.77-3.45c0.75-2,0.23-4.09-1.56-5.58
		c-3.26-2.71-6.55-5.4-9.88-8.02c-2.3-1.81-5.25-1.48-7,0.64c-1.79,2.17-1.46,5.17,0.87,7.1c3.22,2.67,6.46,5.33,9.77,7.89
		C44.21,87.95,45.36,88.23,46.37,88.69z M42.97,105.05c2.21-0.27,3.98-1.44,4.73-3.31c0.8-2.02,0.35-4.15-1.4-5.62
		c-3.25-2.73-6.55-5.4-9.87-8.03c-2.4-1.9-5.31-1.63-7.13,0.55c-1.76,2.12-1.42,5.16,0.9,7.1c3.18,2.65,6.37,5.27,9.64,7.8
		C40.74,104.23,41.92,104.56,42.97,105.05z M44.97,116.09c-0.58-1.12-0.9-2.54-1.79-3.31c-3.53-3.07-7.16-6.02-10.89-8.83
		c-1.97-1.48-4.79-0.89-6.32,0.96c-1.59,1.91-1.67,4.91,0.2,6.53c3.61,3.14,7.27,6.24,11.17,9
		C40.4,122.62,44.61,120.05,44.97,116.09z M32.52,59.42c0-3.82,0-7.13,0-10.45c0-2.45-0.85-3.68-3.17-4.61
		c-2.16-0.86-4.59-0.12-5.91,1.8c-1.27,1.84-1.23,4.49,0.42,6C26.54,54.61,29.4,56.83,32.52,59.42z"/>
        <path className="st0" d="M134.53,126.79c0.11-5.29,0.15-10.57-1.75-15.64c-1.53-4.09-3.82-7.67-6.83-10.83
		c-3.98-4.18-7.92-8.4-11.89-12.59c-0.85-0.89-1.36-1.85-0.81-3.08c0.74-1.65,2.67-1.98,4.06-0.62c1.44,1.41,2.79,2.91,4.17,4.38
		c2.77,2.93,5.54,5.86,8.29,8.8c6.38,6.82,9.66,14.89,9.68,24.25c0.01,3.92-0.1,7.85,0.06,11.76c0.05,1.21,0.56,2.56,1.28,3.54
		c1.68,2.27,3.63,4.34,5.45,6.51c1.32,1.58,1.33,3,0.1,4c-1.2,0.97-2.54,0.73-3.82-0.76c-1.95-2.27-3.88-4.56-5.82-6.84
		c-1.47-1.73-2.2-3.72-2.18-6C134.55,131.37,134.53,129.08,134.53,126.79z"/>
        <path className="st0" d="M73.13,102.43c3.87,0,7.74,0,11.61,0c2.23,0,3.34,0.8,3.36,2.42c0.02,1.64-1.09,2.49-3.29,2.49
		c-7.57,0-15.15,0.04-22.72-0.03c-1.56-0.02-1.97,0.5-1.92,1.97c0.1,2.72,0.1,5.45,0,8.17c-0.05,1.48,0.38,2,1.93,1.98
		c5.88-0.08,11.77-0.04,17.66-0.03c2.01,0.01,3.15,1.18,2.82,2.88c-0.27,1.39-1.23,2.02-2.58,2.02c-6.43,0.02-12.86,0.06-19.29-0.02
		c-3.06-0.04-5.33-2.38-5.41-5.45c-0.09-3.65-0.1-7.3,0-10.95c0.09-3.22,2.47-5.42,5.75-5.44C65.07,102.41,69.1,102.43,73.13,102.43
		z"/>
        <path className="st0" d="M82.87,53.87c1.09,0.96,2.28,1.91,3.36,2.98c1.23,1.22,1.25,2.73,0.17,3.74c-1.03,0.97-2.4,0.87-3.64-0.27
		c-0.08-0.07-0.17-0.14-0.23-0.23c-1.77-2.19-3.46-3.26-6.77-2.34c-5.3,1.48-10.59-1.86-12.59-6.92c-1.98-5-0.22-10.69,4.23-13.72
		c4.37-2.98,10.31-2.53,14.19,1.06c4.02,3.73,4.86,9.53,2.08,14.33C83.48,52.83,83.28,53.16,82.87,53.87z M73.89,53.12
		c3.64-0.03,6.48-2.93,6.45-6.58c-0.04-3.61-3-6.54-6.58-6.48c-3.58,0.05-6.52,3.05-6.47,6.62C67.33,50.31,70.25,53.15,73.89,53.12z
		"/>
    </svg>
}
export const XelIconFacebook = (props) => {
    return <svg viewBox="0 0 136 136" version={'1.0'}  {...props} >
        <g id="Grupo_23" transform="translate(-26.498 -20.163)">
            <g id="Grupo_21" transform="translate(26.498 20.163)">
                <path id="Trazado_41" className="st0" d="M66.87,20.39c25.46,0.03,46.08,20.7,46.04,46.17c-0.03,23.9-18.32,43.81-42.12,45.87
			c-1.3,0.18-2.61,0.26-3.92,0.22c-3.36,0.03-6.72-0.34-10-1.08C32,106.08,16.27,81.47,21.75,56.59
			C26.41,35.42,45.19,20.35,66.87,20.39 M66.87,18.01c-26.79-0.06-48.55,21.61-48.61,48.4c-0.05,22.81,15.81,42.58,38.09,47.48
			c3.46,0.74,6.99,1.11,10.52,1.1c1.44,0.04,2.88-0.04,4.32-0.22c26.67-2.39,46.35-25.95,43.96-52.62
			C112.9,37.16,91.96,18.01,66.87,18.01L66.87,18.01z"/>
            </g>
            <g id="Grupo_22" transform="translate(36.383 28.761)">
                <path id="Trazado_42" className="st0" d="M62.21,48.21c1.13-1.04,2.65-1.56,4.18-1.43c2.13,0.01,4.25,0.3,6.3,0.88l1.81-9.7
			c-3.69-1.19-7.55-1.75-11.43-1.65c-3.3-0.18-6.59,0.53-9.53,2.05c-2.28,1.07-4.1,2.92-5.13,5.22c-1.11,3.14-1.58,6.46-1.41,9.78
			v3.79h-7.65v11.05h7.64v34.78c3.28,0.74,6.63,1.11,10,1.08c1.31,0.04,2.62-0.04,3.92-0.22V68.2h10.12V57.15H60.91v-3.57
			C60.65,51.69,61.11,49.77,62.21,48.21z"/>
            </g>
        </g>
    </svg>
}

export const XelIconPhone = (props) => {
    return <svg viewBox="0 0 188 226" version={'1.0'}  {...props} >
        <path d="M140.37,34.68c3.57,0.73,6.77,2.11,9.19,5c2.01,2.41,3.09,5.15,3.32,8.25c0.05,0.66,0.05,1.32,0.05,1.98
			c0,46.16-0.01,92.33,0.02,138.49c0,5.04-1.49,9.41-5.71,12.3c-2.02,1.38-4.56,1.98-6.87,2.93c-27.28,0-54.56,0-81.84,0
			c-3.57-0.73-6.77-2.11-9.19-5c-1.97-2.36-3.05-5.05-3.3-8.09c-0.06-0.71-0.07-1.43-0.07-2.14c0-46.16,0.01-92.33-0.02-138.49
			c0-5.04,1.49-9.41,5.71-12.3c2.02-1.38,4.56-1.98,6.87-2.93C85.81,34.68,113.09,34.68,140.37,34.68z M147.21,169.77
			c0-37.57,0-74.99,0-112.47c-31.9,0-63.71,0-95.53,0c0,37.54,0,74.96,0,112.47C83.54,169.77,115.31,169.77,147.21,169.77z
			 M51.59,175.57c0,4.7-0.04,9.21,0.01,13.71c0.05,5.14,3.59,8.71,8.77,8.71c26.07,0.03,52.13,0.03,78.2,0
			c5.16-0.01,8.68-3.61,8.73-8.76c0.04-4.18,0.01-8.36,0-12.54c0-0.37-0.08-0.73-0.13-1.13C115.32,175.57,83.55,175.57,51.59,175.57
			z M51.54,51.44c32.06,0,63.86,0,95.64,0c0.8-7.19-2.7-11.14-9.77-11.14c-20.18,0-40.35,0-60.53,0c-5.5,0-10.99-0.03-16.49,0.01
			c-4.46,0.04-7.86,2.75-8.61,6.9C51.53,48.55,51.61,49.94,51.54,51.44z"/>
        <path d="M99.46,195.13c-4.65,0-8.4-3.71-8.43-8.35c-0.04-4.65,3.89-8.57,8.5-8.5c4.6,0.07,8.38,3.9,8.35,8.49
			C107.86,191.4,104.11,195.13,99.46,195.13z M102.2,186.75c0.01-1.56-1.28-2.84-2.82-2.8c-1.48,0.04-2.68,1.27-2.69,2.76
			c-0.01,1.52,1.14,2.72,2.65,2.77C100.94,189.53,102.19,188.33,102.2,186.75z"/>
        <path d="M99.4,48.85c-1.08-0.08-2.24-0.07-3.37-0.26c-1.44-0.25-2.14-1.28-2.17-2.7c-0.03-1.37,0.98-2.56,2.43-2.64
			c2.08-0.12,4.17-0.12,6.25-0.01c1.46,0.08,2.44,1.22,2.49,2.59c0.06,1.35-0.84,2.53-2.29,2.78
			C101.68,48.8,100.57,48.78,99.4,48.85z"/>
        <path d="M68.01,90.61c0.27,0.56,0.48,1.15,0.83,1.66c4.4,6.38,8.81,12.76,13.22,19.13c0.64,0.92,1.41,1.77,1.94,2.75
				c1.63,2.96,1.08,5.79-0.87,8.39c-2.37,3.19-4.85,6.3-7.26,9.46c-2.72,3.57-5.42,7.16-8.12,10.75c-0.89,1.19-1.35,2.48-0.93,3.96
				c0.6,2.14,3.14,3.19,5.12,2.09c0.51-0.28,0.98-0.65,1.4-1.04c7.54-6.96,15.08-13.91,22.6-20.89c2.19-2.03,3.76-4.43,4.14-7.4
				c0.42-3.24-0.66-6.1-2.65-8.65c-0.73-0.93-1.58-1.78-2.42-2.62c-6.81-6.85-13.64-13.7-20.46-20.54
				c-0.29-0.29-0.59-0.57-0.92-0.81c-2.26-1.66-4.14-0.84-5.34,1.33C67.89,88.88,67.88,89.69,68.01,90.61z"/>
        <path d="M135.3,90.38c-0.29,0.55-0.51,1.14-0.87,1.64c-4.56,6.28-9.14,12.54-13.72,18.8
				c-0.66,0.91-1.45,1.74-2.01,2.7c-1.71,2.92-1.23,5.76,0.65,8.41c2.29,3.24,4.69,6.42,7.01,9.64c2.63,3.64,5.23,7.29,7.84,10.95
				c0.86,1.21,1.29,2.51,0.83,3.98c-0.66,2.12-3.22,3.12-5.18,1.97c-0.5-0.29-0.96-0.67-1.38-1.07
				c-7.36-7.14-14.71-14.27-22.05-21.43c-2.13-2.08-3.64-4.52-3.94-7.49c-0.33-3.25,0.82-6.08,2.88-8.59
				c0.75-0.92,1.63-1.74,2.49-2.56c6.99-6.69,13.99-13.36,21-20.04c0.29-0.28,0.61-0.55,0.94-0.79c2.31-1.61,4.16-0.74,5.3,1.46
				C135.46,88.65,135.45,89.47,135.3,90.38z"/>
        <path d="M74.83,83.49c2.75-2.37,7.37-5.72,13.84-7.83c2.32-0.75,6.59-1.91,12.07-1.97c2.03-0.02,5.24,0.09,8.93,0.9
				c8.15,1.78,13.94,5.79,17.19,8.48c-1.24,1.3-2.48,2.61-3.73,3.91c-1.71-1.35-3.94-2.88-6.71-4.23c-3.83-1.87-7.36-2.76-9.99-3.21
				c-1.63-0.28-8.03-1.14-15.81,1.26c-5.5,1.7-9.53,4.38-12.07,6.41C77.32,85.98,76.08,84.73,74.83,83.49z"/>
        <path d="M82.21,90.85c0,0,4.79-4.62,9.23-5.87c1.13-0.46,2.5-0.91,4.08-1.25c2.12-0.45,4-0.56,5.51-0.54
				c1.04-0.02,2.24,0.02,3.55,0.2c0.98,0.13,1.87,0.31,2.66,0.51c1.97,0.47,4.69,1.37,7.57,3.17c2,1.25,3.55,2.59,4.69,3.75
				l-3.73,3.56c-1.15-1.02-2.68-2.18-4.64-3.19c-3.89-2-7.53-2.41-9.75-2.48c-1.71-0.06-4.15,0.06-6.93,0.9
				c-4.1,1.24-6.88,3.45-8.34,4.8L82.21,90.85z"/>
        <path d="M93.23,101.5c0.55-0.61,1.35-1.36,2.44-2.05c1.46-0.91,2.84-1.32,3.77-1.51c1-0.12,3.24-0.24,5.68,0.88
				c1.77,0.81,2.93,1.94,3.56,2.66c1.18-1.19,2.37-2.37,3.55-3.56c-0.8-0.98-2.4-2.67-4.98-3.9c-2.6-1.24-4.95-1.41-6.22-1.41
				c-1.27,0.02-3.02,0.2-4.97,0.89c-3.38,1.2-5.52,3.27-6.57,4.45L93.23,101.5z"/>

    </svg>
}

export const XelIconUser = (props) => {
    return <svg viewBox="0 0 118 125" version={'1.0'}  {...props} >
        <path className="st1" d="M62.38,103c1.96-0.23,5.17-0.39,8.28-1.01c10.72-2.14,19.74-7.35,27.07-15.48c0.47-0.52,0.57-0.98,0.35-1.66
	c-3.97-12.45-12.14-20.88-24.36-25.4c-0.45-0.17-1.14-0.02-1.59,0.23c-2.86,1.53-5.88,2.52-9.11,2.76
	c-4.63,0.35-8.99-0.61-13.06-2.89c-0.36-0.2-0.94-0.25-1.32-0.11c-12.23,4.5-20.4,12.93-24.39,25.37c-0.23,0.71-0.11,1.2,0.38,1.74
	C34.45,97.28,46.64,102.66,62.38,103z M41.86,40.01c-0.16,9.51,7.44,19.24,19.27,19.27C73,59.3,80.51,49.52,80.5,39.97
	c-0.02-9.81-7.83-19.26-19.3-19.29C49.84,20.66,41.71,30,41.86,40.01z"/>
    </svg>
}

export const XelIconDelivery = (props) => {
    return <svg viewBox="0 0 86 106" version={'1.0'}  {...props} >
        <path className="st6" d="M59.28,38.53c-4.29-1.32-8.38-2.59-12.51-3.86c0.21-0.68,0.4-1.31,0.62-1.99c0.46,0.13,0.88,0.24,1.29,0.36
		c4.69,1.44,9.38,2.89,14.07,4.33c1.11,0.34,1.26,0.54,1.26,1.68c0,9.19,0,18.39,0,27.58c0,1.12-0.16,1.34-1.2,1.66
		c-8.85,2.72-17.71,5.45-26.57,8.15c-0.49,0.15-1.1,0.14-1.59-0.01c-8.82-2.69-17.63-5.41-26.44-8.12
		c-1.24-0.38-1.34-0.51-1.34-1.81c0-9.15,0-18.3,0-27.45c0-1.14,0.13-1.34,1.19-1.66c4.94-1.52,9.89-3.04,14.83-4.55
		c0.18-0.06,0.37-0.1,0.6-0.16c0.21,0.67,0.4,1.29,0.62,1.99c-4.12,1.27-8.21,2.53-12.46,3.84c0.31,0.13,0.47,0.22,0.64,0.27
		c7.55,2.32,15.1,4.65,22.66,6.95c0.31,0.1,0.7,0.1,1.01,0c7.58-2.31,15.15-4.64,22.72-6.97C58.81,38.72,58.95,38.66,59.28,38.53z
		 M34.34,47.78c-8.43-2.59-16.87-5.19-25.34-7.8c-0.02,0.17-0.03,0.25-0.03,0.34c0,8.51,0,17.02-0.01,25.53
		c0,0.44,0.2,0.54,0.55,0.65c6,1.83,11.99,3.68,17.98,5.53c2.27,0.7,4.53,1.4,6.85,2.11C34.34,65.34,34.34,56.59,34.34,47.78z
		 M61.89,40.01c-0.25,0.06-0.42,0.1-0.59,0.15c-8.05,2.48-16.11,4.96-24.16,7.42c-0.52,0.16-0.66,0.38-0.66,0.91
		c0.02,8.31,0.01,16.62,0.01,24.94c0,0.22,0,0.44,0,0.69c0.18-0.04,0.3-0.06,0.43-0.1c8.16-2.51,16.32-5.03,24.48-7.52
		c0.51-0.16,0.49-0.45,0.49-0.83c0-8.33,0-16.67,0-25C61.89,40.48,61.89,40.28,61.89,40.01z"/>
        <path className="st6" d="M23.8,25.34c-0.03-6.61,4.62-11.66,10.74-12.17c5.93-0.5,11.42,3.77,12.38,9.68c0.56,3.48-0.33,6.69-1.96,9.73
		c-2.14,4-5.2,7.19-8.79,9.91c-0.46,0.35-0.93,0.4-1.4,0.04c-4.25-3.24-7.8-7.07-9.85-12.1C24.19,28.65,23.81,26.78,23.8,25.34z
		 M35.42,40.41c3.2-2.6,5.92-5.49,7.81-9.08c0.89-1.69,1.45-3.49,1.68-5.39c0.47-3.96-1.62-7.86-5.22-9.67
		c-3.57-1.8-7.93-1.17-10.79,1.61c-2.97,2.89-3.61,6.42-2.5,10.29C27.87,33.33,31.39,37.02,35.42,40.41z"/>
        <path className="st6" d="M28.03,52.18c0,0.84,0.01,1.67,0,2.51c-0.02,0.92-0.61,1.37-1.5,1.11c-3.43-1.04-6.85-2.1-10.28-3.15
		c-0.63-0.19-0.93-0.59-0.93-1.26c0.01-1.63,0-3.26,0.01-4.89c0.01-1,0.6-1.45,1.57-1.16c3.41,1.04,6.81,2.09,10.21,3.14
		c0.63,0.19,0.94,0.6,0.92,1.26C28.01,50.55,28.03,51.36,28.03,52.18z M25.88,50.31c-2.78-0.86-5.57-1.71-8.4-2.58
		c0,1.07,0,2.07,0,3.08c2.81,0.86,5.58,1.71,8.4,2.58C25.88,52.33,25.88,51.35,25.88,50.31z"/>
        <path className="st6" d="M27.72,70.07c-0.72-0.22-1.38-0.42-2.11-0.65c0.21-0.68,0.41-1.32,0.62-2.01c0.71,0.22,1.38,0.42,2.11,0.64
		C28.13,68.75,27.93,69.38,27.72,70.07z"/>
        <path className="st6" d="M29.85,70.73c0.21-0.69,0.4-1.31,0.62-2.02c0.7,0.22,1.38,0.42,2.11,0.65c-0.21,0.7-0.4,1.32-0.61,2.01
		C31.25,71.15,30.57,70.95,29.85,70.73z"/>
        <path className="st6" d="M24.12,66.74c-0.22,0.72-0.42,1.34-0.63,2.02c-0.71-0.21-1.37-0.42-2.1-0.64c0.2-0.65,0.38-1.27,0.6-2
		C22.69,66.33,23.36,66.52,24.12,66.74z"/>
        <path className="st6" d="M42.84,52.17c0-0.84,0.01-1.67,0-2.51c-0.01-0.61,0.29-1,0.86-1.17c3.42-1.06,6.85-2.12,10.27-3.16
		c0.96-0.29,1.56,0.16,1.56,1.17c0.01,1.63-0.01,3.26,0.01,4.89c0.01,0.67-0.3,1.07-0.93,1.26c-3.43,1.05-6.85,2.11-10.27,3.15
		c-0.88,0.27-1.48-0.19-1.49-1.11C42.83,53.85,42.84,53.01,42.84,52.17z M44.99,53.39c2.75-0.85,5.46-1.68,8.16-2.53
		c0.12-0.04,0.25-0.24,0.26-0.37c0.02-0.9,0.01-1.8,0.01-2.77c-2.87,0.88-5.66,1.74-8.43,2.6C44.99,51.36,44.99,52.34,44.99,53.39z"
        />
        <path className="st6" d="M53.75,66.85c-0.21-0.69-0.41-1.31-0.62-2.01c0.69-0.22,1.35-0.43,2.09-0.66c0.21,0.66,0.41,1.29,0.64,2.02
		C55.16,66.41,54.5,66.62,53.75,66.85z"/>
        <path className="st6" d="M60.09,64.89c-0.75,0.23-1.41,0.43-2.11,0.65c-0.21-0.69-0.41-1.33-0.62-2.01c0.71-0.22,1.37-0.43,2.1-0.65
		C59.67,63.54,59.87,64.17,60.09,64.89z"/>
        <path className="st6" d="M48.89,66.13c0.75-0.23,1.4-0.43,2.11-0.64c0.21,0.67,0.41,1.31,0.63,2.01c-0.71,0.22-1.37,0.43-2.1,0.65
		C49.3,67.46,49.1,66.82,48.89,66.13z"/>
        <path className="st6" d="M35.42,18.43c3.47-0.01,6.34,2.82,6.36,6.27c0.02,3.54-2.81,6.42-6.33,6.43c-3.49,0.02-6.36-2.84-6.36-6.33
		C29.08,21.3,31.91,18.45,35.42,18.43z M35.46,29.02c2.33-0.01,4.22-1.94,4.2-4.28c-0.02-2.31-1.92-4.19-4.23-4.19
		c-2.34,0-4.24,1.91-4.23,4.24C31.21,27.13,33.12,29.03,35.46,29.02z"/>
    </svg>
}


export const XelIconAttention = (props) => {
    return <svg viewBox="0 0 188 191" version={'1.0'}  {...props} >
        <path d="M103.87,183.06c-3.11,0-6.23,0-9.34,0c-0.45-0.09-0.9-0.2-1.36-0.27c-4.35-0.67-8.76-1.04-13.03-2.06
		c-40.89-9.8-67.24-50.89-59.23-92.26c3.33-17.2,11.36-31.86,24.29-43.64c18.45-16.81,40.26-23.51,64.97-20.37
		c15.74,2,29.61,8.46,41.5,18.96c14,12.37,22.76,27.76,25.99,46.22c0.52,2.99,0.82,6.03,1.22,9.04c0,3.11,0,6.23,0,9.34
		c-0.1,0.55-0.21,1.1-0.28,1.65c-0.38,2.87-0.6,5.78-1.14,8.62c-3.02,15.87-10.24,29.59-21.6,41.05
		c-11.91,12.02-26.21,19.59-42.93,22.47C109.93,182.35,106.89,182.65,103.87,183.06z M77.48,98.44c3.17,0,6.15,0,9.17,0
		c0,15.59,0,31.06,0,46.7c-3.12,0-6.15,0-9.16,0c0,3.16,0,6.15,0,9.18c14.52,0,28.95,0,43.38,0c0-3.12,0-6.15,0-9.28
		c-3.1,0-6.09,0-9.24,0c0-18.7,0-37.29,0-55.89c-11.49,0-22.81,0-34.15,0C77.48,92.24,77.48,95.23,77.48,98.44z M116.31,62.65
		c0.03-9.42-7.65-17.15-17.05-17.18c-9.42-0.03-17.15,7.65-17.18,17.05c-0.03,9.42,7.65,17.15,17.05,17.18
		C108.55,79.73,116.28,72.05,116.31,62.65z"/>
    </svg>
}

export const XelIconDeliveryCar = (props) => {
    return <svg viewBox="0 0 300 205" version={'1.1'} {...props} >
        <path d="M25.07,22.78c1.44-3.97,3.1-7.94,7.17-9.8c2.51-1.14,5.4-2.03,8.13-2.03c43.66-0.12,87.33-0.12,130.99-0.06
		c9.13,0.01,15.26,6.07,15.29,15.06c0.09,25.09,0.03,50.19,0.03,75.28c0,0.33-0.05,0.66-0.11,1.36c-53.84,0-107.67,0-161.49,0
		C25.07,75.99,25.07,49.38,25.07,22.78z"/>
        <path d="M25.07,112.64c53.27-0.01,106.54-0.02,159.81-0.02c0.49,0,0.98,0.06,1.63,0.1c0,12.58,0,25.08,0,37.98
		c-0.87,0-1.76,0-2.64,0c-29.27,0-58.54-0.01-87.81,0.03c-1.7,0-2.65-0.47-3.53-2.01c-6.01-10.56-15.08-16.52-27.3-16.55
		c-12.34-0.03-21.43,6.02-27.5,16.65c-0.75,1.31-1.49,2.07-3.08,1.9c-1.16-0.13-2.34-0.07-3.51-0.02c-2.75,0.11-4.91-0.75-6.08-3.43
		C25.07,135.73,25.07,124.18,25.07,112.64z"/>
        <path d="M196.77,150.69c0-20.91,0-41.47,0-62.32c0.93-0.05,1.8-0.14,2.68-0.14c19.15-0.01,38.3-0.03,57.45,0.03
		c1.45,0,3,0.33,4.34,0.89c5.69,2.39,11.31,4.98,16.99,7.4c2.72,1.16,3.92,3.05,3.91,6.02c-0.06,14.05-0.02,28.1-0.03,42.14
		c0,4.3-1.61,5.89-5.89,5.96c-0.33,0.01-0.67,0-1,0c-2.84,0-6.09,0.85-8.41-0.25c-2.21-1.05-3.45-4.13-5.12-6.31
		c-13.4-17.55-40.87-15.44-51.43,4.01c-1.07,1.98-2.2,2.77-4.44,2.61C202.89,150.53,199.97,150.69,196.77,150.69z"/>
        <path d="M252.04,78.18c-18.66,0-36.85,0-55.2,0c0-11.19,0-22.26,0-33.48c0.82-0.05,1.55-0.13,2.28-0.13
		c8.44-0.01,16.89,0.05,25.33-0.02c6.88-0.06,11.78,2.88,14.89,9.06C243.42,61.72,247.67,69.75,252.04,78.18z"/>
        <path d="M65.12,142.14c11.55-0.04,21.08,9.4,21.15,20.96c0.07,11.42-9.46,21-21.04,21.14
		c-11.4,0.13-21.06-9.49-21.1-21.02C44.09,151.65,53.52,142.18,65.12,142.14z"/>
        <path d="M237.15,142.14c11.54-0.07,21.01,9.28,21.19,20.92c0.18,11.35-9.36,20.97-20.99,21.18
		c-11.39,0.2-21.03-9.37-21.11-20.97C216.16,151.66,225.5,142.21,237.15,142.14z"/>
    </svg>
}

