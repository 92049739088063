import React, {useEffect, useState} from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {useSelector} from "react-redux";


const useStyles = makeStyles({
    supcontainer: {
        padding: "2%"
    },
    container: {
        backgroundColor: "#FEFEFE",
        boxShadow: "-3px 3px 9px -3px rgba(0,0,0,0.6)",
        //margin:"1%"
    },
    url: {
        cursor: "pointer",
        color: "#00A8E8",
        textDecoration: "none",
        "&:hover": {
            // textDecoration: "underline",
        }
    },
    titleContainer: {
        backgroundColor: "#F9F5FB",
        padding: "3%"
    },
    payButton: {
        color: "#FEFEFE",
        backgroundColor: "green",
        "&:hover": {
            backgroundColor: "green",
        },
        width: "100%",
        marginTop: "4%"
    }
});


const Method = ({rollBack, title, children}) => {
    const styles = useStyles();
    const session = useSelector(state => state.login);
    return <Grid container>
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={12} className={styles.titleContainer}
                      style={{display: "flex", justifyContent: "space-between"}}>
                    <Typography variant={"overline"}>
                        {title}
                    </Typography>
                    {
                        session.logged && <Typography variant={"overline"} className={styles.url} onClick={() => {
                            if (rollBack) {
                                rollBack()
                            }
                        }}>
                            Cambiar medio de pago
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12} style={{padding: "1%"}}>
                    {
                        children
                    }
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}


export default Method;
