import { createSvgIcon } from "@material-ui/core";

export const Facebook = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M15.9144 8.52337C15.9144 4.1623 12.3543 0.631348 7.95721 0.631348C3.56011 0.631348 0 4.1623 0 8.52337C0 12.4635 2.91175 15.7256 6.71942 16.3219V10.8033H4.6918V8.52337H6.71942V6.78128C6.71942 4.80535 7.89826 3.71801 9.71369 3.71801C10.586 3.71801 11.4937 3.87 11.4937 3.87V5.81085H10.4917C9.50149 5.81085 9.19499 6.41883 9.19499 7.0385V8.52337H11.3994L11.0458 10.8033H9.19499V16.3219C13.0027 15.7256 15.9144 12.4635 15.9144 8.52337Z"
      fill="#396EB5"
    />
    <path
      d="M11.0576 10.8033L11.4113 8.52338H9.19502V7.03851C9.19502 6.41884 9.50152 5.81087 10.4918 5.81087H11.4938V3.87001C11.4938 3.87001 10.5861 3.71802 9.71372 3.71802C7.89829 3.71802 6.71945 4.80536 6.71945 6.78129V8.52338H4.69183V10.8033H6.71945V16.3219C7.12026 16.3803 7.54464 16.4154 7.95724 16.4154C8.38162 16.4154 8.79422 16.3803 9.19502 16.3219V10.8033H11.0576Z"
      fill="white"
    />
  </svg>,
  'Facebook'
);
