import React from "react";
import {Divider, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {XEL_COLORS, XEL_FONT} from "../../../../constant";

import Linea from '../../../../assets/logos/home/LineaPlan.png';
import {useResponsiveValues} from "../../../utils/hooks";

const Titulo = () => {
    return <Grid container style={{marginTop: 40,marginBottom:'2%'}} justify={'center'}>
        <Grid item xs={12} md={8}>
            <Typography variant={'h6'} align={'center'} color={'primary'}>
                En tan solo unos pasos te unirás a la familia inXel
            </Typography>
            <Divider variant='fullWidth' style={{backgroundColor:XEL_COLORS.secondary}}/>
        </Grid>
    </Grid>
}

export default Titulo;
