import React, {useEffect, useState} from "react";
import {Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography} from "@material-ui/core";
import Axios from "../../../../../../instances/axios";
import Products from "./Products";
import Alerts from "../../../../../ui/Alert";

const DnDetail = ({msisdn, planes, onChange, data, setPayParams, payParams}) => {

    const [dnData, setDnData] = useState(data ? data : []);
    const [params, setParams] = useState({
        postpago: 0,
        prepago: {
            id: 0,
            price: 0,
            name: "",
        }
    });
    const [total, setTotal] = useState(0);
    const axios = Axios();
    const [error, setError] = useState({
        open: false,
        msg: "Hola",
        severity: "error"
    });

    useEffect(() => {
        setTotal(params.postpago + params.prepago.price);
        setPayParams(msisdn, params);
    }, [params]);
    useEffect(() => {
        for (let i = 0; i < payParams.length; i++) {
            if (payParams[i].msisdn === msisdn) {
                setParams(payParams[i].params);
            }
        }
    }, [])

    const handlePostpago = () => {
        setParams(prevState => {
            return {
                ...prevState,
                postpago: prevState.postpago > 0 ? 0 : dnData.saldo
            }
        });
    }

    const handlePrepago = (id, price, name) => {
        let payparams = params;

        let issame = payparams.prepago.id === id;

        if (!issame)
            setParams(prevState => {
                return {
                    ...prevState,
                    prepago: {
                        id,
                        price,
                        name
                    }
                }
            });
        else
            setParams(prevState => {
                return {
                    ...prevState,
                    prepago: {
                        id: 0,
                        price: 0,
                        name: ""
                    }
                }
            });
    }
    useEffect(() => {
        if (!data)
            axios.get(`/clients/pays/${msisdn}?lower=true`)
                .then(res => {
                    if (res.data.error) {
                        setError({
                            open: true,
                            msg: "Error al consultar datos",
                            severity: "error"
                        });
                    } else {
                        setDnData(res.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
    }, [])

    return <Grid container spacing={1} alignItems={"center"} style={{padding: "2%"}}>
        <Grid item xs={10} md={4}>
            <Typography>
                {msisdn}
            </Typography>
        </Grid>
        <Grid item xs={10} md={5}>
            {
                error.open && <Typography>
                    {error.msg}
                </Typography>
            }
            <Products params={params} handlePostpago={handlePostpago} handlePrepago={handlePrepago} planes={planes}
                      dnData={dnData} onChange={onChange}/>
        </Grid>
        <Grid item xs={10} md={3}>
            ${total}.00
        </Grid>

    </Grid>;
};

export default DnDetail;
