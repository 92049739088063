import React from 'react'
import { useHistory } from 'react-router-dom'
import { XEL_COLORS } from '../../../../constant'
import { useResponsiveValues } from '../../../utils/hooks'
import compatibilityIcon from '../../../../assets/images/compatibilityIcon.svg'

const { secondary, primary } = XEL_COLORS

const Option = ({ iconUrl, path, label }) => {
    const history = useHistory()
    return (
        <div
            onClick={() => history.push(path)}
            style={{
                boxShadow: `4px 4px 0px 3px ${secondary}`,
                borderRadius: 8,
                margin: useResponsiveValues(20, 10, 8),
                width: useResponsiveValues(250, 'auto', 'auto'),
                display: 'flex',
                cursor: 'pointer',
            }}>
            <div
                style={{
                    borderRadius: 8,
                    boxShadow: '-1px -0px 19px -10px #000',
                    padding: 5,
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 10px 0 10px',
                }}>
                <div style={{ display: 'flex' }}>
                    <img
                        style={{
                            width: label === 'Cobertura' ? 50 : 40,
                            height: 40,
                            margin: 10,
                        }}
                        src={iconUrl}
                    />
                    <span
                        style={{
                            color: primary,
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            fontSize: useResponsiveValues(22, 18, 15),
                        }}>
                        {label}
                    </span>
                </div>
            </div>
        </div>
    )
}

const options = [
    {
        label: 'Pagar',
        path: '/recarga',
        iconUrl:
            'https://api.inxel.mx/website/assets/web/media/iconos/paga.png',
    },
    {
        label: 'Apps',
        path: '/app',
        iconUrl: 'https://api.inxel.mx/website/assets/web/media/iconos/app.png',
    },
    {
        label: 'Dudas',
        path: '/preguntas-frecuentes',
        iconUrl:
            'https://api.inxel.mx/website/assets/web/media/iconos/preguntas.png',
    },
    {
        label: 'Compatibilidad',
        path: '/verifica/compatibilidad',
        iconUrl: compatibilityIcon,
    },
    {
        label: 'Cobertura',
        path: '/cobertura-hbb',
        iconUrl:
            'https://api.inxel.mx/website/assets/web/media/iconos/cobertura.png',
    },
]

export default () => (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100vw' }}>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                padding: '20px 0 20px 0',
                justifyContent: 'center',
            }}>
            {options.map((option, i) => (
                <Option {...{ ...option }} />
            ))}
        </div>
    </div>
)
