import React, {useEffect, useState} from 'react';
import NavBar from "../../ui/NavBar";
import Carousel from '../../ui/Carou';

import CarouExample1 from "./assets/2021/Banner-XelFi-PC/banner-XelFi-Pc.png";
import CarouExample2 from "./assets/2021/Banner-XelFi-Móvil/banner-XelFi-Móvil-01.png";
import {Grid, useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {XEL_COLORS, XEL_FONT} from "../../../constant";
import Typography from "@material-ui/core/Typography";
import Footer from '../../ui/Footer';
import {Route} from "react-router-dom";
import DinamicBuy from "./DinamicBuy";
import {Switch, useHistory} from "react-router-dom";

import {useResponsiveValues, useResponsiveValuesV2} from "../../utils/hooks";
import Titulo from "./Titulo";


const useSubNavStyles = makeStyles({
    text: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.bold_weight,
        fontSize: 23,
        color: '#FEFEFE'
    },
    subcontainer: {
        height: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    high_desc: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.bold_weight,
        color: XEL_COLORS.primary,
    },
    low_desc: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        color: XEL_COLORS.primary,
        textAlign: "right",
        padding: 20
    }
});
const SubNav = ({select}) => {
    const styles = useSubNavStyles();
    const history = useHistory();
    const [selected, setSelected] = useState(select);
    useEffect(() => {

    })

    const goPlan = () => {
        setSelected(true);
        history.push('/personas/plan');
    }
    const goPlanEquipo = () => {
        setSelected(false);
        history.push('/personas/plan/equipo');
    }
    return <Grid container>
        <Grid item
              xs={6}
              classes={{root: styles.subcontainer}}
              style={{backgroundColor: select ? XEL_COLORS.primary : XEL_COLORS.primary_light}}
              onClick={goPlan}
        >
            <Typography classes={{root: styles.text}} align={"center"}>
                Comprar solo CHIP
            </Typography>
        </Grid>
        <Grid item
              xs={6}
              classes={{root: styles.subcontainer}}
              style={{backgroundColor: !select ? XEL_COLORS.primary : XEL_COLORS.primary_light}}
              onClick={goPlanEquipo}
        >
            <Typography classes={{root: styles.text}} align={"center"}>
                Comprar celular con CHIP
            </Typography>
        </Grid>
    </Grid>
}
export default function Mifi() {

    const noCel = useMediaQuery('(min-width:426px)');
    const [customIndex, setCustomIndex] = useState(0);
    const items = [
        {
            // name: 'Caro3',
            id: 0,
            file: {
                type: 'image',
                file: noCel ? CarouExample1 : CarouExample2
            }
        }
    ]

    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Xel-Fi";
    }, [])
    return <>
        <NavBar/>
        <Carousel data={items} customindex={customIndex} setIndex={setCustomIndex}/>
        <div id={'plan-section-id'} style={{}}/>
        <Grid style={{backgroundColor: '#F6F6F6'}}>
            <Titulo style={{backgroundColor: '#F6F6F6', paddingTop: '2%', fontSize: useResponsiveValues(20, 15, 12)}}/>
            <Switch>
                <Route path={'/personas/mifi'} exact>
                    <DinamicBuy option={1}/>
                </Route>
            </Switch>
        </Grid>
        <Footer/>
    </>
}
