import React, {useEffect, useReducer, useState} from 'react';
import './App.css';
import Routes from "./Routes";
import {useDispatch, useSelector} from "react-redux";
import * as Actions from './actions';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import Cookies from 'js-cookie'
import {APIHOST, OPENPAY} from "./constant";
import Axios from "./instances/axios";
import {CircularProgress} from "@material-ui/core";
import PopUp from "./components/ui/PopUp";

function App() {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const navbarHeight = useSelector(state => state.navbarheight);
    const axios = Axios();
    let sandbox = false;
    useEffect(() => {
        loadInfo();
    }, []);

    const loadInfo = async () => {

        // eslint-disable-next-line no-undef
        OpenPay.setId(sandbox ? OPENPAY.SANDBOX_ID : OPENPAY.PRODUCTION_ID);

        // eslint-disable-next-line no-undef
        OpenPay.setApiKey(sandbox ? OPENPAY.SANDBOX_API_KEY : OPENPAY.PRODUCTION_API_KEY);
        // eslint-disable-next-line no-undef
        OpenPay.setSandboxMode(sandbox);

        // eslint-disable-next-line no-undef
        let openpay = OpenPay;
        // eslint-disable-next-line no-undef
        dispatch(Actions.INITIALIZE_OPENPAY(openpay));

        let session = Cookies.get('xel_token');


        const loadCatalogs = async () => new Promise(async (resolve, reject) => {
            try {
                let resalliance = await axios.get("/catalog/products/aliance");
                let frontSite = await axios.get("/catalog/products/public");


                let planes = [];
                let secciones = [];

                frontSite = frontSite.data;

                for (let i = 0; i < frontSite.length; i++) {

                    let p = frontSite[i];
                    const {seccion} = p;

                    if (!seccion)
                        continue;
                    let searchSeccion = secciones.filter((secc, i) => secc.name === seccion);
                    if (searchSeccion.length > 0) {
                        let planes = searchSeccion[0].planes;
                        planes.push(p);
                        searchSeccion[0].planes = planes;
                    } else {
                        secciones.push({
                            name: seccion,
                            planes: [p]
                        });
                    }
                }

                let nSecc = [];
                for (let i = 0; i < secciones.length; i++) {
                    let secc = JSON.stringify(secciones[i]);
                    nSecc.push(JSON.parse(secc));
                }
                for (let i = 0; i < secciones.length; i++) {
                    let subsecciones = [];
                    const seccion = nSecc[i];
                    for (let j = 0; j < seccion.planes.length; j++) {
                        const p = seccion.planes[j];
                        const {subseccion} = p;
                        if (!subseccion)
                            continue;
                        let searchSub = subsecciones.filter(sub => sub.name === subseccion);
                        if (searchSub.length > 0) {
                            let planes = searchSub[0].planes;
                            planes.push(p);
                            searchSub[0].planes = planes;
                        } else
                            subsecciones.push({
                                name: subseccion,
                                planes: [p]
                            });
                    }
                    secciones[i].planes = subsecciones;
                }

                await dispatch(Actions.SETPLANS({
                    alliance: resalliance.data,
                    normal: secciones
                }));

                let devices = await axios.get("/catalog/products/devices");

                await dispatch(Actions.SETDEVICES(devices.data));

                let packs = await axios("/catalog/xelpacks");

                await dispatch(Actions.SETPACKS(packs.data));

                resolve("OK");
            } catch (e) {
                reject(e);
            }
        });

        const loadSession = async () => {
            if (!session)
                session = sessionStorage.getItem('xel_token');

            if (session) {
                const form = new FormData();
                form.append('xel_token', session);
                axios.get("/clients/subscriber.get.data/v2", {
                    headers: {
                        "auth-token": session
                    }
                }).then(res => {
                    console.log('inXel - userProfile', res.data);
                    dispatch(Actions.LOG_IN({
                        ...res.data,
                        token: session
                    }));
                    // setLoaded(true);
                    loadCatalogs().then(res => {
                        setLoaded(true);
                    }).catch(err => {
                        setLoaded(true);
                    })
                }).catch(err => {
                    sessionStorage.removeItem("xel_token");
                    Cookies.remove("xel_token");

                    loadCatalogs().then(res => {
                        setLoaded(true);
                    }).catch(err => {
                        setLoaded(true);
                    })
                })
            } else {
                loadCatalogs().then(res => {
                    setLoaded(true);
                }).catch(err => {
                    setLoaded(true);
                })
            }
        }
        //   setLoaded(true);
        loadSession();
    }
    return (
        <div style={{paddingTop: `${navbarHeight}px`, fontFamily: 'Montserrat' }}>
            {
                sandbox ? <div style={{paddingTop: 40}}>Sandbox</div> : ""
            }
            {
                loaded ? <Routes/> : <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100vw",
                    height: "100vh"
                }}>
                    <CircularProgress/>
                </div>
            }
            <PopUp/>
        </div>
    );

}

export default App;
