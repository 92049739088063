import { createSvgIcon } from "@material-ui/core";


export const PhoneCall =createSvgIcon(<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M17.5 13.5625C16.4062 13.5625 15.3562 13.3875 14.3763 13.0638C14.07 12.9675 13.7287 13.0375 13.4837 13.2738L11.5588 15.1988C9.0825 13.9388 7.0525 11.9175 5.7925 9.4325L7.7175 7.49875C7.9625 7.27125 8.0325 6.93 7.93625 6.62375C7.6125 5.64375 7.4375 4.59375 7.4375 3.5C7.4375 3.01875 7.04375 2.625 6.5625 2.625H3.5C3.01875 2.625 2.625 3.01875 2.625 3.5C2.625 11.7163 9.28375 18.375 17.5 18.375C17.9812 18.375 18.375 17.9812 18.375 17.5V14.4375C18.375 13.9563 17.9812 13.5625 17.5 13.5625ZM16.625 10.5H18.375C18.375 6.15125 14.8487 2.625 10.5 2.625V4.375C13.8862 4.375 16.625 7.11375 16.625 10.5ZM13.125 10.5H14.875C14.875 8.085 12.915 6.125 10.5 6.125V7.875C11.9525 7.875 13.125 9.0475 13.125 10.5Z" fill="url(#paint0_linear_2_704)" />
    <defs>
        <linearGradient id="paint0_linear_2_704" x1="2.625" y1="13.0097" x2="18.375" y2="13.0097" gradientUnits="userSpaceOnUse">
            <stop stop-color="#B627FF" />
            <stop offset="0.463542" stop-color="#8A0EC9" />
            <stop offset="1" stop-color="#412C5F" />
        </linearGradient>
    </defs>
</svg>, 'PhoneCall')