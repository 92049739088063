import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Container,
    Grid,
    Typography,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import Axios from '../../../../../../instances/axios'
import CardItem from '../PayMethod/CardMethod/CardItem'
import NewCard from '../PayMethod/CardMethod/NewCard'
import { Alert } from '@material-ui/lab'
import { XEL_COLORS } from '../../../../../../constant'
const { primary2, primary_shiny, secondary, secondary_dark } = XEL_COLORS

const Cards = ({ handleBack }) => {
    const session = useSelector(state => state.login)
    const axios = Axios()
    const [cards, setCards] = useState([])
    const [selectedCard, setSelectedCard] = useState(null)
    const [loading, setLoading] = useState(true)
    const [sessionId, setSessionId] = useState(null)
    const [card, setCard] = useState({})
    const [message, setMessage] = useState({
        open: false,
        text: 'Hola',
        severity: 'info',
    })
    const [windowCheck, setWindowCheck] = useState({
        checked: false,
        isWindows: false,
        accept: false,
    })
    const [status, setStatus] = useState({
        loading: false,
        message: 'Cargando...',
    })
    const [add, setAdd] = useState(false)
    // eslint-disable-next-line no-undef
    const Openpay = OpenPay

    useEffect(() => {
        loadCards()
    }, [])
    useEffect(() => {
        setWindowCheck({
            checked: true,
            isWindows: window.navigator.appVersion.indexOf('Win') === -1,
            accept: false,
        })
    }, [])
    useEffect(() => {
        let device_session_id = Openpay.deviceData.setup('add-card-container')
        setSessionId(device_session_id)
    }, [])

    const loadCards = () => {
        if (session.logged) {
            setLoading(true)
            axios
                .get('/clients/card')
                .then(res => {
                    const { status, message, cards: all } = res.data
                    setLoading(false)
                    if (status !== 200) {
                        setMessage({
                            open: true,
                            text: message,
                            severity: 'error',
                        })
                        return
                    }

                    console.log('CARDS', all)
                    let cards = []

                    for (let i = 0; i < all.length; i++) {
                        let card = all[i]

                        const {
                            id,
                            card_number,
                            holder_name,
                            bank_name,
                            type,
                            brand,
                            source,
                        } = card
                        if (source === 'openpay')
                            cards.push({
                                id,
                                number: card_number,
                                type,
                                brand,
                                bank: bank_name,
                            })
                    }
                    setCards(cards)
                })
                .catch(err => {
                    setLoading(false)
                    console.log('ERROR', err)
                })
        }
    }
    const onDelete = id => {
        if (window.confirm('¿Estas seguro de eliminar esta tarjeta?')) {
            setLoading(true)
            axios
                .delete(`/clients/card/${id}`)
                .then(res => {
                    alert('Tarjeta eliminada')
                    loadCards()
                })
                .catch(err => {
                    setLoading(false)
                    console.log('ERROR', err)
                })
        }
    }
    const handleCardUpdate = (name, value) => {
        setCard(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }
    const createToken = () =>
        new Promise((resolve, reject) => {
            Openpay.token.create(
                {
                    card_number: card.cardNumber,
                    holder_name: card.cardName,
                    expiration_year: card.cardExpirationYear,
                    expiration_month: card.cardExpirationMonth,
                    cvv2: card.cardCvv,
                },
                data => {
                    let token = data.data.id
                    resolve(token)
                },
                error => {
                    const {
                        category,
                        description,
                        http_code,
                        error_code,
                        request_id,
                        fraud_rules,
                    } = error.data
                    console.log(error)
                    setMessage({
                        open: true,
                        text:
                            'Error al procesar tarjeta, código: ' + error_code,
                        severity: 'error',
                    })
                    reject(error)
                },
            )
        })
    const reset = () => {
        loadCards()
        setCard({})
        setMessage({
            open: false,
            text: '',
            severity: '',
        })
        setStatus({
            loading: false,
            message: '',
        })
        setAdd(false)
    }
    const addCard = async () => {
        let token
        setMessage({
            open: true,
            text: 'Procesando tarjeta...',
            severity: 'info',
        })
        setStatus({
            loading: true,
            message: 'Procesando tarjeta...',
        })
        try {
            token = await createToken()
        } catch (e) {
            reset()
            return
        }
        try {
            let res = await axios.post('/clients/card', {
                token,
                device_session_id: sessionId,
            })
            let data = res.data

            if (data.error) {
                setMessage({
                    open: true,
                    text: data.error_descripcion,
                    severity: 'error',
                })
                //   reset();
            } else {
                let id = data.id
                reset()
                //params.card = data.id;
            }
        } catch (e) {
            setMessage({
                open: true,
                text: 'Hubo un problema, al comunicarse con el servidor, intente más tarde',
                severity: 'error',
            })
            reset()
        }
    }
    return (
        <Container>
            <Grid
                container
                spacing={2}
                alignItems={'center'}
                justify={'center'}>
                <Grid item xs={12}>
                    <Typography variant={'h6'} align={'center'}>
                        Tarjetas de crédito y débito.
                    </Typography>
                </Grid>

                {add && (
                    <Grid item xs={12} md={10}>
                        <Card>
                            <CardContent id={'add-card-container'}>
                                <NewCard
                                    setWindowsCheck={setWindowCheck}
                                    windowsCheck={windowCheck}
                                    status={status}
                                    setFunctions={() => {}}
                                    card={card}
                                    updateCard={handleCardUpdate}
                                />
                                {message.open && (
                                    <Alert
                                        style={{ marginTop: 5 }}
                                        severity={message.severity}
                                        onClose={() =>
                                            setMessage(prevState => ({
                                                ...prevState,
                                                open: false,
                                            }))
                                        }>
                                        {message.text}
                                    </Alert>
                                )}
                            </CardContent>
                            {windowCheck.accept && (
                                <CardActions>
                                    <Button
                                        disabled={loading || status.loading}
                                        onClick={addCard}
                                        color={'primary'}
                                        variant={'contained'}>
                                        Aceptar y Guardar
                                    </Button>
                                    <Button
                                        disabled={loading || status.loading}
                                        onClick={() => setAdd(false)}
                                        color={'secondary'}
                                        variant={'contained'}
                                        style={{ color: '#FEFEFE' }}>
                                        Cancelar
                                    </Button>
                                </CardActions>
                            )}
                        </Card>
                    </Grid>
                )}
                <Grid item xs={10} style={{ backgroundColor: '#EEEEEE' }}>
                    <Grid container spacing={2}>
                        {loading && (
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                <Typography align={'center'}>
                                    <CircularProgress
                                        size={20}
                                        color={'primary'}
                                    />{' '}
                                    Cargando...
                                </Typography>
                            </Grid>
                        )}
                        {!loading &&
                            cards.map(card => (
                                <Grid key={card.id} item xs={12}>
                                    <CardItem
                                        {...card}
                                        toDelete={true}
                                        onDelete={onDelete}
                                        selectCard={() => {}}
                                        selected={false}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={10}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={handleBack}
                        variant={'contained'}
                        style={{ color: '#FEFEFE', marginRight: 10, background: `linear-gradient(${secondary}, ${secondary_dark})`, }}
                        color={'secondary'}>
                        Volver
                    </Button>
                    {!add && (
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => setAdd(true)}
                            style={{
                                color: '#FEFEFE',
                                background: `linear-gradient(${primary2}, ${primary_shiny})`,
                            }}>
                            Agregar tarjeta +
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}

export default Cards
