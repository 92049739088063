import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Button, Card, CircularProgress, Container, Grid, TextField, Typography, useMediaQuery} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import Carousel from "react-multi-carousel";
import {SIcon} from "../Alianzas/Unitys";
import {APIHOST, XEL_COLORS, XEL_FONT} from "../../../constant";
import Particles from "react-particles";
import Titulo from "../Inicio/PlansSection/Titulo";
import Footer from "../../ui/Footer";
import {makeStyles} from "@material-ui/core/styles";
import Axios from "../../../instances/axios";
import axio from "axios";
import {useParams} from "react-router-dom";
import Opciones from "../Inicio/SeccionOpciones";
import DeviceService from "../../utils/services/dispositivos";
import {useHistory} from "react-router-dom";
import cookie from "js-cookie"
import {useQuery} from "../../utils/hooks";
import PlanCard from "../../ui/Plan/v2";
import {loadFull} from "tsparticles";

const useStyles = makeStyles({
    container: {
        paddingTop: '2%',
        height: '100%',
        width: '100%'
        //   display:"flow"
        ,
        display: "flex",
        justifyContent: "center"

    },
    c2: {
        padding: '0',
        paddingLeft: 0,
        paddingRight: '2%'
    },
    item: {
        height: '100%'
    }
});
const DescriptionItem = ({icon, title, description, children}) => {
    return <Grid item
                 xs={10}
                 md={4}
                 lg={3}
                 style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "2%"}}>
        <SIcon icon={icon}/>
        <Typography variant={"body1"} align={"center"}
                    style={{fontWeight: XEL_FONT.bold_weight, color: XEL_COLORS.secondary}}>
            {title ? title : "Ejemplo de texto"}
        </Typography>
        <Typography variant={"body2"} align={"center"}>
            {
                children
            }
        </Typography>
    </Grid>
}
const AlianzaSells = ({hideNav}) => {

    const history = useHistory();
    const styles = useStyles();
    const axios = Axios();
    const noCel = useMediaQuery('(min-width:426px)');
    const [banners, setBanners] = useState([]);
    const [access, setAccess] = useState({
        code: "",
        aprobbed: false,
        error: false,
        message: "",
        withAuth: false
    });
    const params = useParams();
    const query = useQuery();
    const {name: alianza} = params;
    const [loading, setLoading] = useState(false);
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 5
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 4
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };
    const responsiveBanner = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 1
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 1
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 1
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };
    const [planes, setPlanes] = useState([]);

    const [active, setActive] = useState("plan");
    const [dev, setDev] = useState(null);
    const devices = DeviceService();
    const responsiveCar = useMemo(() => {
        if (planes.length > 4) {
            return responsive;
        }
        return {
            ...responsive,
            superLargeDesktop: {
                ...responsive.superLargeDesktop,
                items: planes.length
            },
            desktop: {
                ...responsive.desktop,
                items: planes.length
            },
        }

    }, [planes]);
    const discountNames = [{
        name: "unam",
        value: "Precio Comunidad Universitaria:"
    },
        {
            name: "cfe",
            value: "Precio preferencial para CFE:"
        }
    ];

    const owner = [{
        name: "aapaunam",
        owner: "unam"
    }];
    let alName = owner.filter(n => n.name === alianza).length > 0 ? owner.filter(n => n.name === alianza)[0].owner : alianza;
    const carouselRef = useRef(null);
    const [contaSeccion, setContaSeccion] = useState([]);
    useEffect(() => {
        if (access.withAuth && access.code && !access.aprobbed) {
            checkAccess();
        }
    }, [access])
    useEffect(() => {
        let authCode = query.get("auth-code");
        if (authCode) {
            setAccess(prevState => ({...prevState, code: authCode, withAuth: true}))
        }
        let checkAccessCookie = cookie.get(`alliance-access-${alianza}`);

        if (checkAccessCookie) {
            setAccess(prevState => ({...prevState, aprobbed: true}));
        }

        axios.get(`/catalog/products/alliance/${alName}`).then(res => {
            let data = res.data;
            if (data.length === 0) {

                history.push("/");
                return;
            }


            setPlanes(data);
        }).catch(err => {

            console.error(err);
        });
        document.getElementsByTagName("title")[0].text = `Alianza - ${params.name}`;
        window.scrollTo(0, 0);

    }, []);
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);
    useEffect(() => {
        let id = active === "mifi" ? "137" : active === "hbb" ? "68442" : null;
        if (carouselRef.current) {
            carouselRef.current.goToSlide(0);
        }

        if (id) {
            console.log(id);
            devices.getDevice(id).then(res => {
                setDev(res);
            }).catch(err => {

            })
        }
    }, [active, carouselRef]);
    useEffect(() => {
        const source = axio.CancelToken.source();
        if (banners.length === 0)
            axios.get(`/website/banners/alliance/${params.name}`, {
                cancelToken: source.token
            }).then(res => {

                let {data} = res;
                let {desktop, mobile} = data;
                let deskBanners = [];
                let mobileBanners = [];
                for (let i = 0; i < desktop.length; i++) {
                    deskBanners.push(desktop[i].bannerId);
                }
                for (let i = 0; i < mobile.length; i++) {
                    mobileBanners.push(mobile[i].bannerId);
                }
                let bannerss = [];

                for (let i = 0; i < deskBanners.length; i++) {
                    bannerss.push({
                        id: deskBanners[i],
                        file: {
                            type: 'image',
                            files: {
                                desktop: `${axios.defaults.baseURL}website/banners/alliance/${params.name}/desktop/${deskBanners[i]}`,
                                mobile: `${axios.defaults.baseURL}website/banners/alliance/${params.name}/mobile/${mobileBanners[i]}`
                            }
                        }
                    })
                }
                setBanners(bannerss);
            }).catch(err => {
                console.error(err);
            });
        return () => {
            source.cancel("Cancelado por desmontar");
        }
    }, []);

    const checkAccess = e => {
        if (e)
            e.preventDefault();
        setAccess(prevState => ({
            ...prevState,
            error: false,
            message: ""
        }));
        setLoading(true);
        axios.get(`/website/env/check/alliance_${alianza}_auth/${access.code || 0}`).then(res => {
            setLoading(false);

            const {data} = res;
            const {status, message} = data;

            if (status !== 200) {
                setAccess(prevState => ({
                    ...prevState,
                    error: true,
                    message: message
                }));
                return;
            }

            cookie.set(`alliance-access-${alianza}`, "1");

            setAccess(prevState => ({
                ...prevState,
                aprobbed: true,
                error: false,
                message: ""
            }));
        }).catch(err => {
            setLoading(false);
            setAccess(prevState => ({
                ...prevState,
                error: true,
                message: "Hubo un problema al conectarse con el servidor, intente nuevamente."
            }))
        })
    }

    return <Grid container>
        <NavBar pushTo={`/alianza/${alianza}`} hide={hideNav}
                secondaryLogo={`${APIHOST}/website/assets/alliance/brands/${alianza}.png`}/>

        {
            access.aprobbed ? <>
                    <Grid item xs={12}>
                        <Carousel autoPlaySpeed={10000} responsive={responsiveBanner} autoPlay={true}
                                  infinite={banners.length > 1}>
                            {
                                banners.map(item => <div key={item.id}>
                                    <img style={{height: "100%", width: "100%"}}
                                         src={noCel ? item.file.files.desktop : item.file.files.mobile}
                                         alt={`banner-${item.id}`}/>
                                </div>)
                            }
                        </Carousel>
                    </Grid>
                    <Container>
                        <Opciones/>
                        {
                            //  <Grid item xs={12} style={{marginTop: "3%"}}>
                            //             <Container>
                            //                 <Grid container>
                            //                     <DescriptionItem icon={Tel} title={"Consulta médica telefónica"}>
                            //                         ¿Tienes algún malestar? Llama a tu línea de Telemedicina inXel, y en breve un medico te
                            //                         atenderá.
                            //                         Disponible las 24 horas.
                            //                     </DescriptionItem>
                            //                     <DescriptionItem icon={Doctor} title={"Médicos Generales disponibles"}>
                            //                         Al comunicarte a tu línea de
                            //                         Telemedicina te atenderá un médico
                            //                         general al instante.
                            //                     </DescriptionItem>
                            //                     <DescriptionItem icon={Expediente} title={"Expediente médico electrónico"}>
                            //                         Contarás con tu expediente médico electrónico, el cual tendrán disponibles los médicos cada vez
                            //                         que
                            //                         solicites tu servicio de telemedicina.
                            //                     </DescriptionItem>
                            //                     <DescriptionItem icon={Receta} title={"Envío de receta médica"}>
                            //                         Al termino de tu consulta médica
                            //                         telefónica, se te enviará tu receta
                            //                         médica vía correo electrónico.
                            //                     </DescriptionItem>
                            //                 </Grid>
                            //             </Container>
                            //         </Grid>
                        }
                        <Grid item xs={12} style={{overflow: "hidden", position: "relative"}}>
                            <div style={{position: 'absolute', height: '100%', width: '100%', zIndex: 0}}>
                                <Particles
                                    options={{
                                        particles: {
                                            "number": {
                                                "value":20
                                            },
                                            "size": {
                                                "value": 3
                                            },
                                            color: XEL_COLORS.primary,
                                            links: {
                                                enable: true,
                                                distance: 150,
                                                color: XEL_COLORS.secondary,
                                                opacity: 0.6,
                                                width: 1,
                                                shadow: {
                                                    enable: false,
                                                    blur: 5,
                                                    color: "lime"
                                                }
                                            },
                                        },
                                        interactivity: {
                                            events: {
                                                onhover: {
                                                    enable: true,
                                                    mode: "repulse"
                                                }
                                            }
                                        }
                                    }
                                    }
                                    init={particlesInit}
                                    style={{height: '100%'}}
                                />
                            </div>

                            <Titulo title={"Nuestros planes"}
                                    subtitle={"Tenemos el plan ideal para ti, navega a la velocidad de inXel y disfruta de todos nuestros beneficios al máximo."}/>
                            {

                                <Grid container justify={"center"} style={{marginBottom: "2%"}}>
                                    <Grid item xs={12} md={5} lg={4} style={{display: "flex", justifyContent: "center"}}>
                                        <Button variant={"contained"} color={active === "plan" ? "secondary" : "primary"}
                                                style={{color: "#FEFEFE"}}
                                                onClick={() => {
                                                    setActive("plan")
                                                }}
                                        >
                                            Telefonía Móvil
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={5} lg={4} style={{display: "flex", justifyContent: "center"}}>
                                        <Button variant={"contained"} color={active === "mifi" ? "secondary" : "primary"}
                                                style={{color: "#FEFEFE"}}
                                                onClick={() => {
                                                    setActive("mifi")
                                                }}
                                        >
                                            Internet Móvil (MiFi)
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={5} lg={4} style={{display: "flex", justifyContent: "center"}}>
                                        <Button variant={"contained"} color={active === "hbb" ? "secondary" : "primary"}
                                                style={{color: "#FEFEFE"}}
                                                onClick={() => {
                                                    setActive("hbb")
                                                }}
                                        >
                                            Internet Fijo
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                            {
                                planes.length > 0 ? <Grid item xs={12}>
                                    <Container style={{padding: 0}}>
                                        <Carousel
                                            className={styles.container}
                                            containerClass={styles.c2}
                                            itemClass={styles.item}
                                            responsive={responsiveCar}
                                            arrows={planes.filter(plan => (plan.type === active && plan.alliance_sec === "planes")).length > 2}
                                            infinite={planes.filter(plan => (plan.type === active && plan.alliance_sec === "planes")).length > 2}
                                            ref={carouselRef}
                                            // containerRef={carouselRef}
                                        >
                                            {
                                                planes.map((plan, i) => {
                                                    console.log('plan', plan)
                                                    if (plan.alliance_sec === "planes" && plan.type.toLowerCase() === active)
                                                        return <PlanCard
                                                            titleDiscount={discountNames.filter(i => i.name === alName)[0].value}
                                                            types={"alianza"} plan={plan} key={i}/>
                                                    return null;
                                                })
                                            }
                                        </Carousel>
                                    </Container>
                                </Grid> : ""
                            }

                            {
                                active === 'plan' ? <Container> <Grid container style={{paddingBottom: '1%'}} spacing={1}>
                                    <Grid item xs={12} style={{justifyContent: "center", display: "flex"}}>
                                        <Typography variant={'caption'} align={'center'}
                                            //            style={{fontSize: 11}}
                                        >
                                            * Política de uso justo: Superando los primeros 20GB, cuentas con 20GB
                                            adicionales para
                                            seguir
                                            navegando con velocidad limitada a 512 Kbps. Todos los precios publicados
                                            incluyen
                                            I.V.A.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{justifyContent: "center", display: "flex"}}>
                                        <Typography variant={'caption'} align={'center'}
                                            //            style={{fontSize: 11}}
                                        >
                                            ** Política de uso justo: Superando los primeros 50GB, cuentas con 50GB
                                            adicionales para
                                            seguir
                                            navegando con velocidad limitada a 1 Mbps. Todos los precios publicados incluyen
                                            I.V.A.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{justifyContent: "center", display: "flex"}}>
                                        <Typography variant={'caption'} align={'center'}
                                                    color={"primary"}
                                            //            style={{fontSize: 11}}
                                        >
                                            Para planes con política de uso justo: Al consumir los GB adicionales, el
                                            usuario no
                                            podrá
                                            navegar a menos que contrate
                                            una bolsa adicional de datos (recarga).
                                        </Typography>
                                    </Grid>
                                </Grid></Container> : ""
                            }
                            {
                                active === "mifi" ? <Container>
                                    <Grid container style={{paddingBottom: '1%'}} spacing={1}>
                                        <Grid item xs={12} style={{justifyContent: "center", display: "flex"}}>
                                            <Typography variant={'caption'} align={'center'} style={{marginBottom: '2%'}}>
                                                *Los planes Xel-Fi no incluyen router portátil, el costo será incluido al solicitar la compra y es
                                                un
                                                pago
                                                único Todos los precios publicados incluyen I.V.A. Aplican restricciones.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Container> : ""
                            }
                            {
                                active === "hbb" ? <Container>
                                    <Grid container style={{paddingBottom: '1%'}} spacing={1}>
                                        <Grid item xs={12} style={{justifyContent: "center", display: "flex"}}>
                                            <Typography variant={'caption'} align={'center'}
                                            >
                                                * Política de uso justo: De tus Gigas totales 60GB son para navegar con
                                                velocidad
                                                limitada . Todos los precios publicados incluyen I.V.A.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{justifyContent: "center", display: "flex"}}>
                                            <Typography variant={'caption'} align={'center'}
                                            >
                                                *Los planes Xel Home no incluyen Router (Modem), el costo será incluido al solicitar la compra y es
                                                un
                                                pago único. La compra de tu Plan solo será posible si adquieres tu Router
                                                (Modem).
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Container> : ""
                            }

                            {
                                (active === "mifi" || active === "hbb") && dev ?
                                    <Grid container
                                          style={{
                                              backgroundColor: "rgba(90,54,113,0.06)",
                                              paddingTop: "2%",
                                              paddingBottom: "2%"
                                          }}>
                                        <Container>
                                            <Grid container style={{marginTop: "2%"}} justify={"center"}>
                                                <Grid item xs={12}>
                                                    <Typography variant={"h4"} color={"primary"}
                                                                style={{fontWeight: XEL_FONT.bold_weight}}>
                                                        {active === "mifi" ? "Router Portatil" : "Router fijo"}
                                                    </Typography>
                                                    <Typography variant={"body1"} color={"primary"}>
                                                        {active === "mifi" ? "Dispositivo portátil con el cual podrás disfrutar de tu internet inXel dónde sea y cuando sea." : "Dispositivo fijo, ideal para tu hogar, disfruta de la conexión ideal en todos tus dispositivos."}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={11} md={6}>
                                                    <Card elevation={1} style={{marginTop: "2%"}}>
                                                        <Grid container>
                                                            <Grid item xs={12} style={{backgroundColor: XEL_COLORS.lila}}>
                                                                <Typography align={"center"} variant={"body1"}
                                                                            style={{fontWeight: XEL_FONT.bold_weight}}
                                                                            color={"primary"}>
                                                                    {dev.name}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} style={{padding: "1%"}}>
                                                                <Grid container alignItems={"center"}>
                                                                    <Grid item xs={11} md={4}
                                                                          style={{
                                                                              justifyContent: "center",
                                                                              display: "flex"
                                                                          }}>
                                                                        <img style={{
                                                                            padding: "1%",
                                                                            width: "80%",
                                                                            height: "80%"
                                                                        }}
                                                                             src={dev.image} alt={dev.name}/>
                                                                    </Grid>
                                                                    <Grid item xs={12} md style={{padding: "4%"}}>
                                                                        <Card elevation={2} style={{padding: "3%"}}>
                                                                            <Grid container spacing={2}>
                                                                                {
                                                                                    dev.details.map((det, i) => <Grid
                                                                                        key={i * 4}
                                                                                        item
                                                                                        xs={12}>
                                                                                        <Typography variant={"body2"}
                                                                                                    color={"primary"}
                                                                                                    style={{fontWeight: XEL_FONT.bold_weight}}>
                                                                                            {det.title}: <span
                                                                                            style={{fontWeight: XEL_FONT.regular_weight}}>{det.value}</span>
                                                                                        </Typography>
                                                                                    </Grid>)
                                                                                }
                                                                                <Grid item xs={12}>
                                                                                    <Typography variant={"h4"}
                                                                                                color={"primary"}
                                                                                                align={"center"}>
                                                                                        ${dev.price}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </Grid> : ""
                            }

                            {
                                alName === 'unam' && <>
                                    <Titulo title={"Xel Iuris"}
                                            subtitle={"Disfruta de tu plan ideal de telefonía móvil más un servicio especial, que se convertirá en tu mejor apoyo."}/>

                                    <Grid item xs={12}>
                                        <Typography variant={"h6"} color={"primary"}>
                                            En este plan encontrarás el servicio de la "Biblioteca Virtual Tirant Lo
                                            Blanch". Donde
                                            podrás
                                            consultar
                                            todas las obras de la editorial Jurídica, en cualquier momento.
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} style={{marginTop: "1%"}}>
                                        <Grid container justify={"center"}>
                                            <Grid item xs={11} md={8} lg={6}>
                                                {
                                                    [{
                                                        path: "/website/assets/alliance/res/unam/libro.png",
                                                        alt: "Libro",
                                                        text: "Base de datos profesional, especializada en materia jurídica."
                                                    },
                                                        {
                                                            path: "/website/assets/alliance/res/unam/check.png",
                                                            alt: "Check",
                                                            text: "Información 100% cotejable en fuentes oficiales."
                                                        },
                                                        {
                                                            path: "/website/assets/alliance/res/unam/loading.png",
                                                            alt: "Loading",
                                                            text: "Fuentes actualizadas constantemente (Tres veces al día )."
                                                        },
                                                        {
                                                            path: "/website/assets/alliance/res/unam/cell.png",
                                                            alt: "Cellphone",
                                                            text: "La plataforma no requiere instalación de software para su correcto funcionamiento."
                                                        },
                                                        {
                                                            path: "/website/assets/alliance/res/unam/rectangls.png",
                                                            alt: "Rectangls",
                                                            text: "Acceso a más de 12 millones de documentos jurídicos. (Libros, leyes, jurisprudencia, formularios, doctrina práctica, entre otras)."
                                                        }

                                                    ].map((item, i) =>
                                                        <Card key={i}
                                                              style={{padding: '2%', marginBottom: "1%"}}
                                                        >
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={3}
                                                                      style={{
                                                                          display: "flex",
                                                                          alignItems: "center",
                                                                          justifyContent: "center",
                                                                          borderRight: "3px solid #ad1d3f"
                                                                      }}>
                                                                    <img src={`${APIHOST}${item.path}`} alt={item.alt}
                                                                         style={{width: 50, height: "fit-content"}}/>
                                                                </Grid>
                                                                <Grid item xs={9}>
                                                                    <Typography align={"center"} style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                    }}>
                                                                        {item.text}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                    )
                                                }

                                            </Grid>
                                            <Grid item xs={11} md={4} lg={6} style={{
                                                padding: "2%",
                                            }}>
                                                <img src={`${APIHOST}/website/assets/alliance/res/unam/tirant.png`}
                                                     style={{width: "100%", height: "fit-content"}}
                                                     alt={"Tirant Lo Blanch"}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        planes.length > 0 ? <Grid item xs={12}>
                                            <Container style={{padding: 0}}>
                                                <Carousel
                                                    className={styles.container}
                                                    containerClass={styles.c2}
                                                    itemClass={styles.item}
                                                    responsive={responsiveCar}
                                                    infinite={true}
                                                >
                                                    {
                                                        planes.map((plan, i) => {
                                                            if (plan.alliance_sec === "iuris")
                                                                return <PlanCard
                                                                    titleDiscount={discountNames.filter(i => i.name === alName)[0].value}
                                                                    types={"alianza"} plan={plan} key={i}/>
                                                        })
                                                    }
                                                </Carousel>
                                            </Container>
                                        </Grid> : ""
                                    }
                                </>
                            }

                        </Grid>
                    </Container>
                </> :
                <Grid item xs={12}>
                    <Grid container style={{height: "100vh", minHeight: 300}} alignItems={"center"}>
                        <Grid item xs={12}
                              component={"form"}

                              onSubmit={checkAccess}
                              style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center"
                              }}>
                            <Typography style={{marginBottom: 10}}>
                                Ingresa tu código {alianza.toString().toUpperCase()}
                            </Typography>
                            <TextField
                                required
                                disabled={loading}
                                error={access.error}
                                helperText={access.message}
                                style={{marginBottom: 10}}
                                onChange={({target: {value}}) => {
                                    setAccess(prevState => ({...prevState, code: value}))
                                }}
                                value={access.code}
                            />
                            <Button disabled={loading} variant={"contained"} color={"primary"} type={"submit"}>
                                Ingresar {loading && <CircularProgress size={24} style={{marginLeft: 5}}/>}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
        }

        <Footer/>
    </Grid>
}

export default AlianzaSells;
