import React, { useState, useEffect, useMemo, useRef } from 'react'
import { XEL_FONT } from '../../../constant'
import { useSelector } from 'react-redux'
import { Grid, Container, Typography, makeStyles } from '@material-ui/core'
import Carousel from 'react-multi-carousel'

import DeviceService from '../../utils/services/dispositivos'
import PlanCard from '../../ui/Plan/v2'
import NavBar from '../../ui/NavBar'
import Footer from '../../ui/Footer'

export default () => {
    const planes = useSelector((state) => state.planes)
    const [preplan, setPreplan] = useState([])
    const [plansSelected, setPlansSelected] = useState([])
    const [selection, setSelection] = useState('telefonía móvil')
    const [dev, setDev] = useState(null)

    const devices = DeviceService()

    useEffect(() => {
        const selPlans = planes.normal
            .filter((seccion) => seccion.name === 'Planes')[0]
            .planes.filter(
                (subseccion) =>
                    subseccion.name.toLowerCase() === selection.toLowerCase()
            )[0].planes
        setPlansSelected(selPlans)
        if (selPlans.length > 0)
            if (selPlans[0].req_devs.length > 0) {
                devices
                    .getDevice(selPlans[0].req_devs[0].toString())
                    .then((res) => {
                        setDev(res)
                    })
                    .catch((err) => {})
            }
        const normalPlanes = planes.normal
            .filter((seccion) => seccion.name === 'Planes')[0]
            .planes.filter(
                (subseccion) =>
                    subseccion.name.toLowerCase() === selection.toLowerCase()
            )[0].planes
        let preplanes = planes.normal
            .filter((seccion) => seccion.name === 'Preplan')[0]
            .planes.filter(
                (subseccion) =>
                    subseccion.name.toLowerCase() === selection.toLowerCase()
            )
        if (preplanes.length > 0) {
            preplanes = preplanes[0].planes
            let pre = []

            for (let i = 0; i < preplanes.length; i++) {
                const { id, original } = preplanes[i]
                let search = pre.filter(
                    (pr) => pr.original === original && pr.id !== id
                )
                if (search.length > 0) {
                    let plan = search[0]
                    let preplan = preplanes[i]
                    let detpre = plan.detpre
                    detpre.push({
                        months: preplan.months_service,
                        price: preplan.price,
                        id: preplan.id,
                        noPrice:
                            (parseInt(
                                normalPlanes.filter((p) => p.id === original)[0]
                                    .price
                            ) +
                                1) *
                            preplan.months_service,
                    })
                    search[0] = plan
                } else {
                    let plan = preplanes[i]
                    plan.name = `${plan.name.split(' ')[0]} ${
                        plan.name.split(' ')[1]
                    }`
                    plan.detpre = [
                        {
                            months: plan.months_service,
                            price: plan.price,
                            id: plan.id,
                            noPrice:
                                (parseInt(
                                    normalPlanes.filter(
                                        (p) => p.id === original
                                    )[0].price
                                ) +
                                    1) *
                                plan.months_service,
                        },
                    ]
                    // delete plan.price;
                    pre.push(plan)
                }
            }
            setPreplan(pre)
        } else setPreplan([])
        // eslint-disable-next-line no-undef
        gtag('click_plan', {
            tipo: selection,
        })
    }, [selection])

    return (
        <>
            <NavBar />
            <Container style={{ marginTop: '2%', marginBottom: '4%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant={'h4'}
                            color={'primary'}
                            style={{ fontWeight: XEL_FONT.bold_weight }}
                        >
                            Preplan
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant={'body1'}
                            color={'primary'}
                            style={{ fontWeight: XEL_FONT.regular_weight }}
                        >
                            Nueva modalidad Prepago que te brinda grandes
                            beneficios, adquiere ya el plan ideal para ti y
                            disfruta de tu línea inXel al maxímo.
                        </Typography>
                    </Grid>
                    <Container
                        style={{
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                        <Carouplan planes={preplan} />
                    </Container>
                </Grid>
            </Container>
            <Footer />
        </>
    )
}

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
}

const useStylesCar = makeStyles({
    container: {
        // paddingTop: '2%',
        height: '100%',
        width: '100%',
    },
    c2: {
        paddingTop: 5,
        paddingLeft: 0,
        paddingRight: '2%',
    },
    item: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
})

const Carouplan = ({ planes }) => {
    const styles = useStylesCar()

    const containerRef = useRef()
    const responsiveCar = useMemo(() => {
        if (planes.length > 4) {
            return responsive
        }
        return {
            ...responsive,
            superLargeDesktop: {
                ...responsive.superLargeDesktop,
                items: planes.length,
            },
            desktop: {
                ...responsive.desktop,
                items: planes.length,
            },
        }
    }, [planes])

    useEffect(() => {
        if (containerRef.current) {
            console.log('f', containerRef.current.getBoundingClientRect())
        }
    }, [containerRef])
    return (
        <Grid container>
            <Grid item xs={12}>
                <Container ref={containerRef}>
                    <Carousel
                        className={styles.container}
                        containerClass={styles.c2}
                        itemClass={styles.item}
                        responsive={responsiveCar}
                    >
                        {planes.map((plan) => (
                            <PlanCard key={plan.id} type={'plan'} plan={plan} />
                        ))}
                    </Carousel>
                </Container>
            </Grid>
        </Grid>
    )
}
