import React, {useEffect, useState} from "react";
import {Button, Container, Grid, Typography} from "@material-ui/core";
import {usePhoneFormater, useResponsiveValues} from "../../utils/hooks";
import NavBar from "../../ui/NavBar";
import Footer from '../../ui/Footer';

import Titulo from "./Titulo";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Axios from "../../../instances/axios";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Quejas() {
    const axios = Axios();
    const initialData = {
        asunto: '',
        nombre: '',
        numero: '',
        email: '',
        mensaje: '',
    };
    const initialErrs = {
        asunto: false,
        nombre: false,
        numero: false,
        email: false,
        mensaje: false,
        m_asunto: false,
        m_nombre: false,
        m_numero: false,
        m_email: false,
        m_mensaje: false,
    };
    const mailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const [datos, setDatos] = useState(initialData);
    const {asunto, nombre, numero, email, mensaje} = datos;
    const [datosErr, setDatosErr] = useState(initialErrs);
    const [phone, setPhone] = usePhoneFormater('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState({
        open: false,
        severity: 'success',
        msg: ''
    });
    const [classy, setClassy] = useState('1216')
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Quejas o sugerencias";
    }, [])
    useEffect(() => {
        setPhone(datos.numero);
    }, [datos]);
    const handleChange = e => {
        setDatosErr({
            ...datosErr,
            [e.target.name]: false,
        })
        setDatos({
            ...datos,
            [e.target.name]: e.target.value,
        })
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen({
            ...open,
            open: false,
        });
    };
    const handleSave = e => {
        e.preventDefault();

        let errors = {};

        let flag = false;
        if (!mailReg.test(email)) {
            flag = true;
            errors.email = true;
            errors.m_email = 'Correo no válido'
        }
        if (phone.split('') < 12) {
            flag = true;
            errors.numero = true;
            errors.m_numero = 'Número no válido'
        }
        if (flag) {
            setDatosErr({
                ...datosErr,
                ...errors
            });
            return;
        }
        let splitname = nombre.split(' ');
        let apps = '';
        if (splitname.length > 1)
            apps = splitname[splitname.length - 2] + ' ' + splitname[splitname.length - 1];

        setLoading(true);
        axios.post("/website/save.suggest", {
            nombre,
            email,
            numero: phone,
            apps,
            asunto,
            mensaje,
            tipo: classy
        }).then(res => {
            console.log(res.data);
            setOpen({
                open: true,
                severity: 'success',
                msg: 'Gracias, por su preferencia.'
            });
            setLoading(false);
            setDatos(initialData);
            setDatosErr(initialErrs);
            setClassy('1215');
        }).catch(err => {
            setOpen({
                open: true,
                severity: 'error',
                msg: 'Error de conexión, intenta mas tarde.'
            });
        });
    }
    return <>
        <NavBar/>
        <Container style={{marginTop: '1%'}}>
            <Grid container spacing={3} justify={'center'}>
                <Titulo/>
                <Grid item xs={12} component={'form'} onSubmit={handleSave}>
                    <Grid container spacing={1} justify={'center'} alignItems={'center'}>
                        <Grid item xs={11} md={7}>
                            <RadioGroup aria-label="gender" name="type" style={{display: "flex", flexDirection: "row"}}
                                        value={classy} onChange={event => {
                                setClassy(event.target.value)
                            }}>
                                <FormControlLabel style={{width: '40%'}} value="1216" control={<Radio/>}
                                                  label="Sugerencia"/>
                                <FormControlLabel style={{width: '40%'}}  value="1215" control={<Radio/>} label="Queja"/>
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={11} sm={8} md={7}>
                            <TextField error={datosErr.asunto} helperText={datosErr.m_asunto} value={asunto}
                                       onChange={handleChange} id="asunto" required variant="outlined"
                                       name={'asunto'}
                                       label="Asunto"
                                       color="secondary"
                                       fullWidth/>
                        </Grid>
                        <Grid item xs={11} sm={8} md={7}>
                            <TextField error={datosErr.nombre} helperText={datosErr.m_nombre} value={nombre}
                                       onChange={handleChange} id="nombre" required variant="outlined"
                                       name={'nombre'}
                                       label="Nombre Completo"
                                       color="secondary" fullWidth/>
                        </Grid>
                        <Grid item xs={11} sm={8} md={7}>
                            <TextField error={datosErr.numero} helperText={datosErr.m_numero} value={phone}
                                       onChange={handleChange} id="numero" required variant="outlined"
                                       name={'numero'}
                                       label="Número inXel (10 dígitos)"
                                       color="secondary" fullWidth/>
                        </Grid>
                        <Grid item xs={11} sm={8} md={7}>
                            <TextField error={datosErr.email} helperText={datosErr.m_email} value={email}
                                       onChange={handleChange} id="email" required variant="outlined"
                                       name={'email'}
                                       label="Correo Electrónico"
                                       color="secondary" fullWidth/>
                        </Grid>
                        <Grid item xs={11} sm={8} md={7}>
                            <TextField error={datosErr.mensaje} helperText={datosErr.m_mensaje} value={mensaje}
                                       onChange={handleChange} id="mensaje" required variant="outlined"
                                       name={'mensaje'}
                                       label="Mensaje"
                                       multiline
                                       rows={4} color="secondary" fullWidth/>
                        </Grid>
                        <Grid item xs={11} sm={8} md={7}>
                            <Typography style={{fontSize: 11}}>
                                Estimado usuario, en un lapso no mayor a 24 horas hábiles, un ejecutivo te
                                contactará para atender tu solicitud.
                            </Typography>
                            <Typography style={{fontSize: 11}}>
                                Horarios de atención lunes - viernes 9 a 19 horas, sábados de 9 a 15 horas.
                            </Typography>
                        </Grid>
                        <Grid item xs={11} md={7}>
                            <Button disabled={loading} color={'secondary'} variant={'contained'}
                                    style={{color: '#FEFEFE'}}
                                    type={'submit'}>
                                Enviar
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Container>
        <Snackbar open={open.open} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={open.severity}>
                {open.msg}
            </Alert>
        </Snackbar>
        <Footer/>
    </>
}

