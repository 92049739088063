import React, {useCallback, useEffect, useState} from "react";
import {Container, Grid, useMediaQuery} from "@material-ui/core";
import {useParams} from 'react-router-dom';
import Plan from "../../../ui/Plan";
import {makeStyles} from "@material-ui/core/styles";
import {PromosPlan, XEL_COLORS, XEL_FONT} from "../../../../constant";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {XelIconPhoneHand} from "../../../../assets/logos/svg";

import Particles from "react-particles";
import {loadFull} from "tsparticles";
import {useResponsiveValues} from "../../../utils/hooks";
import PromoLogo from "../../../../assets/images/me-gusta.svg";
import {useSelector} from "react-redux";
import PlanCard from "../../../ui/Plan/v2";

const useStyle = makeStyles({
    title_imei: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 30
    },

});
const promo = PromosPlan();
const data1 = {
    name: 'Xel 5+',
    hotspot: true,
    hotspotext: 'Incluye Hotspot',
    primera_desc: '5 GB para navegar y compartir. Incluye redes sociales',
    //   primera_real_desc: 'Incluye Hotspot',
    segunda_desc: '1,500 minutos',
    segunda_real_desc: ' 750 minutos en roaming internacional',
    tercera_desc: '500 Mensajes',
    tercera_real_desc: '250 Mensajes en roaming internacional',
    price: '99',
    period: 'al mes',
    id: '69',
    folio: '406021',
    promos: {
        porta: '79',
        nueva: '90',
        months: 3
    },
    noSocial: true
    //  promo: PromosPlan().XEL5
}
const data2 = {
    name: 'Xel 40',
    hotspot: false,
    primera_desc: '40 GB para navegar y redes sociales (*)',
    // primera_real_desc: '5GB para navegar y para RRSS en roaming internacional',
    segunda_desc: '1,500 minutos',
    segunda_real_desc: '  750 minutos en roaming internacional',
    tercera_desc: '500 Mensajes',
    tercera_real_desc: '250 Mensajes en roaming internacional',
    price: '199',
    period: 'al mes',
    id: '70',
    politicas: true,
    folio: '406022',
    promos: {
        porta: '149',
        nueva: '175',
        months: 2
    },
    noSocial: true
    //  promo: PromosPlan().XELILIMITADO
}

const data3 = {
    name: 'Xel 40+',
    hotspot: true,
    primera_desc: '40 GB para navegar y compartir. Incluye redes sociales (*)',
    //  primera_real_desc: '5GB para navegar y para RRSS en roaming internacional)',
    segunda_desc: '1,500 minutos',
    segunda_real_desc: '  750 minutos en roaming internacional',
    tercera_desc: '500 Mensajes',
    tercera_real_desc: '250 Mensajes en roaming internacional',
    price: '299',
    period: 'al mes',
    id: '82',
    politicas: true,
    folio: '406023',
    promos: {
        porta: '229',
        nueva: '260',
        months: 2
    },
    noSocial: true
}
const data4 = {
    name: 'Xel 100+',
    hotspot: true,
    primera_desc: '100 GB para navegar y compartir. Incluye redes sociales (**)',
    //  primera_real_desc: '5GB para navegar y para RRSS en roaming internacional)',
    segunda_desc: '10,000 minutos',
    segunda_real_desc: '  5,000 minutos en roaming internacional',
    tercera_desc: '10,000 Mensajes',
    tercera_real_desc: '5,000 Mensajes en roaming internacional',
    price: '499',
    period: 'al mes',
    id: '126',
    politicas: true,
    folio: '406024',
    promos: {
        porta: '439',
        nueva: '475',
        months: 4
    },
    noSocial: true
}
export const CheckImei = ({justMessage}) => {
    const style = useStyle();
    return <Container style={{marginBottom: 70}}>
        <Grid container>
            <Grid item xs={12}>
                <Typography classes={{root: style.title_imei}} align={"center"}>
                    Conoce tu <span
                    style={{fontWeight: XEL_FONT.bold_weight, color: XEL_COLORS.secondary}}>IMEI </span> marcando
                    *#06#
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify={'center'}>
                    <Grid item xs={4} md={3} style={{display: "flex", justifyContent: "flex-end"}}>
                        <XelIconPhoneHand style={{fill: 'none', stroke: XEL_COLORS.primary, height: 150}}/>
                    </Grid>
                    <Grid item xs={8} md={4} style={{display: "flex", alignItems: "center"}}>
                        <Grid container>
                            <Grid item xs={12}> <TextField fullWidth id="imei-text" label="Ingresa tu IMEI"/>
                            </Grid>
                            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                <Button variant={"contained"} color={"primary"}
                                        style={{borderRadius: 10, marginTop: 10}}>
                                    Enviar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Container>
}
const FirstOption = ({option}) => {

    const planes = useSelector(state => state.planes);

    const [plans, setPlans] = useState([]);
    const matches2 = useMediaQuery('(min-width:426px)');
    useEffect(() => {
        try {
            let opt = "";

            switch (option) {
                case 1:
                    opt = "telefonía móvil"
                    break;
                case 2:
                    opt = "internet fijo"
                    break;
                default:
                    opt = "telefonía móvil"
                    break;
            }

            let ps = planes.normal.filter(seccion => seccion.name === "Planes")[0].planes.filter(subseccion => subseccion.name.toLowerCase() === opt)[0].planes;
            console.log(ps);
            setPlans(ps);
        } catch (e) {

        }
    }, [option])

    return <>
        <Container>
            <Grid container justify={!matches2 ? 'center' : "space-around"}>
                <Grid item xs={12} style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
                    {
                        plans.map(plan => <PlanCard margin={1} key={plan.id} type={"plan"} plan={plan}/>)
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body2'} align={'center'} style={{fontSize: 11}}>
                        * Política de uso justo: Superando los primeros 20GB, cuentas con 20GB adicionales para
                        seguir
                        navegando con velocidad limitada a 512 Kbps. Todos los precios publicados incluyen I.V.A.
                    </Typography>
                </Grid>
                <br/>
                <Grid item xs={12}>
                    <Typography variant={'body2'} align={'center'} style={{fontSize: 11}}>
                        ** Política de uso justo: Superando los primeros 50GB, cuentas con 50GB adicionales para
                        seguir
                        navegando con velocidad limitada a 1 Mbps. Todos los precios publicados incluyen I.V.A.
                    </Typography>
                </Grid>
                <br/>
                <Grid item xs={12}>
                    <Typography variant={'body2'} align={'center'}
                                style={{fontSize: 11, fontWeight: XEL_FONT.semi_bold_weight}} color={'primary'}>
                        Para planes con política de uso justo: Al consumir los GB adicionales, el usuario no podrá
                        navegar a menos que contrate
                        una bolsa adicional de datos (recarga).
                    </Typography>
                </Grid>
                {
                    promo.withPromo() ? <Grid item xs={12}>
                        <Typography variant={'body2'} align={'center'} style={{
                            fontSize: 11,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <img src={PromoLogo} alt="Amo inXel"
                                 style={{height: '100%', maxWidth: 26, width: '100%', paddingRight: 3}}/> Promoción
                            válida del 25 de enero al 28 de febrero del 2021.
                        </Typography>
                    </Grid> : ""
                }
            </Grid>
        </Container>
    </>
}

const useCatalogStyles = makeStyles({
    title_filter: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 25,
        color: XEL_COLORS.primary
    },
    subtitle_filter: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 20,
        color: XEL_COLORS.primary
    }
});
const useCellStyles = makeStyles({
    container: {
        marginTop: 40
    },
    sub_container: {
        marginTop: 20
    },
    name_cell: {
        color: '#000000',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 35
    },
    model_cell: {
        color: '#4b4b4b',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 25
    },
    price_cell: {
        color: XEL_COLORS.secondary,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 45
    },
    image_container: {
        display: "flex",
        justifyContent: "center"
    },
    button: {
        color: '#4b4b4b',
        borderRadius: 20,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        marginTop: 30,
        fontSize: 20
    }, cell_container: {
        position: "relative",
        cursor: "pointer",
        padding: '10px auto'
    },
    cell_selector: {
        position: "absolute",
        top: 10,
        right: 10
    }
});

const SecondOption = ({cheker}) => {
    const [idSel, setIdSel] = useState(null);
    var array = [];
    useEffect(() => {
        console.log('Selected', idSel)
    }, [idSel]);
    return <>
        <Grid container justify='center'>
            <Plan selector id={'xel_1'} setId={setIdSel} array={array} data={data1}/>
            <Plan selector id={'xel_2'} setId={setIdSel} array={array} data={data2}/>
        </Grid>
    </>
}


export default function DinamicBuy({option, setSel}) {

    const [cheker, setCheker] = useState({
        plan_selected: '',
        plan: false,
        equipo: false,
        equipo_selected: '',
    });
    const particlesInit = useCallback(async engine => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    return <Container style={{paddingTop: 70, paddingBottom: 30, position: "relative"}}>
        <div style={{position: 'absolute', backgrounColor: 'gray', height: '100%', width: '100%', zIndex: -1}}>
            <Particles
                options={{
                    particles: {
                        "number": {
                            "value":20
                        },
                        "size": {
                            "value": 3
                        },
                        color: XEL_COLORS.primary,
                        links: {
                            enable: true,
                            distance: 150,
                            color: XEL_COLORS.secondary,
                            opacity: 0.6,
                            width: 1,
                            shadow: {
                                enable: false,
                                blur: 5,
                                color: "lime"
                            }
                        },
                    },
                    interactivity: {
                        events: {
                            onhover: {
                                enable: true,
                                mode: "repulse"
                            }
                        }
                    }
                }
                }
                init={particlesInit}
            />
        </div>
        {
            <FirstOption option={option} cheker={cheker}/>
        }
    </Container>
}
