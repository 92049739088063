import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import NavBar from "../../ui/NavBar";
import Footer from '../../ui/Footer';
import {makeStyles} from "@material-ui/core/styles";
import {XEL_FONT} from "../../../constant";
import Logo from "../../../assets/logos/inxel/logo-inxel-color.png";
const useStyles = makeStyles({
    title:{
        fontWeight:XEL_FONT.bold_weight,
        fontSize:25
    },
    body:{
        fontSize:20,
        fontWeight:XEL_FONT.regular_weight,
        marginTop:10
    }
});
export default function About() {
    const style = useStyles();
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Acerca de";
    }, [])
    return <Grid container alignItems={'center'}>
        <NavBar/>
        <Grid container style={{minHeight: '80vh'}} alignItems={'center'}>
            <Container>
                <Grid item xs={12} style={{display:"flex",justifyContent:"center"}}>
                    <img src={Logo} alt="inXel" style={{height:120}}/>
                </Grid>
                <Grid item xs={12} style={{marginTop: 20}}>
                    <Typography align={'center'} variant={'h2'} className={style.title}>
                        Misión
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="justify" variant={'h3'} className={style.body}>
                        Posicionarnos como una empresa de alto rendimiento y gestión integral, contribuyendo al negocio
                        de comercialización con ventas y servicios de alta calidad focalizados 100% a los clientes,
                        creando una experiencia digital e incentivando el desarrollo laboral y
                        profesional de nuestros colaboradores.
                    </Typography>
                </Grid>
                <Grid item xs={12}  style={{marginTop: 30}} >
                    <Typography  align={'center'} variant={'h2'} className={style.title}>
                        Visión
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="justify" variant={'h3'}  className={style.body}>
                        Ser el mejor Operador Móvil Virtual de la República, con el más alto nivel de rentabilidad y
                        contribución al negocio, una empresa que logra superar los objetivos de manera continua;
                        trabajando en equipo con todos nuestros colaboradores, con base en la correcta ejecución de
                        nuestra metodología de gestión, planeación ydesarrollo continuo.
                    </Typography>
                </Grid>
            </Container>
        </Grid>
        <Footer/>
    </Grid>

}
