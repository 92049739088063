import React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    top_container: {
        position: "fixed",
        zIndex: 3000,
        height: '100vh',
        backgroundColor:'#FEFEFE',
        width:'100vw',

    },
    items: {
        height: '100%',
        width:'100vw'
    }
})
export default function Loading() {
    const style = useStyles();
    return<Grid container className={style.top_container}>
            <Grid item xs={12} className={style.items}
                  style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CircularProgress color="secondary"/>
            </Grid>
        </Grid>
}