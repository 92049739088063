import React from 'react'
import { XEL_COLORS } from '../../constant'

const {
    primary2,
    primary_dark,
    primary_shiny,
    secondary: secondaryColor,
    secondary_dark,
} = XEL_COLORS

export default ({
    children,
    style,
    y = false,
    reverse = false,
    secondary = false,
    ...rest
}) => (
    <div
        style={{
            background: `linear-gradient(${
                y ? (reverse ? '270deg' : '90deg') : reverse ? '180deg' : '0deg'
            }, ${secondary ? secondaryColor : primary2}, ${
                secondary ? secondaryColor : primary_shiny
            }, ${secondary ? secondary_dark : primary_dark})`,
            ...style,
        }}
        {...rest}>
        {children}
    </div>
)
