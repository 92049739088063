import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import {useResponsiveValues} from "../../../../utils/hooks";
import {XEL_COLORS} from "../../../../../constant";

const useStyles = makeStyles({
    boton: {
        minHeight: 40,
        maxHeight: 160,
        borderRadius: '50%',
        border: `2px solid ${XEL_COLORS.primary}`,
        transition: 'background-color .25s ease-in-out',
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center"
    },
    circle: {
        width: '95%',
        height: '95%',
        borderRadius: '50%',
        border: '1px solid #FEFEFE',
        position: "absolute"
    }
});
const Opcion = ({colorActive, color, Logo, action}) => {
    const size = useResponsiveValues(100, 75, 50);
    const style = useStyles();
    const [mouseIn, setMouseIn] = useState(false);
    return <div onClick={action} style={{
        width: size,
        height: size,
        backgroundColor: mouseIn ? XEL_COLORS.secondary : XEL_COLORS.primary,
        border: mouseIn ? `2px solid ${XEL_COLORS.secondary}` : `2px solid ${XEL_COLORS.primary}`,
        cursor: action ? 'pointer' : 'inherit',

    }} className={style.boton} onMouseEnter={() => {
        setMouseIn(true)
    }} onMouseLeave={() => {
        setMouseIn(false)
    }}>

        <Logo fill={mouseIn ? colorActive : color} style={{
            height: '50%',
            fill: mouseIn ? colorActive : "#FEFEFE",
            fontSize: useResponsiveValues(45, 30, 20),
            stroke: mouseIn ? colorActive : "#FEFEFE",
        }}/>
        {
            mouseIn ? <>
            <span className={style.circle} style={{animation: 'Agrandar .30s ease-in-out'}}>
    </span>
            </> : ''
        }
    </div>
}

Opcion.propTypes = {
    colorActive: PropTypes.string,
    color: PropTypes.string,
    Logo: PropTypes.any,
    action: PropTypes.func,
};

export default Opcion;
