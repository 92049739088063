import React from "react";
import {Button, Grid, Radio} from "@material-ui/core";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import {XEL_FONT} from "../../../../../../../../constant";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CardIcon from "../../../../../../../../assets/improve_pays/card.png";

const CardItem = ({id, number, type, brand, bank, selected, selectCard, toDelete, onDelete}) => {

    return <Grid container style={{borderRadius:10,   boxShadow: "-3px 3px 9px -3px rgba(0,0,0,0.6)", padding: "1%"}}>
        <Grid item xs={2} style={{display: "flex", justifyContent: "center"}}>
            {
                toDelete ? <>
                    <Button onClick={() => {
                        onDelete(id)
                    }}>
                        <DeleteForeverIcon fontSize={"small"} color={"primary"}/>
                    </Button>
                </> : <FormControl component="fieldset">
                    <Radio checked={selected} onClick={selectCard}/>
                </FormControl>
            }
        </Grid>
        <Grid item xs={10} style={{display: "flex", alignItems: "center"}}>
            <Grid container>
                <Grid item xs={2}>
                    <img src={CardIcon} alt={`${number}-${type}-${brand}`} style={{
                        height: 20
                    }}/>
                </Grid>
                <Grid item xs>
                    <Typography variant={"body1"} style={{
                        fontWeight: selected ? XEL_FONT.bold_weight : XEL_FONT.regular_weight
                    }}>
                        {number} {type} - {brand}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

CardItem.propTypes = {
    id: PropTypes.string.isRequired,
    last: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    bank: PropTypes.string.isRequired
}
CardItem.defaultProps = {
    last: "1111",
    type: "credit",
    brand: "Visa",
    bank: "Scotiabank"
}
export default CardItem;
