import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Hidden, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import StepperBuy from './Stepper';
import {useHistory, useParams} from 'react-router-dom';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import {XEL_COLORS, XEL_FONT} from "../../../constant";
import Logo from '../../../assets/images/proceso_pago/inxel.svg';
import {useSelector} from "react-redux";
import Logo2 from '../../../assets/logos/inxel/logo-inxel-color.png';
import Cell from '../../../assets/logos/buy/imei.png';
import {useResponsiveValues} from "../../utils/hooks";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Axios from "../../../instances/axios";

const { primary2, primary_shiny } = XEL_COLORS

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OptionButton = withStyles({
    root: {
        border: '1px solid #3f2550',
        '&:hover': {
            border: '1px solid #ff8b22',
        },
        textTransform: 'none',
        width: '95%',
        height: '100%'
    }
})(Button)

const useGlobalStyles = makeStyles({

    buttonContainer: {
        display: "flex",
        justifyContent: "center"
    },
    subtitle1: {
        color: '#3f2550',
        fontWeight: "bold"
    },
    subtitle2: {
        color: '#3f2550',
    },
    form: {
        marginTop: 20
    },
    input: {
        padding: 5
    },
    options_container: {
        marginBottom: 15
    },
    cancel: {

        color: 'red',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight
    }
});
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


/*const useStyle = makeStyles(theme => {
    const sizeTitle = useResponsiveValues(20, 15, 10);
  //  const sizeRest = useResponsiveValues(20, 14, 8);

    return {
        title_imei: {
            fontFamily: XEL_FONT.name,
            fontWeight: XEL_FONT.regular_weight,
        },
        root: {
            '& input': {
                fontSize:sizeTitle,

            },
            '& label': {
                fontSize:sizeTitle
            },
            '& label.Mui-focused':
                {
                    fontSize: sizeTitle
                    //     color: 'green',
                }
            ,
            '& .MuiOutlinedInput-root':
                {
                    fontSize: sizeTitle,
                    '& fieldset':
                        {
                            //  borderColor: 'red',
                            fontSize: sizeTitle,
                        }
                    ,
                    '&:hover fieldset':
                        {
                            //  borderColor: 'yellow',
                        }
                    ,
                    '&.Mui-focused fieldset':
                        {
                            //   borderColor: 'green',
                            fontSize: sizeTitle,
                        }
                    ,
                }
            ,
        }
        ,
    }
});*/
const useStyle = makeStyles({
    root: {
        '& fieldset': {
            borderColor: '#FEFEFE',
            borderWidth: 2,
        },
    }
});
export const CheckImei = ({imeiReq, imeiData, setImei, justMessage, justCheck}) => {
    //  const style = useStyle();
    const [loading, setLoading] = useState(true);
    const [timerV, setTimerV] = useState(10);
    const [verified, setVerified] = useState({
        status: false,
        valid: 0
    });
    const axios = Axios();
    const history = useHistory();
    const session = useSelector(state => state.login);
    const [detailJustCheck, setDetailJustCheck] = useState('');
    const sizeTitle = useResponsiveValues(25, 18, 12);
    const sizeRest = useResponsiveValues(20, 14, 12);


    useEffect(() => {
        if (imeiReq.verified) {
            if (imeiReq.status)
                setVerified({
                    status: true, valid: imeiReq.valid
                })
            setLoading(false);
        }
    }, [imeiReq])
    const handleCheck = () => {
        //block(true);
        if (imeiData.imei.split('').length === 0) {
            alert('Inserta IMEI');
            return;
        }
        if (imeiData.imei.split('').length < 14) {
            alert('IMEI no válido');
            return;
        }
        setLoading(true);
        axios.get(`/status/imei/${imeiData.imei}`).then(res => {
            let response = res.data;
            if (response.status === 'ERROR') {
                alert(response.message);
                // eslint-disable-next-line no-undef
                gtag("verificar_compatibilidad", {
                    imei: imeiData.imei,
                    status: "error",
                    message: response.message
                })
                if (session.logged) {
                    axios.post("/clients/leads/add.because.fail", {
                        tipo: "IMEINOTCOMPATIBLE",
                        extras: {
                            imei: imeiData.imei,
                            status: response.message
                        }
                    }).then(res => {
                        console.log("Guardado");
                    }).catch(err => {
                        console.log("No guardado")
                    });
                }
                /* */
            } else if (response.status === 'OK') {
                setVerified({
                    status: true,
                    valid: 1
                });
                // eslint-disable-next-line no-undef
                gtag("verificar_compatibilidad", {
                    imei: imeiData.imei,
                    status: "compatible",
                    message: response.message
                })
                setDetailJustCheck(`(${response.message})`);
            } else if (response.status === 'WARNING') {
                setVerified({
                    status: true,
                    valid: 3
                });
                // eslint-disable-next-line no-undef
                gtag("verificar_compatibilidad", {
                    imei: imeiData.imei,
                    status: "compatible",
                    message: response.message
                })
                setDetailJustCheck(`(${response.message})`);
            }
            setLoading(false);
        }).catch(err => {
            console.log(err);
            alert("Error al consultar, intenta de nuevo.")
            setLoading(false);
        })
        /* fetch(`https://inxel.mx/api/xel/v1/imei/${imeiData.imei}`, {
             method: 'GET',
         }).then(response => response.json())
             .then(response => {
                 let invalid = false;
                 let caso = 0;
                 let data = {
                     xel_token: session.token,
                     imei: imeiData.imei.trim(),
                     status: '',
                 }
                 if (response.errorCode) {
                     if (response.errorCode === 400 || response.errorCode === '400') {
                         setTimeout(() => {
                             handleCheck();
                         }, 1500)
                         return;
                     }
                     if (response.errorCode === 500 || response.errorCode === '500') {
                         alert('Error al validar.');
                         return;
                     }

                     if (response.errorCode === 500 || response.errorCode === '500') {
                         alert('Error al validar.');
                         return;
                     }
                     if (response.errorCode === 'E-002') {
                         alert('IMEI no válido.');
                         return;
                     }
                     alert('Error desconocido: ' + response.errorCode)
                     return;
                 }
                 if (response.deviceFeatures.band28 === 'SI') {
                     if (response.imei.homologated === 'SI' || response.imei.homologated === 'VOLTE' || response.imei.homologated === 'HOMOLOGADO' || response.imei.homologated.split(' ')[0] === 'VOZAPP' || response.imei.homologated === 'NO PROBADO') {
                         invalid = false;
                         data.status = 'Compatible';
                         caso = 1;
                         if (justCheck) {
                             if (response.imei.homologated === 'SI' || response.imei.homologated === 'VOLTE' || response.imei.homologated === 'HOMOLOGADO') {
                                 setDetailJustCheck('(Homologado o VoLTE)');
                             } else if (response.imei.homologated.split(' ')[0] === 'VOZAPP') {
                                 setDetailJustCheck('(VOZAPP)');
                             } else if (response.imei.homologated === 'NO PROBADO') {
                                 setDetailJustCheck('(Equipo no probado, se requiere configuración manual)');
                             }
                         }
                     } else if (response.imei.homologated === 'NO') {
                         invalid = true;
                         data.status = '118';
                         caso = 3;
                         if (justCheck) {
                             setDetailJustCheck('(Equipo no probado, se requiere configuración manual)');
                         }
                     } else {
                         invalid = true;
                         data.status = '124';
                         caso = 4;
                     }
                 } else {
                     invalid = true;
                     data.status = '120';
                     caso = 2;
                 }

                 if (invalid) {
                     if (!justCheck)
                         fetch('https://inxel.mx/api/xel/v1/ecommerce.lead.imei.invalid.json', {
                             method: 'POST',
                             body: JSON.stringify(data)
                         }).then(response => response.json())
                             .then(response => {
                                 console.log(response)
                                 if (response.status === 'OK') {
                                     setLoading(false);
                                     setVerified({
                                         status: true,
                                         valid: caso
                                     });
                                 }
                             }).catch(error => {
                             console.log(error);
                         })
                     else {
                         setLoading(false);
                         setVerified({
                             status: true,
                             valid: caso
                         });
                     }
                 } else {
                     setLoading(false);
                     setVerified({
                         status: true,
                         valid: caso
                     });
                 }
             }).catch(error => {
             setLoading(false);
             console.log(error)
             //  alert('Sin conexión a internet');
             console.log(`https://altanredes-prod.apigee.net/ac/v1/imeis/${imeiData.imei}/status`);
             setVerified({
                 status: true,
                 valid: false
             });

         });*/

    }
    const handleChange = e => {
        if (!isNaN(e.target.value))
            setImei({
                ...imeiData,
                imei: e.target.value
            });
    }

    const IMEI = (
        <Grid container justify={'center'}>
            <Grid xs={12} md={7} style={{borderRadius: 7, boxShadow: '11px 11px 8px #000000BF'}}>
                <Grid container>
                    <Grid item xs={12}
                          style={{backgroundColor: XEL_COLORS.secondary, borderRadius: 7, padding: '2%'}}>
                        <Typography variant={'h2'} style={{
                            color: '#FEFEFE',
                            fontWeight: XEL_FONT.regular_weight,
                            fontSize: sizeTitle
                        }}>
                            Consulta la <strong>compatibilidad</strong> de tu celular
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{padding: '4%'}}>
                        <Typography variant={'body1'} color={'primary'} align={'center'} style={{fontSize: sizeRest}}>
                            Para poder revisar la compatibilidad de tu celular es necesario que obtengas el <strong>código
                            IMEI</strong> de tu celular
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{padding: '4%'}}>
                        <Grid container>
                            <Grid xs={12} md={6}>
                                <ol style={{listStyle: 'none'}}>
                                    <li>
                                        <Typography variant={'body1'} color={'primary'} style={{fontSize: sizeRest}}>
                                            <strong>1.</strong> Ingresa a la <strong>marcación</strong> de tu
                                            celular
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} color={'primary'} style={{fontSize: sizeRest}}>
                                            <strong>2.</strong> Marca <strong>*#06#</strong>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} color={'primary'} style={{fontSize: sizeRest}}>
                                            <strong>3.</strong> ¡Listo ahora tienes tu <strong>IMEI</strong>!
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} color={'primary'} style={{fontSize: sizeRest}}>
                                            <strong>4.</strong> Ingresa tu <strong>código</strong> en el cuadro
                                        </Typography>
                                    </li>
                                </ol>
                            </Grid>
                            <Hidden only={["xs", "sm"]}>
                                <Grid item xs={6} style={{display: "flex", justifyContent: "center"}}>
                                    <img src={Cell} alt="Marcacion"
                                         style={{maxWidth: 147, maxHeight: 274, width: '100%', height: '100%'}}/>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{padding: '4%'}}>
                        <Grid container justify={'center'}>
                            <Grid item xs={10} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="imei-text" style={{fontSize: sizeTitle}}>Ingresa tu
                                        IMEI</InputLabel>
                                    <Input id="imei-text" value={imeiData.imei} onChange={handleChange}
                                           style={{fontSize: sizeTitle}}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                <Button variant={"contained"} color={"primary"}
                                        style={{borderRadius: 10, marginTop: 10, fontSize: sizeTitle,}}
                                        onClick={handleCheck}
                                >
                                    Enviar
                                </Button>
                                {
                                    justCheck && <Button
                                        variant={"contained"} color={"secondary"}
                                        style={{
                                            borderRadius: 10,
                                            marginTop: 10,
                                            fontSize: sizeTitle,
                                            marginLeft: 10,
                                            color: "#FEFEFE"
                                        }}
                                        onClick={() => {
                                            window.history.go(-1);
                                        }
                                        }>
                                        Volver
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    return <div style={{minHeight: '55vh', width: '100%', display: "flex", alignItems: "center"}}>
        {
            !loading ? <Grid container>

                {
                    !verified.status ? IMEI : ''
                }

                {
                    /*<Grid item xs={12} style={{marginTop:30,padding:5}}>
                    <Typography style={{
                        fontFamily: XEL_FONT.name,
                        fontWeight: XEL_FONT.semi_bold_weight,
                        fontSize: 18,
                        color: '#FEFEFE',
                        backgroundColor: XEL_COLORS.primary
                    }} align={'center'}>
                        ¡Lo sentimos! Tu celular no es compatible con la red inXel, pero no te preocupes, escríbenos por
                        el chat para darte más opciones para que te unas a la familia inXel
                    </Typography>
                </Grid>*/
                }
                {
                    verified.status ? verified.valid === 1 || verified.valid === 3 ?
                        <Grid item xs={12}
                              style={{marginTop: 30, display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <img src={Logo2} style={{height: 65, marginRight: 10}} alt="inXel"/>
                            <Typography style={{
                                fontFamily: XEL_FONT.name,
                                fontWeight: XEL_FONT.regular_weight,
                                fontSize: 30,
                                marginTop: '1%'
                                //     color: '#FEFEFE',
                                //    backgroundColor: XEL_COLORS.primary
                            }} align={'center'}>
                                ¡Felicidades!
                            </Typography>
                            <Typography style={{
                                fontFamily: XEL_FONT.name,
                                fontWeight: XEL_FONT.semi_bold_weight,
                                fontSize: 18,
                                marginTop: '1%'
                                //     color: '#FEFEFE',
                                //    backgroundColor: XEL_COLORS.primary
                            }} align={'center'}>
                                Tu equipo es compatible con la red inXel {detailJustCheck}
                            </Typography>

                            <Grid item xs={12} md={6} style={{display: "flex", justifyContent: "space-around"}}>
                                <Button style={{marginTop: '1%', marginRight: 10}} onClick={() => {
                                    if (justCheck) {
                                        window.location.reload();
                                    } else {
                                        setImei({
                                            ...imeiData,
                                            valid: true
                                        });
                                    }
                                }} color={'primary'} variant={'outlined'}>
                                    {
                                        justCheck ? 'Verificar otro IMEI' : 'Continuar'
                                    }
                                </Button>
                                {
                                    justCheck &&
                                    <Button onClick={() => window.history.go(-1)} color={'primary'}
                                            variant={'outlined'}>
                                        Volver a Inicio
                                    </Button>
                                }
                            </Grid>


                        </Grid> : '' : ''
                }

                {
                    verified.status ? verified.valid === 4 || verified.valid === 2 ?
                        <Grid item xs={12} style={{marginTop: 30, padding: 5, display: "flex", alignItems: "center"}}>
                            <img src={Logo} style={{height: 65, marginRight: 10}} alt="inXel"/>
                            <Typography style={{
                                fontFamily: XEL_FONT.name,
                                fontWeight: XEL_FONT.semi_bold_weight,
                                fontSize: 18,
                                //  color: '#FEFEFE',
                                //backgroundColor: XEL_COLORS.primary
                            }} align={'center'}>
                                Estimad@ cliente, agradecemos su registro con inXel , detectamos que su IMEI NO ES
                                COMPATIBLE con nuestra red, en el transcurso máximo de 5 días hábiles, un asesor inXel
                                lo contactará para ofrecerle algunas alternativas, reiterando que estamos a sus órdenes,
                                se despide su equipo de asesores InXel.
                            </Typography>
                            <Button style={{width: 150, marginLeft: 15, color: '#FEFEFE'}} onClick={() => {
                                history.push('/');
                            }} color={'secondary'} variant={'contained'}>Finalizar</Button>
                        </Grid> : '' : ''
                }

            </Grid> : <Grid container justify={'center'} alignItems={'center'}>
                <CircularProgress color="secondary"/>
            </Grid>
        }
    </div>
}

const useImeiStyles = makeStyles({
    step: {
        backgroundColor: XEL_COLORS.primary,
        padding: 10,
        borderRadius: "50%",
        marginRight: 5,
        maxWidth: 20,
        maxHeight: 20,
        width: 20,
        height: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FEFEFE"
    },
    stepContainer: {display: "flex", alignItems: "center", marginBottom: 13}

})
export const CheckImeiv2 = ({imeiReq, imeiData, setImei, justMessage, justCheck, planOption}) => {
    const style = useImeiStyles();
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState({
        status: false,
        valid: 0
    });
    const axios = Axios();

    const sizeMes = useResponsiveValues(18, 15, 12);

    const session = useSelector(state => state.login);
    const [detailJustCheck, setDetailJustCheck] = useState('');

    const history = useHistory();
    useEffect(() => {
        if (imeiReq.verified) {
            if (imeiReq.status)
                setVerified({
                    status: true, valid: imeiReq.valid
                })
            setLoading(false);
        }
    }, [imeiReq])
    const handleCheck = () => {
        //block(true);
        let imei = imeiData.imei;
        if (imei.length === 0) {
            alert('Inserta IMEI');
            return;
        }
        if (imei.length < 14) {
            alert('IMEI no válido');
            return;
        }

        setLoading(true);
        axios.get(`/status/imei/${imei}`).then(res => {
            let response = res.data;
            window.location.href = '#steps-to-buy';
            if (response.status === 'ERROR') {
                setLoading(false);
                setVerified({
                    status: true,
                    valid: 2
                })
                //alert(response.message);
                if (false)
                    if (session.logged) {
                        axios.post("/clients/leads/add.because.fail", {
                            tipo: "IMEINOTCOMPATIBLE",
                            extras: {
                                imei: imeiData.imei,
                                status: response.message
                            }
                        }).then(res => {
                            console.log("Guardado");
                        }).catch(err => {
                            console.log("No guardado")
                        });
                    }
                /* */
            } else if (response.status === 'OK') {
                setVerified({
                    status: true,
                    valid: 1
                });
                if (!justCheck) {
                    setImei({
                        ...imeiData,
                        valid: true
                    });
                }else{
                    setLoading(false);
                }
                setDetailJustCheck(`(${response.message})`);
            } else if (response.status === 'WARNING') {
                setVerified({
                    status: true,
                    valid: 3
                });
                setLoading(false);
                setDetailJustCheck(`(${response.message})`);
            }

        }).catch(err => {
            console.log(err);
            alert("Error al consultar, intenta de nuevo.")
            setLoading(false);
            setVerified({
                status: true,
                valid: false
            });
        })
    }
    const handleChange = e => {
        if (!isNaN(e.target.value))
            setImei({
                ...imeiData,
                imei: e.target.value
            });
    }

    const IMEIv2 = (
	<Grid item xs={12}>
	    <div style={{
		display: "flex", justifyContent: "center", alignItems: "center",
		backgroundColor: XEL_COLORS.secondary,
		borderRadius: 10,
		padding: '2%'
	    }}>
		<FormControl fullWidth>
		    <InputLabel htmlFor="imei-text"
				style={{color: '#FEFEFE'}}>Ingresa
			tu
			IMEI</InputLabel>
		    <Input color={'secondary'} id="imei-text" value={imeiData.imei}
			   onChange={handleChange}
			   style={{
			       color: "#FEFEFE",
			       borderBottom: '2px solid #FEFEFE'
			   }}/>
		</FormControl>
	    </div>
	    <Button
	    style={{marginTop: '1%',
				    background: 
					`linear-gradient(${primary2}, ${ primary_shiny })`,
				    color: 'white',

	    }}
		onClick={handleCheck}
		 variant={'contained'}>
		Continuar
	    </Button>
	</Grid>
    )

    return <div  style={{//minHeight: '55vh'        ,
        width: '100%', display: "flex", alignItems: "center"
    }}>
        {
            !loading ? <Grid container>

                {
                    !verified.status ? IMEIv2 : ''
                }

                {
                    /*<Grid item xs={12} style={{marginTop:30,padding:5}}>
                    <Typography style={{
                        fontFamily: XEL_FONT.name,
                        fontWeight: XEL_FONT.semi_bold_weight,
                        fontSize: 18,
                        color: '#FEFEFE',
                        backgroundColor: XEL_COLORS.primary
                    }} align={'center'}>
                        ¡Lo sentimos! Tu celular no es compatible con la red inXel, pero no te preocupes, escríbenos por
                        el chat para darte más opciones para que te unas a la familia inXel
                    </Typography>
                </Grid>*/
                }
                {
                    verified.status ? verified.valid === 1 || verified.valid === 3 ?
                        <Grid item xs={12}
                              style={{marginTop: 5, display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Typography variant={"h6"} align={'center'} color={"primary"}>
                                ¡Felicidades!
                            </Typography>
                            <img src={Logo2} style={{height: 65, marginRight: 10, marginTop: 5}} alt="inXel"/>
                            <Typography style={{
                                marginTop: 10,
                                marginBottom: 10,
                            }} align={'center'} color={"primary"}>
                                Tu equipo <strong>SI</strong> es compatible con la red inXel
                            </Typography>
                            <Typography style={{
                                margin: 3,
                                padding: 5,
                                borderRadius: 5,
                                color: '#FEFEFE',
                                backgroundColor: XEL_COLORS.primary
                            }} align={'center'} variant={"caption"}>
                                Recuerda que tu equipo debe estar liberado, es decir que acepte el Chip de cualquier
                                compañía de telefonía celular.
                            </Typography>
                            <Button style={{width: 120, marginTop: '1%', fontSize: sizeMes, color: "#FEFEFE"}}
                                    onClick={() => {
                                        if (justCheck) {
                                            window.location.reload();
                                        } else {
                                            setImei({
                                                ...imeiData,
                                                valid: true
                                            });
                                        }
                                    }} color={'secondary'} variant={'contained'}>Continuar</Button>
                        </Grid> : '' : ''
                }

                {
                    verified.status ? verified.valid === 4 || verified.valid === 2 ?
                        <Grid item xs={12} style={{
                            marginTop: 5,
                            padding: 5,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <Grid container spacing={2} justify={'center'}>
                                <Grid item xs={12} md={6}>
                                   <Grid container spacing={2}>
                                       <Grid item xs={12}>
                                           <Typography variant={"h4"} align={'center'} color={"primary"}>
                                               ¡Lo sentimos!
                                           </Typography>
                                       </Grid>
                                       <Grid item xs={12} style={{display:"flex",justifyContent:"center"}}>
                                           <img src={Logo2}
                                                style={{
                                                    maxWidth:150,
                                                    width:"100%",
                                                    height: "100%",
                                                    marginRight: 10,
                                                    filter: "grayscale(100%)"
                                                }}
                                                alt="inXel"/>
                                       </Grid>
                                       <Grid item xs={12}>
                                           <Typography align={'center'} color={"primary"}>
                                               Tu equipo <strong>NO</strong> es compatible con la red inXel
                                           </Typography>
                                       </Grid>
                                       <Grid item xs={12} style={{display:"flex",justifyContent:"center",flexWrap:"wrap"}}>
                                           <Button
                                               style={{color: "#FEFEFE", margin: 5}}
                                               onClick={() => {
                                                   setVerified({
                                                       status: false,
                                                       valid: 0
                                                   });
                                               }} color={'secondary'} variant={'contained'}>
                                               Checar otro Imei
                                           </Button>
                                           <Button
                                               style={{margin: 5}}
                                               onClick={() => {
                                                   history.push('/');
                                               }} color={'primary'} variant={'contained'}>
                                               Inicio
                                           </Button>
                                       </Grid>
                                   </Grid>
                                </Grid>
                                <Grid item xs={11}  md={4} lg={6} style={{display: "flex", justifyContent: "center"}}>
                                    <img src={"https://api.inxel.mx/website/assets/web/media/imei/imei2.2.png"} alt="Imei no compatible" style={{width: "80%", height: "100%"}}/>
                                </Grid>
                            </Grid>
                        </Grid> : '' : ''
                }

            </Grid> : <Grid container justify={'center'} alignItems={'center'}>
                <CircularProgress color="secondary"/>
            </Grid>
        }
    </div>
}

export default function ({open, setOpen, idplan, option}) {

    const styles = useGlobalStyles();
    const backStyles = useStyles();
    const [portaSelected, setPortaSelected] = useState(true);
    const history = useHistory();
    const params = useParams();
    const [imeiData, setImeiData] = useState({
        imei: '',
        valid: false
    })
    const [validId, setValidId] = useState({
        isValid: false,
        value: '',
        verified: false
    });
    const [block, setBlock] = useState(false);

    const [checkingImei, setCheckingImei] = useState(true);
    const handleClose = () => {
        if (!block)
            history.push('/')
    };
    useEffect(() => {
        setTimeout(() => {
            const id = params.id;
            if (id) {
                if (id.split('').length > 0) {
                    setValidId({
                        isValid: true,
                        value: id,
                        verified: true
                    })


                }

            }
        }, 3000)


    }, []);


    const ValidIMEI = (<>
        <Grid container justify={'center'} className={styles.options_container}>
            <Grid item xs={10} md={6} className={styles.buttonContainer} style={!portaSelected ? {opacity: '.7'} : {}}>
                <OptionButton onClick={() => {
                    setPortaSelected(true);
                }}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="subtitle1" className={portaSelected ? styles.subtitle1 : ''}>
                            Portame a inXel
                        </Typography>
                        <Typography variant="subtitle2" className={portaSelected ? styles.subtitle2 : ''}>
                            Cámbiate a inXel conservando tu número de teléfono actual.
                        </Typography>
                    </div>
                </OptionButton>
            </Grid>
            <Grid item xs={10} md={6} className={styles.buttonContainer} style={portaSelected ? {opacity: '.7'} : {}}>
                <OptionButton onClick={() => {
                    setPortaSelected(false);
                }}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="subtitle1" className={!portaSelected ? styles.subtitle1 : ''}>
                            Línea nueva
                        </Typography>
                        <Typography variant="subtitle2" className={!portaSelected ? styles.subtitle2 : ''}>
                            Obtienes un nuevo número.
                        </Typography>
                    </div>
                </OptionButton>
            </Grid>
        </Grid>
        <Divider/>
        <StepperBuy idPlan={idplan} isPorta={portaSelected}/>
    </>);


    const ResultValidation = (<Container>
        <Grid container>
            <Grid item xs={12}>
                Tu dispositivo con IMEI {imeiData.imei} {imeiData.valid ? 'Es compatible' : 'No es compatible'}
            </Grid>
        </Grid>
    </Container>);
    return (<>
            {
                validId.isValid ? <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="title-buy">

                            <Typography style={{fontFamily: XEL_FONT.name, fontWeight: XEL_FONT.bold_weight, fontSize: 20}}>
                                Forma parte de inXel
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            {
                                imeiData.valid || option === 2 ? ValidIMEI :
                                    <CheckImei block={setBlock} imeiData={imeiData} setImei={setImeiData}/>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} classes={{root: styles.cancel}} disabled={block}>
                                Cancelar
                            </Button>
                        </DialogActions>
                    </Dialog> :
                    <Backdrop className={backStyles.backdrop} open={true}>
                        {
                            validId.verified ? '' : <CircularProgress color="secondary"/>
                        }
                    </Backdrop>
            }
        </>
    );
}
