import React, {useEffect, useState} from "react";
import {Container, Grid, withStyles} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import Footer from "../../ui/Footer";
import {makeStyles} from "@material-ui/core/styles";
import {PromosPlan, XEL_COLORS, XEL_FONT} from "../../../constant";
import {useParams} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import SIM from '../../../assets/images/tarjeta-sim/tarjeta-simHd.png'
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../../actions';
import {useHistory} from 'react-router-dom';
import LoginForm from "../../ui/LoginForm";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import OpenPayLogoColor from "../../../assets/logos/OpenPay/openpay_color.png";
import MasterCardLogo from "../../../assets/logos/OpenPay/masterCard.png";
import VisaLogo from "../../../assets/logos/OpenPay/visa.png";
import Amex from "../../../assets/logos/OpenPay/americanExpress.png";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Logo from '../../../assets/logos/inxel/ICONO M.png';
import {useQuery, useResponsiveValues} from "../../utils/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import Titulo from "./Titulo";
import PasosCompra from "../../ui/BuyDialog/Stepperv2";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import CelService from "../../utils/services/celulares";
import PacksService from "../../utils/services/packs";
import DeviceService from "../../utils/services/dispositivos";
import Axios from "../../../instances/axios";


const useStyles = makeStyles(theme => ({
    step: {
        backgroundColor: XEL_COLORS.primary,
    },
    text: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight
    },
    container_steps: {
        minHeight: '70vh',
        paddingTop: 10,
        display: "flex",
        alignItems: "flex-start"
    },
    title_resume: {
        color: XEL_COLORS.primary,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.bold_weight,
        fontSize: 17,
        maxHeight: 40,
        height: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: XEL_COLORS.primary,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));


export default function Comprar({option, hideNav}) {
    const styles = useStyles();
    const dispatch = useDispatch();

    const [planOption, setPlanOption] = useState(option);
    const dataSend = useSelector(state => state.dataSend);
    const session = useSelector(state => state.login);
    const planPrice = useSelector(state => state.planprice);
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);
    const query = useQuery();
    const params = useParams();
    const [recurrent, setRecurrent] = useState({
        want: false,
        same: true,
    });
    const axios = Axios();

    /**********Seccion Pago*************/
    const actualYear = new Date().getFullYear();
    const [infoPago, setInfoPago] = useState({
        name_card: '',
        card: '',
        month: '01',
        year: '' + actualYear,
        cvv: ''
    });
    const [infoPagoErrors, setInfoPagoErrors] = useState({
        err_name_card: false,
        mg_name_card: '',
        err_card: false,
        mg_card: '',
        err_month: false,
        mg_month: '',
        err_year: false,
        mg_year: '',
        err_cvv: false,
        mg_cvv: '',
        status_pay: false,
        msg_pay: ''
    });
    const titles = useResponsiveValues(20, 15, 12);
    const onlyLettersReg = /^[a-zA-Z\s]*$/;
    const onlyNumber = /^[0-9]+$/
    // eslint-disable-next-line no-undef
    const OpenPayInstance = OpenPay;
    // const Months = [, , , , , , , , 'Semptiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const Mont = [{
        id: '01',
        name: 'Enero'
    },
        {
            id: '02',
            name: 'Febrero'
        },
        {
            id: '03',
            name: 'Marzo'
        },
        {
            id: '04',
            name: 'Abril'
        },
        {
            id: '05',
            name: 'Mayo'
        },
        {
            id: '06',
            name: 'Junio'
        },
        {
            id: '07',
            name: 'Julio'
        },
        {
            id: '08',
            name: 'Agosto'
        },
        {
            id: '09',
            name: 'Septiembre'
        },
        {
            id: '10',
            name: 'Octubre'
        },
        {
            id: '11',
            name: 'Noviembre'
        },
        {
            id: '12',
            name: 'Diciembre'
        },
    ];


    const Years = () => {
        const date = new Date();
        let year = date.getFullYear();
        let years = [];
        for (let i = 0; i < 13; i++)
            years.push(year++);
        return years;
    }
    const handleChangeRecurrent = e => {
        let namerr_check_reset = `err_${[e.target.name]}`;
        let namerr_msg_reset = `msg_${[e.target.name]}`;
        setInfoPagoErrors({
            ...infoPagoErrors,
            [namerr_check_reset]: false,
            [namerr_msg_reset]: ''
        });

        switch (e.target.name) {
            case 'name_card':
                setInfoPago({
                    ...infoPago,
                    name_card: e.target.value
                })
                break;
            case 'card':
                if (e.target.value.split('').length === 0)
                    setInfoPago({
                        ...infoPago,
                        card: ''
                    })
                else if (e.target.value.split('').length < 17)
                    if (onlyNumber.test(e.target.value)) {
                        setInfoPago({
                            ...infoPago,
                            card: e.target.value
                        })
                    }
                break;
            case 'month':
                setInfoPago({
                    ...infoPago,
                    month: e.target.value
                })
                break;
            case 'year':

                setInfoPago({
                    ...infoPago,
                    year: e.target.value
                });

                break;
            case 'cvv':
                if (e.target.value.split('').length === 0)
                    setInfoPago({
                        ...infoPago,
                        cvv: ''
                    })
                else if (e.target.value.split('').length < 5)
                    if (onlyNumber.test(e.target.value)) {
                        setInfoPago({
                            ...infoPago,
                            cvv: e.target.value
                        })
                    }
                break;
        }
    }
    /**********Fin Seccion Pago*************/
    const planes = useSelector(state => state.planes);
    const login = useSelector(state => state.login);

    const [payed, setPayed] = useState({
        completed: false,
        data: {},
        card: {}
    });
    const [imei, setImei] = useState({
        imei: '',
        valid: false
    });
    const [free, setFree] = useState({
        status: false,
        value: 'NO',
        cheking: false,
    });
    const [imeirequest, setImeiRequest] = useState({
        status: false,
        valid: 0,
        verified: false,
    })
    const [finishing, setFinishing] = useState(false);
    const sizeTitle = useResponsiveValues(20, 15, 10);
    const [selectedPlanDetails, setSelectedPlanDetails] = useState({});
    const DevService = DeviceService();
    const packService = PacksService();
    const [deviceIncluded, setDeviceIncluded] = useState(false);

    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Comprando";
    }, [])
    useEffect(() => {
        load();
    }, []);
    const load = async () => {
        if (!planes) {

            history.push('/');
            return;
        }
        if (!planes.normal || !planes.alliance) {
            history.push('/');
            return;
        }

        switch (option) {
            case 1://MBB
            case 6://Alianza
            case 7://Preplan
                let planId = params.idp; //MBB
                let planData = [];


                if (!planId) {
                    planId = params.idpall;
                    if (planId) {//alliance
                        planData = planes.alliance.filter(p => p.id === planId);
                    } else {//Preplan
                        planId = params.idpre;
                        planData = planes.normal.filter(seccion => seccion.name === "Preplan")[0].planes
                            .filter(subseccion => subseccion.name === "Telefonía Móvil");

                        planData = planData[0].planes.filter(plan => plan.id === planId);
                    }
                } else {
                    planData = planes.normal.filter(seccion => seccion.name === "Planes")[0].planes
                        .filter(subseccion => subseccion.name === "Telefonía Móvil");

                    planData = planData[0].planes.filter(plan => plan.id === planId);
                }

                if (option === 6) {
                    try {
                        planData = await axios("/catalog/products/alliance/id/?id=" + planId);
                        planData = planData.data;

                    } catch (e) {
                        console.log(e);
                    }
                }

                if (planData.length > 0) {
                    let {id, name, price, req_devs, type} = planData[0];

                    if (type === "hbb") {
                        setPlanOption(5);
                    }

                    let car = {
                        total: +price,
                        products: [
                            {
                                title: option === 7 ? "Primera recarga" : "Renta mensual",
                                price,
                                cuantity: 1,
                                price_original: planData[0].price,
                                id
                            },
                        ],
                        months: 0,
                        saldo: 0
                    }
                    if (req_devs && req_devs.length > 0) {
                        let extraProducts = [];
                        if (type === "mifi")
                        {
                            setDeviceIncluded(true);
                            setPlanOption(4);
                        }
                        for (let i = 0; i < req_devs.length; i++) {
                            await DevService.getDevice(req_devs[i].toString()).then(res => {
                                const device = res;
                                car.products = [...car.products, {
                                    title: `Equipo ${device.name}`,
                                    price: +device.price,
                                    cuantity: 1,
                                    price_original: +device.price,
                                    id: device.id
                                }];
                                car.total += +device.price;
                            });
                        }
                    }

                    dispatch(Actions.ADD_TO_CART(car));
                    dispatch(Actions.SET_PLAN_NAME({name, price}));
                    dispatch(Actions.SET_PRODUCT_RESUME_DATA(planData[0]));
                    setLoaded(true);
                } else {
                    history.push('/');
                }
                break;
            case 2:
                const planID = params.idp;
                let equipo = params.ide;
                let planDataE = planes.normal.filter(seccion => seccion.name === "Planes")[0].planes
                    .filter(subseccion => subseccion.name === "Telefonía Móvil");
                planDataE = planDataE[0].planes.filter(plan => plan.id === planID);

                if (planDataE.length === 0) {
                    history.push('/');
                    return;
                }

                DevService.getCel(equipo).then(res => {
                    equipo = res;
                    let {price, name, modality, id} = planDataE[0];
                    price = +price;
                    const total = parseInt(equipo.price) + price;
                    alert(total);
                    let car = {
                        total,
                        products: [
                            {
                                title: 'Renta mensual',
                                price,
                                cuantity: 1,
                                price_original: price,
                                id
                            },
                            {
                                title: 'Equipo ' + equipo.name,
                                price: equipo.price,
                                cuantity: 1,
                                price_original: equipo.price,
                                id: equipo.id
                            },
                        ],
                        months: 0,
                        saldo: 0
                    }
                    dispatch(Actions.SET_PLAN_NAME({name, price}));
                    dispatch(Actions.ADD_TO_CART(car));
                    setLoaded(true);
                }).catch(err => {
                    history.push('/');
                })
                break;
            case 3:
                const pack = params.idpack;

                packService.getPack(pack).then(res => {
                    let XelPack = res;
                    const cuantityPlan = XelPack.cuantity_plan ? XelPack.cuantity_plan : 1;
                    const cuantityDev = XelPack.cuantity_dev ? XelPack.cuantity_dev : 1;
                    let productId = XelPack.product;
                    let plan = planes.normal.filter(seccion => seccion.name === "Planes")[0].planes
                        .filter(subseccion => subseccion.name === "Telefonía Móvil");
                    plan = plan[0].planes.filter(plan => plan.id === productId);

                    if (plan.length === 0) {
                        history.push('/');
                        return;
                    }
                    plan = plan[0];
                    let equipo = XelPack.id_dev;
                    DevService.getCel(equipo).then(res => {
                        equipo = res;
                        const {price, id, name} = plan;
                        dispatch(Actions.SET_PLAN_NAME({name, price}));
                        const disc = XelPack.months_disc;
                        let car = {
                            total: (XelPack.resp_dev_price ? parseInt(XelPack.resp_dev_price) * cuantityDev : parseInt(equipo.price) * cuantityDev) + ((parseInt(price) * (XelPack.months - disc)) * cuantityPlan),
                            products: [
                                {
                                    title: 'Plan ' + name + ' (' + XelPack.months + ' Meses de servicio) ' + (cuantityPlan > 0 ? `x${cuantityPlan}` : ""),
                                    price: (parseInt(price) * XelPack.months) * cuantityPlan,
                                    price_original: parseInt(price) * cuantityPlan,
                                    cuantity: (XelPack.months - XelPack.months_disc) * cuantityPlan,
                                    id: plan.id
                                },
                                {
                                    title: (cuantityDev > 0 ? 'Equipos ' : "Equipo") + equipo.name + (cuantityDev > 0 ? ` x${cuantityDev}` : ""),
                                    price: XelPack.resp_dev_price ? parseInt(XelPack.resp_dev_price) * cuantityDev : parseInt(equipo.price) * cuantityDev,
                                    cuantity: cuantityDev,
                                    price_original: XelPack.resp_dev_price ? parseInt(XelPack.resp_dev_price) * cuantityDev : parseInt(equipo.price) * cuantityDev,
                                    id: equipo.id
                                },
                            ],
                            descuento: {
                                show: XelPack.months_disc > 0,
                                title: 'Descuento mes del amor',
                                value: '-' + (parseInt(plan.price) * disc)
                            },
                            months: 0,
                            saldo: parseInt(price) * XelPack.months
                        }
                        dispatch(Actions.ADD_TO_CART(car));
                        setLoaded(true);
                    }).catch(err => {
                        alert(err)
                        history.push('/');
                    })
                }).catch(err => {
                    alert(err);
                    history.push('/');
                });
                break;
            case 4://Mifi
            case 5://HBB

                let idmifi = params.idmifi;
                let toSearch = "WiFi Portátil";

                if (!idmifi) {
                    idmifi = params.idhbb;
                    toSearch = "Internet fijo";

                }


                const mifis = planes.normal.filter(seccion => seccion.name === "Planes")[0].planes
                    .filter(subseccion => subseccion.name === toSearch);

                let mifi = mifis[0].planes.filter(plan => plan.id === idmifi);

                if (mifi.length === 0) {
                    history.push('/');
                    return;
                }

                mifi = mifi[0];
                setSelectedPlanDetails(mifi);
                const priceMifi = parseInt(mifi.price);
                const name = mifi.name;
                dispatch(Actions.SET_PLAN_NAME({name, price: priceMifi}));
		dispatch(Actions.SET_PRODUCT_RESUME_DATA(mifi))

                DevService.getDevice(mifi.req_devs[0].toString()).then(res => {
                    const device = res;
                    let car = {
                        total: priceMifi + parseInt(device.price),
                        products: [
                            {
                                title: 'Renta mensual',
                                price: priceMifi,
                                price_original: priceMifi,
                                cuantity: 1,
                                id: idmifi
                            },
                            {
                                title: `Equipo ${device.name} (Modem)`,
                                price: parseInt(device.price),
                                cuantity: 1,
                                price_original: parseInt(device.price),
                                id: device.id
                            },
                        ],
                        months: 0,
                        saldo: 0
                    }

                    dispatch(Actions.ADD_TO_CART(car));
                    setLoaded(true);
                }).catch(err => {
                    alert(err)
                    history.push('/');
                })
                break;

            default:
                history.push('/');
                return;
        }

    }
    useEffect(() => {
        dispatch(Actions.SET_DATA({
            ...dataSend,
            imei: imei.imei
        }))
    }, [imei]);

    const handleFinish = () => {
        history.push('/');
    }
    const Resume = (<Grid container justify={'center'} style={{marginTop: 90}}>
        <Grid item xs={11} md={3} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            {/*  <XelIconDeliveryCar style={{stroke: 'none', fill: '#000000', height: 100}}/>*/}
            <img src={Logo} alt="inXel" style={{maxHeight: 120, height: '100%'}}/>
        </Grid>
        <Grid item xs={12} md={8}>
            <Grid container>
                <Grid item xs={11} md={10} style={{
                    // display: "flex",
                    // paddingLeft: useResponsiveValues(14, 15, 0),
                    //  justifyContent: useResponsiveValues('initial', 'initial', 'center')
                }}>
                    <Typography style={{
                        fontFamily: XEL_FONT.name,
                        fontWeight: XEL_FONT.bold,
                        fontSize: useResponsiveValues(38, 33, 27),
                        marginBottom: 25
                    }}
                                align={'center'}
                    >
                        ¡Compra Exitosa!
                    </Typography>
                </Grid>
                <Grid item xs={11} md={10}>
                    <Typography style={{
                        fontFamily: XEL_FONT.name,
                        fontWeight: XEL_FONT.bold,
                        fontSize: useResponsiveValues(22, 22, 17),
                        marginBottom: 25
                    }}
                                align={"center"}>
                        En breve recibirás un correo con más detalles y una llamada de tu asesor inXel.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={10}>
            <Grid container>
                <Grid item xs={12} style={{backgroundColor: XEL_COLORS.primary}}>
                    <Typography style={{
                        fontFamily: XEL_FONT.name,
                        fontWeight: XEL_FONT.bold_weight,
                        color: '#FEFEFE',
                        fontSize: useResponsiveValues(24, 20, 17)
                    }} align={'center'}>
                        Resumen de compra
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{border: `1px solid  ${XEL_COLORS.primary}`}}>
                    <Grid container style={{padding: 15}}>
                        <Grid item xs={7} md={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        fontFamily: XEL_FONT.name,
                                        fontWeight: XEL_FONT.regular_weight,
                                        wordBreak: "break-all",
                                        fontSize: useResponsiveValues(20, 18, 12)
                                    }}>
                                        Id de orden:
                                    </Typography>
                                    <Typography style={{
                                        fontFamily: XEL_FONT.name,
                                        fontWeight: XEL_FONT.regular_weight,
                                        wordBreak: "break-all",
                                        fontSize: useResponsiveValues(20, 18, 12)
                                    }}>
                                        {payed.data.order}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        fontFamily: XEL_FONT.name,
                                        fontWeight: XEL_FONT.regular_weight,

                                        fontSize: useResponsiveValues(20, 18, 12)
                                    }}>
                                        Metodo de pago: Tarjeta
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        fontFamily: XEL_FONT.name,
                                        fontWeight: XEL_FONT.regular_weight,

                                        fontSize: useResponsiveValues(20, 18, 12)
                                    }}>
                                        Monto: ${payed.data.amount}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5} md={6} style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderLeft: `1px solid ${XEL_COLORS.primary}`
                        }}>
                            <img src={SIM} alt="SIM" style={{height: 70}}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {
            infoPagoErrors.status_pay ? <>
                <Grid item xs={12}>
                    <Alert severity="error" onClose={() => {
                        setInfoPagoErrors({
                            ...infoPagoErrors,
                            status_pay: false,
                        })
                    }}>Error: {infoPagoErrors.msg_pay}!</Alert>
                </Grid>
            </> : ''
        }
        {
            /*   <Grid item xs={12} style={{marginTop: '2%'}}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={recurrent.want}
                        onChange={() => {
                            setRecurrent({
                                ...recurrent,
                                want: !recurrent.want
                            })
                        }}
                        name="checkedB"
                        color="primary"

                    />

                }
                style={{fontSize: titles}}
                label="Deseo que se me haga cargo recurrente mensual"/>
            {
                recurrent.want ?
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={recurrent.same}
                                onChange={() => {
                                    setRecurrent({
                                        ...recurrent,
                                        same: !recurrent.same
                                    })
                                }}
                                name="checkedB"
                                color="primary"

                            />

                        }
                        style={{fontSize: titles}}
                        label="Usar la misma tarjeta"


                    />
                    : ''
            }
        </Grid>*/
        }
        {
            recurrent.want && !recurrent.same ? <Grid item xs={12}>
                <Container component={'form'} id={'frm_getcard'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography style={{
                                fontFamily: XEL_FONT.name,
                                fontWeight: XEL_FONT.semi_bold_weight,
                                fontSize: 20, marginTop: 15, marginBottom: 20
                            }} align={'center'}>
                                Inserta una nueva tarjeta
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            <img src={OpenPayLogoColor} alt="OpenPay" style={{height: 60}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container style={{marginBottom: 20}}>
                                <Grid xs={12} md={12}>


                                    <FormControl fullWidth error={infoPagoErrors.err_name_card} disabled={finishing}>
                                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Nombre
                                            completo</InputLabel>
                                        <Input
                                            id="name_card" name={'name_card'}
                                            onChange={handleChangeRecurrent}
                                            value={infoPago.name_card}
                                            aria-describedby="component-helper-text"
                                            type='text'
                                            style={{fontSize: sizeTitle}}
                                        />
                                        {
                                            infoPagoErrors.err_name_card ? <FormHelperText
                                                id="component-helper-text">{infoPagoErrors.msg_name_card}</FormHelperText> : ''
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginBottom: 20}} justify={'center'}>
                                <Grid xs={12} md={8} style={{paddingBottom: '3%'}}>
                                    <FormControl fullWidth error={infoPagoErrors.err_card} disabled={finishing}>
                                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>Número de
                                            tarjeta</InputLabel>
                                        <Input
                                            id="card-number" name={'card'}
                                            onChange={handleChangeRecurrent}
                                            value={infoPago.card}
                                            aria-describedby="component-helper-text"
                                            type='text'
                                            style={{fontSize: sizeTitle}}
                                        />
                                        {
                                            infoPagoErrors.err_card ? <FormHelperText
                                                id="component-helper-text">{infoPagoErrors.msg_card}</FormHelperText> : ''
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid xs={6} md={4} style={{display: "flex", justifyContent: "center"}}>
                                    <img src={MasterCardLogo} alt="MasterCard" style={{height: 43, marginRight: 10}}/>
                                    <img src={VisaLogo} alt="Visa" style={{height: 43, marginLeft: 10}}/>
                                    <img src={Amex} alt="AMEX" style={{height: 43, marginLeft: 10}}/>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginBottom: 20}}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth disabled={finishing}>
                                        <InputLabel
                                            style={{fontSize: sizeTitle}}
                                            id="month">Mes</InputLabel>
                                        <Select
                                            labelId="month"
                                            id="month"
                                            name={'month'}
                                            value={infoPago.month}
                                            onChange={handleChangeRecurrent}
                                            style={{fontSize: sizeTitle}}
                                        >
                                            {
                                                Mont.map(month => <MenuItem value={month.id}
                                                                            key={month.id}
                                                                            style={{fontSize: sizeTitle}}
                                                >{month.id}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <FormControl fullWidth disabled={finishing}>
                                        <InputLabel id="year"
                                                    style={{fontSize: sizeTitle}}
                                        >Año</InputLabel>
                                        <Select
                                            labelId="year"
                                            id="year"
                                            name={'year'}
                                            value={infoPago.year}
                                            onChange={handleChangeRecurrent}
                                            style={{fontSize: sizeTitle}}
                                        >
                                            {
                                                Years().map(year => <MenuItem value={year} key={year}
                                                                              style={{fontSize: sizeTitle}}
                                                >{year}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <FormControl fullWidth error={infoPagoErrors.err_cvv} disabled={finishing}>
                                        <InputLabel htmlFor="name" style={{fontSize: sizeTitle}}>CVV</InputLabel>
                                        <Input
                                            id="cvv" name={'cvv'}
                                            onChange={handleChangeRecurrent}
                                            value={infoPago.cvv}
                                            aria-describedby="component-helper-text"
                                            type={'password'}
                                            style={{fontSize: sizeTitle}}
                                        />
                                        {
                                            infoPagoErrors.err_cvv ? <FormHelperText
                                                id="component-helper-text">{infoPagoErrors.msg_cvv}</FormHelperText> : ''
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>


                        </Grid>
                    </Grid>
                </Container>
            </Grid> : ''
        }
        <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end"}}>
            {
                finishing ?
                    <Button variant={'contained'} color={'secondary'} style={{color: '#FEFEFE'}}
                            startIcon={<CircularProgress color={'secondary'}/>} onClick={handleFinish}
                            disabled={finishing}>Procesando</Button> :
                    <Button variant={'contained'} color={'secondary'} style={{color: '#FEFEFE'}} onClick={handleFinish}
                            disabled={finishing}>Finalizar</Button>
            }
        </Grid>
    </Grid>)


    return <>
        <NavBar hide={hideNav}/>
        {
            !payed.completed ? <Titulo/> : ''
        }

        <Container>
            {
                !payed.completed && loaded?
                    <PasosCompra withDevice={deviceIncluded} planDetails={selectedPlanDetails}
                                 setPayed={setPayed}
                                 planOption={planOption}
                                 option={option}/> : Resume
            }
        </Container>
        <Footer/>
    </>

}
