import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid, Container, Box } from '@material-ui/core'
import { XEL_COLORS, XEL_FONT } from '../../../constant'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import Footer from '../../ui/Footer'
import { useHistory, useLocation } from 'react-router-dom'
import { useResponsiveValuesV2, useResponsiveValues } from '../../utils/hooks'

const useStyles = makeStyles(() => {
    return {
        linH: {
            lineHeight: 0,
        },
    }
})

const LandingBuenFin = () => {
    const lg =
        'https://api.inxel.mobi/website/assets/landings/landing_buen_fin_2022/movil/pc.png'
    const md =
        'https://api.inxel.mobi/website/assets/landings/landing_buen_fin_2022/movil/md.png'
    const sm =
        'https://api.inxel.mobi/website/assets/landings/landing_buen_fin_2022/movil/sm.png'
    const xs =
        'https://api.inxel.mobi/website/assets/landings/landing_buen_fin_2022/movil/xs.png'

    const url = useResponsiveValuesV2(lg, md, sm, xs)

    const history = useHistory()

    const location = useLocation()

    const goBuy = () => {
	history.push('/comprar/plan/39846' + (location.search || ""))
    }

    return (
        <Grid container>
            <Container>
                <img
                    onClick={goBuy}
                    style={{ width: '100%', height: 'auto' }}
                    src={url}
                    alt={'Inxel Fin Veloz'}
                />
                <Grid container justify={'center'}>
                    <Button
                        style={{
                            backgroundColor: XEL_COLORS.secondary,
                            color: 'white',
                            border: `1px solid #FEFEFE`,
                        }}
                        onClick={() => history.push('/')}
                    >
                        <Typography variant={'h3'}>
                            CONOCE M&Aacute;S PLANES
                        </Typography>
                    </Button>
                </Grid>
            </Container>
            <Footer />
        </Grid>
    )
}

export default LandingBuenFin
