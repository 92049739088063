import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import CelService from "../../utils/services/celulares";
import NavBar from "../../ui/NavBar";
import {Container, Grid} from "@material-ui/core";
import Footer from "../../ui/Footer";
import CellPhoneSection from "../Inicio/CellPhoneSection";

export default function Equipos() {
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Equipos";
    }, [])
    return <div style={{scrollBehavior: 'smooth',}}>
        <NavBar/>
        <Grid container style={{margin: '4% 0', backgroundColor: "#F6F6F6"}}>
            <Container>
                <Grid item xs={12}>
                    <CellPhoneSection All/>
                </Grid>
            </Container>
        </Grid>
        <Footer/>
    </div>;
}
