import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import Method from "../MethodContainer";
import {Button, Grid, Typography} from "@material-ui/core";
import {XelIconCard} from "../../../../../../../assets/logos/nav/svg";
import {XEL_COLORS, XEL_FONT} from "../../../../../../../constant";
import {XelIconFacebook} from "../../../../../../../assets/logos/svg";

const MethodItem = ({logo, name, active, onClick}) => {
    return <Grid
        container
        style={{
            ...{backgroundColor: "#EEEEEE",},
            ...(active ? {border: "1px solid #000000", boxShadow: "0px 1px 8px 0px rgba(0,0,0,0.5)"} : {}),
            width: 'fit-content',
            margin: "1%",
            padding: "1%",
            cursor: "pointer",
        }}
        onClick={() => {
            if (onClick)
                onClick()
        }}>
        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
            {
                logo
            }
        </Grid>
        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
            <Typography variant="caption">
                {name}
            </Typography>
        </Grid>
    </Grid>
}

const Banca = ({rollBack, setOption, speiParams, setSpeiParams, payParams, option, total}) => {

    const [dueDate, setDueDate] = useState('');
    const meses = ["Enes", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    useEffect(() => {
        if (speiParams.created) {
            let date = new Date(speiParams.dueDate);
            let dia = date.getDate();
            let mes = meses[date.getMonth()];
            let anio = date.getFullYear();
            setDueDate(`${dia} de ${mes} de ${anio}`);
        }
    }, [speiParams])

    return <Method title={"Transferencia bancaria"} rollBack={rollBack}>
        <Grid container spacing={2}>

            {
                false && <Grid item xs={12} style={{display: "flex"}}>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>
                            Selecciona el metodo de pago que deseas utilizar:
                        </Typography>
                    </Grid>
                    <MethodItem
                        active={option === 1}
                        onClick={() => {
                            setOption(1);
                        }}
                        logo={<XelIconCard height={30} width={30}/>}
                        name={"Transferencia"}/>
                    {
                        /*  <MethodItem
                        active={option === 2}
                        onClick={() => {
                            setOption(2);
                        }}
                        logo={<XelIconCard height={30} width={30}/>}
                        name={"Depósito"}/>*/
                    }
                    <Grid item xs={12}>
                        <hr/>
                    </Grid>
                </Grid>
            }

            {
                option === 1 && <Grid item xs={12}>
                    {
                        speiParams.created ?
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{backgroundColor: XEL_COLORS.primary, color: "#FEFEFE"}}>
                                    <Typography variant={"h6"} align={"center"}>
                                        Total a pagar:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{backgroundColor: "#F9F5FB"}}>
                                    <Typography variant={"h4"} align={"center"}>
                                        ${total}.00
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={"h6"}>
                                        Transferencia interbancaria SPEI
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        style={{
                                            borderLeft: `5px solid ${XEL_COLORS.primary}`,
                                            paddingLeft: "1%",
                                            fontWeight: XEL_FONT.bold_weight
                                        }}>
                                        Fecha limite de pago
                                    </Typography>
                                    <Typography
                                        style={{borderLeft: `5px solid #FEFEFE`, paddingLeft: "1%"}}>
                                        {dueDate}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        style={{
                                            borderLeft: `5px solid ${XEL_COLORS.primary}`,
                                            paddingLeft: "1%",
                                            fontWeight: XEL_FONT.bold_weight
                                        }}>
                                        Pasos para realizar tu pago:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} style={{padding: "1%"}}>
                                    <Grid container spacing={1} style={{
                                        backgroundColor: "#F9F5FB",
                                        padding: "1%",
                                        height: "100%"
                                    }}>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"} style={{fontWeight: "700"}}>
                                                Pagar desde BBVA Bancomer
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"}>
                                                1. Dentro del menú de "Pagar" seleccione la opción
                                                "De Servicios" e ingrese el siguiente "Número de
                                                convenio CIE"
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"} style={{fontWeight: "700"}}>
                                                Número de convenio CIE: {speiParams.bbva.cie}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{paddingTop: "2%"}}>
                                            <Typography variant={"body2"}>
                                                2. Ingrese los datos de registro para concluir con la
                                                operación.
                                            </Typography>
                                            <Typography variant={"body2"} style={{
                                                width: "100%",
                                                display: 'flex',
                                                justifyContent: "space-between",
                                                marginTop: "3%",
                                                marginBottom: "3%"
                                            }}>
                                                <span
                                                    style={{
                                                        fontWeight: "700",
                                                        marginRight: "2%"
                                                    }}>Referencia:</span> {speiParams.bbva.reference}
                                            </Typography>
                                            <Typography variant={"body2"} style={{
                                                width: "100%",
                                                display: 'flex',
                                                justifyContent: "space-between",
                                                marginTop: "3%",
                                            }}>
                                                <span
                                                    style={{
                                                        fontWeight: "700",
                                                        marginRight: "2%"
                                                    }}>Importe:</span> ${total}
                                            </Typography>
                                            <Typography variant={"body2"} style={{
                                                width: "100%",
                                                display: 'flex',
                                                justifyContent: "space-between",
                                                marginTop: "3%",
                                            }}>
                                                <span
                                                    style={{
                                                        fontWeight: "700",
                                                        marginRight: "2%"
                                                    }}>Concepto:</span> Varios pagos
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} style={{padding: "1%"}}>
                                    <Grid container spacing={1} style={{
                                        backgroundColor: "#F9F5FB",
                                        padding: "1%",
                                        height: "100%"
                                    }}>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"} style={{fontWeight: "700"}}>
                                                Desde cualquier otro banco
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant={"body2"}>
                                                1. Ingresa a la sección de transferencias y pagos o
                                                pagos a otros bancos y proporciona los datos de
                                                la transferencia:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{paddingTop: "2%"}}>

                                            <Typography variant={"body2"} style={{
                                                width: "100%",
                                                display: 'flex',
                                                justifyContent: "space-between",
                                                marginTop: "3%"
                                            }}>
                                                <span
                                                    style={{
                                                        fontWeight: "700",
                                                        marginRight: "2%"
                                                    }}>Beneficiario:</span> inXel
                                            </Typography>
                                            <Typography variant={"body2"} style={{
                                                width: "100%",
                                                display: 'flex',
                                                justifyContent: "space-between",
                                                marginTop: "3%"
                                            }}>
                                                <span
                                                    style={{
                                                        fontWeight: "700",
                                                        marginRight: "2%"
                                                    }}>Banco destino:</span> BBVA Bancomer
                                            </Typography>
                                            <Typography variant={"body2"} style={{
                                                width: "100%",
                                                display: 'flex',
                                                justifyContent: "space-between",
                                                marginTop: "3%"
                                            }}>
                                                <span
                                                    style={{
                                                        fontWeight: "700",
                                                        marginRight: "2%"
                                                    }}>Clabe:</span> {speiParams.other.clabe}

                                            </Typography>
                                            <Typography variant={"body2"} style={{
                                                width: "100%",
                                                display: 'flex',
                                                justifyContent: "space-between",
                                                marginTop: "3%"
                                            }}>
                                                <span
                                                    style={{
                                                        fontWeight: "700",
                                                        marginRight: "2%"
                                                    }}>Concepto de pago:</span> {speiParams.other.concept}

                                            </Typography>
                                            <Typography variant={"body2"} style={{
                                                width: "100%",
                                                display: 'flex',
                                                justifyContent: "space-between",
                                                marginTop: "3%"
                                            }}>
                                                <span
                                                    style={{
                                                        fontWeight: "700",
                                                        marginRight: "2%"
                                                    }}>Referencia:</span> {speiParams.other.reference}

                                            </Typography>
                                            <Typography variant={"body2"} style={{
                                                width: "100%",
                                                display: 'flex',
                                                justifyContent: "space-between",
                                                marginTop: "3%",
                                                marginBottom: "3%"
                                            }}>
                                                <span
                                                    style={{
                                                        fontWeight: "700",
                                                        marginRight: "2%"
                                                    }}>Importe:</span> ${total}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                    <Button style={{marginRight: 10}} onClick={() => {
                                        window.open(speiParams.url, '_blank', );
                                    }}
                                            variant={"contained"}
                                            color={"primary"}
                                    >
                                        <Typography variant={"caption"}> Descargar PDF</Typography>
                                        {
                                            //<XelIconFacebook height={30} width={30}/>
                                        }
                                    </Button>
                                    <Button onClick={() => {
                                        window.location.reload();
                                    }}
                                            variant={"contained"}
                                            color={"secondary"}
                                            style={{color: "#FEFEFE"}}
                                    >
                                        <Typography variant={"caption"}> Volver al inicio</Typography>

                                    </Button>

                                </Grid>
                            </Grid>
                            : <Grid container>
                                <Grid item xs={12}>
                                    <Typography>
                                        Para continuar, se deben generar los datos de pago. Por favor presiona el botón Generar información de pago
                                    </Typography>
                                </Grid>
                            </Grid>
                    }
                </Grid>
            }
        </Grid>
    </Method>
}


export default Banca;
