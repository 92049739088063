import React, {useEffect, useState} from "react";
import {CircularProgress, Grid, Typography} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import Axios from "../../../instances/axios";

const Redirect = () => {
    const axios = Axios();
    const params = useParams();
    const history = useHistory();
    useEffect(() => {
        if (!params.slash) {
            history.push("/");
        } else {
                document.getElementsByTagName("title")[0].text = "inXel - Redirección";
            axios(`/website/redirects/${params.slash}`).then(res => {
                let r = res.data;
                if (r.redirectTo) {
                    return window.location.href = r.redirectTo;
                }
                history.push("/");
            }).catch(err => {
                history.push("/");
            });
        }
    }, [])
    return <Grid container>
        <Grid item xs={12} style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column"
        }}>
            <Typography variant={"body2"} style={{padding: "1%"}}>
                Espere un momento mientras lo redireccionamos
            </Typography>
            <CircularProgress/>
        </Grid>
    </Grid>
}

export default Redirect;
