import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackIos, ArrowForwardIos} from "@material-ui/icons";
import Slider from "react-slick";
import {Hidden, Paper, withStyles} from "@material-ui/core";
import {XEL_COLORS, XEL_FONT} from "../../../constant";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {useResponsiveValues, useResponsiveValuesV2} from "../../utils/hooks";

const useStyles = makeStyles({
    orb: {
        backgroundColor: '#5A3671',
        width: 24,
        height: 24,
        borderRadius: '50%',
        cursor: "pointer",
        margin: 4
    },
    active: {
        backgroundColor: '#A27EB8',
    },
    text: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        color: '#FEFEFE'
    },
    high_text: {
        fontWeight: XEL_FONT.bold_weight,
    }
});

export default function SectionN({data, customindex, setIndex, arrows, indicators}) {
    const style = useStyles();
    const size = useResponsiveValuesV2(24, 24, 20, 8);
    const r = useResponsiveValuesV2(30, 25, 20, 20)
    const b = useResponsiveValuesV2(30, 25, 15, 5);
    return <Grid container>
        <Grid item xs={12} style={{position: "relative"}}>
            <SectionOne data={data} customindex={customindex} setIndex={setIndex} arrows={arrows}/>
            {
                indicators ? <div style={{
                    display: "flex",
                    position: "absolute",
                    right: r,
                    bottom: b
                }}>
                    {
                        data.map(item => <span style={{width: size, height: size}}
                                               onClick={() => {
                                                   setIndex(item.id)
                                               }
                                               }
                                               className={`${style.orb} ${customindex === item.id ? style.active : ''}`}/>)
                    }
                </div> : ""
            }
        </Grid>
    </Grid>
}

class SectionOne extends React.Component {
    state = {
        slideIndex: 0,
        updateCount: 0
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.slider.slickGoTo(this.props.customindex);
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            arrows: false,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed:6000,
            autoplay: true,
            afterChange: () =>
                this.setState(state => ({updateCount: state.updateCount + 1})),
            beforeChange: (current, next) => {
                this.setState({slideIndex: next});
                if (this.props.setIndex) {
                    this.props.setIndex(next);
                }
            }
        };
        return (
            <div style={{position: 'relative', overflow: 'hidden', width: '100%'}}>
                {
                    this.props.arrows ? <div style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: -10,
                        zIndex: 3,
                        overflow: 'show',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <IconButton style={{position: "absolute", stroke: '#FEFEFE', strokeWidth: 1}} onClick={() => {
                            this.slider.slickGoTo(this.state.slideIndex - 1);
                        }} color={'primary'}>
                            <ArrowBackIos style={{fontSize: 27}}/>
                        </IconButton>
                    </div> : ""
                }
                <Slider ref={slider => (this.slider = slider)} {...settings} >
                    {
                        this.props.data.map(item => <Item item={item}/>)
                    }
                </Slider>


                {
                    this.props.arrows ? <div style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 33.5,
                        zIndex: 3,
                        overflow: 'show',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <IconButton style={{position: "absolute", stroke: '#FEFEFE', strokeWidth: 1}} onClick={() => {
                            this.slider.slickGoTo(this.state.slideIndex + 1);
                        }} color={'primary'}>
                            <ArrowForwardIos style={{fontSize: 27}}/>
                        </IconButton>
                    </div> : ""
                }


            </div>
        );
    }
}

const ItemDescription = withStyles({
    root: {
        backgroundColor: XEL_COLORS.primary,
        minHeight: 300,
        borderRadius: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 15,
        transition: 'all .3s ease-in-out',
        '&:hover': {
            borderRadius: 10
        }
    },
})(Paper);

const Description = withStyles({
    root: {
        color: '#fefefe',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 40
    }
})(Typography);

const GotoButton = withStyles({
    root: {
        color: '#fefefe',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 25,
        marginTop: '3vw',
        borderRadius: 0,
        marginLeft: 25
    }
})(Button);

const Item = ({item}) => {
    return (
        <Paper style={{
            boxShadow: 'none',
            borderRadius: 'none',
            overflow: 'hidden',
            position: 'relative',
            padding: '0',
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
        }}>

            <Grid container>
                {
                    item.description ? <Grid item xs={7} style={{paddingLeft: 16}}>
                        {
                            item.description
                        }
                    </Grid> : ''
                }
                <Grid item xs={item.description ? 5 : 12}>
                    <img src={item.file.file} alt={"Carrucel"} style={{height: '100%', width: '100%'}}/>
                </Grid>
            </Grid>
        </Paper>
    )
}
