import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import Carousel from '../Carousel'
import { XEL_COLORS } from '../../../../constant'
import PlanCard from '../PlanCard'
import homeBg1 from '../../../../assets/images/homebg1.png'
import homeBg2 from '../../../../assets/images/homebg2.png'

import mifiImg from '../../../../assets/images/MiFi.png'
import hbbImg from '../../../../assets/images/Hbb.png'
import { useResponsiveValues } from '../../../utils/hooks'

const { primary, secondary } = XEL_COLORS

export default ({ mobile = false }) => {
    const xelFi = useSelector(s => s.planes.normal[0].planes[2].planes)
    const xelHome = useSelector(s => s.planes.normal[0].planes[0].planes)

    const plans = { xelFi, xelHome }

    const [selectedIndex, setSelectedIndex] = useState(null)

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    color: primary,
                    marginBottom: 20,
                }}>
                <div
                    style={{
                        flex: 1,
                        maxWidth: 1240,
                        fontSize: 20,
                        padding: '0 20px 0 20px',
                    }}>
                    <h1
                        style={{
                            fontSize: 45,
                            fontWeight: 700,
                            lineHeight: 1,
                            marginBottom: 0,
                        }}>
                        Planes de internet {mobile ? 'móvil' : 'fijo'}
                    </h1>
                    <span style={{ fontWeight: 600 }}>
                        {mobile
                            ? 'Lleva tu internet a donde sea!'
                            : 'El internet ideal para tu hogar!'}
                    </span>
                    <span
                        style={{
                            color: secondary,
                            fontWeight: 800,
                            marginLeft: 20,
                        }}>
                        Conecta hasta 15 dispositivos
                    </span>
                </div>
            </div>
            <div
                style={{
                    backgroundImage: `url(${homeBg1})`,
                    backgroundSize: '',
                    backgroundPosition: 'center',
                }}>
                <Carousel>
                    {plans[mobile ? 'xelFi' : 'xelHome'].map((plan, i) => (
                        <PlanCard
                            onMouseEnter={() => setSelectedIndex(i)}
                            selected={selectedIndex === i}
                            key={i}
                            modem
                            {...{ ...plan, mobile }}
                        />
                    ))}
                </Carousel>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: primary,
                    margin: '30px 0px 30px 0px',
                }}>
                <span
                    style={{
                        fontSize: 15,
                        maxWidth: 1000,
                        textAlign: 'center',
                        padding: '0 20px 0 20px',
                    }}>
                    {!mobile && (
                        <>
                            Política de uso justo. De tus Gigas totales 60GB son
                            para navegar con velocidad limitada. Todos los
                            precios publicados incluyen I.V.A
                            <br />
                        </>
                    )}
                    Los planes {mobile ? 'Xel-Fi' : 'Xel Home'} no incluyen
                    Router (Módem). el costo será incluido al solicitar la
                    compra y es un pago único. El servicio será garantizado por
                    parte de InXel siempre y cuando usted adquiera el Router
                    sugerido
                </span>
            </div>

            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    color: primary,
                    marginBottom: 20,
                }}>
                <div
                    style={{
                        flex: 1,
                        maxWidth: 1240,
                        fontSize: 20,
                        padding: '0 20px 0 20px',
                    }}>
                    <h1
                        style={{
                            fontSize: 45,
                            fontWeight: 700,
                            lineHeight: 1,
                            marginBottom: 0,
                        }}>
                        Router {mobile ? 'Portátil' : 'Fijo'}
                    </h1>
                    <span style={{ fontWeight: 600 }}>
                        {mobile
                            ? `Dispositivo portátil con el cual
			    podras disfrutar de tu internet inXel
			    donde sea y cuando sea`
                            : 'El internet ideal para tu hogar!'}
                    </span>
                </div>
            </div>
            <div
                style={{
                    backgroundImage: `url(${homeBg2})`,
                    backgroundSize: 'cover',
                    color: primary,
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <div
                    style={{
                        maxWidth: 1240,
                        flex: 1,
                        display: 'flex',
                        flexDirection: useResponsiveValues(
                            'row',
                            'column',
                            'column',
                        ),
                    }}>
                    <div
                        style={{
                            flex: 1,
                            // padding: 50,
                            // paddingTop: useResponsiveValues(50, 0, -20),
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <>
                            <img
                                style={{ margin: 60, flex: 1 }}
                                src={mobile ? mifiImg : hbbImg}
                            />
                            <div style={{ padding: '0 20px 0 20px' }}>
                                El modelo del producto puede variar según la
                                disponibilidad del mismo
                            </div>
                        </>
                    </div>
                    <div style={{ flex: 1, padding: 50 }}>
                        <h1
                            style={{
                                fontSize: 35,
                                textAlign: 'center',
                                padding: '0 20px 0 20px',
                                borderBottom: `2px solid ${primary}`,
                            }}>
                            {mobile
                                ? 'Mifi ZLT M-30 Blanco'
                                : 'Shenzhen Americas a-11'}
                        </h1>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                            <ul
                                style={{
                                    paddingLeft: 0,
                                    listStyle: 'none',
                                    fontSize: 25,
                                }}>
                                {mobile ? (
                                    <>
                                        <li>Dispositivo: ZLT M30 (Portátil)</li>
                                        <li>Fabricante: SATCo</li>
                                        <li>Batería: 6 horas, 2000mAs</li>
                                        <li>Recargar: Con cable USB</li>
                                        <li>
                                            Conectividad disponible: LTE / 4G
                                        </li>
                                        <li>Red WiFi: Hasta 15 dispositivos</li>
                                    </>
                                ) : (
                                    <>
                                        <li>Modelo: A-11 (Fijo)</li>
                                        <li>Marca: Shenzhen Americas</li>
                                        <li>Tamaño(L x W x H): 130*55*126mm</li>
                                        <li>Tipo: LTE</li>
                                        <li>Red WiFi: Hasta 20 dispositivos</li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
