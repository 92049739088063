import { createSvgIcon } from "@material-ui/core";

export const X = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M16.6067 8.52336C16.6067 12.8611 13.0584 16.392 8.67307 16.392C4.29955 16.392 0.739441 12.8727 0.739441 8.52336C0.739441 4.18567 4.28776 0.654724 8.67307 0.654724C13.0584 0.654724 16.6067 4.17398 16.6067 8.52336Z"
        fill="#111114"
      />
      <path
        d="M9.82836 7.79846L13.0348 4.39612H11.8206L9.29788 7.06187L7.36457 4.39612H4.02844L7.39994 9.07287L4.02844 12.6506H5.24265L7.93042 9.80946L9.98161 12.6506H13.3177L9.82836 7.79846ZM5.74955 5.27301H6.91661L11.6084 11.7737H10.4414L5.74955 5.27301Z"
        fill="white"
      />
    </svg>,
    'X'
  );
  