import React, {useState} from 'react';
import {Grid, Container, Typography, TextField, Button} from "@material-ui/core";
import NavBar from "../../ui/NavBar";
import Footer from "../../ui/Footer";
import {Cuestion} from "../Preguntas";
import {XEL_COLORS, XEL_FONT} from "../../../constant";
import {Alert, AlertTitle} from "@material-ui/lab";
import Axios from "../../../instances/axios";

const Proveedores = () => {

    const defaultInfo = {
        nombre: "",
        apellidos: "",
        email: "",
        telefono: "",
        producto: "",
    }
    const axios = Axios();
    const [info, setInfo] = useState(defaultInfo);
    const [result, setResult] = useState({
        open: false,
        severity: "",
        message: ""
    });
    const {nombre, apellidos, email, telefono, producto} = info;
    const [loading, setLoading] = useState(false);
    const handleChange = e => {
        setResult({
            open: false,
            severity: "",
            message: ""
        });
        if (e.target.name === "telefono") {
            if (isNaN(e.target.value))
                return;
            if (e.target.value.length > 10)
                return;

            let exists = e.target.value.toString().split("").includes(".");
            if (exists)
                return;
        }
        setInfo({
            ...info,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        setLoading(true);
        setResult({
            open: false,
            severity: "",
            message: ""
        });
        axios.post("/website/proveedores", info).then(res => {
            setLoading(false);
            let data = res.data;
            if (data.error) {
                setResult({
                    open: true,
                    severity: "error",
                    message: data.error_description
                });
            } else {
                setResult({
                    open: true,
                    severity: "success",
                    message: "Se ha enviado la información correctamente"
                });
                setInfo(defaultInfo);
            }
        }).catch(err => {
            setResult({
                open: true,
                severity: "error",
                message: "Ocurrio un error al intentar enviar tu información, por favor intenta más tarde"
            });
            setLoading(false);
        })
    }
    return <Grid container>
        <NavBar/>
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant={"h3"} color={"primary"} align={"center"}>
                        Unete al equipo inXel
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Cuestion variant={"h5"} title={"Política de compras"}>
                        <Grid container spacing={2} style={{backgroundColor: "rgba(93,56,116,0.07)"}}>
                            <Grid item xs={12}>
                                {
                                    ["Objetivo: El objetivo de la Política de Compras de inXel es entregar las bases bajo\n" +
                                    "                                    las cuales se debe ejecutar una acción de compra (de productos y servicios) a modo\n" +
                                    "                                    de establecer un estilo de gestión que garantice objetividad, transparencia y\n" +
                                    "                                    excelencia en el proceso de adquisición para lograr una óptima gestión en términos\n" +
                                    "                                    de eficiencia.",
                                        "              1.Conductas de compra. Se espera que todos los colaboradores guíen sus conductas y\n" +
                                        "                                    decisiones de compra en las prácticas y en los valores de INXEL.",
                                        "2.Prácticas de compras Será responsabilidad de todos los colaboradores involucrados directa o indirectamente en las actividades de compras y, particularmente de los supervisores directos (responsable del proceso de compras), resguardar en todo momento el buen nombre de INXEL, estableciendo y preservando relaciones adecuadas entre la compañía y sus proveedores corporativos y garantizando el uso de prácticas de compras inobjetables.",
                                        "Las siguientes prácticas deben ser consideradas en toda acción de compra:",
                                        "a) Considerar primero el interés de INXEL en todas las decisiones de compra, por sobre el interés particular de las áreas.",
                                        "b) El responsable del proceso de compras debe colocar órdenes de compra y preparar contratos con proveedores sin incurrir en favoritismos de ninguna índole, privilegiando únicamente el interés global de la compañía.",
                                        "c) Toda compra o acuerdo con proveedores debe estar previamente autorizada por la instancia con las facultades o atribuciones de compra necesarias. No se debe comprometer recursos de la compañía ni celebrar contratos o generar órdenes de compras sin atribuciones para ello. La práctica de comprar primero y pedir autorización después no es aceptable en INXEL.",
                                        "d) Tampoco se podrán comprometer o adelantar dineros, sin las correspondientes garantías de fiel cumplimiento cuando corresponda.",
                                        "e) Promover una sana competencia entre los proveedores para obtener el máximo valor añadido para el negocio, sin perder de vista la misión de INXEL en cuanto a desarrollar relaciones con proveedores, de mutuo beneficio, de largo plazo, con altos estándares de calidad, cumplimiento y de transparencia.",
                                        "f) Promover y fortalecer continuamente la transparencia, eficiencia y agilidad del proceso de compra.",
                                        "g) No actuar con indiferencia o pasividad frente a prácticas de compra observadas que sean impropias o reñidas con la conducta de INXEL o con la política de compras."
                                    ].map((text, index) => (
                                        <Typography color={"primary"} key={index} variant={"body1"} align={"justify"}
                                                    style={{marginTop: "2%"}}>
                                            {text}
                                        </Typography>
                                    ))
                                }

                            </Grid>
                        </Grid>
                    </Cuestion>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant={"body1"}
                        align={"center"}
                        color={"primary"}
                        style={{fontWeight: XEL_FONT.bold_weight}}>
                        Si tienes algún producto o servicio para inXel, registra tus datos y nosotros nos comunicaremos
                        contigo para conocer más detalles.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify={"center"}>
                        <Grid item xs={10} md={7}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Nombre"}
                                        value={nombre}
                                        name={"nombre"}
                                        disabled={loading}
                                        onChange={handleChange}
                                        fullWidth/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Apellidos"}
                                        value={apellidos}
                                        name={"apellidos"}
                                        disabled={loading}
                                        onChange={handleChange}
                                        fullWidth/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Producto o Servicio"}
                                        value={producto}
                                        name={"producto"}
                                        disabled={loading}
                                        onChange={handleChange}
                                        fullWidth/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Correo electrónico"}
                                        value={email}
                                        name={"email"}
                                        disabled={loading}
                                        onChange={handleChange}
                                        fullWidth/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={"Número de contacto"}
                                        value={telefono}
                                        name={"telefono"}
                                        disabled={loading}
                                        onChange={handleChange}
                                        fullWidth/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={"caption"}>
                                        *Todos los campos son obligatorios
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        result.open && <Alert severity={result.severity}>
                                            <AlertTitle>
                                                {
                                                    result.severity === "error" &&
                                                    "Error"
                                                }
                                                {
                                                    result.severity === "success" &&
                                                    "Éxito"
                                                }
                                            </AlertTitle>
                                            {result.message}
                                        </Alert>
                                    }
                                </Grid>
                                <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end"}}>
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={loading} style={{color: "#FEFEFE"}} variant={"contained"}
                                        color={"secondary"}>
                                        Enviar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
        <Footer/>
    </Grid>
}
export default Proveedores;
