import React, {useEffect, useState} from "react";
import {Container, Grid, Hidden, PropTypes, Typography, useMediaQuery, withStyles} from "@material-ui/core";
import {useParams, useLocation, useHistory} from 'react-router-dom';
import NavBar from "../../ui/NavBar";
import Footer from "../../ui/Footer";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import {PromosPlan, XEL_COLORS, XEL_FONT} from "../../../constant";
import {useResponsiveValues} from "../../utils/hooks";
import SmartPhone from './assets/smartphone.svg';
import Button from "@material-ui/core/Button";
import CelService from "../../utils/services/celulares";
import PacksService from "../../utils/services/packs";
import NumberFormat from "react-number-format";
import {useSelector} from "react-redux";
import DeviceService from "../../utils/services/dispositivos";

const useStyles = makeStyles({
    img: {
        width: '100%',
        maxWidth: 200,
        height: '70%'
    },
    name: {
        fontWeight: XEL_FONT.bold_weight
    },
    subtitle: {
        fontWeight: XEL_FONT.semi_bold_weight,
        color: '#FEFEFE',
    },
    price_container: {
        borderRadius: 12,
        overflow: "hidden",

    },
    price_head: {
        backgroundColor: '#F3F2F5',
        padding: '.7% 0'
    }
});

const BuyButton = withStyles({
    root: {
        borderRadius: 14,

        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,

        border: `1px solid ${XEL_COLORS.primary}`,
        '&:hover': {
            backgroundColor: XEL_COLORS.secondary,
            color: '#FEFEFE',
            border: `1px solid #FEFEFE`,
        },

    }
})(Button);

const Loading = () => {
    return <Grid container style={{minHeight: '70vh'}} justify={'center'} alignItems={'center'}>
        <Grid item xs={12} style={{display: "flex", justifyContent: "center", height: '100%', alignItems: "center"}}>
            <CircularProgress color={'primary'}/>
        </Grid>
    </Grid>
}


const Error = ({msg}) => {
    return <Grid container style={{minHeight: '70vh'}} justify={'center'} alignItems={'center'}>
        <Grid item xs={12} style={{
            display: "flex",
            justifyContent: "center",
            height: '100%',
            alignItems: "center",
            flexDirection: "column"
        }}>
            <Typography>
                {msg}
            </Typography>
            <Typography>
                <a href="/">Volver a inicio</a>
            </Typography>
        </Grid>
    </Grid>
}


const Detalle = ({data, isPack}) => {
    const styles = useStyles();
    const superTitleSize = useResponsiveValues(30, 25, 20);
    const nameSize = useResponsiveValues(25, 20, 15);
    const subSize = useResponsiveValues(20, 15, 12);
    const normalSize = useResponsiveValues(15, 13, 12);
    const promos = PromosPlan();
    const [dataCel, setDataCel] = useState(null);
    const CellService = DeviceService();
    const promosPlan = PromosPlan();
    const {
        id,
        name,
        id_dev,
        product,
        months
    } = data;
    const cuantityPlan = data.cuantity_plan ? data.cuantity_plan : 1;
    const cuantityDev = data.cuantity_dev ? data.cuantity_dev : 1;
    let planes = useSelector(state => state.planes);
    planes = planes.normal.filter(seccion => seccion.name === "Planes")[0].planes.filter(subseccion => subseccion.name.toLowerCase() === "telefonía móvil")[0].planes;
    let plan = planes.filter(plan => plan.id === product);
    plan = plan[0];
    let TotalPlan = (parseInt(plan.price) * months) * cuantityPlan;
    let TotalPlanWithDiscont = TotalPlan - (parseInt(plan.price) * data.months_disc);
    const [devPrice, setDevPrice] = useState(0)
    const history = useHistory();

    useEffect(() => {
        console.log(id_dev)
        CellService.getCel(id_dev).then(res => {
            console.log(res);
            setDataCel(res);
            if(data.resp_dev_price){
                setDevPrice(parseInt(data.resp_dev_price) * cuantityDev);
            }else{
                setDevPrice(parseInt(res.price) * cuantityDev);
            }
        });
    }, []);


    const handleClick = () => {
        history.push('/comprar/pack/' + id);
    }
    return <Grid container>
        <Grid item xs={12} style={{padding: '3% 0'}}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} style={{display: "flex", justifyContent: "center", alignItems: 'center'}}>
                    <img src={dataCel ? dataCel.image : ''} alt={name} className={styles.img}/>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className={styles.name} color={'primary'} style={{fontSize: nameSize}}>
                                {name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}
                              style={{backgroundColor: XEL_COLORS.primary, padding: '2%', marginBottom: '3%'}}>
                            <Grid container>
                                <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                                    <img src={SmartPhone} alt="icono"
                                         style={{maxWidth: 30, width: '100%', height: '100%'}}/>
                                    <Typography className={styles.subtitle} style={{paddingLeft: 5, fontSize: subSize}}>
                                        Xel Pack
                                    </Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container style={{padding: '2%'}}>
                                        <Grid item xs={12} className={styles.price_container}>
                                            <Grid container>
                                                <Grid item xs={12} className={styles.price_head}>
                                                    <Typography color={'primary'} align={'center'}>
                                                        Precio de Contado
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}
                                                      style={{backgroundColor: '#FEFEFE', padding: '.5% 0'}}>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item xs={6}
                                                              style={{
                                                                  display: "flex",
                                                                  justifyContent: "space-around",
                                                                  alignItems: "center",
                                                                  // paddingRight: '5%'
                                                              }}>
                                                            <Typography align={'center'}
                                                                        style={{
                                                                            fontSize: superTitleSize,
                                                                            fontWeight: XEL_FONT.bold_weight
                                                                        }}>
                                                                {
                                                                    dataCel ? <NumberFormat thousandSeparator={true}
                                                                                            prefix={'$'}
                                                                                            displayType={'text'}
                                                                                            value={TotalPlanWithDiscont + devPrice}/>
                                                                        : ''
                                                                }*
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}
                                                              style={{display: "flex", justifyContent: "center"}}>
                                                            <BuyButton onClick={handleClick}
                                                                       style={{fontSize: subSize}}>¡Lo
                                                                quiero!</BuyButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography color={'primary'} style={{
                                        fontSize: subSize,
                                        fontWeight: XEL_FONT.bold_weight
                                    }}>
                                        Detalles técnicos
                                    </Typography>
                                </Grid>
                                {
                                    //Detalles
                                }
                                <Grid item xs={12}>
                                    <Grid container>

                                        {
                                            dataCel ? dataCel.details.map(detail => <Grid item xs={6}
                                                                                          style={{marginTop: '2%'}}>
                                                <Typography
                                                    style={{
                                                        fontWeight: XEL_FONT.semi_bold_weight,
                                                        fontSize: normalSize
                                                    }}>
                                                    {detail.title}
                                                </Typography>
                                                <Typography style={{fontSize: normalSize}}>
                                                    {detail.value}
                                                </Typography>
                                            </Grid>) : ''
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: 10, marginTop: '3%'}}>
                                *Precio no incluye costo de envío, este se definirá dependiendo zona de contratación.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}


export default function DetallePack() {
    const params = useParams();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({
        isError: false,
        msg: ''
    });
    const [data, setData] = useState({});
    const history = useHistory();
    //const celularService = CelService();
    const packService = PacksService();
    useEffect(() => {

            document.getElementsByTagName("title")[0].text = "inXel - Detalle XelPack";

        const id = params.idpack;
        if (id) {
            packService.getPack(id).then(res => {
                setData(res);
                setLoaded(true);
            }).catch(err => setError({
                isError: true,
                msg: err
            }));

        }
    }, []);

    return <div style={{scrollBehavior: 'smooth',}}>
        <NavBar/>
        <Grid container style={{margin: '4% 0', backgroundColor: "#F6F6F6"}}>
            <Container>
                <Grid item xs={12}>
                    {
                        error.isError ? <Error msg={error.msg}/> : loaded ?
                            <Detalle data={data}/> : <Loading/>
                    }
                </Grid>
            </Container>
        </Grid>
        <Footer/>
    </div>;
}
