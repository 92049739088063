import {useHistory, useParams, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import CelService from "../../utils/services/celulares";
import NavBar from "../../ui/NavBar";
import {Container, Grid} from "@material-ui/core";
import Footer from "../../ui/Footer";
import CellPhoneSection from "../Inicio/CellPhoneSection";
import Logo from "../../../assets/logos/inxel/ICONO M.png";
import Typography from "@material-ui/core/Typography";
import {XEL_COLORS, XEL_FONT} from "../../../constant";
import {useResponsiveValues} from "../../utils/hooks";
import SIM from "../../../assets/images/tarjeta-sim/tarjeta-simHd.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import Axios from "../../../instances/axios";

const Loading = () => {
    return <Grid container style={{minHeight: '70vh'}} justify={'center'} alignItems={'center'}>
        <Grid item xs={12} style={{display: "flex", justifyContent: "center", height: '100%', alignItems: "center"}}>
            <CircularProgress color={'primary'}/>
        </Grid>
    </Grid>
}
const Error = ({msg}) => {
    return <Grid container style={{minHeight: '70vh'}} justify={'center'} alignItems={'center'}>
        <Grid item xs={12} style={{
            display: "flex",
            justifyContent: "center",
            height: '100%',
            alignItems: "center",
            flexDirection: "column"
        }}>
            <Typography>
                {msg}
            </Typography>
            <Typography>
                <a href="/">Volver a inicio</a>
            </Typography>
        </Grid>
    </Grid>
}

export default function Compras() {
    const axios = Axios();
    const {search} = useLocation();
    const [data, setData] = useState({});
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({
        show: false,
        msg: ''
    })
    useEffect(() => {

            document.getElementsByTagName("title")[0].text = "inXel - Compra";

        const id = new URLSearchParams(search).get('id');
        if (id) {
            axios.get(`/website/pays/transaction/${id}`).then(res => {
                let response = res.data;
                setData(response);
                setLoaded(true);
            }).catch(err => {
                console.log("error", err)
                history.push('/');
            });
            /*fetch('https://inxel.mx/api/xel/v1/ecommerce.get.transaction.json?id=' + id, {
                method: 'GET'
            }).then(res => res.json()).then(res => {
                setLoaded(true);
                setData(res.result);
            }).catch(err => {
                history.push('/');
            })*/
        } else {
            history.push('/');
        }
    }, []);
    const Resume = (<Grid container justify={'center'} style={{marginTop: 90}}>
        <Grid item xs={11} md={3} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            {/*  <XelIconDeliveryCar style={{stroke: 'none', fill: '#000000', height: 100}}/>*/}
            <img src={Logo} alt="inXel" style={{maxHeight: 120, height: '100%'}}/>
        </Grid>
        <Grid item xs={12} md={8}>
            <Grid container>
                <Grid item xs={11} md={10} style={{
                    // display: "flex",
                    // paddingLeft: useResponsiveValues(14, 15, 0),
                    //  justifyContent: useResponsiveValues('initial', 'initial', 'center')
                }}>
                    <Typography style={{
                        fontFamily: XEL_FONT.name,
                        fontWeight: XEL_FONT.bold,
                        fontSize: useResponsiveValues(38, 33, 27),
                        marginBottom: 25
                    }}
                                align={'center'}
                    >
                        {
                            data.status === 'in_progress' && "¡Tu pago se esta procesando!"
                        }
                        {
                            data.status === 'completed' && "¡Compra exitosa!"
                        }
                        {
                            data.status === 'failed' && "¡Tu pago no se proceso correctamente!"
                        }

                    </Typography>
                </Grid>
                <Grid item xs={11} md={10}>
                    <Typography style={{
                        fontFamily: XEL_FONT.name,
                        fontWeight: XEL_FONT.bold,
                        fontSize: useResponsiveValues(22, 22, 17),
                        marginBottom: 25
                    }}
                                align={"center"}>
                        {
                            data.status === 'in_progress' && "En cuanto tu pago se complete, recibirás un correo con más detalles y una llamada de tu asesor inXel."
                        }
                        {
                            data.status === 'completed' && " En breve recibirás un correo con más detalles y una llamada de tu asesor inXel."
                        }
                        {
                            data.status === 'failed' && "¡Tu pago no se proceso correctamente!"
                        }

                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={10}>
            <Grid container>
                <Grid item xs={12} style={{backgroundColor: XEL_COLORS.primary}}>
                    <Typography style={{
                        fontFamily: XEL_FONT.name,
                        fontWeight: XEL_FONT.bold_weight,
                        color: '#FEFEFE',
                        fontSize: useResponsiveValues(24, 20, 17)
                    }} align={'center'}>
                        Resumen de compra
                    </Typography>
                </Grid>


                <Grid item xs={12} style={{border: `1px solid  ${XEL_COLORS.primary}`}}>
                    <Grid container style={{padding: 15}}>
                        <Grid item xs={7} md={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        fontFamily: XEL_FONT.name,
                                        fontWeight: XEL_FONT.regular_weight,
                                        wordBreak: "break-all",
                                        fontSize: useResponsiveValues(20, 18, 12)
                                    }}>
                                        Status:
                                    </Typography>
                                    <Typography style={{
                                        fontFamily: XEL_FONT.name,
                                        fontWeight: XEL_FONT.regular_weight,
                                        wordBreak: "break-all",
                                        fontSize: useResponsiveValues(20, 18, 12)
                                    }}>

                                        {
                                            data.status === 'in_progress' && "En progreso"
                                        }
                                        {
                                            data.status === 'completed' && "Exitoso"
                                        }
                                        {
                                            data.status === 'failed' && "Pago fallido"
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        fontFamily: XEL_FONT.name,
                                        fontWeight: XEL_FONT.regular_weight,
                                        wordBreak: "break-all",
                                        fontSize: useResponsiveValues(20, 18, 12)
                                    }}>
                                        Id de orden:
                                    </Typography>
                                    <Typography style={{
                                        fontFamily: XEL_FONT.name,
                                        fontWeight: XEL_FONT.regular_weight,
                                        wordBreak: "break-all",
                                        fontSize: useResponsiveValues(20, 18, 12)
                                    }}>
                                        {data.order}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        fontFamily: XEL_FONT.name,
                                        fontWeight: XEL_FONT.regular_weight,

                                        fontSize: useResponsiveValues(20, 18, 12)
                                    }}>
                                        Metodo de pago: Tarjeta
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        fontFamily: XEL_FONT.name,
                                        fontWeight: XEL_FONT.regular_weight,

                                        fontSize: useResponsiveValues(20, 18, 12)
                                    }}>
                                        Monto: ${data.amount}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5} md={6} style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderLeft: `1px solid ${XEL_COLORS.primary}`
                        }}>
                            <img src={SIM} alt="SIM" style={{height: 70}}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)

    return <div style={{scrollBehavior: 'smooth',}}>
        <NavBar/>
        <Grid container style={{margin: '4% 0', padding: '3% 0', backgroundColor: "#F6F6F6"}}>
            <Container>
                <Grid item xs={12}>
                    {
                        loaded ? Resume : <Loading/>
                    }
                </Grid>
            </Container>
        </Grid>
        <Footer/>
    </div>;
}
