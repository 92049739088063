import { createSvgIcon } from "@material-ui/core";

export const Whatsapp = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M8.63616 16.4154C13.0308 16.4154 16.5934 12.882 16.5934 8.52337C16.5934 4.16473 13.0308 0.631348 8.63616 0.631348C4.24152 0.631348 0.678955 4.16473 0.678955 8.52337C0.678955 12.882 4.24152 16.4154 8.63616 16.4154Z"
        fill="url(#paint0_linear_505_5296)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4674 4.81705C11.5126 3.87001 10.2512 3.35556 8.89553 3.34387C6.11346 3.34387 3.85007 5.58871 3.85007 8.348C3.85007 9.22489 4.08584 10.0901 4.52201 10.8501L3.80292 13.4457L6.4789 12.7441C7.22157 13.1417 8.04676 13.3521 8.89553 13.3521C11.6776 13.3521 13.941 11.1073 13.941 8.348C13.941 7.02682 13.4223 5.76409 12.4674 4.81705ZM8.89553 12.522C8.14107 12.522 7.3984 12.3232 6.76182 11.9374L6.60857 11.8439L5.01713 12.2531L5.44151 10.7215L5.34721 10.5695C4.92282 9.90302 4.71063 9.14305 4.71063 8.35969C4.71063 6.06808 6.59678 4.19738 8.90732 4.19738C10.0272 4.19738 11.0764 4.62998 11.878 5.41334C12.6678 6.19669 13.104 7.24896 13.104 8.35969C13.0922 10.6513 11.2061 12.522 8.89553 12.522ZM11.1943 9.41196C11.0646 9.3535 10.4516 9.04951 10.3337 9.00274C10.2158 8.95598 10.1333 8.94428 10.0508 9.0612C9.96828 9.18981 9.72072 9.47042 9.64999 9.55226C9.57926 9.63411 9.50853 9.6458 9.37886 9.58734C9.24919 9.52888 8.84838 9.38858 8.36505 8.96767C7.98782 8.6403 7.74026 8.23108 7.65774 8.10247C7.58701 7.97386 7.64596 7.9154 7.71669 7.84525C7.77563 7.78679 7.84636 7.70494 7.9053 7.6231C7.96424 7.55295 7.98782 7.49449 8.03497 7.41265C8.08213 7.3308 8.05855 7.26065 8.02318 7.1905C7.98782 7.13204 7.74026 6.51237 7.63417 6.26684C7.52807 6.02131 7.42197 6.05639 7.35124 6.05639C7.28051 6.05639 7.19799 6.05639 7.10368 6.05639C7.00938 6.05639 6.8797 6.09146 6.76182 6.20838C6.64394 6.33699 6.32565 6.64098 6.32565 7.24896C6.32565 7.86863 6.77361 8.46492 6.84434 8.54676C6.90328 8.6286 7.72847 9.89133 9.00163 10.4408C9.30813 10.5695 9.53211 10.6513 9.72072 10.7098C10.0272 10.8033 10.2984 10.7916 10.5105 10.7565C10.7581 10.7215 11.2532 10.4525 11.3593 10.1602C11.4654 9.86794 11.4654 9.62241 11.43 9.56395C11.4065 9.5055 11.324 9.47042 11.1943 9.41196Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_505_5296"
          x1="8.63764"
          y1="15.267"
          x2="8.63764"
          y2="-0.517017"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#55B046" />
          <stop offset="1" stopColor="#83BF67" />
        </linearGradient>
      </defs>
    </svg>,
    'Whatsapp'
  );
  