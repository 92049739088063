import {act} from "react-dom/test-utils";
import {combineReducers, createStore} from "redux";

const bannersReducer = (state = {
    home: [],
    alliance: [],
    enterprise: []
}, action) => {
    switch (action.type) {
        case "SETHOMEBANNER":
            return (state = {
                ...state,
                home: action.payload
            });
        case "SETALLIANCEBANNER":
            return (state = {
                ...state,
                alliance: action.payload
            });

        case "SETENTERPRISEBANNER":
            return (state = {
                ...state,
                enterprise: action.payload
            });
        default:
            return state;
    }
}
const planReducer = (state = [], action) => {
    switch (action.type) {
        case "SETPLANS":
            return (state = action.payload);
        default:
            return state;
    }
};
const loggedReducer = (state = {logged: false, user: {}}, action) => {
    switch (action.type) {
        case "SIGN_IN":
            return (state = {
                logged: true,
                ...action.payload
            });
        case "LOGOUT":
            return (state = {logged: false, contact: null, openpay: null, token: null, email: null});
        default:
            return state;
    }
};

const navBarHeightReducer = (state = 0, action) => {
    switch (action.type) {
        case 'SET_HEIGHT_NAVBAR':
            return (state = action.payload);
        default:
            return state;
    }
}


const openPayReducer = (state = {
    initialited: false,
    obj: null
}, action) => {
    switch (action.type) {
        case 'INITIALIZE_OPENPAY':
            return (state = {initialited: true, obj: action.payload})
        case 'CLOSE_OPENPAY':
            return (state = {initialited: false, obj: null})
        default:
            return state;
    }
}

const buyLoadingEventReducer = (state = false, action) => {
    switch (action.type) {
        case 'BUY_PROCESS_LOADING':
            return (state = true)
        case 'BUY_PROCESS_END':
            return (state = false)
        default:
            return state;
    }
}

const enterCodeReducer = (state = null, action) => {
    switch (action.type) {
        case 'ENTER_CODE':
            return (action.payload);
        default:
            return state;
    }
}

const topLoadingReducer = (state = false, action) => {
    switch (action.type) {
        case 'LOADING_STATE':
            return (action.payload);
        case 'LOADING_FINSHED':
            return (state = false);
        default:
            return state;
    }
}

const planSelectedReducer = (state = false, action) => {
    switch (action.type) {
        case 'PLAN_SELECTED':
            return (state = true);
        case 'PLAN_UNSELECTED':
            return (state = false);
        default:
            return state;
    }
}

const planName = (state = '', action) => {
    switch (action.type) {
        case 'SET_PLAN_NAME':
            return (state = action.payload);
        default:
            return state;
    }
}

const productResumeData = ( state = null, action ) => {
    if (typeof action.payload === 'function')
	return action.payload(state)
    switch (action.type) {
        case 'SET_PRODUCT_RESUME_DATA':
            return (state = action.payload);
        default:
            return state;
    }
}

const xelpackReducer = (state = [], action) => {
    switch (action.type) {
        case 'SETPACKS':
            return (state = action.payload);
        default:
            return state;
    }
}
const devicesReducer = (state = [], action) => {
    switch (action.type) {
        case 'SETDEVICES':
            return (state = action.payload);
        default:
            return state;
    }
}


const dataSendReducer = (state = {
    imei: '',
    personal_nombre: '',
    personal_apellidos: '',
    personal_tel: '',
    identificacion_tipo_id: '',
    identificacion_image_one: '',
    identificacion_image_one_src: '',
    identificacion_image_two: '',
    identificacion_image_two_src: '',
    dir_postal_code: '',
    dir_colonia: '',
    dir_municipio: '',
    dir_ciudad: '',
    dir_estado: '',
    dir_calle: '',
    dir_numex: '',
    dir_numin: '',
    dir_referencias: '',
    dir_postal_code_e: '',
    dir_colonia_e: '',
    dir_municipio_e: '',
    dir_ciudad_e: '',
    dir_estado_e: '',
    dir_calle_e: '',
    dir_numex_e: '',
    dir_numin_e: '',
    dir_referencias_e: '',
    personal_portabilidad: '',
    personal_nip: '',
    personal_compania: '',
    personal_tel_porta: '',
    step: '',
}, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return (state = action.payload);
        case 'UNSET_DATA':
            return (state = {
                imei: '',
                personal_nombre: '',
                personal_apellidos: '',
                personal_tel: '',
                identificacion_tipo_id: '',
                identificacion_image_one: '',
                identificacion_image_one_src: '',
                identificacion_image_two: '',
                identificacion_image_two_src: '',
                dir_postal_code: '',
                dir_colonia: '',
                dir_municipio: '',
                dir_ciudad: '',
                dir_estado: '',
                dir_calle: '',
                dir_numex: '',
                dir_numin: '',
                dir_referencias: '',
                dir_postal_code_e: '',
                dir_colonia_e: '',
                dir_municipio_e: '',
                dir_ciudad_e: '',
                dir_estado_e: '',
                dir_calle_e: '',
                dir_numex_e: '',
                dir_numin_e: '',
                dir_referencias_e: '',
                personal_portabilidad: '',
                personal_nip: '',
                personal_compania: '',
                personal_tel_porta: '',
                step: '',
            });
        default:
            return state;
    }
}

const carritoCompra = (state = null, action) => {
    switch (action.type) {
        case 'ADD_TO_CART':
            return (state = action.payload);
        case 'CLEAN_CAR':
            return (state = null);
	case "ARBITRARY":
	    if (typeof action.payload === 'function')
		return (state = action.payload(state))
        default:
            return state;
    }
}
const planPriceReducer = (state = '50', action) => {
    switch (action.type) {
        case 'WITH_SEND':
            return (state = '149');
        case 'WITHOUT_SEND':
            return (state = '99');
        default:
            return state;
    }
}
const bannerReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SETBANNERS':
            return (state = {...state, ...action.payload});
        default:
            return state;
    }
}

const rootReducer = combineReducers({
    planprice: planPriceReducer,
    datasend: dataSendReducer,
    login: loggedReducer,
    navbarheight: navBarHeightReducer,
    openpay: openPayReducer,
    buyloading: buyLoadingEventReducer,
    secret: enterCodeReducer,
    loading: topLoadingReducer,
    plan: planSelectedReducer,
    planname: planName,
    productResumeData,
    carrito: carritoCompra,
    planes: planReducer,
    banners: bannersReducer,
    xelpacks: xelpackReducer,
    devices: devicesReducer,
    banner: bannerReducer
});
/* eslint-disable no-underscore-dangle */
const myStore = createStore(
    rootReducer /* preloadedState, */
  //  , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
/* eslint-enable */
export default myStore;

