import React, {useEffect, useState} from "react";
import {
    Button,
    Card, CardActions,
    CardContent, CardHeader,
    FormHelperText,
    Grid,
    Typography
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MasterCardLogo from "../../../../../../../../assets/logos/OpenPay/masterCard.png";
import VisaLogo from "../../../../../../../../assets/logos/OpenPay/visa.png";
import Amex from "../../../../../../../../assets/logos/OpenPay/americanExpress.png";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Tarjeta from "../../../../../assets/Tarjeta.svg";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import {useSelector} from "react-redux";
import OpenPayLogo from "../../../../../../../../assets/logos/OpenPay/openpay_color.png";
import {XEL_COLORS} from "../../../../../../../../constant";


const NewCard = ({card, updateCard, setFunctions, status, windowsCheck, setWindowsCheck}) => {
    const [infoPagoErrors, setInfoPagoErrors] = useState({});
    const [toolOpen, setToolOpen] = useState(false);
    const session = useSelector(state => state.login);
    const soloLetras = /^[\u00F1A-Za-z _]*[\u00F1A-Za-z][\u00F1A-Za-z _]*$/;
    const {
        clientName,
        clientLastName,
        cardNumber,
        cardName,
        cardExpirationMonth,
        cardExpirationYear,
        cardCvv,
        clientEmail,
    } = card;

    const Mont = [{
        id: '01',
        name: 'Enero'
    },
        {
            id: '02',
            name: 'Febrero'
        },
        {
            id: '03',
            name: 'Marzo'
        },
        {
            id: '04',
            name: 'Abril'
        },
        {
            id: '05',
            name: 'Mayo'
        },
        {
            id: '06',
            name: 'Junio'
        },
        {
            id: '07',
            name: 'Julio'
        },
        {
            id: '08',
            name: 'Agosto'
        },
        {
            id: '09',
            name: 'Septiembre'
        },
        {
            id: '10',
            name: 'Octubre'
        },
        {
            id: '11',
            name: 'Noviembre'
        },
        {
            id: '12',
            name: 'Diciembre'
        },
    ]
    // eslint-disable-next-line no-undef
    const Openpay = OpenPay;
    useEffect(() => {

        /*let device_session_id = Openpay.deviceData.setup("frm_getcard");
        updateCard(
            "device_session_id",
            device_session_id
        );*/

        setFunctions(prevState => ({
            ...prevState,
            validateCard: validate
        }));
    }, []);


    const validate = (card) => {
        const {
            cardNumber,
            cardName,
            cardExpirationMonth,
            cardExpirationYear,
            cardCvv
        } = card;

        let validCard = Openpay.card.validateCardNumber(cardNumber);
        let validName = cardName.length > 0;
        let validExpire = Openpay.card.validateExpiry(cardExpirationMonth, cardExpirationYear);
        let validCvv = validCard ? Openpay.card.validateCVC(cardCvv, cardNumber) : (cardCvv.length < 5 && cardCvv.length > 2);
        let errors = {};
        let cont = 0;
        if (!validCard) {
            errors.cardNumber = "Número de tarjeta inválido";
            cont++;
        }
        if (!validName) {
            errors.cardName = "Nombre inválido";
            cont++;
        }
        if (!validExpire) {
            errors.cardExpire = "Fecha de expiración inválida ";
            cont++;
        }
        if (!validCvv) {
            errors.cardCvv = "Código de seguridad inválido";
            cont++;
        }


        if (cont > 0) {
            setInfoPagoErrors(errors);
            return false;
        }
        return true;
    }

    function quitarAcentos(cadena) {
        const acentos = {
            'á': 'a',
            'é': 'e',
            'í': 'i',
            'ó': 'o',
            'ú': 'u',
            'Á': 'A',
            'É': 'E',
            'Í': 'I',
            'Ó': 'O',
            'Ú': 'U'
        };
        return cadena.split('').map(letra => acentos[letra] || letra).join('').toString();
    }

    const handleChange = e => {
        let name = e.target.name;
        let value = e.target.value;
        setInfoPagoErrors({});
        switch (name) {
            case 'cardNumber':
                if (isNaN(value))
                    return;
                if (value.length > 16)
                    return;
                updateCard(name, value);
                break;
            case 'clientName':
            case 'clientLastName':
            case 'cardName':

                value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                if (soloLetras.test(value) || value.length === 0)
                    updateCard(name, value);
                break;
            case 'cardExpirationMonth':
                updateCard(name, value);
                break;
            case 'cardExpirationYear':
                updateCard(name, value);
                break;
            case 'cardCvv':
                if (isNaN(value))
                    return;
                if (value.length > 4)
                    return;
                updateCard(name, value);
                break;
            default:
                updateCard(name, value);
                break;
        }
    }
    const Years = () => {
        const date = new Date();
        let year = date.getFullYear();
        let years = [];
        for (let i = 0; i < 13; i++) {

            years.push({
                value: year.toString().charAt(2) + year.toString().charAt(3),
                name: year
            });
            year = year + 1;
        }
        return years;
    }

    return <Grid container>
        {
            windowsCheck.checked && (windowsCheck.isWindows || windowsCheck.accept) ?
                <Grid item xs={12} component={'form'} id={'frm_getcard'}>
                    {
                        !session.logged && <Grid item xs={12} style={{marginBottom: 10}}>
                            <Card>
                                <CardHeader style={{backgroundColor: XEL_COLORS.primary}}
                                            title={<Typography style={{color: "#FEFEFE"}} variant={"caption"}>
                                                Ingresa tus datos
                                            </Typography>}/>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                style={{marginTop: "1%", marginBottom: "1%"}}
                                                disabled={status.loading}
                                                id="clientname" name={'clientName'}
                                                label="Nombre"
                                                value={clientName}
                                                onChange={handleChange} error={infoPagoErrors.clientName}
                                                helperText={infoPagoErrors.clientName} fullWidth/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                style={{marginTop: "1%", marginBottom: "1%"}}
                                                disabled={status.loading}
                                                id="clientlastname" name={'clientLastName'}
                                                label="Apellidos"
                                                value={clientLastName}
                                                onChange={handleChange} error={infoPagoErrors.clientLastName}
                                                helperText={infoPagoErrors.clientLastName} fullWidth/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{marginTop: "1%", marginBottom: "2%"}}
                                                disabled={status.loading}
                                                id="email" name={'clientEmail'}
                                                label="Correo electrónico"
                                                value={clientEmail}
                                                onChange={handleChange} error={infoPagoErrors.clientEmail}
                                                helperText={infoPagoErrors.clientEmail} fullWidth/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    <CardContainer>
                        <Grid item xs={12} md={12}>
                            <TextField
                                disabled={status.loading}
                                id="name" name={'cardName'}
                                label="Nombre del Titular (Tal y como aparece en la tarjeta)"
                                value={cardName}
                                onChange={handleChange} error={infoPagoErrors.cardName}
                                helperText={infoPagoErrors.cardName} fullWidth/>
                        </Grid>
                        <Grid container style={{marginBottom: 20, marginTop: 20}} justify={'center'}>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    disabled={status.loading}
                                    id="card-number" label="Número de tarjeta" name={'cardNumber'}
                                    onChange={handleChange}
                                    error={infoPagoErrors.cardNumber} helperText={infoPagoErrors.cardNumber}
                                    value={cardNumber} fullWidth/>
                            </Grid>
                            <Grid item xs={6} md={4}
                                  style={{display: "flex", justifyContent: "center", marginTop: '1%'}}>
                                <img src={MasterCardLogo} alt="MasterCard" style={{height: 43, marginRight: 10}}/>
                                <img src={VisaLogo} alt="Visa" style={{height: 43, marginLeft: 10}}/>
                                <img src={Amex} alt="AMEX" style={{height: 43, marginLeft: 10}}/>
                            </Grid>
                        </Grid>
                        <Grid container style={{marginBottom: 20}}>
                            <Grid item xs={12} md={6}>
                                <FormControl
                                    disabled={status.loading}
                                    fullWidth>
                                    <InputLabel id="month">Mes de vencimiento</InputLabel>
                                    <Select
                                        labelId="month"
                                        id="month"
                                        name={'cardExpirationMonth'}
                                        value={cardExpirationMonth}
                                        onChange={handleChange}
                                    >
                                        {
                                            Mont.map((month, index) => <MenuItem value={month.id}
                                                                                 key={month.id}>{month.name} - {(index + 1) < 10 ? `0${index + 1}` : index + 1}</MenuItem>)
                                        }
                                    </Select>
                                    <FormHelperText error={infoPagoErrors.cardExpire}>
                                        {infoPagoErrors.cardExpire}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl
                                    disabled={status.loading}
                                    fullWidth>
				    <InputLabel id="year">Año de vencimiento</InputLabel>
                                    <Select
                                        labelId="year"
                                        id="year"
                                        name={'cardExpirationYear'}
                                        value={cardExpirationYear}
                                        onChange={handleChange}
                                    >
                                        {
                                            Years().map(year => <MenuItem value={year.value}
                                                                          key={year.value}>{year.name}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    disabled={status.loading}
                                    id="cvv" label="CVV" name={'cardCvv'} value={cardCvv} type={'password'}
                                    error={infoPagoErrors.cardCvv} helperText={infoPagoErrors.cardCvv}
                                    onChange={handleChange} fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip onClose={() => {
                                                    setToolOpen(false);
                                                }} open={toolOpen} TransitionComponent={Zoom}
                                                         title={<div style={{
                                                             display: "flex",
                                                             justifyContent: "center",
                                                             alignItems: "center"
                                                         }}>
                                                             <img
                                                                 src={Tarjeta}
                                                                 style={{height: '10vh'}} alt="CVV"/>
                                                         </div>}
                                                         disableFocusListener
                                                         disableHoverListener
                                                         disableTouchListener
                                                >
                                                    <IconButton
                                                        onClick={() => {
                                                            setToolOpen(!toolOpen);
                                                        }}
                                                        aria-label="toggle password visibility"
                                                        //  onClick={handleClickShowPassword}
                                                        //    onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        <HelpIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            {
                                /* session.logged && <Grid item xs={12} style={{marginTop: "3%"}}>
                                     <FormGroup>
                                         <FormControlLabel
                                             control={<Checkbox disabled={status.loading} //checked={card.check_accept}
                                                                onClick={() => {
                                                                    // updateCard("saveCard", !card.saveCard);
                                                                    updateCard("check_accept", !card.check_accept);
                                                                }} />}
                                             label="Acepto que mi tarjeta sera guardada para futuros pagos"/>
                                     </FormGroup>
                                 </Grid>*/
                            }
                        </Grid>
                    </CardContainer>
                </Grid> : <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography align={"justify"}>
                                <strong>AVISO IMPORTANTE: </strong>
                                Para continuar con tu proceso de pago, verifica que tienes desactivada la función
                                “Retransmisión
                                Privada” de icloud. Para mayor información dar click <a
                                href="https://support.apple.com/es-mx/HT212614" rel="noopener noreferrer"
                                target={"_blank"}>Aquí</a>.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            <Button variant={"contained"} color={"primary"}
                                    onClick={() => {
                                        setWindowsCheck(prevState => ({...prevState, accept: true}))
                                    }
                                    }>
                                Cerrar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
        }
    </Grid>;
}


const CardContainer = ({children}) => {

    const session = useSelector(state => state.login);

    if (!session.logged) {
        return <Card>
            <CardHeader style={{backgroundColor: XEL_COLORS.primary}}
                        title={<Typography style={{color: "#FEFEFE"}} variant={"caption"}>
                            Ingresa los datos de la tarjeta con la que realizas el pago
                        </Typography>}/>
            <CardContent>
                {children}
            </CardContent>
            <CardActions>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            inXel no realiza los cobros directamente; al presionar el botón Pagar OpenPay procesará la
                            operación con los datos ingresados.
                            Consulta nuestro <a
                            href="https://documents.openpay.mx/docs/aviso-de-privacidad.html" target={"_blank"}
                            rel={'noreferrer noopener'}>Aviso de
                            privacidad</a>, <a href="https://documents.openpay.mx/docs/terminos-servicio.html"
                                               target={"_blank"}
                                               rel={'noreferrer noopener'}>Términos y condiciones</a>.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                        <img src={OpenPayLogo} alt="OpenPay" style={{maxWidth: 160, maxHeight: 50,}}/>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>;
    }
    return <Grid container spacing={2}>
        {children}
        <Grid item xs={12}>
            <Typography>
                inXel no realiza los cobros directamente; al aceptar y guardar, OpenPay recordará los datos de tu
                tarjeta para facilitar tus próximos pagos. Consulta nuestro <a
                href="https://api.inxel.mx/website/assets/legales/avisodeprivacidadterminosycondicionesdeusodelaplataformaopenpay.pdf"
                target={"_blank"} rel={'noreferrer noopener'}>Aviso de
                privacidad</a>, <a
                href="https://api.inxel.mx/website/assets/legales/avisodeprivacidadterminosycondicionesdeusodelaplataformaopenpay.pdf"
                target={"_blank"}
                rel={'noreferrer noopener'}>Términos y condiciones</a>.
            </Typography>
        </Grid>
        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
            <img src={OpenPayLogo} alt="OpenPay" style={{maxWidth: 160, maxHeight: 50,}}/>
        </Grid>
    </Grid>

}


export default NewCard;
