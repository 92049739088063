import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Check from '@material-ui/icons/Check'
import { green } from '@material-ui/core/colors'
import StepConnector from '@material-ui/core/StepConnector'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PersonIcon from '@material-ui/icons/Person'
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation'
import PaymentIcon from '@material-ui/icons/Payment'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import { useResponsiveValues } from '../../../utils/hooks'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import StepContent from '@material-ui/core/StepContent'
import { FormGroup, LinearProgress, Zoom } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import Radio from '@material-ui/core/Radio'
import Divider from '@material-ui/core/Divider'
import InputLabel from '@material-ui/core/InputLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import DoneIcon from '@material-ui/icons/Done'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../../../actions'
import HomeIcon from '@material-ui/icons/Home'
import Input from '@material-ui/core/Input'
import { XEL_COLORS, XEL_FONT } from '../../../../constant'
import OpenPayLogoColor from '../../../../assets/logos/OpenPay/openpay_color.png'
import VisaLogo from '../../../../assets/logos/OpenPay/visa.png'
import MasterCardLogo from '../../../../assets/logos/OpenPay/masterCard.png'
import FormHelperText from '@material-ui/core/FormHelperText'
import Amex from '../../../../assets/logos/OpenPay/americanExpress.png'
import { PayGateContext } from "./context"
import {
    useHistory,
    useLocation,
    useParams
} from 'react-router-dom'

import PlanName from './PlanName'
import Detalles from './Detalles'
import NumberFormat from 'react-number-format'
import Axios from '../../../../instances/axios'
import CoberturaHBB from '../../CoberturaHBB'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'

import CheckImei from '../checkImei'
import ProductResume from './ProductResume'
import { useContext } from 'react'
import ax from 'axios';

const { primary2, primary_shiny, secondary, secondary_dark } = XEL_COLORS

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
})

const portInputStyle = {
    fontSize: 20,
    flex: 1,
    border: 'none',
    borderBottom: '1px solid #333',
    outline: 'none',
    margin: 5,
    padding: 10
}

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress
                    color={'primary'}
                    variant="determinate"
                    {...props}
                />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography
                    variant="body2"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    )
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
}
const useStylesProgress = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: '#F28A30',
        position: 'absolute',
        top: -15.5,
        left: -15.6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))
const globalStyles = makeStyles({
    skeleton_dad: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
    },
    skeleton_container: {
        width: '300px',
    },
    text_capitalize: {
        textTransform: 'capitalize',
    },
    d_flex: {
        display: 'flex',
    },
    justify_center: {
        justifyContent: 'center',
    },
    justify_start: {
        justifyContent: 'flex-start',
    },
    justify_end: {
        justifyContent: 'flex-end',
    },
    form: {
        marginTop: 10,
    },
    input: {
        marginTop: 5,
        padding: 5,
        '& input': {
            width: '100%',
        },
    },
    options_container: {
        marginBottom: 15,
    },
    snack_info: {
        backgroundColor: '#FEFEFE',
        marginBottom: 15,
        display: 'flex',
        justifyContent: 'center',
    },
    porta_container: {
        marginTop: 15,
    },
    label_match: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 16,
        color: XEL_COLORS.primary,
    },
    label_nomatch: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 14,
        color: XEL_COLORS.primary,
    },
    label_active: {
        fontWeight: XEL_FONT.semi_bold_weight,
    },
})

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles()
    const { active, completed } = props

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                <div className={classes.circle} />
            )}
        </div>
    )
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
}

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient( #ff7600 0%,#d35400 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage: 'linear-gradient( #ff7600 0%,#d35400 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: '#593571',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: '#593571',
    },
})

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props
    const progrestyle = useStylesProgress()
    const loading = useSelector((state) => state.buyloading)

    const icons = {
        1: <PermDeviceInformationIcon />,
        2: <PersonIcon />,
        3: <HomeIcon />,
        4: <PaymentIcon />,
    }

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {active ? (
                loading ? (
                    <div className={progrestyle.root}>
                        <div className={progrestyle.wrapper}>
                            {icons[String(props.icon)]}
                            <CircularProgress
                                size={57}
                                className={progrestyle.fabProgress}
                            />
                        </div>
                    </div>
                ) : (
                    <>{icons[String(props.icon)]}</>
                )
            ) : completed ? (
                <DoneIcon />
            ) : (
                <>{icons[String(props.icon)]}</>
            )}
        </div>
    )
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
    /**
     * Verifica si esta cargando
     */
    loading: PropTypes.bool,
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#fefefe',
        paddingBottom: '1%',
    },
    button: {
        marginRight: theme.spacing(1),
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
}))

function getSteps() {
    return ['Compatibilidad', 'Identificación', 'Dirección', 'Pago']
}

const Payment = ({
    infoPago,
    setInfoPago,
    infoPagoErrors,
    setInfoPagoErrors,
    Accept,
    option,
}) => {
    const loading = useSelector((state) => state.buyloading)
    const sizeTitle = useResponsiveValues(20, 15, 10)

    // const onlyLettersReg = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
    const onlyNumber = /^[0-9]+$/
    // const Months = [, , , , , , , , 'Semptiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    const totales = useSelector((state) => state.carrito)

    const { showPortInInput, freeShipping } = useContext(PayGateContext)

    const [totalShow, setTotalShow] = useState(totales.total_w_send)
    const appState = useSelector(s => s)
    useEffect(() => {
        setTotalShow(totales.total_w_send)
    }, [showPortInInput, freeShipping, appState])

    const priceSize = useResponsiveValues(20, 15, 12)

    const Mont = [
        {
            id: '01',
            name: 'Enero',
        },
        {
            id: '02',
            name: 'Febrero',
        },
        {
            id: '03',
            name: 'Marzo',
        },
        {
            id: '04',
            name: 'Abril',
        },
        {
            id: '05',
            name: 'Mayo',
        },
        {
            id: '06',
            name: 'Junio',
        },
        {
            id: '07',
            name: 'Julio',
        },
        {
            id: '08',
            name: 'Agosto',
        },
        {
            id: '09',
            name: 'Septiembre',
        },
        {
            id: '10',
            name: 'Octubre',
        },
        {
            id: '11',
            name: 'Noviembre',
        },
        {
            id: '12',
            name: 'Diciembre',
        },
    ]
    const Years = () => {
        const date = new Date()
        let year = date.getFullYear()
        let years = []
        for (let i = 0; i < 13; i++) years.push(year++)
        return years
    }

    const handleChange = (e) => {
        let namerr_check_reset = `err_${[e.target.name]}`
        let namerr_msg_reset = `msg_${[e.target.name]}`
        setInfoPagoErrors({
            ...infoPagoErrors,
            [namerr_check_reset]: false,
            [namerr_msg_reset]: '',
        })

        switch (e.target.name) {
            case 'name_card':
                if (e.target.value.trim().split('').length === 0) {
                    setInfoPago({
                        ...infoPago,
                        name_card: '',
                    })
                }
                if (e.target.value.trim().split('').length > 0) {
                    setInfoPago({
                        ...infoPago,
                        name_card: e.target.value,
                    })
                }
                break
            case 'card':
                if (e.target.value.split('').length === 0)
                    setInfoPago({
                        ...infoPago,
                        card: '',
                    })
                else if (e.target.value.split('').length < 17)
                    if (onlyNumber.test(e.target.value)) {
                        setInfoPago({
                            ...infoPago,
                            card: e.target.value,
                        })
                    }
                break
            case 'month':
                setInfoPago({
                    ...infoPago,
                    month: e.target.value,
                })
                break
            case 'year':
                setInfoPago({
                    ...infoPago,
                    year: e.target.value,
                })

                break
            case 'cvv':
                if (e.target.value.split('').length === 0)
                    setInfoPago({
                        ...infoPago,
                        cvv: '',
                    })
                else if (e.target.value.split('').length < 5)
                    if (onlyNumber.test(e.target.value)) {
                        setInfoPago({
                            ...infoPago,
                            cvv: e.target.value,
                        })
                    }
                break
        }
    }

    return (
        <>
            {Accept ? (
                <Container component={'form'} id={'frm_getcard'}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={OpenPayLogoColor}
                                alt="OpenPay"
                                style={{ maxHeight: 40, height: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '3%' }}>
                            <Typography
                                align={'center'}
                                color={'primary'}
                                style={{ fontSize: priceSize }}
                            >
                                <strong>Total a pagar:</strong>{' '}
                                <NumberFormat
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    displayType={'text'}
                                    value={totalShow}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container style={{ marginBottom: 20 }}>
                                <Grid xs={12} md={12}>
                                    <FormControl
                                        fullWidth
                                        error={infoPagoErrors.err_name_card}
                                        disabled={loading}
                                    >
                                        <InputLabel
                                            htmlFor="name"
                                            style={{ fontSize: sizeTitle }}
                                        >
                                            Nombre completo
                                        </InputLabel>
                                        <Input
                                            id="name_card"
                                            name={'name_card'}
                                            onChange={handleChange}
                                            value={infoPago.name_card}
                                            aria-describedby="component-helper-text"
                                            type="text"
                                            style={{ fontSize: sizeTitle }}
                                        />
                                        {infoPagoErrors.err_name_card ? (
                                            <FormHelperText id="component-helper-text">
                                                {infoPagoErrors.msg_name_card}
                                            </FormHelperText>
                                        ) : (
                                            ''
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                style={{ marginBottom: 20 }}
                                justify={'center'}
                                spacing={0}
                            >
                                <Grid
                                    xs={12}
                                    md={8}
                                    style={{ paddingBottom: '2%' }}
                                >
                                    <FormControl
                                        fullWidth
                                        error={infoPagoErrors.err_card}
                                        disabled={loading}
                                    >
                                        <InputLabel
                                            htmlFor="name"
                                            style={{ fontSize: sizeTitle }}
                                        >
                                            Número de tarjeta
                                        </InputLabel>
                                        <Input
                                            id="card-number"
                                            name={'card'}
                                            onChange={handleChange}
                                            value={infoPago.card}
                                            aria-describedby="component-helper-text"
                                            type="text"
                                            style={{ fontSize: sizeTitle }}
                                        />
                                        {infoPagoErrors.err_card ? (
                                            <FormHelperText id="component-helper-text">
                                                {infoPagoErrors.msg_card}
                                            </FormHelperText>
                                        ) : (
                                            ''
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid
                                    xs={6}
                                    md={4}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img
                                        src={MasterCardLogo}
                                        alt="MasterCard"
                                        style={{
                                            maxHeight: 43,
                                            marginRight: '1%',
                                            height: '100%',
                                        }}
                                    />
                                    <img
                                        src={VisaLogo}
                                        alt="Visa"
                                        style={{
                                            maxHeight: 43,
                                            marginLeft: '1%',
                                            height: '100%',
                                        }}
                                    />
                                    <img
                                        src={Amex}
                                        alt="AMEX"
                                        style={{
                                            maxHeight: 43,
                                            marginLeft: '1%',
                                            height: '100%',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginBottom: 20 }}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="month"
                                            style={{ fontSize: sizeTitle }}
                                        >
                                            Mes
                                        </InputLabel>
                                        <Select
                                            labelId="month"
                                            id="month"
                                            name={'month'}
                                            value={infoPago.month}
                                            onChange={handleChange}
                                            style={{ fontSize: sizeTitle }}
                                        >
                                            {Mont.map((month) => (
                                                <MenuItem
                                                    value={month.id}
                                                    key={month.id}
                                                    style={{
                                                        fontSize: sizeTitle,
                                                    }}
                                                >
                                                    {month.id}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="year"
                                            style={{ fontSize: sizeTitle }}
                                        >
                                            Año
                                        </InputLabel>
                                        <Select
                                            labelId="year"
                                            id="year"
                                            name={'year'}
                                            value={infoPago.year}
                                            onChange={handleChange}
                                            style={{ fontSize: sizeTitle }}
                                        >
                                            {Years().map((year) => (
                                                <MenuItem
                                                    value={year}
                                                    key={year}
                                                    style={{
                                                        fontSize: sizeTitle,
                                                    }}
                                                >
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <FormControl
                                        fullWidth
                                        error={infoPagoErrors.err_cvv}
                                        disabled={loading}
                                    >
                                        <InputLabel
                                            htmlFor="name"
                                            style={{ fontSize: sizeTitle }}
                                        >
                                            CVV
                                        </InputLabel>
                                        <Input
                                            id="cvv"
                                            name={'cvv'}
                                            onChange={handleChange}
                                            value={infoPago.cvv}
                                            aria-describedby="component-helper-text"
                                            type={'password'}
                                            style={{ fontSize: sizeTitle }}
                                        />
                                        {infoPagoErrors.err_cvv ? (
                                            <FormHelperText id="component-helper-text">
                                                {infoPagoErrors.msg_cvv}
                                            </FormHelperText>
                                        ) : (
                                            ''
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {infoPagoErrors.status_pay ? (
                                <>
                                    <Grid item xs={12}>
                                        <Alert
                                            severity="error"
                                            onClose={() => {
                                                setInfoPagoErrors({
                                                    ...infoPagoErrors,
                                                    status_pay: false,
                                                })
                                            }}
                                        >
                                            ¡{infoPagoErrors.msg_pay}!
                                        </Alert>
                                    </Grid>
                                </>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                <Container>
                    <Detalles option={option} />
                </Container>
            )}
        </>
    )
}

const usePersonaStyle = makeStyles({
    title_c_porta: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 13,
    },
    c_porta: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 11,
    },
    texto_pop: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        padding: 5,
        fontSize: 13,
    },
    input: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
    },
    kind_buy: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.semi_bold_weight,
        fontSize: 20,
        color: XEL_COLORS.primary,
    },
    desc_buy: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 18,
        color: XEL_COLORS.primary,
    },

    optionSelected: {
        borderRadius: 12,
        backgroundColor: XEL_COLORS.secondary,
        color: '#FEFEFE',
        fontWeight: XEL_FONT.semi_bold_weight,
        cursor: 'pointer',
        padding: '2% 0',
    },
    optionIdle: {
        borderRadius: 12,
        backgroundColor: '#E4E4E4',
        color: XEL_COLORS.primary,
        fontWeight: XEL_FONT.semi_bold_weight,
        cursor: 'pointer',
        padding: '2% 0',
    },
})

const PersonalData = ({
    personalData,
    setPersonalData,
    setPersonalDataErrors,
    personalDataErrors,
}) => {
    const styles = globalStyles()
    const loading = useSelector((state) => state.buyloading)
    const handleChange = (e) => {
        if (e.target.name === 'nip') {
            if (isNaN(e.target.value)) return
            if (e.target.value.split('').length > 4) return
        }
        let namerr_check_reset = `err_${[e.target.name]}`
        let namerr_msg_reset = `msg_${[e.target.name]}`
        setPersonalData({
            ...personalData,
            [e.target.name]: e.target.value,
        })
        setPersonalDataErrors({
            ...personalDataErrors,
            [namerr_check_reset]: false,
            [namerr_msg_reset]: '',
        })
    }

    const productResumeData = useSelector(s => s.productResumeData)

    return (
        <Zoom in={true}>
            <Container component={'form'} className={styles.form}>
                <Grid container justify="space-around">
                    <Grid item xs={12}>
                        <PlanName />
                    </Grid>
                </Grid>
                <Grid
                    container
                    wrap="wrap"
                    justify={'center'}
                    style={{ marginBottom: 15 }}
                    spacing={2}
                >
                    {
                        useSelector(state => state.productResumeData.type) === "mifi" ?
                            <ProductResume data={productResumeData} /> : null
                    }
                    <Grid item xs={12}>
                        <Typography
                            variant={'h5'}
                            color={'primary'}
                            align={'center'}
                        >
                            Inserta tus datos
                        </Typography>
                        <Divider
                            style={{
                                width: '100%',
                                backgroundColor: XEL_COLORS.secondary,
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        className={styles.input}
                    >
                        <FormControl
                            fullWidth
                            error={personalDataErrors.err_nombre}
                            disabled={loading}
                        >
                            <InputLabel htmlFor="name">Nombre</InputLabel>
                            <Input
                                id="name"
                                name={'nombre'}
                                value={personalData.nombre}
                                onChange={handleChange}
                                aria-describedby="component-helper-text"
                                type={'text'}
                            />
                            {personalDataErrors.err_nombre ? (
                                <FormHelperText id="component-helper-text">
                                    {personalDataErrors.msg_nombre}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        className={styles.input}
                    >
                        <FormControl
                            fullWidth
                            error={personalDataErrors.err_apellidos}
                            disabled={loading}
                        >
                            <InputLabel htmlFor="apps">
                                Apellidos
                            </InputLabel>
                            <Input
                                id="apps"
                                name={'apellidos'}
                                value={personalData.apellidos}
                                onChange={handleChange}
                                aria-describedby="component-helper-text"
                                type={'text'}
                            />
                            {personalDataErrors.err_apellidos ? (
                                <FormHelperText id="component-helper-text">
                                    {personalDataErrors.msg_apellidos}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        className={styles.input}
                    >
                        <FormControl
                            fullWidth
                            error={personalDataErrors.err_mail}
                            disabled={loading}
                        >
                            <InputLabel htmlFor="email">
                                Correo electrónico
                            </InputLabel>
                            <Input
                                id="email"
                                name={'mail'}
                                onChange={handleChange}
                                value={personalData.mail}
                                aria-describedby="component-helper-text"
                                type="mail"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            {personalDataErrors.err_mail ? (
                                <FormHelperText id="component-helper-text">
                                    {personalDataErrors.msg_mail}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
                <div style={{ display: 'flex', }}>
                    <div style={{ width: '50%', marginTop: 20, borderBottom: '1px solid #777', display: 'flex' }}>
                        <input
                            name="age"
                            placeholder="Edad"
                            type="number"
                            style={{ backgroundColor: 'transparent', borderWidth: 0, fontSize: 18 }}
                            onChange={handleChange}
                        />
                    </div>

                    <div style={{ width: '50%', marginTop: 20, borderBottom: '1px solid #777', display: 'flex' }}>
                        <select
                            name="gender"
                            placeholder="Género"
                            style={{ border: 'none', fontSize: 18, color: '#3c4858', backgroundColor: 'transparent' }}
                            onChange={handleChange}
                        >
                            <option hidden value="">Género</option>
                            <option value="Femenino">Femenino</option>
                            <option value="Masculino">Masculino</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                </div>
            </Container>
        </Zoom>
    )
}

const DirData = ({
    dirData,
    setDirData,
    dirDataErrors,
    setDirDataErrors,
    sameDir,
    setSameDir,
    matches,
    option,
}) => {
    const styles = globalStyles()

    const sizeTitle = useResponsiveValues(20, 15, 10)
    const loading = useSelector((state) => state.buyloading)

    const dispatch = useDispatch()

    const { cpInfo, setCpInfo } = useContext(PayGateContext)
    const [cdmxPostals, setCdmxPostals] = useState([])

    const { setFreeShipping, portInMsisdn, showPortInInput } = useContext(PayGateContext)

    const dataSend = useSelector((state) => state.datasend)
    const totales = useSelector((state) => state.carrito)
    let axios = Axios()
    useEffect(() => {
        axios
            .get(`/utils/cp/cdmx_only`)
            .then((res) => {
                let response = res.data
                if (!response.error) {
                    setCdmxPostals(response.response.cp)
                }
            })
            .catch((err) => { })
    }, [matches])

    const carrito = useSelector(s => s.carrito)

    useEffect(() => {
        if (dirData.cod_pos !== '' && dirData.length === 5) {
            setCpInfoPromise().then()
        }
    }, [dirData.cod_pos])
    useEffect(() => {
        if (dirData.cod_pos || dirData.cod_pos_e) {
            if (
                dirData.cod_pos.split('').length > 0 ||
                dirData.cod_pos_e.split('').length > 0
            ) {
                if (cdmxPostals.length !== 0) {
                    let flag = false
                    for (let i = 0; i < cdmxPostals.length; i++) {
                        let postal = cdmxPostals[i]
                        if (sameDir === 'SI') {
                            if (cpInfo.length !== 0) {
                                if (dirData.cod_pos === postal) {
                                    flag = true
                                    break
                                    // i = cdmxPostals.length;
                                }
                            }
                        }
                    }
                    if (sameDir === 'SI') {
                        let nothing = false
                        if (dirData.cod_pos) {
                            if (dirData.cod_pos.split('').length === 0) {
                                nothing = true
                            }
                        }
                        if (cpInfo.length === 0 && nothing) return
                    } else {
                        let nothing = false
                        if (dirData.cod_pos_e) {
                            if (dirData.cod_pos_e.split('').length === 0) {
                                nothing = true
                            }
                        }
                        if (nothing) return
                    }
                    if (!flag) {
                        //  alert('4.1');
                        setFreeShipping(false)
                        dispatch(Actions.WITH_SEND())
                        dispatch(
                            Actions.ADD_TO_CART({
                                ...totales,
                                total_w_send: parseInt(carrito.total) + 149,
                                envio: {
                                    title: 'Costo de envío',
                                    price: 149,
                                    cuantity: 1,
                                    price_original: 149,
                                    id: 102,
                                },
                            })
                        )
                    } else {

                        // let pId = totales.products[0].id
                        let shippingPrice = 99
                        // if (pId === '39846') {
                        //     shippingPrice = 0
                        //     setFreeShipping(true)
                        // }
                        // else {
                        //     shippingPrice = 99
                        //     setFreeShipping(false)
                        // }
                        dispatch(
                            Actions.ADD_TO_CART({
                                ...totales,
                                total_w_send: parseInt(carrito.total) + shippingPrice,
                                envio: {
                                    title: 'Costo de envío',
                                    // price: 99,
                                    price: shippingPrice,
                                    cuantity: 1,
                                    price_original: 99,
                                    id: 100,
                                },
                            })
                        )
                        dispatch(Actions.WITHOUT_SEND())
                    }
                }
            }
        }
    }, [dirData, cdmxPostals, sameDir, cpInfo, matches, showPortInInput])

    useEffect(() => {
        if (option === 5) {
            //En caso de HBB tomamos la info de la dir que viene con la consulta del servicio
            const { cp, calle, numex } = dataSend.direction_hbb
            axios
                .get(`/utils/cp/query/info_cp/${cp}`)
                .then((res) => {
                    let response = res.data
                    if (response.length > 0) {
                        if (!response[0].error) {
                            setCpInfo(response)
                            setDirData({
                                ...dirData,
                                cod_pos: response[0].response.cp,
                                colonia: response[0].response.asentamiento,
                                municipio: response[0].response.municipio,
                                ciudad:
                                    response[0].response.ciudad.split('')
                                        .length === 0
                                        ? response[0].response.municipio
                                        : response[0].response.ciudad,
                                estado: response[0].response.estado,
                                calle,
                                num_ex: numex,
                            })
                        } else {
                            setDirDataErrors({
                                ...dirDataErrors,
                                err_cod_pos: true,
                                msg_cod_pos: 'Código postal no válido',
                            })
                        }
                    }
                })
                .catch((err) => { })
        }
    }, [])

    const setCpInfoPromise = (cp) =>
        new Promise((resolve, reject) => {
            axios
                .get(`/utils/cp/query/info_cp/${!cp ? dirData.cod_pos : cp}`)
                .then((res) => {
                    let response = res.data
                    if (response.length > 0) {
                        if (!response[0].error) {
                            setCpInfo(response)
                            setDirData({
                                ...dirData,
                                cod_pos: response[0].response.cp,
                                colonia: response[0].response.asentamiento,
                                municipio: response[0].response.municipio,
                                ciudad:
                                    response[0].response.ciudad.split('')
                                        .length === 0
                                        ? response[0].response.municipio
                                        : response[0].response.ciudad,
                                estado: response[0].response.estado,
                            })
                        } else {
                            setDirDataErrors({
                                ...dirDataErrors,
                                err_cod_pos: true,
                                msg_cod_pos: 'Código postal no válido',
                            })
                        }
                    }
                    resolve('OK')
                })
                .catch((err) => {
                    resolve('ERROR')
                })
        })
    const handleChange = (e) => {
        let namerr_check_reset = `err_${[e.target.name]}`
        let namerr_msg_reset = `msg_${[e.target.name]}`

        switch (e.target.name) {
            case 'cod_pos':
                if (isNaN(e.target.value)) return
                else if (e.target.value.split('').length > 5) return
                else if (e.target.value.split('').length === 5)
                    setCpInfoPromise(e.target.value).then()
                break
            case 'contact_phone':
                const {
                    target: { value },
                } = e
                if (isNaN(value)) return
                if (value.length > 10) return
                break
            default:
                break
        }
        setDirData({
            ...dirData,
            [e.target.name]: e.target.value,
        })
        setDirDataErrors({
            ...dirDataErrors,
            [namerr_check_reset]: false,
            [namerr_msg_reset]: '',
        })
    }

    return (
        <Zoom in={true}>
            <Container component={'form'} className={styles.form}>
                <Grid container wrap="wrap" justify={'center'} spacing={2}>
                    <Grid item xs={12}>
                        <PlanName />
                    </Grid>
                    {option === 5 && (
                        <Grid
                            item
                            xs={12}
                            style={{
                                border: '1px solid #000000',
                                borderRadius: 7,
                            }}
                        >
                            <Typography
                                variant={'body1'}
                                align={'center'}
                                style={{ marginBottom: '2%' }}
                            >
                                Te recordamos que la siguiente dirección en la
                                cual validaste tu cobertura será la ubicación
                                fija de tu Modem, de lo contrario tu servicio se
                                podría ver afectado
                            </Typography>

                            {[
                                `Código postal: ${dataSend.direction_hbb.cp}`,
                                `Calle: ${dataSend.direction_hbb.calle}`,
                                `Número exterior: ${dataSend.direction_hbb.numex}`,
                            ].map((txt, i) => {
                                return (
                                    <Typography
                                        variant={'body1'}
                                        color={'primary'}
                                        style={{
                                            fontWeight: XEL_FONT.bold_weight,
                                        }}
                                        key={i}
                                    >
                                        {txt}
                                    </Typography>
                                )
                            })}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography
                            variant={'h5'}
                            align={'center'}
                            color={'primary'}
                        >
                            Registra tu Dirección de envío
                        </Typography>
                        <Divider
                            style={{
                                width: '100%',
                                backgroundColor: XEL_COLORS.secondary,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} className={styles.input}>
                        <FormControl
                            fullWidth
                            error={dirDataErrors.err_cod_pos}
                            disabled={loading}
                        >
                            <InputLabel
                                htmlFor="name"
                                style={{ fontSize: sizeTitle }}
                            >
                                Código Postal
                            </InputLabel>
                            <Input
                                id="cod_pos"
                                name={'cod_pos'}
                                onChange={handleChange}
                                value={dirData.cod_pos}
                                aria-describedby="component-helper-text"
                                type="text"
                                style={{ fontSize: sizeTitle }}
                            />
                            {dirDataErrors.err_cod_pos ? (
                                <FormHelperText id="component-helper-text">
                                    {dirDataErrors.msg_cod_pos}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} className={styles.input}>
                        {cpInfo.length > 1 ? (
                            <>
                                <FormControl
                                    fullWidth
                                    disabled={loading}
                                    error={dirDataErrors.err_colonia}
                                >
                                    <InputLabel
                                        id="coloy"
                                        style={{ fontSize: sizeTitle }}
                                    >
                                        Colonia
                                    </InputLabel>
                                    <Select
                                        labelId="coloy"
                                        value={dirData.colonia}
                                        onChange={handleChange}
                                        id="colonia"
                                        name={'colonia'}
                                        style={{ fontSize: sizeTitle }}
                                    >
                                        {cpInfo.map((cp) => (
                                            <MenuItem
                                                value={cp.response.asentamiento}
                                                style={{ fontSize: sizeTitle }}
                                            >
                                                {cp.response.asentamiento}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {dirDataErrors.err_colonia ? (
                                        <FormHelperText id="nip-helper">
                                            {dirDataErrors.msg_colonia}
                                        </FormHelperText>
                                    ) : (
                                        ''
                                    )}
                                </FormControl>
                            </>
                        ) : (
                            <FormControl
                                fullWidth
                                error={dirDataErrors.err_colonia}
                                disabled={loading}
                            >
                                <InputLabel
                                    htmlFor="name"
                                    style={{ fontSize: sizeTitle }}
                                >
                                    Colonia
                                </InputLabel>
                                <Input
                                    id="colonia"
                                    name={'colonia'}
                                    onChange={handleChange}
                                    value={dirData.colonia}
                                    aria-describedby="component-helper-text"
                                    type="text"
                                    style={{ fontSize: sizeTitle }}
                                />
                                {dirDataErrors.err_colonia ? (
                                    <FormHelperText id="component-helper-text">
                                        {dirDataErrors.msg_colonia}
                                    </FormHelperText>
                                ) : (
                                    ''
                                )}
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4} className={styles.input}>
                        <FormControl
                            fullWidth
                            error={dirDataErrors.err_municipio}
                            disabled={loading}
                        >
                            <InputLabel
                                htmlFor="name"
                                style={{ fontSize: sizeTitle }}
                            >
                                Municipio
                            </InputLabel>
                            <Input
                                id="municipio"
                                name={'municipio'}
                                onChange={handleChange}
                                value={dirData.municipio}
                                aria-describedby="component-helper-text"
                                type="text"
                                style={{ fontSize: sizeTitle }}
                            />
                            {dirDataErrors.err_municipio ? (
                                <FormHelperText id="component-helper-text">
                                    {dirDataErrors.msg_municipio}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} className={styles.input}>
                        <FormControl
                            fullWidth
                            error={dirDataErrors.err_ciudad}
                            disabled={loading}
                        >
                            <InputLabel
                                htmlFor="name"
                                style={{ fontSize: sizeTitle }}
                            >
                                Ciudad
                            </InputLabel>
                            <Input
                                id="ciudad"
                                name={'ciudad'}
                                onChange={handleChange}
                                value={dirData.ciudad}
                                aria-describedby="component-helper-text"
                                type="text"
                                style={{ fontSize: sizeTitle }}
                            />
                            {dirDataErrors.err_ciudad ? (
                                <FormHelperText id="component-helper-text">
                                    {dirDataErrors.msg_ciudad}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} className={styles.input}>
                        <FormControl
                            fullWidth
                            error={dirDataErrors.err_estado}
                            disabled={loading}
                        >
                            <InputLabel
                                htmlFor="name"
                                style={{ fontSize: sizeTitle }}
                            >
                                Estado
                            </InputLabel>
                            <Input
                                id="estado"
                                name={'estado'}
                                onChange={handleChange}
                                value={dirData.estado}
                                aria-describedby="component-helper-text"
                                type="text"
                                style={{ fontSize: sizeTitle }}
                            />
                            {dirDataErrors.err_estado ? (
                                <FormHelperText id="component-helper-text">
                                    {dirDataErrors.msg_estado}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} className={styles.input}>
                        <FormControl
                            fullWidth
                            error={dirDataErrors.err_calle}
                            disabled={loading}
                        >
                            <InputLabel
                                htmlFor="name"
                                style={{ fontSize: sizeTitle }}
                            >
                                Calle
                            </InputLabel>
                            <Input
                                id="calle"
                                name={'calle'}
                                onChange={handleChange}
                                value={dirData.calle}
                                aria-describedby="component-helper-text"
                                type="text"
                                style={{ fontSize: sizeTitle }}
                            />
                            {dirDataErrors.err_estado ? (
                                <FormHelperText id="component-helper-text">
                                    {dirDataErrors.msg_calle}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} className={styles.input}>
                        <FormControl
                            fullWidth
                            error={dirDataErrors.err_num_ex}
                            disabled={loading}
                        >
                            <InputLabel
                                htmlFor="name"
                                style={{ fontSize: sizeTitle }}
                            >
                                Núm. Exterior{' '}
                            </InputLabel>
                            <Input
                                id="num_ex"
                                name={'num_ex'}
                                onChange={handleChange}
                                value={dirData.num_ex}
                                aria-describedby="component-helper-text"
                                type="text"
                                style={{ fontSize: sizeTitle }}
                            />
                            {dirDataErrors.err_num_ex ? (
                                <FormHelperText id="component-helper-text">
                                    {dirDataErrors.msg_num_ex}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} className={styles.input}>
                        <FormControl
                            fullWidth
                            error={dirDataErrors.err_num_in}
                            disabled={loading}
                        >
                            <InputLabel
                                htmlFor="name"
                                style={{ fontSize: sizeTitle }}
                            >
                                Núm. Interior{' '}
                            </InputLabel>
                            <Input
                                id="num_in"
                                name={'num_in'}
                                onChange={handleChange}
                                value={dirData.num_in}
                                aria-describedby="component-helper-text"
                                type="text"
                                style={{ fontSize: sizeTitle }}
                            />
                            {dirDataErrors.err_num_in ? (
                                <FormHelperText id="component-helper-text">
                                    {dirDataErrors.msg_num_in}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8} className={styles.input}>
                        <FormControl
                            fullWidth
                            error={dirDataErrors.err_rfc}
                            disabled={loading}
                        >
                            <InputLabel
                                htmlFor="name"
                                style={{ fontSize: sizeTitle }}
                            >
                                Referencias
                            </InputLabel>
                            <Input
                                id="referencias"
                                name={'referencias'}
                                onChange={handleChange}
                                value={dirData.referencias}
                                aria-describedby="component-helper-text"
                                type="text"
                                style={{ fontSize: sizeTitle }}
                            />
                            {dirDataErrors.err_rfc ? (
                                <FormHelperText id="component-helper-text">
                                    {dirDataErrors.msg_rfc}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4} className={styles.input}>
                        <FormControl
                            fullWidth
                            error={dirDataErrors.err_contact_phone}
                            disabled={loading}
                        >
                            <InputLabel
                                htmlFor="contact_phone"
                                style={{ fontSize: sizeTitle }}
                            >
                                Telefono de contacto
                            </InputLabel>
                            <Input
                                id="contact_phone"
                                name={'contact_phone'}
                                onChange={handleChange}
                                value={dirData.contact_phone}
                                aria-describedby="component-helper-text"
                                type="text"
                                style={{ fontSize: sizeTitle }}
                            />
                            {dirDataErrors.err_contact_phone ? (
                                <FormHelperText id="component-helper-text">
                                    {dirDataErrors.msg_contact_phone}
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>
        </Zoom>
    )
}

const useStylesComp = makeStyles((theme) => ({
    step: {
        backgroundColor: XEL_COLORS.primary,
    },
    check_comps: {
        display: 'flex',
        justifyContent: 'center',
        direction: 'row',
        wrap: 'flex-wrap',
    },
    text: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
    },
    container_steps: {
        minHeight: '70vh',
        paddingTop: 10,
        display: 'flex',
        alignItems: 'flex-start',
    },
    title_resume: {
        color: XEL_COLORS.primary,
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.bold_weight,
        fontSize: 17,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: XEL_COLORS.primary,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    forLabel: {
        '& .MuiFormControlLabel-label': {
            fontSize: '90%',
            color: '#FEFEFE',
            fontWeight: XEL_FONT.semi_bold_weight,
        },
    },
    device_important_container: {
        background:
            'transparent linear-gradient(180deg, #5E1E93 0%, #FF7900 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: 12,
        padding: '1%',
        color: '#FEFEFE',
    },

    radioPrimary: {
        color: '#FEFEFE',
    },
    radioChecked: {
        color: '#FEFEFE',
        fill: '#FEFEFE',
    },
}))

const CompRadio = withStyles({
    root: {
        color: '#FEFEFE',
        '&$checked': {
            color: '#FEFEFE',
        },
    },
    checked: {
        color: '#FEFEFE',
        // backgroundColor:'#FEFEFE'
    },
})((props) => <Radio {...props} />)

const Compatibilidad = ({ planDetails, option, next }) => {
    let axios = Axios()
    const styles = useStylesComp()
    const dispatch = useDispatch()
    const dataSend = useSelector((state) => state.datasend)
    const [promotion, setPromotion] = useState('')
    const [message, setMessage] = useState({
        open: false,
        severity: 'success',
        text: '',
    })
    const [portInAlert, setPortInAlert] = useState('')

    const usePortIn = useRef()

    const history = useHistory()
    const [imei, setImei] = useState({
        imei: '',
        valid: false,
    })

    const continueBuyHbb = async (coordinates) => {
        const { dir, lat, long } = coordinates

        await dispatch(
            Actions.SET_DATA({
                ...dataSend,
                coordinates: {
                    lat: lat.toString(),
                    long: long.toString(),
                },
                direction_hbb: dir,
            })
        )
        next()
    }

    useEffect(() => {
        dispatch(
            Actions.SET_DATA({
                ...dataSend,
                imei: imei.imei,
                promotion: promotion,
            })
        )
        if (imei.valid && option !== 5) {
            next()
        }
    }, [imei, promotion])

    const handleNext = () => {

        if (!checkFree) {
            setMessage({
                open: true,
                text: 'Marca las casillas para continuar',
                severity: 'info',
            })
            return
        }

        if (showPortInInput && !/^\d{10}$/.test(portInMsisdn)) {
            setPortInAlert('Introduzca un número válido')
            return
        }

        next()
    }

    const [showCheckImei, setShowCheckImei] = useState(false)
    const [imeiIsCompatible, setImeiIsCompatible] = useState(true)

    const { matches: isSmallScreen } = window.matchMedia('(max-width: 500px)')


    let carrito = useSelector(s => s.carrito)
    let productResumeData = useSelector(s => s.productResumeData)

    const {
        setPortInMsisdn,
        portInMsisdn,
        checkFree,
        setCheckFree,
        showPortInInput,
        setShowPortInInput
    } = useContext(PayGateContext)

    const actualUrl = window.location.pathname

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PlanName />
                </Grid>

                <ProductResume data={productResumeData} />

                <Grid item xs={12}>
                    {option !== 5 && (
                        <Grid item xs={12}>
                            <Grid container spacing={2} justify={'center'}>
                                <Grid item xs={12} md={7}>
                                    <Typography
                                        style={{ marginBottom: 3 }}
                                        color={'primary'}
                                        variant={'h5'}
                                        align={'center'}
                                    >
                                        Compatibilidad de tu equipo
                                    </Typography>
                                    <Divider
                                        style={{
                                            width: '100%',
                                            backgroundColor:
                                                XEL_COLORS.secondary,
                                        }}
                                    />
                                </Grid>
                                <div style={{ fontSize: isSmallScreen ? 12 : '1rem' }}>
                                    <div style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row' }}>
                                        <Grid style={{ flex: 1, padding: isSmallScreen ? 12 : 35 }} item xs={12} md={7}>
                                            <div
                                                style={{ padding: 15 }}
                                                className={
                                                    styles.device_important_container
                                                }
                                            >
                                                <Typography>IMPORTANTE:</Typography>
                                                <Typography
                                                    variant={'body2'}
                                                    align={'center'}
                                                >
                                                    Recuerda que tu equipo debe estar
                                                    liberado para que funcione con
                                                    cualquier operador telefónico y
                                                    sobre todo debe ser compatible con
                                                    la banda 28
                                                </Typography>
                                            </div>
                                        </Grid>

                                        <div
                                            style={{
                                                flex: 1,
                                                margin: isSmallScreen ? 5 : 30,
                                                padding: isSmallScreen ? 5 : 20,
                                                boxShadow:
                                                    '2px 6px 13px -4px rgba(0,0,0,0.49)',
                                                width: isSmallScreen
                                                    ? '110%'
                                                    : 'auto',
                                                position: 'relative',
                                                left: isSmallScreen ? '-10%' : 0,
                                            }}
                                        >
                                            <Grid item xs={12}>
                                                <FormGroup
                                                    className={styles.check_comps}
                                                    row
                                                    style={
                                                        imeiIsCompatible
                                                            ? {
                                                                color: '#5A3571',
                                                            }
                                                            : {
                                                                opacity: 0,
                                                                height: 0,
                                                            }
                                                    }
                                                >
                                                    <div style={{ margin: 15 }}>
                                                        <div
                                                            style={{
                                                                borderBottom:
                                                                    '2px solid #5A3571',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            defaultChecked={checkFree}
                                                                        />
                                                                    }
                                                                    onChange={e => setCheckFree(_ => !_)}
                                                                    label={
                                                                        <Typography
                                                                            style={{
                                                                                fontSize:
                                                                                    isSmallScreen
                                                                                        ? 12
                                                                                        : 'auto',
                                                                            }}
                                                                        >
                                                                            Mi
                                                                            Equipo
                                                                            esta
                                                                            liberado
                                                                            y es
                                                                            compatible
                                                                            con la
                                                                            banda 28
                                                                        </Typography>
                                                                    }
                                                                />
                                                                <span
                                                                    style={{
                                                                        width: 20,
                                                                        height: 20,
                                                                        borderRadius: 10,
                                                                        backgroundColor:
                                                                            '#e8dded',
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        margin: 'auto',
                                                                        cursor: 'pointer',
                                                                        padding: 3,
                                                                    }}
                                                                    onClick={() => {
                                                                        setShowCheckImei(
                                                                            (_) =>
                                                                                !_
                                                                        )
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            margin: 'auto',
                                                                        }}
                                                                    >
                                                                        ?
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{ flex: 1 }}
                                                        ></div>
                                                    </div>
                                                </FormGroup>
                                            </Grid>
                                            {message.open && (
                                                <Grid item xs={12}>
                                                    <Alert
                                                        severity={message.severity}
                                                        onClose={() =>
                                                            setMessage(
                                                                (prevState) => ({
                                                                    ...prevState,
                                                                    open: false,
                                                                })
                                                            )
                                                        }
                                                    >
                                                        {message.text}
                                                    </Alert>
                                                </Grid>
                                            )}
                                            <div>
                                                {showCheckImei ? (
                                                    <CheckImei
                                                        setImeiIsCompatible={
                                                            setImeiIsCompatible
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div style={{ maxWidth: 700, margin: 'auto', flex: 1 }}>

                                            <Typography
                                                style={{ marginBottom: 3, marginTop: 20 }}
                                                color={'secondary'}
                                                variant={'h5'}
                                                align={'center'}
                                            >
                                                Portabilidad
                                            </Typography>
                                            {actualUrl.includes('39846') &&
                                                <Typography
                                                    style={{ fontWeight: 'bold' }}
                                                    align={'center'}
                                                    color={'primary'}
                                                >
                                                    ¡Con tu Portabilidad disfruta de {' '}
                                                    <Typography component="span" variant="inherit" color="secondary">
                                                        15GB
                                                    </Typography>
                                                    {' '} adicionales en tu plan de 5GB, por todo 1 año!
                                                </Typography>
                                            }
                                            <Divider
                                                style={{
                                                    width: '100%',
                                                    backgroundColor:
                                                        XEL_COLORS.secondary,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    margin: isSmallScreen ? 5 : 30,
                                                    padding: isSmallScreen ? 5 : 20,
                                                    boxShadow:
                                                        '2px 6px 13px -4px rgba(0,0,0,0.49)',
                                                    width: isSmallScreen
                                                        ? '110%'
                                                        : 'auto',
                                                    position: 'relative',
                                                    left: isSmallScreen ? '-10%' : 0,
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox defaultChecked={showPortInInput} />
                                                    }
                                                    onChange={e => setShowPortInInput(_ => !_)}
                                                    label={
                                                        <Typography
                                                            style={{
                                                                fontSize:
                                                                    isSmallScreen
                                                                        ? 12
                                                                        : 'auto',
                                                            }}
                                                        >
                                                            Deseo conservar mi número actual
                                                        </Typography>
                                                    }

                                                />
                                                {
                                                    showPortInInput ?
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <input
                                                                value={portInMsisdn}
                                                                placeholder="Ingrese su número"
                                                                style={portInputStyle}
                                                                type="text"
                                                                onChange={e => setPortInMsisdn(e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    portInAlert ?
                                                        <Alert
                                                            severity={'info'}
                                                            onClose={() =>
                                                                setPortInAlert('')
                                                            }
                                                        >
                                                            {portInAlert}
                                                        </Alert>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        {imeiIsCompatible ? (
                                            <Button
                                                variant={'contained'}
                                                color={'primary'}
                                                onClick={handleNext}
                                                style={{
                                                    margin: 'auto', marginTop: 20, marginBottom: 20,
                                                    background:
                                                        `linear-gradient(${primary2}, ${primary_shiny})`,
                                                    color: 'white',
                                                }}
                                            >
                                                Continuar
                                            </Button>
                                        ) : (
                                            <Button
                                                variant={'contained'}
                                                color={'primary'}
                                                onClick={() =>
                                                    history.push('/')
                                                }
                                                style={{ margin: 'auto' }}
                                            >
                                                Volver
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                    {/* option === 1 || option === 6 || option === 7 ? imei.valid ? '' :
                         free.status ? free.value === 'SI' ?
                                 <Grow in={true} timeout={1000}>
                                     <CheckImei imeiReq={imeirequest} setImei={setImei}
                                                imeiData={imei}/>
                                 </Grow>
                                 : NotFreeMessage :
                             <CheckFreeDev free={free} setFree={setFree} dataSend={dataSend}/> : option === 2 ?
                         '' : ''*/}
                    {option === 5 && (
                        <CoberturaHBB
                            planDetails={planDetails}
                            setStatus={continueBuyHbb}
                        />
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}


export default function PasosCompra({
    setPayed,
    option,
    planDetails,
    withDevice,
    planOption,
}) {
    const styles = globalStyles()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'))
    const classes = useStyles()
    const dispatch = useDispatch()
    const steps = getSteps()
    const params = useParams()
    const plan = params.idp
    //const crmfield = useCRMFields();
    //const onlyLettersReg = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
    const mailReg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const loading = useSelector((state) => state.buyloading)
    // eslint-disable-next-line no-undef
    const OpenPayInstance = OpenPay
    const dataSend = useSelector((state) => state.datasend)
    const session = useSelector((state) => state.login)
    const [activeStep, setActiveStep] = useState(
        (planOption !== 1 &&
            planOption !== 6 &&
            planOption !== 7 &&
            planOption !== 5) ||
            withDevice
            ? 1
            : 0
    )
    const actualYear = new Date().getFullYear()
    const [carriers, setCarriers] = useState([])
    const [loaded, setLoaded] = useState(true)
    const totales = useSelector((state) => state.carrito)
    const [prospectId, setProspectId] = useState()
    const totalToSend = totales.mensuality
        ? totales.mensuality.enabled
            ? totales.mensuality.price
            : totales.total_w_send
        : totales.total_w_send
    let totalPrice = totales.total_w_send
    //const [totalPrice,setTotalPrice] = useState(totales.total_w_send);
    /**********Seccion datos personales*************/
    //Datos
    const [personalData, setPersonalData] = useState({
        nombre: '',
        apellidos: '',
        tel: '',
        mail: '',
        nip: '',
        carrier: '0000',
        tel_porta: '',
        is_porta: false,
    })

    const [personalDataErrors, setPersonalDataErrors] = useState({
        err_nombre: false,
        msg_nombre: '',
        err_apellidos: false,
        msg_apellidos: '',
        err_tel: false,
        msg_tel: '',
        err_mail: false,
        msg_mail: '',
        err_nip: false,
        msg_nip: '',
        err_carrier: false,
        msg_carrier: '',
        err_tel_porta: false,
        msg_tel_porta: '',
    })

    /**********Fin Datos personales*************/
    /**********Seccion direcciones*************/
    const [dirData, setDirData] = useState({
        cod_pos: '',
        colonia: '',
        municipio: '',
        ciudad: '',
        estado: '',
        calle: '',
        num_ex: '',
        num_in: '',
        referencias: '',

        cod_pos_e: '',
        colonia_e: '',
        municipio_e: '',
        ciudad_e: '',
        estado_e: '',
        calle_e: '',
        num_ex_e: '',
        num_in_e: '',
        referencias_e: '',
    })

    const [dirDataErrors, setDirDataErrors] = useState({
        err_cod_pos: false,
        msg_cod_pos: '',
        err_colonia: false,
        msg_colonia: '',
        err_municipio: false,
        msg_municipio: '',
        err_ciudad: false,
        msg_ciudad: '',
        err_estado: false,
        msg_estado: '',
        err_calle: false,
        msg_calle: '',
        err_num_ex: false,
        msg_num_ex: '',
        err_num_in: false,
        msg_num_in: '',
        err_rfc: false,
        msg_rfc: '',

        err_cod_pos_e: false,
        msg_cod_pos_e: '',
        err_colonia_e: false,
        msg_colonia_e: '',
        err_municipio_e: false,
        msg_municipio_e: '',
        err_ciudad_e: false,
        msg_ciudad_e: '',
        err_estado_e: false,
        msg_estado_e: '',
        err_calle_e: false,
        msg_calle_e: '',
        err_num_ex_e: false,
        msg_num_ex_e: '',
        err_num_in_e: false,
        msg_num_in_e: '',
        err_rfc_e: false,
        msg_rfc_e: '',
    })

    const [sameDir, setSameDir] = useState('SI')
    /**********Fin Seccion Direcciones*************/

    const axios = Axios()
    /**********Seccion Pago*************/

    const planData = useSelector((state) => state.planname)
    const amount = useSelector((state) => state.planprice)
    const carrito = useSelector((state) => state.carrito)
    const [totalPago, setTotalPago] = useState(0)
    const [lastStep, setLastStep] = useState(activeStep)
    const [percent, setPercent] = useState(0)
    const [infoPago, setInfoPago] = useState({
        name_card: '',
        card: '',
        month: '01',
        year: '' + actualYear,
        cvv: '',
    })
    const [infoPagoErrors, setInfoPagoErrors] = useState({
        err_name_card: false,
        mg_name_card: '',
        err_card: false,
        mg_card: '',
        err_month: false,
        mg_month: '',
        err_year: false,
        mg_year: '',
        err_cvv: false,
        mg_cvv: '',
        status_pay: false,
        msg_pay: '',
    })
    /**********Fin Seccion Pago*************/
    const [alertData, setAlertData] = useState({
        show: false,
        type: 'success',
        title: 'Lo que sea',
        text: 'Hubo un error',
    })

    const [idData, setIdData] = useState({
        selected: '0000',
        isError: true,
        img_one: null,
        img_two: null,
        loaded: false,
    })
    const [Accept, setAccept] = useState(false)
    const [orderFail, setOrderFail] = useState(null)

    const history = useHistory()

    const location = useLocation()

    useEffect(() => {
        // eslint-disable-next-line no-undef
        gtag('proceso_compra', {
            plan: planData,
            monto: 0,
            paso: 0,
        })
    }, [])

    useEffect(() => {
        if (activeStep > lastStep) {
            // eslint-disable-next-line no-undef
            gtag('proceso_compra', {
                plan: planData,
                monto: amount,
                paso: lastStep,
            })
            setLastStep(activeStep)
        }
    }, [activeStep])

    //Errores
    useEffect(() => {
        if (loaded) {
            dispatch(
                Actions.SET_DATA({
                    ...dataSend,
                    personal_nombre: personalData.nombre,
                    personal_apellidos: personalData.apellidos,
                    personal_tel: personalData.tel,
                    personal_email: session.email,
                    personal_tel_porta: personalData.tel_porta,
                    personal_nip: personalData.nip,
                    personal_portabilidad: personalData.is_porta,
                    personal_compania: personalData.carrier,
                })
            )
        }
    }, [personalData, loaded])
    useEffect(() => {
        if (loaded) {
            dispatch(
                Actions.SET_DATA({
                    ...dataSend,
                    dir_postal_code: dirData.cod_pos,
                    dir_colonia: dirData.colonia,
                    dir_municipio: dirData.municipio,
                    dir_ciudad: dirData.ciudad,
                    dir_estado: dirData.estado,
                    dir_calle: dirData.calle,
                    dir_numex: dirData.num_ex,
                    dir_numin: dirData.num_in,
                    dir_referencias: dirData.referencias,
                })
            )
        }
    }, [dirData, loaded])

    useEffect(() => {
        setTotalPago(parseInt(planData.price) + parseInt(amount))
    }, [amount])

    useEffect(() => {
        dispatch(
            Actions.SET_DATA({
                ...dataSend,
                dir_postal_code_e: '',
                dir_colonia_e: '',
                dir_municipio_e: '',
                dir_ciudad_e: '',
                dir_estado_e: '',
                dir_calle_e: '',
                dir_numex_e: '',
                dir_numin_e: '',
                dir_referencias_e: '',
            })
        )
    }, [sameDir])

    const handleNext = async () => {
        switch (activeStep) {
            case 0:
                dispatch(Actions.BUY_PROCESS_LOADING())
                setActiveStep((prevActiveStep) => prevActiveStep + 1)
                dispatch(Actions.BUY_PROCESS_END())
                break
            case 1:
                let { nombre, apellidos, tel, mail } = personalData

                let arrayErrs = []

                if (nombre.split('').length === 0) {
                    setPersonalDataErrors({
                        ...personalDataErrors,
                        err_nombre: true,
                        msg_nombre: 'Inserta nombre',
                    })
                    let err = {
                        err_name: 'err_nombre',
                        err_msg_name: 'msg_nombre',
                        err_msg: 'Inserta nombre',
                    }

                    arrayErrs.push(err)
                }

                if (apellidos.split('').length === 0) {
                    let err = {
                        err_name: 'err_apellidos',
                        err_msg_name: 'msg_apellidos',
                        err_msg: 'Inserta apellidos',
                    }
                    arrayErrs.push(err)
                }
                if (mail) {
                    if (mail.split('').length === 0) {
                        let err = {
                            err_name: 'err_mail',
                            err_msg_name: 'msg_mail',
                            err_msg: 'Inserta correo electrónico',
                        }
                        arrayErrs.push(err)
                    } else if (!mailReg.test(mail)) {
                        let err = {
                            err_name: 'err_mail',
                            err_msg_name: 'msg_mail',
                            err_msg: 'Correo electrónico inválido',
                        }
                        arrayErrs.push(err)
                    }
                } else {
                    let err = {
                        err_name: 'err_mail',
                        err_msg_name: 'msg_mail',
                        err_msg: 'Inserta correo electrónico',
                    }
                    arrayErrs.push(err)
                }

                let errorExist = arrayErrs.length > 0
                let newErrVals = personalDataErrors
                for (let i = 0; i < arrayErrs.length; i++) {
                    newErrVals = {
                        ...newErrVals,
                        [arrayErrs[i].err_name]: true,
                        [arrayErrs[i].err_msg_name]: [arrayErrs[i].err_msg],
                    }
                }
                setPersonalDataErrors(newErrVals)
                const contactErr =
                    newErrVals.err_apellidos ||
                    newErrVals.err_mail ||
                    newErrVals.err_tel ||
                    newErrVals.err_nombre

                if (errorExist) {
                    handleAncla()
                    return
                }

                setActiveStep((prevActiveStep) => prevActiveStep + 1)

                break
            case 2:
                const {
                    cod_pos,
                    colonia,
                    municipio,
                    ciudad,
                    estado,
                    calle,
                    referencias,
                    colonia_e,
                    municipio_e,
                    ciudad_e,
                    estado_e,
                    calle_e,
                    referencias_e,
                    contact_phone,
                } = dirData
                let arrayErrs2 = []

                if (cod_pos.split('').length === 0) {
                    let err = {
                        err_name: 'err_cod_pos',
                        err_msg_name: 'msg_cod_pos',
                        err_msg: 'Inserta código postal',
                    }
                    arrayErrs2.push(err)
                } else if (cod_pos.split('').length < 5) {
                    let err = {
                        err_name: 'err_cod_pos',
                        err_msg_name: 'msg_cod_pos',
                        err_msg: 'Codigo inválido',
                    }
                    arrayErrs2.push(err)
                }
                if (colonia.split('').length === 0) {
                    let err = {
                        err_name: 'err_colonia',
                        err_msg_name: 'msg_colonia',
                        err_msg: 'Inserta colonia',
                    }
                    arrayErrs2.push(err)
                }
                if (municipio.split('').length === 0) {
                    let err = {
                        err_name: 'err_municipio',
                        err_msg_name: 'msg_municipio',
                        err_msg: 'Inserta municipio',
                    }
                    arrayErrs2.push(err)
                }
                if (ciudad.split('').length === 0) {
                    let err = {
                        err_name: 'err_ciudad',
                        err_msg_name: 'msg_ciudad',
                        err_msg: 'Inserta ciudad',
                    }
                    arrayErrs2.push(err)
                }
                if (estado.split('').length === 0) {
                    let err = {
                        err_name: 'err_estado',
                        err_msg_name: 'msg_estado',
                        err_msg: 'Inserta estado',
                    }
                    arrayErrs2.push(err)
                }
                if (calle.split('').length === 0) {
                    let err = {
                        err_name: 'err_calle',
                        err_msg_name: 'msg_calle',
                        err_msg: 'Inserta calle',
                    }
                    arrayErrs2.push(err)
                }

                if (referencias.split('').length === 0) {
                    let err = {
                        err_name: 'err_rfc',
                        err_msg_name: 'msg_rfc',
                        err_msg: 'Inserta valor',
                    }
                    arrayErrs2.push(err)
                }

                if (!contact_phone) {
                    let err = {
                        err_name: 'err_contact_phone',
                        err_msg_name: 'msg_contact_phone',
                        err_msg: 'Inserta número de contacto',
                    }
                    arrayErrs2.push(err)
                } else if (
                    contact_phone.length !== 10 ||
                    isNaN(contact_phone)
                ) {
                    let err = {
                        err_name: 'err_contact_phone',
                        err_msg_name: 'msg_contact_phone',
                        err_msg: 'Número de contacto no válido',
                    }
                    arrayErrs2.push(err)
                }
                if (sameDir === 'NO') {
                    if (colonia_e.split('').length === 0) {
                        let err = {
                            err_name: 'err_colonia_e',
                            err_msg_name: 'msg_colonia_e',
                            err_msg: 'Inserta colonia',
                        }
                        arrayErrs2.push(err)
                    }
                    if (municipio_e.split('').length === 0) {
                        let err = {
                            err_name: 'err_municipio_e',
                            err_msg_name: 'msg_municipio_e',
                            err_msg: 'Inserta municipio',
                        }
                        arrayErrs2.push(err)
                    }
                    if (ciudad_e.split('').length === 0) {
                        let err = {
                            err_name: 'err_ciudad_e',
                            err_msg_name: 'msg_ciudad_e',
                            err_msg: 'Inserta ciudad',
                        }
                        arrayErrs2.push(err)
                    }
                    if (estado_e.split('').length === 0) {
                        let err = {
                            err_name: 'err_estado_e',
                            err_msg_name: 'msg_estado_e',
                            err_msg: 'Inserta estado',
                        }
                        arrayErrs2.push(err)
                    }
                    if (calle_e.split('').length === 0) {
                        let err = {
                            err_name: 'err_calle_e',
                            err_msg_name: 'msg_calle_e',
                            err_msg: 'Inserta calle',
                        }
                        arrayErrs2.push(err)
                    }

                    if (referencias_e.split('').length === 0) {
                        let err = {
                            err_name: 'err_rfc_e',
                            err_msg_name: 'msg_rfc_e',
                            err_msg: 'Inserta valor',
                        }
                        arrayErrs2.push(err)
                    }
                }

                let newErrVals2 = infoPagoErrors
                for (let i = 0; i < arrayErrs2.length; i++) {
                    newErrVals2 = {
                        ...newErrVals2,
                        [arrayErrs2[i].err_name]: true,
                        [arrayErrs2[i].err_msg_name]: [arrayErrs2[i].err_msg],
                    }
                }
                if (arrayErrs2.length > 0) {
                    setDirDataErrors(newErrVals2)
                    handleAncla()
                    return
                }
                if (!prospectId) {
                    ax.post('https://services.inxel.mx/inxel/ecommerce/prospect', {
                        title: 'Proceso de compra inxel.mx',
                        firstName: personalData.nombre,
                        lastName: personalData.apellidos,
                        email: personalData.mail,
                        phone: parseInt(contact_phone) ,
                    }).then((res) => {
                        setProspectId(res.data.id)
                    }).catch((err) => {
                        console.error('Error creating prospect', err);
                    })
                }

                setActiveStep((prevActiveStep) => prevActiveStep + 1)

                break

            case 3:
                if (!Accept) {
                    setAccept(true)
                    return
                }
                let { name_card, card, cvv } = infoPago

                let arrayErrs3 = []

                if (name_card.split('').length === 0) {
                    let err = {
                        err_name: 'err_name_card',
                        err_msg_name: 'msg_name_card',
                        err_msg: 'Inserta nombre completo',
                    }
                    arrayErrs3.push(err)
                }
                if (card.split('').length === 0) {
                    let err = {
                        err_name: 'err_card',
                        err_msg_name: 'msg_card',
                        err_msg: 'Inserta tarjeta',
                    }
                    arrayErrs3.push(err)
                } else if (card.split('').length < 15) {
                    let err = {
                        err_name: 'err_card',
                        err_msg_name: 'msg_card',
                        err_msg: 'Inserta tarjeta valida',
                    }
                    arrayErrs3.push(err)
                }

                if (cvv.split('').length === 0) {
                    let err = {
                        err_name: 'err_cvv',
                        err_msg_name: 'msg_cvv',
                        err_msg: 'Inserta CVV',
                    }
                    arrayErrs3.push(err)
                }

                let newErrVals3 = infoPagoErrors
                for (let i = 0; i < arrayErrs3.length; i++) {
                    newErrVals3 = {
                        ...newErrVals3,
                        [arrayErrs3[i].err_name]: true,
                        [arrayErrs3[i].err_msg_name]: [arrayErrs3[i].err_msg],
                    }
                }
                setInfoPagoErrors(newErrVals3)

                if (arrayErrs3.length > 0) {
                    handleAncla()
                    return
                }

                if (dataSend.pay_method) {
                    if (dataSend.pay_method === 'MENSUALIDADES') {
                        if (dataSend.cardType === 'VISA') {
                            if (card.split('').length < 16) {
                                alert('Introduce una tarjeta VISA o MasterCard')
                                return
                            }
                        } else {
                            if (card.split('').length > 15) {
                                alert('Introduce una tarjeta American Express')
                                return
                            }
                        }
                    }
                }

                const form = new FormData()

                //  if (OpenPayInstance.card.validateCardNumber(infoPago.card)) {
                form.append('name', personalData.nombre)
                form.append('apps', personalData.apellidos)
                form.append('mail', personalData.mail)
                form.append('phone', personalData.tel)
                // eslint-disable-next-line no-undef
                // form.append('id_dev', deviceSessionId);

                let arrYear = (infoPago.year + '').split('')
                let yr = arrYear[2] + arrYear[3]
                if (!OpenPayInstance.card.validateExpiry(infoPago.month, yr)) {
                    alert(
                        'Fecha de expiracion inválida: ' +
                        infoPago.month +
                        '/' +
                        yr
                    )
                    return
                }
                dispatch(Actions.BUY_PROCESS_LOADING())
                OpenPayInstance.token.create(
                    {
                        card_number: infoPago.card,
                        holder_name: infoPago.name_card,
                        expiration_year: yr,
                        expiration_month: infoPago.month,
                        cvv2: infoPago.cvv,
                        address: {
                            city: dirData.ciudad,
                            line3: dirData.municipio,
                            postal_code: dirData.cod_pos,
                            line1: 'No requerido',
                            line2: 'No requerido',
                            state: dirData.estado,
                            country_code: 'MX',
                        },
                    },
                    async (data) => {
                        var deviceDataId =
                            OpenPayInstance.deviceData.setup('frm_getcard')

                        form.append('id_dev', deviceDataId)
                        form.append('token_card', data.data.id)
                        form.append('monto', totalPrice + '')

                        let mensuality = {
                            enabled: 'NO',
                            months: 0,
                        }
                        if (totales.mensuality) {
                            if (totales.mensuality.enabled) {
                                mensuality.enabled = 'SI'
                                mensuality.months = totales.mensuality.months
                            }
                        }
                        form.append('mensuality', mensuality.enabled)
                        form.append('months', mensuality.months)
                        form.append(
                            'secure',
                            parseInt(totalToSend) > 5000 ? 'SI' : 'NO'
                        )

                        const total = totales.mensuality
                            ? totales.mensuality.enabled
                                ? totales.mensuality.price
                                : totales.total_w_send
                            : totales.total_w_send

                        const {
                            cod_pos: zip_code,
                            colonia,
                            municipio,
                            ciudad,
                            estado,
                            calle,
                            referencias,
                            num_ex: nex,
                            num_in: nin,
                            contact_phone: phone,
                        } = dirData
                        const {
                            nombre: name,
                            apellidos: apps,
                            mail: email,
                        } = personalData
                        const { coordinates } = dataSend
                        const params = {
                            payment: {
                                device_security_id: deviceDataId,
                                token: data.data.id,
                                amount: parseFloat(total).toFixed(2),
                                mensuality: mensuality.enabled === 'SI',
                                secure: parseInt(total) > 5000,
                            },
                            address: {
                                zip_code,
                                colonia,
                                calle,
                                ciudad,
                                estado,
                                referencias,
                                municipio,
                                nex: nex || 'S/N',
                                nin: nin || 'S/N',
                            },
                            client: {
                                name,
                                apps,
                                email,
                                phone,
                                gender: personalData.gender,
                                age: personalData.age
                            },
                            details: {
                                products: totales.products,
                                saldo: totales.saldo,
                                meses: totales.months,
                                envio: totales.envio,
                            },
                            utm: location.search || '',
                            prospectId
                        }
                        if (coordinates) params.coordinates = coordinates
                        if (orderFail) params.payment.order = orderFail
                        if (portInMsisdn) params.details.portInMsisdn = portInMsisdn

                        axios
                            .post('/clients/buys/website.buy', params)
                            .then(({ data }) => {
                                const { status, transactionId, message } = data
                                if (status !== 200) {
                                    setInfoPagoErrors({
                                        status_pay: true,
                                        msg_pay: message,
                                    })
                                    dispatch(Actions.BUY_PROCESS_END())
                                } else {
                                    history.push(
                                        '/recipient?id=' +
                                        transactionId +
                                        '&ecommerce=1'
                                    )
                                }
                            })
                            .catch(() => {
                                dispatch(Actions.BUY_PROCESS_END())
                                setInfoPagoErrors({
                                    status_pay: true,
                                    msg_pay:
                                        'Hubo un problema al contactar con el servidor, intenta nuevamente.',
                                })
                            })
                    },
                    (error) => {
                        if (error.data.error_code === 1018) {
                            setInfoPagoErrors({
                                status_pay: true,
                                msg_pay: 'Error desconocido',
                            })
                        } else if (error.data.error_code === 2005) {
                            setInfoPagoErrors({
                                status_pay: true,
                                msg_pay: 'Tarjeta vencida',
                            })
                        } else if (error.status === 400) {
                            setInfoPagoErrors({
                                status_pay: true,
                                msg_pay:
                                    'Error, verifica los datos de tu tarjeta.',
                            })
                        } else {
                            setInfoPagoErrors({
                                status_pay: true,
                                msg_pay: 'Error de conexión, intente de nuevo.',
                            })
                        }
                        dispatch(Actions.BUY_PROCESS_END())
                    }
                )
                break
            default:
                break
        }
    }

    const handleAncla = () => {
        const el = document.getElementById('steps-to-buy')
        const y = el.getBoundingClientRect().top
        window.scrollTo(0, y + 80)
    }
    const handleBack = () => {
        if (activeStep === 1 && option !== 1) return
        if (activeStep === steps.length - 1 && Accept) {
            setAccept(false)
            return
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleReset = () => {
        setActiveStep(0)
    }

    const getProps = (num) => {
        switch (num) {
            case 0:
                return {
                    personalData,
                    setPersonalData,
                    carriers,
                    setPersonalDataErrors,
                    personalDataErrors,
                    option: planOption,
                }
            case 1:
                return {
                    alertData,
                    setAlertData,
                    idData,
                    setIdData,
                    loading,
                    percent,
                }
            case 2:
                return {
                    dirData,
                    setDirData,
                    dirDataErrors,
                    setDirDataErrors,
                    sameDir,
                    setSameDir,
                    matches,
                    option,
                }
            default:
                return {
                    infoPago,
                    setInfoPago,
                    infoPagoErrors,
                    setInfoPagoErrors,
                }
        }
    }
    const getProperties = () => {
        switch (activeStep) {
            case 0:
                return {
                    personalData,
                    setPersonalData,
                    carriers,
                    setPersonalDataErrors,
                    personalDataErrors,
                    alertData,
                    setAlertData,
                    idData,
                    setIdData,
                    option: option,
                }
            case 1:
                return {
                    dirData,
                    setDirData,
                    dirDataErrors,
                    setDirDataErrors,
                }
            default:
                return {
                    infoPago,
                    setInfoPago,
                    infoPagoErrors,
                    setInfoPagoErrors,
                    Accept,
                    option,
                }
        }
    }

    const [freeShipping, setFreeShipping] = useState(false)
    const [portInMsisdn, setPortInMsisdn] = useState('')

    const productResumeData = useSelector(s => s.productResumeData)
    const [showPortInInput, setShowPortInInput] = useState(false)
    const [cpInfo, setCpInfo] = useState([])
    const [checkFree, setCheckFree] = useState(false)

    return (
        <PayGateContext.Provider
            value={{
                freeShipping,
                setFreeShipping,
                portInMsisdn,
                setPortInMsisdn,
                dirData,
                productResumeData,
                showPortInInput,
                setShowPortInInput,
                cpInfo,
                setCpInfo,
                checkFree,
                setCheckFree
            }}
        >
            {alertData.show ? (
                <div style={{ position: 'fixed', bottom: '5px', zIndex: '3' }}>
                    <Alert
                        variant="outlined"
                        style={{
                            backgroundColor: '#fefefe',
                            textAlign: 'center',
                        }}
                        severity={alertData.type}
                        onClose={() => {
                            setAlertData({
                                ...alertData,
                                show: false,
                            })
                        }}
                    >
                        <AlertTitle> {alertData.title} </AlertTitle>
                        {alertData.text}
                    </Alert>
                </div>
            ) : (
                ''
            )}
            {matches ? (
                <div
                    className={classes.root}
                    style={{
                        border: '.5px solid rgba(0,0,0,0.2)',
                        borderRadius: 5,
                    }}
                    id={'steps-to-buy'}
                >
                    <Stepper
                        alternativeLabel
                        activeStep={activeStep}
                        connector={<ColorlibConnector />}
                    >
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel
                                    classes={{
                                        label: styles.label_match,
                                        active: styles.label_active,
                                    }}
                                    StepIconComponent={ColorlibStepIcon}
                                >
                                    {planOption === 5 && index === 0
                                        ? 'Cobertura'
                                        : label}
                                </StepLabel>
                                {index === 0 &&
                                    option !== 1 &&
                                    option !== 6 &&
                                    option !== 7 &&
                                    option !== 5 ? (
                                    <FormHelperText
                                        style={{ textAlign: 'center' }}
                                    ></FormHelperText>
                                ) : (
                                    ''
                                )}
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>
                                    Pasos completados
                                </Typography>
                                <Button
                                    onClick={handleReset}
                                    className={classes.button}
                                >
                                    Reset
                                </Button>
                            </div>
                        ) : (
                            <div>
                                {loaded ? (
                                    <>
                                        {activeStep === 0 ? (
                                            <Compatibilidad
                                                planDetails={planDetails}
                                                option={planOption}
                                                next={handleNext}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        {activeStep === 1 ? (
                                            <>
                                                <PersonalData
                                                    {...getProps(0)}
                                                />
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {activeStep === 2 ? (
                                            <DirData {...getProps(2)} />
                                        ) : (
                                            ''
                                        )}
                                        {activeStep === 3 ? (
                                            <Payment {...getProperties()} />
                                        ) : (
                                            ''
                                        )}

                                        {activeStep !== 3 && (
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    margin: 10,
                                                }}
                                            >
                                                <Typography
                                                    variant={'caption'}
                                                    align={'center'}
                                                >
                                                    Te informamos que los datos
                                                    que nos proporcionas para
                                                    contratar nuestros servicios
                                                    están protegidos por la Ley
                                                    Federal de Protección de
                                                    Datos Personales en posesión
                                                    de Particulares, para más
                                                    información consulta nuestro
                                                    aviso de privacidad en:{' '}
                                                    <a href="https://inxel.mx/aviso-privacidad">
                                                        https://inxel.mx/aviso-privacidad
                                                    </a>
                                                </Typography>
                                            </Grid>
                                        )}

                                        {activeStep !== 0 ? (
                                            <Grid container justify={'center'}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    style={{
                                                        marginTop: '35px',
                                                        marginBottom: '30px',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    {loading ? (
                                                        <>
                                                            <Button
                                                                disabled={
                                                                    activeStep ===
                                                                    0 ||
                                                                    loading
                                                                }
                                                                // onClick={handleBack}
                                                                className={
                                                                    classes.button
                                                                }
                                                            >
                                                                Volver
                                                            </Button>
                                                            <Button
                                                                disabled={
                                                                    loading
                                                                }
                                                                variant="contained"
                                                                //color={''}
                                                                startIcon={
                                                                    <CircularProgress
                                                                        color={
                                                                            'secondary'
                                                                        }
                                                                    />
                                                                }
                                                                //  onClick={handleNext}
                                                                className={
                                                                    classes.button
                                                                }
                                                                style={{ background: `linear-gradient(${secondary}, ${secondary_dark})`, color: 'white' }}
                                                            >
                                                                {activeStep ===
                                                                    steps.length - 1
                                                                    ? 'Procesando'
                                                                    : 'Siguiente'}
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Button
                                                                disabled={
                                                                    activeStep ===
                                                                    0 ||
                                                                    loading
                                                                }
                                                                onClick={
                                                                    handleBack
                                                                }
                                                                className={
                                                                    classes.button
                                                                }
                                                            >
                                                                Volver
                                                            </Button>
                                                            <Button
                                                                //  disabled={loading}
                                                                variant="contained"
                                                                style={{ background: `linear-gradient(${secondary}, ${secondary_dark})`, color: 'white' }}
                                                                //color={''}
                                                                onClick={
                                                                    handleNext
                                                                }
                                                                className={
                                                                    classes.button
                                                                }
                                                            >
                                                                {activeStep ===
                                                                    steps.length - 1
                                                                    ? Accept
                                                                        ? 'Comprar'
                                                                        : 'Aceptar'
                                                                    : 'Siguiente'}
                                                            </Button>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : (
                                    <div className={styles.skeleton_dad}>
                                        <div
                                            className={
                                                styles.skeleton_container
                                            }
                                        >
                                            <Skeleton />
                                            <Skeleton animation={false} />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation={false} />
                                            <Skeleton animation="wave" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className={classes.root} id={'steps-to-buy'}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel
                                    classes={{
                                        label: styles.label_nomatch,
                                        active: styles.label_active,
                                    }}
                                    StepIconComponent={ColorlibStepIcon}
                                >
                                    {option === 5 && index === 0
                                        ? 'Cobertura'
                                        : label}
                                </StepLabel>
                                <StepContent>
                                    {loaded ? (
                                        <>
                                            {activeStep === 0 ? (
                                                <Compatibilidad
                                                    planDetails={planDetails}
                                                    option={planOption}
                                                    next={handleNext}
                                                />
                                            ) : (
                                                ''
                                            )}
                                            {activeStep === 1 ? (
                                                <>
                                                    <PersonalData
                                                        {...getProps(0)}
                                                    />
                                                </>
                                            ) : (
                                                ''
                                            )}

                                            {activeStep === 2 ? (
                                                <DirData {...getProps(2)} />
                                            ) : (
                                                ''
                                            )}
                                            {activeStep === 3 ? (
                                                <Payment {...getProperties()} />
                                            ) : (
                                                ''
                                            )}

                                            {activeStep !== 3 && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        margin: 10,
                                                    }}
                                                >
                                                    <Typography
                                                        variant={'caption'}
                                                        align={'center'}
                                                    >
                                                        Te informamos que los
                                                        datos que nos
                                                        proporcionas para
                                                        contratar nuestros
                                                        servicios están
                                                        protegidos por la Ley
                                                        Federal de Protección de
                                                        Datos Personales en
                                                        posesión de
                                                        Particulares, para más
                                                        información consulta
                                                        nuestro aviso de
                                                        privacidad en:{' '}
                                                        <a href="https://inxel.mx/aviso-privacidad">
                                                            https://inxel.mx/aviso-privacidad
                                                        </a>
                                                    </Typography>
                                                </Grid>
                                            )}

                                            {activeStep === 0 ? (
                                                ''
                                            ) : (
                                                <div
                                                    style={{
                                                        marginTop: '20px',
                                                    }}
                                                >
                                                    <div>
                                                        {loading ? (
                                                            <>
                                                                <Button
                                                                    disabled={
                                                                        activeStep ===
                                                                        0 ||
                                                                        loading
                                                                    }
                                                                    className={
                                                                        classes.button
                                                                    }
                                                                >
                                                                    Volver
                                                                </Button>
                                                                <Button
                                                                    disabled={
                                                                        loading
                                                                    }
                                                                    variant="contained"
                                                                    //color={''}
                                                                    startIcon={
                                                                        <CircularProgress
                                                                            color={
                                                                                'secondary'
                                                                            }
                                                                        />
                                                                    }
                                                                    //  onClick={handleNext}
                                                                    className={
                                                                        classes.button
                                                                    }
                                                                    style={{ background: `linear-gradient(${secondary}, ${secondary_dark})`, color: 'white' }}
                                                                >
                                                                    {activeStep ===
                                                                        steps.length -
                                                                        1
                                                                        ? 'Procesando'
                                                                        : 'Siguiente'}
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Button
                                                                    disabled={
                                                                        activeStep ===
                                                                        0 ||
                                                                        loading
                                                                    }
                                                                    onClick={
                                                                        handleBack
                                                                    }
                                                                    className={
                                                                        classes.button
                                                                    }
                                                                >
                                                                    Volver
                                                                </Button>
                                                                <Button
                                                                    //  disabled={loading}
                                                                    variant="contained"
                                                                    style={{ background: `linear-gradient(${secondary}, ${secondary_dark})`, color: 'white' }}
                                                                    //color={''}
                                                                    onClick={
                                                                        handleNext
                                                                    }
                                                                    className={
                                                                        classes.button
                                                                    }
                                                                >
                                                                    {activeStep ===
                                                                        steps.length -
                                                                        1
                                                                        ? Accept
                                                                            ? 'Comprar'
                                                                            : 'Aceptar'
                                                                        : 'Siguiente'}
                                                                </Button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className={styles.skeleton_dad}>
                                            <div
                                                className={
                                                    styles.skeleton_container
                                                }
                                            >
                                                <Skeleton />
                                                <Skeleton animation={false} />
                                                <Skeleton animation="wave" />
                                                <Skeleton animation={false} />
                                                <Skeleton animation="wave" />
                                            </div>
                                        </div>
                                    )}
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </div>
            )}
        </PayGateContext.Provider>
    )
}
