import React from "react";
import {Container, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {XEL_COLORS, XEL_FONT} from "../../../../constant";
import {XelIconPhoneHand} from "../../../../assets/logos/svg";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Plan from "../../../ui/Plan";
import {makeStyles} from "@material-ui/core/styles";

const useStyle = makeStyles({
    title: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 40
    },

});
const data1 = {
    name: 'Xel 15.10',
    hotspot: false,
    primera_desc: '10 GB para navegar y para redes sociales',
    primera_real_desc: '(5GB para navegar y para RRSS en roaming internacional)',
    segunda_desc: '1,500 minutos y 500 SMS',
    segunda_real_desc: ' (750 minutos y 250 SMS en roaming internacional)',
    price:'$140.00',
    period:'por 15 días'
}
const data2 = {
    name: 'Xel 7.5',
    hotspot: false,
    primera_desc: '5 GB para navegar y para redes sociales  ',
    primera_real_desc: '(5GB para navegar y para RRSS en roaming internacional)',
    segunda_desc: '1,500 minutos y 500 SMS',
    segunda_real_desc: '  (750 minutos y 250 SMS en roaming internacional)',
    price:'$60.00',
    period:'por 7 días'
}
const data3 = {
    name: 'Xel 3.2',
    hotspot: false,
    primera_desc: '2 GB para navegar y para redes sociales  ',
    primera_real_desc: '(2GB para navegar y para RRSS en roaming internacional)',
    segunda_desc: '1,500 minutos y 500 SMS',
    segunda_real_desc: '  (750 minutos y 250 SMS en roaming internacional)',
    price:'$40.00',
    period:'por 3 días'
}
export default function Prepago() {
    const style = useStyle();
    const Titulo = () => {
        return <Container style={{marginBottom: 50,marginTop:60}}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography classes={{root: style.title}} align={"center"}>
                        No te quedes sin hablar
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    }
    return <>
        <Titulo/>
        <Container>
            <Grid container justify={'center'}>

                        <Plan data={data1}/>
                        <Plan data={data2}/>
                        <Plan data={data3}/>

            </Grid>
        </Container>
    </>
}
