import React, {useMemo, useState} from 'react';
import {Switch, Route, Redirect, useLocation, useParams} from 'react-router-dom';
import Inicio from "./components/pages/Inicio";
// import _Inicio from "./components/pages/_Inicio";
import Personas from "./components/pages/Personas";
import Empresas from './components/pages/Empresas';
import Alianzas from "./components/pages/Alianzas";
import Comprar from "./components/pages/Comprar";
import Privacidad from "./components/pages/Legales/Privacidad";
import Terminos from "./components/pages/Legales/Terminos";
import Derechos from "./components/pages/Legales/Derechos";
import Contrato from "./components/pages/Legales/Contrato";
import Login from "./components/pages/Login";
import AppXel from "./components/pages/AppXel";
import Recargar from "./components/pages/Recargas";
import ZonaVenta from "./components/pages/ZonaVenta";
import Prox from "./components/pages/Proximamente";
import Nosotros from "./components/pages/Nosotros";
import About from "./components/pages/About";
import Preguntas from "./components/pages/Preguntas";
import Activaciones from "./components/pages/Pagos/Activaciones";
import Quejas from "./components/pages/Quejas";
import DetalleCelular from "./components/pages/DetalleCelular";
import Equipos from "./components/pages/Equipos";
import DetallePack from "./components/pages/DetallePack";
import Packs from "./components/pages/Packs";
import Compras from "./components/pages/Compras";
import Mifi from "./components/pages/Mifi";
import CheckImeiPage from "./components/pages/CheckImei";
import RedirectS from './components/pages/Redirect';
import AlianzaSells from "./components/pages/AlianzaSells";
import Alianza from "./components/pages/Alianza";
import Proveedores from "./components/pages/Proveedores";
import Landing from "./components/pages/Landing";
import Confirm from "./components/pages/Pays/Confirm";
import Recipient from "./components/pages/Pays/Recipient";
import LandingBuenFin from './components/pages/LandingBuenFin';
import Preplan from './components/pages/Preplan';
import CoberturaPage from './components/pages/CoberturaPage'

export default function Routes() {
    return <>
        <Switch>
            {
                //   <Route path={'/reportes'} exact>                 <Reportes/>            </Route>
            }
            <Route path={'/r/:slash'} exact>
                <RedirectS/>
            </Route>
            <Route path={'/detalle/pack/:idpack'} exact>
                <DetallePack/>
            </Route>
            <Route path={'/detalle/celular/:idcel'} exact>
                <DetalleCelular/>
            </Route>
            <Route path={'/packs'} exact>
                <Packs/>
            </Route>
            <Route path={'/equipos'} exact>
                <Equipos/>
            </Route>
            <Route path={'/imei'} exact>
                <Redirect to={'verifica/compatibilidad'}/>
            </Route>
            <Route path={'/quejas-sugerencias'} exact>
                <Quejas/>
            </Route>
            {
                /*   <Route path={'/tarifas'} exact>
                <TablaIFT/>
            </Route>*/
            }
            <Route path={'/pagos/activaciones/:id'} exact>
                <Activaciones/>
            </Route>
            <Route path={'/proximamente'} exact>
                <Prox/>
            </Route>
            <Route path={'/comprar/plan/:idp'} exact>
                <Comprar option={1}/>
            </Route>
            <Route path={'/comprar/equipo-plan/:idp/:ide'} exact>
                <Comprar option={2}/>
            </Route>
            <Route path={'/comprar/pack/:idpack'} exact>
                <Comprar option={3}/>
            </Route>
            <Route path={'/comprar/mifi/:idmifi'} exact>
                <Comprar option={4}/>
            </Route>
            <Route path={'/comprar/hbb/:idhbb'} exact>
                <Comprar option={5}/>
            </Route>
            <Route path={'/comprar/alianza/:idpall'} exact>
                <Comprar option={6} hideNav={true}/>
            </Route>
            <Route path={'/comprar/preplan/:idpre'} exact>
                <Comprar option={7}/>
            </Route>
            <Route path={'/compra'} exact>
                <Compras/>
            </Route>
            <Route path={'/proveedores'} exact>
                <Proveedores/>
            </Route>
            <Route path={'/preguntas-frecuentes'} exact>
                <Preguntas/>
            </Route>
            <Route path={'/about'} exact>
                <About/>
            </Route>
            <Route path={'/nosotros'} exact>
                <Nosotros/>
            </Route>
            <Route path={'/zona-venta'} exact>
                <ZonaVenta/>
            </Route>
            <Route path={'/recarga'} exact>
                <Recargar/>
            </Route>
            <Route path={'/preplan'} exact>
		<Preplan />
            </Route>
            <Route path={'/app'} exact>
                <AppXel/>
            </Route>
            <Route path={'/login'} exact>
                <Login dir={1}/>
            </Route>
            <Route path={'/contrato-usuario'} exact>
                <Contrato/>
            </Route>
            <Route path={'/derechos-usuario'} exact>
                <Derechos/>
            </Route>
            <Route path={'/terminos-y-condiciones'} exact>
                <Terminos/>
            </Route>
            <Route path={'/aviso-privacidad'} exact>
                <Privacidad/>
            </Route>
            <Route path={'/alianza/:name'} exact>
                <AlianzaSells hideNav={true}/>
            </Route>
            <Route path={'/alianzas/:name'} exact>
                <Alianza/>
            </Route>
            <Route path={'/alianzas'} exact>
                <Alianzas/>
            </Route>
            <Route path={'/empresas'} exact>
                <Empresas/>
            </Route>
            <Route path={'/verifica/compatibilidad'} exact>
                <CheckImeiPage/>
            </Route>

            <Route path={'/landig/:id'}>
                <RedirectTo to={'/landing/:id'}/>
            </Route>
            <Route path={'/landing/:id'} exact>
                <Landing/>
            </Route>
	    <Route path={'/landings/fin/veloz'} exact>
                <LandingBuenFin/>
            </Route>
            <Route path={'/confirm'}>
                <Confirm/>
            </Route>
            <Route path={'/recipient'}>
                <Recipient/>
            </Route>
            <Route path={'/'} exact>
                <Inicio mobile />
            </Route>
            <Route path={'/internet-fijo'} exact>
                <Inicio modem />
            </Route>
            <Route path={'/internet-movil'} exact>
                <Inicio mobile modem />
            </Route>
	    <Route path={'/cobertura-hbb'}>
		<CoberturaPage />
	    </Route>

        </Switch>

    </>
}


const RedirectTo = ({to}) => {

    const params = useParams();


    let url = useMemo(() => {
        let toSplit = to.split('/');
        let fullUrl = "";
        for (let i = 0; i < toSplit.length; i++) {

            if (toSplit[i].charAt(0) === ":") {
                let param = toSplit[i].split(':')[1];
                fullUrl += params[param] + '/';
            } else
                fullUrl += toSplit[i] + '/';

        }

        console.log('Full urkl', fullUrl);
        return fullUrl;
    }, [to]);

    //let url = to + '/' + params.join('/');


    return <Redirect to={url}/>

}
/*    <Switch>
                    <Route path={'/comprar/plan/:id'} exact>
                        <BuyDiag open={true} idplan={idPlan} option={1} />
                    </Route>
                    <Route path={'/comprar/plan/:id/equipo/:ide'} exact>
                        <BuyDiag open={true} idplan={idPlan} option={2} />
                    </Route>
                </Switch>*/
