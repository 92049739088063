import React, {useEffect, useState} from "react";
import {Button, Chip, Container, Grid, Typography} from "@material-ui/core";
import AddNumber from "../AddNumber";
import Axios from "../../../../../../instances/axios";
import {useDispatch, useSelector} from "react-redux";
import {LOG_IN} from "../../../../../../actions";

const Frecuents = ({handleBack}) => {
    const axios = Axios();
    const [frecuents, setFrecuents] = useState([]);
    const session = useSelector(state => state.login);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (session.logged) {
            setFrecuents(session.frecuents);
        }
    }, [session]);

    const onDelete = (dn) => {
        if (window.confirm("¿Está seguro de eliminar este número?")) {
            setLoading(true);
            axios.delete(`/clients/frecuents/${dn}`)
                .then(res => {
                    console.log("Eliminado");
                    setLoading(false);
                    let nfrec = frecuents.filter(f => f.msisdn.toString() !== dn.toString());
                    //setFrecuents(nfrec);
                    let nsession = {...session, frecuents: nfrec};
                    dispatch(LOG_IN(nsession))
                    //setFrecuents(frecuents.filter(f => f !== dn));
                })
                .catch(err => {
                    setLoading(false);
                    console.log(err);
                })
        }
    };
    const addFrecuent = (dn) => {
        setFrecuents([...frecuents, dn]);
    };
    return <Container>
        <Grid container spacing={2} justify={"center"}>
            <Grid item xs={12}>
                <Typography variant={"h6"} align={"center"}>
                    Numeros Frecuentes
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} style={{backgroundColor: "#EEEEEE"}}>
                <AddNumber addFrecuent={addFrecuent} justAdd={true} msisdns={[]} addData={() => {
                }}/>
            </Grid>
            <Grid item xs={12}>
                <hr/>
            </Grid>
            <Grid item xs={12} style={{backgroundColor: "#EEEEEE"}}>
                {
                    loading && <Typography variant={"body2"} align={"center"}>
                        Cargando...
                    </Typography>
                }
                {
                    !loading && frecuents.map((f, i) => <Chip style={{margin: "1%"}} color={"primary"} key={i}
                                                              label={f.msisdn}
                                                              onDelete={() => onDelete(f.msisdn)}/>)
                }
            </Grid>
            <Grid item xs={12} style={{display: "flex", justifyContent: 'flex-end'}}>
                <Button onClick={handleBack} variant={"contained"} style={{color: "#FEFEFE"}}
                        color={"secondary"}>Volver</Button>
            </Grid>
        </Grid>
    </Container>
}

export default Frecuents;
