import NavBar from "../../../ui/NavBar";
import {Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Footer from "../../../ui/Footer";
import React, {useEffect} from "react";
import {useLegalStyle} from "../Privacidad";
import {Cuestion} from "../../Preguntas";


const Title = ({children}) => {
    useEffect(() => {
        //  console.log(props);
    });
    const style = useLegalStyle();
    return <Grid item xs={12}>
        <Typography className={style.title} align={'center'}>
            {children}
        </Typography>
    </Grid>
}

const Section = ({title, body}) => {
    const style = useLegalStyle();
    return <Grid item xs={12}>
        <Typography className={style.subtitle}>
            {title}
        </Typography>
        <Typography className={style.body} variant="body2">
            {body}
        </Typography>
    </Grid>
}

const Header = ({title, body}) => {
    const style = useLegalStyle();
    return <Grid item xs={12} style={{marginBottom: '1%'}}>
        <Typography className={style.title} align={'center'}>
            {title}
        </Typography>
        <Typography className={style.body} variant="body2">
            {body}
        </Typography>
    </Grid>
}

export default function Terminos() {
    const style = useLegalStyle();
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Términos y Condiciones";
    }, [])
    return <>
        <NavBar/>
        <Container>
            <Grid container justify={'center'} spacing={2} style={{marginBottom: '2%'}}>

                <Title>
                    TÉRMINOS Y CONDICIONES DEL SITIO WEB.
                </Title>
                <Grid item xs={12}>
                    <Typography className={style.body} variant="body2">
                        ABSOLUTETECK, S.A. DE C.V., en adelante denominado como el “Concesionario”.
                    </Typography>
                </Grid>
                <Section title={' Aceptación:'}
                         body={'  El portal INXEL (www.inxel.mx) se ofrece como un servicio a nuestros clientes de telefonía\n' +
                             '                        móvil. Los presentes términos y condiciones de uso rigen el uso que hagas del portal INXEL,\n' +
                             '                        incluidas todas las características y funcionalidades, aplicaciones, contenidos y el software\n' +
                             '                        asociado.\n' +
                             '\n' +
                             '                        Al utilizar el portal INXEL acuerdas y te comprometes a cumplir los presentes términos y\n' +
                             '                        condiciones de uso, si no estás de acuerdo con estos términos y condiciones de uso, no deberías\n' +
                             '                        utilizar el servicio del portal INXEL, incluidas las interfaces de usuario.\n' +
                             '\n' +
                             '                        PRIVACIDAD: Toda la información personal de identificación presentada a través del portal INXEL\n' +
                             '                        está sujeta a nuestro Aviso de Privacidad cuyos términos se pueden consultar en este sitio web.'}/>
                <Grid item xs={12}>
                    <Typography className={style.title} align={'center'}>
                        PROPIEDAD INTELECTUAL
                    </Typography>
                </Grid>
                <Section title={'Derechos de autor:  '}
                         body={'El portal INXEL, incluidos todos los contenidos del sitio web, las aplicaciones de usuario, textos, gráficos, logos, diseños, fotografías, iconos de botones, imágenes, clips de audio y video, descargas digitales, compilaciones de datos y el software son propiedad del “CONCESIONARIO” o de sus licenciantes, y están protegidos por la Ley de la Propiedad Industrial, Ley Federal del Derecho de Autor y otras leyes o tratados de propiedad intelectual internacionales. La compilación de todo el contenido y cualquier software u otro material provisto por “EL CONCESIONARIO” en nuestro sitio web o aplicaciones o en relación con el portal INXEL son de exclusiva propiedad del “CONCESIONARIO” y sus licenciantes y están protegidos por la Ley de la Propiedad Industrial y por las disposiciones de los tratados internacionales. \n' +
                             '\n' +
                             'El contenido no se podrá reproducir ni utilizar sin la autorización expresa por escrito del “CONCESIONARIO” o de sus licenciantes. “EL CONCESIONARIO” se reserva el derecho de dar de baja tu servicio si considera que has realizado cualquier copia o uso no autorizado del contenido protegido por derechos de autor en incumplimiento de los derechos del “CONCESIONARIO” o de sus licenciantes.'}/>
                <Section title={'Marcas:'}
                         body={'Las marcas de servicio e imágenes de la marca de INXEL sólo se podrán utilizar y reproducir con previa autorización de su titular, y no se podrán utilizar en relación con ningún producto o servicio que no esté asociado a INXEL, de ninguna manera que pueda generar confusión entre los clientes, que afecte los derechos del “CONCESIONARIO” o que afecte su imagen o reputación.  '}/>
                <Section title={'Uso de este sitio:'}
                         body={'El propósito del portal INXEL y su contenido es únicamente para uso personal y sin fines comerciales. Podrás bajar o copiar el contenido, así como cualquier otro material que se pueda bajar mostrado en el portal INXEL para tu uso personal y con fines únicamente informativos. No se transfiere derecho, título o interés alguno sobre cualquier material o software bajado como resultado de dicho copiado u obtención de material. No podrás reproducir (excepto por lo dispuesto anteriormente), publicar, transmitir, distribuir, mostrar, modificar, crear obras derivadas, vender o participar en venta alguna o explotar de cualquier manera, en parte o completamente, el contenido, el sitio web o cualquier software relacionado.  \n' +
                             '\n' +
                             'Todo el software usado en el portal INXEL es propiedad del “CONCESIONARIO” o sus proveedores y protegidos por leyes de propiedad intelectual mexicanas, norteamericanas e Internacionales. El contenido y software en el portal INXEL deberá ser usado sólo como fuente de información. Cualquier otro uso, incluyendo la reproducción, modificación, distribución, transmisión, republicación o desplegado del contenido del portal INXEL está estrictamente prohibido.'}/>

                <Header title={'INDEMNIZACIÓN '}
                        body={'Estás de acuerdo en defender, indemnizar y sacar en paz y a salvo a “EL CONCESIONARIO” de cualquier y en contra de toda demanda, incluyendo gastos y costas derivado del mal uso que pudieras hacer del portal INXEL.'}/>
                <Header title={'SERVICIO AL CLIENTE '}
                        body={'Si necesitas asistencia con tu servicio del portal INXEL, puedes contactar al Centro de Atención a Suscriptores de INXEL en cualquier momento.'}/>
                <Header title={'VÍNCULOS Y PÁGINAS'}
                        body={'Algunos de los hipervínculos del portal INXEL pueden llevar a otros sitios web u otros contenidos que no son controlados por “EL CONCESIONARIO” ni tienen ninguna relación con éste. Reconoces y acuerdas que “EL CONCESIONARIO” no es responsable por los actos de terceros, los productos o contenidos de los sitios web o el funcionamiento de las aplicaciones o dispositivos y no otorga ninguna garantía en tal sentido. Estos sitios web vinculados tienen Aviso de Privacidad y términos y condiciones de uso separados e independientes que recomendamos que leas detenidamente.  '}/>
                <Header title={'GENERAL'}
                        body={'Estos términos y condiciones de uso representan el acuerdo único y total entre las partes y reemplazan cualquier otro acuerdo o garantía anterior entre ellos con relación al uso del portal INXEL.  \n' +
                            '\n' +
                            'En caso de que “EL CONCESIONARIO” no actúe según estos términos y condiciones de uso, en el momento de algún incumplimiento, no traerá aparejada renuncia alguna y no limitará los derechos de “EL CONCESIONARIO” con respecto a dicho incumplimiento o cualquier incumplimiento subsecuente.  '}/>
                <Header title={'MODIFICACIÓN DE TARIFAS'}
                        body={'Estimado usuario te informamos que inXel podrá cambiar sus tarifas, siempre\n' +
                            'y cuando no sea de manera arbitraria, siempre dará aviso previo al usuario final o contratante, de cualquier cambio en los términos y condiciones originalmente comprados.\n' +
                            '\n' +
                            'En caso de que exista un cambio de tarifas en la modalidad de contratación\n' +
                            'con vigencia indefinida, EL CLIENTE no esté de acuerdo, podrá cancelar el contrato en el supuesto de que la modificación de tarifas sea en su perjuicio.\n' +
                            '\n' +
                            'Las modificaciones de tarifas derivadas de disposiciones fiscales aplicables,\n' +
                            'no se considerarán cambios de tarifas.\n' +
                            '\n' +
                            'EL CLIENTE en cualquier momento podrá revisar las tarifas inscritas en\n' +
                            'el Registro Público de Concesiones en el sitio oficial del IFT donde podrá observar el precio de la tarifa y podrá constatar también que es lo que contiene cada tarifa.\n' +
                            '\n' +
                            'El usuario final o contratante que no esté de acuerdo con el cambio en\n' +
                            'los términos y condiciones podrá exigir el cumplimiento de las condiciones establecidas, siempre y cuando se haya tenido un contrato a un plazo determinado y en dado caso podrá optar por cancelar el servicio sin penalización alguna, salvo en los casos en que tengan servicios adicionales contratados.\n' +
                            '\n' +
                            'A medida que introduzcamos nuevas funciones en nuestros productos, nuestros\n' +
                            'precios podrían sufrir cambios, asimismo podemos implementar modificaciones en los planes y precios para responder a cambios en el mercado, como cambios en los impuestos o la inflación.\n' +
                            '\n' +
                            'Para ser justos con todos nuestros miembros, los precios de los planes\n' +
                            'nuevos se aplican a todas las personas una vez que se anuncia una actualización en los precios.'}/>


                <Header title={'TERMINACIÓN'}
                        body={'Los términos y condiciones de uso estarán vigentes al menos que “EL CONCESIONARIO” terminé los mismos. Podrás terminar estos términos y condiciones en cualquier momento, no usando más el portal INXEL. “EL CONCESIONARIO” también podrá terminar los términos y condiciones de uso en cualquier momento y lo hará inmediatamente sin previo aviso, por lo que podrá negarte el acceso al portal INXEL, si “EL CONCESIONARIO”, a su entera discreción, considera que no cumpliste con cualquier término o norma de estos términos y condiciones de uso. Una vez terminados los términos y condiciones de uso ya sea por ti o por “EL CONCESIONARIO”, deberás destruir inmediatamente todos los materiales bajados u obtenidos del portal INXEL, así como todas las copias de dichos materiales, ya sea que hayan sido hechas bajo estos términos y condiciones de uso o no.'}/>
                <Header title={'POLÍTICAS DE FUNCIONAMIENTO'} body={''}/>
                <Section title={'Política de envíos, entrega y aceptación de pedidos: '}
                         body={'INXEL no hace envíos fuera de la República Mexicana. \n' +
                             '\n' +
                             'El cliente se compromete a proporcionar una dirección válida localizable dentro de la república mexicana donde pueda entregársele el pedido. En caso de que el cliente proporcione una dirección que no sea localizada por la paquetería, cualquier otro costo adicional será cubierto por el cliente mismo. \n' +
                             '\n' +
                             'Todo pedido procesado con productos en existencia y con el pago aplicado será recolectado por paquetería a más tardar el día hábil siguiente (lunes a viernes). \n' +
                             '\n' +
                             'Las entregas en CDMX se harán de uno a cuatro dias hábiles a partir de la fecha de tu compra, para las entregas en el interior de la Republica, se harán de cinco a ocho dias hábiles a partir de tu fecha de compra.  \n' +
                             '\n' +
                             ' \n' +
                             '\n' +
                             'En ventas de temporada pueden existir demoras en envíos, los tiempos de entrega quedan sujetas a los tiempos adicionales de entrega que estipulen las paqueterías. \n' +
                             '\n' +
                             'En el caso de envíos desde zonas fronterizas pueden llegar a demorar más los envíos por cuestiones aduanales. \n' +
                             '\n' +
                             'INXEL no hace envíos ni sábados y domingos. \n' +
                             '\n' +
                             'Todos los envíos y entregas son realizados por terceros. \n' +
                             '\n' +
                             'INXEL no hace envíos con servicio Ocurre. \n' +
                             '\n' +
                             'El cliente paga el costo de fletes de retorno en todos los casos. \n' +
                             '\n' +
                             'El costo del envío no será reembolsado en ninguna circunstancia. \n' +
                             '\n' +
                             'Para el proceso de recepción del Producto el cliente hará constar en la guía que se firma al transportista o en el formato de entrega en almacén, cualquier incidencia en el estado de esta o del embalaje. Todos los daños físicos, producto diferente o diferencias en bultos contra lo solicitado (Caja abierta, golpeada, con sellos violadas, etc.) deberán ser rechazados por el cliente, en caso de recibir el producto dañado deberá realizar la anotación en la guía de embarque o acuse, de no hacer las aclaraciones al momento de su recepción al transportista o mensajería es responsabilidad del cliente las condiciones de aceptación en las que se encuentre el producto.'}/>

                <Section title={'Garantías:'}
                         body={'Todos los productos que vendemos cuentan con garantía original del fabricante por defectos de fabricación tanto en mano de obra como en materiales. Cada fabricante estipula el tiempo de garantía a través de centros de servicio autorizados por la marca, esta información se encuentra en la póliza dentro de la caja del producto. \n' +
                             '\n' +
                             'La garantía puede ser invalidada por cualquier otro defecto que no está clasificado como defecto de fabricación o por causas no atribuibles a los defectos de fabricación. Más información visite consulte el contrato de adhesión publicado en www.inxel.mx'}/>

                <Section title={'Devoluciones: '}
                         body={'Usted podrá solicitar devolver un producto adquirido en INXEL siempre y cuando el empaque original del mismo se encuentre cerrado, el contenido esté completo y en perfecto estado para su venta. Empaques, manuales y accesorios deben ser originales y los sellos no deben haber sido violados; si el producto es parte de un paquete o incluye un regalo, éste debe ser devuelto en las mismas condiciones. Los gastos de envío no son reembolsables.  '}/>
                <Section title={'Cancelaciones '}
                         body={'Un pedido puede ser cancelado total o parcialmente siempre y cuando no haya sido liberado en almacén. \n' +
                             '\n' +
                             'Los pedidos que se encuentran en estado Pendiente podrán ser cancelados por usted en la página web o bien, contactando al área de ventas por teléfono o por correo electrónico. '}/>
                <Section title={'Reembolsos '}
                         body={'Todo reembolso puede tardar hasta 30 días naturales y serán procesados en la forma original de su pago. \n' +
                             '\n' +
                             'Pagos con depósito bancario, transferencia electrónica y Open Pay serán devueltos por transferencia electrónica a la cuenta del titular del pedido, si su decisión es solicitar la devolución de su pago le enviaremos por correo electrónico un formato de devolución el cual deberá firmar donde se detalla la información necesaria para realizar la transferencia bancaria a su cuenta. En caso de no coincidir esta información con los datos del pedido no se podrá hacer el reembolso correspondiente. No se aceptará el detalle de la información necesaria para realizar la transferencia bancaria por correo electrónico, es obligatorio el formato de devolución firmado. \n' +
                             '\n' +
                             'Pagos con OpenPay y tarjeta de crédito o débito serán devueltos a la cuenta de OpenPay en el tiempo que el Banco emisor de su tarjeta estipule, si existe duda alguna de la fecha de acreditación del monto en su cuenta favor de comunicarse con ellos. \n' +
                             '\n' +
                             'Pagos a meses sin intereses con OpenPay serán devueltos en una sola exhibición y en ninguna circunstancia cancela la venta a meses sin intereses en su tarjeta de crédito, el banco emisor continuara cobrando mes con mes la mensualidad hasta que el plazo seleccionado termine. \n' +
                             '\n' +
                             'Intereses sobre devoluciones o reembolsos en ninguna circunstancia serán pagados por INXEL. \n' +
                             '\n' +
                             'Una vez emitido el reembolso le enviaremos un correo electrónico confirmando la devolución. '}/>
		<Section title={''} body={'Para el caso de los preplanes, una vez realizada la compra no se podrá cambiar de preplan y no será posible realizar la devolución del dinero; lo anterior derivado de que el Provedor del servicio primario, cobra ese tipo de servicio en una sola exhibición y no acepta las devoluciones o cambio de servicios por parte de inXel, por lo que el usuario acepta esto al aceptar los términos y condiciones de la compra.'} />
                <Section title={'USO DE APIS ESPECIALES'}
                         body={'Al usar nuestro sitio web y al iniciar el proceso de compra o consulta de cualquiera de los servicios de inXel, podrás aceptar voluntariamente que haremos uso de la API de Facebook para facilitar tu inicio de sesión, eso se tomará como consentimiento expreso para usar dichas APIS.'}/>
                <Section title={'CONFIRMAR ACCESO AVANZADO PARA PUBLIC_PROFILE '}
                         body={'El permiso public_profile permite que las apps lean los campos de perfil público predeterminado en un nodo User. El uso permitido de este permiso es autenticar a los usuarios de la app y ofrecerles una experiencia personalizada en ella. Este permiso se concede automáticamente a todas las apps. También puedes usar este permiso para solicitar estadísticas de análisis con el fin de mejorar tu app y para fines de marketing o publicidad, a través del uso de información agrupada y no identificada o anónima (siempre que esos datos no se puedan volver a identificar), al aceptar el uso de APIS y confirmar el acceso avanzado para public_profile. '}/>

                <Header title={'PROMOCIONES TRIPLE DE GB '}
                        body={"Vigencia de la promoción: Promoción disponible del 10 de septiembre del 2022 hasta 31 de Enero 2023. \n" +
                            "\n" +
                            "  \n" +
                            "\n" +
                            "Validez de la promoción: Solo aplica para usuarios nuevos que porten su línea dentro de los primeros 13 días de haber activado su sim con el plan Xel 5+, no aplica para preplanes.  \n" +
                            "\n" +
                            "  \n" +
                            "\n" +
                            "Beneficios: Se aplicará el triple de Gb 5 + 15, total: 20 Gb.  Con renovación mensual cada 30 días hasta completar un total de 6 meses. Posterior a la vigencia de la promoción el plan ofrecerá su bolsa base de 5 Gb \n" +
                            "\n" +
                            "  \n" +
                            "\n" +
                            "Aplicación de beneficios: Si la portabilidad se realiza el mismo día que se activa la sim, la totalidad de los 20 GB se aplicara en 24 horas hábiles. Si la portabilidad es solicitada del día 2 al día 13 después de la activación de sim los beneficios serán aplicados en la siguiente fecha de renovación del plan Xel 5+ "}/>

		<Grid item xs={12} style={{marginBottom: '1%'}}>
		    <Typography className={style.title} align={'center'}>
			PROMOCIÓN DÍA DEL PADRE
		    </Typography>
		    <div style={{ fontSize: 17, fontFamily: 'Montserrat' }}>
			Vigencia de promoción: <br/> Promoción disponible del 12 de junio al 18 de junio del 2023. <br/>Validez de la promoción:<br/> Solo aplica en portabilidades con clientes nuevos que contraten el plan Xel 5+ en modalidad postpago por medio de la página inXel.<br/> Cobro:<br/> El costo de la primera renta del plan será de $60, posteriormente el costo será de $129 al mes.<br/> Envió:<br/> El costo del envió de sim será de gratis a CDMX envíos foráneos $149.<br/> Consideraciones: <br/> En caso de no cumplir con la portabilidad el monto de $60 no será respetado, por lo cual, en el siguiente mes, el saldo restante será cobrado más la mensualidad del mes corriente.
		    </div>
		</Grid>
                <Header title={'LEY VIGENTE '}
                        body={'Estos términos y condiciones de uso se regirán e interpretarán de conformidad con las leyes de los Estados Unidos Mexicanos. Si alguna de las disposiciones de estos términos y condiciones de uso se declarara nula, ilegal o inaplicable o importará un conflicto con la ley, la validez, legalidad y aplicación de las restantes disposiciones no se verá afectada y continuarán en plena vigencia.  \n' +
                            '\n' +
                            'El uso del portal INXEL constituye la aceptación de nuestros “Términos y Condiciones de Uso” y nuestro “Aviso de Privacidad”.  \n' +
                            '\n' +
                            'Fecha de última actualización de estos Términos y Condiciones: 04/Octubre/2022. '}/>
                <Header title={'POLÍTICA DE USO JUSTO'}
                        body={'Aplica para consumo de Datos Nacional y en Roaming Internacional cursados en una red en EUA y Canadá.\n' +
                            'Al finalizar la Cuota de Datos el Cliente podrá: i) contratar un nuevo Producto, y/o ii) contratar un Paquete Adicional, y/o iii) solicitar que se ejecute otra acción entre las configuradas para su Producto como: suspensión, portal cautivo u otras de similar naturaleza.\n' +
                            'La Cuota de Datos incluye consumos de Roaming Internacional cursados en una red en EUA y Canadá hasta un máximo medido en MB que el Usuario Final puede usar dentro del Ciclo Individual.\n' +
                            'Los 20000 MB (Xel Ilimitado y Xel Ilimitado Plus) incluidos dentro del Consumo Excedente a Velocidad Reducida, aplican para consumo de Datos Nacional a una velocidad máxima de navegación downlink de 512 Kbps.\n' +
                            'Los 50000 MB (Xel Mega Ilimitado) incluidos dentro del Consumo Excedente a Velocidad Reducida, aplican para consumo de Datos Nacional a una velocidad máxima de navegación downlink de 1 Mbps.\n' +
                            'Aplica para consumo de Datos Nacional. Al finalizar el consumo en RRSS disponible, los consumos de este tráfico se descontarán de la Cuota de Datos si se tuvieran aún MB disponibles.'}/>

	    <Grid item xs={12} style={{marginBottom: '1%'}}>
		    <Typography className={style.title} align={'center'}>
			POLÍTICA DE CICLO DE VIDA LÍNEAS INXEL
		    </Typography>
		    <div style={{ fontSize: 17, fontFamily: 'Montserrat' }}>

			Para Absoluteteck, S.A. de C.V., en adelante (“inXel”), es de vital importancia que sus usuarios conozcan las diversas etapas que conforman el ciclo de vida de su línea. Por ello a continuación se explica a detalle cada una de las fases que lo integran, así como el tratamiento que se da a las líneas durante dicho proceso. <br/>
			Está política aplica a todas las líneas que se adquieran en inXel, sin importar el esquema de cobro seleccionado. Así, se entenderá y se tendrán por activadas las líneas en las que el usuario haya recibido e ingresado el chip inXel en su equipo terminal, le haya sido asignado un DN por parte de inXel, y:<br/>
			i) realice la primera llamada saliente o reciba una llamada;<br/>
ii) envíe o reciba un Mensaje de Texto (SMS), o <br/>
iii) navegue en Internet, con datos móviles contenidos en los planes o recargas de inXel, entendiéndose esta fecha como el día 1 (uno) del Ciclo de Vida de la línea. <br/>
<br/>
El Ciclo de Vida de una línea de inXel consta de 3 Fases:<br/>
A. Fase Activo;<br/>
B. Fase de Inactividad, y<br/>
C. Fase de Baja de la línea.<br/>
<br/>
A. Fase Activo.<br/>
En la Fase Activo encontramos:<br/>
1. Líneas nuevas;<br/>
2. Líneas en operación que presenten tráfico entrante o saliente de manera habitual; o, <br/>
3. Líneas que realicen una recarga y/o paguen su plan de manera habitual, es decir, por lo menos una vez al mes.<br/>
La Fase Activo tiene una duración de entre 1 (uno) a 30 (treinta) días naturales dependiendo de la vigencia de la recarga y el esquema de cobro que el usuario tenga contratado. Concluida la vigencia del saldo de recarga o plan inXel, la línea pasará a la Fase de Inactividad.<br/>
<br/>
B. Fase de Inactividad.<br/>
 <br/>
Una vez transcurridos los 30 (treinta) días correspondientes a la Fase Activo, la línea pasará a la Fase de Inactividad por un periodo de 90 (noventa) días naturales que comenzarán a partir del día siguiente en que concluyó la Fase Activo, es decir, el día que concluya la vigencia de la recarga o plan. Se entiende como Fase de Inactividad, cuando la línea no presenta ningún tipo de tráfico entrante o saliente ya sea de voz, datos o SMS.<br/>
En caso de que la línea llegue al final de los 90 (noventa) días naturales, correspondientes a la Fase de Inactividad sin haber generado ningún tipo de tráfico, el sistema realizará la suspensión preventiva de dicha línea bloqueando el tráfico entrante y saliente tanto de voz como datos,notificando por lo menos con 3 días de anticipación al usuario final de que su línea será dada de baja.  <br/>
Finalmente, el sistema pasará las líneas abandonadas a una Fase de Baja de la Línea.<br/>
<br/>
C. Fase de Baja de Línea.<br/>
Aquí se tendrán dos supuestos a saber: <br/>
1. Baja por inactividad de 90 días. <br/>
Una vez que hayan transcurrido los 90 (noventa) días a que se refiere la Fase anterior (Fase de Inactividad), las líneas abandonadas entran a una Fase de Cancelación, es decir, inXel dará de baja y cancelará las líneas que no hayan presentado tráfico de algún tipo durante 90 días y el usuario no podrá recuperar el número o DN, que haya tenido asignado.<br/>
Para este caso en que hayan transcurrido los 90 (noventa) días naturales sin tráfico entrante ni saliente, el usuario habrá perdido la posibilidad de recuperar su línea, aun cuando el usuario realice una recarga o pago de plan; por lo que, inXel estará en posibilidades de volver a reasignar o reciclar, dicho DN.<br/>
2. Cancelación voluntaria por parte de cliente.<br/>
El usuario final tiene el derecho de solicitar voluntariamente y por convenir a sus intereses la cancelación de su línea o el DN que le haya sido asignado, en este caso, el DN cancelado estará en cuarentena, con la finalidad de que en caso de que el usuario final quisiera recuperar su línea, esté en posibilidades de hacerlo, dentro de los primeros 40 (cuarenta) días naturales contados a partir del día siguiente a la que solicite su cancelación.<br/>
Para el caso en que hayan transcurrido los 40 (cuarenta) días naturales después de haber sido cancelada la línea, y no se haya recuperado la misma, el usuario habrá perdido la posibilidad de recuperarla, aun cuando el usuario realice una recarga o pago de plan e inXel estará en posibilidades de volver a reasignar o reciclar, dicho DN.<br/>
		    </div>
		</Grid>

                <Cuestion title={'¿Qué es la Política de Uso Justo?'} isOpen={true}>
                    <Typography style={{fontSize: 15}}>
                        La Política de Uso Justo (PUJ) es una regulación en la velocidad de navegación que se aplica a
                        los planes que cuentan con un producto de navegación “ilimitada”.
                    </Typography>
                </Cuestion>
                <Cuestion title={'¿Cómo funciona?'} isOpen={false}>
                    <Typography style={{fontSize: 15}}>
                        Si cuentas con un plan tarifario que incluye el uso de datos nacionales ilimitados, navegarás
                        con el mayor ancho de banda disponible (en función de la tecnología instalada en la zona de
                        cobertura donde te encuentres y de la concentración de tráfico principalmente), hasta llegar a
                        una cuota de megabytes (MB) o gigabytes (GB) determinada por la oferta comercial del plan
                        tarifario o de datos que tengas contratado.
                    </Typography>
                    <br/>
                    <Typography style={{fontSize: 15}}>
                        Si cuentas con un plan tarifario que incluye el uso de datos nacionales ilimitados, navegarás
                        con el mayor ancho de banda disponible (en función de la tecnología instalada en la zona de
                        cobertura donde te encuentres y de la concentración de tráfico principalmente), hasta llegar a
                        una cuota de megabytes (MB) o gigabytes (GB) determinada por la oferta comercial del plan
                        tarifario o de datos que tengas contratado.
                    </Typography>
                    <br/>
                    <Typography style={{fontSize: 15}}>
                        Esta graduación en el ancho de banda utilizado para navegar no implica restricción o limitante
                        alguna en el acceso a las diversas aplicaciones, servicios, contenidos o sitios Web que el
                        usuario desee utilizar; por lo que el usuario podrá continuar navegando, utilizando sus
                        aplicaciones, descargando contenidos y accediendo a los sitios en Internet de su preferencia.
                    </Typography>
                </Cuestion>
                <Cuestion title={'¿Por qué baja la velocidad?'} isOpen={false}>
                    <Typography style={{fontSize: 15}}>
                        La velocidad de navegación es reducida con la finalidad de evitar la sobresaturación de la red;
                        de tal forma, que todos los usuarios puedan disfrutar del servicio con una velocidad promedio
                        estable y de calidad. La velocidad bajará por:


                    </Typography>
                    <Typography style={{fontSize: 15}}>
                        1.- Alcanzar la cuota de navegación mensual incluida en tu plan. Recuerda, esto sucede sólo si
                        llegas a dicha cuota.
                    </Typography>

                    <Typography style={{fontSize: 15}}>
                        2.- El tipo de plan, dado que si bien puedes navegar ilimitadamente, una vez que consumas tu
                        cuota de navegación comenzarás a navegar a una velocidad menor.
                    </Typography>
                </Cuestion>
                <Cuestion title={'¿Cuándo se restablece la velocidad?'} isOpen={false}>
                    <Typography style={{fontSize: 15}}>
                        Volverás a navegar normalmente, una vez que llegue tu fecha de renovación (Solo en modalidad
                        Pospago). Esto sucede una vez
                        al mes y lo notarás porque te llegará un Mensaje de Texto (SMS) a tu celular informando el
                        reinicio el mismo día.
                    </Typography>
                    <Typography style={{fontSize: 15}}>
                        Puedes consultar esta fecha en tu aplicación <a target="_blank" href="https://cliente.inxel.mx">Cliente
                        inXel</a>.
                    </Typography>
                </Cuestion>
                <Cuestion title={'¿Cómo mantener el control de tu consumo?'} isOpen={false}>
                    <Typography style={{fontSize: 15}}>
                        Esta información puedes verificarla en tu aplicación <a target="_blank"
                                                                                href="https://cliente.inxel.mx">Cliente
                        inXel</a>.
                    </Typography>
                </Cuestion>
            </Grid>
        </Container>
        <Footer/>
    </>
}
