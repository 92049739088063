import React, {useEffect, useState} from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    PDFViewer,
    Font
} from "@react-pdf/renderer";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import LogoInxel from '../../../../assets/logos/inxel/logo-inxel-color.png';
import LogoPaynet from '../../../../assets/logos/OpenPay/paynet.png';
import Logos from "./Logos";
import MonserratRegular from '../fonts/Montserrat-Regular.ttf';
import MonserratBold from '../fonts/Montserrat-Bold.ttf';
import MonserratLight from '../fonts/Montserrat-Light.ttf';
import {useParams} from 'react-router-dom';
import OpenPayLocalServices from "../../../utils/services/openpay";
// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#FEFEFE',
        height: '100vh'
    },
    section: {
        margin: 10,
        padding: 10,
        //flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%'
    },
    section2: {
        margin: 5
    },

    viewer: {
        height: '100vh',
        width: '100vw'
    },
    image: {
        width: 100,
        height: 90
    }
});


const useStyles = makeStyles({
    viewer: {
        height: '100vh',
        width: '100%'
    },
    image: {
        width: 100
    }
});
Font.register({
    family: 'Monserrat', fonts: [
        {src: MonserratRegular, fontWeight: 400},
        {src: MonserratBold, fontWeight: 700},
        {src: MonserratLight, fontWeight: 300}
    ]
});

// Create Document Component
const Recibo = () => {
    const style = useStyles();

    return <Document title={'Activacion'} author={'inXel'}>
        <Page size="L" style={styles.page}>
            <Logos/>
        </Page>
    </Document>
};

export default function Activaciones() {
    const styles = useStyles();
    const [loaded, setLoaded] = useState(false);
    const params = useParams();
    const openpayService = new OpenPayLocalServices();
    useEffect(() => {
        let id = params.id;
        openpayService.getStoreCharge(id).then(data => {
            setLoaded(true);
        }).catch(error => {

        })
    }, []);

    return <div style={{minHeight: '100vh', position: "relative"}}>

        {
            !loaded ? <div style={{
                position: "absolute",
                zIndex: 3,
                width: '100vw',
                height: '100vh',
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CircularProgress/>
                <Typography style={{marginLeft: '1%'}}>
                    Cargando...
                </Typography>
            </div> : <PDFViewer className={styles.viewer}><Recibo/></PDFViewer>
        }
    </div>
}