import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import Titulo from "./Titulo";
import Footer from "../../ui/Footer";
import NavBar from "../../ui/NavBar";
import Typography from "@material-ui/core/Typography";
import {XEL_COLORS, XEL_FONT} from "../../../constant";
import Collapse from "@material-ui/core/Collapse";
import {makeStyles} from "@material-ui/core/styles";
import {useResponsiveValues} from "../../utils/hooks";
import Container from "@material-ui/core/Container";
import MoreVertIcon from '@material-ui/icons/MoreVert';
//import Pagar from "../../../assets/utils/pagar.png";
const useStyles = makeStyles({
    button: {
        borderRadius: 0,
        width: 160,
        color: '#fefefe',
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 20

    },
    input: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        fontSize: 18
    }, high_desc: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.bold_weight,
        color: XEL_COLORS.primary,
    },
    low_desc: {
        fontFamily: XEL_FONT.name,
        fontWeight: XEL_FONT.regular_weight,
        color: XEL_COLORS.primary,
        textAlign: "left",
        padding: 20
    },
    desc_boton: {
        backgroundColor: XEL_COLORS.secondary,
        color: '#FEFEFE',
        display: "flex", justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    }
});


export const Cuestion = ({title, isOpen, children, variant}) => {
    const style = useStyles();
    const [open, setOpen] = useState(!!isOpen);
    const boxSize = useResponsiveValues(37, 37, 20);
    const fontSize = useResponsiveValues(20, 20, 15);

    return <Grid container>
        <Grid item xs={12} style={{marginBottom: 20}}>
            <Grid container>
                <Grid item xs={1} style={{display: "flex", justifyContent: "center"}}>
                            <span
                                className={style.desc_boton}
                                style={{
                                    width: boxSize,
                                    height: boxSize,
                                    fontSize: fontSize
                                }}
                                onClick={() => {
                                    setOpen(!open);
                                }}
                            >
                                {
                                    open ? '-' : '+'
                                }
                            </span>
                </Grid>
                <Grid item xs={11}>
                    <Typography variant={variant ? variant : 'body2'} color={'primary'}
                                style={{fontWeight: XEL_FONT.bold_weight}}>
                        {title}
                    </Typography>
                    <Collapse in={open}>
                        {children}
                    </Collapse>
                </Grid>
            </Grid>
        </Grid>
    </Grid>

}

export default function Preguntas() {
    useEffect(() => {
        document.getElementsByTagName("title")[0].text = "inXel - Preguntas frecuentes";
    }, [])
    return <>
        <NavBar/>
        <Container>
            <Grid container>
                <Grid item xs={12}>
                    <Titulo/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Cuestion
                                title={'1. ¿Mi compra en el site de inXel es segura?'}
                            >
                                <Typography variant={'body2'}>
                                    Si, tu compra es 100% segura contamos con medidas y certificado de seguridad.
                                </Typography>
                                <Typography variant={'body2'}>
                                    Nuestro site inXel trabaja con tecnología OpenPay.
                                </Typography>
                                <Typography variant={'body2'}>
                                    OpenPay cuenta con la
                                    certificación PCI-DSS para el manejo y almacenamiento seguro de tarjetas.
                                </Typography>
                            </Cuestion>
                        </Grid>

                        <Grid item xs={12}>
                            <Cuestion
                                title={'2. ¿Por qué mi equipo no es compatible con la red de inXel?'}
                            >
                                <Typography>
                                    Esto se debe a alguno de estos motivos:
                                </Typography>
                                <Typography variant={'body2'}>
                                    a) No tiene Banda 28
                                </Typography>
                                <Typography variant={'body2'}>
                                    b) No fue producido para su venta en México
                                </Typography>
                                <Typography variant={'body2'}>
                                    c) Tiene una personalización de otro operador, que no se puede remover.
                                </Typography>
                                <Typography variant={'body2'}>
                                    d) Ha presentado fallas en el desempeño de la red.
                                </Typography>
                                <Typography variant={'body2'}>
                                    e) Ya no cuenta con actualizaciones de software.
                                </Typography>
                            </Cuestion>
                        </Grid>
                        <Grid item xs={12}>
                            <Cuestion
                                title={'3. ¿Cómo puedo liberar mi celular para tener servicio inXel?'}
                            >
                                <Typography variant={'body2'}>
                                    Para liberar tu equipo es necesario que te comuniques con la empresa o distribuidor
                                    donde realizaste la compra de tu celular, si la compra fue por medio de una compañía
                                    telefónica, comunícate al centro de atención de tu compañía celular y solicita la
                                    liberación de tu equipo.
                                </Typography>
                            </Cuestion>
                        </Grid>
                        <Grid item xs={12}>
                            <Cuestion
                                title={'4. ¿Cómo configuro mi equipo con el servicio inXel? '}
                            >
                                <Typography variant={'body2'}>
                                    a) Para configurar tu equipo, debes confirmar que tu línea ya se encuentra
                                    activa. <br/>
                                    b) Revisa que el chip esté insertado de forma correcta en tu equipo.<br/>
                                    c) Verificar que detecte Red o barras de señal<br/>
                                    d) Configuración > Redes o Datos Móviles > Roaming Nacional y Datos encendidos
                                    siempre > Encender Volte en caso de estar apagado.<br/>
                                    e) Configuración de datos > Redes o Datos Móviles > APN o Nombre Puntos de acceso >
                                    Editar lo siguiente:<br/>
                                    Nombre: inXel<br/>
                                    APN: internet.inxel.com<br/>
                                </Typography>
                            </Cuestion>
                        </Grid>

                        <Grid item xs={12}>
                            <Cuestion
                                title={'5. En caso de tener algún problema con mi servicio de voz y/o datos ¿A dónde me puedo comunicar? '}
                            >
                                <Typography variant={'body2'}>
                                    ● Puedes marcar *935 desde tu número inXel.
                                </Typography>
                                <Typography variant={'body2'}>
                                    ● Escríbenos a nuestro chat mediante la página www.bitrix.inxel.mx/online/ventas/
                                </Typography>
                                <Typography variant={'body2'}>
                                    ● Mediante nuestro correo electrónico asistencia@inxel.mx
                                </Typography>
                                <Typography variant={'caption'}>
                                    Recuerda que no importa el medio de contacto, debes tener a la mano tu nombre y
                                    número telefónico para que un ejecutivo te dé la asistencia correspondiente.
                                </Typography>
                            </Cuestion>
                        </Grid>
                        <Grid item xs={12}>
                            <Cuestion
                                title={'6. ¿Cuál es el tiempo de permanencia con los planes inXel que no son paquetizados?'}>
                                <Typography variant={'body2'}>
                                    El tiempo mínimo de permanencia sugerido es de 6 meses.
                                </Typography>
                            </Cuestion>
                        </Grid>

                        <Grid item xs={12}>
                            <Cuestion
                                title={'7. ¿Dónde puedo realizar mis pagos mensuales y recargas para tener el servicio inXel?'}>
                                <Typography variant={'body2'}>
                                    a) Desde tu app inXel podrás pagar o recargar cualquiera de tus servicios y líneas:
                                    <br/>
                                    ● Dispositivos Android: <a target={'_blank'} href="https://play.google.com/store/apps/details?id=com.inxel.cliente">https://play.google.com/store/apps/details?id=com.inxel.cliente</a>
                                    <br/>
                                    ● Dispositivos Apple: <a target={'_blank'} href="https://cliente.inxel.mx">https://cliente.inxel.mx</a>
                                </Typography>
                                <Typography variant={'body2'}>
                                    b) Mediante nuestro sitio web inXel:
                                    <br/>
                                    ● https://inxel.mx/recarga
                                </Typography>
                                <Typography variant={'body2'}>
                                    c) Si no cuentas con acceso a internet o datos, paga desde tu dispositivo inXel en
                                    nuestro Portal Cautivo:
                                    <br/>
                                    ● <a href="https://cautivo.inxel.mx/" target={'_blank'}>https://cautivo.inxel.mx/</a>
                                </Typography>
                                <Typography variant={'body2'}>
                                    En efectivo, desde los siguientes convenios (Walmart, Bodega Aurrera Superama, 7
                                    eleven, Waldos, Sams club, Farmacias del Ahorro, Farmacias Benavides y Circulo K)
                                    <br/>
                                    ● Menciona al cajero que realizarás un pago referenciado PayNet a la referencia
                                    electrónica 935000 + los 10 dígitos de tu número InXel. Guarda tu ticket para
                                    cualquier aclaración.
                                </Typography>
                            </Cuestion>
                        </Grid>
                        <Grid item xs={12}>
                            <Cuestion
                                title={'8. ¿Por qué no aceptan mi tarjeta de crédito o débito? '}>
                                <Typography >
                                    Si cuentas con algún problema con tu pago es importante, no pasar los dos intentos
                                    de pago con tarjeta.
                                </Typography>
                                <Typography variant={'body2'}>
                                    Posibles causas por las que no procede el pago con tu tarjeta:
                                </Typography>
                                <Typography variant={'body2'}>
                                    1. No cuentas con fondos suficientes, válida con tu banco el saldo disponible en tu
                                    tarjeta. Posterior a la consulta intenta nuevamente.
                                </Typography>

                                <Typography variant={'body2'}>
                                    2. Los datos de la tarjeta fueron introducidos de manera incorrecta, también debes
                                    verificar la vigencia de tu tarjeta, recuerda ingresar el nombre del titular de la
                                    tarjeta. Válida los datos de tu tarjeta e intenta nuevamente.
                                </Typography>
                                <Typography variant={'body2'}>
                                    3. Tu banco no reconoce la compra, es necesario que te comuniques con tu banco para
                                    que autoricen el movimiento a. Posterior a la aclaración intenta nuevamente.
                                </Typography>
                                <Typography variant={'caption'}>
                                    Si no encuentras el error o no fue solucionado contacta a nuestro servicio de ATC da
                                    clic aquí <a href="https://crm.inxel.mobi/online/ventas" target={'_blank'}>Ventas</a>
                                </Typography>
                            </Cuestion>
                        </Grid>
                        <Grid item xs={12}>
                            <Cuestion
                                title={'9. ¿Qué debo hacer si quiero que los cargos mensuales se hagan a mi tarjeta de crédito o débito? '}>
                                <Typography>
                                    Realiza los siguientes pasos:
                                </Typography>
                                <Typography variant={'body2'}>
                                    1. Descarga la aplicación inXel desde el siguiente link:
                                    <a href="https://play.google.com/store/apps/details?id=com.inxel.cliente" target={'_blank'}>https://play.google.com/store/apps/details?id=com.inxel.cliente</a>
                                </Typography>
                                <Typography variant={'body2'}>
                                    2. Registrate con tu correo con el cual realizaste tu compra, ingresa tu número
                                    inXel.
                                </Typography>
                                <Typography variant={'body2'}>
                                    3. Dentro de la aplicación dirígete al menú principal en la parte superior
                                    izquierda.
                                </Typography>
                                <Typography variant={'body2'}>
                                    4. Da clic a la opción mis tarjetas y después en agregar tarjeta domiciliada.
                                </Typography>
                                <Typography variant={'body2'}>
                                    5. Ingresa los datos de tu tarjeta.
                                </Typography>
                                <Typography variant={'body2'}>
                                    ¡Listo, ¡tu tarjeta quedará domiciliada y el cargo se ejecutará mes con mes antes de
                                    la fecha límite de pago!.
                                    Es importante revisar que tu cuenta está sin adeudo, de no ser así por esta única
                                    ocasión tendrás que realizar tu pago en efectivo o punto de venta.
                                </Typography>
                            </Cuestion>
                        </Grid>
                        <Grid item xs={12}>
                            <Cuestion
                                title={'10. ¿Cómo consulto mi saldo?'}>
                                <Typography>
                                    A traves de tu App inXel o <a href="https://cliente.inxel.mx"
                                                                  rel="noopener noreferrer"
                                                                  target={'_blank'}>https://cliente.inxel.mx</a>
                                </Typography>
                            </Cuestion>
                        </Grid>
                        <Grid item xs={12}>
                            <Cuestion
                                title={'11. ¿Cómo configuro el Roaming internacional? '}>
                                <Typography>
                                    Si viajas a EUA o Canadá favor de realizar los siguientes pasos y pasado 10 minutos
                                    no tienes señal o registro de red te recomendamos lo siguiente:
                                </Typography>
                                <Typography variant={'body2'}>
                                    Configuración > Conexión Inalámbrica y redes > Red Móvil > Seleccionar Red de forma
                                    Automática (Desactivar y esperar a que el dispositivo realice la búsqueda de redes,
                                    esto puede demorar unos min) > Seleccionar operador con acuerdo Roaming
                                    Internacional (ATT, T Mobile o Verizon).
                                </Typography>
                                <Typography variant={'body2'}>
                                    a) Una vez realizada esta configuración: Debes revisar que estén trabajando los
                                    Datos Móviles y el Roaming activo
                                </Typography>
                                <Typography variant={'body2'}>
                                    b) Revisar en puntos de acceso que se encuentre seleccionada internet. altan
                                </Typography>
                                <Typography variant={'body2'}>
                                    Configuración Datos Celulares Puntos de Acceso En caso de ser Necesario editar en
                                    todas las opciones el Nombre: Internet y en punto de acceso o APN poner con
                                    minúsculas y sin espacio internet. altan
                                </Typography>
                            </Cuestion>
                        </Grid>
                        <Grid item xs={12}>
                            <Cuestion
                                title={'12. ¿Qué debo hacer para realizar una reposición de CHIP por robo y/o extravío?'}>
                                <Typography variant={'body2'}>
                                    Comunícate con nosotros al 558840 9522 y selecciona la opción 2, si quieres llamar
                                    desde otra línea InXel solo debes marcar *xel o *935.
                                </Typography>
                            </Cuestion>
                        </Grid>
                        <Grid item xs={12}>
                            <Cuestion
                                title={'13. No me aparece la opción para descargar mi aplicación de cliente inXel ¿qué hago?'}>
                                <Typography variant={'body2'}>
                                    En caso de sistema IOS Ingresa a <a href="https://cliente.inxel.mx">https://cliente.inxel.mx</a> en tu dispositivo luego
                                    pulsa en , posteriormente pulsa en “Añadir a pantalla de inicio” y confirma que
                                    deseas instalar la app. En el caso de Android ve a tu Play Store busca tu APP inXel
                                    que esta lista para descargar.
                                </Typography>
                            </Cuestion>
                        </Grid>
                        {
                            //  <Grid item xs={12}>
                            //                             <Cuestion
                            //                                 title={'12. ¿Dónde puedo pagar?'}
                            //                             >
                            //                                 <Typography variant={'body2'} >
                            //                                    Te ofrecemos varios metodos de pago.
                            //                                 </Typography>
                            //                                 <img style={{width:"50%",height:"70%"}} src={Pagar} alt="Pagar"/>
                            //                             </Cuestion>
                            //                         </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
        <Footer/>
    </>
}
