import React, {useEffect, useState} from "react";
import {Button, Card, CircularProgress, Container, Grid, Typography} from "@material-ui/core";
import {XEL_COLORS, XEL_FONT} from "../../../../../constant";
import AddNumber from "./AddNumber";
import DnDetail from "./DnDetail";
import Axios from "../../../../../instances/axios";
import {useSelector} from "react-redux";
import MethodContainer from "./PayMethod";
import Products from "./DnDetail/Products";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import MBB from "../../../../../assets/improve_pays/mbb.png";
import MIFI from "../../../../../assets/improve_pays/mifi.png";
import HBB from "../../../../../assets/improve_pays/hbb.png";
import CardIcon from "../../../../../assets/improve_pays/card.png";
import CashIcon from "../../../../../assets/improve_pays/cash.png";
import SpeiIcon from "../../../../../assets/improve_pays/Spei.png";
import IconButton from "@material-ui/core/IconButton";
import {Alert} from "@material-ui/lab";

const { secondary, secondary_dark, primary2, primary_shiny } = XEL_COLORS

const DropdownService = ({src, title, msisdns, onSelect}) => {

    const [open, setOpen] = useState(false);
    const [data, setData] = useState(msisdns ? msisdns : []);

    const hide = (dn) => {
        let dns = data.filter(d => d !== dn);
        setData(dns);
    }
    return <Grid container style={{marginBottom: "2%"}} justify={"center"}>
        <Grid item xs={10}
              style={{
                  backgroundColor: "#FEFEFE",
                  borderRadius: 10,
                  boxShadow: "-4px 2px 5px -4px rgba(0,0,0,0.75)",
                  padding: "2%",
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "2%"
              }}
              onClick={() => setOpen(!open)}>
            <Grid container>
                <Grid item xs={3} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <span
                        style={{
                            width: 35,
                            height: 35,
                            backgroundColor: "#EBE8EC",
                            borderRadius: "50%",
                            padding: "1%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
             <img src={src} alt={title} style={{height: "70%"}}/>            </span>
                </Grid>
                <Grid item xs={6} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Typography align={"center"}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={3} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                  <span style={{
                      fontSize: '0.8em',
                      backgroundColor: XEL_COLORS.secondary,
                      color: "#FEFEFE",
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      padding: "1%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                  }}>{data.length}</span>
                </Grid>
            </Grid>
        </Grid>
        {open && data.map((item, index) => {
            return <Grid style={{
                paddingLeft: "5%",
                paddingRight: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "1%"
            }}
                         item xs={8}
                         key={index}>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                        backgroundColor: "#FEFEFE", color: "#000000", "&:hover": {
                            backgroundColor: XEL_COLORS.secondary, color: "#FEFEFE"
                        }, //    display: "flex",
                        //   justifyContent: "space-between",
                    }}
                    onClick={() => onSelect(item)}
                >
                    <Typography variant={"caption"}
                        //            style={{marginLeft:"5%"}}
                    >
                        {item}
                    </Typography>
                </Button>
                <IconButton onClick={() => hide(item)} color="secondary" style={{marginLeft: 5}} aria-label="Hide">
                    <RemoveCircleIcon/>
                </IconButton>
            </Grid>
        })}
    </Grid>
}

const ProductCatalog = ({msisdn, planes, onChange, data, setPayParams, payParams, delParam, setHasHotspot}) => {
    const [dnData, setDnData] = useState(data ? data : []);
    const [params, setParams] = useState({
        postpago: 0, prepago: {
            id: 0, price: 0, name: "",
        }
    });
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const axios = Axios();
    const [error, setError] = useState({
        open: false, msg: "Hola", severity: "error"
    });
    useEffect(() => {
        setParams({
            postpago: 0, prepago: {
                id: 0, price: 0, name: "",
            }
        });
    }, [msisdn]);
    useEffect(() => {

        setTotal(params.postpago + params.prepago.price);
        let prms = {};
        let atLeastOne = false;
        if (params.prepago.id !== 0) {
            atLeastOne = true;
            prms.prepago = params.prepago;
        }
        if (params.postpago !== 0) {
            atLeastOne = true;
            prms.postpago = params.postpago;
        }
        if (atLeastOne)
            setPayParams(msisdn, prms);
        else {
            delParam(msisdn);
        }
    }, [params]);
    useEffect(() => {
        for (let i = 0; i < payParams.length; i++) {
            if (payParams[i].msisdn === msisdn) {

                const {postpago, prepago} = payParams[i].params;

                setParams({
                    postpago: postpago ? postpago : 0,
                    prepago: prepago ? prepago : {
                        id: 0, price: 0, name: "",
                    }
                });

            }
        }
    }, []);


    useEffect(() => {
        if (!data) {
            setLoading(true);
            axios.get(`clients/msisdn/${msisdn}/offers?lower=true`)
                .then(res => {
                    setLoading(false);
                    const {data} = res;
                    if (data.status !== 200) {
                        setError({
                            open: true, msg: "Hubo un problema al procesar la solicitud.", severity: "error"
                        });
                        return;
                    }
                    setDnData(data);
                })
                .catch(err => {
                    setLoading(false);
                    console.log(err);
                })
        }
    }, [msisdn])
    const handlePostpago = () => {
        setParams(prevState => {
            return {
                ...prevState, postpago: prevState.postpago > 0 ? 0 : dnData.saldo
            }
        });
    }

    const handlePrepago = (id, price, name) => {
        let payparams = params;
        let issame = payparams.prepago.id === id;
        if (!issame) setParams(prevState => {
            return {
                ...prevState, prepago: {
                    id, price, name
                }
            }
        }); else setParams(prevState => {
            return {
                ...prevState, prepago: {
                    id: 0, price: 0, name: ""
                }
            }
        });
    }
    return <Grid container>
        <Grid item xs={12}>
            {loading && <CircularProgress size={30}/>}
            {(!loading && dnData) &&
                <Products params={params} handlePostpago={handlePostpago} handlePrepago={handlePrepago}
			  setHasHotspot={setHasHotspot}
                          planes={planes}
                          dnData={dnData} onChange={onChange}/>}
            {
                error.open  && <Alert severity={error.severity} onClose={()=>setError(prevState => ({...prevState,open: false}))}>
                    {error.msg}
                </Alert>
            }
        </Grid>
    </Grid>
}
const Pagarv2 = ({handleBack}) => {
    const [planes, setPlanes] = useState([]);
    const axios = Axios();
    const [msisdns, setMsisdns] = useState([]);
    const [newMsisdns, setNewMsisdns] = useState([]);
    const session = useSelector(state => state.login);
    const [total, setTotal] = useState(0);
    const [payParams, setPayParams] = useState([]);
    const [step, setStep] = useState(1);
    const [dnSelected, setDnSelected] = useState(null);
    const [addNumber, setAddNumber] = useState(false);
    const [txt, setTxt] = useState({
        title: "Pagos de compras y renovaciones",
        subtitle: "Selecciona aquí los servicios que vas a renovar y las compras que quieras pagar.",
        step: 1
    });
    const [hasHotspot, setHasHotspot] = useState(false)

    /*Seleccion de que pagar*/
    const [frecuents, setFrecuents] = useState(true);

    const [method, setMethod] = useState(1);

    const [dns, setDns] = useState({
        mbb: [], hbb: [], mifi: [],
    });

    const [mbb, setMbb] = useState([]);
    const [hbb, setHbb] = useState([]);
    const [mifi, setMifi] = useState([]);
    const [detalles, setDetalles] = useState([]);

    const [processCompleted, setProcessCompleted] = useState(false);
    const methods = [{
        name: "Tarjeta de crédito o débito",
        action: () => setMethod(1),
        src: CardIcon,
        delay: "Una vez realizado el pago, se verá reflejado al instante.",
    }, {
        name: "Transferencia bancaria (SPEI)", action: () => setMethod(2), src: SpeiIcon, min: {
            amount: 200, message: "*Monto mínimo requerido para habilitar esta opción $200.00"
        }, delay: "Una vez realizado el pago, por lo regular se refleja en 1hrs pero puede abarcar hasta 24hrs.",
    }, {
        name: "Pago en efectivo",
        action: () => setMethod(3),
        src: CashIcon,
        delay: "Una vez realizado el pago, se procesa en un máximo de 15min.",
    }];

    /*Detalles y total a pagar*/
    useEffect(() => {
        if (payParams) {
            let detalle = [];
            let tot = 0;
            for (let i = 0; i < payParams.length; i++) {
                let payParam = payParams[i];
                let conta = 0;

                let detail = {
                    msisdn: payParam.msisdn,
                }

                let pays = [];

                const {params: {postpago, prepago}} = payParam;

                if (postpago) {
                    pays.push(`Mensualidad $${payParam.params.postpago}.00`);
                    conta++;
                    tot += postpago;
                }
                if (prepago) {
                    pays.push(`Recarga ${payParam.params.prepago.name} $${payParam.params.prepago.price}.00`);
                    conta++;
                    tot += prepago.price;
                }
                detail.pays = pays;
                if (conta > 0) {
                    detalle.push(detail);
                }

            }
            setTotal(tot);
            setDetalles(detalle);
        }
    }, [payParams]);
    useEffect(() => {

        if (session.logged) {
            let frecuents = session.frecuents;
            let vinculados = session.msisdns;
            let dnsN = {
                mbb: [], hbb: [], mifi: [],
            };
            for (let i = 0; i < frecuents.length; i++) {
                const dn = frecuents[i].msisdn;


                if (frecuents[i].tipo === "mbb") {
                    dnsN.mbb.push(frecuents[i].msisdn);
                } else if (frecuents[i].tipo === "hbb") {
                    dnsN.hbb.push(frecuents[i].msisdn);
                } else if (frecuents[i].tipo === "mifi") {
                    dnsN.mifi.push(frecuents[i].msisdn);
                }
            }

            for (let i = 0; i < vinculados.length; i++) {
                if (vinculados[i].tipo === "mbb") {
                    dnsN.mbb.push(vinculados[i].msisdn);
                } else if (vinculados[i].tipo === "hbb") {
                    dnsN.hbb.push(vinculados[i].msisdn);
                } else if (vinculados[i].tipo === "mifi") {
                    dnsN.mifi.push(vinculados[i].msisdn);
                }
            }

            setMbb(dnsN.mbb);
            setHbb(dnsN.hbb);
            setMifi(dnsN.mifi);

        }
    }, [session]);


    useEffect(() => {
        if (step === 2) {
            if (!session.logged) setStep(3);
        }

    }, [step]);

    useEffect(() => {
        let t = 0;
        for (let i = 0; i < payParams.length; i++) {
            const {params: {postpago, prepago}} = payParams[i];
            if (postpago) {
                t += postpago;
            }
            if (prepago) {
                t += prepago.price;
            }
        }
        setTotal(t);
    }, [payParams]);

    useEffect(() => {
        axios.get("/clients/pre.catalog").then(res => {
            setPlanes(res.data);
        }).catch(err => {
            console.log(err);
        });
        if (session.logged) {
            let dns = [];
            if (session.msisdns) {
                for (let i = 0; i < session.msisdns.length; i++) dns.push({
                    msisdn: session.msisdns[i].msisdn
                });
            }
            if (session.frecuents) for (let i = 0; i < session.frecuents.length; i++) dns.push({
                msisdn: session.frecuents[i]
            });

            setMsisdns(dns);
        }
    }, []);
    const addData = (msisdn, data) => {
        setNewMsisdns(prevState => [{
            msisdn: msisdn, data
        }, ...prevState,]);
    }

    const handlePayParam = async (msisdn, params) => {
        let newPayParams = payParams.filter(p => p.msisdn !== msisdn);
        newPayParams.push({
            msisdn,
            params
        });
        setPayParams(newPayParams);
    }
    const delPayParam = msisdn => {
        setPayParams(prevState => prevState.filter(p => p.msisdn !== msisdn));
        setDnSelected(null);
    }

    const delMsisdnParam = msisdn => {
        setPayParams(prevState => prevState.filter(p => p.msisdn !== msisdn));
    }

    const BackButton = () => <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end"}}>
        {session.logged && <Button style={{color: "#FEFEFE", marginTop: "2%", background: `linear-gradient(${ secondary }, ${ secondary_dark })`}} variant={"contained"}
                                   color={"secondary"}
                                   onClick={() => {
                                       if (step > 1) {
                                           setStep(prevState => prevState - 1)
                                       } else {
                                           handleBack();
                                       }
                                   }}
        >
            Atras</Button>}
    </Grid>
    const payDetails = <Grid item xs={12} md={6} lg={4}
                             style={{boxShadow: "-2px 2px 5px -2px rgba(0,0,0,0.75)"}}>
        <Grid container justify={"center"}>
            <Grid item xs={12} style={{
                backgroundColor: XEL_COLORS.primary, color: "#FEFEFE", padding: "2%"
            }}>
                <Typography align={"center"}
                            style={{fontWeight: XEL_FONT.bold_weight}}>
                    Total a pagar:
                </Typography>
            </Grid>
            <Grid item xs={12} style={{padding: "1%"}}>
                <Typography variant={"h6"}>
                    Total:
                </Typography>

            </Grid>
            <Grid item xs={12}
                  style={{display: "flex", justifyContent: "center"}}>
                <Typography align={"center"} variant={"h3"} style={{
                    backgroundColor: XEL_COLORS.secondary,
                    borderRadius: 10,
                    padding: "2%",
                    width: "60%",
                    color: "#FEFEFE",
                }}>
                    ${total}.00
                </Typography>
            </Grid>
            {step !== 2 && <Grid item xs={12} style={{
                display: "flex", justifyContent: "center", margin: "2%"
            }}>
                <Button variant={"contained"} disabled={total === 0}
				style={total > 0 ? {
				    background: 
					`linear-gradient(${primary2}, ${ primary_shiny })`,
				    color: 'white',
				} : {}} 
                        onClick={() => {
                            setStep(2)
                        }}>
                    {step !== 3 ? "Siguiente" : "Pagar"}
                </Button>
            </Grid>}
            {step === 1 && <>
                {detalles.length > 0 && <Grid item xs={12}>
                    <hr/>
                </Grid>}
                {detalles.map(param => <Grid style={{marginTop: "1%"}} key={param.msisdn} item xs={11} md={9}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant={"caption"} color={"secondary"}>
                                {param.msisdn}
                            </Typography>
                            {param.pays.map((pay, i) => <Typography color={"primary"} key={i}>
                                {pay}
                            </Typography>)}
			    {
				hasHotspot ? (
				    <Typography variant={"caption"} color={"secondary"}>
					Incluye hotspot (datos para compartir)
				    </Typography>
				) : null
			    }
                        </Grid>
                        <Grid item xs={2}>
                            <Button onClick={() => delPayParam(param.msisdn)}>
                                Borrar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>)}
                {detalles.length > 0 && <Grid item xs={12}>
                    <hr/>
                </Grid>}
            </>}
        </Grid>
    </Grid>;
    return <Container>
        <Grid container spacing={2}>

            {
                !processCompleted && <>
                    <Grid item xs={12}>
                        <Typography variant={"h3"} align={"center"} color={"secondary"}>
                            Paga tus servicios
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                        <Typography variant={"h5"} style={{
                            backgroundColor: XEL_COLORS.secondary,
                            width: 35,
                            height: 35,
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#FEFEFE",
                            margin: 3
                        }}>{step === 3 ? (session.logged) ? step : 2 : step}</Typography>
                        <Typography variant={"h4"} color={"primary"}>
                            {
                                step === 1 ? "Selecciona tu plan o recarga a pagar" : ""
                            }
                            {
                                step === 2 ? "Selecciona tu método de pago" : ""
                            }
                            {
                                step === 3 ? (method === 1 ? (session.logged ? "Selecciona o agrega la tarjeta con la que deseas pagar" : "Introduce los datos de la tarjeta con la que deseas pagar") : "Generar información de pago") : ""
                            }
                        </Typography>
                    </Grid>
                </>
            }
            <Grid item xs={12}>
                <Grid container justify={"center"} spacing={2}>
                    {step === 3 && <MethodContainer
                        onCompleted={() => setProcessCompleted(true)}
                        rollBack={() => {
                            if (session.logged) {
                                setStep(2);
                            } else
                                setStep(1);
                        }}
                        payParams={payParams}
                        method={method}/>}
                    {step === 2 && session.logged && <Grid item xs={12}>
                        <Grid container spacing={2} justify={"center"}>
                            <Grid item xs={6} style={{backgroundColor: "#F9F5FB"}}>
                                <Grid container spacing={3} justify={"center"} style={{padding: "2%"}}>
                                    {methods.map((m, i) => <Grid
                                        onClick={() => {
                                            if (m.min) {
                                                if (total < m.min.amount) {
                                                    return;
                                                }
                                            }
                                            setStep(prevState => prevState + 1);
                                            m.action()
                                        }}
                                        key={i} item xs={10} style={{
                                        padding: "1%"
                                    }}>
                                        <Card elevation={m.min ? (total < m.min.amount) ? 0 : 2 : 2}
                                              style={{
                                                  padding: "2%",
                                                  cursor: `${m.min ? (total < m.min.amount) ? "inherit" : "pointer" : "pointer"}`
                                              }}
                                        >
                                            <Grid container>
                                                <Grid item xs={3}
                                                      style={{display: "flex", justifyContent: "center"}}>
                    <span style={{
                        width: 35,
                        height: 35,
                        backgroundColor: "#EBE8EC",
                        borderRadius: "50%",
                        padding: "1%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    >
             <img src={m.src} alt={m.name} style={{height: "70%"}}/>
            </span>
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography>
                                                        {m.name}
                                                    </Typography>

                                                    {(m.min && (total < m.min.amount)) ?
                                                        <Typography variant={"caption"} style={{
                                                            color: "darkblue"
                                                        }}>

                                                            {m.min.message}
                                                        </Typography> : m.delay &&
                                                        <Typography variant={"caption"} style={{
                                                            color: "darkblue"
                                                        }}>

                                                            {m.delay}
                                                        </Typography>}


                                                </Grid>
                                            </Grid>

                                        </Card>
                                    </Grid>)}
                                </Grid>
                            </Grid>
                            {payDetails}
                            <BackButton/>
                        </Grid>
                    </Grid>}
                    {step === 1 && <Grid item xs={11} md={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={11} md={6} lg={4}>
                                        <Typography
                                            variant={"overline"}
                                            style={{fontWeight: XEL_FONT.bold_weight}}
                                            //    onClick={() => setFrecuents(true)}
                                        >
                                            Selecciona o ingresa tus números
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={11} md={6} lg={4}>
                                        <Typography variant={"overline"}
                                                    style={{fontWeight: XEL_FONT.bold_weight}}
                                            //            onClick={() => setFrecuents(false)}
                                        >
                                            Selecciona tu plan o recarga
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {frecuents && <Grid item xs={12}
                                                style={{
                                                    marginTop: "1%", boxShadow: "-2px 2px 5px -2px rgba(0,0,0,0.75)",
                                                }}
                            >
                                <Grid container>
                                    <Grid item xs={12} md={6} lg={4}
                                          style={{padding: "2%", backgroundColor: "#F9F5FB"}}>
                                        {addNumber ? <AddNumber onSelect={(dn, tipo) => {

                                            let added = false;
                                            for (let i = 0; i < mbb.length; i++) {
                                                if (dn === mbb[i]) {
                                                    added = true;
                                                    break;
                                                }
                                            }
                                            if (!added) for (let i = 0; i < hbb.length; i++) {
                                                if (dn === hbb[i]) {
                                                    added = true;
                                                    break;
                                                }
                                            }
                                            if (!added) for (let i = 0; i < mifi.length; i++) {
                                                if (dn === mifi[i]) {
                                                    added = true;
                                                    break;
                                                }
                                            }
                                            if (!added) switch (tipo) {
                                                case "mbb":
                                                    let mbbs = mbb;
                                                    mbbs.push(dn);
                                                    setMbb(mbbs);
                                                    break;
                                                case "hbb":
                                                    let hbbs = hbb;
                                                    hbbs.push(dn);
                                                    setHbb(hbbs);
                                                    break;
                                                case "mifi":
                                                    let mifis = mifi;
                                                    mifis.push(dn);
                                                    setMifi(mifis);
                                                    break;
                                                default:
                                                    break;
                                            }

                                            setAddNumber(false);
                                            setDnSelected(dn);
                                        }} msisdns={msisdns} addData={addData}/> : <>
                                            {mbb.length > 0 && <DropdownService
                                                src={MBB}
                                                onSelect={(dn) => {
                                                    setDnSelected(dn)
                                                }}
                                                msisdns={mbb}
                                                title={"Movil"}/>}
                                            {hbb.length > 0 && <DropdownService
                                                src={HBB}
                                                onSelect={(dn) => setDnSelected(dn)}
                                                msisdns={hbb}
                                                title={"Internet fijo"}/>}
                                            {mifi.length > 0 && <DropdownService
                                                src={MIFI}
                                                onSelect={(dn) => setDnSelected(dn)}
                                                msisdns={mifi}
                                                title={"Internet movil"}/>}
                                        </>}
                                        <div style={{
                                            width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "8%"
                                        }}>
					    <Button 
						onClick={() => setAddNumber(!addNumber)}
						variant={"contained"}
						style={{
						    color: "#FEFEFE",
						    width: "60%",
						    background: 
						    `linear-gradient(${secondary}, ${secondary_dark})`
					    }}>
                                                <Typography>
                                                    {addNumber ? "Cancelar" : "+ Añadir número"}
                                                </Typography>
                                            </Button>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}
                                          style={{paddingLeft: "3%", paddingRight: "3%", paddingTop: "1%"}}>
                                        {dnSelected && <Typography variant={"h6"}>
                                            {dnSelected}
                                        </Typography>}
                                        {dnSelected && <ProductCatalog
					    setHasHotspot={setHasHotspot}
                                            delParam={delMsisdnParam}
                                            payParams={payParams}
                                            setPayParams={handlePayParam}
                                            planes={planes}
                                            msisdn={dnSelected}
                                            data={msisdns.filter(dn => dn.toString() === dnSelected.toString())[0]}/>}

                                    </Grid>
                                    {payDetails}
                                </Grid>
                            </Grid>}
                            <BackButton/>
                            {<Grid item xs={12} style={{backgroundColor: "#EEEEEE", marginTop: "1%"}}
                            >
                                {!frecuents && newMsisdns.map((dn, index) => {
                                    return <>
                                        <DnDetail payParams={payParams} setPayParams={handlePayParam}
                                                  planes={planes}
                                                  key={index}
                                                  msisdn={dn.msisdn} data={dn.data}/>
                                        <hr/>
                                    </>
                                })}
                            </Grid>}
                        </Grid>
                    </Grid>}
                    {step === 10 && <Grid item xs={12} md={3}>
                        <Card style={{padding: "2%"}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{backgroundColor: XEL_COLORS.primary}}>
                                    <Typography variant={"h5"} align={"center"} style={{color: "#FEFEFE"}}>
                                        Total a pagar
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={"h4"} align={"center"}>
                                        ${total}.00
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginBottom: "1%"
                                }}>
                                    <Button variant={"contained"} disabled={total === 0}
				    style={ total > 0 ? {
						background: 
						    `linear-gradient(${primary2}, ${ primary_shiny })`,
						color: 'white',
					    } : {}}
                                            onClick={() => {
                                                setStep(2)
                                            }}>
                                        {step !== 3 ? "Siguiente" : "Pagar"}
                                    </Button>

                                    {session.logged && <Button style={{color: "#FEFEFE", marginTop: "2%", background: 
					`linear-gradient(${secondary}, ${ secondary_dark })`,
				    color: 'white', }}
                                                               variant={"contained"}
                                                               color={"secondary"}
                                                               onClick={() => {
                                                                   if (step > 1) {
                                                                       setStep(prevState => prevState - 1)
                                                                   } else {
                                                                       handleBack();
                                                                   }
                                                               }}
                                    >
                                        Volver</Button>}

                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>}
                </Grid>
            </Grid>

        </Grid>
    </Container>;
};


export default Pagarv2;
