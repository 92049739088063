import React from "react";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


import Linea from '../../../../assets/logos/home/LineaPlan.png';

const Titulo = () => {
    return <Grid container style={{marginTop: 40}}>
        <Grid item xs={12}>
            <Typography variant={'h1'} align={'center'} color={'primary'} style={{fontSize:19}}>
                Descubre toda nuestra zona de venta.
            </Typography>
        </Grid>
        <Grid item xs={12} style={{display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
            <img src={Linea} alt="Linea" style={{height: 15, maxWidth: 325, width:'100%'}}/>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h3'} align={'center'} color={'primary'} style={{fontSize:13}}>
                Si tu ciudad no aparece, no te preocupes. ¡Esperanos!
            </Typography>
        </Grid>
    </Grid>
}

export default Titulo;