import React from "react";
import {Container, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {XEL_FONT} from "../../../../../constant";

import Linea from '../../../../../assets/logos/home/LineaPlan.png';
import {useResponsiveValues} from "../../../../utils/hooks";

const Titulo = ({title, subtitle, center}) => {
    return <Grid container
                 style={{marginBottom:10,backgroundColor: '#FEFEFE'}}>
        <Container>
            <Grid item xs={12}>
                <Typography variant={'h3'} align={'left'} color={'primary'} style={{
                //    fontSize: useResponsiveValues(35, 25, 15),
                    marginTop: '1%',
                    textAlign: center ? 'center' : "left"
                }}>
                    {
                        title ? title : "Nuestros Planes"
                    }
                </Typography>
                <Typography color={'primary'} style={{
                   // fontSize: useResponsiveValues(20, 15, 12),
                    //  marginTop: '1%'
                    textAlign: center ? 'center' : "left"
                }}>
                    {
                        subtitle ? subtitle : "¡Pórtate a inXel! Y encuentra el plan que más se adapte a ti."
                    }
                </Typography>
            </Grid>
        </Container>
    </Grid>
}

export default Titulo;
