import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import {CardHeader, CircularProgress, Grid, Typography, Card, CardContent, CardActions} from "@material-ui/core";

import {useQuery} from "../../../utils/hooks";
import axios from "axios";
import {Alert} from "@material-ui/lab";
import {XEL_COLORS} from "../../../../constant";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {Home, Print} from "@material-ui/icons";
import OpenpayLogo from '../../../../assets/logos/OpenPay/openpay_color.png';
import Cookie from "js-cookie";
import {useHistory} from "react-router-dom";

const Recipient = () => {

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState({
        open: false, txt: "", severity: 'success'
    });
    const history = useHistory();
    const query = useQuery();

    const [charge, setCharge] = useState();

    const [fromEcc, setFromEcc] = useState(false);

    useEffect(() => {

        let id = query.get('id');

        let eccomerce = query.get('ecommerce');

        setFromEcc(eccomerce === '1');

        if (id) {
            loadCharge(id);

        } else {
            setLoading(false);

            setMessage({
                open: true, txt: "Se requiere un ID para buscar recibo de pago", severity: 'warning'
            })

        }

    }, [])
    const loadCharge = (id) => {

        axios.get(`https://api.inxel.mx/clients/buys/status/` + id).then(({data}) => {
            setLoading(false);
            if (data.error) {
                setMessage({
                    open: false,
                    txt: "Hubo un problema al obtener los datos del pago, código de error: OPAY500",
                    severity: 'error'
                })
                return;
            }
            setCharge(data);

            const {status, order_id} = data;

            switch (status) {
                case 'completed':
                    Cookie.set(`xel-pay-confirmed-${order_id}`, '1', {expires: 3});
                    break;
                case 'failed':
                    Cookie.set(`xel-pay-failed-${order_id}`, '1', {expires: 3});
                    break;
                default:
                    break;
            }

        }).catch(err => {
            setLoading(false);
            setMessage({
                open: false,
                txt: "Hubo un problema al obtener los datos del pago, código de error: XEL500",
                severity: 'error'
            })
        })

    }

    return <Container>

        <Grid container style={{display: "flex", minHeight: "100vh", justifyContent: 'center', paddingTop: '3rem'}}>

            <Grid item xs={11} md={8} lg={6}>
                <Card id={'recipient'}>
                    <CardHeader style={{backgroundColor: XEL_COLORS.primary}}
                                title={<Typography align={"center"} variant={'h5'} style={{color: "#FEFEFE"}}>
                                    Recibo de pago inXel
                                    {fromEcc && <Typography variant={'body2'}>
                                        Un asesor se pondra en contacto contigo en caso de ser requerido
                                    </Typography>}
                                </Typography>}/>
                    <CardContent>
                        <Grid container spacing={2}>
                            {loading && <Grid item xs={12}>
                                <Typography align={'center'}>
                                    Cargando... <CircularProgress size={30} color={'primary'}/>
                                </Typography>
                            </Grid>}
                            {message.open && <Grid item xs={10}>
                                <Alert severity={message.severity}>
                                    {message.txt}
                                </Alert>
                            </Grid>}
                            {charge && charge.status === 'failed' && <Grid item xs={12}>
                                <Typography align={'center'} variant={'h6'} style={{color: "#ff7676"}}>
                                    Al parecer el pago no pudo ser procesado
                                </Typography>
                            </Grid>}

                            {charge && charge.status === 'completed' && <Grid item xs={12}>
                                <Typography align={'center'} variant={'h6'} style={{color: "#32CD32"}}>
                                    Pago procesado correctamente
                                </Typography>
                            </Grid>}

                            {charge && (charge.status === 'pending' || charge.status === 'in_progress' || charge.status === 'charge_pending') &&
                                <Grid item xs={12}>
                                    <Typography align={'center'} variant={'h6'} style={{color: "#778899"}}>
                                        Pago pendiente
                                    </Typography>
                                </Grid>}
                            {(charge && !charge.error) && <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {charge.customer && <Grid item xs={12}>
                                        <Typography>
                                            Nombre del
                                            cliente: <strong> {charge.customer.name} {charge.customer.last_name}</strong>
                                        </Typography>
                                    </Grid>}
                                    {charge.order_id && <Grid item xs={12}>
                                        <Typography>
                                            Id de orden: <strong> {charge.order_id}</strong>
                                        </Typography>
                                    </Grid>}
                                    <Grid item xs={12}>
                                        <Typography>
                                            Concepto: <strong> {charge.description}</strong>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography>
                                            Método de pago: <strong> {charge.method}</strong>
                                        </Typography>
                                    </Grid>
                                    {charge.payment_method && <Grid item xs={12}>
                                        <Typography>
                                            Referencia: <strong> {charge.payment_method.reference}</strong>
                                        </Typography>
                                    </Grid>}
                                    <Grid item xs={12}>
                                        <Typography>
                                            Monto total: <strong> ${(+charge.amount).toFixed(2)}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Fecha de operación: <strong> {charge.operation_date}</strong>
                                        </Typography>
                                    </Grid>
                                    {charge.pays && <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Detalles:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{minWidth: 650}} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><Typography><strong>Número
                                                                    inXel</strong></Typography></TableCell>
                                                                <TableCell
                                                                    align="right"><Typography><strong>Concepto</strong></Typography></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {charge.pays.map((row, index) => (<TableRow
                                                                key={index}
                                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    {row.msisdn}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="right">{row.products.map(product =>
                                                                    <Typography
                                                                        key={product.name}>
                                                                        {product.name} ${(+product.amount.toFixed(2))}
                                                                    </Typography>)}</TableCell>
                                                            </TableRow>))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Grid>}
                                </Grid>
                            </Grid>}
                            <Grid item xs={12} style={{
                                display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"
                            }}>
                                <Typography>Pagos procesados mediante Openpay</Typography>
                                <img src={OpenpayLogo} style={{height: '4rem'}} alt="Openpay"/>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions style={{
                        display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"
                    }}>
                        {!loading && charge && <Button onClick={() => {
                            window.print();
                        }} endIcon={<Print color={'primary'}/>}>
                            Imprimir
                        </Button>}
                        {
                            fromEcc && <Button onClick={() => {
                                history.push('/')
                            }} endIcon={<Home color={'primary'}/>}>
                                Inicio
                            </Button>
                        }
                        {!loading && <Typography variant={'caption'} align={'center'}>
                            Para cualquier duda o aclaración, por favor contáctanos al teléfono <a
                            href="tel:5588409522">(558) 840-9522</a> o al correo <a
                            href="mailto:asistencia@inxel.mx">asistencia@inxel.mx</a>.
                        </Typography>}
                    </CardActions>
                </Card>
            </Grid>


        </Grid>
    </Container>
}


export default Recipient;
